import React from 'react'
import { AccountCreationSectionsEnums } from '../../../enum'
import shopIcon from '../../../assets/images/icons/shop.svg'

type Props = {
    title: string,
    desc: string,
    status: string,
    isVisible: boolean,
    name: string,
    handleCollapsButton: (show: boolean) => void
}

const CollapsibleItemHeader = ({ title, desc, status, isVisible, name, handleCollapsButton }: Props) => {
    return (
        <div className="single-wiz-step__header">
            <div className="wiz-step-header__wrapper">
                <div className="wiz-step-header">
                    <div className="wiz-step-header-icon">
                        {name === AccountCreationSectionsEnums.HOME_ACCOUNT_TYPE ? <i className="bi bi-exclude" />
                            : name === AccountCreationSectionsEnums.HOME_YOUR_PROFILE ? <i className="bi bi-person-badge" />
                                : name === AccountCreationSectionsEnums.HOME_BUSINESS_PROFILE ? <img src={shopIcon} alt="" />
                                    : name === AccountCreationSectionsEnums.HOME_TERMS_AND_CONDITIONS ? <i className="bi bi-file-text-fill" />
                                        : null}
                    </div>
                    <div className="wiz-step-title__wrapper">
                        <h5 className="wiz-step-title">{title}</h5>
                        <p className="wiz-step-desc">{desc}</p>
                    </div>
                </div>
            </div>
            <div className="wiz-step-header__wrapper">
                <span className={"step-status-notify " + status}>
                    <i className={status === "completed" ? "step-icon bi bi-check-circle-fill"
                        : status === "pending" ? "step-icon bi bi-exclamation-circle-fill"
                            : status === "locked" ? "step-icon bi bi-lock-fill"
                                : status === "rejected" ? "step-icon bi bi-x-circle-fill" : ""} />
                    {status}
                </span>
                <button
                    className="btn btn-small primary-action anime-flip-90"
                    onClick={() => handleCollapsButton(!isVisible)}
                    disabled={status === 'locked'}
                >
                    {isVisible ? <i className="bi bi-chevron-up" /> : <i className="bi bi-chevron-down" />}
                </button>
            </div>
        </div>
    )
}

export { CollapsibleItemHeader }