/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { CSSProperties } from "react";
import {Button, Card, Form, Radio, Select} from 'antd';
import {PackageDataRespModel} from "../models/packageDataResp.model";

const layout = {
    labelCol: { span: 1 },
    wrapperCol: { span: 23 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

type Props = {
    onPackageSelect: (values: any) => any,
    packageData: Array<PackageDataRespModel>
}

const PackageSelection = ({ onPackageSelect, packageData }: Props) => {
    return (
        <React.Fragment>
            <div className="card-body gws__body">
                <h4 className="gws-title">Select Package</h4>
                <Form
                    {...layout}
                    name="add-domain"
                    style={{ maxWidth: 600, marginInline: 'auto', textAlign: 'left' }}
                    initialValues={{ remember: true }}
                    onFinish={onPackageSelect}
                    autoComplete="off"
                    labelAlign={"left"}
                >
                    <Form.Item
                        name="package"
                        rules={[{
                            required: true,
                            message: 'This is Mandatory Field, Please select a package.',
                        }]}>
                        <Radio.Group>
                            <div className="package-container">
                                {packageData && packageData.map(packageObj => {
                                    const mainResource = packageObj.resources.filter((resource: any) => resource.isMainResource == true);
                                    return <Radio.Button value={packageObj.productCode} className="package-selector-card">
                                        <Card title={packageObj.title} bordered={false}>
                                            <div className="headers">
                                                <h3>Rs. {packageObj.priceWithoutTax}.00</h3>
                                                <span className="small-desc">Per Month</span>
                                                <h3>{mainResource[0].resourceValue}{mainResource[0].resourceUnit}</h3>
                                                <span className="small-desc">{mainResource[0].resourceType}</span>
                                            </div>
                                            {packageObj.features.map((feature: string) => {
                                                return <p>&#x2022; {feature}</p>
                                            })}
                                        </Card>
                                    </Radio.Button>
                                })}
                            </div>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item {...tailLayout} className={"wizard-action"}>
                        <Button type="primary" htmlType="submit" className={"btn btn-primary"}>
                            Next
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </React.Fragment>
    );
}

export default PackageSelection;