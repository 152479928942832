import React from 'react'
import { SingleAppModel, SubWidgets, WidgetRate } from '../models/response/single-app.model';
import SimpleReactValidator from 'simple-react-validator';

type Props = {
    appDetails: SingleAppModel,
    onChangeWidget: (e: any) => void,
    selectedSubWidget: SubWidgets | null,
    validator: SimpleReactValidator
}

const SubWidgetSelect = ({ appDetails, onChangeWidget, selectedSubWidget, validator }: Props) => {
    return (
        <div className="panel-table-content-list">
            {appDetails.widgetDetails.SubWidgets.map((subWidget: SubWidgets, index: number) => {
                return (
                    <>
                        <span className="panel-group-title">{subWidget.name}</span>
                        {subWidget.SubWidgetRate?.map((widgetRate: WidgetRate, i: number) => {
                            return (
                                <div className={`panel-table-list-item card ${((subWidget.uuid === selectedSubWidget?.uuid) ? 'active' : '')}`}>
                                    <div className="panel-table-listing-item__inner">
                                        <div className="feature-details__wrapper">
                                            <div className="feature-details-title__wrapper no-shade billing-and-plans-package-select">
                                                <span className="title">{widgetRate.title}</span>
                                                <span className="small-desc">
                                                    {widgetRate.description}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel-table-listing-item__inner">
                                        <div className="panel-table-right-align-item__wrapper">
                                            {i == 0 && <div className="panel-table-right-align-item">
                                                <input
                                                    className="form-check-input secondary"
                                                    type="radio"
                                                    name=""
                                                    id="radio1"
                                                    value={JSON.stringify(subWidget)}
                                                    onChange={onChangeWidget}
                                                    checked={subWidget.uuid === selectedSubWidget?.uuid}
                                                />
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </>
                )
            })}
            <div className="validation invalid-feedback">{validator.message('rate', selectedSubWidget, 'required')}</div>
        </div>
    )
}

export { SubWidgetSelect }