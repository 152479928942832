import React from 'react'

type Props = {
    
}

const DoneButtonComponent = ({}: Props) => {
    return (
        <div className="single-wiz-step__wrapper">
            <div className="single-wiz-step__header">
                <div className="wiz-step-header__wrapper">
                    <div className="wiz-step-header">
                        <div className="wiz-step-title__wrapper">
                            <p className="wiz-step-desc">Good luck with onboarding your first
                                business. click “Done” buttons to remove this section from the
                                screen. or this will automatically go away after couple hours.
                                you always can add another Business in settings screen.</p>
                        </div>
                    </div>
                </div>
                <div className="wiz-step-header__wrapper">
                    <button className="btn btn-secondary">Done</button>
                </div>
            </div>
        </div>
    )
}

export { DoneButtonComponent }