/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Button, Form, Input } from 'antd';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

type Props = {
    createGwsCustomer: (values: any) => any
}

const CreateCustomer = ({ createGwsCustomer }: Props) => {

    return (
        <React.Fragment>
            <div>
                <div className="card-body gws__body">
                    <h4 className="gws-title">Create Google Workspace Customer</h4>
                    <Form
                        {...layout}
                        name="create-customer"
                        style={{ maxWidth: 600, marginInline: 'auto', textAlign: 'left' }}
                        initialValues={{ remember: true }}
                        onFinish={createGwsCustomer}
                        autoComplete="off"
                        labelAlign={"left"}
                    >
                        <Form.Item
                            label="Contact Name"
                            name="contactName"
                            rules={[
                                {
                                    required: true,
                                    message: 'This is Mandatory Field, You can\'t keep this as empty.',
                                },
                                {
                                    pattern: new RegExp(/^[a-zA-Z ]*$/),
                                    message: 'Please enter a valid name.',
                                },
                                {
                                    max: 50,
                                    message: 'Maximum length is exceeded.',
                                }
                            ]}
                        >
                            <Input placeholder="Type the contact name" />
                        </Form.Item>
                        <Form.Item
                            label="Email Address"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'This is Mandatory Field, You can\'t keep this as empty.',
                                },
                                {
                                    pattern: new RegExp(/^[\w.+\-]+@gmail\.com$/),
                                    message: 'Please enter a valid gmail address.',
                                },
                            ]}
                        >
                            <Input placeholder="Type the gmail address" />
                        </Form.Item>
                        <Form.Item
                            label="Organization Name"
                            name="organization"
                            rules={[
                                {
                                    required: true,
                                    message: 'This is Mandatory Field, You can\'t keep this as empty.',
                                },
                                {
                                    max: 100,
                                    message: 'Maximum length is exceeded.',
                                }
                            ]}
                        >
                            <Input placeholder="Type the organization name" />
                        </Form.Item>
                        <Form.Item
                            label="Postal Code"
                            name="postalCode"
                            rules={[
                                {
                                    required: true,
                                    message: 'This is Mandatory Field, You can\'t keep this as empty.',
                                },
                                {
                                    pattern: new RegExp(/^[0-9]*$/),
                                    message: 'Please enter a valid postal code',
                                },
                                {
                                    min: 5,
                                    message: 'Postal code is too short.',
                                },
                                {
                                    max: 5,
                                    message: 'Postal code is too long.',
                                }
                            ]}
                        >
                            <Input placeholder="Type the postal code" />
                        </Form.Item>
                        <Form.Item {...tailLayout} className={"wizard-action"}>
                            <Button type="primary" htmlType="submit" className={"btn btn-primary btn-sm"}>
                                Next
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    );
}

export default CreateCustomer;