import DynamicFormSlice from "../reducers/DynamicFormSlice";
import { message } from "antd";
import * as Constants from "../../constants";
import axiosInstance from "../../axios-instance";
import { getEmojotWorkflowDetails } from '../../services/widgets.service';
import {supportNavigation} from "./app-wizard.action";

export const resetDynamicRedux = () => {
    return (dispatch) => {
        dispatch(DynamicFormSlice.actions.resetDynamicRedux());
    };
};

export const dynamicWidgetLoading = () => {
    return (dispatch) => {
        dispatch(DynamicFormSlice.actions.widgetLoading());
    };
};

export const dynamicLoadingStop = () => {
    return (dispatch) => {
        dispatch(DynamicFormSlice.actions.loadingStop());
    };
};

/**
 * Store configurations
 * @author Eshan
 * @param configuration
 * @returns {function(*): void}
 */
export const storeWidgetConfiguration = (configuration) => {
    return (dispatch) => {
        dispatch(DynamicFormSlice.actions.save());
        dispatch(DynamicFormSlice.actions.submitting(configuration));
    }
};

/**
 * @des fetch widget JSON according to the widgetId
 * @author Buwaneka
 * @param widgetId
 * @returns {function(*): void}
 */
export const getDynamicWidget = (widgetId, type) => {
    return (dispatch) => {
        dispatch(DynamicFormSlice.actions.widgetLoading());
        axiosInstance
            .get(`${Constants.REACT_APP_BACKEND_URL}/widgets/common/${widgetId}/${type}`)
            .then(response => {
                dispatch(DynamicFormSlice.actions.fetchWidget(response.data.data));
            })
            .catch(error => {
                dispatch(DynamicFormSlice.actions.loadingStop());
            })
    }
};

/**
 * @des Submit widget data
 * @author Buwaneka
 * @param body
 * @param formSubmitUrl
 * @param tenantId
 * @param widgetId
 * @returns {function(*): void}
 */
export const commonPostDispatch = (body, formSubmitUrl, tenantId, widgetId, redirectToList, redirectTo, navigate) => {
    return (dispatch) => {
        dispatch(DynamicFormSlice.actions.commonFormSubmittingLoading());
        axiosInstance
            .post(`${Constants.REACT_APP_NODE_BACKEND_URL}${formSubmitUrl}/${tenantId}/${widgetId}`, body)
            .then(response => {
                message.success(`Request created successfully`);
                dispatch(DynamicFormSlice.actions.commonFormSubmitting());
                if (redirectToList == true) {
                    navigate(`/lead-widget-details/${widgetId}/list`);
                }
            })
            .catch(error => {
                dispatch(DynamicFormSlice.actions.submitting());
                if (error.response.status === 404) {
                    message.error(`Oops!, 😥 404 - Resource Not Found`);
                }
                if (error.response.status === 500) {
                    message.error(error.response.data.error);
                }
            })
    }
};

/**
 * @des fetch all configuration according to the tenantId & widgetId
 * @author Buwaneka
 * @param tenantId
 * @param widgetId
 * @returns {function(*): void}
 */

export const fetchAllConfigs = (url, tenantId, widgetId) => {
    return (dispatch) => {
        dispatch(DynamicFormSlice.actions.widgetConfigListLoading());
        axiosInstance
            // .get(`https://run.mocky.io/v3/23b9cdbe-876e-4b1d-b377-a764c401c10d`)
            .get(`${Constants.REACT_APP_NODE_BACKEND_URL}${url}/${tenantId}/${widgetId}`)
            .then(response => {
                dispatch(DynamicFormSlice.actions.widgetConfigList(response.data.data));
            })
            .catch(error => {
                dispatch(DynamicFormSlice.actions.loadingStop());
            })
    }
};

export const fetchOneConfig = (tenantId, widgetId, uuid, formGetUrl) => {
    return axiosInstance
        .get(`${Constants.REACT_APP_NODE_BACKEND_URL}${formGetUrl}/${uuid}`)
    // .get(`${Constants.REACT_APP_BACKEND_URL}/api/widget/crbt/config/one/${uuid}`)
    // .get(`${Constants.REACT_APP_BACKEND_URL}/api/widgets/commonWidget/singleConfig/${uuid}`)
    // .get(`${Constants.REACT_APP_BACKEND_URL}/api/widget/${tenantId}/${widgetId}/${uuid}`)
    // .get(`https://run.mocky.io/v3/7aee62cd-68f0-4598-8a5a-01995de3f484`)
    // .get(`https://run.mocky.io/v3/4a912726-c5a4-4dd5-b6a3-755267bd56af`)
    // .get(`https://run.mocky.io/v3/d03d770c-fbb9-413e-ad6e-a5c2a24503ce`)
    // .get(`https://run.mocky.io/v3/3ca99f67-e6b0-417f-8dc6-33fe3965724b`) //Upload new number base
    // .get(`https://run.mocky.io/v3/16a73286-47db-413c-bf7f-e2df2fe4d9a6`) //Upload new number base
};

export const fetchListDataForMPSelect = (url, tenantId, widgetId) => {
    return (dispatch) => {
        dispatch(DynamicFormSlice.actions.widgetConfigListLoading());
        axiosInstance
            .get(`${Constants.REACT_APP_BACKEND_URL}${url}/${tenantId}/${widgetId}`)
            .then(response => {
                dispatch(DynamicFormSlice.actions.listDataForMPSelect(response.data));
            })
            .catch(error => {
                dispatch(DynamicFormSlice.actions.loadingStop());
            })
    }
};

export const fetchListDataForMPAudioUpload = (url, tenantId, widgetId) => {
    return (dispatch) => {
        dispatch(DynamicFormSlice.actions.widgetConfigListLoading());
        axiosInstance
            .get(`${Constants.REACT_APP_NODE_BACKEND_URL}${url}/${tenantId}/${widgetId}`)
            .then(response => {
                dispatch(DynamicFormSlice.actions.listDataForMPAudioUpload(response.data));
            })
            .catch(error => {
                dispatch(DynamicFormSlice.actions.loadingStop());
            })
    }
};

export const postDataForMPUpload = (body, config, tenantId, widgetId) => {
    return (dispatch) => {
        dispatch(DynamicFormSlice.actions.widgetConfigListLoading());
        axiosInstance
            .post(`${Constants.REACT_APP_BACKEND_URL}/api/widget/crbt/tone/${tenantId}/${widgetId}`, body, config)
            .then(response => {
                dispatch(DynamicFormSlice.actions.widgetLoading());
            })
            .catch(error => {
                dispatch(DynamicFormSlice.actions.loadingStop());
            })
    }
};

export const validateAudioClip = (file) => {
    return (dispatch) => {
        console.log(file);
        let body = {
            base64: file
        };
        const config = {
            headers: { "Content-Type": "application/json" }
        };
        axiosInstance
            .post(`${Constants.REACT_APP_BACKEND_URL}/api/widget/crbt/tone`, JSON.stringify(body), config)
            .then(response => {
                dispatch(DynamicFormSlice.actions.listDataForMPAudioUpload(response.data));
                console.log(response)
            })
            .catch(error => {
                dispatch(DynamicFormSlice.actions.loadingStop());
            })
    }
};

export const fetchEmojotWorkflowDetails = (tenantId, widgetId, configId) => {
    return (dispatch) => {
        dispatch(DynamicFormSlice.actions.workflowLoading());
        getEmojotWorkflowDetails(tenantId, widgetId, configId)
            .then(response => {
                dispatch(DynamicFormSlice.actions.workflow(response.data.data));
            })
            .catch(error => {
                dispatch(DynamicFormSlice.actions.workflowLoadingStop());
            })
    }
};
export const postManualCharge = (uuid, tenantUUId) => {
    return (dispatch) => {
        const config = {
            headers: { "Content-Type": "application/json" }
        };
        dispatch(DynamicFormSlice.actions.widgetConfigListLoading());
        axiosInstance
            .post(`${Constants.REACT_APP_BACKEND_URL}/api/widget/crbt/renewSubscription/${tenantUUId}/${uuid}`, {}, config)
            .then(response => {
                dispatch(DynamicFormSlice.actions.manualCharge(response));
            })
            .catch(error => {
                dispatch(DynamicFormSlice.actions.manualCharge(error.response));
            })
    }
};

export const validateToneName = (tenantUUId, name) => {
    console.log('validateToneName')
    console.log(tenantUUId)
    console.log(name)

    axiosInstance
        .get(`${Constants.REACT_APP_BACKEND_URL}/api/widget/crbt/tone/validate-tone-name/${tenantUUId}/${name}`)
        .then(response => {
            console.log(response.data.data.message)
            if (response.data.data.message === 'Taken') {
                message.error(`Tone name is already taken`);
            }
            // dispatch(DynamicFormSlice.actions.commonFormSubmitting());

        })
        .catch(error => {

        })

};

export const retryCharging = (configId, tenantUUId) => {
    return (dispatch) => {
        const config = {
            headers: { "Content-Type": "application/json" }
        };
        dispatch(DynamicFormSlice.actions.widgetConfigListLoading());
        axiosInstance
            .put(`${Constants.REACT_APP_NODE_BACKEND_URL}/widget/crbt/configRetryCharge/${tenantUUId}/${configId}`, {}, config)
            .then(response => {
                dispatch(DynamicFormSlice.actions.retryCharge(response));
            })
            .catch(error => {
                dispatch(DynamicFormSlice.actions.retryCharge(error.response));
            })
    }
};

export const fetchWidgetPackages = (widgetUuid) => {
    return (dispatch) => {
        dispatch(DynamicFormSlice.actions.widgetPackageListFetch());
        axiosInstance
            .get(`${Constants.REACT_APP_NODE_BACKEND_URL}/widgets/commonWidget/packageList/${widgetUuid}`)
            .then(response => {
                dispatch(DynamicFormSlice.actions.widgetPackageListFetchSuccess(response.data));
            })
            .catch(error => {
                dispatch(DynamicFormSlice.actions.widgetPackageListFetchFailed());
            })
    }
};

export const postCommonWidgetCharging = (enterpriseId, widgetId, formData, widgetDetails, navigate,values,formSubmitUrl, redirectToList, signupUrl, singleAppDetails) => {
    return async (dispatch) => {
        dispatch(DynamicFormSlice.actions.commonFormSubmittingLoading());
        axiosInstance
            .post(`${Constants.REACT_APP_BACKEND_URL}/widgets/sub-revamp/${enterpriseId}/${widgetId}`, formData)
            .then(response => {
                if (response.status === 200 || response.status === 201) {
                    dispatch(formDataPostDispatch(values, formSubmitUrl, enterpriseId, widgetId, redirectToList, signupUrl, navigate, singleAppDetails, widgetDetails.isApprovalRequired));
                }
            })
            .catch(error => {
                dispatch(DynamicFormSlice.actions.submitting());
                if (error.response.status === 404) {
                    message.error(`Oops!, 😥 404 - Resource Not Found`);
                }
                if (error.response.status === 500) {
                    message.error(error.response.data.error);
                }
            })
    }
};

export const formDataPostDispatch = (body, formSubmitUrl, tenantId, widgetId, redirectToList, redirectTo, navigate, singleAppDetails, isApprovalRequired) => {
    return (dispatch) => {
        dispatch(DynamicFormSlice.actions.commonFormSubmittingLoading());
        axiosInstance
            .post(`${Constants.REACT_APP_NODE_BACKEND_URL}/widgets/commonWidget/subData/${widgetId}/${tenantId}`,body)
            .then(response => {
                // dispatch(DynamicFormSlice.actions.commonFormSubmitting());
                // if (isApprovalRequired) {
                //     dispatch(supportNavigation(tenantId, widgetId, navigate, singleAppDetails));
                // } else {
                //     message.success(`You have successfully subscribed for this widget`);
                //     navigate(`/dynamic-widget/${widgetId}/list`);
                //     dispatch(sendSubscriptionNotification(tenantId, widgetId, singleAppDetails, navigate));
                // }
                dispatch(sendSubscriptionNotification(tenantId, widgetId, singleAppDetails, navigate));
            })
            .catch(error => {
                dispatch(DynamicFormSlice.actions.submitting());
                if (error.response?.status === 404) {
                    message.error(`Oops!, 😥 404 - Resource Not Found`);
                }
                if (error.response?.status === 500) {
                    message.error(error.response.data.error);
                }
            })
    }
};

export const sendSubscriptionNotification = (tenantId,widgetId, singleAppDetails, navigate) => {
    return (dispatch) => {
        axiosInstance
            .post(`${Constants.REACT_APP_NODE_BACKEND_URL}/widgets/newCommonWidget/sendNotification/${widgetId}/${tenantId}`)
            .then(response => {
                dispatch(DynamicFormSlice.actions.commonFormSubmitting());
                message.success(`You have successfully created the request`);
                navigate(`/dynamic-widget/${widgetId}/list`);
            })
            .catch(error => {
                if (error.response?.status === 404) {
                    console.log(`Oops!, 😥 404 - Resource Not Found`);
                }
                if (error.response?.status === 500) {
                    console.log(error.response.data.error);
                }
            })
    }
};

export const fetchBillingHistory = (widgetId, tenantId) => {
    return (dispatch) => {
        dispatch(DynamicFormSlice.actions.dynamicWidgetBillingHistoryLoading());
        axiosInstance
            .get(`${Constants.REACT_APP_NODE_BACKEND_URL}/widgets/newCommonWidget/billing/${widgetId}/${tenantId}`)
            .then(response => {
                dispatch(DynamicFormSlice.actions.dynamicWidgetBillingHistory(response.data));
            })
            .catch(error => {
                dispatch(DynamicFormSlice.actions.dynamicWidgetBillingHistoryFailed());
            })
    }
};

export const fetchDynamicSubData = (widgetId, tenantId) => {
    return (dispatch) => {
        dispatch(DynamicFormSlice.actions.dynamicSubscriptionDataLoading());
        axiosInstance
            .get(`${Constants.REACT_APP_NODE_BACKEND_URL}/widgets/newCommonWidget/subDetails/${widgetId}/${tenantId}`)
            .then(response => {
                dispatch(DynamicFormSlice.actions.dynamicSubscriptionData(response.data));
            })
            .catch(error => {
                dispatch(DynamicFormSlice.actions.dynamicSubscriptionDataFailed());
            })
    }
};