/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { CSSProperties, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import { fetchTracklist, fetchSingleApp, fetchTrackHistory } from '../../../store/actions';
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from '@emotion/react';
import AuthHandler from '../../../helper/AuthHandler';
import TableIcon from '../../../assets/images/icons/app-media-placeholder.svg';
import { PageHeaderStatic } from "../components/PageHeaderStatic";
import moment from "moment";
import NoData from "../../settings/components/NoData";

const override: CSSProperties = {
    display: "block",
    margin: "0 auto"
};

const LocationLogs = ({ auth }: any) => {
    const { wid, configId } = useParams();
    const [logList, setLogList] = useState<any[]>([]);
    const [limit, setLimit] = useState<number>(10);
    const [offset, setOffset] = useState<number>(0);
    const [address, setAddress] = useState<string>('');
    const [lat, setLat] = useState<string>('');
    const [lng, setLng] = useState<string>('');
    const [radius, setRadius] = useState<number>(0);

    const navigate = useNavigate();

    const {
        singleAppDetails
    } = useSelector(({ apps }: any) => apps);
    const { success, loading, error, trackHistory } = useSelector((state: any) => state.location);
    const { userDetails } = useSelector(({ user }: any) => user);
    const [initialDataLoaded, setInitialDataLoaded] = useState(false);

    const dispatch = useAppDispatch();

    useEffect(() => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        dispatch(fetchSingleApp(wid!, tenantGUID));
    }, [wid, userDetails]);

    useEffect(() => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        if (initialDataLoaded) {
            dispatch(fetchTrackHistory(tenantGUID, configId!, limit, offset));
        }
    }, [initialDataLoaded, offset]);

    useEffect(() => {
        if (trackHistory && Array.isArray(trackHistory.data)) {
            if (trackHistory.data.length !== 0) {
                if (Array.isArray(trackHistory.data[0].Log)) {
                    setLogList([...logList, ...trackHistory.data[0].Log]);
                }
            }
            setAddress(trackHistory.data[0].location);
            setLat(trackHistory.data[0].latitude);
            setLng(trackHistory.data[0].longitude);
            setRadius(trackHistory.data[0].radius);
        }
    }, [trackHistory]);

    useEffect(() => {
        if (singleAppDetails && userDetails) {
            setInitialDataLoaded(true);
        }
    }, [singleAppDetails, userDetails]);

    const onViewMore = () => {
        setOffset(offset + 10);
    }

    const renderTrackList = () => {
        if (success) {
            return (
                <div className="section__wrapper">
                    <div className="card panel content-holder-panels">
                        <div className="panel-header">
                            <div className="panel-header-content__wrapper">
                                <h4 className="panel-header-title">List of Trackings</h4>
                                {/* <p className="panel-header-subtitle">List of </p> */}
                            </div>
                        </div>
                        <div className="panel-table-listing__wrapper">
                            <div className="table-parent__wrapper">
                                <table className="table table-default">
                                    <tbody>
                                        <tr className="table-header">
                                            <th className="txt-left">Expected address</th>
                                            <th className="txt-left">Expected location</th>
                                            <th className="txt-left">Current location</th>
                                            <th className="txt-left">Tracked time</th>
                                            <th className="txt-left">In or Out</th>
                                            <th className="txt-left">Status</th>
                                            <th className="txt-left">Remarks</th>
                                            <th className="txt-left">Paid amount</th>
                                            <th className="txt-left">Tax</th>
                                            <th className="txt-left">Payment method</th>
                                        </tr>
                                        {logList.length > 0 ? logList.map((value: any, index: number) => {
                                            return (
                                                <tr className="table-row ">
                                                    <td>
                                                        <div className="feature-details__wrapper">
                                                            <div
                                                                className="card app-media-holder"
                                                                style={{
                                                                    backgroundImage: `url(${TableIcon})`
                                                                }}
                                                            ></div>
                                                            <div className="feature-details-title__wrapper">
                                                                <span className="title">{address !== '' ? address : '-'}</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="txt-left">{lat}, {lng}</td>
                                                    <td className="txt-left">{value.Payment !== null && value.currentLatitude + ', ' + value.currentLongitude}</td>
                                                    <td className="txt-left">{moment(value.trackedAt).format('YYYY-MM-DD hh:mm:ss A')}</td>
                                                    <td className="txt-left">{value.Payment !== null && value.inOrOut}</td>
                                                    <td className="txt-left">
                                                        {value.status}
                                                    </td>
                                                    <td className="txt-left">
                                                        {value.remarks === null ? '-' : value.remarks === '' ? '-' : value.remarks}
                                                    </td>
                                                    <td className="txt-left">
                                                        {value.Payment !== null ? parseFloat(value.Payment.amount).toFixed(2) : ''}
                                                    </td>
                                                    <td className="txt-left">
                                                        {value.Payment !== null ? parseFloat(value.Payment.tax).toFixed(2) : ''}
                                                    </td>
                                                    <td className="txt-left">
                                                        {value.Payment !== null && value.Payment.PaymentMethod !== null && value.Payment.PaymentMethod.title ? value.Payment.PaymentMethod.title : '-'}
                                                    </td>
                                                    <td className="txt-left">
                                                        {value.status === 'SMS SENT' && <Link to={`/location/${wid}/logs/${configId}/maplocation/${lng}&${lat}&${radius}&${value.currentLongitude}&${value.currentLatitude}`} target="_blank">
                                                            <i className="bi bi-map"></i>
                                                        </Link>}
                                                    </td>
                                                </tr>
                                            )
                                        }) : <tr className="table-row ">
                                            <td colSpan={10}>
                                                {/* <NoData></NoData> */}
                                            </td>
                                        </tr>}
                                        <tr className="table-row">
                                            <td>
                                                <a onClick={onViewMore}>View All</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else if (loading) {
            return (
                <div className="section__wrapper">
                    <div className="empty-state-new-action__wrapper">
                        <section className="content__wrapper">
                            <div className="primaryBrandLoaderBg">
                                <div className="primaryBrandLoader">
                                    <img src={require('../../../assets/images/loader-bg-primary.svg').default} alt="" srcSet="" />
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            )
        } else if (error) {
            return (
                <div className="section__wrapper">
                    <div className="empty-state-new-action__wrapper">
                        <div className="new-action-content__wrapper">
                            <h4 className="sub">Oops, something went wrong!</h4>
                        </div>
                    </div>
                </div>
            )
        }
    }

    if (AuthHandler.isAuthorized(auth, 'tracklist') && userDetails !== null) {
        return (
            <React.Fragment>
                <div className="main-content main-spacer-x-y">
                    <PageHeaderStatic
                        page={'logs'}
                    />
                    {renderTrackList()}
                </div>

            </React.Fragment>
        );
    } else return (<React.Fragment></React.Fragment>);
}

export default LocationLogs;