import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface OffersSliceModel {
    loading: boolean,
    success: boolean,
    error: boolean,
    allOffersList: any,
    eligibleOffersList: any,
    claimedOffersList: any[],
}

const offersStore = createSlice({
    name: "offers-store",
    initialState: {
      loading: false,
      success: false,
      error: false,
      allOffersList: null,
      eligibleOffersList: null,
      claimedOffersList: []
    } as OffersSliceModel,
    reducers: {
      allOffersListFetchSuccess: (state: OffersSliceModel, action: PayloadAction<any>) => {
        console.log("allOffersList", action.payload)
        return {
          ...state,
          allOffersList: action.payload,
          loading: false
        }
      },
      allOffersListFetchFailed: (state: OffersSliceModel) => {
        return {
          ...state,
          loading: false
        }
      },
      eligibleOffersListFetchSuccess: (state: OffersSliceModel, action: PayloadAction<any>) => {
        return {
          ...state,
          eligibleOffersList: action.payload,
          loading: false
        }
      },
      eligibleOffersListFetchFailed: (state: OffersSliceModel) => {
        return {
          ...state,
          loading: false
        }
      },
      claimedOffersListFetchSuccess: (state: OffersSliceModel, action: PayloadAction<any>) => {
        return {
          ...state,
          claimedOffersList: action.payload,
          loading: false
        }
      },
      claimedOffersListFetchFailed: (state: OffersSliceModel) => {
        return {
          ...state,
          loading: false
        }
      },
    },
});

export default offersStore;