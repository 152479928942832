import {
    getTenantMasksByType, getMsisdnList, getDistricts, getTenantMasks, getEntConfigs,
} from "../../services/enterprise.service";
import {message} from "antd";
import enterpriseStore from "../reducers/enterprise.store";

export const fetchTenantMasksByType = (tenantId: string, maskType: string): any => {
    return (dispatch: any) => {
        dispatch(enterpriseStore.actions.masksListFetch());
        getTenantMasksByType(tenantId, maskType)
            .then((response: any) => {
                dispatch(enterpriseStore.actions.masksListFetchSuccess(response.data));
            })
            .catch((error: any) => {
                message.error("Error occurred while fetching masks list.");
                dispatch(enterpriseStore.actions.masksListFetchFail());
            });
    }
};

export const fetchMsisdnsList = (tenantId: string, widgetId: string): any => {
    return (dispatch: any) => {
        dispatch(enterpriseStore.actions.msisdnsListFetch());
        getMsisdnList(tenantId, widgetId)
            .then((response: any) => {
                dispatch(enterpriseStore.actions.msisdnsListFetchSuccess(response.data));
            })
            .catch((error: any) => {
                message.error("Error occurred while fetching msisdns list.");
                dispatch(enterpriseStore.actions.msisdnsListFetchFail());
            });
    }
};

export const fetchDistricts = (): any => {
    return (dispatch: any) => {
        dispatch(enterpriseStore.actions.districtListFetch());
        getDistricts()
            .then((response: any) => {
                dispatch(enterpriseStore.actions.districtListFetchSuccess(response.data));
            })
            .catch((error: any) => {
                dispatch(enterpriseStore.actions.districtListFetchFail());
            });
    }
};

export const fetchTenantMasks = (tenantId: string): any => {
    return (dispatch: any) => {
        dispatch(enterpriseStore.actions.tenantMasksListFetch());
        getTenantMasks(tenantId)
            .then((response: any) => {
                dispatch(enterpriseStore.actions.tenantMasksListFetchSuccess(response.data));
            })
            .catch((error: any) => {
                message.error("Error occurred while fetching tenant masks list.");
                dispatch(enterpriseStore.actions.tenantMasksListFetchFail());
            });
    }
};

export const fetchEntConfigs = (tenantId: string): any => {
    return (dispatch: any) => {
        dispatch(enterpriseStore.actions.entConfigsFetch());
        getEntConfigs(tenantId)
            .then((response: any) => {
                dispatch(enterpriseStore.actions.entConfigsFetchSuccess(response.data));
            })
            .catch((error: any) => {
                message.error("Error occurred while fetching enterprise configs list.");
                dispatch(enterpriseStore.actions.entConfigsFetchFail());
            });
    }
};