import Auth from "../../models/response/auth.model";
import authenticationStore from "../reducers/authentication.store";

export const submitUserLoggedIn = (): any => {
    return (dispatch: any) => {
        dispatch(authenticationStore.actions.userLoggedIn(true));
    }
}
export const submitUserLoggedOut = (): any => {
    return (dispatch: any) => {
        dispatch(authenticationStore.actions.userLoggedIn(false));
    }
}

export const setAuthInStore = (auth: Auth): any => {
    // console.log("kkkkkkkkkkkkkkk", auth);
    return (dispatch: any) => {
        dispatch(authenticationStore.actions.setAuth(auth));
    }
}

export const setAuthenticatedInStore = (authenticated: any): any => {
    // console.log("kkkkkkkkkkkkkkk", authenticated);
    return (dispatch: any) => {
        dispatch(authenticationStore.actions.setAuthenticated(authenticated));
    }
}

export const setKeycloakInStore = (keycloak: any): any => {
    // console.log("kkkkkkkkkkkkkkk", keycloak);
    return (dispatch: any) => {
        dispatch(authenticationStore.actions.setKeycloak(keycloak));
    }
}

export const removeAuth = (): any => {
    // console.log("kkkkkkkkkkkkkkk", keycloak);
    return (dispatch: any) => {
        dispatch(authenticationStore.actions.removeAuth());
    }
}

export const closeNoticeBanner = (): any => {
    return (dispatch: any) => {
        dispatch(authenticationStore.actions.closeNoticeBanner());
    }
};


