import gwsStore from "../reducers/gws-store";
import {
    getConfigData,
    postGWSDomain,
    postUser,
    postVerify,
    getAllConfigs,
    getUserLicenseCount,
    deleteDomain,
    postCustomer,
    putVerifyDomain

} from "../../services/gws-service";

export const fetchConfigData = (tenantUuid: string, configUuid: string): any => {
    return (dispatch: any) => {
        dispatch(gwsStore.actions.gwsConfigFetch());

        getConfigData(tenantUuid, configUuid)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(gwsStore.actions.gwsConfigFetchSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(gwsStore.actions.gwsConfigFetchFail(error));
            });
    }
};

export const addDomain = (request_payload: any, tenantUuid: string, widgetUuid: string): any => {
    return (dispatch: any) => {
        dispatch(gwsStore.actions.gwsDomainPost());

        postGWSDomain(request_payload, tenantUuid, widgetUuid)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(gwsStore.actions.gwsDomainPostSuccess(response.data));
                }
            })
            .catch((error) => {
                console.log(error)
                dispatch(gwsStore.actions.gwsDomainPostFail(error.response?.data?.error));
            });
    }
};

export const createGWSUser = (request_payload: any, configUuid: string, tenantUuid: string, widgetUuid: string): any => {
    return (dispatch: any) => {
        dispatch(gwsStore.actions.gwsUserPost());
        postUser(request_payload, configUuid, tenantUuid, widgetUuid)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(gwsStore.actions.gwsUserPostSuccess(response.data ? response.data : 'User created successfully'));
                }
            })
            .catch((error) => {
                dispatch(gwsStore.actions.gwsUserPostFail(error.response?.data?.error));
            });
    }
};

export const createGWSCustomer = (request_payload: any, configUuid: string, tenantUuid: string, widgetUuid: string): any => {
    return (dispatch: any) => {
        dispatch(gwsStore.actions.gwsCustomerPost());
        postCustomer(request_payload, configUuid, tenantUuid, widgetUuid)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(gwsStore.actions.gwsCustomerPostSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(gwsStore.actions.gwsCustomerPostFail(error.response?.data?.error));
            });
    }
};

export const verifyDomain = (request_payload: any, tenantUuid: string, widgetUuid: string, configUuid: string): any => {
    return (dispatch: any) => {
        dispatch(gwsStore.actions.gwsDomainVerification());
        postVerify(request_payload, tenantUuid, widgetUuid, configUuid)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(gwsStore.actions.gwsDomainVerificationSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(gwsStore.actions.gwsDomainVerificationFail(error.response?.data?.error));
            });
    }
};

export const fetchAllGwsConfigs = (tenantUuid: string, widgetUuid: string): any => {
    return (dispatch: any) => {
        dispatch(gwsStore.actions.gwsAllConfigFetch());
        getAllConfigs(tenantUuid, widgetUuid)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(gwsStore.actions.gwsAllConfigFetchSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(gwsStore.actions.gwsAllConfigFetchFail(error));
            });
    }
};

export const fetchLicenseCount = (tenantUuid: string, configUuid: string): any => {
    return (dispatch: any) => {
        dispatch(gwsStore.actions.gwsLicenseCount());
        getUserLicenseCount(tenantUuid, configUuid)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(gwsStore.actions.gwsLicenseCountSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(gwsStore.actions.gwsLicenseCountFail(error));
            });
    }
};

export const deleteDomainById = (tenantUuid: string, widgetUuid: string, configUuid: string): any => {
    return (dispatch: any) => {
        dispatch(gwsStore.actions.gwsDomainDelete());
        deleteDomain(tenantUuid, widgetUuid, configUuid)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(gwsStore.actions.gwsDomainDeleteSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(gwsStore.actions.gwsDomainDeleteFail(error));
            });
    }
};

export const verifyGwsDomainFinal = (request_payload: any, tenantUuid: string, widgetUuid: string, configUuid: string): any => {
    return (dispatch: any) => {
        dispatch(gwsStore.actions.gwsDomainVerify());
        putVerifyDomain(request_payload, tenantUuid, widgetUuid, configUuid)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(gwsStore.actions.gwsDomainVerifySuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(gwsStore.actions.gwsDomainVerifyFail(error.response?.data?.error));
            });
    }
};

export const resetGwsStatuses = (): any => {
    return (dispatch: any) => {
        dispatch(gwsStore.actions.gwsResetStatuses());
    }
};
