import React from 'react'

type Props = {}

const Agreement = (props: Props) => {
    return (
        <div className="scroller__wrapper scroller__wrapper__container">
            <div className="terms__content">
                <h2 className="agreement__header">TERMS OF SERVICE AGREEMENT</h2>
                <p className="agreement__purpose__paragraph">PLEASE READ THIS TERMS OF SERVICE AGREEMENT CAREFULLY. BY USING THIS WEBSITE OR ORDERING PRODUCTS FROM THIS WEBSITE YOU AGREE TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT.</p>
                <p className="agreement__header__content__paragraph">This Terms of Service Agreement (the "Agreement") governs your use of this website,<a className="marketplace__agreement__links" target="_blank" href="https://marketplace.ideabiz.lk/">[https://marketplace.ideabiz.lk/]</a>(the "Website"), Dialog Axiata PLC ("Dialog") provide of products for purchase on this Website, or your purchase of products available on this Website. This Agreement includes, and incorporates by this reference, the policies and guidelines referenced below. Dialog reserves the right to change or revise the terms and conditions of this Agreement at any time by posting any changes or a revised Agreement on this Website without prior notice to you. The changed or revised Agreement will be effective immediately after it is posted on this Website. Your use of the Website following the posting any such changes or of a revised Agreement will constitute your acceptance of any such changes or revisions. Dialog encourages you to review this Agreement whenever you visit the Website to make sure that you understand the terms and conditions governing use of the Website. This Agreement does not alter in any way the terms or conditions of any other written agreement you may have with Dialog for other products or services. If you do not agree to this Agreement (including any referenced policies or guidelines), please immediately terminate your use of the Website.</p>

                <ol className="tnc__container" type="I">
                    <li className="sub__tnc__title">PRODUCTS & SERVICES
                        <p className="agreement__content__paragraph" >This Website provides for purchase certain products (the "Products") and services including testing & commissioning, maintenance, training, consultancy (the “Services”). By placing an order for Products and/or Services through this Website, you agree to the terms set forth in this Agreement.</p>
                        <p className="agreement__content__paragraph">Proprietary Rights: Dialog and/or it’s Partners may have proprietary rights and trade secrets in the Products/Services. You may not copy, reproduce, resell or redistribute any Product manufactured and/or distributed by Dialog or any Product available in the Website. Dialog and/or it’s Partners also has rights to all trademarks and trade dress and specific layouts of this webpage, including calls to action, text placement, images and other information.</p>
                        <p className="agreement__content__paragraph">Sales Tax: If you purchase any Products/Services, you will be responsible for paying any applicable sales tax.</p>
                    </li>
                    <li className="sub__tnc__title">PAYMENT
                        <p className="agreement__content__paragraph" >You shall make payments for the Products and/or Services via Add to Bill and other payment methods (eZCash, Genie, Credit Cards and Debit Cards).</p>
                        <p className="agreement__content__paragraph" >In the event the Services include “SMS Voting”, Dialog shall share a Forty percent (40%) revenue share with the enterprise.</p>
                        <p className="agreement__content__paragraph" >Selected Services shall be invoiced on a monthly basis by Dialog and the enterprise shall settle all outstanding payments within Thirty (30) days from the date of the invoice.</p>
                        <p className="agreement__content__paragraph" >All payments shall be in Sri Lankan Rupees.</p>
                        <p className="agreement__content__paragraph" >Prices specified on the Website can be revised at the sole discretion of Dialog with no liability to you or any third parties.</p>
                    </li>
                    <li className="sub__tnc__title">WEBSITE
                        <p className="agreement__content__paragraph" >Content, Intellectual Property & Third-Party Links; In addition to making Products available, this Website also provides information and marketing materials. This Website also provides information, both directly and through indirect links to third-party websites of Dialog Partners. Dialog does not always create the information provided on this Website; instead the information is often gathered from other sources including Partners of Dialog. To the extent that Dialog does create the content on this Website, such content is protected by intellectual property laws of the Sri Lanka, foreign nations, and international bodies. Unauthorized use of the material may violate copyright, trademark, and/or other laws. You acknowledge that your use of the content on this Website is for personal, noncommercial use. Any links to third-party websites are provided solely as a convenience to you. Dialog does not endorse the contents on any such third-party websites. Dialog is not responsible for the content of or any damage that may result from your access to or reliance on these third-party websites. If you link to third-party websites, you do so at your own risk.</p>
                        <p className="agreement__content__paragraph" >Use of Website; Dialog is not responsible for any damages resulting from use of this website by anyone. You will not use the Website for illegal purposes. You will (1) abide by all applicable local, state, national, and international laws and regulations in your use of the Website (including laws regarding intellectual property), (2) not interfere with or disrupt the use and enjoyment of the Website by other users, (3) not resell material on the Website, (4) not engage, directly or indirectly, in transmission of "spam", chain letters, junk mail or any other type of unsolicited communication, and (5) not defame, harass, abuse, or disrupt other users of the Website.</p>
                        <p className="agreement__content__paragraph" >License; By using this Website, you are granted a limited, non-exclusive, non-transferable right to use the content and materials on the Website in connection with your normal, noncommercial, use of the Website. You may not copy, reproduce, transmit, distribute, or create derivative works of such content or information without express written authorization from the duly authorized representative of Dialog or r the applicable third party (if third party content is at issue, including Dialog Partners).</p>
                        <p className="agreement__content__paragraph" >Maintenance; Platform maintenance will be done by the Dialog. A 48 hours SLAs and conditions will be applied.</p>
                    </li>
                    <li className="sub__tnc__title">DISCLAIMER OF WARRANTIES
                        <p className="agreement__content__paragraph" >YOUR USE OF THIS WEBSITE AND/OR PRODUCTS AND/OR SERVICES ARE AT YOUR SOLE RISK. THE WEBSITE, PRODUCTS AND SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. DIALOG EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT WITH RESPECT TO THE PRODUCTS AND/OR SERVICES OR WEBSITE CONTENT, OR ANY RELIANCE UPON OR USE OF THE WEBSITE CONTENT OR PRODUCTS. ("PRODUCTS" INCLUDE TRIAL PRODUCTS.)</p>
                        <p className="agreement__content__paragraph" >WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, DIALOG MAKES NO WARRANTY:</p>
                        <p className="agreement__content__paragraph" >THAT THE INFORMATION PROVIDED ON THIS WEBSITE IS ACCURATE, RELIABLE, COMPLETE, OR TIMELY.</p>
                        <p className="agreement__content__paragraph" >THAT THE LINKS TO THIRD-PARTY WEBSITES ARE TO INFORMATION THAT IS ACCURATE, RELIABLE, COMPLETE, OR TIMELY.</p>
                        <p className="agreement__content__paragraph" >NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM THIS WEBSITE WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED HEREIN.</p>
                        <p className="agreement__content__paragraph" >AS TO THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE PRODUCTS OR THAT DEFECTS IN PRODUCTS WILL BE CORRECTED.</p>
                        <p className="agreement__content__paragraph" >REGARDING ANY PRODUCTS AND/OR SERVICES PURCHASED OR OBTAINED THROUGH THE WEBSITE.</p>
                    </li>
                    <li className="sub__tnc__title">LIMITATION OF LIABILITY
                        <p className="agreement__content__paragraph" >DIALOG WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES IN CONNECTION WITH THIS AGREEMENT OR THE PRODUCTS AND/OR SERVICES IN ANY MANNER, INCLUDING LIABILITIES RESULTING FROM (1) THE USE OR THE INABILITY TO USE THE WEBSITE CONTENT OR PRODUCTS AND/OR SERVICES; (2) THE COST OF PROCURING SUBSTITUTE PRODUCTS AND/OR SERVICES OR CONTENT; (3) ANY PRODUCTS AND/OR SERVICES PURCHASED OR OBTAINED OR TRANSACTIONS ENTERED INTO THROUGH THE WEBSITE; OR (4) ANY LOST PROFITS YOU ALLEGE.</p>
                    </li>
                    <li className="sub__tnc__title">INDEMNIFICATION
                        <p className="agreement__content__paragraph" >You will release, indemnify, defend and hold harmless Dialog, and any of its directors, employees, agents, employees, shareholders, affiliates and assigns from all liabilities, claims, damages, costs and expenses, including reasonable attorneys' fees and expenses, of third parties relating to or arising out of (1) this Agreement or the breach of your warranties, representations and obligations under this Agreement; (2) the Website content or your use of the Website content; (3) the Products and/or Services or your use of the Products (including Trial Products); (4) any intellectual property or other proprietary right of any person or entity; (5) your violation of any provision of this Agreement; or (6) any information or data you supplied to Dialog. In the event Dialog is threatened with legal action by a third party, Dialog may seek written assurances from you concerning your promise to indemnify Dialog; your failure to provide such assurances may be considered by Dialog to be a material breach of this Agreement. Dialog will have the right to participate in any defense by you of a third-party claim related to your use of any of the Website content or Products, with counsel of Dialogs’ choice at its expense. You will have sole responsibility to defend Dialog against any claim, but you must obtain Dialog’s prior written consent regarding any related settlement. The terms of this provision will survive any termination or cancellation of this Agreement or your use of the Website or Products.</p>
                    </li>
                    <li className="sub__tnc__title">PRIVACY
                        <p className="agreement__content__paragraph" >Dialog believes strongly in protecting user privacy. Please refer to Dialog’s privacy policy, <a className="marketplace__agreement__links" target="_blank" href="https://dlg.dialog.lk/privacy-notice">https://dlg.dialog.lk/privacy-notice</a>, statement, incorporated by reference herein, that is posted on the Website.</p>
                    </li>
                    <li className="sub__tnc__title">AGREEMENT TO BE BOUND
                        <p className="agreement__content__paragraph" >By using this Website or purchasing Products and/or Services, you acknowledge that you have read and agree to be bound by this Agreement and all terms and conditions on this Website.</p>
                    </li>
                    <li className="sub__tnc__title">GENERAL
                        <p className="agreement__content__paragraph" >Force Majeure; Dialog will not be deemed in default hereunder or held responsible for any cessation, interruption or delay in the performance of its obligations hereunder due to earthquake, flood, fire, storm, natural disaster, act of God, war, terrorism, armed conflict, labor strike, lockout, or boycott or any act beyond the reasonable control of Dialog.</p>
                        <p className="agreement__content__paragraph" >Cessation of Operation; Dialog may at any time, in its sole discretion and without advance notice to you, cease operation of the Website and distribution of the Products.</p>
                        <p className="agreement__content__paragraph" >Entire Agreement; This Agreement comprises the entire agreement between you and Dialog and supersedes any prior agreements pertaining to the subject matter contained herein.</p>
                        <p className="agreement__content__paragraph" >Effect of Waiver; The failure of Dialog to exercise or enforce any right or provision of this Agreement will not constitute a waiver of such right or provision. If any provision of this Agreement is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties' intentions as reflected in the provision, and the other provisions of this Agreement remain in full force and effect.</p>
                        <p className="agreement__content__paragraph" >Governing Law and Jurisdiction; This Agreement will be governed by the laws of Sri Lanka without regard to its conflict of law principles to the contrary</p>
                        <p className="agreement__content__paragraph" >Termination; Dialog reserves the right to terminate your access to the Website if it reasonably believes, in its sole discretion, that you have breached any of the terms and conditions of this Agreement. Following termination, you will not be permitted to use the Website and Dialog may, in its sole discretion and without advance notice to you, cancel any outstanding orders for Products. If your access to the Website is terminated, Dialog reserves the right to exercise whatever means it deems necessary to prevent unauthorized access of the Website. This Agreement will survive indefinitely unless and until Dialog chooses, in its sole discretion and without advance to you, to terminate it.</p>
                        <p className="agreement__content__paragraph" >Assignment. You may not assign your rights and obligations under this Agreement to anyone. Dialog may assign its rights and obligations under this Agreement in its sole discretion and without advance notice to you.</p>
                    </li>
                    <p className="agreement__purpose__paragraph">BY USING THIS WEBSITE OR ORDERING PRODUCTS FROM THIS WEBSITE YOU AGREE TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT.</p>


                </ol>
            </div>
        </div>
    )
}

export default Agreement