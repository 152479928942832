import React, { useEffect, useState, FocusEvent } from 'react';
import { useCollapse } from "react-collapsed";
import { PaymentMethod } from './PaymentMethod';
import { PaymentPackage } from './PaymentPackage';
import { PaymentRetry } from "./PaymentRetry";
import { Button, Form, FormInstance, Input } from 'antd';
import widgetIcon from '../../../assets/images/icons/app-media-placeholder.svg';
import {WidgetTypes} from "../../../enum";

interface Props {
    keyIndex: number
    isExpanded: boolean,
    setExpanded: any,
    expandable: boolean,
    widget: any,
    onChangeMethod: (event: any) => void,
    msisdns: any[],
    cards: any[],
    setMsisdnList: (msisdns: any) => void,
    setDisplayMsisdnModal: (msisdn: any) => void,
    setActiveMsisdn: (msisdn: string) => void,
    activeMsisdn: string,
    setActiveCard: (card: any) => void,
    activeCard: any,
    setDisplayCardModal: (card: any) => void,
    setCardList: (cards: any) => void,
    onFinishSelect: (values: any) => void,
    form: FormInstance<any>,
    onFieldsChange: (changedFields: any, allFields: any, msisdn: string, invoiceNo: string) => void,
    activeMsisdnObj: any,
    setActiveMsisdnObj: (values: any) => void,
    activeCardObj: any,
    setActiveCardObj: (values: any) => void,
    checkIsDisabled: (uuid: string, editable: boolean) => boolean,
    paymentMethodError: any
}

const CardPanelItem = ({ isExpanded, setExpanded, expandable, widget, keyIndex, onChangeMethod, msisdns, cards, setMsisdnList, setDisplayMsisdnModal, setActiveMsisdn, activeMsisdn, setActiveCard, activeCard, setDisplayCardModal, setCardList, onFinishSelect, form, onFieldsChange, activeMsisdnObj, setActiveMsisdnObj, activeCardObj, setActiveCardObj, checkIsDisabled, paymentMethodError }: Props) => {
    // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!", widget);
    // const [form] = Form.useForm()
    const [activeRateCard, setActiveRateCard] = useState<number>(0);
    const [paymentMethod, setPaymentMethod] = React.useState(0);

    const { getToggleProps, getCollapseProps } = useCollapse({
        isExpanded,
        duration: 1000
    });

    useEffect (() => {
        let selectedPackage = widget.WidgetRate.find((value: any) => value.RatePlaneDetails.length > 0);
        if(selectedPackage) {
            setActiveRateCard(selectedPackage.RateCard.id);
        }
        if(widget.WidgetSubscriptions && widget.WidgetSubscriptions.paymentMethodId) {
            setPaymentMethod(widget.WidgetSubscriptions.paymentMethodId);
        }
    }, [widget])

    const chargingArrGet = (widget: any) => {
        let charging = null;
        let ratecards: any[] = [];
        if (widget.ChargeableType != 6) {
            widget !== null && Array.isArray(widget.WidgetRate) && widget.WidgetRate.map((value: any, index: number) => {
                if (value.id === ((value && Array.isArray(value.RatePlaneDetails) && value.RatePlaneDetails.length > 0) ? value.RatePlaneDetails[0].rateDetailId : 0)) {
                    charging = value.uuid;
                }
            })
            return charging;
        } else {
            // item !== null && typeof item.newAvailablePlans !== 'undefined' && item.newAvailablePlans.map(avPlan => {
            //     avPlan.RateCardDetails.map(rateCard => {
            //         if (rateCard.rateType === 'SIMPLE') {
            //             if (rateCard.isSelected) {
            //                 ratecards = rateCard.uuid;
            //             }
            //         } else {
            //             if (rateCard.isSelected) {
            //                 // charging = rateCard.uuid;
            //                 ratecards.push(rateCard.uuid);
            //             }
            //         }
            //     })
            // })
            // let charging = typeof ratecards == 'string' ? ratecards : JSON.stringify(ratecards);

            // return charging
        }
    }

    const getSelectedPaymentMethod = (widget: any) => {
        // if (widget.title === "Where are My Employees-With consent") {
        //     console.log("@@@@@@@@@@@@@", widget.WidgetSubscriptions);
        // }
        if (widget && widget.WidgetSubscriptions && widget.WidgetSubscriptions.paymentMethodId) {
            return widget.WidgetSubscriptions.paymentMethodId;
        }
    }

    const onChangeFields = (changedFields: any) => {
        if (changedFields[0].name[0] === "paymentMethod") {
            setPaymentMethod(changedFields[0].value);
        }
        onFieldsChange(changedFields, widget.uuid, msisdns.find((value) => value.id === widget.WidgetSubscriptions.msisdnId)?.msisdn, cards.find((value) => value.id === widget.WidgetSubscriptions.cardId)?.invoiceNo)
    }

    const checkUserRetryEnable = () => {
        // let selectedPackage = widget.WidgetRate.find((value: any) => value.RatePlaneDetails.length > 0);
        // if (widget.isUserRetryEnable && selectedPackage.RatePlaneDetails[0].RatePlans.status === "Failed" && !widget.WidgetSubscriptions.isSubscriptionActive) {
        if (widget.isUserRetryEnable && !widget.WidgetSubscriptions.isSubscriptionActive) {
            return true
        } else {
            return false
        }
    }

    return (
        <React.Fragment>
            <Form
                // form={form}
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                // style={{ maxWidth: 600 }}
                initialValues={{
                    widgetUuid: widget.uuid,
                    paymentMethod: getSelectedPaymentMethod(widget),
                    paymentPackage: chargingArrGet(widget)
                }}
                onFinish={onFinishSelect}
                // onFinishFailed={onFinishFailed}
                onFieldsChange={onChangeFields}
                autoComplete="off"
                layout='horizontal'
            >
                <div className="panel-table-list-item">
                    <div className="panel-table-listing-item__inner">
                        <div className="feature-details__wrapper">
                            <div
                                className="card app-media-holder"
                                style={{
                                    backgroundImage: widget.thumbnail ? `url(${widget.thumbnail})` : `url(${widgetIcon})`
                                }}
                            ></div>
                            <div className="feature-details-title__wrapper no-shade">
                                <span className="title">
                                    {widget.title}
                                </span>
                                <div className="billing-plans-data">
                                {widget.widgetType === WidgetTypes.SUBSCRIPTION_BASED && <span className="small-desc billing-date">
                                    {(activeRateCard === 8) ? "Bill in Annually" : "Bill in End of the Month" }
                                </span>}
                                {widget.Discounts && widget.Discounts.length > 0 && <label className="discount-badge badge-green">Discount</label>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="panel-table-listing-item__inner">
                        <div className="panel-table-right-align-item__wrapper">
                            {checkUserRetryEnable() && <div className="panel-table-right-align-item">
                                <a href="" className="txt-orange" type="button">
                                    Pending Renewal&nbsp;
                                    <i className="bi bi-info-circle-fill"/>
                                </a>
                            </div>}
                            {widget.isPaymentMethodEditable && <div className="panel-table-right-align-item">
                                <a href="" className="" type="button">
                                    Custom&nbsp;
                                    <i className="bi bi-info-circle-fill"/>
                                </a>
                            </div>}
                            <div className="panel-table-right-align-item">
                                {expandable && <button className="btn btn-small primary-action anime-flip-90"
                                    {...getToggleProps({
                                        onClick: () => {
                                            setExpanded(!isExpanded, keyIndex)
                                            setActiveMsisdnObj(null)
                                            setActiveCardObj(null)
                                        },
                                    })}>
                                    <i className={isExpanded ? "bi bi-chevron-up" : "bi bi-chevron-down"} />
                                </button>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="panel-table-list-item collapse collapse-content-dir-colomn" {...getCollapseProps()}>
                    <Form.Item name="widgetUuid" hidden>
                        <Input />
                    </Form.Item>
                    {widget.Discounts && widget.Discounts.length > 0 && widget.Discounts[0].balance && <div className="alert green widget-discount">
                      <span className="alert-text__wrapper">
                          <i className="alert-icon bi bi-info-circle-fill"/>
                          <span className="alert-text">You have a Rs.{parseFloat(widget.Discounts[0].balance).toFixed(2)} discount for the {widget.title} app.</span>
                      </span>
                    </div>}
                    {widget.isChargeable ? <>
                        {checkUserRetryEnable() && <PaymentRetry
                            widget={widget}
                            keyIndex={keyIndex}
                        />}
                        <PaymentMethod
                            widget={widget}
                            onChangeMethod={onChangeMethod}
                            msisdns={msisdns}
                            cards={cards}
                            setMsisdnList={setMsisdnList}
                            setDisplayMsisdnModal={setDisplayMsisdnModal}
                            setActiveMsisdn={setActiveMsisdn}
                            activeMsisdn={activeMsisdn}
                            setActiveCard={setActiveCard}
                            activeCard={activeCard}
                            setDisplayCardModal={setDisplayCardModal}
                            setCardList={setCardList}
                            activeMsisdnObj={activeMsisdnObj}
                            setActiveMsisdnObj={setActiveMsisdnObj}
                            activeCardObj={activeCardObj}
                            setActiveCardObj={setActiveCardObj}
                            paymentMethod={paymentMethod}
                            paymentError={paymentMethodError.widgetUuid === widget.uuid ? paymentMethodError.error : ""}
                        />
                        <PaymentPackage
                            widget={widget}
                        />
                        <div className="panel-table-list-item">
                            <div className="panel-bottom-btn__wrapper">
                                <Form.Item wrapperCol={{offset: 8, span: 16}}>
                                    <Button
                                        htmlType="submit"
                                        className='btn btn-primary'
                                        disabled={checkIsDisabled(widget.uuid, widget.isPaymentMethodEditable)}
                                    >
                                        Save &amp; Update
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>
                    </> : <div className="alert info">
                        <span className="alert-text__wrapper"><i
                            className="alert-icon bi bi-info-circle-fill"/><span
                            className="alert-text">Note: MarketPlace hasn't defined a fee for this widget. Enterprise owner can be desired or will be dependent on the external application. For further details please contact the Market Place Support deck.</span></span>
                    </div>}
                </div>
            </Form>
        </React.Fragment >
    );
}

export { CardPanelItem };