import React from 'react'

type Props = {
    title: string,
    subTitle: string
}

const ProgressDescription = ({title, subTitle}: Props) => {
    return (
        <div className="wiz-inner-content__wrapper">
            <h4 className="title">{title}</h4>
            <p className="sub-title">{subTitle}</p>
        </div>
    )
}

export { ProgressDescription }