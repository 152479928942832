import { message } from 'antd';
import { getLocationTrackingList, getTermsAndConditions, postLocationTrackData, getIndividualTrackData, getTrackHistory, updateTrackData, getSettlementTypes } from '../../services/where-are-my-employees.service';
import whereAreMyEmpWithoutConcent from '../reducers/where-are-my-employees.store';

export const fetchTracklist = (tenantId: string, widgetId: string, limit: number, offset: number): any => {
    return (dispatch: any) => {
        dispatch(whereAreMyEmpWithoutConcent.actions.trackListFetch());
        getLocationTrackingList(tenantId, widgetId, limit, offset)
            .then((response: any) => {
                dispatch(whereAreMyEmpWithoutConcent.actions.trackListFetchSuccess(response.data.data));
            })
            .catch((error: any) => {
                dispatch(whereAreMyEmpWithoutConcent.actions.trackListFetchFail());
            });
    }
};

export const fetchTermsAndConditions = (widgetId: string): any => {
    return (dispatch: any) => {
        getTermsAndConditions(widgetId)
            .then((response: any) => {
                dispatch(whereAreMyEmpWithoutConcent.actions.tacFetchSuccess(response.data));
            })
            .catch((error: any) => {
                dispatch(whereAreMyEmpWithoutConcent.actions.tacFetchFail());
            });
    }
};

export const postLocation = (request_payload: any, tenantId: string, widgetId: string, navigate:any): any => {
    return (dispatch: any) => {
        message.loading("Submitting...")
        dispatch(whereAreMyEmpWithoutConcent.actions.locationPost());
        postLocationTrackData(request_payload, tenantId, widgetId)
            .then((response: any) => {
                if (response.status === 200) {
                    message.success("New location track has been added successfully");
                    navigate(`/location/${widgetId}/list`);
                    dispatch(whereAreMyEmpWithoutConcent.actions.locationPostSuccess(response.data));
                }
            })
            .catch((error: any) => {
                message.error("Error occurred while adding new location track. Please try again.");
                dispatch(whereAreMyEmpWithoutConcent.actions.locationPostFail(error.response.data.error));
            });
    }
};

export const fetchIndividualTrackData = (tenantId: string, trackId: string): any => {
    return (dispatch: any) => {
        getIndividualTrackData(tenantId, trackId)
            .then((response: any) => {
                dispatch(whereAreMyEmpWithoutConcent.actions.individualTrackFetchSuccess(response.data));
            })
            .catch((error: any) => {
                dispatch(whereAreMyEmpWithoutConcent.actions.individualTrackFetchFail(error));
            });
    }
};

export const fetchTrackHistory = (tenantId: string, trackId: string, limit: number, offset: number): any => {
    return (dispatch: any) => {
        dispatch(whereAreMyEmpWithoutConcent.actions.trackHistoryFetch());
        getTrackHistory(tenantId, trackId, limit, offset)
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(whereAreMyEmpWithoutConcent.actions.trackHistoryFetchSuccess(response.data));
                }
            })
            .catch((error: any) => {
                dispatch(whereAreMyEmpWithoutConcent.actions.trackHistoryFetchFail(error));
            });
    }
};

export const updateTrackDetails = (tenantId: string, trackId: string, expiredAt: string, status: string, navigate: any, widgetId: string): any => {
    return (dispatch: any) => {
        dispatch(whereAreMyEmpWithoutConcent.actions.trackDataUpdate());
        message.loading("Submitting...")
        updateTrackData(tenantId, trackId, expiredAt, status)
            .then((response: any) => {
                if (response.status === 200) {
                    message.success("Location track has been updated successfully");
                    navigate(`/location/${widgetId}/list`);
                    dispatch(whereAreMyEmpWithoutConcent.actions.trackDataUpdateSuccess(response.data));
                }
            })
            .catch((error: any) => {
                message.error("Error occurred while adding new location track. Please try again.");
                dispatch(whereAreMyEmpWithoutConcent.actions.trackDataUpdateFail(error.response.data.error));
            });
    }
};

export const fetchSettlementTyps = (tenantUuid: string, widgetId: string): any => {
    return (dispatch: any) => {
        dispatch(whereAreMyEmpWithoutConcent.actions.enterpriseSettlementTypeGet());
        getSettlementTypes(tenantUuid, widgetId)
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(whereAreMyEmpWithoutConcent.actions.enterpriseSettlementTypeSuccess(response.data.data));
                }
            })
            .catch((error: any) => {
                dispatch(whereAreMyEmpWithoutConcent.actions.enterpriseSettlementTypeFail(error));
            });
    }
};