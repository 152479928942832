import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from '@emotion/react';
import AuthHandler from '../../../helper/AuthHandler';
import { ClipLoader } from "react-spinners";
import { MapContainer, Marker, Popup, TileLayer, LayersControl, CircleMarker, Circle, useMapEvents } from 'react-leaflet';
import { DatePicker, TimePicker } from "antd";
import {
    postLocation,
    // fetchMsisdnList,
    fetchSettlementTyps,
    fetchSingleApp,
    submitMsisdn,
    submitMsisdnOtp,
    fetchIndividualTrackData,
    updateTrackDetails
} from '../../../store/actions';
import L from 'leaflet';
import Moment from 'moment';
import { postMsisdn, postMsisdnOtp } from '../../../services/user.service';
import SimpleReactValidator from 'simple-react-validator';
import * as CONSTANTS from '../../../constants';
import { confirmAlert } from 'react-confirm-alert';
import { onSearch } from "../../../services/widgets.service";
import dayjs from 'dayjs';
import MarkerMapIcon from '../../../assets/images/Marker.svg';
import { PageHeaderStatic } from "../components/PageHeaderStatic";
import { InputTextFieldWithButton } from "../../../components";

const override: CSSProperties = {
    display: "block",
    margin: "0 auto"
};

const LocationTrackDetails = ({ auth }: any) => {
    const mapRef = useRef<any>(null);
    const { wid, configId } = useParams();
    const [trackRef, setTrackRef] = useState(null);
    const [trackList, setTrackList] = useState([]);
    const [screen, setScreen] = useState('add-location-track');
    const [latitude, setLatitude] = useState(6.927079);
    const [longitude, setLongitude] = useState(79.861244);
    const [radius, setRadius] = useState(500);
    const [zoom, setZoom] = useState(13);
    const [animate, setAnimate] = useState(false);
    const [latlng, setLatlng] = useState({ lat: 6.927079, lng: 79.861244 });
    const [bounds, setBounds] = useState([
        [50.505, -29.09],
        [52.505, 29.09],
    ]);
    const [searchText, setSearchText] = useState("");
    const [alertReceiving, setAlertReceiving] = useState('');
    const [alertReceivingError, setAlertReceivingError] = useState('');
    const [receiverOtp, setReceiverOtp] = useState('');
    const [receiverOtpError, setReceiverOtpError] = useState('');
    const [receiverOtpLoading, setReceiverOtpLoading] = useState(false);
    const [receiverOtpSuccess, setReceiverOtpSuccess] = useState<boolean | null>(null);
    const [receiverServerRef, setReceiverServerRef] = useState('');
    const [receiverOtpReadOnly, setReceiverOtpReadOnly] = useState(false);
    const [trackerNumber, setTrackerNumber] = useState('');
    const [trackerNumberError, setTrackerNumberError] = useState('');
    const [trackerOtp, setTrackerOtp] = useState('');
    const [trackerOtpError, setTrackerOtpError] = useState('');
    const [trackerOtpLoading, setTrackerOtpLoading] = useState(false);
    const [trackerOtpSuccess, setTrackerOtpSuccess] = useState<boolean | null>(null);
    const [trackerServerRef, setTrackerServerRef] = useState('');
    const [trackerOtpReadOnly, setTrackerOtpReadOnly] = useState(false);
    const [checkedDays, setCheckedDays] = useState([]);
    const [mon, setMon] = useState(false);
    const [tue, setTue] = useState(false);
    const [wed, setWed] = useState(false);
    const [thu, setThu] = useState(false);
    const [fri, setFri] = useState(false);
    const [sat, setSat] = useState(false);
    const [sun, setSun] = useState(false);
    const [dayError, setDayError] = useState('');
    const [time, setTime] = useState('');
    const [timeError, setTimeError] = useState('');
    const [status, setStatus] = useState("Active");
    const [trackerName, setTrackerName] = useState('');
    const [trackerNameError, setTrackerNameError] = useState('');
    const [searchResult, setSearchResult] = useState([]);
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [saveError, setSaveError] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [title, setTitle] = useState("");
    const [expiredate, setExpiredate] = useState("");
    const [termsAndConditions, setTermsAndConditions] = useState<boolean>(false);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
    const [termsAndConditionsDetailsState, setTermsAndConditionsDetails] = useState('');
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [locationSubmitErrorMessageState, setLocationSubmitErrorMessage] = useState('');
    const [paymentMethodId, setPaymentMethodId] = useState('');
    const [showReceiverMsisdnOtpField, setShowReceiverMsisdnOtpField] = useState(false);
    const [receiverRemainingTime, setReceiverRemainingTime] = useState(0);
    const [receiverMobileVerified, setReceiverMobileVerified] = useState<string | undefined>();

    const [showTrackingMsisdnOtpField, setShowTrackingMsisdnOtpField] = useState(false);
    const [trackingRemainingTime, setTrackingRemainingTime] = useState(0);
    const [trackingMobileVerified, setTrackingMobileVerified] = useState<string | undefined>();

    const [otpSender, setOtpSender] = useState('receiver');
    const [locationSelected, setLocationSelected] = useState(false);
    const [statusValue, setStatusValue] = useState('');
    const validator = new SimpleReactValidator({
        messages: {
            required: 'You can’t keep this as empty',
            min: 'Minimum length is :min',
            max: 'Maximum length is :max',
        },
        validators: {
            all_mobiles: {
                message: 'The entered :attribute number is invalid.',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^[0]{1}[123456789]{2}\d{7}$/) && params.indexOf(val) === -1
                }
            },
            dialog: {
                message: 'The :attribute is invalid.',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^([0]{1}[7]{1}[764]{1}\d{7}|[7]{1}[764]{1}\d{7})$/) && params.indexOf(val) === -1
                }
            }
        },
        autoForceUpdate: this
    });

    const navigate = useNavigate();

    const position = { lat: latitude, lng: longitude };
    const MarkerIcon = L.icon({
        iconUrl: MarkerMapIcon,
        iconRetinaUrl: MarkerMapIcon,
        iconSize: new L.Point(50, 50),
        className: 'leaflet-div-icon'
    });

    const otpTextValidator = new SimpleReactValidator({
        messages: {
            required: 'You can’t keep this as empty',
            min: 'Minimum length is :min',
            max: 'Maximum length is :max',
        },
        autoForceUpdate: this
    });

    const {
        singleAppDetails
    } = useSelector(({ apps }: any) => apps);

    const {
        locationSubmitError,
        locationSubmitErrorMessage,
        locationSubmitLoading,
        locationSubmitSuccess,
        settlement_types,
        trackData
    } = useSelector(({ location }: any) => location);

    const {
        msisdnData,
        msisdnOtpData,
        userDetails
    } = useSelector<any, any>(({ user }) => user);

    const dispatch = useAppDispatch();

    const useForceUpdate = () => {
        const [value, setValue] = useState(0);
        return () => setValue(value => value + 1);
    }

    const forceUpdate = useForceUpdate()

    const onReceiverOtpChange = (name: string) => (e: any) => {
        setReceiverOtp(e.target.value);
        setReceiverOtpError('');
    }
    const onTrackerNumberChange = (name: string) => (e: any) => {
        setTrackerNumber(e.target.value);
    }
    const onTrackerOtpChange = (name: string) => (e: any) => {
        setTrackerOtp(e.target.value);
        setTrackerOtpError('');
    }

    useEffect(() => {
        if (searchText !== '' && !locationSelected) {
            var searchString = searchText.replace(/,/g, "%2C");
            searchString = searchString.replace(/ /g, "+");
            onSearch(searchString).then((data) => {
                setSearchResult(data.data);
            });
        }
    }, [searchText]);

    const onSelectSearch = (value: any) => {
        var lat = parseFloat(value.lat);
        var lon = parseFloat(value.lon);
        setSearchText(value.display_name);
        setLocationSelected(true);
        setSearchResult([]);
        setLatitude(lat);
        setLongitude(lon)
    }

    const onMapPress = (e: any) => {
        setLongitude(e.latlng.lng);
        setLatitude(e.latlng.lat);
    }
    const onDragMarker = (e: any) => {
        try {
            setLongitude(e.target._latlng.lng);
            setLatitude(e.target._latlng.lat);
        } catch (err) {
            console.log(err);
        }
    }
    const onSetZoom = (e: any) => {
        try {
            setZoom(e.target._zoom);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        if (mapRef.current) {
            // Perform animation after the map is initialized
            mapRef.current.setView(position, 13, { animate: true });
            // Add event listener for zoomstart event
            mapRef.current.on('zoomstart', onSetZoom);
            mapRef.current.on('click', onMapPress);

        }

        return () => {
            if (mapRef.current) {
                mapRef.current.off('zoomstart', onSetZoom);
                mapRef.current.off('click', onMapPress);
            }
        };
    }, [mapRef, position]);

    const onChangeTime = (value: any) => {
        setTime(value);
    }

    const onChangeExpireDate = (value: any, dateString: string) => {
        setExpiredate(dateString);
        console.log(Moment(value).toString(), dateString);
        
        setSubmitDisabled(false);
    }

    const disabledDate = (current: any) => {
        return current < Moment().startOf('day');
    }

    useEffect(() => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        dispatch(fetchSingleApp(wid!, tenantGUID));
    }, [wid, userDetails]);

    useEffect(() => {
        if (trackData && Array.isArray(trackData.data) && trackData.data.length > 0) {
            setTrackRef(trackData.data[0].uuid);
            setTitle(trackData.data[0].title);
            setAlertReceiving(trackData.data[0].trackerNumber);
            setTrackerNumber(trackData.data[0].trackingNumber);
            setTrackerName(trackData.data[0].personName);
            setLatitude(trackData.data[0].latitude);
            setLongitude(trackData.data[0].longitude);
            setRadius(trackData.data[0].radius);
            setMon(trackData.data[0].monday);
            setTue(trackData.data[0].tuesday);
            setWed(trackData.data[0].wednesday);
            setThu(trackData.data[0].thursday);
            setFri(trackData.data[0].friday);
            setSat(trackData.data[0].saturday);
            setSun(trackData.data[0].sunday);
            setTime(trackData.data[0].trackTime);
            setExpiredate(Moment(trackData.data[0].expiredAt).toString());
            setStatus(trackData.data[0].status);
            setStatusValue(trackData.data[0].status);
        }
    }, [trackData]);

    useEffect(() => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        if (singleAppDetails && singleAppDetails.widgetDetails) {
            dispatch(fetchSettlementTyps(tenantGUID, singleAppDetails.widgetDetails.uuid));
        }
    }, [userDetails, singleAppDetails]);

    useEffect(() => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        if (singleAppDetails && singleAppDetails.widgetDetails) {
            dispatch(fetchIndividualTrackData(tenantGUID, configId!));
        }
    }, [userDetails, singleAppDetails]);

    useEffect(() => {
        if (userDetails && userDetails.OwnedEnterprises[0] && userDetails.OwnedEnterprises[0].Enterprise && userDetails.OwnedEnterprises[0].Enterprise.billingStatus == 'Invoice') {
            setAlertReceiving((userDetails && Array.isArray(userDetails.OwnedEnterprises) && userDetails.OwnedEnterprises.length > 0 && userDetails.OwnedEnterprises[0].Enterprise) ? userDetails.OwnedEnterprises[0].Enterprise.ownerMobile : '');
            setPaymentMethodId(settlement_types?.paymentMethodId);
        } else {
            if (settlement_types && settlement_types?.paymentMethodId != null) {
                setPaymentMethodId(settlement_types?.paymentMethodId);
                if (settlement_types?.paymentMethodId == '1') {
                    setAlertReceiving(settlement_types.Msisdns ? settlement_types.Msisdns.msisdn : '');
                } else {
                    setAlertReceiving((userDetails && Array.isArray(userDetails.OwnedEnterprises) && userDetails.OwnedEnterprises.length > 0 && userDetails.OwnedEnterprises[0].Enterprise) ? userDetails.OwnedEnterprises[0].Enterprise.ownerMobile : '');
                }
            }
        }
    }, [settlement_types, userDetails]);

    useEffect(() => {
        if (formSubmitted) {
            setSaveSuccess(locationSubmitSuccess);
            setSaveError(locationSubmitError);
            setSaveLoading(locationSubmitLoading);
            setLocationSubmitErrorMessage(locationSubmitErrorMessage);
        }
    }, [formSubmitted, locationSubmitSuccess, locationSubmitError, locationSubmitLoading]);

    useEffect(() => {
        let intervalId: any = null;

        if (receiverRemainingTime > 0) {
            intervalId = setInterval(() => {
                setReceiverRemainingTime((prevTime) => prevTime - 1);
            }, 1000);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [receiverRemainingTime]);

    useEffect(() => {
        let intervalId: any = null;

        if (trackingRemainingTime > 0) {
            intervalId = setInterval(() => {
                setTrackingRemainingTime((prevTime) => prevTime - 1);
            }, 1000);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [trackingRemainingTime]);

    useEffect(() => {
        setSubmitDisabled(!termsAndConditions);
    }, [termsAndConditions]);

    const onChangeStatus = (e: any) => {
        setStatus(e.target.checked === true ? 'Active' : 'Inactive');
        setSubmitDisabled(false);
    }

    const handleWidgetSave = async () => {
        confirmAlert({
            message: CONSTANTS.LOCATION_TRACKING_UPDATE,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => submitTrackingData()
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    const submitTrackingData = async () => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        dispatch(updateTrackDetails(tenantGUID, configId!, Moment(expiredate).format('YYYY-MM-DD HH:mm:ss'), status, navigate, wid!));
    };

    const renderSearchList = () => {
        return (
            <ul className="dropdown-list">
                {searchResult && searchResult.map(function (value: any, index: number) {
                    return (
                        <li className="dropdown-item" key={index}>
                            <a onClick={() => onSelectSearch(value)}>{value.display_name}</a>
                        </li>
                    )
                })}
            </ul>
        )
    }

    const renderSubmitButton = () => {
        if (AuthHandler.isPerformable(auth, "update-location-track")) {
            return (
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleWidgetSave}
                    disabled={submitDisabled}
               >
                   Update
                </button>
            )
        } else {
            return (
                <button type="button" className="btn btn-primary" disabled={true}>You don't have access to update a location track</button>
            )
        }
    }

    useEffect(() => {
        if (msisdnData && msisdnData.data) {
            if (otpSender === 'receiver') {
                setShowReceiverMsisdnOtpField(true);
                setReceiverRemainingTime(120);
                setReceiverServerRef(msisdnData.data.serverRef);
            } else {
                setShowTrackingMsisdnOtpField(true);
                setTrackingRemainingTime(120);
                setTrackerServerRef(msisdnData.data.serverRef);
            }
            forceUpdate();
        }
    }, [msisdnData])

    useEffect(() => {
        if (otpSender === 'receiver') {
            if (msisdnOtpData && msisdnOtpData.data && msisdnOtpData.data.data && msisdnOtpData.data.data.status === "SUCCESS") {
                setReceiverMobileVerified("verified")
                setShowReceiverMsisdnOtpField(false)
            }
            if (msisdnOtpData && msisdnOtpData.status === "ERROR") {
                setReceiverMobileVerified("notVerified")
            }
        } else {
            if (msisdnOtpData && msisdnOtpData.data && msisdnOtpData.data.data && msisdnOtpData.data.data.status === "SUCCESS") {
                setTrackingMobileVerified("verified")
                setShowTrackingMsisdnOtpField(false)
            }
            if (msisdnOtpData && msisdnOtpData.status === "ERROR") {
                setTrackingMobileVerified("notVerified")
            }
        }
    }, [msisdnOtpData]);



    const renderOwnersNumber = () => {
        if (paymentMethodId) {
            return (
                <>
                    {(!showReceiverMsisdnOtpField || receiverRemainingTime == 0) ?
                        <InputTextFieldWithButton
                            name='alertReceiving'
                            label='Owner number'
                            placeholder='Charging number'
                            value={alertReceiving}
                            setValue={() => () => { }}
                            validator={validator}
                            rules={['required', 'dialog']}
                            disabled={true}
                            status={(receiverMobileVerified === "verified") ? "verified" : (receiverMobileVerified === "notVerified") ? "notVerified" : ""}
                            validationMessage={(receiverMobileVerified === "verified") ? "Verified!" : (receiverMobileVerified === "notVerified") ? "Not verified! please use correct type of Number" : ""}
                            buttonLable="Verify"
                            showButton={false}
                            onClickFunc={() => { }}
                            forceUpdate={forceUpdate}
                            buttonDisabled={(receiverMobileVerified && receiverMobileVerified === "verified") ? true : false}
                            preValueCheck={false}
                            errorText={receiverOtpError}
                        />
                        :
                        <InputTextFieldWithButton
                            name='recieverOtp'
                            label='Enter OTP'
                            placeholder='123456'
                            value={receiverOtp}
                            setValue={onReceiverOtpChange}
                            validator={otpTextValidator}
                            rules={['required', 'numeric', { min: 6 }, { max: 6 }]}
                            disabled={false}
                            status={(receiverMobileVerified === "verified") ? "verified" : (receiverMobileVerified === "notVerified") ? "notVerified" : ""}
                            validationMessage={(receiverMobileVerified === "verified") ? "Verified!" : (receiverMobileVerified === "notVerified") ? "Not verified! please use correct type of Number" : ""}
                            buttonLable="Submit"
                            showButton={true}
                            onClickFunc={() => { }}
                            buttonDisabled={false}
                            forceUpdate={forceUpdate}
                            preValueCheck={false}
                        />}
                </>
            )
        }
    }

    const renderTrackingNumber = () => {
        return (
            <>
                {(!showTrackingMsisdnOtpField || trackingRemainingTime == 0) ?
                    <InputTextFieldWithButton
                        name='trackerNumber'
                        label="Tracking person's number"
                        placeholder="Tracking person's number"
                        value={trackerNumber}
                        setValue={onTrackerNumberChange}
                        validator={validator}
                        rules={['required', 'dialog']}
                        disabled={true}
                        status={(trackingMobileVerified === "verified") ? "verified" : (trackingMobileVerified === "notVerified") ? "notVerified" : ""}
                        validationMessage={(trackingMobileVerified === "verified") ? "Verified!" : (trackingMobileVerified === "notVerified") ? "Not verified! please use correct type of Number" : ""}
                        buttonLable="Verify"
                        showButton={false}
                        onClickFunc={() => { }}
                        forceUpdate={forceUpdate}
                        buttonDisabled={true}
                        preValueCheck={false}
                        errorText={trackerOtpError}
                    />
                    :
                    <InputTextFieldWithButton
                        name='trackerOtp'
                        label='Enter OTP'
                        placeholder='123456'
                        value={trackerOtp}
                        setValue={onTrackerOtpChange}
                        validator={otpTextValidator}
                        rules={['required', 'numeric', { min: 6 }, { max: 6 }]}
                        disabled={true}
                        status={(trackingMobileVerified === "verified") ? "verified" : (trackingMobileVerified === "notVerified") ? "notVerified" : ""}
                        validationMessage={(trackingMobileVerified === "verified") ? "Verified!" : (trackingMobileVerified === "notVerified") ? "Not verified! please use correct type of Number" : ""}
                        buttonLable="Submit"
                        showButton={false}
                        onClickFunc={() => { }}
                        buttonDisabled={true}
                        forceUpdate={forceUpdate}
                        preValueCheck={false}
                    />}
            </>
        )
    }

    if (AuthHandler.isAuthorized(auth, 'add-location-track') && userDetails !== null) {
        return (
            <React.Fragment>
                <div className="main-content main-spacer-x-y">
                    <PageHeaderStatic
                        page={'create'}
                    />
                    <div className="card added-app-thumbnnail__wrapper">
                        <div
                            className="added-app-thumbnail__inner"
                            style={{
                                backgroundImage: `url(${(singleAppDetails && singleAppDetails.widgetDetails !== null) ? singleAppDetails.widgetDetails.leftImage : ""})`
                            }}
                        ></div>
                    </div>
                    <div className="card panel content-holder-panels added-app-form__wrapper">
                        <div className="panel-header">
                            <div className="panel-header-content__wrapper">
                                <h4 className="panel-header-title">New Tracking Request</h4>
                            </div>
                        </div>
                        <div className="panel-content">
                            <div className="wiz-content-form__wrapper">
                                <p>
                                    Are you sure your employees are at the correct place? Well,
                                    Let's find out by getting an SMS.
                                </p>
                                <br />
                                <div className="form-group__wrapper">
                                    <div className="form-group">
                                        <div className="form-element__wrapper mb-3">
                                            <label htmlFor="" className="form-label">
                                                Title
                                            </label>
                                            <input
                                                type="text"
                                                id="tile"
                                                className="form-control"
                                                value={title}
                                                disabled
                                                placeholder="Title for the service"
                                            />
                                            <div className="validation invalid-feedback">{validator.message('title', title, 'required|alpha_num_dash_space')}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group__wrapper">
                                    <div className="form-group">
                                        {renderOwnersNumber()}
                                    </div>
                                    <div className="form-group">
                                        {renderTrackingNumber()}
                                    </div>
                                </div>
                                <div className="form-group__wrapper">
                                    <div className="form-group">
                                        <div className="form-element__wrapper mb-3">
                                            <label htmlFor="" className="form-label">
                                                Tracking person's name
                                            </label>
                                            <input
                                                type="text"
                                                id="otp"
                                                className="form-control"
                                                value={trackerName}
                                                disabled
                                                placeholder="Your tracker's name"
                                            />
                                            <div className="validation invalid-feedback">{validator.message('tracker name', trackerName, 'required|alpha_space')}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group__wrapper">
                                    <div className="form-group">
                                        <div className="form-element__wrapper mb-3 has-dropdown">
                                            <label htmlFor="" className="form-label">
                                                Search location
                                            </label>
                                            <div className="icon-inside right">
                                                <span className="in-icon" onClick={() => {
                                                    setSearchText('');
                                                    setLocationSelected(false);
                                                    setSearchResult([]);
                                                }}>
                                                    <i className={searchText == '' ? "bi bi-search" : "bi bi-x"} />
                                                </span>
                                                <input
                                                    type="text"
                                                    id="otp"
                                                    className="form-control"
                                                    value={searchText}
                                                    disabled
                                                    placeholder="Enter location"
                                                />
                                            </div>
                                            {searchResult.length !== 0 && <div className="dropdown">
                                                {renderSearchList()}
                                            </div>}
                                        </div>
                                    </div>
                                    <div className="form-group" />
                                </div>
                                <div className="form-group__wrapper">
                                    <div className="form-group">
                                        <div className="form-element__wrapper mb-3">
                                            <label htmlFor="" className="form-label">
                                                Select location on the map
                                            </label>
                                            <div className="inner-feature-element-container__wrapper">
                                                <MapContainer
                                                    ref={mapRef}
                                                    center={position}
                                                    zoom={zoom}
                                                    zoomControl={true}
                                                    doubleClickZoom={true}
                                                    id="map"
                                                >
                                                    <TileLayer
                                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                        attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                                                    />
                                                    <Marker
                                                        draggable
                                                        opacity={0.8}
                                                        position={position}
                                                        icon={MarkerIcon}
                                                        eventHandlers={{
                                                            dragend: onDragMarker,
                                                        }}
                                                    >
                                                    </Marker>
                                                    <Circle
                                                        center={position}
                                                        radius={radius}
                                                        fillColor='red'
                                                    />
                                                </MapContainer>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group__wrapper">
                                    <div className="form-group">
                                        <div className="form-element__wrapper mb-3">
                                            <label htmlFor="" className="form-label">
                                                Select fence radius
                                            </label>
                                            <div>
                                                <input
                                                    type="range"
                                                    className="custom-range"
                                                    value={radius}
                                                    // onChange={onChangeRadius}
                                                    disabled
                                                    min="0"
                                                    max="5000"
                                                    step="500"
                                                    id="range"
                                                />
                                                <span className="font-weight-bold text-primary ml-2 mt-1 valueSpan">{radius / 1000} km</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group__wrapper">
                                    <div className="form-group">
                                        <div className="form-element__wrapper mb-3">
                                            <label htmlFor="" className="form-label">
                                                Select when to track
                                            </label>
                                            <div className="inner-multiple-feature-list-element__wrapper horizontal">
                                                <div className="form-check mb-4">
                                                    <input
                                                        type="checkbox"
                                                        id="mon"
                                                        name="mon"
                                                        className="form-check-input"
                                                        // onChange={onChangeMon}
                                                        checked={mon}
                                                        disabled
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexCheckDefault"
                                                    >
                                                        Monday
                                                    </label>
                                                </div>
                                                <div className="form-check mb-4">
                                                    <input
                                                        type="checkbox"
                                                        id="tue"
                                                        name="tue"
                                                        className="form-check-input"
                                                        // onChange={onChangeTue}
                                                        checked={tue}
                                                        disabled
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexCheckDefault"
                                                    >
                                                        Tuesday
                                                    </label>
                                                </div>
                                                <div className="form-check mb-4">
                                                    <input
                                                        type="checkbox"
                                                        id="wed"
                                                        name="wed"
                                                        className="form-check-input"
                                                        // onChange={onChangeWed}
                                                        checked={wed}
                                                        disabled
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexCheckDefault"
                                                    >
                                                        Wednesday
                                                    </label>
                                                </div>
                                                <div className="form-check mb-4">
                                                    <input
                                                        type="checkbox"
                                                        id="thu"
                                                        name="thu"
                                                        className="form-check-input"
                                                        // onChange={onChangeThu}
                                                        checked={thu}
                                                        disabled
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexCheckDefault"
                                                    >
                                                        Thursday
                                                    </label>
                                                </div>
                                                <div className="form-check mb-4">
                                                    <input
                                                        type="checkbox"
                                                        id="fri"
                                                        name="fri"
                                                        className="form-check-input"
                                                        // onChange={onChangeFri}
                                                        checked={fri}
                                                        disabled
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexCheckDefault"
                                                    >
                                                        Friday
                                                    </label>
                                                </div>
                                                <div className="form-check mb-4">
                                                    <input
                                                        type="checkbox"
                                                        id="sat"
                                                        name="sat"
                                                        className="form-check-input"
                                                        // onChange={onChangeSat}
                                                        checked={sat}
                                                        disabled
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexCheckDefault"
                                                    >
                                                        Saturday
                                                    </label>
                                                </div>
                                                <div className="form-check mb-4">
                                                    <input
                                                        type="checkbox"
                                                        id="sun"
                                                        name="sun"
                                                        className="form-check-input"
                                                        // onChange={onChangeSun}
                                                        checked={sun}
                                                        disabled
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexCheckDefault"
                                                    >
                                                        Sunday
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group__wrapper">
                                    <div className="form-group">
                                        <div className="form-element__wrapper mb-3">
                                            <label htmlFor="" className="form-label">
                                                Select time of the day
                                            </label>
                                            <TimePicker
                                                id="appt"
                                                name="appt"
                                                use12Hours
                                                format="h:mm A"
                                                className="form-control form-control-md"
                                                onChange={onChangeTime}
                                                disabled
                                                value={time ? dayjs(`2000-01-01 ${time}`) : null}
                                            />
                                            <div className="validation invalid-feedback">{validator.message('time', time, 'required')}</div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-element__wrapper mb-3">
                                            <label htmlFor="" className="form-label">
                                                Alert stop date
                                            </label>
                                            <DatePicker
                                                disabledDate={disabledDate}
                                                onChange={onChangeExpireDate}
                                                className="form-control"
                                                id="expiredate"
                                                name="expiredate"
                                                value={dayjs(expiredate, 'YYYY/MM/DD')}
                                            />
                                            {/* <input
                                                type="text"
                                                id="sun"
                                                name="sun"
                                                className="form-control"
                                                value={expiredate}
                                                disabled
                                            /> */}
                                            <div className="validation invalid-feedback">{validator.message('stop date', expiredate, 'required')}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group__wrapper">
                                    <div className="form-group">
                                        <div className="form-element__wrapper mb-3">
                                            <label htmlFor="expiredate">Status</label>
                                            {/* <div className="form-group"> */}
                                            <div id="console-event" >{status}</div>
                                            <label className="switch">
                                                <input
                                                    type="checkbox"
                                                    onChange={onChangeStatus}
                                                    checked={status === 'Active' ? true : status === 'Running' ? true : false}
                                                    disabled={statusValue === 'Active' ? false : statusValue === 'Running' ? false : true}
                                                />
                                                <span className="slider round" />
                                            </label>
                                            {/* </div> */}
                                        </div>
                                    </div>
                                </div>
                                <p>
                                    {singleAppDetails !== null ? singleAppDetails?.widgetDetails?.termsAndConditions : ''}
                                </p>
                                <div className="panel-bottom-btn__wrapper">
                                    <button className="btn btn-secondary">Cancel</button>
                                    {renderSubmitButton()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    } else return (<React.Fragment></React.Fragment>);
}

export default LocationTrackDetails;