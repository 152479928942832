import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useAppDispatch } from '../store';
import { submitGeneratedLeadRequest } from '../store/actions';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { InputTextField } from './InputTextField';
import SimpleReactValidator from 'simple-react-validator';
import { InputTextArea } from './InputTextArea';
type leadReq = {
    widgetUuid: string,
    name: string,
    contactNumber: string,
    comment: string,
    campaignUuid: string;
}

type Props = {
    campaignUuid: string;
};

const GeneratingLeadsPopup: React.FC<Props> = ({ campaignUuid }) => {
    const { wid } = useParams();
    const [visible, setVisible] = useState<boolean>(false);
    const [cancelButtonClicked, setCancelButtonClicked] = useState<boolean>(false);

    useEffect(() => {
            setVisible(true);
    }, []);

    const [requestData, setRequestData] = useState({
        name: "",
        mobile: "",
        comment: ""
    })

    const dispatch = useAppDispatch();

    const [validator] = useState(new SimpleReactValidator({
        messages: {
            required: 'You can’t keep this as empty',
            max: 'Maximum length is :max',
        },
        validators: {
            all_mobiles: {
                message: 'The entered :attribute number is invalid.',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^[0]{1}[123456789]{2}\d{7}$/) && params.indexOf(val) === -1
                }
            },
        },
    }));

    const handleRequest = (name: string) => (event: any) => {
        setRequestData({ ...requestData, [name]: event.target.value });
    };

    const handleOk = () => {
        let leadReq: leadReq = {
            widgetUuid: wid!,
            name: requestData.name,
            contactNumber: requestData.mobile,
            comment: requestData.comment,
            campaignUuid: campaignUuid,
        }

        console.log('Lead submitted:', leadReq);

        dispatch(submitGeneratedLeadRequest(leadReq));
        setVisible(false);
    };

    const handleCancel = () => {
        setCancelButtonClicked(true);
        setVisible(false);
    };

    const useForceUpdate = () => {
        const [value, setValue] = useState(0);
        return () => setValue(value => value + 1);
    }

    const forceUpdate = useForceUpdate()

    return (
        <>
        <Modal
            title=""
            visible={visible}
            onCancel={handleCancel}
            footer={[
                <button
                    key="submit"
                    className="btn btn-primary"
                    onClick={handleOk}
                    disabled={!requestData.name || !requestData.mobile || !validator.allValid()}
                >
                    Submit
                </button>
            ]}
        >
            <div className="panel-content">
                <div><p><h4>Are you interested in this product?</h4></p></div>
                <div><p><h5>Please provide below details.</h5></p></div>
                <div className="wiz-content-form__wrapper">
                    <div className="form-group__wrapper">
                        <div className="form-group">
                            <div className="form-element__wrapper mb-3">
                                <InputTextField
                                    name='name'
                                    label='Name'
                                    placeholder='Enter your name'
                                    value={requestData.name}
                                    setValue={handleRequest}
                                    validator={validator}
                                    disabled={false}
                                    status="default"
                                    validationMessage=""
                                    rules={['required', { max: 100 }]}
                                    forceUpdate={forceUpdate} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group__wrapper">
                        <div className="form-group">
                            <div className="form-element__wrapper mb-3">
                                <InputTextField
                                    name='mobile'
                                    label='Contact Number'
                                    placeholder='Contact Number'
                                    value={requestData.mobile}
                                    setValue={handleRequest}
                                    validator={validator}
                                    disabled={false}
                                    status="default"
                                    validationMessage="you can't keep this as empty"
                                    rules={['required', 'all_mobiles', { max: 10 }]}
                                    forceUpdate={forceUpdate}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group__wrapper">
                        <div className="form-group">
                            <InputTextArea
                                name='comment'
                                label='Comment'
                                placeholder='Enter your comment'
                                value={requestData.comment}
                                setValue={handleRequest}
                                validator={validator}
                                disabled={false}
                                status="default"
                                validationMessage=""
                                rules={[{ max: 400 }]}
                                forceUpdate={forceUpdate} 
                                rows={3} 
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Modal >
        {cancelButtonClicked && (
            <button className="btn btn-primary fixed-button" onClick={() => setVisible(true)}>
                I'm Interested!
            </button>
        )}
        </>
    );
};

export default GeneratingLeadsPopup;
