import React, {useEffect, useState} from 'react';
import {Button} from "antd";
import {useSelector} from "react-redux";
import {useAppDispatch} from "../../../store";
import {renewSubscription} from "../../../store/actions";
import {confirmAlert} from "react-confirm-alert";

interface Props {
    widget: any,
    keyIndex: number
}

const PaymentRetry = ({ widget, keyIndex }: Props) => {

    const dispatch = useAppDispatch();

    const [renewalAmount, setRenewalAmount] = useState<string>('');

    const {
        userDetails
    } = useSelector<any, any>(({ user }) => user);
    const {
        renewalChargingDataList
    } = useSelector<any, any>(({ settings }) => settings);

    useEffect(() => {
        if(widget) {
        }
    }, [widget])

    useEffect(() => {
        if(widget && Array.isArray(renewalChargingDataList) && renewalChargingDataList.length > 0) {
            let chargingData = renewalChargingDataList.filter((obj: any) => (obj.widgetType === widget.widgetType && obj.index.toString() === keyIndex.toString()));
            if(chargingData && chargingData.length > 0) {
                setRenewalAmount(chargingData[0].chargingAmount);
            }
        }
    }, [renewalChargingDataList])

    const onRetry = async () => {
        confirmAlert({
            message: `Are you sure you want to renew your subscription?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
                        if(tenantGroupId && widget && widget.uuid) {
                            dispatch<any>(renewSubscription(tenantGroupId, widget.uuid));
                        }
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    return (
        <React.Fragment>
            <div className="panel-inner-feature-content__wrapper" style={{width: '100%'}}>
                <span className="panel-inner-feature-content-title">
                    Pending Renewal
                </span>
                <div className="panel-inner-feature-content">
                    <p>
                        Renew your subscriptions to explore more features on below product.
                    </p>
                    {renewalAmount &&
                        <p>
                            {`You will be charged ${renewalAmount} + tax as monthly subscription`}
                        </p>
                    }
                    <div className="form-group__wrapper">
                        <div className="panel-table-list-item" style={{justifyContent: "center", borderBottom: "none"}}>
                            <Button
                                className='btn btn-primary'
                                onClick={onRetry}
                            >
                                Renew Subscriptions
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export { PaymentRetry };