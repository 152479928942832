import React from "react";
import { Link } from "react-router-dom";

type Props = {
    widgetDetails: any;
    hasOffer: boolean;
    hasSubscribed: boolean;
}

const WidgetItem = ({ widgetDetails, hasOffer, hasSubscribed }: Props) => {
    return (
        <div className="app-listing-item__wrapper">
            <a>
                <div className="app-listinng-item">
                    <div className="app-item-content__wrapper app-details">
                        <div className="card app-media-holder"
                            style={{ backgroundImage: `url(${widgetDetails?.icon})` }}>
                            {hasOffer && !hasSubscribed &&
                                <div className="discount-label">Offer</div>
                            }
                        </div>
                        <div className="app-media-info-holder">
                            <Link to={`/widget/${widgetDetails?.uuid}`} className="app-media-title">{widgetDetails?.title}</Link>
                            <p className="app-media-desc">{widgetDetails?.description}</p>
                            <Link to={`/app-category/${widgetDetails?.Category?.uuid}`} className="app-media-category">{widgetDetails?.Category?.title} </Link>
                            <div className="app-stats__wrapper">
                                <ul className="app-stats-list">
                                    {/* <li className="app-stats-item"><span className="stats-icon"><i className="bi bi-download"></i></span><span className="stats-value">400+</span></li>
                                                                    <li className="app-stats-item"><span className="stats-divider"><i className="bi bi-dot"></i></span></li>
                                                                    <li className="app-stats-item"><span className="stats-icon ico-highlighter"><i className="bi bi-star-fill"></i></span><span className="stats-value">4.8</span></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    )
}
export { WidgetItem };