import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import {fetchDynamicSubData, fetchSingleApp,} from '../../../store/actions';
import {PageHeaderStatic} from "../components/PageHeaderStatic";
import _ from 'lodash';

const DynamicWidgetConfigDetails = ({auth}) => {
    const [widgetUuid, setWidgetUuid] = useState("");

    const navigate = useNavigate();

    const {
        singleAppDetails
    } = useSelector(({apps}) => apps);

    const {
        userDetails
    } = useSelector(({user}) => user);

    const {
        dynamicSubData
    } = useSelector(({ dynamicForm }) => dynamicForm);


    const dispatch = useDispatch();

    const useForceUpdate = () => {
        const [value, setValue] = useState(0);
        return () => setValue(value => value + 1);
    }

    const forceUpdate = useForceUpdate()

    useEffect(() => {
        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        const widgetUuid = window.location.pathname.split('/')[2];
        if (tenantGroupId !== "" && widgetUuid) {
            dispatch(fetchSingleApp(widgetUuid, tenantGroupId));
        }
    }, [userDetails]);

    useEffect(() => {
        if (widgetUuid === "" && singleAppDetails && singleAppDetails.widgetDetails && singleAppDetails.widgetDetails.uuid) {
            setWidgetUuid(singleAppDetails.widgetDetails.uuid)
        }
    }, [singleAppDetails]);

    useEffect(() => {
        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        if (singleAppDetails && singleAppDetails.widgetDetails) {
            dispatch(fetchDynamicSubData(singleAppDetails.widgetDetails.uuid, tenantGroupId))
        }
    }, [singleAppDetails, userDetails]);

    const onViewBillingHistory = () => {
        if (singleAppDetails && singleAppDetails.widgetDetails) {
            navigate(`/dynamic-widget/${singleAppDetails.widgetDetails.uuid}/billing-history`);
        }
    }

    if (userDetails !== null) {
        if (userDetails) {
            return (
                <React.Fragment>
                    <div className="main-content main-spacer-x-y">
                        <PageHeaderStatic
                            page={'create'}
                        />
                        <div className="card added-app-thumbnnail__wrapper">
                            <div
                                className="added-app-thumbnail__inner"
                                style={{
                                    backgroundImage: `url(${(singleAppDetails && singleAppDetails.widgetDetails !== null) ? singleAppDetails.widgetDetails.leftImage : ""})`
                                }}
                            ></div>
                        </div>
                        <div className="card panel content-holder-panels added-app-form__wrapper">
                            <div className="panel-header">
                                <div className="panel-header-content__wrapper">
                                    <h4 className="panel-header-title">Widget Configuration Details</h4>
                                </div>
                                {dynamicSubData && dynamicSubData.leadData && dynamicSubData.leadData.length > 0 && <div>
                                        <button
                                            className="btn btn-primary"
                                            onClick={onViewBillingHistory}
                                        >
                                            View Billing History
                                        </button>
                                    </div>
                                }
                            </div>
                            <div className="panel-content">
                                {dynamicSubData && dynamicSubData.leadData && dynamicSubData.leadData.length > 0 ?
                                    <div className="wiz-content-form__wrapper">
                                        <p>
                                            {(singleAppDetails && singleAppDetails.widgetDetails !== null) ? singleAppDetails.widgetDetails.description : ""}
                                        </p>
                                        <br/>

                                        {/*<div className="form-group__wrapper">*/}
                                        {/*    <div className="form-group">*/}
                                        {/*        <div className="form-element__wrapper mb-3">*/}
                                        {/*            <label htmlFor="" className="form-label">*/}
                                        {/*                Company*/}
                                        {/*            </label>*/}
                                        {/*            <label htmlFor="" className="form-control">*/}
                                        {/*                {(dynamicSubData && dynamicSubData.Company) ? dynamicSubData.Company : "N/A"}*/}
                                        {/*            </label>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {/*<div className="form-group__wrapper">*/}
                                        {/*    <div className="form-group">*/}
                                        {/*        <div className="form-element__wrapper mb-3">*/}
                                        {/*            <label htmlFor="" className="form-label">*/}
                                        {/*                Subscription ID*/}
                                        {/*            </label>*/}
                                        {/*            <label htmlFor="" className="form-control">*/}
                                        {/*                {(dynamicSubData && dynamicSubData["Subscription ID"]) ? dynamicSubData["Subscription ID"] : "N/A"}*/}
                                        {/*            </label>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {/*<div className="form-group__wrapper">*/}
                                        {/*    <div className="form-group">*/}
                                        {/*        <div className="form-element__wrapper mb-3">*/}
                                        {/*            <label htmlFor="" className="form-label">*/}
                                        {/*                Frequency*/}
                                        {/*            </label>*/}
                                        {/*            <label htmlFor="" className="form-control">*/}
                                        {/*                {(dynamicSubData && dynamicSubData.Frequency) ? dynamicSubData.Frequency : "N/A"}*/}
                                        {/*            </label>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className="form-group__wrapper">
                                            <div className="form-group">
                                                <div className="form-element__wrapper mb-3">
                                                    <label htmlFor="" className="form-label">
                                                        Plan
                                                    </label>
                                                    <label htmlFor="" className="form-control">
                                                        {(dynamicSubData && dynamicSubData.Plan) ? dynamicSubData.Plan : "N/A"}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        {/*<div className="form-group__wrapper">*/}
                                        {/*    <div className="form-group">*/}
                                        {/*        <div className="form-element__wrapper mb-3">*/}
                                        {/*            <label htmlFor="" className="form-label">*/}
                                        {/*                Trail Time Type*/}
                                        {/*            </label>*/}
                                        {/*            <label htmlFor="" className="form-control">*/}
                                        {/*                {(dynamicSubData && dynamicSubData["Trail Time Type"]) ? dynamicSubData["Trail Time Type"] : "N/A"}*/}
                                        {/*            </label>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className="form-group__wrapper">
                                            <div className="form-group">
                                                <div className="form-element__wrapper mb-3">
                                                    <label htmlFor="" className="form-label">
                                                        Price
                                                    </label>
                                                    <label htmlFor="" className="form-control">
                                                        {(dynamicSubData && dynamicSubData.Price) ? dynamicSubData.Price : "N/A"}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group__wrapper">
                                            <div className="form-group">
                                                <div className="form-element__wrapper mb-3">
                                                    <label htmlFor="" className="form-label">
                                                        Status
                                                    </label>
                                                    <label htmlFor="" className="form-control">
                                                        {(dynamicSubData && dynamicSubData.Status) ? dynamicSubData.Status : "N/A"}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        {/*<div className="form-group__wrapper">*/}
                                        {/*    <div className="form-group">*/}
                                        {/*        <div className="form-element__wrapper mb-3">*/}
                                        {/*            <label htmlFor="" className="form-label">*/}
                                        {/*                Start On*/}
                                        {/*            </label>*/}
                                        {/*            <label htmlFor="" className="form-control">*/}
                                        {/*                {(dynamicSubData && dynamicSubData["Start On"]) ? dynamicSubData["Start On"] : "N/A"}*/}
                                        {/*            </label>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {/*<div className="form-group__wrapper">*/}
                                        {/*    <div className="form-group">*/}
                                        {/*        <div className="form-element__wrapper mb-3">*/}
                                        {/*            <label htmlFor="" className="form-label">*/}
                                        {/*                On Demand*/}
                                        {/*            </label>*/}
                                        {/*            <label htmlFor="" className="form-control">*/}
                                        {/*                {(dynamicSubData && dynamicSubData["On Demand"]) ? dynamicSubData["On Demand"] : "N/A"}*/}
                                        {/*            </label>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {/*<div className="form-group__wrapper">*/}
                                        {/*    <div className="form-group">*/}
                                        {/*        <div className="form-element__wrapper mb-3">*/}
                                        {/*            <label htmlFor="" className="form-label">*/}
                                        {/*                Multiple Items*/}
                                        {/*            </label>*/}
                                        {/*            <label htmlFor="" className="form-control">*/}
                                        {/*                {(dynamicSubData && dynamicSubData["Multiple Items"]) ? dynamicSubData["Multiple Items"] : "N/A"}*/}
                                        {/*            </label>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {dynamicSubData && dynamicSubData.leadData && dynamicSubData.leadData.map((obj) => {
                                            return <div className="form-group__wrapper">
                                                <div className="form-group">
                                                    <div className="form-element__wrapper mb-3">
                                                        <label htmlFor="" className="form-label">
                                                            {_.startCase(obj.paramKey)}
                                                        </label>
                                                        <label htmlFor="" className="form-control">
                                                            {obj.value}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                    : <div>
                                        <div className="section__wrapper">
                                            <div className="empty-state-new-action__wrapper"  >
                                                <div className="new-action-content__wrapper">
                                                    <h4 className="sub">No Records available</h4>
                                                    <h4 className="title">Create new request</h4>
                                                    <button className="btn btn-primary"
                                                            onClick={() => navigate(`/dynamic-widget/${widgetUuid}/create`)}>New
                                                        request
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>

                </React.Fragment>
            );
        } else {
            return (
                <div className="section__wrapper">
                    <div className="empty-state-new-action__wrapper">
                        <div className="new-action-content__wrapper">
                            <h4 className="sub">Oops, something went wrong!</h4>
                        </div>
                    </div>
                </div>
            );
        }
    } else return (<React.Fragment></React.Fragment>);
}

export default DynamicWidgetConfigDetails;