import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom'
import StatCard from './components/StatCard';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useAppDispatch } from '../../store';
import { getWidgetAnalyticsForOwner } from '../../services/dashboard.service';
import dashboardStore from '../../store/reducers/dashboard.store';
import { fetchExpensesByWidgets, fetchWidgetAnalytics, fetchExpensesForLastQuater } from '../../store/actions/dashboard.action';
import { useSelector } from 'react-redux';
import NoData from '../settings/components/NoData';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

interface WidgetExpense {
    widgetName: string;
    expenseByWidget: number | null;
    widgetIcon: string;
}

interface WidgetAnalyticsData {
    [key: string]: string;
}

interface DataItem {
    widgetName: string;
    expenseByWidget: number;
    monthName?: string;
    previousMonthName?: string;
    monthBeforePreviousMonth?: string;
}

interface Props {
    data: DataItem[];
}
// interface allWidgetsExpenses {
//     [key : WidgetAnalyticsData]
// }


export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        title: {
            display: true,
            text: 'Profit sharing and earning over the period of last 3 months',
        },
    },
};

const labels = ['NOV', 'DEC', 'JAN'];

const randomNumberInRange = (min: number, max: number): number => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const data = {
    labels,
    datasets: [
        {
            label: 'Widget 1',
            data: labels.map(() => randomNumberInRange(0, 1000)),
            backgroundColor: '#41215a',
        },
        {
            label: 'Widget 2',
            data: labels.map(() => randomNumberInRange(0, 1000)),
            backgroundColor: 'rgb(247, 181, 0)',
        },
        {
            label: 'Widget 3',
            data: labels.map(() => randomNumberInRange(0, 1000)),
            backgroundColor: '#343a40',
        },
    ],
};

const OwnerDashboard = () => {
    const [limit, setLimit] = useState<number>(5);
    const [offset, setOffset] = useState<number>(0);
    const [chartData, setChartData] = useState<any>({ labels: [], datasets: [] });
    const [analyticsdata, setAnalyticsData] = useState<any>();
    const [widgetExpensesData, setWidgetExpensesData] = useState<any>([])
    const [totalExpenses, setTotalExpenses] = useState<number | null>(null);

    const dispatch = useAppDispatch();

    const {
        userDetails,
    } = useSelector<any, any>(({ user }) => user);

    const {
        widgetAnalyticsData,
        expensesPerWidgets,
        expensesLastQuater
    } = useSelector<any, any>(({ dashboard }) => dashboard);

    useEffect(() => {
        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        if (tenantGroupId) {
            dispatch(fetchWidgetAnalytics(tenantGroupId));
            dispatch(fetchExpensesByWidgets(tenantGroupId, limit, offset));
            dispatch(fetchExpensesForLastQuater(tenantGroupId))
        }
    }, [userDetails])

    useEffect(() => {
        console.log("expensesLastQuater: ", expensesLastQuater);

        const newChartData: { labels: string[], datasets: any[] } = {
            labels: [],
            datasets: []
        };
        const datasets: Record<string, Record<string, number>> = {};

        if (Array.isArray(expensesLastQuater) && expensesLastQuater.length > 0) {
            expensesLastQuater.forEach((item: any) => {
                let groupKey: string | undefined;

                if (item.monthName) {
                    groupKey = item.monthName;
                } else if (item.previousMonthName) {
                    groupKey = item.previousMonthName;
                } else if (item.monthBeforePreviousMonth) {
                    groupKey = item.monthBeforePreviousMonth;
                }

                if (groupKey) {
                    const label: any = (item.widgetName ? (item.widgetName + ' ' + groupKey) : "No data availabe for this month" + ' ' + groupKey);
                    datasets[groupKey] = datasets[groupKey] || {};
                    datasets[groupKey][label] = (datasets[groupKey][label] || 0) + item.expenseByWidget;
                }
            });

            // Generate column names since the widget names changes per each month
            const columns: string[] = [];
            Object.values(datasets).forEach((group) => {
                Object.keys(group).forEach((label) => {
                    columns.push(label);
                });
            });

            newChartData.labels = Object.keys(datasets);

            newChartData.datasets = columns.map((columnName, index) => ({
                label: columnName,
                data: Object.values(datasets).map((group) => group[columnName] || 0),
                backgroundColor: index % 3 === 0 ? '#41215a' : index % 3 === 1 ? 'rgb(247, 181, 0)' : '#343a40',
                borderColor: 'rgba(0, 0, 0, 0.5)',
                borderWidth: 1,
                // barThickness: 40, // Remove this line to let Chart.js handle the bar thickness automatically
                barPercentage: 0.9,  // Adjust as necessary (default is 0.9)
                categoryPercentage: 0.8,  // Adjust as necessary (default is 0.8)
            }));


            setChartData(newChartData);
        }

    }, [expensesLastQuater])

    useEffect(() => {

        const titleMapping: { [key: string]: string } = {
            "subscriptionCount": "Actively using app",
            "lastMonthExpense": "Last Month's Expense",
            "averagePerAppExpense": "Average Per App expense"
        };

        if (widgetAnalyticsData != null && widgetAnalyticsData != 'null') {
            setTotalExpenses(widgetAnalyticsData.allExpenses);
            const { allExpenses: _, ...otherData } = widgetAnalyticsData;
            const statCardsData = Object.entries(otherData).map(([key, amount]) => ({
                title: titleMapping[key] || key,
                amount
            }));

            setAnalyticsData(statCardsData);
        }

    }, [widgetAnalyticsData])

    useEffect(() => {
        console.log("expensesPerWidgets: ", expensesPerWidgets);
       if(expensesPerWidgets != undefined && Array.isArray(expensesPerWidgets) && expensesPerWidgets.length>0 && widgetExpensesData.length <= offset){
        let allWidgets = widgetExpensesData.concat(expensesPerWidgets);
        setWidgetExpensesData(allWidgets);
       }
    }, [expensesPerWidgets])

    const onViewMore = () => {
        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        dispatch(fetchExpensesByWidgets(tenantGroupId, limit, offset + limit));
        setOffset(offset + limit);
    }

    return (
        <div className="main-content main-spacer-x-y">
            <div className="section__wrapper app-listing-section__wrapper">
                <div className="app-listing-section-title__wrapper">
                    <span>Dashboard</span>
                </div>
                <div className="db-grid-1__wrapper">
                    <div className="db-grid-inner__wrapper db-grid-2__wrapper">
                        <div className="sub-nav-inner-container stats-widgets__wrapper">
                            {analyticsdata?.map((data: any, index: any) => (
                                <StatCard key={index} title={data.title} amount={data.amount} />
                            ))}
                        </div>
                        <div className="card panel content-holder-panels">
                            <div className="panel-header">
                                <div className="panel-header-content__wrapper">
                                    <h4 className="panel-header-title">Expense by Apps</h4>
                                    <p className="panel-header-subtitle">
                                        Your expense by each apps in previous month
                                    </p>
                                </div>
                            </div>
                            <div className="panel-table-listing__wrapper">
                                <div className="table-parent__wrapper">
                                    <table className="table table-default">
                                        <tbody>
                                            <tr className="table-header">
                                                <th className="txt-left">App Names</th>
                                                {/* <th className="txt-right">Amount(Rs.)</th>
                                                <th className="txt-right">Tax(Rs.)</th> */}
                                                <th className="txt-right">Total Expenses(Rs.)</th>
                                            </tr>
                                            {widgetExpensesData && widgetExpensesData.length > 0 ? (
                                                widgetExpensesData?.map((widget: WidgetExpense, index: number) => (
                                                    <tr className="table-row" key={index}>
                                                        <td>
                                                            <div className="feature-details__wrapper">
                                                                <div
                                                                    className="card app-media-holder"
                                                                    style={{
                                                                        backgroundImage: `url(${widget.widgetIcon})`
                                                                    }}
                                                                ></div>
                                                                <div className="feature-details-title">
                                                                    <span className="title">{widget.widgetName}</span>
                                                                    {/* <span className="small-desc">Bill in 4th of every month</span> */}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="txt-right">{widget.expenseByWidget !== null ? widget.expenseByWidget : "N/A"}</td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={2}>
                                                    <h5 style={{ textAlign: 'center' }}>No Data Available</h5>
                                                    </td>
                                                </tr>
                                            )}
                                            {widgetExpensesData && <div className="panel-table-list-item">
                                                <div className="panel-table-listing-item__inner">
                                                    <a onClick={onViewMore}>View All</a>
                                                </div>
                                            </div>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {/* panel table */}
                        </div>
                        <div className="card panel content-holder-panels">
                            <div className="panel-header">
                                <div className="panel-header-content__wrapper">
                                    <h4 className="panel-header-title">Expense Overview</h4>
                                    <p className="panel-header-subtitle">
                                        Profit sharing and earning over the period of last 3 months
                                    </p>
                                </div>
                            </div>
                            <div className="panel-content">
                                {chartData.labels.length > 0 ? (
                                    <Bar data={chartData} />
                                ) : (
                                    <h5 style={{ textAlign: 'center' }}>No Data Available</h5>)}
                            </div>
                        </div>
                    </div>
                    {/* <div className="db-grid-inner__wrapper db-grid-2__wrapper">
                        <div className="card sp-styled-type-1" style={{ minHeight: "18%" }}>
                            <div className="stats-container__wrapper">
                                <div className="card">
                                    <i className="bi bi-bar-chart-fill" />
                                </div>
                                <div className="stats-details-content__wrapper">
                                    <span className="stats-title">Total Expenses(Rs.)</span>
                                    <span className="stats-data">{totalExpenses ? totalExpenses : ""}</span>
                                </div>
                            </div>
                            <div className="stats-container__wrapper">

                            </div>
                            <div className="stats-container__wrapper">

                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default OwnerDashboard;
