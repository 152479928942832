import React from 'react'
import {WidgetRate} from "../../pages/single-app/models/response/single-app.model";

type Props = {
    appDetails: any
}

const ChargeableTypeFiveComponent = ({appDetails}: Props) => {
    return (
        <div className="panel-table-content-list">
            {appDetails.widgetDetails && Array.isArray(appDetails.widgetDetails.WidgetRate) && appDetails.widgetDetails.WidgetRate.map((value: WidgetRate, index1: number) => {
                return (
                    <React.Fragment key={index1}>
                        <table style={{border: "1px solid black"}}>
                            <thead>
                            <th style={{
                                border: "1px solid black",
                                textAlign: 'center',
                                fontWeight: 'bold',
                                backgroundColor: '#e2e4e9'
                            }}>Reach
                            </th>
                            <th style={{
                                border: "1px solid black",
                                textAlign: 'center',
                                fontWeight: 'bold',
                                backgroundColor: '#e2e4e9'
                            }}>Price per SMS (Excluding tax)(Rs.) Short
                                Message-124 Characters
                            </th>
                            <th style={{
                                border: "1px solid black",
                                textAlign: 'center',
                                fontWeight: 'bold',
                                backgroundColor: '#e2e4e9'
                            }}>Price per SMS (Excluding tax)(Rs.) Long
                                Message-284 Characters
                            </th>
                            <th style={{
                                border: "1px solid black",
                                textAlign: 'center',
                                fontWeight: 'bold',
                                backgroundColor: '#e2e4e9'
                            }}>Price per SMS (Excluding tax)(Rs.) Essay Type
                                Message-444 Characters
                            </th>
                            <th style={{
                                border: "1px solid black",
                                textAlign: 'center',
                                fontWeight: 'bold',
                                backgroundColor: '#e2e4e9'
                            }}>Price per SMS (Excluding tax)(Rs.) Essay Type
                                Message-600 Characters
                            </th>
                            <th style={{
                                border: "1px solid black",
                                textAlign: 'center',
                                fontWeight: 'bold',
                                backgroundColor: '#e2e4e9'
                            }}>Price per SMS (Excluding tax)(Rs.) Essay Type
                                Message-960 Characters
                            </th>
                            </thead>
                            <tbody>
                            {Array.isArray(value.RateCardSlabDetails) && value.RateCardSlabDetails.map((rateCardSlabType, index2) => {
                                return (
                                    <tr style={{border: "1px solid black"}}>
                                        <td style={{
                                            border: "1px solid black",
                                            textAlign: 'center',
                                            fontWeight: 'bold',
                                            backgroundColor: '#e2e4e9'
                                        }}>
                                            {rateCardSlabType.discription}
                                        </td>
                                        {rateCardSlabType?.RateCardSalbRate.length > 0 && rateCardSlabType.RateCardSalbRate?.map((rates, index) => {
                                            return (
                                                <td style={{
                                                    border: "1px solid black",
                                                    textAlign: 'center'
                                                }}>
                                                    {rates.rate}
                                                </td>
                                            )
                                        })
                                        }
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </React.Fragment>
                )
            })}
        </div>
    )
}

export {ChargeableTypeFiveComponent}