import React from "react";
import Image from 'antd';

interface Props {
    file: any;
    imageUrl: string;
}

function ProfileIdentificationImage({ file, imageUrl }: Props) {

    const onPressPreview = () => {
        // const imageElement = document.createElement('img');
        // imageElement.src = imageUrl;
        // document.body.appendChild(imageElement);
        console.log("imageURL")
    }

    return <div className="panel-table-list-item">
        <div className="panel-table-listing-item__inner">
            <div className="feature-details__wrapper">
                <div className="card app-media-holder red-shade sm-center-icon-card">
                    <span className="icon__wrapper"> <i className="bi bi-paperclip" /></span>
                </div>
                <div className="feature-details-title__wrapper no-shade">
                    <span className="title">{file}</span>
                </div>
            </div>
        </div>
        <div className="panel-table-listing-item__inner">
            <div className="panel-table-right-align-item__wrapper">
                <div className="panel-table-right-align-item">
                    <a href={imageUrl} className="" type="button" onClick={onPressPreview}><i className="bi bi-eye-fill" />&nbsp;Preview</a>
                </div>
            </div>
        </div>
    </div>
}

export default ProfileIdentificationImage;