import axiosInstance from "../axios-instance";
import * as CONSTANTS from '../constants';

const getSurveyList = async (tenantUuid: string, widgetUuid: string) => {
    return await axiosInstance({
        method: `get`,
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widget/survey/${tenantUuid}/${widgetUuid}`
    })
}

const postSurveyData = async (data: any, tenantUuid: string, widgetUuid: string) => {
    return await axiosInstance({
        method: `post`,
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widget/survey/${tenantUuid}/${widgetUuid}`,
        data: data
    })
}

const getSurveyIndividualStatus = async (tenantUuid: string, surveyId: string) => {
    return await axiosInstance({
        method: `get`,
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widget/survey/view/${tenantUuid}/${surveyId}`
    })
}

const putQuickSurveyStatus = async (data: any, tenantUuid: string, surveyId: string) => {
    return await axiosInstance({
        method: `put`,
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widget/survey/update/${tenantUuid}/${surveyId}`,
        data: data
    })
}

const getSurveyLogs = async (tenantUuid: string, surveyId: string) => {
    return await axiosInstance({
        method: `get`,
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widget/survey/status/${tenantUuid}/${surveyId}`
    })
}

export {
    getSurveyList,
    postSurveyData,
    getSurveyIndividualStatus,
    putQuickSurveyStatus,
    getSurveyLogs
}