import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { SlideshowLightbox } from 'lightbox.js-react';
import { useAppDispatch } from '../../../store';
import { SimilarApps, SingleAppModel, WidgetFeatures, WidgetRate, WidgetScreenshots } from '../models/response/single-app.model';
import { InnerTopFeatureItem } from './InnerTopFeatureItem';
import { SingleAppWidget } from './SingleAppWidget';
import { SliderListingItem } from './SliderListingItem';
import { Card } from "antd";

type Props = {
    rateDetails: any
}

const RateItemComponent = ({ rateDetails }: Props) => {
    // console.log("############", JSON.parse(rateDetails.RatecardAddtionalDetails.detailsJson));

    let additionalData = rateDetails?.RatecardAddtionalDetails?.detailsJson ? (typeof rateDetails?.RatecardAddtionalDetails?.detailsJson === 'string' ? JSON.parse(rateDetails?.RatecardAddtionalDetails?.detailsJson) : rateDetails?.RatecardAddtionalDetails?.detailsJson) : null;
    let features = additionalData ? additionalData.features : [];
    return (
        <>
        <div className="pricing-grid-list-item bg-yellow">
            <div className="pricing-grid-list-content">
                <h4 className="pricing-item-title">{rateDetails.title}</h4>
                <p className="pricing-item-desc">
                    {rateDetails.description}
                </p>
                {rateDetails.rate && <span className="pricing-item-value__wrapper">
                    <span className="currancy">{rateDetails.currencyType ? rateDetails.currencyType : 'LKR'}&nbsp;</span>
                    <span className="value">{rateDetails.rate}</span>
                    <span className="type">/ {rateDetails.RateCardId == 1 ? 'Per Transaction' : rateDetails.RateCardId == 2 ? 'Per Month' : rateDetails.RateCardId == 3 ? 'One time' : rateDetails.RateCardId == 5 ? 'Per Transaction' : (rateDetails.RateCardId == 6 && rateDetails.currencyType == "USD") ? 'Per user / month' : rateDetails.RateCardId == 6 ? 'Per Month' : rateDetails.RateCardId == 7 ? 'Per Transaction' : rateDetails.RateCardId == 8 ? 'Per Year' : ''}</span>
                    <span>&nbsp;+ Tax</span>
                </span>}
                {additionalData && rateDetails && rateDetails.RatecardAddtionalDetails && <ul className="pricing-grid-list-options-list">
                    {additionalData.description && <li className="options-item">{additionalData.description}</li>}
                    {additionalData.detail1 && <li className="options-item">{additionalData.detail1}</li>}
                    {additionalData.detail2 && <li className="options-item">{additionalData.detail2}</li>}
                    {additionalData.detail3 && <li className="options-item">{additionalData.detail3}</li>}
                    {additionalData.detail4 && <li className="options-item">{additionalData.detail4}</li>}
                    {additionalData.detail5 && <li className="options-item">{additionalData.detail5}</li>}
                </ul>}
            </div>
        </div>
        {features && features.length > 0 && features.map((obj: any, index: number) => {
            return (
                <div className="col-md-6">
                    <Card
                        title={obj.title}
                        className={'bizemail_model_header ' + (index === 0 ? 'bizemail_model_header_standard' : 'bizemail_model_header_premium')}
                    >
                        {obj.detail1 && <p>✅ {obj.detail1}</p>}
                        {obj.detail2 && <p>✅ {obj.detail2}</p>}
                        {obj.detail3 && <p>✅ {obj.detail3}</p>}
                        {obj.detail4 && <p>✅ {obj.detail4}</p>}
                        {obj.detail5 && <p>✅ {obj.detail5}</p>}
                        {obj.detail6 && <p>✅ {obj.detail6}</p>}
                    </Card>
                </div>
            )
        })}
        </>
    )
}

export { RateItemComponent };