import axiosInstance from '../axios-instance';
import * as CONSTANTS from '../constants';
import { BusinessDetailsModel } from '../pages/settings/models/request/business-details.model';
import { LongNumberPutRequest } from '../pages/settings/models/request/long-number-put.model';
import { MaskPostRequest } from '../pages/settings/models/request/mask-post-request.model';
import { MaskPutRequest } from '../pages/settings/models/request/mask-put-request.model';
import { MsisdnPostRequest } from '../pages/settings/models/request/msisdn-post-request.model';
import { MsisdnPutRequest } from '../pages/settings/models/request/msisdn-put-request.model';
import { OwnerDetailsModel } from '../pages/settings/models/response/owner-details.model';
import { PackageChangeRequest } from '../pages/settings/models/request/package-change.model';
import { EnterpriseStatusRequest } from '../pages/settings/models/request/enterprise-status.modal';

export const getProfileDetails = (uuid: string) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/settings/user-permission/profile/${uuid}`
    });
}

const editUserDetails = (req: OwnerDetailsModel, uuid: string) => {
    return axiosInstance({
        method: "PUT",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/ent/settings/user-details-update/${uuid}`,
        data: req
    });
}

const unsubcribeApp = (tenantGroupId: string, widgetId: string) => {
    return axiosInstance({
        method: "put",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/settings/added-apps/unsubscribed-apps/${tenantGroupId}/${widgetId}`,
    })
}

const getAppList = (tenantGroupId: string, key: string, limit: number, offset: number) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/settings/added-apps/subscribed-apps/${tenantGroupId}/${key}/${limit}/${offset}`,
    })
}

const getUnsubscribedAppList = (tid: string, searchKey: string) => {
    console.log("tid", tid)
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/settings/added-apps/unsubscribed-apps/${tid}/${searchKey}`,
    })
}

const getBusinessProfileDetails = (uuid: string) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/settings/business-details/enterprise-details/${uuid}`,
    });
}

const editBusinessProfileDetails = (req: BusinessDetailsModel, entId: string, tid: string) => {
    return axiosInstance({
        method: "put",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/settings/business-details/edit-business-details/${entId}/${tid}`,
        data: req
    });
}

const getOwnerDetails = (uuid: string) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/settings/business-details/owner-details/${uuid}`,
    });
}

const getPartnerDetails = (uuid: string) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/settings/business-details/partner-details/${uuid}`,
    });
}

const getPaymentMethodDetails = (req: any) => {
    return axiosInstance({
        method: "get",
        url: "",
        data: req
    });
}

const getProfileIdentificationImages = (req: any) => {
    return axiosInstance({
        method: "get",
        url: "",
        data: req
    });
}

const getBusinessRegistrationDocuments = (req: any) => {
    return axiosInstance({
        method: "get",
        url: "",
        data: req
    });
}

const getSignedDocuments = (req: any) => {
    return axiosInstance({
        method: "get",
        url: "",
        data: req
    });
}

const getPartnerRequests = (uuid: any) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/ent/revamp/malist/${uuid}`,
    })
}

const deletePartnerRequest = (req: any, tid: string, uuid: string) => {
    return axiosInstance({
        method: "delete",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/settings/business-details/partner-details/${tid}/${uuid}`,
        data: req
    })

}
const sendPartnerRequest = (req: any, tid: string, uuid: string) => {
    return axiosInstance({
        method: "post",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/ent/revamp/marketingent/change/${tid}`,
        data: {
            "currentMaId": "",
            "requestMaId": uuid,
            "remark": ""
        }
    })

}

const deletePartner = (tid: string, uuid: string, partnerLevel: string) => {
    return axiosInstance({
        method: "delete",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/settings/user-details/deactivate-partner/${tid}/${uuid}/${partnerLevel}`
    })
}

const getIndustryList = () => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/settings/business-details/industry-types`,
    })
}

const getMsisdnList = (tenantId: string, msisdn: string) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/settings/number-configures/msisdn/${tenantId}?mobile=${msisdn}`
    })
}

const postMsisdn = (tenantId: string, req: MsisdnPostRequest) => {
    return axiosInstance({
        method: "post",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/settings/number-configures/msisdn/${tenantId}`,
        data: req
    })
}

const putMsisdn = (tenantId: string, req: MsisdnPutRequest) => {
    return axiosInstance({
        method: "put",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/settings/number-configures/msisdn/${tenantId}`,
        data: req
    })
}

const deleteMsisdn = (tenantId: string, msisdnId: string) => {
    return axiosInstance({
        method: "put",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/settings/number-configures/delete-msisdn/${tenantId}/${msisdnId}`
    })
}

const getMaskList = (tenantId: string, mask: string) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/ent/mask/${tenantId}?mask=${mask}`
    })
}

const postMask = (req: MaskPostRequest) => {
    // console.log("########## 2", req);
    return axiosInstance({
        method: "post",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/ent/mask`,
        data: req
    })
}

const putMask = (req: MaskPutRequest) => {
    return axiosInstance({
        method: "put",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/ent/mask`,
        data: req
    })
}

const deleteMask = (tenantId: string, maskId: string) => {
    return axiosInstance({
        method: "DELETE",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/ent/mask/${tenantId}/${maskId}`
    })
}

const getMaskDuplicate = (mask: string) => {
    return axiosInstance({
        method: "GET",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/ent/mask_duplicate_check/?keyword=${mask}`
    })
}


const getEnterpriseDocs = (uuid: any) => {
    console.log("service: ", uuid)
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/settings/documents/get-enterprise-documents/${uuid}`
    })
}

const getAvailablePorts = () => {
    return axiosInstance({
        method: "GET",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/user/ports`
    })
}

const getSelectedPort = (tenantId: string) => {
    return axiosInstance({
        method: "GET",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/user/ports/${tenantId}`
    })
}

const postPort = (tenantId: string, port: string) => {
    return axiosInstance({
        method: "POST",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/user/ports/${tenantId}?port=${port}`
    })
}

const getBusinessesList = () => {
    return axiosInstance({
        method: "GET",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}`
    })
}

const getLongNumber = (tenantId: string) => {
    return axiosInstance({
        method: "GET",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/settings/number-configures/get-long-number/${tenantId}`
    })
}

const putLongNumber = (tenantId: string, req: LongNumberPutRequest) => {
    return axiosInstance({
        method: "PUT",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/settings/number-configures/add-long-number/${tenantId}`,
        data: req
    })
}
const updatePartnerAccessLevel = (uuid: string, req: any) => {
    return axiosInstance({
        method: "POST",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/settings/change-admin-partner/${uuid}`,
        data: req
    })
}


const getPaymentInfo = (tenantId: string) => {
    return axiosInstance({
        method: "GET",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/settings/billing/entBalance/${tenantId}`
    })
}

const getWidgetInfoList = (tenantId: string, type: string, limit: number, offset: number, searchTerm: string) => {
    return axiosInstance({
        method: "GET",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/settings/billing/billing-info/${tenantId}/${type}?limit=${limit}&offset=${offset}&searchTerm=${searchTerm}`
    })
}

const putPaymentMethod = (tenantId: string, widgetId: string, req: any) => {
    return axiosInstance({
        method: "PUT",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/settings/billing/payment-method/${tenantId}/${widgetId}`,
        data: req
    })
}

const getPayOptionsList = (tenantId: string, searchText: string) => {
    return axiosInstance({
        method: "GET",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/settings/billing/payment-methods/${tenantId}/${searchText}`
    })
}

const putPaymentRatePan = (tenantId: string, widgetId: string, req: PackageChangeRequest) => {
    return axiosInstance({
        method: "PUT",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/settings/billing/package/${tenantId}/${widgetId}`,
        data: req
    })
}

const getPaymentHistoryList = (tenantGroupId: string, limit: number, offset: number) => {
    return axiosInstance({
        method: "GET",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/settings/billing/payment-history/${tenantGroupId}/${limit}/${offset}`
    })
}

const updateEntStatus = (tenantGroupId: string, req: EnterpriseStatusRequest) => {
    return axiosInstance({
        method: "PUT",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/ent/status-change/${tenantGroupId}`,
        data: req
    })
}

const updateEntLogo = (req: any, tenantGroupId: string, uuid: string) => {
    return axiosInstance({
        method: "put",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/settings/business-brand/update-logo/${tenantGroupId}/${uuid}`,
        headers: { 'Content-Type': 'multipart/form-data' },
        data: req
    });
}

const getNoPaymentMsisdnList = (tenantGroupId: string) => {
    return axiosInstance({
        method: "GET",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/settings/number-configures/get-msisdn-list/${tenantGroupId}`
    })
}

const addPaymentMsisdn = (tenantGroupId: string, msisdn: string) => {
    return axiosInstance({
        method: "post",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/settings/payment/msisdn/${tenantGroupId}/${msisdn}`
    });
}

const getAddGenieCardView = (tenantGroupId: string) => {
    return axiosInstance({
        method: "GET",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/genie/addCard/${tenantGroupId}`
        // url: `${CONSTANTS.REACT_APP_BACKEND_URL}/widget/genie/revamp/addCard/${tenantGroupId}`
    })
}

const getPartnerSearchSuggestions = (inputValue: any) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/settings/user-details/search-partner-details/${inputValue}`,
        headers: { 'Content-Type': 'multipart/form-data' },
    });
}

const editPaymentMsisdn = (tenantGroupId: string, msisdn: string, isPrimary: boolean) => {
    return axiosInstance({
        method: "put",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/settings/payment/${tenantGroupId}/${msisdn}/${isPrimary}`
    });
}

const deletePaymentMsisdn = (tenantGroupId: string, msisdn: string) => {
    return axiosInstance({
        method: "PUT",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/settings/payment/${tenantGroupId}/${msisdn}`
    });
}

const updateGenieCardDetails = async (tenantGroupId: string, invoiceNumber: string) => {
    return await axiosInstance({
        method: `post`,
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/widget/genie/revamp/saveCard/${tenantGroupId}/${invoiceNumber}`
    })
};

const deleteCardDetails = (tenantGroupId: string, cardId: string) => {
    return axiosInstance({
        method: "DELETE",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/widget/genie/revamp/delete/${tenantGroupId}/${cardId}`
    });
}

const updateGenieCard = (tenantGroupId: string, invoiceNo: string, status: string) => {
    return axiosInstance({
        method: "PUT",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/settings/payment/genieCard/${tenantGroupId}/${invoiceNo}/${status}`
    })
}

const retrySubscription = (tenantGroupId: string, widgetUuid: string) => {
    return axiosInstance({
        method: "POST",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widgets/better-hr/manual-recharge/${tenantGroupId}/${widgetUuid}`
    })
}

const getRenewalChargingRate = (widgetType: string, index: number, ratePlanId: string) => {
    return axiosInstance({
        method: "GET",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/settings/billing-and-plans/sub/renewal-charging-amount/${widgetType}/${index}/${ratePlanId}`
    })
}

const getDiscountInfo = (tenantId: string) => {
    return axiosInstance({
        method: "GET",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/discount/discount-configs/${tenantId}`
    })
}

const updateOwnerDetails = (req: any, userId: string) => {
    return axiosInstance({
        method: "put",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/settings/owner-details/edit-owner-details/${userId}`,
        data: req
    });
}

export {
    editUserDetails,
    unsubcribeApp,
    getAppList,
    getBusinessProfileDetails,
    editBusinessProfileDetails,
    getPaymentMethodDetails,
    getProfileIdentificationImages,
    getBusinessRegistrationDocuments,
    getSignedDocuments,
    getPartnerDetails,
    getOwnerDetails,
    getPartnerRequests,
    deletePartnerRequest,
    sendPartnerRequest,
    getIndustryList,
    getMsisdnList,
    postMsisdn,
    putMsisdn,
    deleteMsisdn,
    deletePartner,
    getUnsubscribedAppList,
    getMaskList,
    postMask,
    putMask,
    deleteMask,
    getMaskDuplicate,
    getEnterpriseDocs,
    getAvailablePorts,
    getSelectedPort,
    postPort,
    getBusinessesList,
    getLongNumber,
    putLongNumber,
    updatePartnerAccessLevel,
    getPaymentInfo,
    getWidgetInfoList,
    putPaymentMethod,
    getPayOptionsList,
    putPaymentRatePan,
    getPaymentHistoryList,
    updateEntStatus,
    updateEntLogo,
    getNoPaymentMsisdnList,
    addPaymentMsisdn,
    getAddGenieCardView,
    getPartnerSearchSuggestions,
    editPaymentMsisdn,
    deletePaymentMsisdn,
    updateGenieCardDetails,
    deleteCardDetails,
    updateGenieCard,
    retrySubscription,
    getRenewalChargingRate,
    getDiscountInfo,
    updateOwnerDetails
}
