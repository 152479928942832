import React, { CSSProperties, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import {
    fetchSingleApp,
    fetchKycList,
    fetchKycUsernames,
    fetchKycConfigs,
    fetchFilterKycList,
    fetchKycListForCsv,
    submitKycConfigs,
    submitKYCAppSubscription,
    fetchRedisStatus,
    fetchKycRedisStatus
} from '../../../store/actions';
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from '@emotion/react';
import AuthHandler from '../../../helper/AuthHandler';
import TableIcon from '../../../assets/images/icons/app-media-placeholder.svg';
import { PageHeaderStatic } from "../components/PageHeaderStatic";
import { DatePicker, Input, Select, message } from "antd";
import moment from "moment";
import dayjs, { Dayjs } from "dayjs";
import SimpleReactValidator from "simple-react-validator";
import { KycFilterModel } from "./models/request/kyc-filter.model";
import { getKycListForCsv } from "../../../services/kyc.service";
import { v4 as uuid } from 'uuid';
import { ProgressBar } from "./components/ProgressBar";

const { RangePicker } = DatePicker;
const { Option } = Select;

const KycAPI = ({ auth }: any) => {
    const { wid } = useParams();
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [username, setUsername] = useState<string[]>([]);
    const [msisdn, setMsisdn] = useState<string>('');
    const [event, setEvent] = useState<string>('');
    const [displayFilter, setDisplayFilter] = useState<boolean>(false);
    const [filtered, setFiltered] = useState<boolean>(false);
    const [showProgress, setShowProgress] = useState<boolean>(false);
    const [newButtonText, setNewButtonText] = useState<string>('');
    const [newLinkUrl, setNewLinkUrl] = useState<string>('');
    const [provisionId, setProvisionId] = useState<string>('');
    const [provisionStatus, setProvisionStatus] = useState<string>("");
    const [intervalState, setIntervalState] = useState<any>(null);
    const [progress, setProgress] = useState<number>(5);
    const [fetchRedisStatusOnGoing, setFetchRedisStatusOnGoing] = useState<boolean>(false);

    const [validator] = useState(new SimpleReactValidator({
        autoForceUpdate: this
    }));

    const navigate = useNavigate();

    const {
        singleAppDetails
    } = useSelector(({ apps }: any) => apps);
    const { success, loading, error, kycList, userNames, csvData, kycConfigs, redisStatus, redisStatusSuccess, redisStatusFailed } = useSelector(({ kyc }) => kyc);
    const { userDetails } = useSelector(({ user }: any) => user);

    const dispatch = useAppDispatch();

    useEffect(() => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        dispatch(fetchSingleApp(wid!, tenantGUID));
    }, [wid, userDetails]);

    useEffect(() => {
        if (auth.realmRoles.includes('mp_enterprise_owner')) {
            if ( kycConfigs && kycConfigs.apiDetails) {
                setNewButtonText('API Configurations');
            } else {
                setNewButtonText('Get your client id & secret');
            }
            setNewLinkUrl(`/dynamic-api/${wid}/configs`)

        }
    }, [kycConfigs]);

    const setKycList = () => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        dispatch(fetchKycList(tenantGUID, wid!));
        if (!auth.roles.includes('mp_enterprise_user')) {
            dispatch(fetchKycUsernames(tenantGUID));
        }
        dispatch(fetchKycConfigs(tenantGUID, wid!));
    }

    useEffect(() => {
        setKycList();
    }, [wid, userDetails]);

    const onChangeDates = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
        if (dateStrings[0] === '') {
            setKycList();
        }
        setStartDate(moment(dateStrings[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss'));
        setEndDate(moment(dateStrings[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss'));
    };

    const onChangeUsername = (value: any) => {
        setUsername(value);
    };

    const onClearOptions = () => {
        setFiltered(false);
        setKycList();
    };

    const onChangeMsisdn = (e: any) => {
        if (!e.target.value) {
            setKycList()
        } else {
            setMsisdn(e.target.value);
        }
    };

    const onPressApply = () => {
        if (validator.allValid() && ((Array.isArray(username) && username.length > 0) || startDate != '' || (Array.isArray(event) && event.length > 0) || msisdn != '')) {
            setFiltered(true);
            const data: KycFilterModel = {
                'username': username,
                'startDate': startDate,
                'endDate': endDate,
                'event': event,
                'msisdn': msisdn
            };

            const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
            dispatch(fetchFilterKycList(tenantGUID, wid!, data));

        } else {
            message.warning('At least one filter option should be selected!');
        }
    };

    const onPressDownload = () => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        let dataArray: KycFilterModel = {
            'username': username,
            'startDate': startDate,
            'endDate': endDate,
            'event': event,
            'msisdn': msisdn
        }
        // dispatch(fetchKycListForCsv(tenantGUID, wid!, dataArray));
        getKycListForCsv(tenantGUID, wid!, dataArray).then((response) => {
            if (response.data.data !== null) {
                const url = window.URL.createObjectURL(new Blob([response.data.data]));
                const link = document.createElement('a');
                link.href = url;
                const fileName = `Details List_${moment(new Date()).format('YYYY-MM-DDTHH_mm_ss')}.csv`; // whatever your file name .
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        }).catch((error) => {

        });
    }

    const getClientDetails = async () => {
        // console.log("#############");

        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";

        if (singleAppDetails !== null) {
            let prvId = uuid();
            setProvisionId(prvId);

            if (singleAppDetails && singleAppDetails.widgetDetails) {
                dispatch(submitKYCAppSubscription(tenantGUID, singleAppDetails.widgetDetails.uuid, prvId));
            }
        }
        setShowProgress(true);
    }

    const getProvisionStatus = () => {
        try {
            const interval = setInterval(() => {
                console.log("provisionId: ", provisionId)
                if(provisionId) {
                    // dispatch(fetchRedisStatus(userDetails.OwnedEnterprises[0].Enterprise.uuid))
                    dispatch(fetchKycRedisStatus(provisionId))
                    setFetchRedisStatusOnGoing(true);
                }
                setIntervalState(interval)
            }, 1000)
        } catch (e) {
            console.log(e)
            clearInterval(intervalState)
        }
    }

    const onViewApiConfigs = () => {
        // this.props.history.push(`/${this.state.paths[Constants.BASEURLPATHLENGTH + 1]}/${this.state.paths[Constants.BASEURLPATHLENGTH + 2]}/api-configurations`)
        navigate(`/dynamic-api/${wid}/configs`)
    }


    useEffect(() => {
        if(fetchRedisStatusOnGoing) {
            if (redisStatusSuccess && redisStatus && redisStatus.data) {

                // if (redisStatus.data.error == "false") {
                if (!redisStatus.data.error) {
                    if (Object.keys(redisStatus.data).length !== 0 && redisStatus.data.consumerDetails === "DONE") {

                        setShowProgress(false);
                        setProvisionStatus("");
                        setProvisionId('')
                        onViewApiConfigs();
                        clearInterval(intervalState)
                        setKycList();
                        setFetchRedisStatusOnGoing(false);
                    }
                    if (redisStatus.data.adminUserLogIn === "DONE" && redisStatus.data.UserLogIn === "PROCESSING" && redisStatus.data.appValidation === "NOT_STARTED" && redisStatus.data.newAppCreation === "NOT_STARTED" && redisStatus.data.defaultSubscription === "NOT_STARTED" && redisStatus.data.apiSubscription === "NOT_STARTED" && redisStatus.data.consumerDetails === "NOT_STARTED") {
                        setProvisionStatus("Validating user account...");
                        setProgress(5);
                    } else if (redisStatus.data.adminUserLogIn === "DONE" && redisStatus.data.UserLogIn === "DONE" && redisStatus.data.appValidation === "PROCESSING" && redisStatus.data.newAppCreation === "NOT_STARTED" && redisStatus.data.defaultSubscription === "NOT_STARTED" && redisStatus.data.apiSubscription === "NOT_STARTED" && redisStatus.data.consumerDetails === "NOT_STARTED") {
                        setProvisionStatus("Validating application...");
                        setProgress(15);
                    } else if (redisStatus.data.adminUserLogIn === "DONE" && redisStatus.data.UserLogIn === "DONE" && redisStatus.data.appValidation === "DONE" && redisStatus.data.newAppCreation === "PROCESSING" && redisStatus.data.defaultSubscription === "NOT_STARTED" && redisStatus.data.apiSubscription === "NOT_STARTED" && redisStatus.data.consumerDetails === "NOT_STARTED") {
                        setProvisionStatus("Creating new application...");
                        setProgress(25);
                    } else if (redisStatus.data.adminUserLogIn === "DONE" && redisStatus.data.UserLogIn === "DONE" && redisStatus.data.appValidation === "DONE" && redisStatus.data.newAppCreation === "DONE" && redisStatus.data.defaultSubscription === "PROCESSING" && redisStatus.data.apiSubscription === "NOT_STARTED" && redisStatus.data.consumerDetails === "NOT_STARTED") {
                        setProvisionStatus("Creating new application...");
                        setProgress(40);
                    } else if (redisStatus.data.adminUserLogIn === "DONE" && redisStatus.data.UserLogIn === "DONE" && redisStatus.data.appValidation === "DONE" && redisStatus.data.newAppCreation === "DONE" && redisStatus.data.defaultSubscription === "DONE" && redisStatus.data.apiSubscription === "PROCESSING" && redisStatus.data.consumerDetails === "NOT_STARTED") {
                        setProvisionStatus("Subscribing to the API...");
                        setProgress(80);
                    } else if (redisStatus.data.adminUserLogIn === "DONE" && redisStatus.data.UserLogIn === "DONE" && redisStatus.data.appValidation === "DONE" && redisStatus.data.newAppCreation === "DONE" && redisStatus.data.defaultSubscription === "DONE" && redisStatus.data.apiSubscription === "DONE" && redisStatus.data.consumerDetails === "PROCESSING") {
                        setProvisionStatus("Subscribing to the API...");
                        setProgress(90);
                    } else if (redisStatus.data.adminUserLogIn === "DONE" && redisStatus.data.UserLogIn === "DONE" && redisStatus.data.appValidation === "DONE" && redisStatus.data.newAppCreation === "DONE" && redisStatus.data.defaultSubscription === "DONE" && redisStatus.data.apiSubscription === "DONE" && redisStatus.data.consumerDetails === "DONE") {
                        setProvisionStatus("API configuration has been completed successfully...");
                        setProgress(100);
                    }
                } else {
                    clearInterval(intervalState);
                    setFetchRedisStatusOnGoing(false);
                    message.error('Something went wrong!');
                }
            } else {
                if (redisStatusFailed) {
                    clearInterval(intervalState)
                    setFetchRedisStatusOnGoing(false);
                    message.error('Something went wrong!');
                }
            }
        }
    }, [redisStatus])

    const renderKycUsernames = () => {
        return (
            Array.isArray(userNames) && userNames.length > 0 && userNames.map((value, index) => {
                return (
                    <Option key={index} value={value.userName}>{value.userName}</Option>
                )
            })
        )
    };

    const renderData = () => {
        return (
            <div className="section__wrapper">
                <div className="advanced-filter-btn__wrapper">
                    <div className="btn btn-primary advanced-filter-trigger-button" onClick={() => setDisplayFilter(!displayFilter)}>
                        <i className="bi bi-filter-circle"></i>
                        Advanced filter
                    </div>
                </div>

                {displayFilter && <div className=" card panel  advanced-filter__wrapper">
                    <div className=" advanced-filter-inner">
                        <div className="advanced-filter-item__wrapper">
                            <div className="form-group">
                                <label htmlFor="month">Select Date Range</label>
                                <RangePicker
                                    format='YYYY-MM-DD HH:mm:ss'
                                    onChange={onChangeDates}
                                    showTime={{
                                        hideDisabledOptions: true,
                                        defaultValue: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('11:59:59', 'HH:mm:ss')],
                                    }}
                                    disabledDate={(current) => {
                                        return dayjs() <= current
                                    }}
                                // value={this.state.month}
                                />
                            </div>
                        </div>
                        <div className="advanced-filter-item__wrapper">
                            {
                                !auth.roles.includes('mp_enterprise_user') ? (
                                    <div className="form-group">
                                        <label>Username</label>
                                        <br />
                                        <Select
                                            // filterOption={(input, option) =>
                                            //     option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            // }
                                            className="form-control antd-form-control"
                                            // style={{ width: 200 }}
                                            mode="multiple"
                                            allowClear
                                            placeholder="Select Username"
                                            value={username}
                                            onChange={(value) => onChangeUsername(value)}
                                            onClear={onClearOptions}
                                        >
                                            {renderKycUsernames()}
                                        </Select>
                                    </div>
                                ) : null
                            }
                        </div>
                        <div className="advanced-filter-item__wrapper">
                            <div className="form-group">
                                <label htmlFor="enterprise">MSISDN</label>
                                <Input
                                    id='msisdn'
                                    placeholder="Enter MSISDN"
                                    value={msisdn}
                                    onChange={(value) => onChangeMsisdn(value)}
                                >
                                </Input>
                                {/* <small className="errorText">{this.validator.message('msisdn', this.state.msisdn, 'numeric|max:10')}</small> */}
                            </div>
                        </div>
                        <div className="advanced-filter-item__wrapper flex-justi-end flex-align-end">
                            <button
                                className="btn btn-primary"
                                onClick={onPressApply}
                            >
                                Apply
                            </button>
                            <button className="btn btn-primary"
                                onClick={onPressDownload}
                            >
                                Download
                            </button>
                        </div>
                        {/* <div className="advanced-filter-item__wrapper">Item</div> */}
                    </div>
                </div>}
                {showProgress ? <ProgressBar
                    provisionStatus={provisionStatus}
                    getProvisionStatus={getProvisionStatus}
                    progress={progress}
                /> : <div className="card panel content-holder-panels">
                    <div className="panel-header">
                        <div className="panel-header-content__wrapper">
                            <h4 className="panel-header-title">{(singleAppDetails && singleAppDetails.widgetDetails) ? singleAppDetails.widgetDetails.title : ''} - Details list</h4>
                            {/* <p className="panel-header-subtitle">List of </p> */}
                        </div>
                    </div>
                    <div className="panel-table-listing__wrapper">
                        <div className="table-parent__wrapper">
                            <table className="table table-default">
                                <tbody>
                                    <tr className="table-header">
                                        <th className="txt-left">Id</th>
                                        <th className="txt-left">Event name</th>
                                        <th className="txt-left">Organization</th>
                                        <th className="txt-left">MSISDN</th>
                                        <th className="txt-left">NIC</th>
                                        <th className="txt-left">Response</th>
                                        <th className="txt-left">Username</th>
                                    </tr>
                                    {(Array.isArray(kycList) && kycList.length > 0) ? kycList.map((value: any, index: number) => {
                                        return (
                                            <tr className="table-row ">
                                                <td>
                                                    <div className="feature-details__wrapper">
                                                        <div
                                                            className="card app-media-holder"
                                                            style={{
                                                                backgroundImage: `url(${TableIcon})`
                                                            }}
                                                        ></div>
                                                        <div className="feature-details-title__wrapper">
                                                            <span className="title">{value.uuid}</span>
                                                            <span className="small-desc">Created at {value.createdAt}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="txt-left">{value.KycEventData != undefined ? value.KycEventData.eventName : '-'}</td>
                                                <td className="txt-left">{value.organizationDetails}</td>
                                                <td className="txt-left">{value.msisdn}</td>
                                                <td className="txt-left">{value.nic}</td>
                                                <td className="txt-left">
                                                    {value.response}
                                                </td>
                                                <td className="txt-left">
                                                    {value.requestUserName}
                                                </td>
                                            </tr>
                                        )
                                    }) : <tr className="table-row">
                                        <td>
                                            <a>No data</a>
                                        </td>
                                    </tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>}
            </div>
        )
    }

    const renderTrackList = () => {
        if (success) {
            if (Array.isArray(kycList) && kycList.length > 0) {
                return (
                    renderData()
                )
            } else {
                if (filtered) {
                    return (
                        renderData()
                    )
                } else {
                    if (showProgress) {
                        return (
                            renderData()
                        )
                    } else {
                        return (
                            <div className="section__wrapper">
                                {/* <div className="empty-state-new-action__wrapper"> */}
                                <div className="new-action-content__wrapper">
                                    <h4 className="sub">No Records available</h4>
                                    {/* <h4 className="title">Create new request</h4> */}
                                    {/* <button className="btn btn-primary" onClick={() => navigate(`/location/${wid}/create`)}>New request</button> */}
                                </div>
                                {/* </div> */}
                            </div>
                        )
                    }
                }
            }
        } else if (loading) {
            return (
                <div className="section__wrapper">
                    <div className="empty-state-new-action__wrapper">
                        <section className="content__wrapper">
                            <div className="primaryBrandLoaderBg">
                                <div className="primaryBrandLoader">
                                    <img src={require('../../../assets/images/loader-bg-primary.svg').default} alt="" srcSet="" />
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            )
        } else if (error) {
            return (
                <div className="section__wrapper">
                    <div className="empty-state-new-action__wrapper">
                        <div className="new-action-content__wrapper">
                            <h4 className="sub">Oops, something went wrong!</h4>
                        </div>
                    </div>
                </div>
            )
        }
    }

    if (AuthHandler.isAuthorized(auth, 'tracklist') && userDetails !== null) {
        return (
            <React.Fragment>
                <div className="main-content main-spacer-x-y">
                    <PageHeaderStatic
                        auth={auth}
                        newButtonLink={newLinkUrl}
                        newButtonText={newButtonText}
                        listShow={true}
                        page={'list'}
                        consentConfig={kycConfigs}
                        addConsentConfigs={submitKycConfigs}
                        callBackend={(newButtonText !== 'API Configurations') ? getClientDetails : null}
                    />
                    {renderTrackList()}
                </div>
            </React.Fragment>
        );
    } else return (<React.Fragment></React.Fragment>);
}

export default KycAPI;