/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { CSSProperties, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import { fetchSingleApp } from '../../../store/actions';
import AuthHandler from '../../../helper/AuthHandler';
import TableIcon from '../../../assets/images/icons/app-media-placeholder.svg';
import { PageHeaderStatic } from "../components/PageHeaderStatic";
import { fetchSocialMediaConfigs } from "../../../store/actions/social-media.action";
import NoData from "../../settings/components/NoData";
import { Tooltip } from "antd";

const override: CSSProperties = {
    display: "block",
    margin: "0 auto"
};

const SocialMediaConfigList = ({ auth }: any) => {
    const { wid, configId } = useParams();
    const [limit, setLimit] = useState<number>(10);
    const [offset, setOffset] = useState<number>(0);


    const navigate = useNavigate();

    const { success, loading, error, smConfigList } = useSelector(({ sm }: any) => sm);
    const { userDetails } = useSelector(({ user }: any) => user);

    const dispatch = useAppDispatch();

    const widgetroutePath = window.location.pathname.split('/')[1];

    useEffect(() => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        dispatch(fetchSingleApp(wid!, tenantGUID));
    }, [wid, userDetails]);

    useEffect(() => {
        // console.log('------------------>',mcConfigList);
        // setLimit(limit);
    }, [smConfigList]);

    useEffect(() => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        dispatch(fetchSocialMediaConfigs(tenantGUID, wid!));
    }, [wid, userDetails]);

    const onViewMore = () => {
        setOffset(offset + 10);
    }

    const renderConfigList = () => {

        if (success && smConfigList) {
            if (Array.isArray(smConfigList) && smConfigList.length > 0) {
                return (
                    <div className="section__wrapper">
                        <div className="card panel content-holder-panels">
                            <div className="panel-header">
                                <div className="panel-header-content__wrapper">
                                    <h4 className="panel-header-title">List of Configs</h4>
                                </div>
                            </div>
                            <div className="panel-table-listing__wrapper">
                                <div className="table-parent__wrapper">
                                    <table className="table table-default">
                                        <tbody>
                                            <tr className="table-header">
                                                <th className="txt-left">Enterprise Name</th>
                                                <th className="ext-left">Email Address</th>
                                                <th className="txt-left">Contact Number</th>
                                                <th className="txt-left">Selected Package</th>
                                                <th className="txt-left">Preferred Platforms</th>
                                                <th className="txt-left">Contact Person's Name</th>
                                                <th className="txt-left">Contact Person's Number</th>
                                                <th className="txt-left">Contact Person's Email</th>
                                                <th className="txt-left">Special Note</th>
                                                <th className="txt-left"></th>
                                            </tr>
                                            {smConfigList.map((value: any, index: number) => {
                                                return (
                                                    <tr className="table-row ">
                                                        <td>
                                                            <div className="feature-details__wrapper">
                                                                {/* <div
                                                                    className="card app-media-holder"
                                                                    style={{
                                                                        backgroundImage: `url(${TableIcon})`
                                                                    }}
                                                                ></div> */}
                                                                <div className="feature-details-title">
                                                                    <span className="title">{value.enterpriseName}</span>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td className="txt-left">{value.email}</td>
                                                        <td className="txt-left">{value.contactNum}</td>
                                                        <td className="txt-left">{value.selectedPackage}</td>
                                                        <td className="txt-left">{value.platforms}</td>
                                                        <td className="txt-left">{value.contactPersonName}</td>
                                                        <td className="txt-left">{value.contactPersonNumber}</td>
                                                        <td className="txt-left">{value.contactPersonEmail}</td>
                                                        <td className="txt-left">{value.specialNote}</td>
                                                        <td className="txt-left" style={{ textAlign: 'center' }}>
                                                            <Link to={`/${widgetroutePath}/${wid}/view-${widgetroutePath}/${value.uuid}`}>
                                                                <Tooltip title="View">
                                                                    <i className="bi bi-eye-fill" />
                                                                </Tooltip>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="section__wrapper">
                        <div className="empty-state-new-action__wrapper">
                            <div className="new-action-content__wrapper">
                                <h4 className="sub">No Records available</h4>
                                <h4 className="title">Create new request</h4>
                                <button className="btn btn-primary" onClick={() => navigate(`/${widgetroutePath}/${wid}/${widgetroutePath}-create`)}>New request</button>
                            </div>
                        </div>
                    </div>
                )
            }
        } else if (loading) {
            return (
                <div className="section__wrapper">
                    <div className="empty-state-new-action__wrapper">
                        <section className="content__wrapper">
                            <div className="primaryBrandLoaderBg">
                                <div className="primaryBrandLoader">
                                    <img src={require('../../../assets/images/loader-bg-primary.svg').default} alt="" srcSet="" />
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            )
        } else if (error) {
            return (
                <div className="section__wrapper">
                    <div className="empty-state-new-action__wrapper">
                        <div className="new-action-content__wrapper">
                            <h4 className="sub">Oops, something went wrong!</h4>
                        </div>
                    </div>
                </div>
            )
        }
    }

    if (AuthHandler.isAuthorized(auth, 'social-media-config-list') && userDetails !== null) {
        let path = window.location.pathname.split('/');
        return (
            <React.Fragment>
                <div className="main-content main-spacer-x-y">
                    {smConfigList &&
                        <PageHeaderStatic
                            listShow={true}
                            page={'list'}
                            disableSMrequest={true}
                            // newButtonLink={path[0] + '/' + path[1] + '/' + path[2] + '/social-media-create'}
                            // disableSMrequest={Array.isArray(smConfigList) && smConfigList.length > 0 ? true : false}
                        />
                    }
                    {renderConfigList()}
                </div>

            </React.Fragment>
        );
    } else return (<React.Fragment></React.Fragment>);
}

export default SocialMediaConfigList;