import { message } from 'antd';
import betterHrStore from "../reducers/better-hr.store";
import {getBetterHrTotAmountData} from "../../services/better-hr.service";

export const fetchBetterHrTotAmountData = (widgetId: string, count: number): any => {
    return (dispatch: any) => {
        dispatch(betterHrStore.actions.totalAmountFetch(count));
        getBetterHrTotAmountData(widgetId, count)
            .then((response: any) => {
                dispatch(betterHrStore.actions.totalAmountFetchSuccess(response.data));
            })
            .catch((error: any) => {
                message.error("Error occurred while fetching total amount for entered licences count.");
                dispatch(betterHrStore.actions.totalAmountFetchFail());
            });
    }
};