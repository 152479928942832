import settingsStore from "../reducers/settings.store";
import {
    getProfileDetails,
    editUserDetails,
    unsubcribeApp,
    getAppList,
    editBusinessProfileDetails,
    getPaymentMethodDetails,
    getProfileIdentificationImages,
    getBusinessRegistrationDocuments,
    getSignedDocuments,
    getBusinessProfileDetails,
    getOwnerDetails,
    getPartnerDetails,
    getPartnerRequests,
    deletePartnerRequest,
    sendPartnerRequest,
    getIndustryList,
    deletePartner,
    getUnsubscribedAppList,
    getMsisdnList,
    deleteMsisdn,
    postMsisdn,
    putMsisdn,
    postMask,
    getMaskList,
    putMask,
    deleteMask,
    getMaskDuplicate,
    getAvailablePorts,
    getSelectedPort,
    postPort,
    getBusinessesList,
    getLongNumber,
    putLongNumber,
    getEnterpriseDocs,
    updatePartnerAccessLevel,
    getPaymentInfo,
    getWidgetInfoList,
    putPaymentMethod,
    getPayOptionsList,
    putPaymentRatePan,
    getPaymentHistoryList,
    updateEntStatus,
    updateEntLogo,
    getNoPaymentMsisdnList,
    retrySubscription,
    getRenewalChargingRate,
    getDiscountInfo,
    updateOwnerDetails
} from "../../services/settings.service";
import { profileAndUserPermissionModel } from "../../pages/home/models/request/profileAndUserPermission.model";
import { BusinessDetailsModel } from "../../pages/settings/models/request/business-details.model";
import { Button, message, notification } from 'antd';
import { PartnerDetailsModel } from "../../pages/settings/models/response/partner-details.model";
import { OwnerDetailsModel } from "../../pages/settings/models/response/owner-details.model";
import { PartnerRequestModel } from "../../pages/settings/models/response/partner-requests";
import { MsisdnPostRequest } from "../../pages/settings/models/request/msisdn-post-request.model";
import { MsisdnPutRequest } from "../../pages/settings/models/request/msisdn-put-request.model";
import { MaskPostRequest } from "../../pages/settings/models/request/mask-post-request.model";
import { MaskPutRequest } from "../../pages/settings/models/request/mask-put-request.model";
import { LongNumberPutRequest } from "../../pages/settings/models/request/long-number-put.model";
import { EnterpriseDocumentModel } from "../../pages/settings/models/response/enterprise-document.model";
import { PackageChangeRequest } from "../../pages/settings/models/request/package-change.model";
import { EnterpriseStatusRequest } from "../../pages/settings/models/request/enterprise-status.modal";
import { redirect } from "react-router-dom";
import {PartnerErrorResponseModel} from "../../pages/settings/models/response/partner-error-response";
//import { AppDetailsModel } from "../../pages/settings/models/request/app-details-model";

// const [api, contextHolder] = notification.useNotification();

export const getPermissionDetails = (uuid: string): any => {
    return (dispatch: any) => {
        getProfileDetails(uuid)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(settingsStore.actions.getProfileDetails(response.data));
                }
            })
            .catch((error) => {
                dispatch(settingsStore.actions.getProfileDetails(error));
            });
    }
};

export const editPermissionDetails = (request: OwnerDetailsModel, uuid: string): any => {
    return (dispatch: any) => {
        editUserDetails(request, uuid)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(settingsStore.actions.editUserDetails(response.data));
                }
            })
            .catch((error) => {
                dispatch(settingsStore.actions.editUserDetails(error));
            });
    }
};

export const unsubcribeApps = (tenantGroupId: string, widgetId: string, limit: number, offset: number) => {
    return (dispatch: any) => {
        unsubcribeApp(tenantGroupId, widgetId)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(settingsStore.actions.unsubscribe(res.data));
                    dispatch(fetchAppList(tenantGroupId, '0', limit, offset, []));
                    dispatch(fetchUnsubscribedAppList(tenantGroupId, "0"));
                    notification.success({
                        message: 'Notification',
                        description: 'App Unsubscribed Successfully'
                    });
                } else {
                    notification.error({
                        message: 'Notification',
                        description: 'App Unsubscribing Failed'
                    });
                }
            })
            .catch((error) => {
                dispatch(settingsStore.actions.unsubscribe(error));
            })
    }
};

export const fetchAppList = (uuid: string, key: string, limit: number, offset: number, appDataArray: any[]) => {
    return (dispatch: any) => {
        getAppList(uuid, key, limit, offset)
            .then((res => {
                if (res.status === 200) {
                    const updatedData = offset === 0 ? res.data.data : [...appDataArray, ...res.data.data];
                    let hasMoreData = res.data.data < limit ? false : true;
                    // let filterdArray = updatedData.filter( (ele: any, ind: number) => ind === updatedData.findIndex((elem:any) => elem.jobid === ele.jobid && elem.id === ele.id))
                    let filterdArray = updatedData.filter((tag: any, index: number, array: any[]) => array.findIndex(t => t.title == tag.title && t.uuid == tag.uuid) == index);
                    // console.log("filterdArray", filterdArray);

                    dispatch(settingsStore.actions.getAppList({ filterdArray, hasMoreData }));
                }
            }))
            .catch((error) => {
                dispatch(settingsStore.actions.getAppList(error))
            })
    }
};


export const fetchUnsubscribedAppList = (uuid: string, searchKey: string) => {
    return (dispatch: any) => {
        getUnsubscribedAppList(uuid, searchKey)
            .then((res => {
                if (res.status === 200) {
                    dispatch(settingsStore.actions.getUnsubscribedAppList(res.data.data));
                }
            }))
            .catch((error) => {
                dispatch(settingsStore.actions.getUnsubscribedAppList(error))
            })
    }
};


export const getBusinessDetails = (uuid: string) => {
    return (dispatch: any) => {
        getBusinessProfileDetails(uuid)
            .then((response) => {
                if (response.status === 200) {
                    console.log("response", response.data.data)
                    const businessData = {
                        businessName: response.data.data.businessDetails.entName,
                        businessEmail: response.data.data.businessDetails.entEmail,
                        businessDescription: response.data.data.businessDetails.entDescription,
                        bussinessRegNumber: response.data.data.businessDetails.entBussinessRegNumber,
                        ownerNicNumber: response.data.data.businessDetails.ownerNIC,
                        industryType: response.data.data.businessDetails.industry,
                        businessAddressNumber: response.data.data.businessDetails.entAddressNumber,
                        businessAddressStreet: response.data.data.businessDetails.entAddressStreet,
                        businessAddressCity: response.data.data.businessDetails.entAddressCity,
                        businessAddressDistrict: response.data.data.businessDetails.entAddressDistrict,
                        logo: response.data.data.entImage ? response.data.data.entImage.imageUrl : null,
                        billingEmail: response.data.data.businessDetails.billingEmail ? response.data.data.businessDetails.billingEmail : null
                    }
                    dispatch(settingsStore.actions.getBusinessDetails(businessData));

                    if(response.data.data.tempStatus !== null) {
                        dispatch(settingsStore.actions.getEntUpdateStatus(response.data.data.tempStatus.status));
                    }
                }
            })
            .catch((error: BusinessDetailsModel) => {
                dispatch(settingsStore.actions.getBusinessDetails(error));
            });
    }
};

export const fetchOwnerDetails = (uuid: string) => {
    return (dispatch: any) => {
        getOwnerDetails(uuid)
            .then((response) => {
                if (response.status === 200) {
                    const ownerData = {
                        ownerFirstName: response.data.data.ownerFirstName,
                        ownerLastName: response.data.data.ownerLastName,
                        ownerEmail: response.data.data.ownerEmail,
                        ownerMobile: response.data.data.ownerMobile,
                        logo: ""
                    }
                    dispatch(settingsStore.actions.getOwnerDetails(ownerData));
                }
            })
            .catch((error: OwnerDetailsModel) => {
                dispatch(settingsStore.actions.getOwnerDetails(error));
            });
    }
};

export const fetchPartnerDetails = (uuid: string) => {
    return (dispatch: any) => {
        getPartnerDetails(uuid)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(settingsStore.actions.getPartnerDetails(response.data.data));
                }
            })
            .catch((error: PartnerDetailsModel) => {
                dispatch(settingsStore.actions.getPartnerDetails(error));
            });
    }
};

export const editBusinessDetails = (request: any, entId: string, tid: string): any => {
    return (dispatch: any) => {
        editBusinessProfileDetails(request, entId, tid)
            .then((response) => {
                if (response.data.status === 200) {
                    dispatch(settingsStore.actions.editBusinessDetails(response.data));
                    notification.success({
                        message: 'Notification',
                        description: 'The enterprise profile will be updated after the admin approval.'
                    });
                    window.location.reload();
                } else {
                    notification.error({
                        message: 'Notification',
                        description: 'The enterprise details update failed. Please try again.'
                    });
                    window.location.reload();
                }
            })
            .catch((error) => {
                dispatch(settingsStore.actions.editBusinessDetails(error));
            });
    }
};

export const fetchPaymentDetails = (request: object): any => {
    return (dispatch: any) => {
        getPaymentMethodDetails(request).then((res => {
            if (res.status === 200) {
                dispatch(settingsStore.actions.getPaymentMethodDetails(res));
            }
        })).catch((error => {
            dispatch(settingsStore.actions.getPaymentMethodDetails(error));
        }))
    }
};

export const fetchProfileIdentificationImages = (request: object): any => {
    return (dispatch: any) => {
        getProfileIdentificationImages(request).then((res) => {
            if (res.status === 200) {
                dispatch(settingsStore.actions.getProfileIdentificationImages(res));
            }
        }).catch((error) => {
            dispatch(settingsStore.actions.getProfileIdentificationImages(error));
        })
    }
};

export const fetchBusinessRegistrationDocuments = (request: object): any => {
    return (dispatch: any) => {
        getBusinessRegistrationDocuments(request).then((res) => {
            if (res.status === 200) {
                dispatch(settingsStore.actions.getBusinessRegistrationDocuments(res));
            }
        }).catch((error) => {
            dispatch(settingsStore.actions.getBusinessRegistrationDocuments(error));
        })
    }
};

export const fetchSignedDocuments = (request: object): any => {
    return (dispatch: any) => {
        getSignedDocuments(request).then((res) => {
            if (res.status === 200) {
                dispatch(settingsStore.actions.getSignedDocuments(res));
            }
        }).catch((error) => {
            dispatch(settingsStore.actions.getSignedDocuments(error));
        })
    }
};

export const setMobileNavbarOpen = (isOpen: boolean): any => {
    return (dispatch: any) => {
        dispatch(settingsStore.actions.mobileNavbarLinkClicked(isOpen));
    }
};

export const fetchIndustryTypeList = () => {
    return (dispatch: any) => {
        getIndustryList()
            .then((response) => {
                if (response.status === 200) {
                    dispatch(settingsStore.actions.getIndustryTypeList(response));
                }

            });
    }
}

export const fetchPartnerRequests = (uuid: string) => {
    return (dispatch: any) => {
        getPartnerRequests(uuid)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(settingsStore.actions.getPartnerRequests(response.data));
                }
            })
            .catch((error: PartnerRequestModel) => {
                dispatch(settingsStore.actions.getPartnerRequestsError());
            });
    }
};

export const rewokePartnerRequest = (req: any, tid: string, uuid: string) => {
    return (dispatch: any) => {
        deletePartnerRequest(req, tid, uuid)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(settingsStore.actions.deletePartnerRequest(true));
                    dispatch(fetchPartnerRequests(tid));
                    notification.success({
                        message: 'Notification',
                        description: 'Revoke Request Sent'
                    });
                }
            })
            .catch((error: PartnerRequestModel) => {
                dispatch(settingsStore.actions.deletePartnerRequest(error));
            });
    }

};

export const newPartnerRequest = (status: string, tid: string, uuid: string) => {
    return (dispatch: any) => {
        sendPartnerRequest(status, tid, uuid)
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(settingsStore.actions.sendPartnerRequest(uuid));
                    //set store function to boolean in redux store if succcessful
                    //from screen page if successful remove item
                    //keep uuid and remove status
                    dispatch(fetchPartnerRequests(tid));
                    // notification.success({
                    //     message: 'Notification',
                    //     description: 'Successfully sent the request for approvals'
                    // });
                    message.success("Successfully sent the request for approvals");
                }
            })
            .catch((error: PartnerErrorResponseModel) => {
                dispatch(settingsStore.actions.sendPartnerRequest(error));
                if(error && error.response && error.response.data && error.response.data.error === "Already requested to change Agent") {
                    message.warning("You have already requested to change the marketing agent. Please wait for the approval");
                } else {
                    message.warning("Partner Request Failed");
                }
            });
    }

};

export const removePartner = (tid: string, uuid: string, accessLevel: string) => {
    return (dispatch: any) => {
        deletePartner(tid, uuid, accessLevel)
            .then((response) => {
                if (response.status === 200) {
                    const req = {
                        partnerId: uuid,
                        isDeleted: true
                    }
                    dispatch(settingsStore.actions.deletePartner(req));
                }
            })
            .catch((error: PartnerRequestModel) => {
                dispatch(settingsStore.actions.deletePartnerRequest(error));
            });
    }
};

export const fetchMsisdnList = (tenantId: string, msisdn: string) => {
    return (dispatch: any) => {
        getMsisdnList(tenantId, msisdn)
            .then((response: any) => {
                console.log("msisdn", response.data)
                if (response.status === 200) {
                    dispatch(settingsStore.actions.getMsisdnListSuccess(response.data.data));
                }
            })
            .catch((error: PartnerRequestModel) => {
                dispatch(settingsStore.actions.getMsisdnListFail(error));
            });
    }
};

export const submitNewMsisdn = (tenantId: string, req: MsisdnPostRequest) => {
    return (dispatch: any) => {
        postMsisdn(tenantId, req)
            .then((response: any) => {
                if (response.status === 200) {
                    if (response.data.status === "SUCCESS") {
                        notification.success({
                            message: `SUCCESS`,
                            description: 'Msisdn added successfully',
                            placement: 'bottomRight'
                        });
                    } else if (response.data.status === "ERROR") {
                        notification.error({
                            message: `ERROR`,
                            description: response.data.message,
                            placement: 'bottomRight'
                        });
                    } else {
                    }
                    dispatch(settingsStore.actions.postMsisdnListSuccess(response.data));
                }
            })
            .catch((error: PartnerRequestModel) => {
                dispatch(settingsStore.actions.postMsisdnListFail(error));
            });
    }
};

export const updateMsisdn = (tenantId: string, req: MsisdnPutRequest) => {
    return (dispatch: any) => {
        putMsisdn(tenantId, req)
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(settingsStore.actions.updateMsisdnListSuccess(response.data));
                }
            })
            .catch((error: PartnerRequestModel) => {
                dispatch(settingsStore.actions.updateMsisdnListFail(error));
            });
    }
};

export const removeMsisdn = (tenantId: string, msisdnId: string) => {
    return (dispatch: any) => {
        deleteMsisdn(tenantId, msisdnId)
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(settingsStore.actions.deleteMsisdnSuccess(response.data));
                }
            })
            .catch((error: PartnerRequestModel) => {
                dispatch(settingsStore.actions.deleteMsisdnFail(error));
            });
    }
};


export const fetchEnterpriseDocs = (uuid: string) => {
    return (dispatch: any) => {
        console.log("action: ", uuid)
        getEnterpriseDocs(uuid)
            .then((response: any) => {
                if (response.status === 200) {
                    console.log("fetchEnterpriseDocs response: ", response);
                    const documentData = response.data.data.map((document: any) => ({
                        id: document.id,
                        uuid: document.uuid,
                        image: document.image,
                        fieldName: document.fieldName,
                        imageUrl: document.imageUrl
                    }));
                    dispatch(settingsStore.actions.getEnterpriseDocs(documentData));
                }
            })
            .catch((error: EnterpriseDocumentModel) => {
                dispatch(settingsStore.actions.getEnterpriseDocs(error));
            });
    };
};

export const fetchMaskList = (tenantId: string, mask: string) => {
    return (dispatch: any) => {
        getMaskList(tenantId, mask)
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(settingsStore.actions.getMaskListSuccess(response.data.data));
                }
            })
            .catch((error: PartnerRequestModel) => {
                dispatch(settingsStore.actions.getMaskListFail(error));
            });
    }
};

export const submitNewMask = (req: MaskPostRequest) => {
    return (dispatch: any) => {
        // console.log("########## 1", req);
        postMask(req)
            .then((response: any) => {
                if (response.status === 200) {
                    if (response.data.status.toLowerCase() === "success") {
                        // console.log("########## 3");
                        notification.success({
                            message: `SUCCESS`,
                            description: 'Mask added successfully',
                            placement: 'bottomRight'
                        });
                    } else if (response.data.status.toLowerCase() === "failed") {
                        // console.log("########## 4");
                        notification.error({
                            message: `ERROR`,
                            description: response.data.message,
                            placement: 'bottomRight'
                        });
                    } else {
                        console.log("########## 5");
                    }
                    dispatch(settingsStore.actions.postMaskListSuccess(response.data));
                }
            })
            .catch((error: PartnerRequestModel) => {
                console.log("########## 6");
                dispatch(settingsStore.actions.postMaskListFail(error));
            });
    }
};

export const updateMask = (req: any) => {
    return (dispatch: any) => {
        console.log("update req in action :" + JSON.stringify(req));
        putMask(req)
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(settingsStore.actions.updateMaskListSuccess(response.data));
                    if (response.data.status.toLowerCase() === "success") {
                        notification.success({
                            message: `SUCCESS`,
                            description: 'Masks updated successfully',
                            placement: 'bottomRight'
                        });
                    } else {
                        notification.error({
                            message: `ERROR`,
                            description: response.data.message,
                            placement: 'bottomRight'
                        });
                    }
                } else {
                    dispatch(settingsStore.actions.updateMaskListFail(response.data));
                    notification.error({
                        message: `FAILED`,
                        description: response.message,
                        placement: 'bottomRight'
                    });
                }
            })
            .catch((error: PartnerRequestModel) => {
                dispatch(settingsStore.actions.updateMaskListFail(error));
            });
    }
};

export const removeMask = (tenantId: string, maskId: string) => {
    return (dispatch: any) => {
        deleteMask(tenantId, maskId)
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(settingsStore.actions.deleteMaskSuccess(response.data));
                }
            })
            .catch((error: PartnerRequestModel) => {
                dispatch(settingsStore.actions.deleteMaskFail(error));
            });
    }
};

export const checkMaskDuplicate = (mask: string) => {
    return (dispatch: any) => {
        const maskEnc = encodeURIComponent(mask);
        dispatch(settingsStore.actions.checkDuplicateLoading());
        getMaskDuplicate(maskEnc)
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(settingsStore.actions.checkDuplicateSuccess(response.data.data));
                }
            })
            .catch((error: PartnerRequestModel) => {
                dispatch(settingsStore.actions.checkDuplicateFail(error));
            });
    }
};


export const fetchPortList = () => {
    return (dispatch: any) => {
        getAvailablePorts()
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(settingsStore.actions.getPortListSuccess(response.data.data));
                }
            })
            .catch((error: PartnerRequestModel) => {
                dispatch(settingsStore.actions.getPortListFail(error));
            });
    }
};

export const fetchSelectedPort = (tenantId: string) => {
    return (dispatch: any) => {
        getSelectedPort(tenantId)
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(settingsStore.actions.getPortSuccess(response.data.data));
                }
            })
            .catch((error: PartnerRequestModel) => {
                dispatch(settingsStore.actions.getPortFail(error));
            });
    }
};

export const submitPort = (tenantId: string, port: string) => {
    return (dispatch: any) => {
        postPort(tenantId, port)
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(settingsStore.actions.postPortSuccess(response.data));
                }
            })
            .catch((error: PartnerRequestModel) => {
                dispatch(settingsStore.actions.postPortFail(error));
            });
    }
};

export const fetchBusinessesList = () => {
    return (dispatch: any) => {
        getBusinessesList()
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(settingsStore.actions.getBusinessList(response.data));
                }
            })
            .catch((error: any) => {
                dispatch(settingsStore.actions.getBusinessList(error));
            });
    }
};

export const fetchSelectedLongNumber = (tenantId: string) => {
    return (dispatch: any) => {
        getLongNumber(tenantId)
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(settingsStore.actions.getLongNumberSuccess(response.data.data));
                }
            })
            .catch((error: PartnerRequestModel) => {
                dispatch(settingsStore.actions.getLongNumberFail(error));
            });
    }
};

export const submitLongNumber = (tenantId: string, req: LongNumberPutRequest) => {
    return (dispatch: any) => {
        putLongNumber(tenantId, req)
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(settingsStore.actions.postLongNumberSuccess(response.data));
                }
            })
            .catch((error: PartnerRequestModel) => {
                dispatch(settingsStore.actions.postLongNumberFail(error));
            });
    }
};
export const changePartnerAccessLevel = (uuid: string, req: any) => {
    return (dispatch: any) => {
        updatePartnerAccessLevel(uuid, req)
            .then((response: any) => {
                if (response.status === 200) {
                    console.log(response);
                    notification.success({
                        message: `SUCCESS`,
                        description: 'Partner role updated successfully',
                        placement: 'bottomRight'
                    });
                    dispatch(settingsStore.actions.updatePartnerAccessLevel(true));
                }
            })
            .catch((error: any) => {
                dispatch(settingsStore.actions.updatePartnerAccessLevel(error));
            });
    }
};

export const fetchPaymentInfo = (tenantId: string) => {
    return (dispatch: any) => {
        getPaymentInfo(tenantId)
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(settingsStore.actions.getPaymentInfoSuccess(response.data.data));
                }
            })
            .catch((error: PartnerRequestModel) => {
                dispatch(settingsStore.actions.getPaymentInforFail(error));
            });
    }
};

export const fetchWidgetInfoList = (tenantId: string, type: string, limit: number, offset: number, searchTerm: string) => {
    return (dispatch: any) => {
        if (!searchTerm || searchTerm === '0') {
            dispatch(settingsStore.actions.getWidgetList());
        }
        getWidgetInfoList(tenantId, type, limit, offset, searchTerm)
            .then((response: any) => {
                if (response.status === 200) {
                    if (type === "SUBSCRIPTION_BASED") {
                        dispatch(settingsStore.actions.getSubBasedSuccess(response.data.data));
                    } else if (type === "PER_USAGE") {
                        dispatch(settingsStore.actions.getPerUsageSuccess(response.data.data));
                    }
                    else if (type === "NO_CHARGE") {
                        dispatch(settingsStore.actions.getFreeWidgetsSuccess(response.data.data));
                    } else if (type === "ONETIME") {
                        dispatch(settingsStore.actions.getOnetimeSuccess(response.data.data));
                    }
                }
            })
            .catch((error: PartnerRequestModel) => {
                if (type === "SUBSCRIPTION_BASED") {
                    dispatch(settingsStore.actions.getSubBasedFail(error));
                } else if (type === "PER_USAGE") {
                    dispatch(settingsStore.actions.getPerUsageFail(error));
                } else if (type === "NO_CHARGE") {
                    dispatch(settingsStore.actions.getFreeWidgetsFail(error));
                } else if (type === "ONETIME") {
                    dispatch(settingsStore.actions.getOnetimeFail(error));
                }
            });
    }
};

export const updatePaymentMethod = (tenantId: string, widgetId: string, req: any) => {
    return (dispatch: any) => {
        putPaymentMethod(tenantId, widgetId, req)
            .then((response: any) => {
                if (response.status === 200) {
                    message.success("You have successfully changed your payment method");
                    dispatch(settingsStore.actions.putPaymentMethodSuccess(response.data.data));
                }
            })
            .catch((error: PartnerRequestModel) => {
                dispatch(settingsStore.actions.putPaymentMethodFail(error));
            });
    }
};

export const fetchPaymentOptionsList = (tenantId: string, searchText: string) => {
    return (dispatch: any) => {
        getPayOptionsList(tenantId, searchText)
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(settingsStore.actions.getPaymentOptionsListSuccess(response.data.data));
                }
            })
            .catch((error: PartnerRequestModel) => {
                dispatch(settingsStore.actions.getPaymentOptionsListFail(error));
            });
    }
};

export const updateRatePlan = (tenantId: string, widgetId: string, req: PackageChangeRequest) => {
    return (dispatch: any) => {
        putPaymentRatePan(tenantId, widgetId, req)
            .then((response: any) => {
                if (response.status === 200) {
                    // message.success("You have successfully changed your package");
                    dispatch(settingsStore.actions.putRatePlanSuccess(response.data));
                }
            })
            .catch((error: PartnerRequestModel) => {
                dispatch(settingsStore.actions.putRatePlanFail(error));
            });
    }
};

export const fetchPaymentHistoryList = (tenantId: string, limit: number, offset: number) => {
    return (dispatch: any) => {
        getPaymentHistoryList(tenantId, limit, offset)
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(settingsStore.actions.getPaymentHistoryListSuccess(response.data.data));
                }
            })
            .catch((error: PartnerRequestModel) => {
                dispatch(settingsStore.actions.getPaymentHistoryListFail(error));
            });
    }
};

export const updateEnterpriseStatus = (tenantGroupId: string, req: EnterpriseStatusRequest) => {
    return (dispatch: any) => {
        updateEntStatus(tenantGroupId, req)
            .then((response: any) => {
                if (response.status === 200) {
                    console.log("terminate response", response.data.data);
                    dispatch(settingsStore.actions.terminateEntAccount(response.data.data));
                    notification.success({
                        message: `Success`,
                        description: "Account Status Change Successfully",
                        placement: 'bottomRight'
                    });
                }
            })
            .catch((error: EnterpriseStatusRequest) => {
                dispatch(settingsStore.actions.terminateEntAccount(error));
            });
    }
};


export const updateEnterpriseLogo = (req: any, tenantGroupId: string, uuid: string) => {
    return (dispatch: any) => {
        dispatch(settingsStore.actions.requestPending());
        updateEntLogo(req, tenantGroupId, uuid)
            .then((response: any) => {
                if (response.status === 200) {
                    console.log("terminate response", response);
                    dispatch(settingsStore.actions.updateEntLogo(response));
                    notification.success({
                        message: `SUCCESS`,
                        description: 'Logo update request has been sent successfully. ',
                        placement: 'bottomRight'
                    });
                }
            })
            .catch((error) => {
                dispatch(settingsStore.actions.updateEntLogo(error));
            });
    }
};

export const fetchNoPayMsisdnList = (tenantGroupId: string) => {
    return (dispatch: any) => {
        getNoPaymentMsisdnList(tenantGroupId)
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(settingsStore.actions.getNoPayMsisdnListSuccess(response.data.data));
                }
            })
            .catch((error) => {
                dispatch(settingsStore.actions.getNoPayMsisdnListFail(error));
            });
    }
};

export const renewSubscription = (tenantGroupId: string, widgetUuid: string) => {
    return (dispatch: any) => {
        dispatch(settingsStore.actions.renewSubscription());
        retrySubscription(tenantGroupId, widgetUuid)
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(settingsStore.actions.renewSubscriptionSuccess());
                    notification.success({
                        message: `SUCCESS`,
                        description: response.data?.message ? response.data.message : 'Subscription renewal successful.',
                        placement: 'topRight',
                        onClose: () => {window.location.reload()}
                    });
                }
            })
            .catch((error: any) => {
                dispatch(settingsStore.actions.renewSubscriptionFailed());
                notification.error({
                    message: `ERROR`,
                    description: error.response?.data?.error ? error.response.data.error : 'Oops... Something went wrong!',
                    placement: 'topRight'
                });
            });
    }
};

export const fetchRenewalChargingAmount = (widgetType: string, index: number, ratePlanId: string) => {
    return (dispatch: any) => {
        getRenewalChargingRate(widgetType, index, ratePlanId)
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(settingsStore.actions.getRenewalChargingSuccess(response.data.data));
                }
            })
            .catch((error: PartnerRequestModel) => {
                dispatch(settingsStore.actions.getRenewalChargingFailed(error));
            });
    }
};

export const setRenewalChargingDataList = (renewalChargingData: any) => {
    return (dispatch: any) => {
        dispatch(settingsStore.actions.setRenewalChargingList(renewalChargingData));
    }
};

export const fetchDiscountInfo = (tenantId: string) => {
    return (dispatch: any) => {
        getDiscountInfo(tenantId)
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(settingsStore.actions.getDiscountInfoSuccess(response.data));
                }
            })
            .catch((error: PartnerRequestModel) => {
                dispatch(settingsStore.actions.getDiscountInfoFailed(error));
            });
    }
};

export const editOwnerDetails = (request: any, userId: string): any => {
    return (dispatch: any) => {
        console.log("req", request)

        updateOwnerDetails(request, userId)
            .then((response: any) => {
                if (response.data.status === 200) {
                    dispatch(settingsStore.actions.editOwnerDetails(response.data));
                    notification.success({
                        message: 'Notification',
                        description: 'Owner details has been updated successfully!'
                    });
                } else {
                    notification.error({
                        message: 'Notification',
                        description: 'Owner details update failed. Please try again.'
                    });
                }
            })
            .catch((error: any) => {
                dispatch(settingsStore.actions.editOwnerDetails(error));
            });
    }
};