import React from 'react';
import AppPlaceholder from '../../../assets/images/icons/app-media-placeholder.svg';
import { RateCardSelect } from './RateCardSelect';
import { SingleAppModel, WidgetRate } from '../models/response/single-app.model';
import { InputNumber } from 'antd';
import SimpleReactValidator from 'simple-react-validator';

type Props = {
    appDetails: SingleAppModel;
    onChangeUsageCount: (e: any) => void;
    usageCount: string;
    onChangeWidgetCount: (value: any, rate: any) => void;
    maxWidgetCount: number;
    subscriptionRate: number;
    validator: SimpleReactValidator;
}

const PanelTableListCard = ({
    appDetails,
    onChangeUsageCount,
    usageCount,
    onChangeWidgetCount,
    maxWidgetCount,
    subscriptionRate,
    validator
}: Props) => {

    const widgetList = ['Sales CRM & Lead Management', 'Expense Approval Workflow', 'Project workflow tracking', 'Helpdesk solution', 'Daily call reporting'];
    return (
        <>
            <>
            <br></br>
            <h5  style={{ textAlign: "left" }}>This suite includes the following automated workflows,</h5>
            <div className="panel-table-content-list group-list">
                {widgetList.map((value: string, index: number) => {
                    return (
                        <div className="panel-table-list-item card ">
                            <div className="panel-table-listing-item__inner">
                                <div className="feature-details__wrapper">
                                    <div
                                        className="card app-media-holder"
                                        style={{
                                            backgroundImage: `url(${AppPlaceholder})`
                                        }}
                                    ></div>
                                    <div className="feature-details-title__wrapper no-shade billing-and-plans-package-select">
                                        <span className="title">
                                            {index + 1}. {value}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-table-listing-item__inner">
                                <div className="panel-table-right-align-item__wrapper">
                                    <div className="panel-table-right-align-item">
                                        <span className="title">Included</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
                <br></br>
                <p>When subscribing to any of the workflows in the Business Workflow Automation Suite, it will entitle you to activate any workflow that suits your requirement.</p>
            </div>
            </>
            <div className="panel-table-content-list">
                <h5 style={{ textAlign: "left" }}>Select the suitable slab for your required user licenses</h5>
                {appDetails.widgetDetails && Array.isArray(appDetails.widgetDetails.WidgetRate) && appDetails.widgetDetails.WidgetRate.map((widgetRate: WidgetRate, index: number) => {
                    return (
                        <div className={`panel-table-list-item card ${((JSON.stringify(widgetRate) === usageCount) ? 'active' : '')}`}>
                            <div className="panel-table-listing-item__inner">
                                <div className="feature-details__wrapper">
                                    <div className="feature-details-title__wrapper no-shade">
                                        <span className="title">{widgetRate.title}</span>
                                        <span className="small-desc">
                                            Rs. {widgetRate.rate}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-table-listing-item__inner">
                                <div className="panel-table-right-align-item__wrapper">
                                    <div className="panel-table-right-align-item">
                                        <input
                                            className="form-check-input secondary"
                                            type="radio"
                                            name=""
                                            id="radio1"
                                            value={JSON.stringify(widgetRate)}
                                            onChange={onChangeUsageCount}
                                            checked={JSON.stringify(widgetRate) === usageCount}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
                <div className="validation invalid-feedback">{validator.message('rate', usageCount, 'required')}</div>
            </div>
            <div className="form-group__wrapper">
                <div className="form-group">
                    <div className="form-element__wrapper mb-3 ">
                        <label htmlFor="" className="form-label">
                            Input the number of workflows to be activated for your subscription
                        </label>
                        <div className="icon-inside left d-flex btn-with-group">
                            {/* <span className="in-icon">
                                                                <i className="bi bi-search" />
                                                            </span> */}
                            {appDetails && appDetails.groupDetails[0] && <InputNumber
                                style={{ width: "50%" }}
                                min={0}
                                precision={2}
                                step={1.0}
                                value={maxWidgetCount}
                                onChange={(value) => onChangeWidgetCount(value, appDetails)}
                                max={appDetails.groupDetails[0].widgetCount}
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            // parser={value => value!.replace(/\$\s?|(,*)/g, '')}
                            />}

                        </div>
                        <div className="validation invalid-feedback">{validator.message('widget count', maxWidgetCount, 'required|min:1,num')}</div>
                    </div>
                </div>
            </div>
            <div className="form-group__wrapper">
                <div className="form-group">
                    <div className="form-element__wrapper mb-3 ">
                        <small style={{ marginBottom: 20 }}><em>Note : The ‘number of workflows’ that you input above means that you will only be allowed to access that many workflows during a subscription rental period. (i.e. If you request for 2 workflows, your user licenses will have access to ONLY two workflows at a given time.</em></small>
                    </div>
                </div>
            </div>
            <div className="form-group__wrapper">
                <div className="form-group">
                    <div className="form-element__wrapper mb-3 ">
                        <label htmlFor="" className="form-label" style={{ fontWeight: 600 }}>
                            Total subscription monthly rental (Without Tax)
                        </label>
                        <div className="icon-inside left d-flex btn-with-group">
                            Rs. <InputNumber
                                value={subscriptionRate}
                                style={{ width: "100%" }}
                                bordered={false}
                                readOnly={true}
                                min={0.00}
                                precision={2}
                                step={0.1}
                            />

                        </div>
                        <div className="validation invalid-feedback">{validator.message('subscription rate', subscriptionRate, 'required|min:1,num')}</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { PanelTableListCard };