import axiosInstance from "../axios-instance";
import * as CONSTANTS from '../constants';

const getSentimentList = async (tenantUuid: string, widgetUuid: string) => {
    return await axiosInstance({
        method: `get`,
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widget/sentiment/${tenantUuid}/${widgetUuid}`
    })
}

const postSentimentData = async (data: any, tenantUuid: string, widgetUuid: string) => {
    return await axiosInstance({
        method: `post`,
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widget/sentiment/${tenantUuid}/${widgetUuid}`,
        data: data
    })
}

const getNumberCount = async (tenantId: string, numberBaseId: string) => {
    return await axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/ent/numberbase/count/${tenantId}/${numberBaseId}`
    });
};

const getTenantNumberBases = async (tenantId: string, widgetId: string) => {
    return await axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/ent/numberbase/${tenantId}/${widgetId}`
    })
};

const getSentimentIndividualStatus = async (tenantId: string, sentimentId: string) => {
    return await axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widget/sentiment/view/${tenantId}/${sentimentId}`
    });
};

const putSentimentStatus = async (tenantId: string, sentimentId: string, data: any) => {
    return await axiosInstance({
        method: "put",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widget/sentiment/update/${tenantId}/${sentimentId}`,
        data: data
    });
};

const getSentimentLogs = async (tenantId: string, sentimentId: string) => {
    return await axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widget/sentiment/status/${tenantId}/${sentimentId}`
    });
};

const getSentimentWidget = async (sentimentId: string) => {
    return await axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widget/sentiment/${sentimentId}`
    });
};

const getSentimentQuestionsListForCustomers = async (sentimentId: string, msisdn: string) => {
    return await axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widget/sentiment/qList/${sentimentId}/${msisdn}`
    });
};

const putSentimentCustomerResponses = async (data: any) => {
    return await axiosInstance({
        method: "post",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widget/sentiment/saveResponse`,
        data: data
    });
};

export {
    getSentimentList,
    postSentimentData,
    getNumberCount,
    getTenantNumberBases,
    getSentimentIndividualStatus,
    putSentimentStatus,
    getSentimentLogs,
    getSentimentWidget,
    getSentimentQuestionsListForCustomers,
    putSentimentCustomerResponses
}