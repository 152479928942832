export const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const HOST_URL = process.env.REACT_APP_HOST_URL;
export const REVAMP_HOST_URL = process.env.REACT_APP_REVAMP_HOST_URL;

export const REACT_APP_NODE_BACKEND_URL = process.env.REACT_APP_NODE_BACKEND_URL;
export const REACT_APP_GOOGLE_RECAPTURE_SITE_KEY = process.env.REACT_APP_GOOGLE_RECAPTURE_SITE_KEY ? process.env.REACT_APP_GOOGLE_RECAPTURE_SITE_KEY : '';
export const REACT_APP_VALID_ROLES = process.env.REACT_APP_VALID_ROLES;
export const TEST_BACKEND_URL = "http://localhost:3000";
export const REACT_APP_CLASSIC_UI_URL =  process.env.REACT_APP_CLASSIC_UI_URL;
export const REACT_APP_LANDING_URL = process.env.REACT_APP_LANDING_PAGE;
export const OPEN_STREET_REVERSE_GEO_URL = "https://nominatim.openstreetmap.org/reverse?lat=[LATITUDE]&lon=[LONGITUDE]&format=json";

export const BASEURLPATHLENGTH = 0;


export const EMP_ATT_SAVE = "Are you sure, you want to save employee attendance?";
export const EMP_ATT_UPDATE = "Are you sure, you want to update employee attendance?";
export const PAYRENT_CONSENT_SAVE = "Are you sure to save and Pay for parent consent widget ?";
export const EMPLOYEE_SENTIMENT_SAVE = "Are you sure to save and Pay for Employee sentiment widget ?";
export const CHILD_TRACKING_SAVE = "Are you sure to save and pay for location track widget?";
export const LOCATION_WMEWC = "Are you sure you want to send the new tracking for admin approvals?";
export const LOCATION_MAPPING_SAVE = "Are you sure to save and pay for location mapping widget?";
export const LOCATION_MAPPING_UPDATE = "Are you sure to update and pay for location mapping widget?";
export const NEW_GENIE_SAVE = "Are you sure to create a new Genie Account?";
export const LOCATION_TRACKING_UPDATE = "Are you sure to update and pay for location track widget?";
export const RESUBMIT_LOCATION_TRACKING_UPDATE = "Are you sure you want to resubmit the session for approval?";
export const CHILD_TWO_FACTOR_SMS = "Are you sure to save and Pay for Two Factor Authentication Through SMS widget ?";
export const WEATHERALERT = "Are you sure to save and Pay for weather alert widget ?";
export const AIRINDEXALERT = "Are you sure to save and Pay for weather alert widget ?";
export const QUICKSURVEY = "Are you sure you want to pay for this survey?";
export const QUICKSURVEY_UPDATE = "Are you sure you want to update the status of Quick Survey ?";
export const SMSCONFIG_SAVE = "Are you sure to save SMS vote?";
export const SMSCONFIG_UPDATE = "Are you sure to update the SMS vote?";
export const TWO_FACTOR_USSD = "Are you sure to save and Pay for Two Factor Authentication Through USSD widget ?";
export const QUICK_CAMPAIGN = "Are you sure you want to pay for this campaign?";
export const QUICK_CAMPAIGN_UPDATE = "Are you sure to update your quick campaign ?";
export const NEW_EZCASH_SAVE = "Are you sure to create a new eZ Cash Account? ";
export const NEW_SPIN_WHEEL_SAVE = "Are you sure to create a new spin Request? ";
export const NEW_TRIBE_ID_SAVE = "Are you sure to create a new DigiEdu Verifiable Credentials Request? ";
export const LOCATION_OD_WC_EDIT = "Are you sure you want to resubmit the session for approval? ";
export const NEW_LOCATION_OD_WC_SAVE = "Are you sure you want to send the new tracking for admin approvals?";
export const PORT_SAVE = "Are you sure, you want to select this short code?";
export const LONG_NUMBER_SAVE = "Are you sure, you want to add this long number?";
export const NEW_ENT_AGENT_SAVE = "Do you want to create new agent?";
export const META_EVENT_SAVE = "Are you sure to save meta event details?"
export const MANAGE_CAMPAIGN_SAVE = "Are you sure you want to save this campaign?"
export const SENTIMENT = "Are you sure you want to pay for this sentiment?"
export const SENTIMENT_UPDATE = "Are you sure you want to update the status of Sentiment?";
export const SM_WIDGET_SAVE = "Are you sure you want to send this request?"
export const NEW_BIZ_EMAIL_SAVE = "Are you sure you want to create a new DialogBiz email account?";

export const DRAFT = "Draft";
export const REDRAFT = "Redraft";
export const REJECTED = "Rejected";
export const SUBMITTED = "Submitted";
export const RESUBMITTED = "Resubmitted";
export const STOPPED = "Stopped";
export const Failed = "Failed";

export const COMMON_TENANT_ID = process.env.REACT_APP_COMMON_TENANT_ID;
export const MARKETPLACEAUTH = process.env.REACT_APP_MARKETPLACEAUTH;

export const ESMS_APP_ID = "f1c23ca9-717f-4285-9002-275d0b9f9a66";

export const KYC_API_WIDGETS_API_DOC='https://d2xt8w8fh2bnjt.cloudfront.net/qa/widgets/images/ivp_api_doc_v2.pdf';

export const BETTER_HR_WIDGET_ID = "69be0e60-952f-4a6e-bc64-1d0466d80864";
export const CRBT_WIDGET_ID = "0fec3745-cd58-454b-881d-f67932ebc813";
export const WORKHUB_WIDGET_IDS = ["2c6e504d-6a22-405d-a8df-22e7049c4786","8a07ebe8-dc6d-49fe-b030-70d05dfd9316","115d7eda-9ab5-4ccc-9c1c-1de306814ae4","2f6f87d8-6ccd-4f28-bcba-06bce4637a28","5f61e9e0-e0f8-4c8a-937a-d31e6f218ab0"];
export const MULTIPLE_QS_DIALOG = "16ab6f42-0fa0-47c9-91c4-5e8be6bf360e";
export const SINGLE_QS_DIALOG = "cc85e443-4763-49fe-910c-d38129bb5e9b";

export const MANAGE_CAMPAIGN_MIN_REACH_COUNT = 3000;

export const DEFAULT_MSG = '9c6dc12f-6f9a-4fc6-8875-ed8dc31d8194';
export const SHORT_MSG = '09659763-c2bb-4814-95a7-636fe6709c3b';
export const LONG_MSG = '3a758147-5849-42ca-be69-669e2fc6d46c';
export const ESSAY_MSG_1 = 'a6abb8bd-0ddd-4173-8561-f1613601d5b9';
export const ESSAY_MSG_2 = '61ed2f0d-bd94-4a7f-ab11-c68cb9d7cc9f';
export const ESSAY_MSG_3 = '515cb32d-be28-4edb-a61a-20f882f76301';

export const REACT_APP_COMMON_AGENT_USER_UUID =  process.env.REACT_APP_COMMON_AGENT_USER_UUID;
export const SESSION_TIMEOUT_MINUTES = 2;


export const DEMO_COST = 0.01;

//payment methods
export const ADD_TO_BILL = 1;
export const CREDIT_CARD = 9;
export const INVOICE = 4;

// GWS status
export const GWS_INIT='init';
export const GWS_DOMAIN_SUBMITTED='DomainSubmitted';
export const GWS_PENDING_VERIFICATION='PendingVerification';
export const GWS_VERIFIED='Verified';

// GWS method
export const GWS_METHOD_CNAME='DNS_CNAME';
export const GWS_METHOD_TXT='DNS_TXT';

export const PARTNER_PORTAL_URL = process.env.REACT_APP_PARTNER_PORTAL;

export const KEYCLOAK_URL = process.env.REACT_APP_KEYCLOAK_URL;
export const KEYCLOAK_REALM = 'MarketPlace';
export const KEYCLOAK_CLIENT = 'market-place-portal-ui';

export const SENTIMENTWITH2FACE  = "Customer sentiment with 2 faces";
export const SENTIMENTWITH3FACE  = "Customer sentiment with 3 faces";
// export const BIZ_EMAIL_DOMAIN_LIST = process.env.REACT_APP_BIZ_EMAIL_DOMAIN_LIST.split(' ');
export const BIZ_EMAIL_DOMAIN_LIST = ['cumulus.lk'];
