import React from "react";
import { Button, Form, Modal, Radio, Row, Switch } from 'antd';

type Props = {
    showEntConsentModal: boolean;
    closeModalEntConsentModal: () => void,
    addEntConsentDetails: (values:any) => void,
    onConsentStatusChange: (values:any) => void,
    isConsentTrue: boolean
}

const KycConsentModal = ({ showEntConsentModal, closeModalEntConsentModal, addEntConsentDetails, onConsentStatusChange, isConsentTrue }: Props) => {

    return (
        <Modal
            title="User Consent"
            open={showEntConsentModal}
            onCancel={closeModalEntConsentModal}
            footer={null}>
            <div className="container-fluid">
                <p>You have successfully subscribed to the widget. Please select below consent status and medium in order to obtain customer consent before verifying the customer data.</p>
                <div className="mt-4 wow fadeIn">
                    <Form
                        className="form cf"
                        layout="horizontal"
                        onFinish={(values) => addEntConsentDetails(values)}
                        size="large">
                        <Form.Item
                            label="Consent Status"
                            name="consentStatus"
                            valuePropName="checked">
                            <Switch
                                checkedChildren={"YES"}
                                unCheckedChildren={"NO"}
                                defaultChecked={false}
                                onChange={(value) => onConsentStatusChange(value)}
                                style={{backgroundColor: isConsentTrue ? "#4E286D" : ""}}
                            />
                        </Form.Item>
                        {isConsentTrue &&
                            <Form.Item name="consentMedium" label="Consent Medium:"
                                       rules={[
                                           () => ({
                                               validator(rule, value) {
                                                   if (!value) {
                                                       return Promise.reject('You can’t keep this empty');
                                                   } else {
                                                       return Promise.resolve();
                                                   }
                                               },
                                           }),
                                       ]}>
                                <Radio.Group className="kyc-consent-medium">
                                    <Radio value="sms">SMS</Radio>
                                    <Radio value="ussd">USSD</Radio>
                                </Radio.Group>
                            </Form.Item>
                        }
                        <Row gutter={[24, 0]} className="row-wrap" justify="center" align="middle">
                            <Form.Item shouldUpdate={true}>
                                {() => (
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="btn btn-primary btn-sm">
                                        Save
                                    </Button>
                                )}
                            </Form.Item>
                        </Row>
                    </Form>
                </div>
            </div>
        </Modal>
    )
}
export { KycConsentModal };