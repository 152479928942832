import React, {useEffect, useState} from 'react'

type Props = {
    rateDetails: any
}

const RateItemTypeTenComponent = ({rateDetails}: Props) => {
    const [rateTitle, setRateTitle] = useState<string>('');
    const [rateDescription, setRateDescription] = useState<string>('');

    useEffect(() => {
        if (rateDetails.title) {
            let data = rateDetails.title.split(':')
            setRateTitle(data[0])
            setRateDescription(data[1])
        }
    }, [])
    return (
        <div className="pricing-grid-list-item bg-yellow">
            <div className="pricing-grid-list-content">
                <h4 className="pricing-item-title">{rateTitle}</h4>
                <p className="pricing-item-desc">
                    {rateDescription}
                </p>
                {rateDetails.rate && <span className="pricing-item-value__wrapper">
                    <span className="currancy">Rs&nbsp;</span>
                    <span className="value">{rateDetails.rate}</span>
                    <span
                        className="type">/ Per Licence</span>
                </span>}
            </div>
        </div>
    )
}

export {RateItemTypeTenComponent};