import React from 'react';
import bcImage from '../assets/images/empty-stack-holder.svg'; 

function NoPaymentMethods() {
    return <div className="panel-table-content-list">
        <div className="empty-stack__wrapper">
            <img src={bcImage} alt="" />
            <h4>This payment method will be available soon</h4>
        </div>
    </div>
}

export default NoPaymentMethods;