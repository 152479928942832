import React from 'react'

type Props = {
    title : string,
    amount: string
    subTitle?: string
}
export default function StatCard({title, amount, subTitle} : Props) {
    return (
        <div className="stats-widget-item__wrapper">
            <div className="stats-widget-item card">
                <span className="stats-title">{title}</span>
                <span className="stats-data">
                    <span className="stats-unit"></span>{amount}
                </span>
                {/* <span className="stats-peak">
                    <span className="icon__wrapper">
                        {" "}
                        <i className="bi bi-arrow-up txt-red" />
                    </span> */}
                    {/* {subTitle} */}
                {/* </span> */}
            </div>
        </div>
    )
}
