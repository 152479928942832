import userStore from "../reducers/user.store";
import { postAccountType, postBusinessData, postEnterprise, postProfileData, postUser, postEmail, 
    postEmailOtp, getUserDetails, postMsisdn, postMsisdnOtp, getRedisStatus, getUserSearch, getProfileStatus, 
    putBusinessProfileDetails, postResubmitEnterprise, postSatisfactionScore, getSatisfactionScores, 
    postGeneratedLeadRequest, postbusinessEmail, postbusinessEmailOtp } from '../../services/user.service';
import { AccountTypeModel } from "../../pages/home/models/request/account-type.model";
import { Enterprise } from "../../pages/home/models/request/enterprise.model";
import { DuplicateCheck } from "../../pages/home/models/request/duplicate-check.model";
import { UserModel } from "../../pages/signup/models/request/user.model";
import { notification } from "antd";

export const submitUser = (request: UserModel): any => {
    return (dispatch: any) => {
        postUser(request)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    dispatch(userStore.actions.userSignup(response.data));
                    console.log("success");
                } else {
                    alert("errored in response");
                }
            })
            .catch((error) => {
                dispatch(userStore.actions.userSignup(error));
                alert("errored");
            });
    }
};

export const submitEmail = (request: object): any => {
    return (dispatch: any) => {
        postEmail(request)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(userStore.actions.sendUserEmail(response.data));
                }
            })
            .catch((error) => {
                dispatch(userStore.actions.sendUserEmail(error));
            });
    }
};

export const submitOtp = (request: object): any => {
    return (dispatch: any) => {
        postEmailOtp(request)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(userStore.actions.sendEmailOtp(response.data));
                }
            })
            .catch((error) => {
                dispatch(userStore.actions.sendEmailOtp(error));
            });
    }
};

export const submitBusinessEmail = (request: object): any => {
    return (dispatch: any) => {
        postbusinessEmail(request)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(userStore.actions.sendUserEmail(response.data));
                }
            })
            .catch((error) => {
                dispatch(userStore.actions.sendUserEmail(error));
                notification.error({
                    message: `ERROR`,
                    description: error?.response?.data?.message,
                    placement: 'bottomRight'
                });
            });
    }
};

export const submitBusinessEmailOtp = (request: object): any => {
    return (dispatch: any) => {
        postbusinessEmailOtp(request)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(userStore.actions.sendEmailOtp(response));
                }
            })
            .catch((error) => {
                dispatch(userStore.actions.sendEmailOtp(error));
                notification.error({
                    message: `ERROR`,
                    description: "Invalid Email Otp",
                    placement: 'bottomRight'
                });
            });
    }
};

export const submitBillingEmail = (request: object): any => {
    return (dispatch: any) => {
        postbusinessEmail(request)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(userStore.actions.sendBillingEmail(response.data));
                }
            })
            .catch((error) => {
                dispatch(userStore.actions.sendBillingEmail(error));
                notification.error({
                    message: `ERROR`,
                    description: error?.response?.data?.message,
                    placement: 'bottomRight'
                });
            });
    }
};

export const submitBillingEmailOtp = (request: object): any => {
    return (dispatch: any) => {
        postbusinessEmailOtp(request)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(userStore.actions.sendBillingEmailOtp(response));
                }
            })
            .catch((error) => {
                dispatch(userStore.actions.sendBillingEmailOtp(error));
                notification.error({
                    message: `ERROR`,
                    description: "Invalid Email Otp",
                    placement: 'bottomRight'
                });
            });
    }
};

export const fetchUserDetails = (keycloakId: string): any => {
    return (dispatch: any) => {
        getUserDetails(keycloakId)
            .then((response) => {
                // console.log(response)
                if (response.status === 200) {
                    dispatch(userStore.actions.getUserDetails(response.data));
                }
            })
            .catch((error) => {
                dispatch(userStore.actions.getUserDetails(error));
            });
    }
};

export const submitAccountType = (request: AccountTypeModel): any => {
    return (dispatch: any) => {
        dispatch(userStore.actions.requestPending());
        postAccountType(request)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    notification.success({
                        message: `SUCCESS`,
                        description: 'Role Assigned successfully',
                        placement: 'bottomRight'
                    });
                    window.location.reload();
                    dispatch(userStore.actions.accountTypeSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(userStore.actions.accountTypeFailed(error));
            });
    }
};

export const submitProfile = (request: any): any => {
    return (dispatch: any) => {
        dispatch(userStore.actions.requestPending());
        postProfileData(request)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    dispatch(userStore.actions.profileSubmitSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(userStore.actions.profileSubmitFailed(error));
            });
    }
};

export const submitBusinessDetails = (request: any): any => {
    return (dispatch: any) => {
        dispatch(userStore.actions.requestPending());
        postBusinessData(request)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    notification.success({
                        message: `Successful!`,
                        description: response.data.message,
                        placement: 'bottomRight'
                    });
                    dispatch(userStore.actions.businessSubmitSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(userStore.actions.businessSubmitFailed(error));
            });
    }
};

export const submitEnterprise = (req: Enterprise, uuid: string): any => {
    return (dispatch: any) => {
        dispatch(userStore.actions.requestPending());
        postEnterprise(req, uuid)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    dispatch(userStore.actions.enterpriseSubmitSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(userStore.actions.enterpriseSubmitFailed(error));
            });
    }
};

export const checkDuplicate = (request: DuplicateCheck, uuid: string): any => {
    return (dispatch: any) => {
        dispatch(userStore.actions.requestPending());
        // postDuplicateCheck(request, uuid)
        //     .then((response) => {
        //         if (response.status === 200) {
        //             dispatch(userStore.actions.duplicateCheckSuccess(response.data));
        //         }
        //     })
        //     .catch((error) => {
        //         dispatch(userStore.actions.duplicateCheckFailed(error));
        //     });
    }
};

export const submitMsisdn = (request: object, tid: string): any => {
    return (dispatch: any) => {
        postMsisdn(request, tid)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(userStore.actions.sendUserMsisdn(response.data.data));
                }
            })
            .catch((error) => {
                dispatch(userStore.actions.sendUserMsisdn(error));
            });
    }
};

export const submitMsisdnOtp = (request: object, tid: string): any => {
    return (dispatch: any) => {
        postMsisdnOtp(request, tid)
            .then((response) => {
                console.log("######### 5");
                if (response.status === 200) {
                    dispatch(userStore.actions.sendMsisdnOtp(response.data));
                }
            })
            .catch((error) => {
                console.log("Error", error);
                dispatch(userStore.actions.sendMsisdnOtp(error));
            });
    }
};

export const fetchRedisStatus = (uuid: string): any => {
    return (dispatch: any) => {
        getRedisStatus(uuid)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(userStore.actions.fetchRedisSucces(response.data));
                }
            })
            .catch((error) => {
                dispatch(userStore.actions.fetchRedisFailed(error));
            });
    }
};

export const fetchUsernameSearch = (type: string, value: string): any => {
    return (dispatch: any) => {
        getUserSearch(type, value)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(userStore.actions.usernameSearchSucces(response.data.data));
                }
            })
            .catch((error) => {
                dispatch(userStore.actions.usernameSearchFailed(error));
            });
    }
};

export const fetchEmailSearch = (type: string, value: string): any => {
    return (dispatch: any) => {
        getUserSearch(type, value)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(userStore.actions.emailSearchSucces(response.data.data));
                }
            })
            .catch((error) => {
                dispatch(userStore.actions.emailSearchFailed(error));
            });
    }
};

export const fetchProfileStatus = (): any => {
    return (dispatch: any) => {
        getProfileStatus()
            .then((response) => {
                if (response.status === 200) {
                    dispatch(userStore.actions.profileStatusSuccess(response.data.data));
                }
            })
            .catch((error) => {
                dispatch(userStore.actions.profileStatusFailed(error));
            });
    }
};

export const editBusinessProfileDetails = (request: any, entUuid: any): any => {
    return (dispatch: any) => {
        dispatch(userStore.actions.requestPending());
        dispatch(userStore.actions.resetEnterpriseEdit(
            {
            "status": false
        } ));
        putBusinessProfileDetails(request, entUuid)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    notification.success({
                        message: `Successful!`,
                        description: response.data.message,
                        placement: 'bottomRight'
                    });
                    dispatch(userStore.actions.businessProfileEditSuccess({
                        "status": true
                    }));
                }
            })
            .catch((error) => {
                dispatch(userStore.actions.businessProfileEditFailed(error));
            });
    }
};

export const enterpriseResubmit = (request: any, entUuid: any): any => {
    return (dispatch: any) => {
        dispatch(userStore.actions.requestPending());
        dispatch(userStore.actions.resetEnterpriseEdit(
            {
                "status": false
            } 
        ));
        postResubmitEnterprise(request, entUuid)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    notification.success({
                        message: `Successful!`,
                        description: response.data.message,
                        placement: 'bottomRight'
                    });
                    dispatch(userStore.actions.enterpriseResubmitSuccess({
                        "status": true
                    }));
                }
            })
            .catch((error) => {
                dispatch(userStore.actions.enterpriseResubmitFailed(error));
            });
    }
};

export const submitSatisfactionScore = (request: any): any => {
    return (dispatch: any) => {
        postSatisfactionScore(request)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    dispatch(userStore.actions.satisfactionSubmitSuccess(true));
                    // notification.success({ 
                    //     message: `Rating submitted successfully!`,
                    //     description: response.data.message,
                    //     placement: 'bottomRight'
                    // });
                    console.log("success");
                } else {
                    alert("errored in response");
                    dispatch(userStore.actions.satisfactionSubmitSuccess(false));
                }
            })
            .catch((error) => {
                dispatch(userStore.actions.satisfactionSubmitSuccess(false));
                alert("errored");
            });
    }
};

export const fetchSatisfactionScores = (uuid: string): any => {
    return (dispatch: any) => {
        getSatisfactionScores(uuid)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(userStore.actions.fetchSatisfactionScoreSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(userStore.actions.fetchSatisfactionScoreFailed(error));
            });
    }
};

export const submitGeneratedLeadRequest = (request: any): any => {
    return (dispatch: any) => {
        postGeneratedLeadRequest(request)
            .then((response) => {
                if (response.data.statusCode === 200 || response.data.statusCode === 201) {
                    dispatch(userStore.actions.leadRequestSubmitSuccess(true));
                    notification.success({ 
                        message: `Thank you! We will get back to you.`,
                        description: response.data.message,
                        placement: 'topRight'
                    });
                    console.log("success");
                } else {
                    notification.error({ 
                        message: `Oops! Something went wrong.`,
                        description: response.data.message,
                        placement: 'topRight'
                    });                    
                    dispatch(userStore.actions.leadRequestSubmitSuccess(false));
                }
            })
            .catch((error) => { 
                notification.error({ 
                    message: `Oops! Something went wrong.`,
                    description: error.message,
                    placement: 'topRight'
                });    
                dispatch(userStore.actions.leadRequestSubmitSuccess(false));
            });
    }
};