import React from 'react';
import bcImage from "../../../assets/images/empty-stack-holder.svg";

function NoData() {
    return <div className="panel-table-content-list">
        <div className="empty-stack__wrapper">
            <img src={bcImage} alt="" />
            <h4>No Data Available</h4>
        </div>
    </div>
}

export default NoData;