import { AddedApp } from "../../pages/subscribed-apps/models/added-app.model";
import { getAppDetails, getSliderItems } from "../../services/app.service"
import { getTenantNumberBases, getWidgetsByCategory } from "../../services/widgets.service";
import memoryStore from "../reducers/memory.store";

export const memorizeApp = (appDetails: AddedApp): any => {
    return (dispatch: any) => {
        dispatch(memoryStore.actions.memorizeApp(appDetails));
    }
}
