import axiosInstance from '../axios-instance';
import * as CONSTANTS from '../constants';

const getWidgetAnalyticsForOwner = (tid: string) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/user/revamp/widget-expenses/${tid}`
    });
}

const getExpensesByWidgetsForOwner = (tid: string, limit: number, offset: number) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/user/revamp/expenses-by-widget/${tid}/${limit}/${offset}`
    });
}

const getExpensesForLqOwner = (tid: string) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/user/revamp/last-three-months-expenses/${tid}`
    });
}

const getTenantAnalyticsForPartner = (tid: string) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/user/tenant-expenses/${tid}`
    });
}

const getExpensesByTenantsForPartner = (tid: string, limit: number, offset: number) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/user/tenant-wise-expenses/${tid}/${limit}/${offset}`
    });
}

const getRevenueForLqPartner = (tid: string) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/user/last-three-months-expenses/${tid}`
    });
}




export {
    getWidgetAnalyticsForOwner,
    getExpensesByWidgetsForOwner,
    getExpensesForLqOwner,
    getTenantAnalyticsForPartner,
    getExpensesByTenantsForPartner,
    getRevenueForLqPartner
}
