/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { CSSProperties, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import { addDomain, fetchSingleApp, verifyDomain, createGWSCustomer, createGWSUser, verifyGwsDomainFinal, fetchConfigData } from '../../../store/actions';
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from '@emotion/react';
import AuthHandler from '../../../helper/AuthHandler';
import TableIcon from '../../../assets/images/icons/app-media-placeholder.svg';
import { PageHeaderStatic } from "../components/PageHeaderStatic";
import moment from "moment";
import NoData from "../../settings/components/NoData";
import { confirmAlert } from "react-confirm-alert";
import * as Constants from '../../../constants';
import { Divider, Steps, notification } from "antd";
import AddDomain from "./components/AddDomain";
import { Window } from "react-bootstrap-icons";
import VerifyDomain from "./components/VerifyDomain";
import CreateCustomer from "./components/CreateCustomer";
import CreateUser from "./components/CreateUser";
import DomainVerificationSummary from "./components/DomainVerificationSummary";

const override: CSSProperties = {
    display: "block",
    margin: "0 auto"
};

const GwsDomainCreate = ({ auth }: any) => {

    // const domainUuidParam = window.location.pathname.split('/')[Constants.BASEURLPATHLENGTH + 4];

    const { wid, domainId } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { userDetails } = useSelector(({ user }: any) => user);
    const { singleAppDetails } = useSelector(({ apps }: any) => apps);
    const { success, loading, error, configData, addDomainRes, domainVerificationRes, createCustomerRes, createUserRes, verifyDomainFinalRes } = useSelector(({ gws }: any) => gws);

    const [domainUuid, setDomainUuid] = useState(domainId ? domainId : '');
    const [isAddingDomainOnGoing, setIsAddingDomainOnGoing] = useState(false);
    const [isGetStatusOnGoing, setIsGetStatusOnGoing] = useState(true);
    const [isCreateUserOnGoing, setIsCreateUserOnGoing] = useState(false);
    const [isCreateCustomerOnGoing, setIsCreateCustomerOnGoing] = useState(false);
    const [isDomainVerificationOnGoing, setIsDomainVerificationOnGoing] = useState(false);
    const [isVerificationOnGoing, setIsVerificationOnGoing] = useState(false);
    const [provisionStatus, setProvisionStatus] = useState({
        domainCreated: false,
        verificationCodeReceived: false,
        userConsentTaken: false,
        customerCreated: false,
        userCreated: false,
        setUserAsAdmin: false,
        subscription: false,
        verified: false,
    })

    // useEffect(() => {
    //     console.log('GWS create - did mount')
    //     getStatus();
    // }, []);

    useEffect(() => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        dispatch(fetchSingleApp(wid!, tenantGUID));
        getStatus();
    }, [wid, userDetails]);

    useEffect(() => {
        if (configData) {
            setProvisionStatus(configData.WidgetGwsProvision)
        }
    }, [configData]);

    // useEffect(() => {
    //     if (isAddingDomainOnGoing && success) {
    //         if (addDomainRes) {
    //             const paths = window.location.pathname.split('/');
    //             navigate(`/${paths[Constants.BASEURLPATHLENGTH + 1]}/${paths[Constants.BASEURLPATHLENGTH + 2]}/config-domain/${addDomainRes.domainUuid}`);
    //         }
    //         setIsAddingDomainOnGoing(false);
    //         setDomainUuid(addDomainRes.domainUuid);
    //         getStatus();
    //     }else{
    //         if(error){
    //             setIsAddingDomainOnGoing(false);
    //             notification.error({
    //                 message: `Adding domain failed.`,
    //                 placement: 'topRight',
    //             });
    //         }
    //     }
    // }, [isAddingDomainOnGoing, addDomainRes]);

    // useEffect(() => {
    //     if (isCreateUserOnGoing && success) {
    //         setIsCreateUserOnGoing(false);
    //         getStatus();
    //     } else if (isCreateUserOnGoing && error) {
    //         setIsCreateUserOnGoing(false);
    //         notification.error({
    //             message: `Sign up failed.`,
    //             placement: 'topRight',
    //         });
    //     }
    // }, [isCreateUserOnGoing]);

    useEffect(() => {
        if (isCreateCustomerOnGoing && success) {
            setIsCreateCustomerOnGoing(false);
            getStatus();
        } else if (isCreateCustomerOnGoing && error) {
            setIsCreateCustomerOnGoing(false);
            notification.error({
                message: `Sign up failed.`,
                placement: 'topRight',
            });
        }
    }, [isCreateCustomerOnGoing]);

    useEffect(() => {
        if (isVerificationOnGoing && success) {
            setIsVerificationOnGoing(false);
            getStatus();
        } else if (isVerificationOnGoing && error) {
            setIsVerificationOnGoing(false);
            notification.error({
                message: `Verification failed.`,
                placement: 'topRight',
            });
        }
    }, [isVerificationOnGoing]);

    useEffect(() => {
        if (isDomainVerificationOnGoing && success) {
            setIsDomainVerificationOnGoing(false);
            getStatus();
        } else if (isDomainVerificationOnGoing && error) {
            setIsDomainVerificationOnGoing(false);
            notification.error({
                message: `Verification failed.`,
                placement: 'topRight',
            });
        }
    }, [isDomainVerificationOnGoing]);

    useEffect(() => {
        if (isGetStatusOnGoing && success) {
            setIsGetStatusOnGoing(false);
            getStatus();
        } else if (isGetStatusOnGoing && error) {
            setIsGetStatusOnGoing(false);
            notification.error({
                message: `Get domain data failed.`,
                placement: 'topRight',
            });
        }
    }, [isGetStatusOnGoing]);

    useEffect(() => {
        // if (addDomainRes && success) {
        //     const paths = window.location.pathname.split('/');
        //     navigate(`/${paths[Constants.BASEURLPATHLENGTH + 1]}/${paths[Constants.BASEURLPATHLENGTH + 2]}/create-domain/${addDomainRes.domainUuid}`);
        //     setDomainUuid(addDomainRes.domainUuid);
        // }else{
        //     notification.error({
        //         message: `Failed in adding domain.`,
        //         placement: 'topRight',
        //     });
        // }

        console.log('addDomainRes = ', addDomainRes);
        if (addDomainRes && error) {
            notification.error({
                message: addDomainRes ? addDomainRes : `Failed in adding domain.`,
                placement: 'topRight',
            });
        } else if (addDomainRes && success) {
            const paths = window.location.pathname.split('/');
            navigate(`/${paths[Constants.BASEURLPATHLENGTH + 1]}/${paths[Constants.BASEURLPATHLENGTH + 2]}/create-domain/${addDomainRes.domainUuid}`);
            setDomainUuid(addDomainRes.domainUuid);

            setIsAddingDomainOnGoing(false);
            getStatus();
        }

    }, [addDomainRes]);

    useEffect(() => {
        if (domainVerificationRes && success) {
            setIsVerificationOnGoing(false);
            getStatus();
        } else if(domainVerificationRes && error) {
            notification.error({
                message: `Failed in adding domain verification.`,
                placement: 'topRight',
            });
        }

    }, [domainVerificationRes]);

    useEffect(() => {
        if (createCustomerRes && success) {
            setIsCreateCustomerOnGoing(false);
            getStatus();
        } else if(createCustomerRes && error) {
            notification.error({
                message: `Failed in creating customer.`,
                placement: 'topRight',
            });
        }

    }, [createCustomerRes]);

    useEffect(() => {
        setIsCreateUserOnGoing(false);
        if (createUserRes && error) {
            notification.error({
                message: `Failed in creating admin user.`,
                placement: 'topRight',
            });
        } else if(createUserRes && success){
            getStatus();
        }
    }, [createUserRes]);

    useEffect(() => {
        setIsDomainVerificationOnGoing(false);
        if (verifyDomainFinalRes && error) {
            notification.error({
                message: `Failed in verifying domain.`,
                placement: 'topRight',
            });
        } else if(verifyDomainFinalRes && success){
            getStatus();
        }  
    }, [verifyDomainFinalRes]);

    const getStatus = async () => {
        setIsGetStatusOnGoing(true);
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        const domainUuid = window.location.pathname.split('/')[Constants.BASEURLPATHLENGTH + 4];
        console.log('userdetails in GWS create : ', userDetails)
        console.log('getStatus in GWS create : ', tenantGUID, ' = ', domainUuid)

        if (userDetails && domainUuid !== Constants.GWS_INIT) {
            dispatch(fetchConfigData(tenantGUID, domainUuid));
        }
    }

    const addGWSDomain = async (values: any) => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        let body = {
            domain: values.domain,
            verificationMethod: values.method
        }
        setIsAddingDomainOnGoing(true);
        if (wid !== null) {
            dispatch(addDomain(body, tenantGUID, wid!));
        }
    }

    const verifyGWSDomain = async () => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        let body = { "verificationDataReceived": true }
        setIsVerificationOnGoing(true);
        if (wid !== null) {
            dispatch(verifyDomain(body, tenantGUID, wid!, domainUuid));
        }
    }

    const createGwsCustomer = async (values: any) => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        let body = {
            contactName: values.contactName,
            email: values.email,
            organization: values.organization,
            postalCode: values.postalCode
        }
        setIsCreateCustomerOnGoing(true);
        if (wid !== null && domainUuid !== '') {
            dispatch(createGWSCustomer(body, domainUuid, tenantGUID, wid!));
        }
    }

    const createGwsUser = async (values: any) => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        let body = {
            firstName: values.firstName,
            lastName: values.lastName,
            username: values.adminEmail,
            password: values.password
        }
        setIsCreateUserOnGoing(true);
        if (wid !== null && domainUuid !== '') {
            dispatch(createGWSUser(body, domainUuid, tenantGUID, wid!));
        }
    }

    const verifyDomainFinal = async () => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        let body = {}
        setIsDomainVerificationOnGoing(true);
        if (wid !== null && domainUuid !== '') {
            dispatch(verifyGwsDomainFinal(body, tenantGUID, wid!, domainUuid));
        }
    }

    const onClickGoBack = () => {
        const paths = window.location.pathname.split('/');
        navigate(`/${paths[Constants.BASEURLPATHLENGTH + 1]}/${paths[Constants.BASEURLPATHLENGTH + 2]}/google-workspace`);
    }

    const checkStep = (step: string) => {
        const paths = window.location.pathname.split('/');
        switch (step) {
            case 'addDomain':
                return paths[Constants.BASEURLPATHLENGTH + 4] === Constants.GWS_INIT;
            case 'verifyData':
                return paths[Constants.BASEURLPATHLENGTH + 4] !== Constants.GWS_INIT && provisionStatus.domainCreated && provisionStatus.verificationCodeReceived && !provisionStatus.userConsentTaken && !provisionStatus.customerCreated && !provisionStatus.userCreated && !provisionStatus.setUserAsAdmin && !provisionStatus.subscription && !provisionStatus.verified;
            case 'createCustomer':
                return paths[Constants.BASEURLPATHLENGTH + 4] !== Constants.GWS_INIT && provisionStatus.domainCreated && provisionStatus.verificationCodeReceived && provisionStatus.userConsentTaken && !provisionStatus.customerCreated && !provisionStatus.userCreated && !provisionStatus.setUserAsAdmin && !provisionStatus.subscription && !provisionStatus.verified;
            case 'createUser':
                return paths[Constants.BASEURLPATHLENGTH + 4] !== Constants.GWS_INIT && provisionStatus.domainCreated && provisionStatus.verificationCodeReceived && provisionStatus.userConsentTaken && provisionStatus.customerCreated && !provisionStatus.userCreated && !provisionStatus.setUserAsAdmin && !provisionStatus.subscription && !provisionStatus.verified;
            case 'verification':
                return paths[Constants.BASEURLPATHLENGTH + 4] !== Constants.GWS_INIT && provisionStatus.domainCreated && provisionStatus.verificationCodeReceived && provisionStatus.userConsentTaken && provisionStatus.customerCreated && provisionStatus.userCreated && provisionStatus.setUserAsAdmin && provisionStatus.subscription;
            default:
                return false;
        }
    }

    if (AuthHandler.isAuthorized(auth, 'gws-config-domain') && userDetails !== null) {
        let paths = window.location.pathname.split('/');
        return (
            <React.Fragment>
                <div className="main-content main-spacer-x-y">
                    <PageHeaderStatic
                        page={'create'}
                        backLink={`/google-workspace/${wid}/list`}
                    />
                    <div className="card added-app-thumbnnail__wrapper">
                        <div
                            className="added-app-thumbnail__inner"
                            style={{
                                backgroundImage: `url(${(singleAppDetails && singleAppDetails.widgetDetails !== null) ? singleAppDetails.widgetDetails.leftImage : ""})`
                            }}
                        ></div>
                    </div>
                    <div className="card panel content-holder-panels added-app-form__wrapper">
                        <div className="panel-header">
                            <div className="panel-header-content__wrapper">
                                <h4 className="panel-header-title">Create Google Workspace Domain</h4>
                            </div>
                        </div>
                        <div className="panel-content">
                            <div className="wiz-content-form__wrapper">
                                <Steps
                                    current={checkStep('addDomain') ? 0
                                        : checkStep('verifyData') ? 1
                                            : checkStep('createCustomer') ? 2
                                                : checkStep('createUser') ? 3
                                                    : checkStep('verification') ? 4
                                                        : 5}
                                    style={{ padding: '10px 50px 20px' }}
                                    size="small"
                                >
                                    <Steps.Step title="" style={{ color: 'red' }} />
                                    <Steps.Step title="" />
                                    <Steps.Step title="" />
                                    <Steps.Step title="" />
                                    <Steps.Step title="" />
                                </Steps>
                                {loading ?
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', height: 200 }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                            <ScaleLoader
                                                cssOverride={override}
                                                // size={20}
                                                color={"#EB8C20"}
                                                loading={true}
                                            />
                                        </div>
                                    </div>
                                    : checkStep('addDomain') ? <AddDomain addGWSDomain={addGWSDomain} />
                                        : checkStep('verifyData') ? <VerifyDomain verifyGWSDomain={verifyGWSDomain} />
                                            : checkStep('createCustomer') ? <CreateCustomer createGwsCustomer={createGwsCustomer} />
                                                : checkStep('createUser') ? <CreateUser createGwsUser={createGwsUser} />
                                                    : checkStep('verification') ? <DomainVerificationSummary verifyDomainFinal={verifyDomainFinal} onClickGoBack={onClickGoBack} />
                                                        : <></>}
                            </div>
                        </div>
                    </div>
                    {/* <main className="pt-5 mx-lg-5">
                        <div className="container-fluid mt-5">
                            <div className="row wow fadeIn">
                                <div className="col-md-12 mb-4">
                                    <div className="card mb-4 profile__card">
                                        <div className="card-header text-center gws__header">
                                            <h4>Google Workspace</h4>
                                        </div>
                                        <Divider style={{ marginTop: 0 }} />
                                        <Steps
                                            current={checkStep('addDomain') ? 0
                                                : checkStep('verifyData') ? 1
                                                    : checkStep('createCustomer') ? 2
                                                        : checkStep('createUser') ? 3
                                                            : checkStep('verification') ? 4
                                                                : 5}
                                            style={{ padding: '10px 50px 20px' }}
                                            size="small"
                                        >
                                            <Steps.Step title="Add Domain" style={{ color: 'red' }} />
                                            <Steps.Step title="Domain Verification Data" />
                                            <Steps.Step title="Customer Creation" />
                                            <Steps.Step title="User Creation" />
                                            <Steps.Step title="Domain Verification" />
                                        </Steps>
                                        {loading ?
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', height: 200 }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                    <ScaleLoader
                                                        cssOverride={override}
                                                        // size={20}
                                                        color={"#EB8C20"}
                                                        loading={true}
                                                    />
                                                </div>
                                            </div>
                                            : checkStep('addDomain') ? <AddDomain addGWSDomain={addGWSDomain} />
                                                : checkStep('verifyData') ? <VerifyDomain verifyGWSDomain={verifyGWSDomain} />
                                                    : checkStep('createCustomer') ? <CreateCustomer createGwsCustomer={createGwsCustomer} />
                                                        : checkStep('createUser') ? <CreateUser createGwsUser={createGwsUser} />
                                                            : checkStep('verification') ? <DomainVerificationSummary verifyDomainFinal={verifyDomainFinal} onClickGoBack={onClickGoBack} />
                                                                : <></>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main> */}

                </div>
            </React.Fragment>
        );
    } else return (<React.Fragment></React.Fragment>);
}

export default GwsDomainCreate;