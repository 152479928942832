import React from 'react'
import { WidgetRate } from '../models/response/single-app.model';
import SimpleReactValidator from 'simple-react-validator';

type Props = {
    widgetRate: WidgetRate,
    onChangeRate: (e: any) => void,
    selectedRate: string,
    validator: SimpleReactValidator

}

const RateCardSelect = ({ widgetRate, onChangeRate, selectedRate,validator }: Props) => {
    return (
        <>
            {/* <span className="panel-group-title">{widgetRate.RateCard.name}</span> */}
            <div className={`panel-table-list-item card ${((widgetRate.uuid === selectedRate) ? 'active' : '')}`}>
                <div className="panel-table-listing-item__inner">
                    <div className="feature-details__wrapper">
                        <div className="feature-details-title__wrapper no-shade billing-and-plans-package-select">
                            <span className="title">{widgetRate.title}</span>
                            <span className="small-desc">
                                {widgetRate.description} - {widgetRate.rate}LKR
                            </span>
                        </div>
                    </div>
                </div>
                <div className="panel-table-listing-item__inner">
                    <div className="panel-table-right-align-item__wrapper">
                        <div className="panel-table-right-align-item">
                            <input
                                className="form-check-input secondary"
                                type="radio"
                                name=""
                                id="radio1"
                                value={widgetRate ? widgetRate.uuid! : ''}
                                onChange={onChangeRate}
                                checked={widgetRate.uuid === selectedRate}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { RateCardSelect }