import axiosInstance from '../axios-instance';
import * as CONSTANTS from '../constants';
import { newEsmsUserDetailsModel } from "../pages/home/models/request/newEsmsUserDetails.model";

const getUserDetails = (tenantId: any) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/externalapps/revamp/esms/user-details/${tenantId}`,
        headers: { 'Content-Type': 'applocation/json' },
    });
}

const postNewEsmsUser = (tenantId: string, widgetId: string, req: newEsmsUserDetailsModel) => {
    return axiosInstance({
        method: "post",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/externalapps/revamp/signup/${tenantId}/${widgetId}`,
        data: req
    })
}

const getImageAsBase64 = async (imageId: string) => {
    return await axiosInstance({
        method: `get`,
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/externalapps/revamp/esms/image-as-base64/${imageId}`,
    })
}


export {
    getUserDetails,
    postNewEsmsUser,
    getImageAsBase64
}    