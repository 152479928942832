import React from 'react'
import { AccountTypeDetails } from './AccountTypeDetails'
import { CollapsibleItemHeader } from './CollapsibleItemHeader'
import { WarningAlertBox } from './WarningAlertBox'
import { AccountCreationSectionsEnums } from '../../../enum'
import { UserRoleSelection } from './UserRoleSelection'
import { Spin } from 'antd'

type Props = {
    status: string,
    show: boolean,
    realmRoles: string[],
    setShow: (show: boolean) => void,
    onSave: () => void,
    progress: number,
    setAccountType: (accountType: string) => void,
    loading: boolean
}

const AccountTypeSection = ({ status, show, realmRoles, setShow, onSave, progress, setAccountType, loading }: Props) => {
    return (
        <div className="single-wiz-step__wrapper">
            <CollapsibleItemHeader
                title='Account Type'
                desc='Choose the appropriate user role.'
                status={status}
                isVisible={show}
                name={AccountCreationSectionsEnums.HOME_ACCOUNT_TYPE}
                handleCollapsButton={setShow}
            />
            <div className={"single-wiz-step__content " + (show ? "show" : "")}>
                {/* <WarningAlertBox closable={false} message="For your information, once selected you're unable to change the profile type. If you need any support, dial the Marketplace support team." /> */}
                <UserRoleSelection roles={realmRoles} setAccountType={setAccountType} status={status} progress={progress} />
                <div className="single-wiz-step-footer">
                    <Spin tip="" size="small" spinning={loading}>
                        {status == "pending" && <button className="btn btn-primary" onClick={onSave}>Continue</button>}
                    </Spin>
                </div>
            </div>
        </div>
    )
}

export { AccountTypeSection }