import React, { CSSProperties, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import { confirmAlert } from 'react-confirm-alert';
import { fetchSentimentQuestionsForCustomer, putCustomerResponses } from '../../../store/actions';
import AuthHandler from '../../../helper/AuthHandler';
import CryptoJS from "crypto-js";
import '../../../assets/css/sentimentstyles.css'
import * as CONSTANTS from '../../../constants';
import { CheckCircleFilled } from "@ant-design/icons";

type Props = {}

const CustomerSentiment = (props: Props) => {

    const { wid } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [respondedCount, setRespondedCount] = useState(0);
    const [userResponses, setUserResponses] = useState([]);
    const [selectedEmoji, setSelectedEmoji] = useState([]);

    const { success, loading, error, sentimentList,
        sentimentWidgetData,
        sentimentIndividualData,
        cutomerResponseLoading,
        customerResponseSuccess,
        customerResponseError,
        sentimentCustomerReponse } = useSelector(({ sentiment }: any) => sentiment);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sentimentId = urlParams.get('sentimentId');
    const msisdn = urlParams.get('mobile');
    const decodedMobile = decodeURIComponent(msisdn ? msisdn : '');
    const bytes = CryptoJS.AES.decrypt(decodedMobile, 'sentiment-secret');
    const decryptedMsisdn = bytes.toString(CryptoJS.enc.Utf8);

    const useForceUpdate = () => {
        const [value, setValue] = useState(0);
        return () => setValue(value => value + 1);
    }

    const forceUpdate = useForceUpdate()

    useEffect(() => {
        dispatch(fetchSentimentQuestionsForCustomer(sentimentId ? sentimentId : '', decryptedMsisdn));

        console.log("customerResponseSuccess", success)
    }, [sentimentId, msisdn])

    const renderSentiment3QuestionsCards = () => {
        if (sentimentWidgetData != null && sentimentIndividualData != null) {
            if (sentimentIndividualData.length > 0) {
                return (
                    <>
                        {
                            findNotRespondedSentiment(sentimentIndividualData) ? (
                                <>
                                    <div className="row">
                                        <div className="idea__card feedback__card ">
                                            <div className="row">
                                                {
                                                    sentimentIndividualData?.map((obj: any, index: number) => (
                                                        obj.status === 'SENT' ? (
                                                            <>
                                                                <div className="question__Wrapper">
                                                                    <p className="q-title">{index + 1}. {obj.question}</p>
                                                                    <div className="row reaction__wrapper">
                                                                        <div
                                                                            className={selectedEmoji[index + 1] === "Happy" ? "col emoji__container active" : "col emoji__container"}
                                                                            key={1}
                                                                            onClick={() => getEmoji('Happy', obj, index)} // Pass as parameter
                                                                        >
                                                                            <div className="emoji__wrapper">
                                                                                <img
                                                                                    src={require("../../../assets/images/customer-sentiment/happy.webp")}
                                                                                    alt="Satisfied face" />
                                                                                <p>Happy</p>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className={selectedEmoji[index + 1] === "Neutral" ? "col emoji__container active" : "col emoji__container"}
                                                                            key={2}
                                                                            onClick={() => getEmoji('Neutral', obj, index)} // Pass as parameter
                                                                        >
                                                                            <div className="emoji__wrapper">
                                                                                <img
                                                                                    src={require("../../../assets/images/customer-sentiment/sad.webp")}
                                                                                    alt="Sad face" />
                                                                                <p>Neutral</p>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className={selectedEmoji[index + 1] === "Unhappy" ? "col emoji__container active" : "col emoji__container"}
                                                                            key={1}
                                                                            onClick={() => getEmoji('Unhappy', obj, index)} // Pass as parameter
                                                                        >
                                                                            <div className="emoji__wrapper">
                                                                                <img
                                                                                    src={require("../../../assets/images/customer-sentiment/disappointed.webp")}
                                                                                    alt="disappointed face" />
                                                                                <p>Unhappy</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                </div>
                                                            </>
                                                        ) : null
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <button className="btn btn-primary btn-main-action"
                                        disabled={userResponses.length !== sentimentIndividualData.length}
                                        onClick={onSubmit}>Send &
                                        Submit
                                    </button>
                                </>
                            ) : renderAlreadySubmitted()
                        }
                    </>
                )
            }
        }
    }

    const renderSentiment2QuestionsCards = () => {
        if (sentimentWidgetData != null && sentimentIndividualData != null) {
            if (sentimentIndividualData.length > 0) {
                return (
                    <>
                        {
                            findNotRespondedSentiment(sentimentIndividualData) ? (
                                <>
                                    <div className="row">
                                        <div className="idea__card feedback__card ">
                                            <div className="row">
                                                {
                                                    sentimentIndividualData?.map((obj: any, index: number) => (
                                                        obj.status === 'SENT' ? (
                                                            <>
                                                                <div className="question__Wrapper">
                                                                    <p className="q-title">{index + 1}. {obj.question}</p>
                                                                    <div className="row reaction__wrapper">
                                                                        <div
                                                                            className={selectedEmoji[index + 1] === "Happy" ? "col emoji__container active" : "col emoji__container"}
                                                                            key={1}
                                                                            onClick={() => getEmoji('Happy', obj, index)} // Pass as parameter
                                                                        >
                                                                            <div className="emoji__wrapper">
                                                                                <img
                                                                                    src={require("../../../assets/images/customer-sentiment/happy.webp")}
                                                                                    alt="Satisfied face" />
                                                                                <p>Happy</p>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className={selectedEmoji[index + 1] === "Unhappy" ? "col emoji__container active" : "col emoji__container"}
                                                                            key={1}
                                                                            onClick={() => getEmoji('Unhappy', obj, index)} // Pass as parameter
                                                                        >
                                                                            <div className="emoji__wrapper">
                                                                                <img
                                                                                    src={require("../../../assets/images/customer-sentiment/disappointed.webp")}
                                                                                    alt="disappointed face" />
                                                                                <p>Unhappy</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                </div>
                                                            </>
                                                        ) : null
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <button className="btn btn-primary btn-main-action"
                                        disabled={userResponses.length !== sentimentIndividualData.length}
                                        onClick={onSubmit}>Send &
                                        Submit
                                    </button>
                                </>
                            ) : renderAlreadySubmitted()
                        }
                    </>
                )
            }
        }
    }

    const onSubmit = () => {
        if (userResponses.length === sentimentIndividualData.length) {
            confirmAlert({
                childrenElement: () => <div><b className="model_special_text">Are you sure you want to submit
                    response?</b><br /></div>,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => onSubmitSentimentResponses()
                    },
                    {
                        label: 'No',
                    }
                ]
            });
        }
    }

    const onSubmitSentimentResponses = () => {
        dispatch(putCustomerResponses(userResponses));
    }

    const getEmoji = (value: any, questionDetails: any, index: number) => {

        console.log("value",value);
        console.log("questionDetails",questionDetails);
        console.log("index",index);
        console.log("userResponses",userResponses);
        if (userResponses.filter((response: any) => response.questionId === questionDetails.individualStatusUuid).length > 0) {
            userResponses.splice(0, 1);
            console.log("splice",userResponses);
        }
        const response = {
            questionId: questionDetails.individualStatusUuid,
            response: value,
            status: 'RESPONDED'
        };

        let emoRes: any = userResponses;
        emoRes.push(response);
        setUserResponses(emoRes);

        let selectedEmojiObj: any = selectedEmoji;
        selectedEmojiObj[index + 1] = value;
        console.log("selectedEmojiObj",selectedEmojiObj)
        // selectedEmojiObj[index + 1] === "Happy" ? console.log("ds") : null;
        setSelectedEmoji(selectedEmojiObj);
        forceUpdate();
    }

    const renderSubmittedSuccess = () => {
        return (
            <React.Fragment>
                <main className={"pt-5 mx-lg-5 access_denied_container"}>
                    <div className="container-fluid mt-5">
                        <div className="card mb-4 wow fadeIn">
                            <div className="card-body justify-content-center">
                                <p className="text-center"><CheckCircleFilled style={{ fontSize: "36px", marginTop: "31px", color: "#00c851"}}/></p>
                                <h3 className="mb-sm-0 pt-1 text-success text-center">
                                    Thank you for your response.
                                </h3>
                                <h4 className="mb-2 mb-sm-0 pt-1 text-success text-center">
                                    {sentimentCustomerReponse.message}
                                </h4><br />
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        )
    }

    const findNotRespondedSentiment = (data: any) => {
        const exist = data.some((row: any) => row.status === 'SENT');
        return exist;
    }

    const renderAlreadySubmitted = () => {
        return (
            <React.Fragment>
                <div className="card card-body justify-content-center">
                    <p className="text-center"><i className="fas fa-check-circle fa-2x green-text"></i>
                    <CheckCircleFilled style={{ fontSize: "36px", marginTop: "31px", color: "#00c851"}}/></p>
                    <h3 className="mb-sm-0 pt-1 text-success text-center">
                        Already Submitted
                    </h3>
                    <h4 className="mb-2 mb-sm-0 pt-1 text-success text-center">
                        You have given your feedback. Thank you!
                    </h4><br />
                </div>
            </React.Fragment>
        );
    }

    return (
        <div className="container main">
            <div className="main__wrapper col">
                <h5 className="campaing__title">Please provide feedback on how you feel about this product</h5>

                {
                    customerResponseSuccess === false && customerResponseError === false ?
                        sentimentWidgetData != null ?
                            sentimentWidgetData.title == CONSTANTS.SENTIMENTWITH2FACE ?
                                renderSentiment2QuestionsCards()
                                :
                                sentimentWidgetData.title == CONSTANTS.SENTIMENTWITH3FACE ?
                                    renderSentiment3QuestionsCards() : null : null :
                        <p>{renderSubmittedSuccess()}</p>
                }
            </div>
            <footer className="main__footer container ">
                <div className="row ">
                    <div className="col-md-6 no-padding ">
                        <span className="copy-rights ">
                            {/*svg*/}
                            <div className="svg__wrapper ">
                                <img src={require("../../../assets/images/mp-primary-logo.svg")}
                                                className=""
                                                alt="" />
                            </div>
                            {/*/svg*/}
                            © 2021 Copyright. Engineered by Ideamart
                        </span>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default CustomerSentiment;