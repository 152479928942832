import React, {useEffect, useState} from 'react'
import {PanelTableListCard} from "../../pages/single-app/components";
import SimpleReactValidator from "simple-react-validator";
import {WidgetRate} from "../../pages/single-app/models/response/single-app.model";
import {fetchWorkhubSubscriptionData, saveWorkhubData} from "../../store/actions/workhub.action";
import {useAppDispatch} from "../../store";
import {useSelector} from "react-redux";

type Props = {
    appDetails: any
}

const ChargeableTypeTwoComponent = ({appDetails}: Props) => {

    const [usageCount, setUsageCount] = React.useState('');
    const [maxWidgetCount, setMaxWidgetCount] = React.useState(0);
    const [subscriptionRate, setSubscriptionRate] = React.useState(0.00);
    const [workhubData, setWorkhubData] = React.useState({
        "widgetCount": 0,
        "subscriptionRate": 0,
        "workhubConfigId": "",
        "masterWidgetUuid": "",
        "isChanged": false
    });
    const [validator] = useState(new SimpleReactValidator());

    const dispatch = useAppDispatch();

    const {
        userDetails,
    } = useSelector<any, any>(({ user }) => user);

    const {
        workHubSubscriptionData,
    } = useSelector<any, any>(({ workhub }) => workhub);

    useEffect(() => {
        let tenantId = userDetails?.OwnedEnterprises[0]?.groupId;
        dispatch(fetchWorkhubSubscriptionData(tenantId, appDetails.widgetDetails.uuid));
        if(appDetails && appDetails.widgetDetails && appDetails.widgetDetails.WidgetRate && Array.isArray(appDetails.widgetDetails.WidgetRate)) {
            appDetails.widgetDetails.WidgetRate.map((widgetRate: WidgetRate) => {
                if(Array.isArray(widgetRate.RatePlaneDetails) && widgetRate.RatePlaneDetails.length > 0) {
                    setUsageCount(JSON.stringify(widgetRate))
                }
            })
        }
    }, [])

    useEffect(() => {
        if(workHubSubscriptionData) {
            setMaxWidgetCount(workHubSubscriptionData.widgetCount);
            setSubscriptionRate(workHubSubscriptionData.subscriptionRate);
            if(appDetails && appDetails.widgetDetails && appDetails.widgetDetails.Category) {
                let masterId = appDetails.widgetDetails.Category.masterWidgetId;
                setWorkhubData({
                    ...workhubData,
                    widgetCount: workHubSubscriptionData.widgetCount,
                    subscriptionRate: workHubSubscriptionData.subscriptionRate,
                    workhubConfigId: workHubSubscriptionData.workhubConfigId,
                    masterWidgetUuid: appDetails.widgetDetails.Category.Widgets.find((widget: any) => widget.id === masterId)?.uuid,
                    isChanged: false
                });

            }
        }
    }, [workHubSubscriptionData])

    useEffect(() => {
        dispatch(saveWorkhubData(workhubData));
    }, [workhubData])

    const onChangePackage = async (e: any) => {
        if (appDetails && appDetails.widgetDetails && appDetails.widgetDetails.isPaymentMethodEditable) {
            setUsageCount(e.target.value);
            let subRate = JSON.parse(e.target.value).rate * maxWidgetCount
            setSubscriptionRate(subRate)
            setWorkhubData({
                ...workhubData,
                subscriptionRate: subRate
            });
        }
    }

    const onChangeWidgetCount = async (value: any) => {
        if (appDetails && appDetails.widgetDetails && appDetails.widgetDetails.isPaymentMethodEditable) {
            setMaxWidgetCount(value);
            let subRate = value * JSON.parse(usageCount).rate;
            setSubscriptionRate(subRate);
            setWorkhubData({
                ...workhubData,
                widgetCount: value,
                subscriptionRate: subRate,
                isChanged: true
            });
        }
    }

    return (
        <div className="panel-table-content-list">
            <PanelTableListCard
                appDetails={appDetails}
                onChangeUsageCount={onChangePackage}
                usageCount={usageCount}
                onChangeWidgetCount={onChangeWidgetCount}
                maxWidgetCount={maxWidgetCount}
                subscriptionRate={subscriptionRate}
                validator={validator}
            />
        </div>
    )
}

export {ChargeableTypeTwoComponent}