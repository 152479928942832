import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccountTypeModel } from "../../pages/home/models/request/account-type.model";
import { Enterprise } from "../../pages/home/models/request/enterprise.model";
import { Profile } from "../../pages/home/models/request/profile.model";
import { UserModel } from "../../pages/signup/models/request/user.model";
import { BusinessProfile } from "../../pages/home/models/request/business-profile.model";
// import { UserDetailsModel } from "../../models/userDetails.model";
import { DuplicateCheck } from "../../pages/home/models/request/duplicate-check.model";
import { MsisdnModel } from "../../pages/home/models/response/msisdn.model";
import { MsisdnOtpModel } from "../../pages/home/models/response/msisdn-otp.model";
import { UserDetailsModel } from "../../pages/home/models/response/user-details.model";
import { RedisStatusModel } from "../../pages/home/models/response/redis-status.model";
import { CommonResponseModel } from "../../pages/home/models/response/common-response.model";
import { UserSearchModel } from "../../pages/signup/models/response/usersearch.model";
import { CommonResponse } from "../../pages/signup/models/response/common-response.model";

interface AuthSliceModel {
  isUserLogin: boolean;
  user: UserModel | null;
  loading: boolean;
  isEmailVerified: boolean;
  emailOtpVerification: any;
  userCreationResponse: any;
  accountTypeSubmitResponse: CommonResponseModel | null,
  profileSubmitResponse: any,
  businessSubmitResponse: CommonResponseModel | null,
  enterpriseSubmitResponse: CommonResponseModel | null,
  userDetails: UserDetailsModel | null;
  msisdnData: MsisdnModel | null;
  msisdnOtpData: MsisdnOtpModel | null;
  redisStatus: RedisStatusModel | null;
  searchUsernameResponse: UserSearchModel | null;
  searchEmailResponse: UserSearchModel | null;
  businessProfileEditResponse: CommonResponseModel | null;
  enterpriseResubmitResponse: CommonResponseModel | null;
  isSatisfactionScoreSubmitted: boolean;
  satisfactionScoreResponse: any,
  isLeadRequestSubmitted: boolean;
}

const userStore = createSlice({
  name: "user-store",
  initialState: {
    isUserLogin: false,
    user: null,
    loading: false,
    isEmailVerified: false,
    emailOtpVerification: null,
    emailData: null,
    emailOtpData: null,
    billingEmailData: null,
    billingEmailOtpData: null,
    userCreationResponse: null,
    accountTypeSubmitResponse: null,
    profileSubmitResponse: null,
    businessSubmitResponse: null,
    enterpriseSubmitResponse: null,
    userDetails: null,
    msisdnData: null,
    msisdnOtpData: null,
    redisStatus: null,
    searchUsernameResponse: null,
    searchEmailResponse: null,
    businessProfileEditResponse: null,
    enterpriseResubmitResponse: null,
    isSatisfactionScoreSubmitted: false,
    satisfactionScoreResponse: null,
    isLeadRequestSubmitted: false
  } as AuthSliceModel,
  reducers: {
    userSignup: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        userCreationResponse: action.payload
      }
    },
    sendUserEmail: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        emailData: action.payload,
        isEmailVerified: action.payload.status ? action.payload.status : false
      }
    },
    sendEmailOtp: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        emailOtpData: action.payload,
        emailOtpVerification: action.payload
      }
    },
    sendBillingEmail: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        billingEmailData: action.payload,
      }
    },
    sendBillingEmailOtp: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        billingEmailOtpData: action.payload,
      }
    },
    getUserDetails: (state: AuthSliceModel, action: PayloadAction<UserDetailsModel>) => {
      return {
        ...state,
        userDetails: action.payload
      }
    },
    requestPending: (state: AuthSliceModel) => {
      return {
        ...state,
        loading: true
      }
    },
    accountTypeSuccess: (state: AuthSliceModel, action: PayloadAction<CommonResponseModel>) => {
      return {
        ...state,
        accountTypeSubmitResponse: action.payload,
        loading: false
      }
    },
    accountTypeFailed: (state: AuthSliceModel, action: PayloadAction<CommonResponseModel>) => {
      return {
        ...state,
        loading: false
      }
    },
    profileSubmitSuccess: (state: AuthSliceModel, action: PayloadAction<Profile>) => {
      return {
        ...state,
        profileSubmitResponse: action.payload,
        loading: false
      }
    },
    profileSubmitFailed: (state: AuthSliceModel, action: PayloadAction<Profile>) => {
      return {
        ...state,
        loading: false
      }
    },
    businessSubmitSuccess: (state: AuthSliceModel, action: PayloadAction<CommonResponseModel>) => {
      return {
        ...state,
        businessSubmitResponse: action.payload,
        loading: false
      }
    },
    businessSubmitFailed: (state: AuthSliceModel, action: PayloadAction<CommonResponseModel>) => {
      return {
        ...state,
        loading: false
      }
    },
    enterpriseSubmitSuccess: (state: AuthSliceModel, action: PayloadAction<CommonResponseModel>) => {
      console.log("@@@@@@@@@@@@@@@@ 1", action.payload);
      
      return {
        ...state,
        enterpriseSubmitResponse: action.payload,
        loading: false
      }
    },
    enterpriseSubmitFailed: (state: AuthSliceModel, action: PayloadAction<Enterprise>) => {
      console.log("@@@@@@@@@@@@@@@@ 2");
      return {
        ...state,
        loading: false
      }
    },
    duplicateCheckSuccess: (state: AuthSliceModel, action: PayloadAction<DuplicateCheck>) => {
      return {
        ...state,
        loading: false
      }
    },
    duplicateCheckFailed: (state: AuthSliceModel, action: PayloadAction<DuplicateCheck>) => {
      return {
        ...state,
        loading: false
      }
    },
    sendUserMsisdn: (state: AuthSliceModel, action: PayloadAction<MsisdnModel>) => {
      return {
        ...state,
        msisdnData: action.payload
      }
    },
    sendMsisdnOtp: (state: AuthSliceModel, action: PayloadAction<MsisdnOtpModel>) => {
      return {
        ...state,
        msisdnOtpData: action.payload
      }
    },
    fetchRedisSucces: (state: AuthSliceModel, action: PayloadAction<RedisStatusModel>) => {
      return {
        ...state,
        redisStatus: action.payload
      }
    },
    fetchRedisFailed: (state: AuthSliceModel, action: PayloadAction<RedisStatusModel>) => {
      return {
        ...state,
        redisStatus: action.payload
      }
    },
    usernameSearchSucces: (state: AuthSliceModel, action: PayloadAction<UserSearchModel>) => {
      return {
        ...state,
        searchUsernameResponse: action.payload
      }
    },
    usernameSearchFailed: (state: AuthSliceModel, action: PayloadAction<UserSearchModel>) => {
      return {
        ...state,
        searchUsernameResponse: null
      }
    },
    emailSearchSucces: (state: AuthSliceModel, action: PayloadAction<UserSearchModel>) => {
      return {
        ...state,
        searchEmailResponse: action.payload
      }
    },
    emailSearchFailed: (state: AuthSliceModel, action: PayloadAction<UserSearchModel>) => {
      return {
        ...state,
        searchEmailResponse: null
      }
    },
    profileStatusSuccess: (state: AuthSliceModel, action: PayloadAction<UserDetailsModel>) => {
      return {
        ...state,
        userDetails: action.payload
      }
    },
    profileStatusFailed: (state: AuthSliceModel, action: PayloadAction<UserDetailsModel>) => {
      return {
        ...state,
        userDetails: null
      }
    },
    businessProfileEditSuccess: (state: AuthSliceModel, action: PayloadAction<CommonResponseModel>) => {
      return {
        ...state,
        businessProfileEditResponse: action.payload,
        loading: false
      }
    },
    businessProfileEditFailed: (state: AuthSliceModel, action: PayloadAction<CommonResponseModel>) => {
      return {
        ...state,
        loading: false
      }
    },
    enterpriseResubmitSuccess: (state: AuthSliceModel, action: PayloadAction<CommonResponseModel>) => {
      return {
        ...state,
        enterpriseResubmitResponse: action.payload,
        loading: false
      }
    },
    enterpriseResubmitFailed: (state: AuthSliceModel, action: PayloadAction<CommonResponseModel>) => {
      return {
        ...state,
        loading: false
      }
    },
    resetEnterpriseEdit: (state: AuthSliceModel, action: PayloadAction<CommonResponseModel>) => {
      return {
        ...state,
        businessProfileEditResponse: action.payload,
        enterpriseResubmitResponse: action.payload
      }
    },
    satisfactionSubmitSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        isSatisfactionScoreSubmitted: action.payload,
        loading: false
      }
    },
    satisfactionSubmitFailed: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        isSatisfactionScoreSubmitted: action.payload,
        loading: false
      }
    },
    fetchSatisfactionScoreSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        satisfactionScoreResponse: action.payload
      }
    },
    fetchSatisfactionScoreFailed: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        satisfactionScoreResponse: null
      }
    },
    leadRequestSubmitSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        isLeadRequestSubmitted: action.payload,
        loading: false
      }
    },
  },
});

export default userStore;