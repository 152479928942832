import React from "react";
import { useNavigate } from "react-router-dom";

type Props = {
    backgroundImage: string;
    title: string;
    description: string;
    uuid: string;
}

const BusinessType = ({ backgroundImage, title, description, uuid }: Props) => {

    const navigate = useNavigate();
    return (
        <div className="feature-inner-lister-item card " onClick={() => navigate(`/apps/${uuid}`)}>
            <div
                className="thumbnail__wrapper"
                style={{
                    backgroundImage: `url(${backgroundImage})`
                }}
            ></div>
            <div className="feature-inner-lister-item-content">
                <h3 className="title">{title}</h3>
                <h5 className="desc">{description}</h5>
            </div>
        </div>
    )
}
export { BusinessType };