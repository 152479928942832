const Spinner = (props: any) => {
    return (
        <div className="section__wrapper">
            <section className="content__wrapper">
                <div className="primaryBrandLoaderBg">
                    <div className="primaryBrandLoader">
                        <img src={require('../assets/images/loader-bg-primary.svg').default} alt="" srcSet="" />
                    </div>
                </div>
            </section>
        </div>
    )
}

export { Spinner }