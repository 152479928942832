import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface SliceModel {
    error: boolean,
    loading: boolean,
    success: boolean,
    totAmountData: any,
    licenceCount: number
}

const betterHrStore = createSlice({
    name: "better-hr-store",
    initialState: {
        error: false,
        loading: false,
        success: false,
        totAmountData: null,
        licenceCount: 0
    } as SliceModel,
    reducers: {
        totalAmountFetch: (state: SliceModel, action: PayloadAction<number>) => {
            return {
                ...state,
                error: false,
                loading: true,
                success: false,
                licenceCount: action.payload
            }
        },
        totalAmountFetchSuccess: (state: SliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                error: false,
                loading: false,
                success: true,
                totAmountData: action.payload
            }
        },
        totalAmountFetchFail: (state: SliceModel) => {
            return {
                ...state,
                error: true,
                loading: false,
                success: false
            }
        }
    },
});

export default betterHrStore;