import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthSliceModel {
  widgetAnalyticsData: any | null;
  expensesPerWidgets: Array<any> | [];
  expensesLastQuater: Array<any> | [];
  tenantAnalyticsData: any | null;
  expensesPerTenant: Array<any> | [];
  revenueLastQuater: Array<any> | [];
}

const dashboardStore = createSlice({
  name: "dashboard-store",
  initialState: {
    widgetAnalyticsData: null,
    expensesPerWidgets: [],
    expensesLastQuater: [],
    tenantAnalyticsData: null,
    expensesPerTenant: [],
    revenueLastQuater: []
  } as AuthSliceModel,
  reducers: {
    getWidgetAnalytics: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        widgetAnalyticsData: action.payload
      }
    },
    getExpensesByWidgets: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        expensesPerWidgets: action.payload
      }
    },
    getLastQuaterExpenses: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        expensesLastQuater: action.payload
      }
    },
    getTenantAnalytics: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        tenantAnalyticsData: action.payload
      }
    },
    getExpensesByTenants: (state: AuthSliceModel, action: PayloadAction<[any]>) => {
      return {
        ...state,
        expensesPerTenant: action.payload
      }
    },
    getLastQuaterRevenue: (state: AuthSliceModel, action: PayloadAction<[any]>) => {
      return {
        ...state,
        revenueLastQuater: action.payload
      }
    },
  },
});

export default dashboardStore;