import React from 'react'

type Props = {
    status: string,
    progress: number
}

const ProgressStatus = ({ status, progress }: Props) => {
    return (
        status === "PENDING_APPROVAL" ? <>
            <div className="wiz-inner-content__wrapper d-flex flex-end flex-dir-column align-end completion-pert approval-pending ">
                <h1><i className="bi bi-exclamation-circle-fill"></i></h1>
                <h4>Pending Approval</h4>
            </div>
            <div className="wiz-inner-bg__cover pending" />
        </> :
            status === "APPROVED" ? <>
                <div className="wiz-inner-content__wrapper d-flex flex-end flex-dir-column align-end completion-pert approved ">
                    <h1><i className="bi bi-check-circle-fill"></i></h1>
                    <h4>Approved</h4>
                </div>
                <div className="wiz-inner-bg__cover approved" />
            </> :
                status === "processing" ? <>
                    <div className="primary-progress-spinner spinner-md" />
                </> : <>
                    <div className="wiz-inner-content__wrapper d-flex flex-end flex-dir-column align-end completion-pert ">
                        <h1>{progress}<span className="pert__wrapper">%</span></h1>
                        <h4>Completed</h4>
                    </div>
                    <div className="wiz-inner-bg__cover pending" />
            </>

    )
}

export { ProgressStatus }