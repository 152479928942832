import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { SlideshowLightbox } from 'lightbox.js-react';
import { useAppDispatch } from '../../../store';
import { SimilarApps, SingleAppModel, SubWidgets, WidgetFeatures, WidgetRate, WidgetScreenshots } from '../models/response/single-app.model';
import { InnerTopFeatureItem } from './InnerTopFeatureItem';
import { SingleAppWidget } from './SingleAppWidget';
import { SliderListingItem } from './SliderListingItem';
import { RateItemComponent } from './RateItemComponent';
import { ChargeableTypeTenComponent } from "./ChargeableTypeTenComponent";
import { RateItemCommonFeaturesComponent } from './RateItemCommonFeaturesComponent';
import WidgetRateTable from '../../apps/socialMedia/WidgetRateTable';

type Props = {
  appDetails: SingleAppModel | null
}

const RateListComponent = ({ appDetails }: Props) => {
  return (
    appDetails && appDetails.widgetDetails && <>
      {appDetails.widgetDetails.chargeableType == 1 ? <div className="pricing-grid-list__wrapper">
        {appDetails.widgetDetails && Array.isArray(appDetails.widgetDetails.WidgetRate) && appDetails.widgetDetails.WidgetRate.map((value: WidgetRate, index: number) => {
          return (
            <RateItemComponent
              key={index}
              rateDetails={value}
            />
          )
        })}
      </div> : appDetails.widgetDetails.chargeableType == 2 ?
        <div className="pricing-grid-list__wrapper">
          {appDetails.widgetDetails && Array.isArray(appDetails.widgetDetails.WidgetRate) && appDetails.widgetDetails.WidgetRate.map((value: WidgetRate, index: number) => {
            return (
              <RateItemComponent
                key={index}
                rateDetails={value}
              />
            )
          })}
        </div>
        : appDetails.widgetDetails.chargeableType == 3 ? <div className="pricing-grid-list__wrapper">
          {appDetails.widgetDetails && Array.isArray(appDetails.widgetDetails.WidgetRate) && appDetails.widgetDetails.WidgetRate.map((value: WidgetRate, index: number) => {
            return (
              <RateItemComponent
                key={index}
                rateDetails={value}
              />
            )
          })}
        </div> : appDetails.widgetDetails.chargeableType == 5 ?
          <div>
            {appDetails.widgetDetails && Array.isArray(appDetails.widgetDetails.WidgetRate) && appDetails.widgetDetails.WidgetRate.map((value: WidgetRate, index1: number) => {
              return (
                <React.Fragment key={index1}>
                  <table style={{ border: "1px solid black" }}>
                    <thead>
                      <th style={{
                        border: "1px solid black",
                        textAlign: 'center',
                        fontWeight: 'bold',
                        backgroundColor: '#e2e4e9'
                      }}>Reach
                      </th>
                      <th style={{
                        border: "1px solid black",
                        textAlign: 'center',
                        fontWeight: 'bold',
                        backgroundColor: '#e2e4e9'
                      }}>Price per SMS (Excluding tax)(Rs.) Short Message-124 Characters
                      </th>
                      <th style={{
                        border: "1px solid black",
                        textAlign: 'center',
                        fontWeight: 'bold',
                        backgroundColor: '#e2e4e9'
                      }}>Price per SMS (Excluding tax)(Rs.) Long Message-284 Characters
                      </th>
                      <th style={{
                        border: "1px solid black",
                        textAlign: 'center',
                        fontWeight: 'bold',
                        backgroundColor: '#e2e4e9'
                      }}>Price per SMS (Excluding tax)(Rs.) Essay Type Message-444 Characters
                      </th>
                      <th style={{
                        border: "1px solid black",
                        textAlign: 'center',
                        fontWeight: 'bold',
                        backgroundColor: '#e2e4e9'
                      }}>Price per SMS (Excluding tax)(Rs.) Essay Type Message-600 Characters
                      </th>
                      <th style={{
                        border: "1px solid black",
                        textAlign: 'center',
                        fontWeight: 'bold',
                        backgroundColor: '#e2e4e9'
                      }}>Price per SMS (Excluding tax)(Rs.) Essay Type Message-960 Characters
                      </th>
                    </thead>
                    <tbody>
                      {Array.isArray(value.RateCardSlabDetails) && value.RateCardSlabDetails.map((rateCardSlabType, index2) => {
                        return (
                          <tr style={{ border: "1px solid black" }}>
                            <td style={{
                              border: "1px solid black",
                              textAlign: 'center',
                              fontWeight: 'bold',
                              backgroundColor: '#e2e4e9'
                            }}>
                              {rateCardSlabType.discription}
                            </td>
                            {rateCardSlabType?.RateCardSalbRate.length > 0 && rateCardSlabType.RateCardSalbRate?.map((rates, index) => {
                              return (
                                <td style={{ border: "1px solid black", textAlign: 'center' }}>
                                  {rates.rate}
                                </td>
                              )
                            })
                            }
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </React.Fragment>
              )
            })}
          </div>
          :
          appDetails.widgetDetails.chargeableType == 6 ? <div className="pricing-grid-list__wrapper">
            {appDetails.widgetDetails && Array.isArray(appDetails.widgetDetails.WidgetRate) && appDetails.widgetDetails.WidgetRate.map((value: WidgetRate, index: number) => {
              let commonFeatureGrid = null;
              if (value?.RatecardAddtionalDetails?.detailsJson?.commonFeatures && index === 0) {
                // return (
                //   <RateItemCommonFeaturesComponent
                //     key={index}
                //     commonFeatures={value.RatecardAddtionalDetails.detailsJson.commonFeatures}
                //   />
                // )
                commonFeatureGrid = <RateItemCommonFeaturesComponent
                  key={index}
                  commonFeatures={value.RatecardAddtionalDetails.detailsJson.commonFeatures}
                />
              }
              return (
                <>
                  {commonFeatureGrid}
                  <RateItemComponent
                    key={index}
                    rateDetails={value}
                  />
                </>

              )
            })}
          </div> : appDetails.widgetDetails.chargeableType == 7 ? <div className="pricing-grid-list__wrapper">
            {appDetails.widgetDetails && Array.isArray(appDetails.widgetDetails.WidgetRate) && appDetails.widgetDetails.WidgetRate.map((value: WidgetRate, index: number) => {
              //set Rate to null, because this chargeableType (7) should not show the rate in subscription screen
              value = { ...value, rate: null };
              return (
                <RateItemComponent
                  key={index}
                  rateDetails={value}
                />
              )
            })}
          </div> : appDetails.widgetDetails.chargeableType == 8 ? <div className="pricing-grid-list__wrapper">
            {appDetails.widgetDetails && Array.isArray(appDetails.widgetDetails.SubWidgets) && appDetails.widgetDetails.SubWidgets.map((value: SubWidgets, index: number) => {
              return (
                <div className="pricing-grid-list-item bg-yellow">
                  <div className="pricing-grid-list-content">
                    <h4 className="pricing-item-title">{value.name}</h4>
                    <ul className="pricing-grid-list-options-list">
                      {value.SubWidgetRate?.map((obj: WidgetRate, index: number) => {
                        return (
                          <li className="options-item">
                            <b>{obj.title}</b>
                          </li>
                        )
                      })}
                      <p>Pricing will be decided by the Admin team.</p>
                    </ul>
                  </div>
                </div>
              )
            })}
          </div> : appDetails.widgetDetails.chargeableType == 9 ? <div className="pricing-grid-list__wrapper">
            {appDetails.widgetDetails && Array.isArray(appDetails.widgetDetails.WidgetRate) && appDetails.widgetDetails.WidgetRate.map((value: WidgetRate, index: number) => {
              return (
                <RateItemComponent
                  key={index}
                  rateDetails={value}
                />
              )
            })}
          </div> : appDetails.widgetDetails.chargeableType == 10 ? <ChargeableTypeTenComponent
            appDetails={appDetails}
          /> : appDetails.widgetDetails.chargeableType == 11 ? <div className="pricing-grid-list__wrapper">
            {appDetails.widgetDetails && Array.isArray(appDetails.widgetDetails.WidgetRate) && appDetails.widgetDetails.WidgetRate.map((value: WidgetRate, index: number) => {
              return (
                  <RateItemComponent
                      key={index}
                      rateDetails={value}
                  />
              )
            })}
          </div> : appDetails.widgetDetails.chargeableType == 13 ?
            <WidgetRateTable rateDetails={appDetails.widgetDetails} />
            : <></>}
    </>
  )
}

export { RateListComponent };