/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { CSSProperties } from "react";
import { useSelector } from 'react-redux';
import { Button, Form, Input, Select } from 'antd';
import * as Constants from '../../../../constants';

const { Option } = Select;
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

type Props = {
    addGWSDomain: (values: any) => any
}

const AddDomain = ({ addGWSDomain }: Props) => {

    const { userDetails } = useSelector(({ user }: any) => user);
    let path = window.location.pathname.split('/');

    return (
        <React.Fragment>
            <div className="card-body gws__body">
                <h4 className="gws-title">Add Domain</h4>
                <Form
                    {...layout}
                    name="add-domain"
                    style={{ maxWidth: 600, marginInline: 'auto', textAlign: 'left' }}
                    initialValues={{ remember: true }}
                    onFinish={addGWSDomain}
                    autoComplete="off"
                    labelAlign={"left"}
                >
                    <Form.Item
                        label="Domain"
                        name="domain"
                        rules={[
                            {
                                required: true,
                                message: 'This is Mandatory Field, You can\'t keep this as empty.',
                            },
                            {
                                pattern: RegExp('^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\\.[a-zA-Z]{2,3})$'),
                                message: 'Please enter a valid domain.'
                            }
                            // () => ({
                            //     validator(rule, value) {
                            //         if (!value) {
                            //             return Promise.reject('Please input your domain to set-up the account!');
                            //         } else if (!RegExp(^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,3})$).test(value)) {
                            //             return Promise.reject('Please input a valid domain');
                            //         } else {
                            //             return Promise.resolve();
                            //         }
                            //     },
                            // })
                        ]}
                    >
                        <Input placeholder="Enter your domain" />
                    </Form.Item>
                    <Form.Item
                        name="method"
                        label="Verification Method"
                        rules={[{
                            required: true,
                            message: 'This is Mandatory Field, You can\'t keep this as empty.',
                        }]}>
                        <Select
                            placeholder="Select a verification method."
                            allowClear
                        >
                            <Option value={Constants.GWS_METHOD_TXT}>TXT</Option>
                            <Option value={Constants.GWS_METHOD_CNAME}>CNAME</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item {...tailLayout} className={"wizard-action"}>
                        <Button type="primary" htmlType="submit" className={"btn btn-primary"}>
                            Next
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </React.Fragment>
    );
}

export default AddDomain;