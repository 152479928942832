import mainImage from '../../../../assets/images/icons/app-thumbail-placeholder.webp';
import iconImage from '../../../../assets/images/icons/app-media-placeholder.svg';

const ToAppsCard = (props: any) => {
    // console.log(props)
    return (
        <>
            <div className="app-listing-item__wrapper">
                {/* item */}
                <a href={`http://localhost:3001/apps/${props.app.id}`}>
                    <div className="app-listinng-item">
                        <a href={`http://localhost:3001/apps/${props.app.id}`}>
                            <div className="app-item-content__wrapper">
                                <div className="card app-thumbnail-holder"
                                    style={{ backgroundImage: `url(${props.app.main_image ? props.app.main_image : mainImage})` }}>
                                </div>
                            </div>
                        </a>
                        <div className="app-item-content__wrapper app-details">
                            <a href={`http://localhost:3001/apps/${props.app.id}`}>
                                <div className="card app-media-holder"
                                    style={{ backgroundImage: `url(${iconImage})` }}>
                                </div>
                            </a>
                            <div className="app-media-info-holder">
                                <a href={`http://localhost:3001/apps/${props.app.id}`}>
                                </a>
                                <a className="app-media-title">{props.app.title}</a>
                                <a href="http://" className="app-media-category">{props.app.category}</a>
                                <div className="app-stats__wrapper">
                                    <ul className="app-stats-list">
                                        <li className="app-stats-item">
                                            <span className="stats-icon">
                                                <i className="bi bi-download" />
                                            </span>
                                            <span className="stats-value">{props.app.downloads}+</span>
                                        </li>
                                        <li className="app-stats-item">
                                            <span className="stats-divider"> <i className="bi bi-dot" /></span>
                                        </li>
                                        <li className="app-stats-item">
                                            <span className="stats-icon ico-highlighter">
                                                <i className="bi bi-star-fill" />
                                            </span>
                                            <span className="stats-value">{props.app.rating}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
                {/* / item */}
            </div>
        </>
    );
}
export default ToAppsCard;