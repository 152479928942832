import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthSliceModel {

    mcConfigList:any[],
    // kycList: any[],
    loading: boolean,
    campaignStatus: any,
    campaignUpdate: any,
    campaignData: any,
    success: boolean,
    error: boolean,
    // filterData: any,
    // csvData: any,
    // userNames: any[],
    // kycEvents: any[],
    // kycConfigs: any,
    // subscriptionResponse: any,
    reachCostData: any,
    reachCostLoading: boolean,
    campaignDetails: any,
    campaignSubmitError: boolean,
    campaignSubmitLoading: boolean,
    campaignSubmitSuccess: boolean,
    campaignSubmitErrorMessage: string,
}


const manageCampaignStore = createSlice({
    name: "mc-store",
    initialState: {
        mcConfigList:[],
        campaignStatus: null,
        campaignData: null,
        campaignUpdate: null,
        // kycList: [],
        loading: false,
        // filterData: null,
        // csvData: null,
        // userNames: [],
        // kycEvents: [],
        // kycConfigs: null,
        success: false,
        error: false,
        // subscriptionResponse: null
        reachCostData: null,
        reachCostLoading: false,
        campaignDetails: null,
        campaignSubmitError: true,
        campaignSubmitLoading: false,
        campaignSubmitSuccess: false,
        campaignSubmitErrorMessage: "",
    } as AuthSliceModel,
    reducers: {
        mcConfigFetch: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        },
        mcConfigFetchSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                mcConfigList: action.payload,
                success: true,
                error: false
            }
        },
        mcConfigFetchFail: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                mcConfigList: []
            }
        },
        reachCostFetch: (state: AuthSliceModel) => {
            return {
                ...state,
                reachCostLoading: true,
                success: false,
                error: false
            }
        },
        reachCostFetchSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                reachCostLoading: false,
                reachCostData: action.payload,
                success: true,
                error: false
            }
        },
        reachCostFetchFail: (state: AuthSliceModel) => {
            return {
                ...state,
                reachCostLoading: false,
                success: false,
                error: true,
                mcConfigList: []
            }
        },
        fetchCampaignDetails: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        },
        fetchCampaignDetailsSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                campaignDetails: action.payload,
                success: true,
                error: false
            }
        },
        fetchCampaignDetailsFail: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                mcConfigList: []
            }
        },
        campaignPost: (state) => {
            return {
                ...state,
                campaignSubmitError: false,
                campaignSubmitLoading: true,
                campaignSubmitSuccess: false,
            };
        },
        campaignPostSuccess: (state) => {
            return {
                ...state,
                campaignSubmitError: false,
                campaignSubmitLoading: false,
                campaignSubmitSuccess: true,
            };
        },
        campaignPostFail: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                campaignSubmitError: true,
                campaignSubmitLoading: false,
                campaignSubmitSuccess: false,
                campaignSubmitErrorMessage: action.payload,
            };
        },
        manageCampaignWorkflowRequestPending: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
            }
        },
        manageCampaignWorkflowRequestSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            console.log('getAppDetailsSuccess-reducer', action.payload)
            return {
                ...state,
                campaignStatus: action.payload,
                loading: false,
                success: true,
                error: false,
            }
        },
        manageCampaignWorkflowRequestFailed: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false,
                error: true,
                success: false,
                campaignStatus: null,
            }
        },



        manageCampaignDetailRequestPending: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
            }
        },
        manageCampaignDetailRequestSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            console.log('getAppDetailsSuccess-reducer', action.payload)
            return {
                ...state,
                campaignData: action.payload,
                loading: false,
                success: true,
                error: false,
            }
        },
        manageCampaignDetailRequestFailed: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false,
                error: true,
                success: false,
                campaignData: null,
            }
        },
        confirmCampaignRequestPending: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
            }
        },
        confirmCampaignRequestSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            console.log('getAppDetailsSuccess-reducer', action.payload)
            return {
                ...state,
                campaignUpdate: action.payload,
                loading: false,
                success: true,
                error: false,
            }
        },
        confirmCampaignRequestFailed: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false,
                error: true,
                success: false,
                campaignUpdate: null,
            }
        },




        // kycListFilterSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
        //     return {
        //         ...state,
        //         loading: false,
        //         kycList: action.payload,
        //         success: true,
        //         error: false
        //     }
        // },
        // kycListFilterFail: (state: AuthSliceModel) => {
        //     return {
        //         ...state,
        //         loading: false,
        //         kycList: [],
        //         success: false,
        //         error: true
        //     }
        // },
        // kycUsernamesFetchSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
        //     return {
        //         ...state,
        //         loading: false,
        //         userNames: action.payload
        //     }
        // },
        // kycUsernamesFetchFail: (state: AuthSliceModel) => {
        //     return {
        //         ...state,
        //         loading: false,
        //         userNames: []
        //     }
        // },
        // kycEventsFetchSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
        //     return {
        //         ...state,
        //         loading: false,
        //         kycEvents: action.payload
        //     }
        // },
        // kycEventsFetchFail: (state: AuthSliceModel) => {
        //     return {
        //         ...state,
        //         loading: false,
        //         kycEvents: []
        //     }
        // },
        // kycCsvSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
        //     return {
        //         ...state,
        //         loading: false,
        //         csvData: action.payload
        //     }
        // },
        // kycCsvFail: (state: AuthSliceModel) => {
        //     return {
        //         ...state,
        //         loading: false,
        //         csvData: []
        //     }
        // },
        // kycConfigsFetchSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
        //     return {
        //         ...state,
        //         loading: false,
        //         kycConfigs: action.payload
        //     }
        // },
        // kycConfigsFetchFail: (state: AuthSliceModel) => {
        //     return {
        //         ...state,
        //         loading: false,
        //         kycConfigs: null
        //     }
        // },
        // kycConfigsPostSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
        //     return {
        //         ...state,
        //         loading: false
        //     }
        // },
        // kycConfigsPostFail: (state: AuthSliceModel) => {
        //     return {
        //         ...state,
        //         loading: false
        //     }
        // },
        // kycSubscriptionPostSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
        //     return {
        //         ...state,
        //         loading: false,
        //         subscriptionResponse: action.payload
        //     }
        // },
        // kycSubscriptionPostFail: (state: AuthSliceModel) => {
        //     return {
        //         ...state,
        //         loading: false,
        //         subscriptionResponse: null
        //     }
        // }
    },
});

export default manageCampaignStore;