import {message} from "antd";
import socialMediaStore from "../reducers/social-media.store";
import { getSingleConfigRatePlan, smConfigFetch, smRequestPost, smSingleConfigFetch } from "../../services/social-media.service";

export const fetchSocialMediaConfigs = (tenantId: string, widgetId: string): any => {
    return (dispatch: any) => {

        console.log('fetchSocialMediaConfigs | tenantId',tenantId)
        dispatch(socialMediaStore.actions.smConfigFetch());
        smConfigFetch(tenantId, widgetId)
            .then((response) => {
                if (response.status === 200) {
                    console.log('social media get configs list resp',response)
                    dispatch(socialMediaStore.actions.smConfigFetchSuccess(response.data.data));
                }
            })
            .catch((error) => {
                dispatch(socialMediaStore.actions.smConfigFetchFail(error));
            });
    }
};

export const saveSocialMediaRequest = (body: any, tenantId: string, widgetId: string, navigate:any): any => {
    return (dispatch: any) => {
        message.loading("Submitting...")
        dispatch(socialMediaStore.actions.smRequestPost());
        smRequestPost(body, tenantId, widgetId)
            .then((response: any) => {
                if (response.status === 200) {
                    message.success("New Social media request has been added successfully");
                    navigate(`/social-media/${widgetId}/list`);
                    dispatch(socialMediaStore.actions.smRequestPostSuccess(response.data.data));
                }
            })
            .catch((error: any) => {
                message.error("Error occurred while adding new request. Please try again.");
                dispatch(socialMediaStore.actions.smRequestPostFail(error.response.data.error));
            });
    }
};

export const fetchSocialMediaSingleConfig = (tenantId: string, configId: string): any => {
    return (dispatch: any) => {
        dispatch(socialMediaStore.actions.smRequestDetailFetch());
        smSingleConfigFetch(tenantId,configId)
            .then((response) => {
                if (response.status === 200) {
                    console.log('social media get single resp',response)
                    dispatch(socialMediaStore.actions.smRequestDetailFetchSuccess(response.data.data));
                }
            })
            .catch((error) => {
                dispatch(socialMediaStore.actions.smRequestDetailFetchFail(error));
            });
    }
};

export const fetchSocialMediaRatePlan = (tenantId: string, configId: string): any => {
    return (dispatch: any) => {
        getSingleConfigRatePlan(tenantId,configId)
            .then((response) => {
                if (response.status === 200) {
                    console.log('social media rate plan resp',response)
                    dispatch(socialMediaStore.actions.smRatePlanFetchSuccess(response.data.data[0]));
                }
            })
            .catch((error) => {
                dispatch(socialMediaStore.actions.smRatePlanFetchSuccess(error));
            });
    }
};
