import axios from 'axios';
import axiosInstance from '../axios-instance';
import * as Constants from '../constants';

const passwordResetInitiatePin = async (data,userDetail) => {
    console.log("Password_reset_GET_PIN *****ACTION*****",data,userDetail);
    return await axiosInstance({
        method: `post`,
        url: `${Constants.REACT_APP_NODE_BACKEND_URL}/passwordreset/otpValidate?userDetail=${userDetail}`,
        data: data
    })
}



const passwordResetValidatePin = async (data) => {
    return await axiosInstance({
        method: `post`,
        url: `${Constants.REACT_APP_NODE_BACKEND_URL}/passwordreset/otpVerify`,
        data: data
    })
};
//
const passwordReset = async (data) => {
    return await axiosInstance({
        method: `post`,
        url: `${Constants.REACT_APP_NODE_BACKEND_URL}/passwordreset/changePassword`,
        data: data
    })
};

export {
    passwordResetInitiatePin,
    passwordResetValidatePin,
    passwordReset
};