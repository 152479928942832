/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { CSSProperties, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import {fetchAllGwsConfigs, fetchSingleApp, resetGwsStatuses} from '../../../store/actions';
import AuthHandler from '../../../helper/AuthHandler';
import TableIcon from '../../../assets/images/icons/app-media-placeholder.svg';
import { PageHeaderStatic } from "../components/PageHeaderStatic";
import { confirmAlert } from "react-confirm-alert";
import { Tooltip } from "antd";
import { EyeOutlined, DeleteOutlined } from "@ant-design/icons";

const override: CSSProperties = {
    display: "block",
    margin: "0 auto"
};

const GwsConfigList = ({ auth }: any) => {

    const { wid } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { userDetails } = useSelector(({ user }: any) => user);
    const { success, loading, error, gwsConfigList } = useSelector(({ gws }: any) => gws);

    const [limit, setLimit] = useState<number>(10);
    const [offset, setOffset] = useState<number>(0);

    useEffect(() => {
        dispatch(resetGwsStatuses());
    }, []);

    useEffect(() => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        dispatch(fetchSingleApp(wid!, tenantGUID));
    }, [wid, userDetails]);

    useEffect(() => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        dispatch(fetchAllGwsConfigs(tenantGUID, wid!));
    }, [wid, userDetails]);

    // const onViewMore = () => {
    //     setOffset(offset + 10);
    // }

    const showDeletePopup = (domainUuid: string) => {
        confirmAlert({
            message: "Are you sure, you want to remove this domain?",
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => onDeleteDomain(domainUuid)
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    const onDeleteDomain = (domainUuid: string) => {
        console.log("Domain to be deleted: ", domainUuid)
        return true;
    }

    const onViewConfig = (domainId: string) => {
        navigate(`/google-workspace/${wid}/create-domain/${domainId}`);
    }

    const renderConfigList = () => {

        if (success && gwsConfigList) {
            if (gwsConfigList.length > 0) {
                return (
                    <div className="section__wrapper">
                        <div className="card panel content-holder-panels">
                            <div className="panel-header">
                                <div className="panel-header-content__wrapper">
                                    <h4 className="panel-header-title">List of Configs</h4>
                                </div>
                            </div>
                            <div className="panel-table-listing__wrapper">
                                <div className="table-parent__wrapper">
                                    <table className="table table-default">
                                        <tbody>
                                            <tr className="table-header">
                                                <th className="txt-left">#</th>
                                                <th className="txt-left">Domain</th>
                                                <th className="txt-left">Active Licences Count</th>
                                                <th className="txt-left">Admin User</th>
                                                <th className="txt-left">Status</th>
                                                <th className="txt-left"></th>
                                                <th className="txt-left"></th>
                                            </tr>
                                            {gwsConfigList.map((value: any, index: number) => {
                                                return (
                                                    <tr className="table-row ">
                                                        {/* <td>
                                                            <div className="feature-details__wrapper">
                                                                <div
                                                                    className="card app-media-holder"
                                                                    style={{
                                                                        backgroundImage: `url(${TableIcon})`
                                                                    }}
                                                                ></div>
                                                                <div className="feature-details-title__wrapper">
                                                                    <span className="title">{index + 1}</span>
                                                                </div>
                                                            </div>
                                                        </td> */}
                                                        <td className="txt-left">{index + 1}</td>
                                                        <td className="txt-left">{value.domain}</td>
                                                        <td className="txt-left">{(value.widgetGwsDailyEmailCount && value.widgetGwsDailyEmailCount.length > 0 && value.widgetGwsDailyEmailCount[0].mailCount) ? value.widgetGwsDailyEmailCount[0].mailCount : '-'}</td>
                                                        <td className="txt-left">{(value.adminFirstName && value.adminLastName) ? value.adminFirstName + ' ' + value.adminLastName : '-'}</td>
                                                        <td className="txt-left">{value.status}</td>
                                                        <td className="txt-left" style={{ textAlign: 'center' }}>
                                                            {/* <Link to={`/google-workspace/${wid}/view-google-workspace/${value.uuid}`}>
                                                                <i className="fas fa-eye ml-1"></i>
                                                            </Link> */}
                                                            <Tooltip title="View">
                                                                <EyeOutlined onClick={() => onViewConfig(value.uuid)} />
                                                            </Tooltip>
                                                        </td>
                                                        <td className="txt-left" style={{ textAlign: 'center' }}>
                                                            {/* <Link to={``} onClick={() => showDeletePopup(value.uuid)}>
                                                                <i className="fa fa-minus-circle" aria-hidden="true" style={{ color: "red" }}></i>
                                                            </Link> */}
                                                            <span aria-disabled>
                                                                <Tooltip title="Delete">
                                                                    <DeleteOutlined disabled/>
                                                                    {/* <DeleteOutlined onClick={() => showDeletePopup(value.uuid)} disabled/> */}
                                                                </Tooltip>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            {/*<tr className="table-row">*/}
                                            {/*    <td>*/}
                                            {/*        <a onClick={onViewMore}>View All</a>*/}
                                            {/*    </td>*/}
                                            {/*</tr>*/}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="section__wrapper">
                        <div className="empty-state-new-action__wrapper">
                            <div className="new-action-content__wrapper">
                                <h4 className="sub">No Records available</h4>
                                <h4 className="title">Create new request</h4>
                                <button className="btn btn-primary" onClick={() => navigate(`/google-workspace/${wid}/create-domain/init`)}>New request</button>
                            </div>
                        </div>
                    </div>
                )
            }
        } else if (loading) {
            return (
                <div className="section__wrapper">
                    <div className="empty-state-new-action__wrapper">
                        <section className="content__wrapper">
                            <div className="primaryBrandLoaderBg">
                                <div className="primaryBrandLoader">
                                    <img src={require('../../../assets/images/loader-bg-primary.svg').default} alt="" srcSet="" />
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            )
        } else if (error) {
            return (
                <div className="section__wrapper">
                    <div className="empty-state-new-action__wrapper">
                        <div className="new-action-content__wrapper">
                            <h4 className="sub">Oops, something went wrong!</h4>
                        </div>
                    </div>
                </div>
            )
        }
    }

    if (AuthHandler.isAuthorized(auth, 'gws-config-list') && userDetails !== null) {
        let path = window.location.pathname.split('/');
        return (
            <React.Fragment>
                <div className="main-content main-spacer-x-y">
                    <PageHeaderStatic
                        listShow={Array.isArray(gwsConfigList) && gwsConfigList.length > 0}
                        page={'list'}
                        newButtonLink={path[0] + '/' + path[1] + '/' + path[2] + '/create-domain/init'}
                        // externalLink={`https://admin.google.com/ac/accountchooser?continue=https://workspace.google.com/dashboard`}
                        externalLink={`https://admin.google.com/`}
                        externalLinkText={`Google Workspace Login`}
                    />
                    {renderConfigList()}
                </div>

            </React.Fragment>
        );
    } else return (<React.Fragment></React.Fragment>);
}

export default GwsConfigList;