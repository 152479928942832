import React from 'react'
import { SingleAppModel, WidgetRate } from '../models/response/single-app.model';

type Props = {
    appDetails: SingleAppModel
}

const RateCardDisplay = ({ appDetails }: Props) => {
    return (
        <>
            {appDetails.widgetDetails && Array.isArray(appDetails.widgetDetails.WidgetRate) && appDetails.widgetDetails.WidgetRate.map((widgetRate: WidgetRate, index: number) => {
                return (
                    <React.Fragment key={index}>
                        {widgetRate.RateCard.isMust ? <>
                            {appDetails.widgetDetails.chargeableType == 3 ? <>
                                <span className="panel-group-title">{widgetRate.RateCard.name}</span>
                                <div className={`panel-table-list-item card active`}>
                                    <div className="panel-table-listing-item__inner">
                                        <div className="feature-details__wrapper">
                                            <div className="feature-details-title__wrapper no-shade">
                                                <span className="title">{widgetRate.title}</span>
                                                <span className="small-desc">
                                                    {widgetRate.description}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel-table-listing-item__inner">
                                        <div className="panel-table-right-align-item__wrapper">
                                            <div className="panel-table-right-align-item">
                                                <input
                                                    className="form-check-input secondary"
                                                    type="radio"
                                                    name=""
                                                    id="radio1"
                                                    checked={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </> : appDetails.widgetDetails.chargeableType == 7 ? <>
                                <span className="panel-group-title">{widgetRate.RateCard.name}</span>
                                <div className={`panel-table-list-item card active`}>
                                    <div className="panel-table-listing-item__inner">
                                        <div className="feature-details__wrapper">
                                            <div className="feature-details-title__wrapper no-shade">
                                                <span className="title">{widgetRate.title}</span>
                                                <span className="small-desc">
                                                    {widgetRate.description}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel-table-listing-item__inner">
                                        <div className="panel-table-right-align-item__wrapper">
                                            <div className="panel-table-right-align-item">
                                                <input
                                                    className="form-check-input secondary"
                                                    type="radio"
                                                    name=""
                                                    id="radio1"
                                                    checked={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>  : appDetails.widgetDetails.chargeableType == 10 ? <>
                                <div className={`panel-table-list-item card active`}>
                                    <div className="panel-table-listing-item__inner">
                                        <div className="feature-details__wrapper">
                                            <div className="feature-details-title__wrapper no-shade">
                                                <span className="title">{widgetRate.title ? widgetRate.title.split(':')[0] : ""}</span>
                                                <span className="small-desc">
                                                                            {widgetRate.title ? widgetRate.title.split(':')[1] : ""}
                                                                        </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel-table-listing-item__inner">
                                        <div className="panel-table-right-align-item__wrapper">
                                            <div className="panel-table-right-align-item">
                                                <input
                                                    className="form-check-input secondary"
                                                    type="radio"
                                                    name=""
                                                    id="radio1"
                                                    checked={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>  : appDetails.widgetDetails.chargeableType == 11 ? <>
                                <span className="panel-group-title">{widgetRate.RateCard.name}</span>
                                <div className={`panel-table-list-item card active`}>
                                    <div className="panel-table-listing-item__inner">
                                        <div className="feature-details__wrapper">
                                            <div className="feature-details-title__wrapper no-shade">
                                                <span className="title">{widgetRate.title}</span>
                                                <span className="small-desc">
                                                            {widgetRate.description}
                                                        </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel-table-listing-item__inner">
                                        <div className="panel-table-right-align-item__wrapper">
                                            <div className="panel-table-right-align-item">
                                                <input
                                                    className="form-check-input secondary"
                                                    type="radio"
                                                    name=""
                                                    id="radio1"
                                                    checked={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </> : <></>}
                        </> : <>
                            {widgetRate.RateCard.name == 'Usage' ? <>
                                {widgetRate.RateCard.description === 'Transaction basis' ?
                                    <>
                                        <span className="panel-group-title">{widgetRate.RateCard.name}</span>
                                        <div className={`panel-table-list-item card active`}>
                                            <div className="panel-table-listing-item__inner">
                                                <div className="feature-details__wrapper">
                                                    <div className="feature-details-title__wrapper no-shade">
                                                        <span className="title">{widgetRate.title}</span>
                                                        <span className="small-desc">
                                                            {widgetRate.description}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel-table-listing-item__inner">
                                                <div className="panel-table-right-align-item__wrapper">
                                                    <div className="panel-table-right-align-item">
                                                        <input
                                                            className="form-check-input secondary"
                                                            type="radio"
                                                            name=""
                                                            id="radio1"
                                                            checked={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        {appDetails.widgetDetails.chargeableType == 3 ? <>
                                            {Array.isArray(widgetRate.RateCardSlabDetails) && widgetRate.RateCardSlabDetails.map((rateCard, index) => {
                                                return (
                                                    <>
                                                        <div className={`panel-table-list-item card active`}>
                                                            <div className="panel-table-listing-item__inner">
                                                                <div className="feature-details__wrapper">
                                                                    <div className="feature-details-title__wrapper no-shade">
                                                                        <span className="title">{rateCard.rate}</span>
                                                                        <span className="small-desc">
                                                                            {rateCard.discription}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="panel-table-listing-item__inner">
                                                                <div className="panel-table-right-align-item__wrapper">
                                                                    <div className="panel-table-right-align-item">
                                                                        <input
                                                                            className="form-check-input secondary"
                                                                            type="radio"
                                                                            name=""
                                                                            id="radio1"
                                                                            checked={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </> : appDetails.widgetDetails.chargeableType == 5 ? <>
                                            {Array.isArray(widgetRate.RateCardSlabDetails) && widgetRate.RateCardSlabDetails.map((rateCardSlabType, index) => {
                                                return (
                                                    <div className={`panel-table-list-item card active`}>
                                                        <div className="panel-table-listing-item__inner">
                                                            <div className="feature-details__wrapper">
                                                                <div className="feature-details-title__wrapper no-shade">
                                                                    <span className="title">{rateCardSlabType.discription}</span>
                                                                    {
                                                                        rateCardSlabType?.RateCardSalbRate.length > 0 && rateCardSlabType.RateCardSalbRate?.map((rates, index) => {
                                                                            return (
                                                                                <span className="small-desc">
                                                                                    {rates.rate}
                                                                                </span>
                                                                            )

                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="panel-table-listing-item__inner">
                                                            <div className="panel-table-right-align-item__wrapper">
                                                                <div className="panel-table-right-align-item">
                                                                    <input
                                                                        className="form-check-input secondary"
                                                                        type="radio"
                                                                        name=""
                                                                        id="radio1"
                                                                        checked={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )

                                            })}
                                        </> : appDetails.widgetDetails.chargeableType == 7 ? <>
                                            <span className="panel-group-title">{widgetRate.RateCard.name}</span>
                                            <div className={`panel-table-list-item card active`}>
                                                <div className="panel-table-listing-item__inner">
                                                    <div className="feature-details__wrapper">
                                                        <div className="feature-details-title__wrapper no-shade">
                                                            <span className="title">{widgetRate.title}</span>
                                                            <span className="small-desc">
                                                                {widgetRate.description}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="panel-table-listing-item__inner">
                                                    <div className="panel-table-right-align-item__wrapper">
                                                        <div className="panel-table-right-align-item">
                                                            <input
                                                                className="form-check-input secondary"
                                                                type="radio"
                                                                name=""
                                                                id="radio1"
                                                                checked={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </> : <></>}
                                    </>}
                            </> : widgetRate.RateCard.name == 'Annual charge' ? <>
                                {appDetails.widgetDetails.chargeableType == 10 ?  <>
                                                <div className={`panel-table-list-item card active`}>
                                                    <div className="panel-table-listing-item__inner">
                                                        <div className="feature-details__wrapper">
                                                            <div className="feature-details-title__wrapper no-shade">
                                                                <span className="title">{widgetRate.title ? widgetRate.title.split(':')[0] : ""}</span>
                                                                <span className="small-desc">
                                                                            {widgetRate.title ? widgetRate.title.split(':')[1] : ""}
                                                                        </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="panel-table-listing-item__inner">
                                                        <div className="panel-table-right-align-item__wrapper">
                                                            <div className="panel-table-right-align-item">
                                                                <input
                                                                    className="form-check-input secondary"
                                                                    type="radio"
                                                                    name=""
                                                                    id="radio1"
                                                                    checked={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </> : <></>}
                            </> : <>
                                <span className="panel-group-title">{widgetRate.RateCard.name}</span>
                                <div className={`panel-table-list-item card active`}>
                                    <div className="panel-table-listing-item__inner">
                                        <div className="feature-details__wrapper">
                                            <div className="feature-details-title__wrapper no-shade">
                                                <span className="title">{widgetRate.title}</span>
                                                <span className="small-desc">
                                                    {widgetRate.description}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel-table-listing-item__inner">
                                        <div className="panel-table-right-align-item__wrapper">
                                            <div className="panel-table-right-align-item">
                                                <input
                                                    className="form-check-input secondary"
                                                    type="radio"
                                                    name=""
                                                    id="radio1"
                                                    checked={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>}
                        </>}
                    </React.Fragment>
                )
            })}
        </>
    )
}

export { RateCardDisplay }