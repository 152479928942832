import axiosInstance from '../axios-instance';
import * as Constants from '../constants';
import axios from 'axios';

const getAllWidgets = (searchTerm) => {
    return axiosInstance.get(`${Constants.REACT_APP_BACKEND_URL}/widgets/${searchTerm}`);
}

const getAllWidgetsWithSubsDetails = (tenantId, searchTerm) => {
    return axiosInstance.get(`${Constants.REACT_APP_BACKEND_URL}/widgets/${tenantId}/${searchTerm}`);
}

const getWidget = (categoryId) => {
    return axiosInstance.get(`${Constants.REACT_APP_BACKEND_URL}/widgets/widgetByCategories?categoryId=${categoryId}`);
}

const getMyWidget = () => {
    return axiosInstance.get(`${Constants.REACT_APP_BACKEND_URL}/widgets/mywidgets`);
}

const subscribeWidget = async (enterpriseId, widgetId) => {
    return await axiosInstance({
        method: `post`,
        url: `${Constants.REACT_APP_BACKEND_URL}/widgets/sub/${enterpriseId}/${widgetId}`,
        headers: {
            'Content-Type': 'application/json',
            'User-Agent': 'application/json'
        }
    })
}

const getWidgetCharging = async (widgetId) => {
    return axiosInstance({
        method: `GET`,
        url: `${Constants.REACT_APP_BACKEND_URL}/widgets/charging/${widgetId}`,
    });
}

const saveWidgetCharging = async (enterpriseId, widgetId, formData) => {
    return axiosInstance({
        method: `post`,
        data: formData,
        url: `${Constants.REACT_APP_BACKEND_URL}/widgets/sub/${enterpriseId}/${widgetId}`,
    });
}

const unsubscribeWidget = async (enterpriseId, widgetId) => {
    return axiosInstance({
        method: `delete`,
        url: `${Constants.REACT_APP_BACKEND_URL}/widgets/sub/${enterpriseId}/${widgetId}`,
    });
}

const getRateCardStatus = async (tenantId, widgetId) => {
    return axiosInstance({
        method: `get`,
        url: `${Constants.REACT_APP_BACKEND_URL}/widgeddts/sub/${tenantId}/${widgetId}`,
    });
}
const getCheckRatePlanAvailable = async (enterpriseId, widgetId) => {
    return axiosInstance({
        method: `get`,
        url: `${Constants.REACT_APP_BACKEND_URL}/widgets/plan/${enterpriseId}/${widgetId}`,
    });
}

const saveWorkHubSubscribe = async (enterpriseId, widgetId, formData) => {
    return axiosInstance({
        method: `post`,
        data: formData,
        url: `${Constants.REACT_APP_NODE_BACKEND_URL}/widget/workhub/${enterpriseId}/${widgetId}`,
    });
}

const saveBetterHrSubscribe = async (enterpriseId, widgetId, formData) => {
    return axiosInstance({
        method: `post`,
        data: formData,
        url: `${Constants.REACT_APP_NODE_BACKEND_URL}/widgets/sub/${enterpriseId}/${widgetId}`,
    });
}


const saveClick2PaySubscribe = async (enterpriseId, widgetId, formData) => {
    return axiosInstance({
        method: `post`,
        data: formData,
        url: `${Constants.REACT_APP_BACKEND_URL}/widget/c2p/revamp/${enterpriseId}/${widgetId}`,
    });
}

const getWorkHub = async (tid, wid) => {
    // console.log('getWorkHub tid ------->', tid);
    // console.log('getWorkHub wid ------->', wid);
    return axiosInstance({
        method: `GET`,
        url: `${Constants.REACT_APP_BACKEND_URL}/widget/workhub/${tid}/${wid}`,
    });
}

const putWorkHub = async (tid, WidegetId, formData) => {
    return axiosInstance({
        method: `PUT`,
        data: formData,
        url: `${Constants.REACT_APP_BACKEND_URL}/widget/workhub/${tid}/${WidegetId}`,
    });
}

const getWalletReport = async (tid) => {
    return axiosInstance({
        method: `GET`,
        url: `${Constants.REACT_APP_BACKEND_URL}/report/wallet/${tid}`,
    });
}

const getSearchedWidgets = async (body, enterpriseId) => {
    if (enterpriseId == 1) {
        return axiosInstance({
            method: `post`,
            data: body,
            url: `${Constants.REACT_APP_BACKEND_URL}/widgets/search`,
        });
    } else {
        return axiosInstance({
            method: `post`,
            data: body,
            url: `${Constants.REACT_APP_BACKEND_URL}/widgets/search/${enterpriseId}`,
        });
    }
}

const getWidgetCategories = async () => {
    return axiosInstance({
        method: `GET`,
        url: `${Constants.REACT_APP_BACKEND_URL}/widget/category`,
    });
}

const getWidgetKeywords = async () => {
    return axiosInstance({
        method: `GET`,
        url: `${Constants.REACT_APP_BACKEND_URL}/widget/keywords/list`,
    });
}

const getWidgetComponents = async () => {
    return axiosInstance({
        method: `GET`,
        url: `${Constants.REACT_APP_BACKEND_URL}/widget/component/List`,
    });
}

const getWidgetComponentDataTypes = async () => {
    return axiosInstance({
        method: `GET`,
        url: `${Constants.REACT_APP_BACKEND_URL}/dataTypes/list`,
    });
}

const getWidgetNameExists = async (widgetTitle) => {
    return axiosInstance({
        method: `get`,
        url: `${Constants.REACT_APP_BACKEND_URL}/widgets/checkTitleExists/${widgetTitle}`,
    });
}

const saveLeadWidgetDetails = async (formData) => {
    return axiosInstance({
        method: `post`,
        data: formData,
        url: `${Constants.REACT_APP_BACKEND_URL}/widgets/data/widget`,
    });
}

const updateLeadWidgetDetails = async (formData) => {
    return axiosInstance({
        method: `put`,
        data: formData,
        url: `${Constants.REACT_APP_BACKEND_URL}/widgets/commonWidget/data/widget/edit`,
    });
}

const saveLeadWidgetConfigDetails = async (widgetId, formData) => {
    return axiosInstance({
        method: `post`,
        data: formData,
        url: `${Constants.REACT_APP_BACKEND_URL}/widgets/commonWidget/${widgetId}`,
    });
}

const getLeadWidgetDetails = async (widgetId) => {
    return axiosInstance({
        method: `get`,
        url: `${Constants.REACT_APP_BACKEND_URL}/widgets/component/view/${widgetId}`,
    });
}

const getEmojotWorkflowDetails = async (tenantId, widgetId, configId) => {
    return axiosInstance({
        method: `get`,
        url: `${Constants.REACT_APP_BACKEND_URL}/emojot/commonWidget/${tenantId}/${widgetId}/${configId}`,
    });
}

const validateToneName = async (name) => {
    return axiosInstance({
        method: `get`,
        url: `${Constants.REACT_APP_NODE_BACKEND_URL}/widget/crbt/tone/validate-tone-name/${name}`,
    });
}

const getWidgetDetails = async (widgetId) => {
    return axiosInstance({
        method: `get`,
        url: `${Constants.REACT_APP_BACKEND_URL}/widgets/product-details/${widgetId}`,
    });
}

/**
 *  These widgets fetch for public screen only
 */
const getAllWidgetsForPublicPage = async () => {
    return axiosInstance({
        method: `get`,
        url: `${Constants.REACT_APP_BACKEND_URL}/widgets/publicPage/getWidgets`,
    });
};

const postContactUsFormData = async (data) => {
    return axiosInstance({
        method: `post`,
        url: `${Constants.REACT_APP_BACKEND_URL}/widgets/save-contact-us-info`,
        data: data
    });
};

const getWidgetsByCategory = async (catId, tenantId, limit, offset) => {
    return axiosInstance({
        method: `get`,
        url: `${Constants.REACT_APP_BACKEND_URL}/widgets/category/${catId}/${tenantId}/${limit}/${offset}`
    });
};

const updateWidgetConfigStatus = async (body, configUuid, tenantUUID, widgetUUID) => {
    return axiosInstance({
        method: `put`,
        url: `${Constants.REACT_APP_BACKEND_URL}/widget/crbt/config/manual-deactivation/${tenantUUID}/${widgetUUID}/${configUuid}`,
        data: body
    });
};

const onSearch = (searchString) => {
    return axios.get(`https://nominatim.openstreetmap.org/?addressdetails=2&q=${searchString}&format=json&limit=5`)
        .then(function (response) {
            //handle success
            return response;
        }).catch(function (response) {
            //handle error
            // console.log(response);
            return response;
        });
}

const getTenantNumberBases = (tenantId, widgetId) => {
    return axiosInstance({
        method: "get",
        url: `${Constants.REACT_APP_NODE_BACKEND_URL}/ent/numberbase/${tenantId}/${widgetId}`
    })
};

const getWidgetStats = async (tenantId) => {
    return axiosInstance({
        method: `get`,
        url: `${Constants.REACT_APP_NODE_BACKEND_URL}/widgets/revamp/subscriptionCount/${tenantId}`
    });
};

export {
    getAllWidgets,
    getWidget,
    getMyWidget,
    subscribeWidget,
    getAllWidgetsWithSubsDetails,
    getWidgetCharging,
    saveWidgetCharging,
    unsubscribeWidget,
    getCheckRatePlanAvailable,
    saveWorkHubSubscribe,
    getWorkHub,
    putWorkHub,
    saveClick2PaySubscribe,
    getWalletReport,
    getSearchedWidgets,
    getWidgetCategories,
    getWidgetComponents,
    getWidgetNameExists,
    saveLeadWidgetDetails,
    saveLeadWidgetConfigDetails,
    getWidgetKeywords,
    getWidgetComponentDataTypes,
    getLeadWidgetDetails,
    getEmojotWorkflowDetails,
    validateToneName,
    getWidgetDetails,
    getAllWidgetsForPublicPage,
    postContactUsFormData,
    updateLeadWidgetDetails,
    getWidgetsByCategory,
    updateWidgetConfigStatus,
    onSearch,
    getTenantNumberBases,
    saveBetterHrSubscribe,
    getWidgetStats
};