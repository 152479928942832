import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {RedisStatusModel} from "../../pages/apps/kyc/models/response/kyc-redis-status.model";

interface AuthSliceModel {
  kycList: any[],
  loading: boolean,
  success: boolean,
  error: boolean,
  filterData: any,
  csvData: any,
  userNames: any[],
  kycEvents: any[],
  kycConfigs: any,
  subscriptionResponse: any,
  redisStatus: RedisStatusModel | null,
  redisStatusSuccess: boolean,
  redisStatusFailed: boolean,
}


const kycStore = createSlice({
  name: "kyc-store",
  initialState: {
    kycList: [],
    loading: false,
    filterData: null,
    csvData: null,
    userNames: [],
    kycEvents: [],
    kycConfigs: null,
    success: false,
    error: false,
    subscriptionResponse: null,
    redisStatus: null,
    redisStatusSuccess: false,
    redisStatusFailed: false,
  } as AuthSliceModel,
  reducers: {
    kycListFetch: (state: AuthSliceModel) => {
      return {
        ...state,
        loading: true,
        success: false,
        error: false
      }
    },
    kycListFetchSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        kycList: action.payload,
        success: true,
        error: false
      }
    },
    kycListFetchFail: (state: AuthSliceModel) => {
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        kycList: []
      }
    },
    kycListFilterSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        kycList: action.payload,
        success: true,
        error: false
      }
    },
    kycListFilterFail: (state: AuthSliceModel) => {
      return {
        ...state,
        loading: false,
        kycList: [],
        success: false,
        error: true
      }
    },
    kycUsernamesFetchSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        userNames: action.payload
      }
    },
    kycUsernamesFetchFail: (state: AuthSliceModel) => {
      return {
        ...state,
        loading: false,
        userNames: []
      }
    },
    kycEventsFetchSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        kycEvents: action.payload
      }
    },
    kycEventsFetchFail: (state: AuthSliceModel) => {
      return {
        ...state,
        loading: false,
        kycEvents: []
      }
    },
    kycCsvSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        csvData: action.payload
      }
    },
    kycCsvFail: (state: AuthSliceModel) => {
      return {
        ...state,
        loading: false,
        csvData: []
      }
    },
    kycConfigsFetchSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        kycConfigs: action.payload
      }
    },
    kycConfigsFetchFail: (state: AuthSliceModel) => {
      return {
        ...state,
        loading: false,
        kycConfigs: null
      }
    },
    kycConfigsPostSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false
      }
    },
    kycConfigsPostFail: (state: AuthSliceModel) => {
      return {
        ...state,
        loading: false
      }
    },
    kycSubscriptionPostSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        subscriptionResponse: action.payload
      }
    },
    kycSubscriptionPostFail: (state: AuthSliceModel) => {
      return {
        ...state,
        loading: false,
        subscriptionResponse: null
      }
    },
    fetchKycRedisSuccess: (state: AuthSliceModel, action: PayloadAction<RedisStatusModel>) => {
      return {
        ...state,
        redisStatus: action.payload,
        redisStatusSuccess: true,
        redisStatusFailed: false
      }
    },
    fetchKycRedisFailed: (state: AuthSliceModel, action: PayloadAction<RedisStatusModel>) => {
      return {
        ...state,
        redisStatus: action.payload,
        redisStatusSuccess: false,
        redisStatusFailed: true
      }
    }
  },
});

export default kycStore;