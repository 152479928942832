import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    formSubmitting: false,
    commonFormSubmittingLoading: false,
    configurations: [],
    widgetLoading: false,
    dynamicWidget: null,
    manualChargeResponse: null,
    widgetConfigListLoading: false,
    widgetConfigList: null,
    listDataForMPSelect: null,
    listDataForMPAudioUpload: null,
    workflowLoading: false,
    workflowResponse: null,
    duplicateName: false,
    retryChargeResponse: null,
    widgetPackageList: [],
    widgetPackageListLoading: false,
    dynamicBillingHistoryLoading: false,
    dynamicBillingHistory: null,
    dynamicSubDataLoading: false,
    dynamicSubData: null
};

const DynamicFormSlice = createSlice({
    name: 'DynamicFormSlice',
    initialState: initialState,
    reducers: {
        resetDynamicRedux: (state) => initialState,
        save: (state) => {
            return {
                ...state,
                formSubmitting: true,
            }
        },
        submitting: (state, {payload}) => {
            localStorage.setItem("configurationWidgets", JSON.stringify([...state.configurations, payload]));
            return {
                ...state,
                formSubmitting: false,
                configurations: [...state.configurations, payload]
            }
        },
        commonFormSubmittingLoading: (state) => {
            return {
                ...state,
                commonFormSubmittingLoading: true,
            }
        },
        commonFormSubmitting: (state) => {
            return {
                ...state,
                commonFormSubmittingLoading: false
            }
        },
        widgetLoading: (state) => {
            return {
                ...state,
                widgetLoading: true,
            }
        },
        fetchWidget: (state, {payload}) => {
            return {
                ...state,
                widgetLoading: false,
                dynamicWidget: payload
            }
        },

        manualCharge: (state, {payload}) => {
            return {
                ...state,
                widgetLoading: false,
                manualChargeResponse: payload,
                widgetConfigListLoading: false,
            }
        },

        loadingStop: (state) => {
            return {
                ...state,
                widgetLoading: false,
                widgetConfigListLoading: false,
            }
        },
        widgetConfigListLoading: (state) => {
            return {
                ...state,
                widgetConfigListLoading: true,
            }
        },
        widgetConfigList: (state, {payload}) => {
            return {
                ...state,
                widgetConfigListLoading: false,
                widgetConfigList: payload
            }
        },
        listDataForMPSelect: (state, {payload}) => {
            return {
                ...state,
                widgetConfigListLoading: false,
                listDataForMPSelect: payload
            }
        },
        listDataForMPAudioUpload: (state, {payload}) => {
            return {
                ...state,
                widgetConfigListLoading: false,
                listDataForMPAudioUpload: payload
            }
        },
        workflowLoading: (state) => {
            return {
                ...state,
                workflowLoading: true
            }
        },
        workflow: (state, {payload}) => {
            return {
                ...state,
                workflowLoading: false,
                workflowResponse: payload
            }
        },
        workflowLoadingStop: (state) => {
            return {
                ...state,
                workflowLoading: false
            }
        },
        duplicateName: (state, {payload}) => {
            console.log(payload)
            return {
                ...state,
                duplicateName: true,
            }
        },
        retryCharge: (state, {payload}) => {
            return {
                ...state,
                widgetLoading: false,
                retryChargeResponse: payload,
                widgetConfigListLoading: false,
            }
        },
        widgetPackageListFetch : (state) => {
            return {
                ...state,
                widgetPackageListLoading: true
            }
        },
        widgetPackageListFetchSuccess : (state, {payload}) => {
            return {
                ...state,
                widgetPackageList: payload,
                widgetPackageListLoading: false
            }
        },
        widgetPackageListFetchFailed : (state) => {
            return {
                ...state,
                widgetPackageListLoading: false
            }
        },
        dynamicWidgetBillingHistoryLoading: (state) => {
            return {
                ...state,
                dynamicBillingHistoryLoading: true,
            }
        },
        dynamicWidgetBillingHistory: (state, {payload}) => {
            return {
                ...state,
                dynamicBillingHistoryLoading: false,
                dynamicBillingHistory: payload
            }
        },
        dynamicWidgetBillingHistoryFailed: (state) => {
            return {
                ...state,
                dynamicBillingHistoryLoading: false,
            }
        },
        dynamicSubscriptionDataLoading: (state) => {
            return {
                ...state,
                dynamicSubDataLoading: true,
            }
        },
        dynamicSubscriptionData: (state, {payload}) => {
            return {
                ...state,
                dynamicSubDataLoading: false,
                dynamicSubData: payload
            }
        },
        dynamicSubscriptionDataFailed: (state) => {
            return {
                ...state,
                dynamicSubDataLoading: false,
            }
        }
    }
});


export default DynamicFormSlice;
