/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { CSSProperties, useEffect} from "react";
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import { fetchBizEmailConfigData } from '../../../store/actions';
import AuthHandler from '../../../helper/AuthHandler';
import { PageHeaderStatic } from "../components/PageHeaderStatic";
import { Tooltip } from "antd";
import { EyeOutlined } from "@ant-design/icons";

const override: CSSProperties = {
    display: "block",
    margin: "0 auto"
};

const BizEmailConfigList = ({ auth }: any) => {

    const { wid } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { userDetails } = useSelector(({ user }: any) => user);
    const { success, loading, error, bizEmailConfigList } = useSelector(({ bizEmail }: any) => bizEmail);

    useEffect(() => {
        getBizEmailConfigsList();
    }, []);

    const getBizEmailConfigsList = () => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        dispatch(fetchBizEmailConfigData(tenantGUID));
    }

    useEffect(() => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        dispatch(fetchBizEmailConfigData(tenantGUID));
    }, [wid, userDetails]);


    const onViewConfig = (configId: string) => {
        navigate(`/biz-email/${wid}/view-config/${configId}`);
    }

    const renderConfigList = () => {

        if (success && bizEmailConfigList) {
            if (bizEmailConfigList.length > 0) {
                return (
                    <div className="section__wrapper">
                        <div className="card panel content-holder-panels">
                            <div className="panel-header">
                                <div className="panel-header-content__wrapper">
                                    <h4 className="panel-header-title">List of Configs</h4>
                                </div>
                            </div>
                            <div className="panel-table-listing__wrapper">
                                <div className="table-parent__wrapper">
                                    <table className="table table-default">
                                        <tbody>
                                            <tr className="table-header">
                                                <th className="txt-left">#</th>
                                                <th className="txt-left">Email Address</th>
                                                <th className="txt-left">Recovery Email</th>
                                                <th className="txt-left">Mobile Number</th>
                                                <th className="txt-left"></th>
                                            </tr>
                                            {bizEmailConfigList.map((value: any, index: number) => {
                                                return (
                                                    <tr className="table-row ">
                                                        <td className="txt-left">{index + 1}</td>
                                                        <td className="txt-left">{value.email}</td>
                                                        <td className="txt-left">{value.recoveryEmail}</td>
                                                        <td className="txt-left">{value.msisdn}</td>
                                                        <td className="txt-left" style={{ textAlign: 'center' }}>
                                                            <Tooltip title="View">
                                                                <EyeOutlined onClick={() => onViewConfig(value.uuid)} />
                                                            </Tooltip>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="section__wrapper">
                        <div className="empty-state-new-action__wrapper">
                            <div className="new-action-content__wrapper">
                                <h4 className="sub">No Records available</h4>
                                <h4 className="title">Create new request</h4>
                                <button className="btn btn-primary" onClick={() => navigate(`/biz-email/${wid}/new-biz-email`)}>New request</button>
                            </div>
                        </div>
                    </div>
                )
            }
        } else if (loading) {
            return (
                <div className="section__wrapper">
                    <div className="empty-state-new-action__wrapper">
                        <section className="content__wrapper">
                            <div className="primaryBrandLoaderBg">
                                <div className="primaryBrandLoader">
                                    <img src={require('../../../assets/images/loader-bg-primary.svg').default} alt="" srcSet="" />
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            )
        } else if (error) {
            return (
                <div className="section__wrapper">
                    <div className="empty-state-new-action__wrapper">
                        <div className="new-action-content__wrapper">
                            <h4 className="sub">Oops, something went wrong!</h4>
                        </div>
                    </div>
                </div>
            )
        }
    }

    if (AuthHandler.isAuthorized(auth, 'biz-email-config-list') && userDetails !== null) {
        let path = window.location.pathname.split('/');
        return (
            <React.Fragment>
                <div className="main-content main-spacer-x-y">
                    <PageHeaderStatic
                        listShow={Array.isArray(bizEmailConfigList) && bizEmailConfigList.length > 0}
                        page={'list'}
                        newButtonLink={path[0] + '/' + path[1] + '/' + path[2] + '/new-biz-email'}
                    />
                    {renderConfigList()}
                </div>

            </React.Fragment>
        );
    } else return (<React.Fragment></React.Fragment>);
}

export default BizEmailConfigList;