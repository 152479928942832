import axiosInstance from "../axios-instance";
import * as CONSTANTS from "../constants";

const getAllOffers = (userUuid: string, tenantGroupId: string) => {
    console.log("tenantGroupId", tenantGroupId)
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/ent/offers/all-offers/${userUuid}/${tenantGroupId}`
    });
};

const claimOffers = (couponUuid: string) => {
    return axiosInstance({
        method: "post",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/ent/claim-offer/${couponUuid}`
    });
}; //when clicking the 'claim offer'button

export {
    getAllOffers,
    claimOffers
}