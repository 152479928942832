import React from 'react'
import { ProgressDescription } from './ProgressDescription'
import { ProgressStatus } from './ProgressStatus'

type Props = {
    status: string,
    progress: number
}

const ProgressDescriptionBox = ({ status, progress }: Props) => {

    

    return (
        <div className="wiz-progress-details__wrapper d-flex flex-space-between flex-dir-row" id="top-section">
            {
                status === "processing" ?
                    <ProgressDescription
                        title="Processing your data…" subTitle="We are processing your data. This might take few seconds. Please do not close the window!" /> :
                    status === "PENDING_APPROVAL" ?
                        <ProgressDescription
                            title="Good job! Your account has been submitted successfully! Please wait for the account approval."
                            subTitle="Your account is currently in 'Pending Approval' status. Approval may take up to 12 hours. Thank you for your patience." /> :
                        status === "APPROVED" ?
                            <ProgressDescription
                                title="Good news! Your profile is fully approved!" subTitle="Head over to Discover to dive 
                              in to the sea of marketplace solutions." /> :
                            status === "REJECTED" ?
                                <ProgressDescription
                                    title="You account creation request has been rejected!" subTitle="Please revise the enterprise data and resubmit the request." /> :
                                <ProgressDescription
                                    title="Create Your Organization" subTitle="Complete the following four steps to experience the full potential of Marketplace" />
            }
            <ProgressStatus status={status} progress={progress} />
        </div>
    )
}

export { ProgressDescriptionBox }