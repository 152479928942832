/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Auth from '../../../models/response/auth.model';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { getExistingUserDetails, submitNewEsmsUser } from '../../../store/actions/esms.action';
import {fetchMsisdnList, fetchSingleApp} from '../../../store/actions';
import { InputTextField, InputPasswordField } from '../../../components';
import { InputTextFieldForEsms } from '../../../components/InputTextFieldForEsms';
import SimpleReactValidator from 'simple-react-validator';
import { useAppDispatch } from '../../../store';
import { useParams } from 'react-router-dom';
import { ESMS_APP_ID } from '../../../constants';
import { PageHeaderDynamicFormComponent } from '../components/PageHeaderDynamicForm.component';

type Props = {
    auth: Auth
}

const EsmsSignupForm = ({auth}: any) => {
    const { wid } = useParams();
    const {
        singleAppDetails
    } = useSelector(({ apps }: any) => apps);

    const dispatch = useAppDispatch();
    const [tenantData, setTenantData] = useState({ tenantGroupId: "", userUuid: "" });
    const [isReadAgreement, setIsReadAgreement] = useState(true);
    const [mobileVerified, setMobileVerified] = useState<string | undefined>();
    const [numberList, setNumberList] = useState<any[]>([]);
    const [existingUserDetailsData, setexistingUserDetailsData] = useState({
        appId: "",
        maId: "",
        username: "",
        fname: "",
        lname: "",
        address: "",
        email: "",
        mobile: "",
        mobileList: [],
        nicOrBrc: "",
        image: "",
        accountIsUseFor: "",
        defaultMask: "",
        marketingAgent: "",
        marketingAgentList: "",
        password: "",
        confirm_password: "",
        agreeToTerms: false,
        accountUsedFor: ""
    });

    const [usernameUnique, setUsernameUnique] = useState<boolean | undefined>();
    const [emailUnique, setEmailUnique] = useState<boolean | undefined>();
    const [agentsList, setAgentsList] = useState<any[]>([])
    const [documentsList, setDocumentsList] = useState<any[]>([])

    const [validator] = useState(new SimpleReactValidator({
        messages: {
            regex: 'The :attribute field cannot be null.',
            min: ':attribute is too short',
            max: ':attribute is too lengthy',
        },
        validators: {
            specialCharacter: {
                message: 'Invalid character',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^[a-zA-Z0-9]+$/) && params.indexOf(val) === -1
                }
            },
            specialCharacterForAddress: {
                message: 'Invalid character',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^([a-zA-Z0-9\-/()`,_\.:\s]*$)/) && params.indexOf(val) === -1
                }
            },
            specialCharacterForMask: {
                message: 'Invalid character',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^([^+=-]*$)/) && params.indexOf(val) === -1
                }
            },
            spaceValidate: {
                message: 'Spaces are not allowed',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^[^\s]{1,}$/) && params.indexOf(val) === -1
                }
            },
            lowerCase: {
                message: 'Password must include lowercase value',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^(?=.*[a-z])/) && params.indexOf(val) === -1
                }
            },
            upperCase: {
                message: 'Password must include uppercase value',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^(?=.*[A-Z])/) && params.indexOf(val) === -1
                }
            },
            numberValue: {
                message: 'Must include at least one special character',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^(?=.*[!@#$%^&*._-])/) && params.indexOf(val) === -1
                }
            },
            dialog: {
                message: 'The :attribute is invalid.',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^([0]{1}[7]{1}[764]{1}\d{7}|[7]{1}[764]{1}\d{7})$/) && params.indexOf(val) === -1
                }
            },
        },
        // autoForceUpdate: this
    }))

    const { userDetails } = useSelector<any, any>(({ user }) => user);
    const { existingUserDetails } = useSelector<any, any>(({ esms }) => esms);

    const useForceUpdate = () => {
        const [value, setValue] = useState(0);
        return () => setValue(value => value + 1);
    }

    const forceUpdate = useForceUpdate()

    useEffect(() => {
        setIsReadAgreement(false);
    }, [])

    useEffect(() => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        dispatch(fetchSingleApp(wid!, tenantGUID));
    }, [wid, userDetails]);

    useEffect(() => {
        const groupId = (userDetails && userDetails.OwnedEnterprises[0]) ? JSON.stringify(userDetails.OwnedEnterprises[0].groupId) : "";
        setTenantData({
            ...tenantData,
            tenantGroupId: groupId
        });
    }, [userDetails]);

    useEffect(() => {
        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        if (tenantGroupId !== "") {
            dispatch<any>(getExistingUserDetails(tenantGroupId));
            dispatch<any>(fetchMsisdnList(tenantGroupId, existingUserDetailsData.mobile));
        }
    }, [userDetails]);

    const setExistingUserDetails = (details: any) => {
        setexistingUserDetailsData({
            ...existingUserDetailsData,
            appId: details ? details.appId : "",
            maId: details ? details.maId : "",
            username: details ? details.username : "",
            fname: details ? details.fname : "",
            lname: details ? details.lname : "",
            address: details ? details.address : "",
            email: details ? details.email : "",
            mobile: details ? details.mobile : "",
            mobileList: details ? details.mobileList : [],
            nicOrBrc: details ? details.nicOrBrc : "",
            image: details ? details.image : "",
            defaultMask: details ? details.defaultMask : "",
            marketingAgent: details ? details.marketingAgent : "",
            marketingAgentList: details ? details.marketingAgentList : [],
            password: details ? details.password : "",
            confirm_password: details ? details.confirm_password : "",
            agreeToTerms: details ? details.agreeToTerms : "",
            accountUsedFor: details ? details.accountUsedFor : ""
        });
    };

    useEffect(() => {
        setExistingUserDetails(existingUserDetails);

        if (existingUserDetails) {
            setAgentsList(existingUserDetails.marketingAgentList.MarketingAdmins);
        }

        if (existingUserDetails) {
            setDocumentsList(existingUserDetails.image)
        }

        if (existingUserDetails) {
            setNumberList(existingUserDetails.mobileList)
        }
    }, [existingUserDetails]);

    const handleChange = (name: string) => (event: any) => {
        setexistingUserDetailsData({ ...existingUserDetailsData, [name]: event.target.value });
        if (name === "username") {
            setUsernameUnique(undefined);
        }
        if (name === "email") {
            setEmailUnique(event.target.value);
        }

        validator.showMessages();
        forceUpdate();
    };

    const [passwordValidity, setPasswordValidity] = useState({
        isValid: true,
        message: ''
    });

    const onSubmitRegister = () => {
        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        let req = {
            "appId": ESMS_APP_ID,
            "maId": existingUserDetailsData.maId,
            "username": existingUserDetailsData.username,
            "fname": existingUserDetailsData.fname,
            "lname": existingUserDetailsData.lname,
            "address": existingUserDetailsData.address,
            "email": existingUserDetailsData.email,
            "mobile": existingUserDetailsData.mobile,
            "msisdnStatus": "",
            "nicOrBrc": existingUserDetailsData.nicOrBrc,
            "image": existingUserDetailsData.image,
            "defaultMask": existingUserDetailsData.defaultMask,
            "marketingAgent": existingUserDetailsData.marketingAgent,
            "marketingAgentList": [],
            "accountUsedFor": existingUserDetailsData.accountIsUseFor === 'Transaction' ? 'Transaction' : 'Promotions',
            "password": existingUserDetailsData.password,
            "agreeToTerms": isReadAgreement === true ? true : false
        }

        if (validator.allValid() && validator.allValid() && usernameUnique === true && emailUnique === true && mobileVerified === "verified") {
            if (existingUserDetailsData.password === existingUserDetailsData.confirm_password) {
                setPasswordValidity({
                    ...passwordValidity,
                    isValid: true
                })
            } else {
                setPasswordValidity({
                    isValid: false,
                    message: 'Passwords must be same.'
                });
            }
        } else {
            validator.showMessages();
            forceUpdate();
        }

        dispatch<any>(submitNewEsmsUser(tenantGroupId, wid!, req));
    }

    useEffect(() => {
        if (existingUserDetailsData.password && existingUserDetailsData.confirm_password && existingUserDetailsData.password !== existingUserDetailsData.confirm_password) {
            setPasswordValidity({
                isValid: false,
                message: 'Passwords must be same.'
            });
        } else {
            setPasswordValidity({
                isValid: true,
                message: ''
            });
        }
    }, [existingUserDetailsData.password, existingUserDetailsData.confirm_password]);

    const renderAgentsList = () => {
        if(Array.isArray(agentsList)){
            return (
                agentsList.map((agent, index) => {
                    return (
                        <option
                            value={agent.uuid}
                        >
                            {agent.firstName} {agent.lastName}
                        </option>
                    )
                })
            )
        }
    }

    const renderMsisdnList = () => {
        if(Array.isArray(numberList)){
            return (
                numberList.map((number, index) => {
                    return (
                        <option
                            value={number.msisdn}
                        >
                            {number.msisdn}
                        </option>
                    )
                })
            )
        }
    }

    const onPressLogin = () => {
        window.open('https://esms.dialog.lk/login', "_blank") || window.location.replace('https://esms.dialog.lk/login');
    }

    return (
        <React.Fragment>
            <div>
                <div className="main-content main-spacer-x-y">
                    <PageHeaderDynamicFormComponent
                        page={'list'}
                    />
                    <div className="card added-app-thumbnnail__wrapper">
                        <div className="added-app-thumbnail__inner" 
                        style={{
                            backgroundImage: `url(${(singleAppDetails && singleAppDetails.widgetDetails !== null) ? singleAppDetails.widgetDetails.thumbnail : ""})`
                        }}>
                        </div>
                    </div>
                    <div className="card panel content-holder-panels added-app-form__wrapper">
                        <div className="panel-header">
                            <div className="panel-header-content__wrapper">
                                <h4 className="panel-header-title">Sign In for eSMS</h4>
                            </div>
                        </div>
                        <div className="panel-content">
                            <div className="wiz-content-form__wrapper">
                                <img src="" alt="" />
                                {/* <p>eSMS is a revolutionary solution by Dialog Axiata PLC  which will enable you to send and SMS to multiple parties in an easy to use interface at a  very nominal cost without any initial setup costs in real life</p> */}
                                {/* <br> */}
                                <button className="btn btn-primary btn-fl-wd" onClick={onPressLogin}>Sign In</button>
                                <div className="sign-in-advice"><span>Please note that even if you've signed up for the Marketplace, a separate registration is required to create your eSMS account</span></div>
                                <br />
                                <div className="form-group__wrapper">
                                    <div className="form-group">
                                        <div className="form-element__wrapper mb-3">
                                            <InputTextField
                                                name='username'
                                                label='Username'
                                                placeholder='Username'
                                                value={existingUserDetailsData.username}
                                                setValue={handleChange}
                                                validator={validator}
                                                rules={['required', 'specialCharacter', { max: 20 }, { min: 3 }]}
                                                disabled={false}
                                                status="default"
                                                validationMessage=""
                                                forceUpdate={forceUpdate}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group__wrapper">
                                    <div className="form-group">
                                        <div className="form-element__wrapper mb-3">
                                            <InputTextField
                                                name='fname'
                                                label='First name'
                                                placeholder='Firstname'
                                                value={existingUserDetailsData.fname}
                                                setValue={handleChange}
                                                validator={validator}
                                                rules={['required', 'specialCharacterForAddress', { max: 25 }, { min: 3 }]}
                                                disabled={false}
                                                status="default"
                                                validationMessage=""
                                                forceUpdate={forceUpdate}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-element__wrapper mb-3">
                                            <InputTextField
                                                name='lname'
                                                label='Last name'
                                                placeholder='Lastname'
                                                value={existingUserDetailsData.lname}
                                                setValue={handleChange}
                                                validator={validator}
                                                rules={['required', 'specialCharacterForAddress', { max: 20 }, { min: 3 }]}
                                                disabled={false}
                                                status="default"
                                                validationMessage=""
                                                forceUpdate={forceUpdate}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group__wrapper">
                                    <div className="form-group">
                                        <div className="form-element__wrapper mb-3">
                                            <InputTextField
                                                name='address'
                                                label='Address'
                                                placeholder='Address'
                                                value={existingUserDetailsData.address}
                                                setValue={handleChange}
                                                validator={validator}
                                                rules={['required', 'specialCharacterForAddress', { max: 100 }, { min: 10 }]}
                                                disabled={false}
                                                status="default"
                                                validationMessage=""
                                                forceUpdate={forceUpdate}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group__wrapper">
                                    <div className="form-group">
                                        <div className="form-element__wrapper mb-3">
                                            <InputTextField
                                                name='email'
                                                label='Email'
                                                placeholder='Email'
                                                value={existingUserDetailsData.email}
                                                setValue={handleChange}
                                                validator={validator}
                                                rules={['required']}
                                                disabled={false}
                                                status="default"
                                                validationMessage=""
                                                forceUpdate={forceUpdate}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-element__wrapper validated form-element__wbtn right mb-3">
                                            <div className="input__wrapper">
                                                <label htmlFor="" className="form-label">Contact</label>
                                                {existingUserDetails &&
                                                    <select
                                                        name="mobileList"
                                                        id="mobile"
                                                        className="form-control"
                                                        value={existingUserDetailsData.mobile}
                                                        onChange={(e) => handleChange('mobile')(e)}
                                                        defaultValue={existingUserDetailsData.mobile}
                                                    >
                                                        {renderMsisdnList()}
                                                    </select>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group__wrapper">
                                    <div className="form-group">
                                        <div className="form-element__wrapper mb-3">
                                            <label htmlFor="" className="form-label">This account is used for</label>
                                            <div className="inner-multiple-feature-list-element__wrapper horizontal">
                                                <div className="form-check mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        value="Transaction"
                                                        id="flexCheckDefault"
                                                        onChange={(e) => handleChange('accountIsUseFor')(e)}
                                                        name="accountIsUseFor"
                                                        checked={existingUserDetailsData.accountIsUseFor === "Transaction"}
                                                    />
                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                        Transaction
                                                    </label>
                                                </div>
                                                <div className="form-check mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        value="Promotions"
                                                        id="flexCheckDefault"
                                                        name='accountIsUseFor'
                                                        onChange={(e) => handleChange('accountIsUseFor')(e)}
                                                        checked={existingUserDetailsData.accountIsUseFor === "Promotions"}
                                                    />
                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                        Promotions
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group__wrapper">
                                    <div className="form-group">
                                        <div className="form-element__wrapper mb-3">
                                            <InputTextField
                                                name='nicOrBrc'
                                                label='NIC/BRC number'
                                                placeholder='NIC/BRC number'
                                                value={existingUserDetailsData.nicOrBrc}
                                                setValue={handleChange}
                                                validator={validator}
                                                rules={['required', 'specialCharacter', 'spaceValidate', { min: 5 }, { max: 45 }]}
                                                disabled={false}
                                                status="default"
                                                validationMessage=""
                                                forceUpdate={forceUpdate}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group__wrapper">
                                    <div className="form-group">
                                        <div className="form-element__wrapper mb-3">
                                            <InputTextFieldForEsms
                                                name='defaultMask'
                                                label='SMS Mask (Sender Name)'
                                                placeholder='Customize the sender name displayed in your campaign messages'
                                                value={existingUserDetailsData.defaultMask}
                                                setValue={handleChange}
                                                validator={validator}
                                                rules={['required', { max: 11 }, { min: 3 }, 'specialCharacterForMask']}
                                                disabled={false}
                                                status="default"
                                                validationMessage=""
                                                forceUpdate={forceUpdate}
                                                tooltipComponent={<div>
                                                    <p>Customize the sender name displayed in your campaign messages.</p>
                                                </div>}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-element__wrapper mb-3 has-dropdown">
                                            <label htmlFor="" className="form-label">Who introduced you to Marketplace?</label>
                                            {existingUserDetails &&
                                                <select
                                                    name=""
                                                    id="marketingAgent"
                                                    className="form-control"
                                                    value={existingUserDetailsData.marketingAgent}
                                                    onChange={(e) => handleChange('marketingAgent')(e)}
                                                    defaultValue={existingUserDetailsData.marketingAgent}
                                                >
                                                    {renderAgentsList()}
                                                </select>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group__wrapper">
                                    <div className="form-group">
                                        <div className="form-element__wrapper mb-3">
                                            <InputPasswordField
                                                name='password'
                                                label='Password'
                                                placeholder="Enter password"
                                                value={existingUserDetailsData.password}
                                                setValue={handleChange}
                                                validator={validator}
                                                rules={['required', 'spaceValidate', 'lowerCase', 'upperCase', 'numberValue', { min: 8 }, { max: 15 }]}
                                                disabled={false}
                                                status={"default"}
                                                validationMessage=""
                                                showEyeIcon={true}
                                                forceUpdate={forceUpdate} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-element__wrapper mb-3">
                                            <InputPasswordField
                                                name='confirm_password'
                                                label='Confirm password'
                                                placeholder='Retype the same password'
                                                value={existingUserDetailsData.confirm_password}
                                                setValue={handleChange}
                                                validator={validator}
                                                rules={['required']}
                                                disabled={false}
                                                status={passwordValidity.isValid ? "default" : "invalid"}
                                                validationMessage={passwordValidity.message ? passwordValidity.message : ""}
                                                showEyeIcon={true}
                                                forceUpdate={forceUpdate} />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-check mb-3">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue=""
                                        id="flexCheckDefault2"
                                        //checked={isReadAgreement} 
                                        onChange={() => setIsReadAgreement(!isReadAgreement)}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault2">
                                        I agree to
                                    </label>
                                    <a href="/agreement" target="_blank"> <u>Terms & Conditions</u> </a>
                                </div>
                                <div className="panel-bottom-btn__wrapper">
                                    <button className="btn btn-secondary">Cancel</button>
                                    <button className="btn btn-primary" onClick={onSubmitRegister} disabled={!isReadAgreement || 
                                        existingUserDetailsData.username === '' || existingUserDetailsData.fname === '' || 
                                        existingUserDetailsData.lname === '' || existingUserDetailsData.address === '' ||
                                        existingUserDetailsData.email === '' || existingUserDetailsData.mobile === '' ||
                                        existingUserDetailsData.accountIsUseFor === '' || existingUserDetailsData.nicOrBrc === '' ||
                                        existingUserDetailsData.password === '' || existingUserDetailsData.confirm_password === '' ||
                                        !validator.allValid()}>Register</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default EsmsSignupForm;