import axiosInstance from "../axios-instance";
import * as CONSTANTS from '../constants';
import {OrderReqModel} from "../pages/apps/fixedBB/models/orderReq.model";

const checkCoverage = async (lat: number, lng: number) => {
    return await axiosInstance({
        method: `get`,
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widgets/fixed-bb/check-coverage/${lat}/${lng}`
    })
}

const getPackageData = async () => {
    return await axiosInstance({
        method: `get`,
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widgets/fixed-bb/package-list/all`
    })
}

const getDeviceData = async (traceId: string) => {
    return await axiosInstance({
        method: `get`,
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widgets/fixed-bb/device-list/${traceId}`
    })
}

const postCartOrder = async (data: OrderReqModel, tenantUuid: string, widgetUuid: string, traceId: string) => {
    return await axiosInstance({
        method: `post`,
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/cart/create-order/${tenantUuid}/${widgetUuid}/${traceId}`,
        data: data
    })
}

const getAllOrders = async (tenantUuid: string, widgetUuid: string) => {
    return await axiosInstance({
        method: `get`,
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/cart/get-orders/${tenantUuid}/${widgetUuid}`
    })
}

const getSingleOrder = async (tenantUuid: string, widgetUuid: string, orderUuid: string) => {
    return await axiosInstance({
        method: `get`,
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/cart/get-order/${tenantUuid}/${widgetUuid}/${orderUuid}`
    })
}

const paymentSubmit = async (data: { orderUuid: string }, tenantUuid: string, widgetUuid: string) => {
    return await axiosInstance({
        method: `put`,
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/cart/submit-payment/${tenantUuid}/${widgetUuid}`,
        data: data
    })
}

const requestAdminApproval = async (data: { orderUuid: string }, tenantUuid: string, widgetUuid: string) => {
    return await axiosInstance({
        method: `put`,
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/cart/set-admin-approval/${tenantUuid}/${widgetUuid}`,
        data: data
    })
}

const getLocationData = async (lat: number, lng: number) => {
    let url = CONSTANTS.OPEN_STREET_REVERSE_GEO_URL;
    url = url.replace('[LATITUDE]', lat.toString());
    url = url.replace('[LONGITUDE]', lng.toString());
    return await axiosInstance({
        method: `get`,
        url: url
    })
}

export {
    checkCoverage,
    getPackageData,
    getDeviceData,
    postCartOrder,
    getAllOrders,
    getSingleOrder,
    paymentSubmit,
    requestAdminApproval,
    getLocationData
}