/* eslint-disable import/no-anonymous-default-export */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { existingUserDetailsModel } from "../../pages/home/models/request/existingUserDetails.model";
import { newEsmsUserDetailsModel } from "../../pages/home/models/request/newEsmsUserDetails.model";
import { MsisdnModel } from "../../pages/home/models/response/msisdn.model";

interface AuthSliceModel {
    existingUserDetails: existingUserDetailsModel | null
    newEsmsUserDetails: newEsmsUserDetailsModel | null,
    signUpResponseSuccess: any,
    signUpResponseFail: any,
    checkMsisdnValidated : any,
    msisdnList: Array<any>;

}

const esmsStore = createSlice({
    name: "esms-store",
    initialState: {
        existingUserDetails: null,
        newEsmsUserDetails: null,
        signUpResponseSuccess: null,
        signUpResponseFail: null,
        checkMsisdnValidated: null,
        msisdnList: [],

    } as AuthSliceModel,
    reducers: {
        getExistingUserDetails: (state: AuthSliceModel, action: PayloadAction<existingUserDetailsModel>) => {
          return {
            ...state,
            existingUserDetails: action.payload
          }
        },
        postNewEsmsUserDetails: (state: AuthSliceModel, action: PayloadAction<newEsmsUserDetailsModel>) => {
          return {
            ...state,
            newEsmsUserDetails: action.payload
          }
        },
        getSignUpResponseSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
          return {
            ...state,
            signUpResponseSuccess: action.payload
          }
        },
        getSignUpResponseFail: (state: AuthSliceModel, action: PayloadAction<any>) => {
          return {
            ...state,
            signUpResponseFail: (action.payload !== null && typeof action.payload.message !== 'undefined') ? action.payload.message : "" 
          }
        },
        getMsisdnListSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
          return {
            ...state,
            msisdnList: action.payload
          }
        },
    }  
});

export default esmsStore;
