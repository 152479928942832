/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { CSSProperties, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import {fetchTracklist, fetchSingleApp, fetchTrackHistory, fetchManageCampaignConfigs} from '../../../store/actions';
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from '@emotion/react';
import AuthHandler from '../../../helper/AuthHandler';
import TableIcon from '../../../assets/images/icons/app-media-placeholder.svg';
import { PageHeaderStatic } from "../components/PageHeaderStatic";
import moment from "moment";
import NoData from "../../settings/components/NoData";

const override: CSSProperties = {
    display: "block",
    margin: "0 auto"
};

const ManagedCampaignConfigList = ({ auth }: any) => {
    const { wid, configId } = useParams();
    const [logList, setLogList] = useState<any[]>([]);
    const [limit, setLimit] = useState<number>(10);
    const [offset, setOffset] = useState<number>(0);
    const [address, setAddress] = useState<string>('');
    const [lat, setLat] = useState<string>('');
    const [lng, setLng] = useState<string>('');
    const [radius, setRadius] = useState<number>(0);

    const navigate = useNavigate();

    const {success, loading, error,mcConfigList} = useSelector(({ mc }: any) => mc);
    // const { success, loading, error, trackHistory } = useSelector((state: any) => state.location);
    const { userDetails } = useSelector(({ user }: any) => user);
    const [initialDataLoaded, setInitialDataLoaded] = useState(false);

    const dispatch = useAppDispatch();
    const [type, setType] = useState('password')

    useEffect(() => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        if(tenantGUID){
            dispatch(fetchSingleApp(wid!, tenantGUID));
        }
    }, [wid, userDetails]);

    useEffect(() => {
       // console.log('------------------>',mcConfigList);
        // setLimit(limit);
    }, [mcConfigList]);

    useEffect(() => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        if(tenantGUID){
            dispatch(fetchManageCampaignConfigs(tenantGUID));
        }
    }, [wid, userDetails]);

    const onViewMore = () => {
        setOffset(offset + 10);
    }

    const renderConfigList = () => {

        // console.log('cccccccccccccccccc',AuthHandler.isAuthorized(auth, 'manage-campaign-config-list'));
        // console.log('-----------------------------------------------------------',mcConfigList);
        // console.log('successsuccesssuccesssuccess',success);

        if (success && mcConfigList ) {

            if ( mcConfigList.length > 0) {
                return (
                    <div className="section__wrapper">
                        <div className="card panel content-holder-panels">
                            <div className="panel-header">
                                <div className="panel-header-content__wrapper">
                                    <h4 className="panel-header-title">List of Configs</h4>
                                    {/* <p className="panel-header-subtitle">List of </p> */}
                                </div>
                            </div>
                            <div className="panel-table-listing__wrapper">
                                <div className="table-parent__wrapper">
                                    <table className="table table-default">
                                        <tbody>
                                        <tr className="table-header">
                                            <th className="txt-left">Campaign</th>
                                            <th className="txt-left">Campaign Title</th>
                                            <th className="txt-left">Owner MSISDN</th>
                                            <th className="txt-left">Mask</th>
                                            <th className="txt-left">Campaign Start Time</th>
                                            <th className="txt-left">Campaign End Time</th>
                                            <th className="txt-left">View</th>
                                            <th className="txt-left">Status</th>
                                            <th className="txt-left">Status Workflow</th>
                                        </tr>
                                        {mcConfigList.map((value: any, index: number) => {
                                            return (
                                                <tr className="table-row ">
                                                    <td>
                                                        <div className="feature-details__wrapper">
                                                            <div
                                                                className="card app-media-holder"
                                                                style={{
                                                                    backgroundImage: `url(${TableIcon})`
                                                                }}
                                                            ></div>
                                                            <div className="feature-details-title__wrapper">
                                                                <span className="title">{value.uuid}</span>
                                                                {/* <span className="small-desc">Tracking time {value.trackTime}</span> */}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="txt-left">{value.title}</td>
                                                    <td className="txt-left">{value.msisdn}</td>
                                                    <td className="txt-left">{decodeURIComponent(value.mask)}</td>
                                                    <td className="txt-left">{value.startTime}</td>
                                                    <td className="txt-left">{value.endTime}</td>
                                                    <td className="txt-left">
                                                        <Link to={`/manage-campaign/${wid}/view-manage-campaign/${value.uuid}`}>
                                                            View <i className="bi bi-pen-fill" />
                                                        </Link>
                                                    </td>
                                                    <td className="txt-left"> {(function () {
                                                        if (value.status == 'Pending') {
                                                            return <span>Campaign Verification Pending</span>
                                                        } else if (value.status == 'CHARGING_PENDING') {
                                                            return <span>Charging Pending</span>
                                                        } else if (value.status == 'Resubmitted') {
                                                            return <span>Campaign verification resubmitted</span>
                                                        } else if (value.status == 'TEST_CAMPAIGN_PENDING') {
                                                            return <span>Test Campaign Pending</span>
                                                        } else if (value.status == 'TEST_CAMPAIGN_FAILED') {
                                                            return <span>Test Campaign Rejected</span>
                                                        } else if (value.status == 'REJECTED') {
                                                            return <span>Campaign Verification Rejected</span>
                                                        } else if (value.status == 'CAMPAIGN_PENDING') {
                                                            return <span>Campaign Pending</span>
                                                        } else if (value.status == 'CHARGING_FAILED') {
                                                            return <span>Charging Failed</span>
                                                        } else if (value.status == 'CHARGING_SUCCESS') {
                                                            return <span>Charging Success</span>
                                                        } else if (value.status == 'CAMPAIGN_PASSED') {
                                                            return <span>Campaign Passed</span>
                                                        } else if (value.status == 'CAMPAIGN_FAILED') {
                                                            return <span>Campaign Failed</span>
                                                        } else if (value.status == 'LEAD_ONLY') {
                                                            return <span>Lead Only</span>
                                                        } else if (value.status == 'FAILED') {
                                                            return <span>Test Campaign Failed</span>
                                                        } else {
                                                            return <span>Neither</span>
                                                        }
                                                    })()}
                                                    </td>


                                                    {/*<td>{value.reachCount < 50000 ?*/}
                                                    {/*    <span>*/}
                                                    {/*    <button type='button' className="btn btn-default light-shade btn-xsmall" onClick={onClickWorkFlow}>*/}
                                                    {/*        <i className={ "bi bi-eye-fill" } />*/}
                                                    {/*    </button>*/}
                                                    {/*</span> : "Lead Only"}*/}
                                                    {/*</td>*/}

                                                    {/* <td className="txt-left">{value.reachCount < 50000 ?
                                                        <Link to={`/manage-campaign/${wid}/manage-campaign-workflow/${value.uuid}`}>
                                                           Workflow<i className="bi bi-eye-fill"></i>
                                                        </Link>: "Lead Only"
                                                    }
                                                    </td> */}
                                                    <td className="txt-left">
                                                        <Link to={`/manage-campaign/${wid}/manage-campaign-workflow/${value.uuid}`}>
                                                           Workflow<i className="bi bi-eye-fill"></i>
                                                        </Link>
                                                    </td>


                                                    {/*<td className="txt-left">*/}
                                                    {/*    <Link to={`/location/${wid}/logs/${value.uuid}`}>*/}
                                                    {/*        View Status Workflow<i className="bi bi-eye-fill"></i>*/}
                                                    {/*    </Link>*/}
                                                    {/*</td>*/}
                                                </tr>
                                            )
                                        })}
                                        {/*<tr className="table-row">*/}
                                        {/*    <td>*/}
                                        {/*        <a onClick={onViewMore}>View All</a>*/}
                                        {/*    </td>*/}
                                        {/*</tr>*/}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="section__wrapper">
                        <div className="empty-state-new-action__wrapper">
                            <div className="new-action-content__wrapper">
                                <h4 className="sub">No Records available</h4>
                                <h4 className="title">Create new request</h4>
                                <button className="btn btn-primary" onClick={() => navigate(`/manage-campaign/${wid}/manage-campaign-create`)}>New request</button>
                            </div>
                        </div>
                    </div>
                )
            }
        } else if (loading) {
            return (
                <div className="section__wrapper">
                    <div className="empty-state-new-action__wrapper">
                        <section className="content__wrapper">
                            <div className="primaryBrandLoaderBg">
                                <div className="primaryBrandLoader">
                                    <img src={require('../../../assets/images/loader-bg-primary.svg').default} alt="" srcSet="" />
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            )
        } else if (error) {
            return (
                <div className="section__wrapper">
                    <div className="empty-state-new-action__wrapper">
                        <div className="new-action-content__wrapper">
                            <h4 className="sub">Oops, something went wrong!</h4>
                        </div>
                    </div>
                </div>
            )
        }
    }

    if (AuthHandler.isAuthorized(auth, 'manage-campaign-config-list') && userDetails !== null) {
        let path = window.location.pathname.split('/');
        return (
            <React.Fragment>
                <div className="main-content main-spacer-x-y">
                    <PageHeaderStatic
                        listShow={Array.isArray(mcConfigList) && mcConfigList.length > 0}
                        page={'list'}
                        newButtonLink={path[0]+'/'+path[1]+'/'+path[2]+'/manage-campaign-create'}
                    />
                    {renderConfigList()}
                </div>

            </React.Fragment>
        );
    } else return (<React.Fragment></React.Fragment>);
}

export default ManagedCampaignConfigList;