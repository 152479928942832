const CategoryCard = (props: any) => {
    return (
        <div className="app-listing-item__wrapper">
            {/* item */}
            <a aria-label="Click to see category details">
                <div className="app-listing-item">
                    <div className="app-item-content__wrapper app-details">
                        <div className="card app-thumbnail-holder"
                             style={{backgroundImage: 'url(/src/assets/images/icons/app-thumbail-placeholder.webp)'}}>
                            <div className={`app-media-overlay__wrapper ${props.category.color}`}>
                                <div className="app-media-info-holder">
                                                    <span className="app-media-title"
                                                          aria-label="App name">{props.category.title}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
            {/* /item */}
        </div>
    );
};

export default CategoryCard;