/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import appMediaIcon from "../../../../src/assets/images/icons/app-media-placeholder.svg";
import { fetchAppList, unsubcribeApps } from "../../../store/actions/settings.actions"
import { useAppDispatch } from '../../../store';
interface Prop {
    uuid: string;
    appTitle: String;
    description: String;
    needContactSupport: boolean;
    tenantGroupId: string;
    onPressUnsubscribe: () => void;
    isUnsubscribable: boolean;
    icon: string;
}

function AppItem({ uuid, appTitle, description, needContactSupport, tenantGroupId, onPressUnsubscribe, isUnsubscribable, icon }: Prop) {

    const dispatch = useAppDispatch();

    const onPressAppSupport = () => {
        console.log("clicked app support");
    }

    // const onPressUnsubscribe = () => {
    //     dispatch<any>(unsubcribeApps(tenantGroupId, uuid));
    //     dispatch<any>(fetchAppList(tenantGroupId, '0'));
    // }

    const onPressContactSupport = () => {
        console.log("clicked contact support");
    }

    return <React.Fragment>
        <div className="panel-table-list-item">
            <div className="panel-table-listing-item__inner">
                <div className="feature-details__wrapper">
                    <div className="card app-media-holder" style={{ backgroundImage: `url(${icon})` }}>
                    </div>
                    <div className="feature-details-title__wrapper">
                        <span className="title">{appTitle}</span>
                        <span className="small-desc">{description}</span>
                    </div>
                </div>
            </div>
            <div className="panel-table-listing-item__inner">
                <div className="panel-table-right-align-item__wrapper">
                    {/* <div className="panel-table-right-align-item">
                        {needContactSupport ? <a href="" className="" type="button" onClick={onPressContactSupport}> Contact support to unsub</a> : <a href="" className="" type="button" onClick={onPressAppSupport}> App Support</a>}
                    </div> */}
                    {!needContactSupport && <div className="panel-table-right-align-item">
                        {isUnsubscribable === true ? 
                            <a className="txt-red" type="Button" onClick={onPressUnsubscribe}><i className="bi bi-dash-circle" />&nbsp;Unsubscribe</a>
                            :
                            <a className="txt-red" type="Button" href="mailto:support.marketplace@dialog.lk"><i className="bi bi-dash-circle" />&nbsp;Contact 'Support' to unsubscribe</a>
                        }
                    </div>}
                </div>
            </div>
        </div>
    </React.Fragment>
}

export default AppItem;