import { message } from 'antd';
import {getWorkhubSubscriptionData, putWorkHub} from "../../services/workhub.service";
import workhubStore from "../reducers/workhub.store";

export const fetchWorkhubSubscriptionData = (tenantId: string, widgetId: string): any => {
    return (dispatch: any) => {
        dispatch(workhubStore.actions.workhubSubscriptionDataFetch());
        getWorkhubSubscriptionData(tenantId, widgetId)
            .then((response: any) => {
                dispatch(workhubStore.actions.workhubSubscriptionDataFetchSuccess(response.data));
            })
            .catch((error: any) => {
                message.error("Error occurred while fetching subscription data.");
                dispatch(workhubStore.actions.workhubSubscriptionDataFetchFail());
            });
    }
};

export const saveWorkhubData = (workhubData: any): any => {
    return (dispatch: any) => {
        dispatch(workhubStore.actions.workhubSubscriptionDataSave(workhubData));
    }
};

export const updateWorkhubSubscriptionData = (tenantId: string, widgetId: string, updateData: any): any => {
    return (dispatch: any) => {
        dispatch(workhubStore.actions.workhubSubscriptionDataUpdate());
        putWorkHub(tenantId, widgetId, updateData)
            .then((response: any) => {
                dispatch(workhubStore.actions.workhubSubscriptionDataUpdateSuccess(response.data));
            })
            .catch((error: any) => {
                message.error("Error occurred while updating workhub subscription data.");
                dispatch(workhubStore.actions.workhubSubscriptionDataUpdateFail());
            });
    }
};