import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

type Props = {
    widgetUuid: string,
    image: string,
    icon: string,
    title: string,
    description: string
}

const SingleAppWidget = ({
    widgetUuid,
    image,
    icon,
    title,
    description
}: Props) => {
    let navigate = useNavigate();
    return (
        <div className="app-listing-item__wrapper">
            {/* item */}
            <a aria-label="Click to see the app details" onClick={() => navigate(`/widget/${widgetUuid}`)}>
                <div className="app-listing-item">
                    <div className="app-item-content__wrapper app-details">
                        <div className="card app-thumbnail-holder" style={{ backgroundImage: `url(${image ? image : require('../../../assets/images/icons/app-thumbail-placeholder.webp')})` }}>
                            <div className="app-media-overlay__wrapper">
                                <div className="app-media-info-holder">
                                    <div className="card app-media-holder" style={{ backgroundImage: `url(${icon ? icon : require('../../../assets/images/icons/app-media-placeholder.svg').default})` }}>
                                    </div>
                                </div>
                                <div className="app-media-info-holder">
                                    <span className="app-media-title" aria-label="App name">{title}</span>
                                    <p className="app-media-desc" aria-label="App description">{description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
            {/* /item */}
        </div>
    )
}

export { SingleAppWidget }