import React, { FocusEvent, useEffect, useRef, useState } from 'react';
import { CollapsibleItemHeader } from './CollapsibleItemHeader'
import { WarningAlertBox } from './WarningAlertBox'
import { AccountCreationSectionsEnums, EmailStatusEnums, MsisdnStatusEnums } from '../../../enum'
import { InputPasswordField, InputTextField, InputTextFieldWithButton } from '../../../components'
import { FileUpload } from '../../../components/FileUpload';
import { getUserSearch } from '../../../services/user.service';
import SimpleReactValidator from 'simple-react-validator';
import { useAppDispatch } from '../../../store';
import ReCAPTCHA from "react-google-recaptcha";
import * as CONSTANTS from '../../../constants';
import { submitMsisdn, submitMsisdnOtp, submitUser } from '../../../store/actions';
import { UserModel } from '../../signup/models/request/user.model';
import signInImage from '../../assets/images/bg-cover-sign-in-1.webp';
import { useSelector } from 'react-redux';
import Keycloak, { KeycloakInstance } from 'keycloak-js';
import { AccountCreationError, AccountCreationLoading, AccountCreationSuccess } from '../../signup/components';

type Props = {
    status: string,
    show: boolean,
    data: any,
    validator: any,
    showMsisdnOtpField: boolean,
    otp: any,
    setShow: (show: boolean) => void,
    handleOtpChange: (name: string) => (event: any) => void,
    submitOtp: () => void,
    forceUpdate: () => void,
    progress: number,
    userDetails: any
}

const YourProfileSectionUpdated = ({ status, show, data, setShow, forceUpdate, progress, userDetails }: Props) => {

    const {
        msisdnData,
        msisdnOtpData,
        //userDetails
    } = useSelector<any, any>(({ user }) => user);

    const [usernameUnique, setUsernameUnique] = useState<boolean | undefined>();
    const [emailUnique, setEmailUnique] = useState<boolean | undefined>();
    const [mobileVerified, setMobileVerified] = useState<string | undefined>();
    const [showMsisdnOtpField, setShowMsisdnOtpField] = useState(false);
    const [remainingTime, setRemainingTime] = useState(0);

    const [otp, setOtp] = useState({
        pin: "",
        serverRef: ""
    })
    const dispatch = useAppDispatch();
    const [recaptchaKey, setRecaptchaKey] = useState("");
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const [values, setValues] = useState({
        first_name: '',
        last_name: '',
        user_name: "",
        mobile: '',
        password: '',
        confirm_password: ''
    });

    const [loadingStatus, setLoadingStatus] = useState("init");

    const [validator] = useState(new SimpleReactValidator({
        messages: {
            regex: 'The :attribute field must have atleast two words (First name and last name).',
            required: 'You can’t keep this as empty',
        },
        validators: {
            dialog: {
                message: 'The number you entered is invalid.',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^([0]{1}[7]{1}[764]{1}\d{7}|[7]{1}[764]{1}\d{7})$/) && params.indexOf(val) === -1
                }
            },
            all_mobiles: {
                message: 'The entered :attribute number is invalid.',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^[0]{1}[123456789]{2}\d{7}|[123456789]{2}\d{7}$/) && params.indexOf(val) === -1
                }
            },
            alpha_num_underscore: {
                message: 'The :attribute is invalid.',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^[a-zA-Z0-9_]*$/) && params.indexOf(val) === -1
                }
            },
            lowerCase: {
                message: 'Password must include lowercase value',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^(?=.*[a-z])/) && params.indexOf(val) === -1
                }
            },
            upperCase: {
                message: 'Password must include uppercase value',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^(?=.*[A-Z])/) && params.indexOf(val) === -1
                }
            },
            numberValue: {
                message: 'Must include at least one special character',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^(?=.*[!@#$%^&*._-])/) && params.indexOf(val) === -1
                }
            },
            specialCharacter: {
                message: 'Must include at least one number',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^(?=.*[0-9])/) && params.indexOf(val) === -1
                }
            },
            spaceValidate: {
                message: 'Spaces are not allowed',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^[^\s]{1,}$/) && params.indexOf(val) === -1
                }
            }
        },
        autoForceUpdate: this
    }))
    const [passwordValidity, setPasswordValidity] = useState({
        isValid: true,
        message: ''
    });
    const [passwordValidator] = useState(new SimpleReactValidator({
        messages: {
            required: 'You can’t keep this as empty',
            min: ':attribute is too short',
            max: ':attribute is too lengthy',
        },
        validators: {
            lowerCase: {
                message: 'Password must include lowercase value',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^(?=.*[a-z])/) && params.indexOf(val) === -1
                }
            },
            upperCase: {
                message: 'Password must include uppercase value',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^(?=.*[A-Z])/) && params.indexOf(val) === -1
                }
            },
            numberValue: {
                message: 'Must include at least one special character',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^(?=.*[!@#$%^&*._-])/) && params.indexOf(val) === -1
                }
            },
            specialCharacter: {
                message: 'Must include at least one number',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^(?=.*[0-9])/) && params.indexOf(val) === -1
                }
            },
            spaceValidate: {
                message: 'Spaces are not allowed',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^[^\s]{1,}$/) && params.indexOf(val) === -1
                }
            }
        },
        autoForceUpdate: this
    }));

    const reCaptchaChange = (value: any) => {
        setRecaptchaKey(value);
        // console.log("recaptcha key:", recaptchaKey);
    }

    const handleContinue = async () => {

        if (validator.allValid() && passwordValidator.allValid() && usernameUnique === true && mobileVerified === "verified") {
            if (values.password === values.confirm_password) {
                setPasswordValidity({
                    ...passwordValidity,
                    isValid: true
                })

                const userModel: UserModel = {
                    userName: values.user_name,
                    password: values.password,
                    firstName: values.first_name,
                    lastName: values.last_name,
                    email: "",
                    contact: values.mobile.length === 9 ? "0" + values.mobile : values.mobile,
                    key: recaptchaKey,
                    userRole: "",
                    otpRef: otp.serverRef ? otp.serverRef : ""
                }
                // console.log("recaptch key in submit", recaptchaKey);
                // console.log("username key in submit", values.user_name);

                dispatch(submitUser(userModel))
                setLoadingStatus("success");

                // setTimeout(() => {
                //     // navigate("/");
                //     window.location.href = "/";
                //   }, 1000)


            } else {
                setPasswordValidity({
                    isValid: false,
                    message: 'Passwords must be same.'
                });
                recaptchaRef!.current!.reset();
            }
        } else {
            // alert("error before submit");
            validator.showMessages();
            passwordValidator.showMessages();
            recaptchaRef!.current!.reset();
            if (mobileVerified !== "verified") {
                setMobileVerified("notVerified")
            }
            forceUpdate()
        }
    }

    useEffect(() => {
        if (values.password && values.confirm_password && values.password !== values.confirm_password) {
            setPasswordValidity({
                isValid: false,
                message: 'Passwords must be same.'
            });
        } else {
            setPasswordValidity({
                isValid: true,
                message: ''
            });
        }
    }, [values.password, values.confirm_password]);


    useEffect(() => {
        setUsernameUnique(undefined);
        forceUpdate();
    }, [values.user_name]);

    useEffect(() => {
        if (msisdnData && msisdnData.data) {
            setShowMsisdnOtpField(true)
            setRemainingTime(120);
            setOtp({ ...otp, serverRef: msisdnData.data.serverRef })
            forceUpdate();
        }
    }, [msisdnData])

    useEffect(() => {
        if (msisdnOtpData && msisdnOtpData.status === "ERROR") {
            if (msisdnOtpData.message == "Wrong Pin") {
                setMobileVerified("notVerified")
            } else {
                setMobileVerified("notVerified")
            }
        }
        if (msisdnOtpData && msisdnOtpData.data && msisdnOtpData.data.data && msisdnOtpData.data.data.status === "SUCCESS") {
            setMobileVerified("verified")
            setShowMsisdnOtpField(false)
        }
    }, [msisdnOtpData])

    useEffect(() => {
        let intervalId: any = null;

        if (remainingTime > 0) {
            intervalId = setInterval(() => {
                setRemainingTime((prevTime) => prevTime - 1);
            }, 1000);
        } else {
            if (mobileVerified !== 'verified') {
                setMobileVerified(undefined);
            }
            setShowMsisdnOtpField(false);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [remainingTime]);

    useEffect(() => {
        if (userDetails) {
            // console.log("user details in profile section", userDetails);
            setValues({
                ...values,
                first_name: userDetails.firstName ? userDetails.firstName : "",
                last_name: userDetails.lastName ? userDetails.lastName : "",
                mobile: userDetails.contact ? userDetails.contact : "",
                user_name: userDetails.userName ? userDetails.userName : ""
            })


        }
    }, [userDetails])

    const useForceUpdate = () => {
        const [value, setValue] = useState(0);
        return () => setValue(value => value + 1);
    }

    const handleChange = (name: string) => (event: any) => {
        // console.log("form event", name, event)
        setValues({ ...values, [name]: event.target.value });
        if (name == "user_name") {
            setUsernameUnique(undefined);
        }
        if (name == "mobile") {
            setMobileVerified(undefined);
        }
    };


    const submitUsernameCheck = () => {

        let username = values.user_name;
        console.log("username", username);

        if (username !== "") {
            getUserSearch("username", username).then((response) => {
                console.log("username", response.data.data == '0');

                setUsernameUnique(response.data.data == '0' ? false : true);
            }).catch((error) => {
                console.log(error);
            });
        }
    }

    const verifyMsisdnForYourProfile = () => {
        if (values.mobile) {
            let mobileNo = values.mobile;
            if (values.mobile.length == 9) {
                mobileNo = "94" + mobileNo;
            } else {
                if (values.mobile.charAt(0) === "0") {
                    mobileNo = "94" + mobileNo.slice(1);
                }
            }
            //empty previously entered otp if available
            setOtp({
                pin: "",
                serverRef: ""
            });
            const req = {
                method: "ANC",
                msisdn: mobileNo
            }
            dispatch(submitMsisdn(req, CONSTANTS.COMMON_TENANT_ID!))
        }
    }

    const submitOtp = () => {
        if (otp.pin && otp.serverRef) {
            dispatch(submitMsisdnOtp(otp, CONSTANTS.COMMON_TENANT_ID!))
        }
    }

    const handleOtpChange = (name: string) => (event: any) => {
        setOtp({ ...otp, pin: event.target.value });
    }


    const handleSignIn = () => {
        window.location.href = "/home";
    }


    return (
        (loadingStatus === "init") ?
            <>
                <div className="wiz-step__wrapper" >
                    {/* step*/}
                    <div className="single-wiz-step__wrapper" id="profile-section">
                        <CollapsibleItemHeader
                            title='Your Profile'
                            desc='Your personal profile details.'
                            status={status}
                            isVisible={show}
                            name={AccountCreationSectionsEnums.HOME_YOUR_PROFILE}
                            handleCollapsButton={setShow}
                        />
                        <div className={"single-wiz-step__content " + (show ? "show" : "")}>
                            <div className="wiz-content-form__wrapper">
                                {/*<>*/}
                                {/*    <div className="alert warning">*/}
                                {/*        <span className="alert-text__wrapper">*/}
                                {/*            <i className="alert-icon bi bi-exclamation-circle-fill" />*/}
                                {/*            /!*<span className="alert-text">{"Dear Customer,\n A scheduled service maintenance is in progress. Therefore, some interruptions can be expected during registration. We apologize for the inconvenience."}</span>*!/*/}
                                {/*            <span className="alert-text"><p>Dear Customer,</p><p>A scheduled service maintenance is in progress. Therefore, some interruptions can be expected during registration. We apologize for the inconvenience.</p></span>*/}
                                {/*            /!*<button className="btn btn-small secondary-action" onClick={() => { window.location.replace(`${CONSTANTS.REACT_APP_CLASSIC_UI_URL}`); }}>Switch to classic view</button>*!/*/}
                                {/*        </span>*/}
                                {/*    </div><br></br>*/}
                                {/*</>*/}
                                {/* ~ is-validated ~ class. removing or adding this class will effect the ~form-control~ class.*/}
                                <div className="form-group__wrapper is-validated">
                                    <div className="form-group">
                                        <InputTextField
                                            name='first_name'
                                            label='First name'
                                            placeholder='Type your first name'
                                            value={values.first_name}
                                            setValue={handleChange}
                                            validator={validator}
                                            rules={['required', 'alpha_space', { min: 3 }, { max: 25 }]}
                                            disabled={userDetails ? true : false}
                                            status="default"
                                            validationMessage=""
                                            forceUpdate={forceUpdate}
                                        />
                                        <div className="form-element__wrapper form-element__wbtn validated mb-3">
                                            <InputPasswordField
                                                name='password'
                                                label='Password'
                                                placeholder={userDetails ? '*******' : 'Type the password'}
                                                value={values.password}
                                                setValue={handleChange}
                                                validator={passwordValidator}
                                                rules={['required', 'spaceValidate', 'lowerCase', 'upperCase', 'numberValue', 'specialCharacter', 'min:8', 'max:30']}
                                                disabled={userDetails ? true : false}
                                                status="default"
                                                validationMessage=""
                                                showEyeIcon={true}
                                                forceUpdate={forceUpdate}
                                                tooltipComponent={<div>
                                                    <b>Password policy criteria</b>
                                                    <p>*Password must include uppercase, lowercase value.</p>
                                                    <p>*Must include at least one number.</p>
                                                    <p>*Must include at least one Symbol.</p>
                                                    <p>*The minimum length is 8</p>
                                                    <p>*The maximum length is 30</p>
                                                </div>}/>
                                        </div>
                                        {(!showMsisdnOtpField || remainingTime == 0) ?
                                            <InputTextFieldWithButton
                                                name='mobile'
                                                label='Mobile number'
                                                placeholder='07xxxxxxxx/7xxxxxxxx'
                                                value={values.mobile}
                                                setValue={handleChange}
                                                validator={validator}
                                                rules={['required', 'dialog']}
                                                disabled={userDetails ? true : false}
                                                status={(mobileVerified === "verified") ? "verified" : (mobileVerified === "notVerified") ? "notVerified" : ""}
                                                validationMessage={(mobileVerified === "verified") ? "Verified!" : (mobileVerified === "notVerified") ? "Not verified! Please verify your mobile" : ""}
                                                buttonLable="Verify"
                                                showButton={true}
                                                onClickFunc={verifyMsisdnForYourProfile}
                                                forceUpdate={forceUpdate}
                                                buttonDisabled={(userDetails || mobileVerified === "verified") ? true : false}
                                                preValueCheck={true}
                                                showRemainingTime={!userDetails && showMsisdnOtpField}
                                                remainingTime={remainingTime}
                                            />
                                            :
                                            <InputTextFieldWithButton
                                                name='otp'
                                                label='Enter OTP'
                                                placeholder='123456'
                                                value={otp.pin}
                                                setValue={handleOtpChange}
                                                validator={validator}
                                                rules={['required', 'numeric', { min: 6 }, { max: 6 }]}
                                                disabled={userDetails ? true : false}
                                                status={(data.msisdnStatus === MsisdnStatusEnums.VERIFIED) ? "verified" : (data.msisdnStatus === MsisdnStatusEnums.PENDING) ? "notVerified" : (data.msisdnStatus === MsisdnStatusEnums.INVALID) ? "invalid" : ""}
                                                validationMessage={(data.msisdnStatus === MsisdnStatusEnums.VERIFIED) ? "Verified!" : (data.msisdnStatus === MsisdnStatusEnums.PENDING) ? "Not verified! Please verify your mobile" : (data.msisdnStatus === MsisdnStatusEnums.INVALID) ? "Invalid OTP!" : ""}
                                                buttonLable="Submit"
                                                // showButton={data.msisdnStatus === MsisdnStatusEnums.PENDING}
                                                showButton={true}
                                                onClickFunc={submitOtp}
                                                buttonDisabled={false}
                                                forceUpdate={forceUpdate}
                                                preValueCheck={true}
                                                showRemainingTime={!userDetails && showMsisdnOtpField}
                                                remainingTime={remainingTime}
                                            />
                                        }
                                        {/* {!userDetails &&
                                            (<div className="form-bottom__wrapper">
                                                <ReCAPTCHA sitekey={CONSTANTS.REACT_APP_GOOGLE_RECAPTURE_SITE_KEY} onChange={reCaptchaChange} ref={recaptchaRef} />
                                                <div className="form-terms-mention__wrapper">
                                                    <p>{'By clicking "Save & Continue" button, you agree to our '}
                                                        <a href="https://d2xt8w8fh2bnjt.cloudfront.net/qa/userguides/Terms+-Business+owner.docx" target="_blank" rel="noopener noreferrer">Terms of Service</a> and 
                                                        <a href="https://d2xt8w8fh2bnjt.cloudfront.net/qa/userguides/Privacy+Policy.pdf" target="_blank" rel="noopener noreferrer"> Privacy Policy.</a>
                                                    </p>
                                                </div> */}
                                                {/* <button className="btn btn-primary btn-fl-wd" onClick={onSubmitRegister} disabled={!recaptchaKey}>Sign up</button> */}
                                            {/* </div>)} */}
                                        {/* <div className="form-bottom__wrapper">
                                    <div className="form-terms-mention__wrapper">
                                        <p>By clicking "Save &amp; Continue" below, you agree to out <a href="#" target="_blank" rel="noopener noreferrer">Terms of Service</a> and Privacy Policy.</p>
                                    </div>
                                </div> */}
                                        {/* <div class="form-element__wrapper validated  mb-3">
                                                        <label for="" class="form-label">NIC Number</label>
                                                        <input type="text" class="form-control invalid" id=""
                                                            placeholder="1900000000">
                                                        <div class="validation invalid-feedback">
                                                            Invalid! please use correct type of Number
                                                        </div>
                                                    </div> */}
                                    </div>
                                    <div className="form-group">
                                        <InputTextField
                                            name='last_name'
                                            label='Last name'
                                            placeholder='Type your last name'
                                            value={values.last_name}
                                            setValue={handleChange}
                                            validator={validator}
                                            rules={['required', 'alpha_space', { min: 3 }, { max: 25 }]}
                                            disabled={userDetails ? true : false}
                                            status="default"
                                            validationMessage=""
                                            forceUpdate={forceUpdate} />
                                        <div className="form-element__wrapper mb-3">
                                            <InputPasswordField
                                                name='confirm_password'
                                                label='Confirm password'
                                                placeholder={userDetails ? '*******' : 'Re-type the password'}
                                                value={values.confirm_password}
                                                setValue={handleChange}
                                                validator={passwordValidator}
                                                rules={['required']}
                                                disabled={userDetails ? true : false}
                                                status={passwordValidity.isValid ? "default" : "invalid"}
                                                validationMessage={passwordValidity.message ? passwordValidity.message : ""}
                                                showEyeIcon={true}
                                                forceUpdate={forceUpdate} />
                                        </div>
                                        <InputTextFieldWithButton
                                            name='user_name'
                                            label='Type username you preferred'
                                            placeholder='Type your username (Eg: JohnF)'
                                            value={values.user_name}
                                            setValue={handleChange}
                                            validator={validator}
                                            rules={['required', 'alpha_num_underscore', { max: 15 }, { min: 5 }]}
                                            disabled={userDetails ? true : false}
                                            status={(usernameUnique === true) ? "verified" : (usernameUnique === false) ? "notVerified" : ""}
                                            validationMessage={(usernameUnique === true) ? "Username available! Confirm to reserve it" : (usernameUnique === false) ? "Username already exists. please try again" : ""}
                                            buttonLable="Check Availability"
                                            showButton={(validator.fieldValid('user_name') && !userDetails) ? true : false}
                                            onClickFunc={submitUsernameCheck}
                                            buttonDisabled={false}
                                            forceUpdate={forceUpdate}
                                            preValueCheck={true}
                                        />
                                        {/* <div className="form-element__wrapper validated form-element__wbtn right mb-3">
                                    <label htmlFor="" className="form-label">User name</label>
                                    <div className="input__wrapper">
                                        <input type="text" className="form-control valid" id="" placeholder="" defaultValue="username123" />
                                        <span><button className="btn btn-outline-green light-shade btn-xsmall">Check availability</button></span>
                                    </div>
                                    <div className="validation valid-feedback">
                                        Username Vailable! confirm to reserve it
                                    </div>
                                </div> */}
                                        {/* <div class="form-element__wrapper form-element__wbtn right mb-3">
                                                        <label for="" class="form-label">Upload field goes here</label>
                                                        <div class="input__wrapper dropzone__wrapper">

                                                            <div class="ant-upload ant-upload-drag">
                                                                drag and drop zone
                                                            </div>


                                                        </div>
                                                    </div> */}
                                    </div>
                                    {!userDetails &&
                                        <div className="sign-in-advice-inner"><span>OR</span></div>}
                                    {!userDetails &&
                                        <div className="form-group sign-up-sign-in-advice__wrapper">
                                            <h5 className="title-sign-in-advice__wrapper">Already have a Marketplace account?</h5>
                                            <button className="btn btn-outline-primary" onClick={handleSignIn}>Sign in</button>
                                            <img src={require('../../../assets/images/bg-cover-sign-in-1.webp')} alt="" srcSet="" />
                                        </div>}
                                </div>
                            </div>
                            <div className="single-wiz-step-footer">{
                                !userDetails && (
                                    <button className="btn btn-secondary" >Cancel</button>)}
                                    {/* !recaptchaKey || */}
                                <button className="btn btn-primary" onClick={handleContinue} disabled={!(validator.allValid() && passwordValidator.allValid() && usernameUnique === true)}>Save &amp; Continue</button>
                            </div>
                        </div>
                    </div>
                </div></>
            : (loadingStatus === "loading") ? <AccountCreationLoading />
                : (loadingStatus === "success") ? <AccountCreationSuccess />
                    : (loadingStatus === "error") ? <AccountCreationError />
                        : null
    )
}

export { YourProfileSectionUpdated }