import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SliceModel {
    error: boolean,
    loading: boolean,
    success: boolean,
    trackListData: any[],
    trackData: any[],
    updatedTrackData: any[],
    trackHistory: any[],
    termsAndConditionsDetails: any[],
    locationSubmitError: boolean,
    locationSubmitErrorMessage: string,
    locationSubmitLoading: boolean,
    locationSubmitSuccess: boolean,
    updateErrorMessage: string
}


const whereAreMyEmpWithoutConcent = createSlice({
  name: "settings-store",
  initialState: {
    error: false,
    loading: false,
    success: false,
    trackListData: [],
    trackData: [],
    updatedTrackData: [],
    trackHistory: [],
    termsAndConditionsDetails: [],
    locationSubmitError: false,
    locationSubmitErrorMessage: '',
    locationSubmitLoading: false,
    locationSubmitSuccess: false,
    updateErrorMessage: ''
  } as SliceModel,
  reducers: {
    trackListFetch: (state: SliceModel) => {
      return {
        ...state,
        error: false,
        loading: true,
        success: false
      }
    },
    trackListFetchSuccess: (state: SliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        error: false,
        loading: false,
        success: true,
        trackListData: action.payload
      }
    },
    trackListFetchFail: (state: SliceModel) => {
      return {
        ...state,
        error: true,
        loading: false,
        success: false
      }
    },
    tacFetchSuccess: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        error: false,
        loading: false,
        success: true,
        termsAndConditionsDetails: action.payload,
      };
    },
    tacFetchFail: (state) => {
      return {
        ...state,
        error: true,
        loading: false,
        success: false,
      };
    },
    locationPost: (state) => {
      return {
        ...state,
        locationSubmitError: false,
        locationSubmitLoading: true,
        locationSubmitSuccess: false,
      };
    },
    locationPostSuccess: (state) => {
      return {
        ...state,
        locationSubmitError: false,
        locationSubmitLoading: false,
        locationSubmitSuccess: true,
      };
    },
    locationPostFail: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        locationSubmitError: true,
        locationSubmitLoading: false,
        locationSubmitSuccess: false,
        locationSubmitErrorMessage: action.payload,
      };
    },
    individualTrackFetchSuccess: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        error: false,
        loading: false,
        success: true,
        trackData: action.payload,
      };
    },
    individualTrackFetchFail: (state) => {
      return {
        ...state,
        error: true,
        loading: false,
        success: false,
      };
    },
    trackHistoryFetch: (state) => {
      return {
        ...state,
        error: false,
        loading: true,
        success: false,
      };
    },
    trackHistoryFetchSuccess: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        error: false,
        loading: false,
        success: true,
        trackHistory: action.payload,
      };
    },
    trackHistoryFetchFail: (state) => {
      return {
        ...state,
        error: true,
        loading: false,
        success: false,
      };
    },
    trackDataUpdate: (state) => {
      return {
        ...state,
        locationSubmitError: false,
        locationSubmitLoading: true,
        locationSubmitSuccess: false,
      };
    },
    trackDataUpdateSuccess: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        locationSubmitError: false,
        locationSubmitLoading: false,
        locationSubmitSuccess: true,
        updatedTrackData: action.payload,
      };
    },
    trackDataUpdateFail: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        locationSubmitError: true,
        locationSubmitLoading: false,
        locationSubmitSuccess: false,
        updateErrorMessage: action.payload,
      };
    },
    enterpriseSettlementTypeGet: (state) => {
      return {
        ...state,
        error: false,
        loading: true,
        success: false,
      };
    },
    enterpriseSettlementTypeSuccess: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        error: false,
        loading: false,
        success: true,
        settlement_types: action.payload,
      };
    },
    enterpriseSettlementTypeFail: (state) => {
      return {
        ...state,
        error: true,
        loading: false,
        success: false,
      };
    }
  },
});

export default whereAreMyEmpWithoutConcent;