import axiosInstance from "../axios-instance";
import * as CONSTANTS from '../constants';

const getConfigData = async (tenantUuid: string, configUuid: string) => {
    return await axiosInstance({
        method: `get`,
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widget/gws/get-domain/${configUuid}/${tenantUuid}`
    })
}

const postGWSDomain = async (data: any, tenantUuid: string, widgetUuid: string) => {
    return await axiosInstance({
        method: `post`,
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widget/gws/add-domain/${tenantUuid}/${widgetUuid}`,
        data: data
    })
}

const postUser = async (data: any, configUuid: string, tenantUuid: string, widgetUuid: string) => {
    return await axiosInstance({
        method: `post`,
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widget/gws/create-user/${configUuid}/${tenantUuid}/${widgetUuid}`,
        data: data
    })
}

const postCustomer = async (data: any, configUuid: string, tenantUuid: string, widgetUuid: string) => {
    return await axiosInstance({
        method: `post`,
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widget/gws/create-customer/${configUuid}/${tenantUuid}/${widgetUuid}`,
        data: data
    })
}

const postVerify = async (data: any, tenantUuid: string, widgetUuid: string, configUuid: string) => {
    return await axiosInstance({
        method: `put`,
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widget/gws/take-user-consent/${configUuid}/${tenantUuid}/${widgetUuid}`,
        data: data
    })
}

const getAllConfigs = async (tenantUuid: string, widgetUuid: string) => {
    return await axiosInstance({
        method: `get`,
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widget/gws/get-all-domains/${tenantUuid}/${widgetUuid}`
    })
}

const getUserLicenseCount = async (tenantUuid: string, configUuid: string) => {
    return await axiosInstance({
        method: `get`,
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widget/gws/get-license-count/${tenantUuid}/${configUuid}`
    })
}

const deleteDomain = async (tenantUuid: string, widgetUuid: string, configUuid: string) => {
    return await axiosInstance({
        method: `put`,
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widget/gws/delete-domain/${tenantUuid}/${widgetUuid}/${configUuid}`
    })
}

const putVerifyDomain = async (data: any, tenantUuid: string, widgetUuid: string, configUuid: string) => {
    return await axiosInstance({
        method: `put`,
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widget/gws/verification/${configUuid}/${tenantUuid}/${widgetUuid}`,
        data: data
    })
}

export {
    getConfigData,
    postGWSDomain,
    postUser,
    postVerify,
    getAllConfigs,
    getUserLicenseCount,
    deleteDomain,
    postCustomer,
    putVerifyDomain
}