import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthSliceModel {
    
}

const quickSurveyStore = createSlice({
    name: "quicksurvey-store",
    initialState: {
        quickSurveyList: [],
        surveyIndividualDetails: [],
        surveyUpdateResponse: [],
        surveyLogs: [],
    } as AuthSliceModel,
    reducers: {
        quickSurveyFetch: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        },
        quickSurveyFetchSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                quickSurveyList: action.payload,
                success: true,
                error: false
            }
        },
        quickSurveyFetchFail: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                quickSurveyList: []
            }
        },
        surveyDataPost: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        },
        surveyDataPostSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                createSurveyResponse: action.payload,
                success: true,
                error: false
            }
        },
        surveyDataPostFail: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                createSurveyResponse: null
            }
        },
        surveyIndividualDetailsFetch: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        },
        surveyIndividualDetailsFetchSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                surveyIndividualDetails: action.payload,
                success: true,
                error: false
            }
        },
        surveyIndividualDetailsFetchFail: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                surveyIndividualDetails: []
            }
        },
        surveyUpdateFetch: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        },
        surveyUpdateSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                surveyUpdateResponse: action.payload,
                success: true,
                error: false
            }
        },
        surveyUpdateFail: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                surveyUpdateResponse: []
            }
        },
        surveyLogFetch: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        },
        surveyLogFetchSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                surveyLogs: action.payload,
                success: true,
                error: false
            }
        },
        surveyLogFetchFail: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                surveyLogs: []
            }
        },
    }
});

export default quickSurveyStore;