import React, { SyntheticEvent } from 'react'
import SimpleReactValidator from 'simple-react-validator'

type Props = {
    name: string,
    label: string,
    placeholder: string,
    rows: number,
    value: string,
    setValue: (name: string) => (event: any) => void,
    validator: SimpleReactValidator,
    rules: Array<any>,
    disabled: boolean,
    status: string, //defult, verified, notVerified, invalid
    validationMessage: string,
    forceUpdate: () => void
}

const InputTextArea = ({ name, label, placeholder, rows, value, setValue, validator, rules, disabled, status, validationMessage, forceUpdate }: Props) => {
    return (
        <div className="form-element__wrapper mb-3">
            <label htmlFor="" className="form-label">{label}
                {rules.includes('required') ? (
                    <span aria-label="required"></span>
                ) : ("")}
            </label>
            <textarea
                className="form-control"
                name={name}
                rows={rows}
                value={value}
                onChange={e => {
                    setValue(name)(e)
                    validator.showMessageFor(name)
                    forceUpdate();
                }}
                required={true}
                placeholder={placeholder}
                disabled={disabled} />
            {status === "verified" ?
                <div className="validation valid-feedback">{validationMessage}</div>
                : status === "invalid" ?
                    <div className="validation invalid-feedback">{validationMessage}</div>
                    : status === "notVerified" ?
                        <div className="validation panding-feedback">{validationMessage}</div>
                        : validator.message(name, value, rules) ?
                            <div className="validation invalid-feedback">{validator.message(name, value, rules)}</div>
                            : <></>}
        </div>
    )
}

export { InputTextArea }