import React, {CSSProperties, useEffect, useState} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Form, Modal, Radio, Row, Switch, Tooltip } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { Progress } from 'antd';
import {PropagateLoader} from "react-spinners";

const override: CSSProperties = {
    display: "block",
    margin: "0 auto"
};

type Props = {
    provisionStatus: string;
    getProvisionStatus: () => void,
    progress: number
}

const ProgressBar = ({ provisionStatus, getProvisionStatus, progress }: Props) => {
    const { wid } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        singleAppDetails
    } = useSelector(({ apps }) => apps);
    const {
        redisStatus
    } = useSelector(({ kyc }) => kyc);

    useEffect(() => {
        getProvisionStatus();
    }, []);

    return (
        <div className="card panel content-holder-panels">
            <div className="inner-center-loader__wrapper">
                <div className="progress_bar_container">
                    {redisStatus && redisStatus.data && !redisStatus.data.error &&
                        <div>
                            <Progress type="circle" strokeColor={'#4E286D'} percent={progress}/>
                            {progress !== 101 &&
                                <div>
                                    <p style={{marginTop: '30px', marginBottom: '30px'}}>{provisionStatus}</p>
                                    <PropagateLoader
                                        cssOverride={override}
                                        size={15}
                                        color={"#4E286D"}
                                        loading={true}
                                    />
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    )

}
export { ProgressBar };