export default {
    'home-account-type': ['mp_enterprise_owner', 'mp_dialog_agent', 'mp_marketing_admin', 'mp_common_agent', 'mp_content_generator'],
    'home-your-profile': ['mp_enterprise_owner', 'mp_dialog_agent', 'mp_marketing_admin', 'mp_common_agent', 'mp_content_generator'],
    'home-business-profile': ['mp_enterprise_owner', 'mp_dialog_agent', 'mp_marketing_admin', 'mp_common_agent'],
    'home-terms-and-conditions': ['mp_enterprise_owner', 'mp_dialog_agent', 'mp_marketing_admin', 'mp_common_agent', 'mp_content_generator'],
    'add-location-track': ['mp_enterprise_owner', 'mp_dialog_agent', 'mp_marketing_admin', 'mp_common_agent', 'mp_content_generator'],
    'update-location-track': ['mp_enterprise_owner', 'mp_dialog_agent', 'mp_marketing_admin', 'mp_common_agent', 'mp_content_generator'],
    'header-ent-dropdown': ['mp_enterprise_owner', 'mp_dialog_agent', 'mp_marketing_admin', 'mp_common_agent', 'mp_content_generator'],
    'header-user-dropdown': ['mp_user', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_marketing_admin', 'mp_common_agent', 'mp_content_generator'],
    'app-subscribe': ['mp_ent_admin', 'mp_enterprise_owner', 'mp_enterprise_user', 'mp_user'],
    'add-manage-campaign-config': ['mp_enterprise_owner', 'mp_dialog_agent', 'mp_marketing_admin', 'mp_common_agent', 'mp_content_generator'],
    'add-social-media-config': ['mp_enterprise_owner', 'mp_dialog_agent', 'mp_marketing_admin', 'mp_common_agent', 'mp_content_generator'],
    'add-new-sentiment': ['mp_enterprise_owner', 'mp_dialog_agent', 'mp_marketing_admin', 'mp_common_agent', 'mp_content_generator'],
    'update-sentiment': ['mp_enterprise_owner', 'mp_dialog_agent', 'mp_marketing_admin', 'mp_common_agent', 'mp_content_generator'],
    'survey-create': ['mp_enterprise_owner', 'mp_dialog_agent', 'mp_marketing_admin', 'mp_common_agent', 'mp_content_generator'],
    'survey-update': ['mp_enterprise_owner', 'mp_dialog_agent', 'mp_marketing_admin', 'mp_common_agent', 'mp_content_generator'],
}