import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Keycloak from 'keycloak-js';
import { useSelector } from "react-redux";
import { Tooltip } from 'antd';
import Auth from '../models/response/auth.model';
import AuthHandler from '../helper/AuthHandler';
import { persistor, useAppDispatch } from '../store';
import { closeNoticeBanner, memorizeApp, removeAuth, submitUserLoggedOut } from '../store/actions';
import * as CONSTANTS from '../constants';

type AppRoutesProps = {
    auth: Auth;
    keycloak: Keycloak | undefined;
    sideBarOpen: boolean;
    setSideBarOpen: (value: boolean) => void;
    entStatus: string;
}

const Header = ({ keycloak, sideBarOpen, setSideBarOpen, auth, entStatus }: AppRoutesProps) => {
    const [logo, setLogo] = useState<any>("");
    let navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [beforeRegDate, setBeforeRegDate] = useState<Date>(new Date("2023-11-28"));

    const { userDetails } = useSelector<any, any>(({ user }) => user);

    const { showNoticeBanner } = useSelector<any, any>(({ authentication }) => authentication);

    // const { memory } = useSelector<any, any>((memory) => memory);
    const {
        recentApps
    } = useSelector<any, any>(({ memory }) => memory);
    const entSelectRef = useRef<HTMLDivElement>(null);
    const profileSelectRef = useRef<HTMLDivElement>(null);

    const [entSelectOpen, setEntSelectOpen] = useState(false);
    const [userSelectOpen, setUserSelectOpen] = useState(false);
    const [userName, setUserName] = useState({
        firstName: "",
        lastName: ""
    })

    const [conpanyDetails, setCompanyDetails] = useState({
        companyName: "",
        address: ""
    })

    const onPressEntSelect = () => {
        setEntSelectOpen(!entSelectOpen);
    }

    const onPressUserSelect = () => {
        setUserSelectOpen(!userSelectOpen);
    }

    useEffect(() => {
        if (userDetails) {
            setUserName({
                firstName: userDetails.firstName ? userDetails.firstName : "",
                lastName: userDetails.lastName ? userDetails.lastName : ""
            })
            if (userDetails.OwnedEnterprises && Array.isArray(userDetails.OwnedEnterprises) && userDetails.OwnedEnterprises.length > 0 && userDetails.OwnedEnterprises[0].Enterprise) {
                setCompanyDetails({
                    companyName: userDetails.OwnedEnterprises[0].Enterprise.entName,
                    address: userDetails.OwnedEnterprises[0].Enterprise.entAddressCity
                });
                userDetails.OwnedEnterprises[0].EnterpriseImages.map((value: any, index: number) => {
                    if (value.fieldName === "entLogo") {
                        setLogo(value.imgUrl.url);
                    }
                });
            }
        }

    }, [userDetails])

    useEffect(() => {
        function handleEntSelectClickOutside(event: MouseEvent): void {
            if (entSelectRef.current && !entSelectRef.current.contains(event.target as Node)) {
                setEntSelectOpen(false);
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleEntSelectClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleEntSelectClickOutside);
        };
    });

    useEffect(() => {
        function handleProfileSelectClickOutside(event: MouseEvent): void {
            if (profileSelectRef.current && !profileSelectRef.current.contains(event.target as Node)) {
                setUserSelectOpen(false);
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleProfileSelectClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleProfileSelectClickOutside);
        };
    });

    const onPressNav = () => {
        setSideBarOpen(!sideBarOpen);
    }

    const onBannerClose = () => {
        dispatch(closeNoticeBanner());
    }

    return (
        <>
            {showNoticeBanner && keycloak && userDetails && userDetails.createdAt && (new Date(userDetails.createdAt) < new Date(beforeRegDate)) &&
                <nav className="nav-top-secondary">
                    <div className="nav-element__wrapper d-flex align-center">
                        <span className="testing-nav-secondary">
                            <i className="alert-icon bi bi-exclamation-circle" /> Great choice on the enhanced interface! As our classic view phases out, stick with this upgrade!
                        </span>
                        <span id="nav-top-secondary-close" onClick={onBannerClose}> ✕ </span>
                    </div>
                </nav>
            }
            <nav className="nav-top">
                <div className="nav-element__wrapper d-flex align-center">
                    <div className="nav-brand" style={{ backgroundImage: `url(${require('../assets/images/mp-symbol.png')})` }} onClick={() => { window.location.replace(`${CONSTANTS.REACT_APP_LANDING_URL}`) }} />
                    {userDetails && userDetails.OwnedEnterprises && Array.isArray(userDetails.OwnedEnterprises) && userDetails.OwnedEnterprises.length > 0 && userDetails.OwnedEnterprises[0].Enterprise && userDetails.OwnedEnterprises[0].Enterprise.status === "Active" && <div className="pop-dropdown__wrapper adj-123" ref={entSelectRef}>
                        {(entStatus == "Active" && AuthHandler.isPerformable(auth, 'header-ent-dropdown')) && <a className="pop-dropdown " onClick={onPressEntSelect} onBlur={() => setEntSelectOpen(false)}>
                            <div className="profile-placeholder__wrapper left-align business-placeholder__wrapper">
                                <div className="business-img-holder sm" style={{ backgroundImage: `url(${logo})` }}>{(Array.isArray(conpanyDetails.companyName.split(" ")) && conpanyDetails.companyName.split(" ").length > 0) ? conpanyDetails.companyName.split(" ")[0].charAt(0) : ""}{(Array.isArray(conpanyDetails.companyName.split(" ")) && conpanyDetails.companyName.split(" ").length > 1) ? conpanyDetails.companyName.split(" ")[1].charAt(0) : ""}</div>
                                <div className="profile-details">
                                    <span className="main-title mt-bg">{conpanyDetails.companyName}</span>
                                    <span className="sub-title">{conpanyDetails.address}</span>
                                </div>
                                <span className="pop-dropdown-icon"><i className="bi bi-chevron-down" /></span>
                            </div>
                        </a>}
                        {/* ++ add ~ show ~ class for visibility*/}
                        {/* ~ mob-bottom-slider ~  class for mobile devices*/}
                        <ul className={"pop-dropdown-menu dropdown-menu-end mob-bottom-slider " + (entSelectOpen ? 'show' : 'close')}>
                            <li>
                                <a className="dropdown-item" href="#">
                                    <div className="profile-placeholder__wrapper left-align business-placeholder__wrapper">
                                        <div className="business-img-holder sm" style={{ backgroundImage: `url(${logo})` }}>{(Array.isArray(conpanyDetails.companyName.split(" ")) && conpanyDetails.companyName.split(" ").length > 1) ? conpanyDetails.companyName.split(" ")[1].charAt(0) : ""}</div>
                                        <div className="profile-details">
                                            <span className="main-title mt-bg">{conpanyDetails.companyName}</span>
                                            <span className="sub-title">{conpanyDetails.address}</span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            {/* <li>
                            <hr className="dropdown-divider" />
                        </li> */}
                            {/* <li>
                            <a className="dropdown-item" href="#">
                                <div className="profile-placeholder__wrapper left-align business-placeholder__wrapper">
                                    <div className="business-img-holder sm" style={{ backgroundImage: 'url()' }}>PB</div>
                                    <div className="profile-details">
                                        <span className="main-title mt-bg">Puththalam Buru Company LTD</span>
                                        <span className="sub-title">Rambukkana</span>
                                    </div>
                                </div>
                            </a>
                        </li> */}
                        </ul>
                    </div>}
                </div>
                {/* ~ mob-d-none ~ mobile display none - valid only for mobile devices*/}
                <div className="nav-element__wrapper center mob-d-none">
                    {/* <div className="primary-searchbar__wrapper">
                        <input type="text" className="form-control primary-searchbar" id="" placeholder="Search by app names, categories, solutions" />
                        <div className="primary-searchbar-list">
                        </div>
                    </div> */}
                </div>
                {/* ~ desk-d-none ~ desktop display none - valid only for desktop devices*/}
                <div className="nav-element__wrapper d-flex flex-end user-details-dropdown" ref={profileSelectRef}>
                    {/* { keycloak && userDetails && userDetails.createdAt && Date(userDetails.createdAt) < Date("2023-11-28") */}
                    {keycloak && userDetails && userDetails.createdAt && (new Date(userDetails.createdAt) < new Date(beforeRegDate)) &&
                        <button className="btn btn-outline-secondary btn-small" onClick={() => { window.location.replace(`${CONSTANTS.REACT_APP_CLASSIC_UI_URL}`) }}>Switch to classic view</button>
                    }
                    {!keycloak && <>
                        <button className="btn btn-outline-secondary btn-small" onClick={() => window.location.href = "/home"}>Sign in</button>
                        <button className="btn btn-outline-secondary btn-small" onClick={() => navigate('/signup')}>Sign up</button>
                    </>}
                    <div className="pop-dropdown__wrapper dropdown">
                        {AuthHandler.isPerformable(auth, 'header-user-dropdown') && userDetails && <a className="pop-dropdown " onClick={onPressUserSelect} onBlur={() => setUserSelectOpen(false)}>
                            <div className="profile-placeholder__wrapper right-align">
                                <div className="profile-details">
                                    <span className="main-title">{userName.firstName} {userName.lastName}</span>
                                    <span className="sub-title">{(userDetails && userDetails.role) ? userDetails.role == "mp_enterprise_owner" ? "Business Owner" : userDetails.role == "mp_marketing_admin" ? "Partner" : "" : ""}</span>
                                </div>
                                <div className="profile-img-holder sm" style={{ backgroundImage: 'url()' }}>
                                    {userName.firstName.toUpperCase().charAt(0)}{userName.lastName.toUpperCase().charAt(0)}
                                </div>
                            </div>
                        </a>}
                        {/* ++ add show class*/}
                        <ul className={"pop-dropdown-menu dropdown-menu-end " + (userSelectOpen ? 'show' : '')}>
                            <li>
                                <a className="dropdown-item" href="#">
                                    <div className="sub-pro-details">
                                        <div className="sub-pro-circle">
                                            <i className="bi bi-person-fill" />
                                        </div>
                                        <div className="sub-pro-content__wrapper">
                                            <Tooltip title={`${userName.firstName}`}>
                                                <span>{`${userName.firstName}`}</span>
                                            </Tooltip>
                                        </div>&nbsp;
                                        <div className="sub-pro-content__wrapper">
                                            <Tooltip placement="topLeft" title={`${userName.lastName}`}>
                                                <span>{`${userName.lastName}`}</span>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            {userDetails && userDetails.email && <li>
                                <a className="dropdown-item" href="#">
                                    <div className="sub-pro-details">
                                        <div className="sub-pro-circle">
                                            <i className="bi bi-envelope-fill" />
                                        </div>
                                        <div className="sub-pro-content__wrapper">
                                            <Tooltip title={userDetails.email ? userDetails.email : ''}>
                                                <span>
                                                    {userDetails.email ? (
                                                        userDetails.email.length > 20 ? `${userDetails.email.slice(0, 20)}...`
                                                            : userDetails.email
                                                    ) : (
                                                        ''
                                                    )}
                                                </span>
                                            </Tooltip>
                                        </div>
                                        <div className="sub-pro-content__wrapper">
                                            <Tooltip placement="topLeft" title={userDetails.email ? userDetails.email : ''}>
                                                <span>
                                                    {userDetails.email ? (
                                                        userDetails.email.length > 20 ?
                                                            `${userDetails.email.slice(0, 20)}...`
                                                            : userDetails.email
                                                    ) : (
                                                        ''
                                                    )}
                                                </span>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </a>
                            </li>}
                            <li>
                                <hr className="dropdown-divider" />
                            </li>
                            {(entStatus == "Active" && AuthHandler.isAccessible(auth, 'settings')) &&
                                <li>
                                    <a className="dropdown-item" href="/settings/profile-and-user-permission">
                                        Profile settings
                                    </a>
                                </li>
                            }
                            <li>
                                <a className="dropdown-item" onClick={() => {
                                    let clearState = new Promise(async (resolve, reject) => {
                                        let recentAppsObj = recentApps;
                                        dispatch(submitUserLoggedOut());
                                        await localStorage.clear();
                                        await persistor.purge();
                                        for (let i = 0; i < recentAppsObj.length; i++) {
                                            dispatch(memorizeApp(recentAppsObj[i]));
                                        }
                                        resolve(true);
                                    });
                                    clearState.then(() => {
                                        keycloak!.logout();
                                    }).catch((error) => {
                                        console.log(error);
                                    });
                                }}>
                                    Logout
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="nav-element__wrapper d-flex flex-end btn-nav-trigger " onClick={onPressNav}>
                    {/* <div id="activator" className="nav-trigger">
                  <span className="menu-icon">menu icon</span>
              </div> */}
                    <span className="burger"><span /></span>
                </div>
            </nav>
            <div className="primary-search-bar-mobile__wrapper desk-d-none">
                <input
                    type="text"
                    className="form-control primary-searchbar"
                    id=""
                    placeholder="Search by app names, categories, solutions"
                />
                <div className="primary-searchbar-list"></div>
            </div>
        </>
    );
}

export { Header };