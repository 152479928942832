import axiosInstance from "../axios-instance";
import * as CONSTANTS from "../constants";
import { KycConfigModel } from "../pages/apps/kyc/models/request/kyc-config.model";
import { KycFilterModel } from "../pages/apps/kyc/models/request/kyc-filter.model";
import * as Constants from "../constants";

const mcConfigFetch = (tenantId: string) => {
    // let mockResp = {
    //     test1:'test1',
    //     test2:'test2',
    // }
    // return mockResp;
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widget/campaignManagement/${tenantId}`
    });
};

const manageCampaignWorkflowRequestStatus = (configUUID: string) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widget/campaignManagement/status/${configUUID}`
    });
};

const manageCampaignDetails = (tenantId: string,configUUID: string) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widget/campaignManagement/view/${tenantId}/${configUUID}`
    });
};

const managedCampaignConfirm = (workflowData:any,tenantId: string,configUUID: string,messageTypeUuid:string) => {
    return axiosInstance({
        method: "put",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widget/campaignManagement/update/${tenantId}/${configUUID}/${messageTypeUuid}`,
        data: workflowData
    });
};

const getReachCost = (body: any, tenantId: string, widgetId: string, msgType: string) => {
    return axiosInstance({
        method: "post",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widget/campaignManagement/generateEstimatedReachCost/${tenantId}/${widgetId}/${msgType}`,
        data: body
    });
}

const getManageCampaignDetails = (tenantId: string, configId: string) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widget/campaignManagement/view/${tenantId}/${configId}`
    });
};

const postCampaignData = async (data: any, tenantId: string, widgetId: string) => {
    return await axiosInstance({
        method: `post`,
        url: `${Constants.REACT_APP_NODE_BACKEND_URL}/widget/campaignManagement/${tenantId}/${widgetId}`,
        data: data
    })
}

const putCampaignData = async (data: any, tenantId: string, widgetId: string, configId: string) => {
    return await axiosInstance({
        method: `put`,
        url: `${Constants.REACT_APP_NODE_BACKEND_URL}/widget/campaignManagement/update-resubmit/${tenantId}/${widgetId}/${configId}`,
        data: data
    })
}

export {
    mcConfigFetch,
    manageCampaignWorkflowRequestStatus,
    manageCampaignDetails,
    managedCampaignConfirm,
    getReachCost,
    getManageCampaignDetails,
    postCampaignData,
    putCampaignData
}