import axiosInstance from "../axios-instance";
import * as CONSTANTS from "../constants";
import { KycConfigModel } from "../pages/apps/kyc/models/request/kyc-config.model";
import { KycFilterModel } from "../pages/apps/kyc/models/request/kyc-filter.model";

const getKYCList = (tenantId: string, widgetId: string) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/widget/kyc/revamp/kycList/${tenantId}/${widgetId}`
    });
};

const filterKycList = (tenantId: string, widgetId: string, req: KycFilterModel) => {
    return axiosInstance({
        method: "post",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/widget/kyc/revamp/filter/${tenantId}/${widgetId}`,
        data: req
    });
};

const getKycUsernames = (tenantId: string) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/widget/kyc/revamp/usernames/${tenantId}`
    });
};

const getKycEvents = (tenantId: string) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/widget/kyc/revamp/events/${tenantId}`
    });
};

const getKycListForCsv = (tenantId: string, widgetId: string, req: KycFilterModel) => {
    return axiosInstance({
        method: "post",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/widget/kyc/revamp/kycListForCSV/${tenantId}/${widgetId}`,
        data: req
    });
};

const getKycConfigs = (tenantId: string, widgetId: string) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/widget/kyc/revamp/kycConfig/${tenantId}/${widgetId}`
    });
};

const postKycConfigs = (tenantId: string, widgetId: string, req: KycConfigModel) => {
    return axiosInstance({
        method: "post",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/widget/kyc/revamp/kycConfig/${tenantId}/${widgetId}`,
        data: req
    });
};

const postAppSubscription = (tenantId: string, widgetId: string, prvId: string) => {
    return axiosInstance({
        method: "post",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/apiWidget/revamp/appSubscription/${tenantId}/${widgetId}/${prvId}`
    });
};

const getKycRedisStatus = (uuid: string) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/enterprise-status/${uuid}`
    });
}

export {
    getKYCList,
    filterKycList,
    getKycUsernames,
    getKycEvents,
    getKycListForCsv,
    getKycConfigs,
    postKycConfigs,
    postAppSubscription,
    getKycRedisStatus
}