import React from 'react'
import { SingleAppModel, SubWidgets, WidgetRate } from '../models/response/single-app.model';
import SimpleReactValidator from 'simple-react-validator';
import { RateItemCommonFeaturesComponent } from './RateItemCommonFeaturesComponent';

type Props = {
    appDetails: SingleAppModel,
    onChangeRate: (e: any) => void,
    selectedRate: string,
    validator: SimpleReactValidator,
    disabled?: boolean | undefined,
}

const MultiRateCardSelect = ({ appDetails, onChangeRate, selectedRate, validator, disabled }: Props) => {

    return (
        <div className="panel-table-content-list">
            {Array.isArray(appDetails?.widgetDetails?.WidgetRate) && appDetails?.widgetDetails?.WidgetRate.map((widgetRate: WidgetRate, index: number) => {
                let detailsJson = widgetRate?.RatecardAddtionalDetails?.detailsJson ? (typeof widgetRate?.RatecardAddtionalDetails?.detailsJson === 'string' ? JSON.parse(widgetRate?.RatecardAddtionalDetails?.detailsJson) : widgetRate?.RatecardAddtionalDetails?.detailsJson) : null;

                const commonFeatures = detailsJson?.commonFeatures && index === 0 ? <div className="pricing-grid-list__wrapper" style={{gridTemplate: "none"}}><RateItemCommonFeaturesComponent commonFeatures={detailsJson.commonFeatures}/></div> : null;
                const renderedDetails = detailsJson &&
                    <div className={`panel-table-list-item card ${((widgetRate.uuid === selectedRate) ? 'active' : '')}`}>
                    <div className="panel-table-listing-item__inner">
                        {detailsJson && ('description' in detailsJson) && !('detail1' in detailsJson) &&
                            <div className="feature-details__wrapper">
                                <div className="feature-details-title__wrapper no-shade  billing-and-plans-package-select">
                                    <span className="title">{detailsJson['description']}</span>
                                </div>
                            </div>}
                        {detailsJson && Object.keys(detailsJson).map((key) => {
                            if (key !== 'title' && key !== 'description' && key !== 'commonFeatures') {
                                return (<div className="feature-details__wrapper">
                                    <div className="feature-details-title__wrapper no-shade  billing-and-plans-package-select">
                                        <span className="title">{detailsJson[key]}</span>
                                        {/* <span className="small-desc">
                                            {widgetRate.description}
                                        </span> */}
                                    </div>
                                </div>)
                            }
                            return null;
                        })}
                    </div>
                    <div className="panel-table-listing-item__inner">
                        <div className="panel-table-right-align-item__wrapper">
                            {<div className="panel-table-right-align-item">
                                <input
                                    className="form-check-input secondary"
                                    type="radio"
                                    name=""
                                    id="radio1"
                                    value={widgetRate.uuid?.toString()}
                                    onChange={onChangeRate}
                                    checked={widgetRate.uuid === selectedRate}
                                    disabled={disabled}
                                />
                            </div>}
                        </div>
                    </div>
                </div>

                return (
                    <> 
                        {commonFeatures}
                        <span className="panel-group-title">{widgetRate.title} - {widgetRate.currencyType ? widgetRate.currencyType : 'LKR '} {widgetRate.rate} {" + Tax"}</span>
                        {renderedDetails}
                    </>
                )
            })}
            <div className="validation invalid-feedback">{validator.message('rate', selectedRate, 'required')}</div>
        </div>
    )
}

export { MultiRateCardSelect }