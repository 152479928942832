/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import Auth from '../../models/response/auth.model';
import { checkMaskDuplicate, fetchMaskList, fetchMsisdnList, fetchPaymentOptionsList, fetchPortList, fetchSelectedLongNumber, fetchSelectedPort, removeMask, removeMsisdn, submitLongNumber, submitMsisdn, submitMsisdnOtp, submitNewMask, submitNewMsisdn, submitPort, updateMask, updateMsisdn } from '../../store/actions';
import { useAppDispatch } from '../../store';
import { InputTextField, InputTextFieldWithButton } from '../../components';
import MskImg from '../../assets/images/featured-cover-sms-mask.png';
import PortImg from '../../assets/images/featured-cover-sms-short-code.png';
import LongNumImg from '../../assets/images/featured-long-number.png';
import { message, notification } from 'antd';
import * as CONSTANTS from '../../constants';
import { editPaymentMsisdn } from '../../services/settings.service';

type Props = {
    auth: Auth
}

function NumberConfigures() {
    const dispatch = useAppDispatch();
    const [msisdnSearchText, setMsisdnSearchText] = useState('');
    const [msisdnMode, setMsisdnMode] = useState('view');
    const [newMsisdn, setNewMsisdn] = useState('');
    const [otp, setOtp] = useState({
        pin: "",
        serverRef: ""
    })
    const [msisdnStatus, setMsisdnStatus] = useState('');
    const [updatedMsisdnStatus, setUpdatedMsisdnStatus] = useState<any>([]);
    const [updatedMsisdn, setUpdatedMsisdn] = useState<any>(null);

    const [numberList, setNumberList] = useState<any>(null);
    const [primaryNumberChanged, setPrimaryNumberChanged] = useState(false);

    const [editPaymentIndex, setEditPaymentIndex] = useState<number>(0);


    const [maskSearchText, setMaskSearchText] = useState('');
    const [maskMode, setMaskMode] = useState('view');
    const [newMask, setNewMask] = useState('');

    const [maskStatus, setMaskStatus] = useState('');
    const [updatedMaskStatus, setUpdatedMaskStatus] = useState<any>([]);
    const [updatedMask, setUpdatedMask] = useState<any>(null);

    const [isDuplicate, setIsDuplicate] = useState<null | boolean>(null);
    const [maskArray, setMaskArray] = useState<any>([]);
    const [isEditDuplicate, setIsEditDuplicate] = useState<any>([]);
    const [portInput, setPortInput] = useState('');
    const [longNumberInput, setLongNumberInput] = useState('');
    const [maskUpdateReq, setMaskUpdateReq] = useState<any>(null);
    const [maskActiveList, setMaskActiveList] = useState<string[]>([]);
    const [maskInactiveList, setMaskInactiveList] = useState<string[]>([]);
    const [viewAllClickCount, setViewAllClickCount] = useState(1);
    const [viewListLimit, setViewListLimit] = useState(5);
    const [showAll, setShowAll] = useState(false);
    const [defaultMaskReq, setDefaultMaskReq] = useState('');
    const [addRemainingTime, setAddRemainingTime] = useState(0);
    const [editRemainingTime, setEditRemainingTime] = useState(0);

    const [validator] = useState(new SimpleReactValidator({
        messages: {
            required: 'You can’t keep this as empty.',
            min: 'This should not be less than :min:type.',
            max: 'Maximum :max:type are allowed.',
            email: 'This does not look like an email.',
            numeric: 'Only numbers are allowed'
        },
        validators: {
            dialog: {
                message: 'The number you entered is invalid.',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    if (val.length == 10) {
                        return validator.helpers.testRegex(val, /^[0]{1}[7]{1}[764]{1}\d{7}$/) && params.indexOf(val) === -1
                    } else {
                        return validator.helpers.testRegex(val, /^[7]{1}[764]{1}\d{7}$/) && params.indexOf(val) === -1
                    }
                }
            },
            all_mobiles: {
                message: 'The entered :attribute number is invalid.',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    if (val.length == 10) {
                        return validator.helpers.testRegex(val, /^[0]{1}[123456789]{2}\d{7}$/) && params.indexOf(val) === -1
                    } else {
                        return validator.helpers.testRegex(val, /^[123456789]{2}\d{7}$/) && params.indexOf(val) === -1
                    }
                }
            },
            alpha_num_underscore: {
                message: 'The :attribute is invalid.',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^[a-zA-Z0-9_]*$/) && params.indexOf(val) === -1
                }
            },
            alpha_num_underscore_space: {
                message: 'The :attribute is invalid.',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^[a-zA-Z0-9_\s]*$/) && params.indexOf(val) === -1
                }
            },
            alpha_num_underscore_dash_space: {
                message: 'The :attribute is invalid.',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^[a-zA-Z0-9_',.\s-]*$/) && params.indexOf(val) === -1
                }
            },
            address: {
                message: 'The :attribute is invalid.',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^[a-zA-Z0-9.,/\s-]*$/) && params.indexOf(val) === -1
                }
            },
            nic: {
                message: 'The entered :attribute is invalid.',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^([0-9]{9}[x|X|v|V]|[0-9]{12})$/) && params.indexOf(val) === -1
                }
            },
            mask: {
                message: 'The entered :attribute is invalid.',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^[ A-Za-z0-9!@#$%^&*'._]*$/) && params.indexOf(val) === -1
                }
            }

        },
        // autoForceUpdate: this
    }));

    const useForceUpdate = () => {
        const [value, setValue] = useState(0);
        return () => setValue(value => value + 1);
    }

    const forceUpdate = useForceUpdate()

    const {
        msisdnList,
        msisdnSubmitResponse,
        maskList,
        isMaskDuplicate,
        maskSubmitResponse,
        portList,
        selectedPort,
        portSubmitResponse,
        selectedLongNumber,
        longNumberSubmitResponse
    } = useSelector<any, any>(({ settings }) => settings);
    const {
        userDetails,
        msisdnData,
        msisdnOtpData
    } = useSelector<any, any>(({ user }) => user);

    useEffect(() => {
        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        dispatch<any>(fetchMsisdnList(tenantGroupId, msisdnSearchText));
        dispatch<any>(fetchMaskList(tenantGroupId, maskSearchText));
        dispatch<any>(fetchPortList());
        dispatch<any>(fetchSelectedPort(tenantGroupId));
        dispatch<any>(fetchSelectedLongNumber(tenantGroupId));
    }, []);

    useEffect(() => {
        setNumberList(msisdnList);
    }, [msisdnList]);

    useEffect(() => {
        if (maskMode == 'add') {
            setIsDuplicate(isMaskDuplicate);
        } else if (maskMode == 'edit') {
            console.log("isMaskDuplicate", isMaskDuplicate);
            if (isMaskDuplicate !== null) {
                let editDuplicate = isEditDuplicate;
                editDuplicate[updatedMask.index] = isMaskDuplicate;
                setIsEditDuplicate(editDuplicate);
            }
        }
    }, [isMaskDuplicate]);

    useEffect(() => {
        setMaskArray(maskList);
    }, [maskList]);

    useEffect(() => {
        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        const delayDebounceFn = setTimeout(() => {
            dispatch<any>(fetchMsisdnList(tenantGroupId, msisdnSearchText));
        }, 3000)

        return () => clearTimeout(delayDebounceFn)
    }, [userDetails, msisdnSearchText]);

    useEffect(() => {
        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        const delayDebounceFn = setTimeout(() => {
            dispatch<any>(fetchMaskList(tenantGroupId, maskSearchText));
        }, 3000)

        return () => clearTimeout(delayDebounceFn)
    }, [userDetails, maskSearchText]);

    useEffect(() => {
        if (msisdnData && msisdnData.data) {
            if (msisdnMode == 'add') {
                setMsisdnStatus('pending')
                setAddRemainingTime(120);
                setOtp({ ...otp, serverRef: msisdnData.data.serverRef })
            } else if (msisdnMode == 'edit') {
                let statusList: any = [...updatedMsisdnStatus];
                statusList[updatedMsisdn.index] = 'pending';
                // console.log(statusList, updatedMsisdn.index, statusList[updatedMsisdn.index]);
                setEditRemainingTime(120);

                setUpdatedMsisdnStatus(statusList);
                setOtp({ ...otp, serverRef: msisdnData.data.serverRef })
            }
        }
    }, [msisdnData])

    useEffect(() => {
        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        if (msisdnSubmitResponse && msisdnSubmitResponse.status === "SUCCESS") {
            setMsisdnMode('view');
            setMsisdnStatus('');
            setNewMsisdn('');
            dispatch<any>(fetchMsisdnList(tenantGroupId, msisdnSearchText));
        }
        if (msisdnSubmitResponse && msisdnSubmitResponse.status === "ERROR") {
            setMsisdnMode('view');
            setMsisdnStatus('');
            setNewMsisdn('');
            // if (msisdnSubmitResponse.message === "Msisdn already exists") {

            // } else {

            // }
        }
    }, [msisdnSubmitResponse])

    useEffect(() => {
        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        if (maskSubmitResponse && maskSubmitResponse.status.toLowerCase() === "success") {
            setMaskMode('view');
            setMaskStatus('');
            setNewMask('');
        }
        if (maskSubmitResponse && maskSubmitResponse.status.toLowerCase() === "failed") {
            setMaskMode('view');
            setMaskStatus('');
            setNewMask('');
        }
        dispatch<any>(fetchMaskList(tenantGroupId, maskSearchText));
        setMaskArray(maskList);
    }, [maskSubmitResponse])

    useEffect(() => {
        console.log("###### $$$$$$$$", msisdnOtpData);

        if (msisdnMode == 'add') {
            if (msisdnOtpData && msisdnOtpData.status && msisdnOtpData.status.toUpperCase() === "SUCCESS") {
                setMsisdnStatus('verified')
            }
            if (msisdnOtpData && msisdnOtpData.status && msisdnOtpData.status.toUpperCase() === "ERROR") {
                setMsisdnStatus('error')
            }
        } else if (msisdnMode == 'edit') {
            // console.log("###### $$$$$$$$ 1");
            if (msisdnOtpData && msisdnOtpData.status && msisdnOtpData.status.toUpperCase() === "SUCCESS") {
                // console.log("###### $$$$$$$$ 2");
                // setMsisdnStatus('verified')
                setMsisdnStatus('verified')
                let statusList: any = [...updatedMsisdnStatus];
                statusList[updatedMsisdn.index] = 'verified';
                // console.log(statusList, updatedMsisdn.index, statusList[updatedMsisdn.index]);

                setUpdatedMsisdnStatus(statusList);
            }
            if (msisdnOtpData && msisdnOtpData.status && msisdnOtpData.status.toUpperCase() === "ERROR") {
                // console.log("###### $$$$$$$$ 3");
                setMsisdnStatus('error')
                // setMsisdnStatus('error')
                let statusList: any = [...updatedMsisdnStatus];
                statusList[updatedMsisdn.index] = 'error';
                // console.log(statusList, updatedMsisdn.index, statusList[updatedMsisdn.index]);

                setUpdatedMsisdnStatus(statusList);
            }
        }
    }, [msisdnOtpData])

    useEffect(() => {
        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        dispatch<any>(fetchPortList());
        dispatch<any>(fetchSelectedPort(tenantGroupId));
        dispatch<any>(fetchMaskList(tenantGroupId, ''));
    }, [portSubmitResponse])

    useEffect(() => {
        if(Array.isArray(portList) && portList.length > 0) {
            setPortInput(portList[0].port);
        }
    }, [portList])

    useEffect(() => {

        if(longNumberSubmitResponse && longNumberSubmitResponse.status && longNumberSubmitResponse.status == "FAILED" && longNumberSubmitResponse.message =="Long number already used" ){
            notification.error({
                message: 'Notification',
                description: 'Long number already used',
                placement: 'bottomRight'
            });

            setLongNumberInput('');
        }
        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        dispatch<any>(fetchSelectedLongNumber(tenantGroupId));


    }, [longNumberSubmitResponse])

    useEffect(() => {
        if (longNumberInput !== '') {
            validator.showMessageFor('Long number');
            forceUpdate();
        }
    }, [longNumberInput])

    useEffect(() => {
        // console.log("Inactive list: " + maskInactiveList);
        // console.log("Active list: " + maskActiveList);
        const updateReq = {
            activeReq: maskActiveList,
            inactiveReq: maskInactiveList,
            defaultMask: defaultMaskReq
        }
        setMaskUpdateReq(updateReq);
    }, [maskInactiveList, maskActiveList]);

    useEffect(() => {
        setMaskUpdateReq({ ...maskUpdateReq, defaultMask: defaultMaskReq });
    }, [defaultMaskReq])

    useEffect(() => {
        let intervalId: any = null;

        if (addRemainingTime > 0) {
            intervalId = setInterval(() => {
                setAddRemainingTime((prevTime) => prevTime - 1);
            }, 1000);
        } else {
            setMsisdnStatus('');
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [addRemainingTime]);

    useEffect(() => {
        let intervalId: any = null;

        if (editRemainingTime > 0) {
            intervalId = setInterval(() => {
                setEditRemainingTime((prevTime) => prevTime - 1);
            }, 1000);
        } else {
            // setUpdatedMsisdnStatus([]);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [editRemainingTime]);

    const sendMobileOtp = () => {
        // console.log("dsfdf");

        if (newMsisdn) {
            let mobileNo = newMsisdn;
            if (newMsisdn.length == 9) {
                mobileNo = "94" + mobileNo;
            } else {
                if (newMsisdn.charAt(0) === "0") {
                    mobileNo = "94" + mobileNo.slice(1);
                }
            }
            const req = {
                method: "ANC",
                msisdn: mobileNo
            }
            // console.log("!!!!!!!!!!!!", (newMsisdn.length == 9 ? '0' + newMsisdn : newMsisdn));

            if (numberList && numberList.some((e: any) => e.msisdn === (newMsisdn.length == 9 ? '0' + newMsisdn : newMsisdn) && e.status !== 'Rejected')) {
                message.error('Mobile number already exists');
            } else {
                dispatch(submitMsisdn(req, CONSTANTS.COMMON_TENANT_ID!));
            }
        }
    }

    const verifyMobileOtp = () => {
        // console.log("##############%%%%%%%%%%###", mobileOtp.pin, mobileOtp.serverRef);

        if (otp.pin && otp.serverRef) {
            dispatch(submitMsisdnOtp(otp, CONSTANTS.COMMON_TENANT_ID!))
        }
    }

    const handleMsisdnChange = (name: string) => (event: any) => {
        setNewMsisdn(event.target.value);
    };

    const handleNewMask = (name: string) => (event: any) => {
        setIsDuplicate(null);
        setNewMask(event.target.value);
    };

    const onBlurMaskAdd = (e: any) => {
        let maskInput = e.target.value;
        if (maskInput.length > 2) {
            dispatch<any>(checkMaskDuplicate(maskInput));
        }
    }

    const onBlurMaskEdit = (e: any) => {
        let maskInput = e.target.value;
        console.log("### Edit ### 2");
        if (maskInput !== '' && (updatedMask && maskInput != maskList[updatedMask.index].mask)) {
            dispatch<any>(checkMaskDuplicate(maskInput));
        }
    }

    const handleOtpChange = (name: string) => (event: any) => {
        setOtp({ ...otp, pin: event.target.value })
    };

    const onSaveMsisdn = () => {
        // console.log("sfsfsdf");
        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        let req;
        if (msisdnMode == 'add') {
            req = {
                "msisdn": (newMsisdn.length === 9 ? '0' + newMsisdn : newMsisdn),
                "status": "Active",
                "subscriptionStatus": "Success",
                "otpRef": otp.serverRef
            }
            if (numberList && numberList.some((e: any) => e.msisdn === (newMsisdn.length === 9 ? '0' + newMsisdn : newMsisdn) && e.status !== 'Rejected')) {
                message.error('Mobile number already exists');
            } else {
                dispatch<any>(submitNewMsisdn(tenantGroupId, req));
            }
            // console.log("sfsfsdf 2", req);
        } else if (msisdnMode == 'edit') {
            req = {
                "uuid": updatedMsisdn ? updatedMsisdn.uuid : "",
                "msisdn": updatedMsisdn ? (updatedMsisdn.msisdn.length === 9 ? '0' + updatedMsisdn.msisdn : updatedMsisdn.msisdn) : ""
            }

            if (primaryNumberChanged && numberList[editPaymentIndex].msisdn) {
                
                let mssisdnUuid = numberList[editPaymentIndex].uuid;

                editPaymentMsisdn(tenantGroupId, mssisdnUuid , numberList[editPaymentIndex].isPrimary).then((response) => {
                    if (response.data && response.data.status === "SUCCESS") {
                        setMsisdnMode('view');
                        dispatch<any>(fetchPaymentOptionsList(tenantGroupId, 'null'));
                        message.success("Msisdn updated successfully");
                    } else {
                        message.error("Msisdn update failed");
                    }
                }).catch((error) => {
                    message.error("Msisdn update failed");
                });
            }
            // console.log("sfsfsdf 2", req);
            else if (numberList && numberList.some((e: any) => e.msisdn === (updatedMsisdn.msisdn.length === 9 ? '0' + updatedMsisdn.msisdn : updatedMsisdn.msisdn))) {
                dispatch<any>(updateMsisdn(tenantGroupId, req));
            }
            else {
                message.error('Something went wrong');
            }
            
            setPrimaryNumberChanged(false);

        }
    }

    const editMsisdn = (name: string) => (event: any) => {
        let uuid: string = name.split("/")[0];
        let index: number = parseInt(name.split("/")[1]);
        let list: any = [...numberList]; // Create a copy of the numberList array
        let obj = { ...list[index] }; // Create a copy of the object at the specified index

        obj.msisdn = event.target.value;
        console.log("$$$$$$$$$$$$", event.target.value);

        list[index] = obj; // Update the object in the copied array
        setNumberList(list);
    }

    const sendUpdatedMobileOtp = (uuid: string, index: number, msisdn: string) => {
        const msisdnsList = [...numberList];
        msisdnsList.splice(index, 1);
        // console.log("numberList", numberList);

        if (msisdn) {
            if (msisdnsList.some((e: any) => e.msisdn === (msisdn.length === 9 ? '0' + msisdn : msisdn) && e.status !== 'Rejected')) {
                message.error('Mobile number already exists');
            } else {
                let mobileNo = msisdn;
                if (newMsisdn.length == 9) {
                    msisdn = "94" + mobileNo;
                } else {
                    if (msisdn.charAt(0) === "0") {
                        mobileNo = "94" + mobileNo.slice(1);
                    }
                }
                const req = {
                    method: "ANC",
                    msisdn: mobileNo
                }
                let msisdnPayload = {
                    "index": index,
                    "uuid": uuid,
                    "msisdn": msisdn
                }
                setUpdatedMsisdn(msisdnPayload);
                dispatch(submitMsisdn(req, CONSTANTS.COMMON_TENANT_ID!))
            }
        }
    }

    const handleUpdateOtpChange = (name: string) => (event: any) => {
        setOtp({ ...otp, pin: event.target.value })
    };

    const onDeleteNumber = (uuid: string) => {
        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        dispatch<any>(removeMsisdn(tenantGroupId, uuid));
    }

    const onSaveMask = () => {
        const hasPendingMasks = maskArray.some((element: any) => element.status === "Pending");
        if (!hasPendingMasks) {
            if (maskMode == 'add') {
                const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
                let req = {
                    mask: newMask,
                    maskType: 'USER',
                    tenantId: tenantGroupId
                }
                dispatch<any>(submitNewMask(req));
            } else {
                const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
                let req = {
                    activeReq: maskUpdateReq.activeReq ? maskUpdateReq.activeReq : [],
                    inactiveReq: maskUpdateReq.inactiveReq ? maskUpdateReq.inactiveReq : [],
                    defaultMask: maskUpdateReq.defaultMask,
                    tenantId: tenantGroupId
                }
                dispatch<any>(updateMask(req));
                setMaskActiveList([]);
                setMaskInactiveList([]);
                setMaskUpdateReq(null);
            }
            setMaskMode('view');
        } else {
            message.warning("You will be able to add a new mask after the admin approves the pending mask.");
            setMaskMode('view');
            setMaskStatus('');
            setNewMask('');
        }
    }

    const editMask = (name: string) => (event: any) => {
        console.log("### Edit ### 1");

        let uuid: string = name.split("/")[1];
        let index: number = parseInt(name.split("/")[0]);
        let list: any = [...maskArray]; // Create a copy of the numberList array
        let obj = { ...list[index] }; // Create a copy of the object at the specified index

        obj.mask = event.target.value;

        list[index] = obj; // Update the object in the copied array
        setMaskArray(list);
        setIsEditDuplicate([]);
        // console.log(event.target.value);
        setUpdatedMask({ "index": index, "mask": obj.mask, "uuid": uuid });
    }

    const onDeleteMask = (uuid: string) => {
        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        dispatch<any>(removeMask(tenantGroupId, uuid));
    }

    const onSubmitPort = () => {
        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        dispatch<any>(submitPort(tenantGroupId, portInput));
    }

    const onSelectPort = (e: any) => {
        setPortInput(e.target.value);
    }

    const onSubmitLongNumber = () => {
        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        let req = {
            "longNumber": longNumberInput
        }
        dispatch<any>(submitLongNumber(tenantGroupId, req));
    }

    const onChangeActiveInactive = (uuid: string) => {
        console.log("press active or inactive: " + uuid);
        const newState = maskArray.map((obj: any) => {
            if (obj.uuid === uuid) {
                if (obj.status == 'Active') {
                    console.log("change to inactive");
                    if (Array.isArray(maskActiveList) && maskActiveList.includes(uuid)) {
                        console.log("uuid exist in the active list");
                        const newActiveList = maskActiveList.filter(item => item !== uuid);
                        console.log("newActiveList: " + newActiveList);
                        setMaskActiveList(newActiveList);
                    } else {
                        if (Array.isArray(maskInactiveList) && !maskInactiveList.includes(uuid)) {
                            console.log("uuid doesn't exist in the inactive list");
                            setMaskInactiveList([...maskInactiveList, uuid]);
                        }
                    }
                    return { ...obj, status: 'Inactive' };
                } else {
                    console.log("change to active");
                    if (Array.isArray(maskInactiveList) && maskInactiveList.includes(uuid)) {
                        console.log("uuid exist in the inactive list");
                        const newInactiveList = maskInactiveList.filter(item => item !== uuid);
                        console.log("newInactiveList: " + newInactiveList);
                        setMaskInactiveList(newInactiveList);
                    } else {
                        if (Array.isArray(maskActiveList) && !maskActiveList.includes(uuid)) {
                            console.log("uuid doesn't exist in the active list");
                            setMaskActiveList([...maskActiveList, uuid]);
                        }
                    }
                    return { ...obj, status: 'Active' };
                }
            }
            return obj;
        });
        console.log(newState);
        setMaskArray(newState);

        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        console.log("mask uuid: " + uuid + "tenant id: " + tenantGroupId);

    }

    const onPressViewAll = () => {
        setViewAllClickCount(viewAllClickCount + 1);
        setViewListLimit(viewListLimit + 5);
        if (viewAllClickCount > 1) {
            setShowAll(true);
        } else if (viewListLimit + 5 >= maskArray.length) {
            setShowAll(true);
        }
        if (showAll) {
            setViewListLimit(maskArray.length);
        }
    }

    const onPressSelect = (uuid: string) => {
        var list = JSON.parse(JSON.stringify(maskArray));

        if (Array.isArray(maskInactiveList)) {
            list.forEach((element: any) => {
                if (element.uuid === uuid) {
                    element.isDefault = true;
                    setDefaultMaskReq(uuid);
                } else if (element.isDefault) {
                    element.isDefault = false;
                }
            });
        }
        setMaskArray(list);
    }

    const onChangeNumberStatus = (e: any, paymentList: any, key: number) => {
        var payListVar = numberList;

        setPrimaryNumberChanged(true);
        var updatedArray = payListVar.map((obj: any, index: number) => {

            //if number is primary, change to secondary on edit
            if (obj.isPrimary === true) {
                return { ...obj, isPrimary: false }
            }

            //set isPrimary on the changed index
            if (index === key) {
                // Create a new object with the updated isPrimary property
                return { ...obj, isPrimary: e.target.value === "Primary" ? true : false };
            }

            return obj; // Keep other objects unchanged
        });
        console.log("obj", updatedArray);
        setNumberList(updatedArray);
        setEditPaymentIndex(key);
    }

    return (
        <React.Fragment>
            <div className="sub-nav-inner-container__wrapper">
                <div className="sub-nav-inner-container content-holder">
                    <div className="content-holder-header__wrapper">
                        <div className="content-holder-header">
                            <h4 className="inner-title">Manage Numbers</h4>
                        </div>
                    </div>
                    <div className="card panel content-holder-panels">
                        <div className="panel-header">
                            <div className="panel-header-content__wrapper">
                                <h4 className="panel-header-title">Your Numbers</h4>
                                <p className="panel-header-subtitle">
                                Both primary and secondary numbers accepted for Add to Bill. (non-invoice enterprises only)
                                </p>
                            </div>
                        </div>
                        {/* panel table */}
                        <div className="panel-table-listing__wrapper">
                            <div className="panel-table-listing">
                                <div className="panel-table-listing-header__wrapper">
                                    <div className="panel-table-listing-header">
                                        <div className="panel-table-listing-header__inner">
                                            <p>
                                                <strong>{Array.isArray(numberList) ? numberList.length : '0'} Total</strong>
                                            </p>
                                        </div>
                                        <div className="panel-table-listing-header__inner">
                                            <div className="form-group__wrapper">

                                                <div className="form-group">
                                                    <div className="form-element__wrapper ">

                                                        <div className="icon-inside left">
                                                            <span className="in-icon"><i className="bi bi-search"></i></span>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id=""
                                                                placeholder="Search by keywords"
                                                                spellCheck="false"
                                                                data-ms-editor="true"
                                                                value={msisdnSearchText}
                                                                onChange={(e) => setMsisdnSearchText(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel-table-content-list">
                                    {/* item */}
                                    {Array.isArray(numberList) && numberList.map((value, index) => {
                                        return (
                                            <div className="panel-table-list-item" key={index}>
                                                <div className="panel-table-listing-item__inner">
                                                    <div className="feature-details__wrapper">
                                                        <div className="card app-media-holder orange-shade sm-center-icon-card">
                                                            <span className="icon__wrapper">
                                                                <i className="bi bi-sim-fill" />
                                                            </span>
                                                        </div>
                                                        <div className="feature-details-title__wrapper no-shade">
                                                            {msisdnMode === 'edit' ?
                                                                (updatedMsisdnStatus.length == 0 || (updatedMsisdnStatus[index] !== 'pending' && updatedMsisdnStatus[index] !== 'error') || editRemainingTime == 0) ? <InputTextFieldWithButton
                                                                    name={value.uuid + '/' + index}
                                                                    placeholder='07xxxxxxxx'
                                                                    value={value.msisdn}
                                                                    setValue={editMsisdn}
                                                                    validator={validator}
                                                                    rules={['required', 'dialog']}
                                                                    disabled={updatedMsisdnStatus[index] == 'verified'}
                                                                    status={(updatedMsisdnStatus[index] == 'verified') ? "verified" : "notVerified"}
                                                                    // status={""}
                                                                    validationMessage={(updatedMsisdnStatus[index] == 'verified') ? "Verified!" : ''}
                                                                    // validationMessage={""}
                                                                    buttonLable="Verify"
                                                                    showButton={(updatedMsisdnStatus[index] == 'verified') ? false : true}
                                                                    onClickFunc={() => sendUpdatedMobileOtp(value.uuid, index, value.msisdn)}
                                                                    buttonDisabled={updatedMsisdnStatus.includes('pending')}
                                                                    forceUpdate={forceUpdate}
                                                                    specificClasses={"form-element__wrapper form-element__wbtn right "}
                                                                    preValueCheck={true}
                                                                    showRemainingTime={!(updatedMsisdnStatus.length == 0 || (updatedMsisdnStatus[index] !== 'pending' && updatedMsisdnStatus[index] !== 'error'))}
                                                                    remainingTime={editRemainingTime}
                                                                />
                                                                    : <InputTextFieldWithButton
                                                                        name={value.uuid + '/' + index}
                                                                        placeholder='xxxxxx'
                                                                        value={otp.pin}
                                                                        setValue={handleUpdateOtpChange}
                                                                        validator={validator}
                                                                        rules={['numeric', 'max:6']}
                                                                        disabled={false}
                                                                        status={(msisdnStatus == 'verified') ? "verified" : "notVerified"}
                                                                        // status={""}
                                                                        validationMessage={(msisdnStatus == 'verified') ? "Verified!" : (msisdnStatus == 'error') ? "Invalid OTP!" : ""}
                                                                        // validationMessage={""}
                                                                        buttonLable="Submit"
                                                                        showButton={true}
                                                                        onClickFunc={verifyMobileOtp}
                                                                        buttonDisabled={false}
                                                                        forceUpdate={forceUpdate}
                                                                        specificClasses={"form-element__wrapper form-element__wbtn right "}
                                                                        preValueCheck={true}
                                                                        showRemainingTime={!(updatedMsisdnStatus.length == 0 || (updatedMsisdnStatus[index] !== 'pending' && updatedMsisdnStatus[index] !== 'error'))}
                                                                        remainingTime={editRemainingTime}
                                                                    />
                                                                : <><span className="title">{value.msisdn}</span>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                {msisdnMode === 'view' && <div className="panel-table-listing-item__inner">
                                                    <div className="panel-table-right-align-item__wrapper">
                                                        <div className="panel-table-right-align-item">
                                                            <div className="form-group__wrapper"></div>
                                                        </div>
                                                        <div className="panel-table-right-align-item">
                                                            {msisdnMode === 'view' && <p className="">
                                                                <i className={value.isPrimary ? "bi bi-1-circle-fill" : "bi bi-2-circle-fill"} />
                                                                &nbsp;{value.isPrimary ? "Primary" : "Secondary"}
                                                            </p>}
                                                        </div>
                                                        <div className="panel-table-right-align-item">
                                                            <span className="step-status-badge">
                                                                <span className="status-icon__wrapper txt-green">
                                                                    {value.status === "Active" ? <i className="status-icon__wrapper txt-green bi bi-check-circle-fill" /> : value.status === "Pending" ? <i className="status-icon__wrapper txt-yellow bi bi-exclamation-circle-fill" /> : value.status === "Rejected" ? <i className="status-icon__wrapper txt-red bi bi-x-circle-fill" />:""}
                                                                </span>
                                                                {value.status === "Active" ? "In use" : value.status === "Pending" ? "Pending" : value.status === "Rejected"? "Rejected" :""}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>}
                                                {(msisdnMode === 'edit') &&
                                                    <div className="panel-table-listing-item__inner">
                                                        <div className="panel-table-right-align-item__wrapper">
                                                            <div className="panel-table-right-align-item">
                                                                {msisdnMode === 'edit' && <div className="form-group__wrapper">
                                                                    <div className="form-group">
                                                                        <div className="form-element__wrapper ">
                                                                            <div className="icon-inside right">
                                                                                {/* <span className="in-icon"><i className="bi bi-chevron-down" /></span> */}
                                                                                {/* <input type="text" className="form-control" id="" placeholder="" spellCheck="false" data-ms-editor="true" defaultValue={paymentDetails.isPrimary ? " Primary" : " Secondary"} /> */}
                                                                                <select className="form-control" value={value.isPrimary ? "Primary" : "Secondary"} onChange={(e) => onChangeNumberStatus(e, value, index)} disabled={value.status === "Pending" ? true : value.status === "Rejected" ? true : false}>
                                                                                    <option value={"Primary"}>Primary</option>
                                                                                    <option value={"Secondary"} disabled={value.isPrimary}>Secondary</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {/* {(msisdnMode === 'edit') && <div className="panel-table-listing-item__inner">
                                                    <div className="panel-table-right-align-item__wrapper ">
                                                        <div className="panel-table-right-align-item">
                                                            {!value.isPrimary && !primaryNumberChanged && <a className="txt-red" type="button" onClick={() => onDeleteNumber(value.uuid)}  >
                                                                <i className="bi bi-trash-fill" />
                                                                &nbsp;Remove
                                                            </a>}
                                                        </div>
                                                    </div>
                                                </div>} */}
                                            </div>
                                        )
                                    })}
                                    {msisdnMode === 'add' && <div className="panel-table-list-item">
                                        <div className="panel-table-listing-item__inner">
                                            <div className="feature-details__wrapper">
                                                <div className="card app-media-holder green-shade sm-center-icon-card">
                                                    <span className="icon__wrapper">
                                                        <i className="bi bi-sim-fill" />
                                                    </span>
                                                </div>
                                                <div className="feature-details-title__wrapper no-shade">
                                                    {(msisdnStatus == '' || msisdnStatus == 'verified' || addRemainingTime == 0) ? <InputTextFieldWithButton
                                                        name='msisdn'
                                                        placeholder='07xxxxxxxx'
                                                        value={newMsisdn}
                                                        setValue={handleMsisdnChange}
                                                        validator={validator}
                                                        rules={['required', 'dialog']}
                                                        disabled={msisdnStatus == 'verified'}
                                                        status={(msisdnStatus == 'verified') ? "verified" : "notVerified"}
                                                        // status={""}
                                                        validationMessage={(msisdnStatus == 'verified') ? "Verified!" : ''}
                                                        // validationMessage={""}
                                                        buttonLable="Verify"
                                                        showButton={(msisdnStatus == 'verified') ? false : true}
                                                        onClickFunc={sendMobileOtp}
                                                        buttonDisabled={false}
                                                        forceUpdate={forceUpdate}
                                                        specificClasses={"form-element__wrapper form-element__wbtn right "}
                                                        preValueCheck={true}
                                                        showRemainingTime={!(msisdnStatus == '' || msisdnStatus == 'verified')}
                                                        remainingTime={addRemainingTime}
                                                    /> : <InputTextFieldWithButton
                                                        name='otp'
                                                        placeholder='xxxxxx'
                                                        value={otp.pin}
                                                        setValue={handleOtpChange}
                                                        validator={validator}
                                                        rules={['numeric', 'max:6']}
                                                        disabled={false}
                                                        status={(msisdnStatus == 'verified') ? "verified" : "notVerified"}
                                                        // status={""}
                                                        validationMessage={(msisdnStatus == 'verified') ? "Verified!" : (msisdnStatus == 'error') ? "Invalid OTP!" : ""}
                                                        // validationMessage={""}
                                                        buttonLable="Submit"
                                                        showButton={true}
                                                        onClickFunc={verifyMobileOtp}
                                                        buttonDisabled={false}
                                                        forceUpdate={forceUpdate}
                                                        specificClasses={"form-element__wrapper form-element__wbtn right "}
                                                        preValueCheck={true}
                                                        showRemainingTime={!(msisdnStatus == '' || msisdnStatus == 'verified')}
                                                        remainingTime={addRemainingTime}
                                                    />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                    {/* / item */}
                                    {/* item */}
                                    <div className="panel-table-list-item">
                                        {msisdnMode == 'view' ? <div className="panel-table-listing-item__inner">
                                            <button
                                                className="btn btn-primary btn-xsmall"
                                                onClick={() => {
                                                    setMsisdnMode('add')
                                                    setOtp({ ...otp, pin: '' })
                                                }}
                                            ><i className="bi bi-plus-lg"></i>&nbsp;
                                                Add New Number
                                            </button>
                                            <button
                                                className="btn btn-primary btn-xsmall"
                                                onClick={() => {
                                                    setMsisdnMode('edit')
                                                    setOtp({ ...otp, pin: '' })
                                                }}
                                                disabled={!(Array.isArray(numberList) && numberList.length > 0)}
                                            ><i className="bi bi-pencil-fill"></i>&nbsp;
                                                Edit Number
                                            </button>
                                        </div> : <div className="panel-bottom-btn__wrapper">
                                            <button className="btn btn-secondary" onClick={() => {
                                                setMsisdnMode('view');
                                                setMsisdnStatus('');
                                                setNewMsisdn('');
                                                setNumberList(msisdnList);
                                                setPrimaryNumberChanged(false);
                                            }}>Cancel</button>
                                            <button className="btn btn-primary" onClick={onSaveMsisdn} disabled={false}>Save &amp; Update</button>
                                        </div>}
                                    </div>
                                    {/* / item */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* panel table */}
                    {/* list of mask*/}
                    <div className="card panel content-holder-panels">
                        <div className="panel-header">
                            <div className="panel-header-content__wrapper">
                                <h4 className="panel-header-title">Masks</h4>
                                <p className="panel-header-subtitle">List of masks </p>
                            </div>
                        </div>
                        {/* panel table */}
                        <div className="panel-table-listing__wrapper">
                            <div className="panel-table-listing">
                                <div className="panel-table-listing-header__wrapper">
                                    <div className="panel-table-listing-header">
                                        <div className="panel-table-listing-header__inner">
                                            <p>
                                                <strong>{Array.isArray(maskArray) ? maskArray.length : '0'} Total</strong>
                                            </p>
                                        </div>
                                        <div className="panel-table-listing-header__inner">
                                            <div className="form-group__wrapper">

                                                <div className="form-group">
                                                    <div className="form-element__wrapper ">

                                                        <div className="icon-inside left">
                                                            <span className="in-icon"><i className="bi bi-search"></i></span>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id=""
                                                                placeholder="Search by keywords"
                                                                spellCheck="false"
                                                                data-ms-editor="true"
                                                                value={maskSearchText}
                                                                onChange={(e) => setMaskSearchText(e.target.value)}
                                                                disabled={maskMode == 'add' ? true : false}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel-table-content-list">
                                    {/* item */}
                                    {/* {selectedLongNumber != null && <div className="panel-table-list-item">
                                        <div className="panel-table-listing-item__inner">
                                            <div className="feature-details__wrapper">
                                                <div
                                                    className="card app-media-holder red-shade sm-center-icon-card">
                                                    <span className="icon__wrapper"><i
                                                        className="bi bi-back"></i></span>
                                                </div>
                                                <div className="feature-details-title__wrapper no-shade">
                                                    <span className="title">{selectedLongNumber}</span>
                                                    <span className="small-desc">Primary Number</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                    {selectedPort != null && <div className="panel-table-list-item">
                                        <div className="panel-table-listing-item__inner">
                                            <div className="feature-details__wrapper">
                                                <div
                                                    className="card app-media-holder red-shade sm-center-icon-card">
                                                    <span className="icon__wrapper"><i
                                                        className="bi bi-back"></i></span>
                                                </div>
                                                <div className="feature-details-title__wrapper no-shade">
                                                    <span className="title">{selectedPort}</span>
                                                    <span className="small-desc">PORT</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="panel-table-right-align-item__wrapper">
                                            <div className="panel-table-right-align-item">
                                                <div className="form-group__wrapper">

                                                    <div className="form-group">
                                                        <div className="form-element__wrapper ">

                                                            <div className="icon-inside right">
                                                                <span className="in-icon"><i className="bi bi-chevron-down"></i></span>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id=""
                                                                    placeholder=""
                                                                    spellCheck="false"
                                                                    data-ms-editor="true"
                                                                    defaultValue="Primary"
                                                                    autoComplete="off"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>} */}
                                    {Array.isArray(maskArray) && maskArray.slice(0, viewListLimit).map((value, index) => {
                                        // console.log("$$$$$$$$$$$$$", index, isEditDuplicate[index]);
                                        return (
                                            <div className="panel-table-list-item" key={index}>
                                                <div className="panel-table-listing-item__inner">
                                                    <div className="feature-details__wrapper">
                                                        <div className="card app-media-holder red-shade sm-center-icon-card">
                                                            <span className="icon__wrapper">
                                                                <i className="bi bi-back" />
                                                            </span>
                                                        </div>
                                                        <div className="feature-details-title__wrapper no-shade">
                                                            {/* {maskMode == 'edit' ? <InputTextField
                                                                name={index.toString() + '/' + value.uuid}
                                                                placeholder='MASK'
                                                                value={value.mask}
                                                                setValue={editMask}
                                                                validator={validator}
                                                                rules={['required', { max: 10 }]}
                                                                disabled={false}
                                                                status="default"
                                                                validationMessage=""
                                                                forceUpdate={forceUpdate}
                                                                specificClasses={"form-element__wrapper"}
                                                                onBlurValue={onBlurMaskEdit}
                                                            /> : */}
                                                            <span className="title">{decodeURIComponent(value.mask)}</span>
                                                            {value.maskType == 'MSISDN' && <span className="small-desc">Primary Number</span>}
                                                            {value.maskType == 'PORT' && <span className="small-desc">PORT</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                                {((value.isDefault && maskMode == 'view') || (maskMode == 'edit' && value.maskType == 'USER') && value.status == 'Active') && <div className="panel-table-right-align-item__wrapper">
                                                    <div className="panel-table-right-align-item">
                                                        <div className="form-group__wrapper">

                                                            <div className="form-group">
                                                                <div className="form-element__wrapper mb-3 ">
                                                                    <div className="icon-inside right">
                                                                        <select className="form-control" disabled={maskMode == 'view'} onChange={() => onPressSelect(value.uuid)}>
                                                                            {maskMode == 'edit' && !value.isDefault && <option disabled selected > -- select an option -- </option>}
                                                                            <option selected={maskMode == 'view' && value.isDefault} value='primary'>Primary</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}
                                                {(maskMode == 'edit' && value.isDefault && (value.maskType == 'PORT' || value.maskType == 'MSISDN')) && <div className="panel-table-right-align-item__wrapper">
                                                    <div className="panel-table-right-align-item">
                                                        <div className="form-group__wrapper">

                                                            <div className="form-group">
                                                                <div className="form-element__wrapper mb-3 ">
                                                                    <div className="icon-inside right">
                                                                        <select className="form-control" disabled>
                                                                            <option selected={true} value='primary'>Primary</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}
                                                <div className="panel-table-listing-item__inner">
                                                    {maskMode === 'edit' && <div className="panel-table-right-align-item__wrapper">
                                                        {isEditDuplicate[index] == false ? <div className="panel-table-right-align-item">
                                                            <p>
                                                                <i className="bi bi-check-lg txt-green" />
                                                                &nbsp;Available to use
                                                            </p>
                                                        </div> : isEditDuplicate[index] == true ?
                                                            <div className="panel-table-right-align-item">
                                                                <p>
                                                                    <i className="bi bi-check-lg txt-red" />
                                                                    &nbsp;Not available, Try different key words or
                                                                    combination
                                                                </p>
                                                            </div> : <></>}
                                                        {/* {JSON.stringify(isEditDuplicate[index])} */}
                                                    </div>}
                                                </div>
                                                <div className="panel-table-listing-item__inner">
                                                    <div className="panel-table-right-align-item__wrapper">
                                                        <div className="panel-table-right-align-item">
                                                            <div className="form-group__wrapper"></div>
                                                        </div>
                                                        <>
                                                            {value.status === "Active" ? <div className="panel-table-right-align-item">
                                                                <span className="step-status-badge">
                                                                    <span className="status-icon__wrapper txt-green">
                                                                        <i className="bi bi-check-circle-fill" />
                                                                    </span>
                                                                    In use
                                                                </span>
                                                            </div> : value.status === "Inactive" ? <div className="panel-table-right-align-item">
                                                                <span className="step-status-badge">
                                                                    <span className="status-icon__wrapper txt-red">
                                                                        <i className="bi bi-x-circle-fill" />
                                                                    </span>
                                                                    Inactive
                                                                </span>
                                                            </div> : value.status === "Rejected" ? <div className="panel-table-right-align-item">
                                                                <span className="step-status-badge">
                                                                    <span className="status-icon__wrapper txt-red">
                                                                        <i className="bi bi-x-circle-fill" />
                                                                    </span>
                                                                    Rejected
                                                                </span>
                                                            </div> : <div className="panel-table-right-align-item">
                                                                <span className="step-status-badge">
                                                                    <span className="status-icon__wrapper txt-orange">
                                                                        <i className="bi bi-exclamation-circle-fill" />
                                                                    </span>
                                                                    Approval Pending
                                                                </span>
                                                            </div>}
                                                        </>
                                                        {maskMode === 'edit' && value.status !== "Pending" && value.status !== "Rejected" && !value.isDefault && <div className="panel-table-right-align-item">
                                                            <div className="form-group__wrapper">
                                                                <div className="form-group">
                                                                    <label className="switch">
                                                                        <input type="checkbox" onChange={() => onChangeActiveInactive(value.uuid)} checked={value.status == 'Active'} />
                                                                        <span className="slider round" />
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>}
                                                        {/* {value.status !== "Pending" && <div className="panel-table-right-align-item">
                                                            <div className="form-group__wrapper">
                                                                <div className="form-group">
                                                                    <label className="switch">
                                                                        {value.status === "Active" ? <input type="checkbox" onChange={() => onPressInactive(value.uuid)} checked /> : <input type="checkbox" onChange={() => onPressActive(value.uuid)} />}
                                                                        <span className="slider round" />
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>} */}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    {maskMode === 'add' && <div className="panel-table-list-item">
                                        <div className="panel-table-listing-item__inner">
                                            <div className="feature-details__wrapper">
                                                <div className="card app-media-holder yellow-shade sm-center-icon-card">
                                                    <span className="icon__wrapper">
                                                        <i className="bi bi-back" />
                                                    </span>
                                                </div>
                                                <div className="feature-details-title__wrapper no-shade">
                                                    <div className="form-element__wrapper">
                                                        <input
                                                            // type={type}
                                                            className="form-control"
                                                            name='mask'
                                                            placeholder='MASK'
                                                            value={newMask}
                                                            onChange={e => {
                                                                // console.log("$$$$$$$$$$$$$$$", e.target.value);

                                                                handleNewMask('mask')(e);
                                                                validator.showMessageFor('mask');

                                                                forceUpdate();
                                                                onBlurMaskAdd(e);
                                                            }}
                                                            // onBlur={onBlurMaskAdd}
                                                            required={true}
                                                            disabled={false}
                                                        />
                                                        <div className="validation invalid-feedback">{validator.message('mask', newMask, ['required', 'mask', { min: 3 }, { max: 11 }])}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="panel-table-listing-item__inner">
                                            <div className="panel-table-right-align-item__wrapper ">
                                                {(newMask != '' && validator.check(newMask, ['required', 'mask', { min: 3 }, { max: 11 }]) && newMask.length > 2 && newMask.length < 12 && isDuplicate == false) ? <div className="panel-table-right-align-item">
                                                    <p>
                                                        <i className="bi bi-check-lg txt-green" />
                                                        &nbsp;Available to use
                                                    </p>
                                                </div> : isDuplicate == true ?
                                                    <div className="panel-table-right-align-item">
                                                        <p>
                                                            <i className="bi bi-check-lg txt-red" />
                                                            &nbsp;Not available, Try different key words or
                                                            combination
                                                        </p>
                                                    </div> : <></>}
                                            </div>
                                        </div>
                                    </div>}
                                    {/* / item */}
                                    {/* item */}
                                    {!showAll && <div className="panel-table-list-item">
                                        <div className="panel-table-listing-item__inner">
                                            <a href="#" onClick={onPressViewAll}>View more</a>
                                        </div>
                                    </div>}
                                    <div className="panel-table-list-item">

                                        {maskMode == 'view' ? <div className="panel-table-listing-item__inner">
                                            <button
                                                onClick={() => setMaskMode('add')}
                                                className="btn btn-primary btn-xsmall">
                                                <i className="bi bi-plus-lg" />
                                                &nbsp;Add New Mask
                                            </button>
                                            <button
                                                onClick={() => setMaskMode('edit')}
                                                className="btn btn-primary btn-xsmall">
                                                <i className="bi bi-pencil-fill" />
                                                &nbsp;Edit Masks
                                            </button>
                                        </div> : <div className="panel-bottom-btn__wrapper">
                                            <button className="btn btn-secondary" onClick={() => {
                                                setMaskMode('view');
                                                setMaskStatus('');
                                                setNewMask('');
                                                const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
                                                dispatch<any>(fetchMaskList(tenantGroupId, maskSearchText));
                                            }}>
                                                Cancel
                                            </button>
                                            <button
                                                className="btn btn-primary"
                                                onClick={onSaveMask}
                                                disabled={((maskMode === 'add' && isDuplicate !== true && !(validator.check(newMask, ['required', 'mask', { min: 3 }, { max: 11 }]))) ? true : false) || (maskMode === 'edit' && (updatedMask ? (isEditDuplicate[updatedMask.index] === true || isEditDuplicate[updatedMask.index] === null) : false) ? true : false)}
                                            // disabled={(maskMode === 'add' || maskMode === 'edit') && (isDuplicate === true || isDuplicate === null || isEditDuplicate[updatedMask.index] === true)}
                                            >Save &amp; Update
                                            </button>
                                        </div>}
                                    </div>
                                    {/* / item */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* panel table */}
                    {/* SMS Short code */}
                    <div className="card panel content-holder-panels">
                        <div className="featured-panel-content__wrapper">
                            <div className="featured-panel-content">
                                <div className="featured-panel-content-text__wrapper">
                                    <div className="featured-panel-content-text">
                                        <h5 className="main-title mt-bg" style={{fontSize: '16px'}}>What is an SMS Mask?</h5>
                                        <div className="featured-panel-content-text-desc__wrapper">
                                            <p>An SMS mask, also known as a sender ID or number mask, is a distinct identifier displayed when a corporate sends SMS to customers. It serves as a branding element, allowing customization for a recognizable name or number, enhancing brand visibility, trust, and facilitating a professional communication channel for promotional or informational SMS campaigns. </p>
                                            <p>
                                            For example, a business using the SMS mask "BRAND123" ensures that recipients immediately identify the sender. This customization enhances brand visibility and fosters trust, establishing a professional communication channel for effective promotional or informational SMS campaigns.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="featured-panel-content-cover__wrapper">
                                    <div
                                        className="featured-panel-content-cover"
                                        style={{
                                            backgroundImage:
                                                `url(${MskImg})`
                                        }}
                                    ></div>
                                </div>
                            </div>
                        </div>
                        <div className="featured-panel-content__wrapper">
                            <div className="featured-panel-content">
                                <img
                                    src={require("../../assets/images/featured-cover-sms-mask-explaining.png")}
                                    alt="Explanation about what exacly sms mask "
                                    srcSet=""
                                />
                            </div>
                        </div>
                    </div>
                    {/* /SMS Short code */}
                    {/* SMS Short code */}
                    <div className="card panel content-holder-panels">
                        <div className="featured-panel-content__wrapper">
                            <div className="featured-panel-content">
                                <div className="featured-panel-content-text__wrapper">
                                    <div className="featured-panel-content-text">
                                        <h4 className="main-title mt-bg" style={{fontSize: '16px'}}>SMS short code</h4>
                                        <div className="featured-panel-content-text-desc__wrapper">
                                            <p>What is an SMS Short Code?</p>
                                            <p>SMS Short Code is a condensed numeric identifier, SMS Short Code streamlines communication, especially for interactive campaigns. Businesses use it to efficiently connect with their audience, facilitating engagement and information retrieval.</p>
                                            <p>For example, a company might utilize the SMS Short Code "56789" for customers to easily interact, participate in promotions, or receive targeted information through a quick text exchange. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="featured-panel-content-cover__wrapper">
                                    <div
                                        className="featured-panel-content-cover"
                                        style={{
                                            backgroundImage:
                                                `url(${PortImg})`
                                        }}
                                    ></div>
                                </div>
                            </div>
                        </div>
                        <div className="featured-panel-content__wrapper">
                            <div className="featured-panel-content">
                                {!selectedPort && <div className="form-group__wrapper">
                                    <div className="form-group">
                                        <div className="form-element__wrapper mb-3">
                                            <label htmlFor="" className="form-label">
                                                SMS Short Code
                                            </label>
                                            <div className="icon-inside right">
                                                {/* <span className="in-icon">
                                                    <i className="bi bi-chevron-down" />
                                                </span> */}
                                                <select className='form-control' value={portInput} onChange={(val)=>onSelectPort(val)} >
                                                    {Array.isArray(portList) && portList.map((value, index) => {
                                                        return (
                                                            <option value={value.port} key={index}>{value.port}</option>
                                                        )
                                                    })}
                                                </select>
                                                {/* <button className='btn btn-primary' onClick={onSubmitPort}>Submit</button> */}

                                            </div>
                                        </div>
                                        <div className="panel-bottom-btn__wrapper">
                                            <button
                                                className="btn btn-primary"
                                                onClick={onSubmitPort}
                                            >Save &amp; Update
                                            </button>
                                        </div>
                                    </div>
                                </div>}
                                {selectedPort && <div className="clipboard-item__wrapper">
                                    <label htmlFor="" className="form-label">
                                        Added SMS Short Code
                                    </label>
                                    <div className="clipboard-item">
                                        <span>{selectedPort ? selectedPort : 'No port selected'}</span>
                                        <button className="btn btn-xsmall">
                                            <i className="bi bi-clipboard-check-fill" />
                                        </button>
                                    </div>
                                </div>}
                                {!selectedPort && <div className="alert info">
                                    <span className="alert-text__wrapper">
                                        <i className="alert-icon bi bi-info-circle-fill" />
                                        <span className="alert-text">
                                            Note - The SMS short code cannot be changed once it has been selected.
                                        </span>
                                    </span>
                                </div>}
                            </div>
                        </div>
                    </div>
                    {/* /SMS Short code */}
                    {/* Long number */}
                    <div className="card panel content-holder-panels">
                        <div className="featured-panel-content__wrapper">
                            <div className="featured-panel-content">
                                <div className="featured-panel-content-text__wrapper">
                                    <div className="featured-panel-content-text">
                                        <h4 className="main-title mt-bg" style={{fontSize: '16px'}}>Long Number</h4>
                                        <div className="featured-panel-content-text-desc__wrapper">
                                            <p>What is a Long Number? </p>
                                            <p>
                                            A "Long Number" refers to a complete phone number, usually in its standard format, capable of receiving SMS messages. Unlike short codes, long numbers are full phone numbers that offer businesses a dedicated and recognizable channel for two-way communication with customers, often used for various applications such as customer support, surveys, and contests.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="featured-panel-content-cover__wrapper">
                                    <div
                                        className="featured-panel-content-cover"
                                        style={{
                                            backgroundImage:
                                                `url(${LongNumImg})`
                                        }}
                                    ></div>
                                </div>
                            </div>
                        </div>
                        <div className="featured-panel-content__wrapper">
                            <div className="featured-panel-content">
                                {!selectedLongNumber && <div className="form-group__wrapper">
                                    <div className="form-group">
                                        <div className="form-element__wrapper mb-3">
                                            <label htmlFor="" className="form-label">
                                                Long Number
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id=""
                                                placeholder="0777*********"
                                                value={longNumberInput}
                                                onChange={(e) => setLongNumberInput(e.target.value)}
                                                spellCheck="false"
                                                data-ms-editor="true"
                                            />
                                            <div className="validation invalid-feedback">{validator.message('Long number', longNumberInput, 'required|dialog')}</div>
                                        </div>
                                        <div className="panel-bottom-btn__wrapper">
                                            <button
                                                className="btn btn-primary"
                                                onClick={onSubmitLongNumber}
                                            >Save &amp; Update
                                            </button>
                                        </div>
                                    </div>
                                </div>}
                                {selectedLongNumber && <div className="clipboard-item__wrapper">
                                    <label htmlFor="" className="form-label">
                                        Added Long Number
                                    </label>
                                    <div className="clipboard-item">
                                        <span>{selectedLongNumber}</span>
                                        <button className="btn btn-xsmall">
                                            <i className="bi bi-clipboard-check-fill" />
                                        </button>
                                    </div>
                                </div>}
                                {!selectedLongNumber && <div className="alert info">
                                    <span className="alert-text__wrapper">
                                        <i className="alert-icon bi bi-info-circle-fill" />
                                        <span className="alert-text">
                                            Note - Configured long number will be given by the Marketplace team. Please contact Marketplace team for more information.
                                        </span>
                                    </span>
                                </div>}
                            </div>
                        </div>
                    </div>
                    {/* /Long numbre */}
                </div>
                {/*  list of mask */}
            </div>
        </React.Fragment>
    );
}

export default NumberConfigures;