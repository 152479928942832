import React from 'react'

type Props = {
    title: string,
    description: string,
    image: string
}

const InnerTopFeatureItem = ({ title, description, image }: Props) => {
    return (
        <div className="inner-top-feature-item">
            <div className="card app-media-holder" style={{ backgroundImage: `url(${image})` }}>
            </div>
            <div className="inner-top-feature-item-content">
                <h4>{title}</h4>
                <p>{description}</p>
            </div>
        </div>
    )
}

export { InnerTopFeatureItem }