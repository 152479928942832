import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthSliceModel {
    smonfigList: any[],
    loading: boolean,
    success: boolean,
    error: boolean,
    requestDetails: any,
    requestSubmitError: boolean,
    requestSubmitLoading: boolean,
    requestSubmitSuccess: boolean,
    requestSubmitErrorMessage: string,
    smRatePlan: any;
}


const socialMediaStore = createSlice({
    name: "sm-store",
    initialState: {
        smonfigList: [],
        loading: false,
        success: false,
        error: false,
        requestDetails: null,
        requestSubmitError: true,
        requestSubmitLoading: false,
        requestSubmitSuccess: false,
        requestSubmitErrorMessage: "",
        smRatePlan: null
    } as AuthSliceModel,
    reducers: {
        smConfigFetch: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        },
        smConfigFetchSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                smConfigList: action.payload,
                success: true,
                error: false
            }
        },
        smConfigFetchFail: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                smConfigList: []
            }
        },
        smRequestPost: (state) => {
            return {
                ...state,
                requestSubmitError: false,
                requestSubmitLoading: true,
                requestSubmitSuccess: false,
            };
        },
        smRequestPostSuccess: (state) => {
            return {
                ...state,
                requestSubmitError: false,
                requestSubmitLoading: false,
                requestSubmitSuccess: true,
            };
        },
        smRequestPostFail: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                requestSubmitError: true,
                requestSubmitLoading: false,
                requestSubmitSuccess: false,
                requestSubmitErrorMessage: action.payload,
            };
        },
        smRequestDetailFetch: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        },
        smRequestDetailFetchSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                requestDetails: action.payload,
                success: true,
                error: false
            }
        },
        smRequestDetailFetchFail: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                requestDetails: null
            }
        },
        smRatePlanFetchSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                smRatePlan: action.payload,
            }
        },
    },
});

export default socialMediaStore;