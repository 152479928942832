import axiosInstance from "../axios-instance";
import * as CONSTANTS from "../constants";

const smConfigFetch = (tenantId: string, widgetId: string) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widget/socialMedia/get-all-configs/${tenantId}/${widgetId}`
    });
};

const smRequestPost = (req: any, tenantId: string, widgetId: string) => {
    return axiosInstance({
        method: "post",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widget/socialMedia/post-new-request/${tenantId}/${widgetId}`,
        data: req
    });
};

const smSingleConfigFetch = (tenantId: string, configId: string) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widget/socialMedia/get-single-config/${tenantId}/${configId}`
    });
};

const getSingleConfigRatePlan = (tenantId: string, configId: string) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widget/socialMedia/get-selected-rate-plans/${tenantId}/${configId}`
    });
};

export {
    smConfigFetch,
    smRequestPost,
    smSingleConfigFetch,
    getSingleConfigRatePlan
}