import kycStore from "../reducers/kyc.store";
import {
    filterKycList,
    getKYCList,
    getKycConfigs,
    getKycEvents,
    getKycListForCsv,
    getKycUsernames,
    postAppSubscription,
    postKycConfigs,
    getKycRedisStatus,
} from "../../services/kyc.service";
import { KycFilterModel } from "../../pages/apps/kyc/models/request/kyc-filter.model";
import { KycConfigModel } from "../../pages/apps/kyc/models/request/kyc-config.model";
import { NavigateFunction } from "react-router-dom";
import { SingleAppModel } from "../../pages/single-app/models/response/single-app.model";
import { onRedirectTo } from "./app-wizard.action";

export const fetchKycList = (tenantId: string, widgetId: string): any => {
    return (dispatch: any) => {
        dispatch(kycStore.actions.kycListFetch());
        getKYCList(tenantId, widgetId)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(kycStore.actions.kycListFetchSuccess(response.data.data));
                }
            })
            .catch((error) => {
                dispatch(kycStore.actions.kycListFetchFail(error));
            });
    }
};

export const fetchFilterKycList = (tenantId: string, widgetId: string, req: KycFilterModel): any => {
    return (dispatch: any) => {
        filterKycList(tenantId, widgetId, req)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(kycStore.actions.kycListFilterSuccess(response.data.data));
                }
            })
            .catch((error) => {
                dispatch(kycStore.actions.kycListFilterFail(error));
            });
    }
};

export const fetchKycUsernames = (tenantId: string): any => {
    return (dispatch: any) => {
        getKycUsernames(tenantId)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(kycStore.actions.kycUsernamesFetchSuccess(response.data.data));
                }
            })
            .catch((error) => {
                dispatch(kycStore.actions.kycUsernamesFetchFail(error));
            });
    }
};

export const fetchKycEvents = (tenantId: string): any => {
    return (dispatch: any) => {
        getKycEvents(tenantId)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(kycStore.actions.kycEventsFetchSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(kycStore.actions.kycEventsFetchFail(error));
            });
    }
};

export const fetchKycListForCsv = (tenantId: string, widgetId: string, req: KycFilterModel): any => {
    return (dispatch: any) => {
        getKycListForCsv(tenantId, widgetId, req)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(kycStore.actions.kycCsvSuccess(response.data.data));
                }
            })
            .catch((error) => {
                dispatch(kycStore.actions.kycCsvFail(error));
            });
    }
};

export const fetchKycConfigs = (tenantId: string, widgetId: string): any => {
    return (dispatch: any) => {
        getKycConfigs(tenantId, widgetId)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(kycStore.actions.kycConfigsFetchSuccess(response.data.data));
                }
            })
            .catch((error) => {
                dispatch(kycStore.actions.kycConfigsFetchFail(error));
            });
    }
};

export const submitKycConfigs = (tenantId: string, widgetId: string, req: KycConfigModel, navigate: NavigateFunction, appDetails: SingleAppModel | null | any, callBackend: any | null): any => {
    return (dispatch: any) => {
        postKycConfigs(tenantId, widgetId, req)
            .then((response) => {
                if (response.status === 200) {
                    onRedirectTo(tenantId, navigate, appDetails, dispatch, callBackend);
                    dispatch(kycStore.actions.kycConfigsPostSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(kycStore.actions.kycConfigsPostFail(error));
            });
    }
};

export const submitKYCAppSubscription = (tenantId: string, widgetId: string, prvId: string): any => {
    return (dispatch: any) => {
        postAppSubscription(tenantId, widgetId, prvId)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(kycStore.actions.kycSubscriptionPostSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(kycStore.actions.kycSubscriptionPostFail(error));
            });
    }
};

export const fetchKycRedisStatus = (uuid: string): any => {
    return (dispatch: any) => {
        getKycRedisStatus(uuid)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(kycStore.actions.fetchKycRedisSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(kycStore.actions.fetchKycRedisFailed(error));
            });
    }
};