import _ from 'lodash';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../store';
import Auth from '../../models/response/auth.model';
import { AppWizardModel } from './models/response/app-wizard.model';
import { fetchAllOffers, fetchAppList, fetchBusinessTypeDetails, fetchBusinessTypeTopApps, fetchSearchResults } from '../../store/actions';
import BusinessType1 from '../../assets/images/business-type-wiz-feature-1.svg';
import { WidgetItem } from './components/WidgetItem';

type Props = {
    auth: Auth
}

const BusinessTypeApps = ({ auth }: Props) => {
    const { businessType } = useParams();
    const dispatch = useAppDispatch();

    const [appsList, setAppList] = useState<Array<any>>([]);
    const [topAppsLimit, setTopAppsLimit] = useState<number>(6);
    const [topAppsOffset, setTopAppsOffset] = useState<number>(0);

    const [limit, setLimit] = useState<number>(250);
    const [offset, setOffset] = useState<number>(0);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [hasSearchResults, setHasSearchResults] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchText, setSearchText] = useState("");

    const {
        businessDetails,
        allWidgets,
        topWidgets
    } = useSelector<any, any>(({ appWizard }) => appWizard);

    const {
        authData
    } = useSelector<any, any>(({ authentication }) => authentication);

    const {
        allOffersList
    } = useSelector<any, any>(({ offers }) => offers);

    const {
        appList
    } = useSelector<any, any>(({ settings }) => settings);

    const {
        userDetails
    } = useSelector<any, any>(({ user }) => user);


    const navigate = useNavigate();

    useEffect(() => {
        if (searchTerm == "") {
            dispatch(fetchBusinessTypeDetails(businessType!, searchTerm !== '' ? searchTerm : null, limit, offset));
        }
    }, [offset, searchTerm]);

    useEffect(() => {
        dispatch(fetchBusinessTypeTopApps(businessType!, topAppsLimit, topAppsOffset));
    }, [topAppsOffset]);

    const onSearchWidget = () => {
        setOffset(0);
        dispatch(fetchBusinessTypeDetails(businessType!, searchTerm !== '' ? searchTerm : null, limit, offset));
    }

    useEffect(() => {
        let route = window.location.pathname.split('/')[1];
        if (route == 'apps' && authData) {
            navigate(`/discover/apps/${businessType}`);
        }
    }, []);

    useEffect(() => {
        const tenantGroupId = (userDetails && userDetails?.OwnedEnterprises[0]) ? userDetails?.OwnedEnterprises[0].groupId : "";
        dispatch(fetchAllOffers(userDetails && userDetails?.uuid, tenantGroupId));
    }, [userDetails]);

    useEffect(() => {
        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        if (tenantGroupId !== "" && searchText == "") {
            dispatch<any>(fetchAppList(tenantGroupId, searchText !== "" ? searchText : '0', limit, offset, appList));
        }
    }, [userDetails, offset, searchText]);

    return (
        <>
            <div className="main-content">
                <div className="inner-top-feature-holder"
                    style={{ backgroundImage: `url(${BusinessType1})` }} aria-label="">
                    <div className="container">
                        <button className="btn back-btn" aria-label="Back to the previous page" onClick={() => navigate(-1)}>
                            <span></span> Go back
                        </button>
                        <div className="inner-feature-content">
                            <h1 className="app-title" itemProp="name" aria-label="App title">{businessDetails?.businessType}</h1>
                            <span className="app-desc">{businessDetails?.longDescription}</span>
                        </div>
                    </div>
                </div>
                <div className="container inner-container">
                    <div className="feature-search-bar card">
                        <div className="primary-searchbar__wrapper">
                            <input
                                type="text"
                                className="form-control primary-searchbar lg"
                                id=""
                                placeholder="Search by app name"
                                spellCheck={false}
                                data-ms-editor={true}
                                onChange={(e) => {
                                    let val = e.target.value;
                                    if (val === "") {
                                        setOffset(0);
                                    }
                                    setSearchTerm(val);
                                }}
                                value={searchTerm}
                                onKeyDown={event => {
                                    if (event.key === 'Enter') {
                                        onSearchWidget();
                                    }
                                }} />
                        </div>
                    </div>
                    {/* {searchTerm == '' && <div className="app-listing-section__wrapper type-2">
                        <div className="app-listing-section-title__wrapper">
                            <span>Top Apps</span>
                        </div>
                        <div className="app-listing-section not-at-right type-2-inner">
                            {Array.isArray(topWidgets) && topWidgets.map((value: any, index: number) => {
                                if (Array.isArray(value) && value.length > 0) {
                                    return (
                                        <WidgetItem
                                            key={index}
                                            widgetDetails={value[0]}
                                        />
                                    )
                                }
                            })}
                        </div>
                        {Array.isArray(topWidgets) && topWidgets.length > 5 && <div className="app-listing-section-footer">
                            <button className="btn btn-primary" onClick={() => setTopAppsOffset(topAppsOffset + 6)}>View more</button>
                        </div>}
                    </div>} */}
                </div>
                <div className="app-listing-section__wrapper type-4 full-bleed__wrapper light">
                    <div className="container full-bleed">
                        <div className="app-listing-section__wrapper type-2">
                            {/* <div className="app-listing-section-title__wrapper">
                                <span>All Apps</span>
                            </div> */}
                            <div className="app-listing-section not-at-right type-2-inner">
                                {Array.isArray(allWidgets) && (allWidgets.filter((item: any) => item.featured == true)).map((value: any, index: number) => {
                                    const hasOffer = Array.isArray(allOffersList?.allOffersWidgetDetailsList) && allOffersList?.allOffersWidgetDetailsList.some((offer: { uuid: string | null; }) => offer.uuid === value.uuid);
                                    const hasSubscribed = Array.isArray(appList) && appList.some((appId: { uuid: string | null; }) => appId.uuid === value.uuid);

                                    if (value) {
                                        return (
                                            <WidgetItem
                                                key={index}
                                                widgetDetails={value}
                                                hasOffer={hasOffer}
                                                hasSubscribed={hasSubscribed}
                                            />
                                        )
                                    }
                                })}
                            </div>
                            {Array.isArray(allWidgets) && allWidgets.length > 5 && <div className="app-listing-section-footer">
                                <button className="btn btn-primary" onClick={() => setOffset(offset + 6)}>View more</button>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BusinessTypeApps;