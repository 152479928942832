import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FeatureAppModel } from "../../pages/discover/models/response/app-wizard.model";
import { SingleAppModel } from "../../pages/single-app/models/response/single-app.model";
import { BusinessTypes } from "../../pages/discover/models/response/business-types.model";

interface AuthSliceModel {
    featureAppListDetails: Array<any> | null | FeatureAppModel;
    categoryList: Array<any> | null;
    loading: boolean,
    topAppsList: Array<any> | null;
    allApps: Array<any> | null;
    trendingApps: Array<any> | null;
    paymentMsisdnList: Array<any>;
    genieCardList: Array<any>;
    appSubLoading: boolean,
    workhubSubLoading: boolean;
    businessTypes: BusinessTypes[];
    businessDetails: any;
    allWidgets: any[];
    topWidgets: any[];
    searchResults: Array<any>;
    hasMoreData: boolean;
}

const appWizardStore = createSlice({
    name: "app-store",
    initialState: {
        featureAppListDetails: null,
        categoryList: [],
        loading: false,
        topAppsList: [],
        allApps: [],
        trendingApps: [],
        paymentMsisdnList: [],
        genieCardList: [],
        appSubLoading: false,
        workhubSubLoading: false,
        businessTypes: [],
        businessDetails: null,
        allWidgets: [],
        topWidgets: [],
        searchResults: [],
        hasMoreData: true
    } as AuthSliceModel,
    reducers: {
        requestPending: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true
            }
        },
        getAppDetailsSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            console.log('getAppDetailsSuccess-reducer', action.payload)
            return {
                ...state,
                featureAppListDetails: action.payload,
                loading: false
            }
        },
        getAppDetailsFailed: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false
            }
        },
        getCategorySuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                categoryList: action.payload,
                loading: false
            }
        },
        getCategoryFailed: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false
            }
        },
        getTopAppsSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                topAppsList: action.payload,
                loading: false
            }
        },
        getTopAppsFailed: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false
            }
        },
        getAllWidgetsSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                allApps: action.payload,
                loading: false
            }
        },
        getAllWidgetsFailed: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false
            }
        },
        getTrendingSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                trendingApps: action.payload,
                loading: false
            }
        },
        getTrendingFailed: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false
            }
        },
        getPaymentMsisdnListSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                paymentMsisdnList: action.payload,
                loading: false
            }
        },
        getPaymentMsisdnListFailed: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false
            }
        },
        getGenieCardListSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                genieCardList: action.payload,
                loading: false
            }
        },
        getGenieCardListFailed: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false
            }
        },
        postAppSubLoading: (state: AuthSliceModel) => {
            return {
                ...state,
                appSubLoading: true
            }
        },
        postAppSubSuccess: (state: AuthSliceModel) => {
            return {
                ...state,
                appSubLoading: false
            }
        },
        postAppSubFailed: (state: AuthSliceModel) => {
            return {
                ...state,
                appSubLoading: false
            }
        },
        postWorkhubSubLoading: (state: AuthSliceModel) => {
            return {
                ...state,
                appSubLoading: false
            }
        },
        postWorkhubSubSuccess: (state: AuthSliceModel) => {
            return {
                ...state,
                appSubLoading: false
            }
        },
        postWorkhubSubFailed: (state: AuthSliceModel) => {
            return {
                ...state,
                appSubLoading: false
            }
        },
        postC2PSubLoading: (state: AuthSliceModel) => {
            return {
                ...state,
                appSubLoading: true
            }
        },
        postC2PSubSuccess: (state: AuthSliceModel) => {
            return {
                ...state,
                appSubLoading: false
            }
        },
        postC2PSubFailed: (state: AuthSliceModel) => {
            return {
                ...state,
                appSubLoading: false
            }
        },
        getBusinessTypesSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
          return {
            ...state,
            businessTypes: action.payload
          }
        },
        getBusinessTypesFailed: (state: AuthSliceModel) => {
          return {
            ...state,
            businessTypes: []
          }
        },
        getBusinessTypeDetailsSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            const updatedData = action.payload.offset === 0 ? action.payload.data.widgets : [...state.allWidgets, ...action.payload.data.widgets];
          return {
            ...state,
            businessDetails: action.payload.data.businessType,
            allWidgets: updatedData
          }
        },
        getBusinessTypeDetailsFailed: (state: AuthSliceModel) => {
          return {
            ...state,
            businessDetails: null,
            allWidgets: []
          }
        },
        getBusinessTypeTopAppsSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            const updatedData = action.payload.offset === 0 ? action.payload.data : [...state.topWidgets, ...action.payload.data];
          return {
            ...state,
            topWidgets: updatedData
          }
        },
        getBusinessTypeTopAppsFailed: (state: AuthSliceModel) => {
          return {
            ...state,
            topWidgets: []
          }
        },
        postBetterHrSubLoading: (state: AuthSliceModel) => {
            return {
                ...state,
                appSubLoading: true
            }
        },
        postBetterHrSubSuccess: (state: AuthSliceModel) => {
            return {
                ...state,
                appSubLoading: false
            }
        },
        postBetterHrSubFailed: (state: AuthSliceModel) => {
            return {
                ...state,
                appSubLoading: false
            }
        },
        getSearchResults: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
              ...state,
              searchResults: action.payload.filterdArray,
              hasMoreData: action.payload.hasMoreData
            }
          },
    },
});

export default appWizardStore;