import React, { useEffect, useState } from 'react'
import eventThumbnail from "../../../assets/images/feature-video-thumbnail.png";
import event1 from "../../../assets/images/event1.jpg"
import event2 from "../../../assets/images/event2.jpg"
import featureImg from "../../../assets/images/bg-dashboard-feature-img.svg"
import AuthHandler from '../../../helper/AuthHandler';
import { Stats } from './Stats';
import { useSelector } from 'react-redux';
import SmileyRatingPopup from '../../../components/SmileyRatingPopup';
import { Link } from 'react-router-dom';


const Events = ({ }) => {

  const openExternalURLInNewTab = (url: string) => {
    window.open(url, '_blank');
  };

  const [isEmailExpired, setIsEmailExpired] = useState(true);

  const {
    authData
  } = useSelector<any, any>(({ authentication }) => authentication);

  const currentDate = new Date();
  const millisecondsInMonth = 1000 * 60 * 60 * 24 * 30;

  const { userDetails, isSatisfactionScoreSubmitted } = useSelector<any, any>(({ user }) => user);

  const redirectToSettings = (): string => {
    return '/settings/business-details/true';
  }

  useEffect(() => {
    if (userDetails && userDetails.OwnedEnterprises[0].Enterprise.isEmailVerified) {
      setIsEmailExpired(false)
    }
  }, [userDetails])

  return (
    <div className="db-grid-1__wrapper">
      {!isSatisfactionScoreSubmitted && userDetails && userDetails.createdAt && ((currentDate.getTime() - new Date(userDetails.createdAt).getTime()) / millisecondsInMonth) &&
        <SmileyRatingPopup />
      }
      <div className="db-grid-inner__wrapper db-grid-2__wrapper">
        <div className="sub-nav-inner-container stats-widgets__wrapper">
          <Stats />
          <div className="stats-widget-item__wrapper">
            {/*<div className="stats-widget-item card">*/}
            {/*      <span className="stats-title">Next Payment due</span>*/}
            {/*      <span className="stats-data"><span className="stats-unit">Rs.</span>98680.00</span>*/}
            {/*      <span className="stats-peak">*/}
            {/*          <span className="icon__wrapper"> <i*/}
            {/*              className="bi bi-arrow-down txt-green"></i></span>+12% lower than last*/}
            {/*          month*/}
            {/*      </span>*/}
            {/*</div>*/}
          </div>
        </div>

        <div className="alert info">
          <span className="alert-text__wrapper">
            <i className="alert-icon bi bi-info-circle-fill" />
            <span className="alert-text">
              Note: The calculation of 'Previous month's data' will involve manual intervention and is expected to take a few days. Thank you for your cooperation.
            </span>
          </span>
        </div>
        {isEmailExpired && <div className="alert warning">
          <span className="alert-text__wrapper">
            <i className="alert-icon bi bi-info-circle-fill" />
            <span className="alert-text">
              Pending Email Verification Reminder: Your email verification is due every 3 months to ensure the security and accuracy of your account. Please check your Account Settings and verify your email address promptly to maintain uninterrupted access to our services. <Link to={redirectToSettings()}>Click here</Link> to access Settings.
            </span>
          </span>
        </div>}
        <h4>Highlights</h4>
        <div className="card panel">
          <div className=" feature-content-style">
            <div className="feature-content-style-thumbnail" style={{ backgroundImage: `url(${require('../../../assets/images/dialog-innovation-challenge-lg.jpg')})` }} />
            <div className="feature-content-style-content">
              <div className="feature-content-style-content-title">Dialog Innovation Challenge</div>
              <div className="feature-content-style-content-desc">  Welcome to the Dialog Innovation Challenge! Showcase and develop digital solutions that matter to you, your business, and your communities. Whether an idea or a working prototype, register now!</div>
              <button className="btn btn-inline link-blue" onClick={() => openExternalURLInNewTab('https://ic.dialog.lk/')}>View more info <span /></button>
            </div>
          </div>
        </div>
        <div className="card panel">
          <div className=" feature-content-style">
            <div className="feature-content-style-thumbnail" style={{ backgroundImage: `url(${require('../../../assets/images/event_02.jpg')})` }} />
            <div className="feature-content-style-content">
              <div className="feature-content-style-content-title">Challenges of becoming a ‘Telco-as-a-Platform.</div>
              <div className="feature-content-style-content-desc">Join the TM Forum discussion featuring Dialog Axiata PLC's Group Chief Information Officer, delving into challenges and key considerations of evolving into a 'Telco-as-a-Platform.' Engage on pivotal topics like API empowerment, customer segmentation, content monetization, and platform federation</div>
              <span>
                <button className="btn btn-inline link-blue" onClick={() => openExternalURLInNewTab('https://www.linkedin.com/feed/update/urn:li:activity:7133726325680300032/')}>LinkedIn <span /></button>
                <button className="btn btn-inline link-blue" onClick={() => openExternalURLInNewTab(' https://inform.tmforum.org/dtw/dtw/journey-towards-becoming-a-telco-as-a-platform-anthony-rodrigo?utm_medium=Social&utm_source=linkedin.com&utm_campaign=DTW23')}>TM forum <span /></button>
              </span>
            </div>
          </div>
        </div>
        {/* <h4>Events</h4>
                              <div class="card panel">
                                <div class=" feature-content-style">
                                  <div class="feature-content-style-thumbnail" style="background-image: url(/src/assets/images/feature-video-thumbnail.png);"></div>
                                  <div class="feature-content-style-content">
                                      <div class="feature-content-style-content-title">Title goes here</div>
                                      <div class="feature-content-style-content-desc">Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit distinctio, quas quibusdam voluptates eveniet eaque qui sed veniam pariatur doloribus beatae ducimus possimus quam sapiente ratione, similique dicta non provident.</div>
                                      <button class="btn btn-inline link-blue">Explore more solutions <span></span></button>
                                  </div>
                                </div>
                              </div>
  
                              <div class="card panel">
                                  <div class=" feature-content-style">
                                    <div class="feature-content-style-thumbnail" style="background-image: url(/src/assets/images/feature-video-thumbnail.png);"></div>
                                    <div class="feature-content-style-content">
                                        <div class="feature-content-style-content-title">Title goes here</div>
                                        <div class="feature-content-style-content-desc">Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit distinctio, quas quibusdam voluptates eveniet eaque qui sed veniam pariatur doloribus beatae ducimus possimus quam sapiente ratione, similique dicta non provident.</div>
                                        <button class="btn btn-inline link-blue">Explore more solutions <span></span></button>
                                      </div>
                                  </div>
                                </div> */}
        {/* <div className="card panel content-holder-panels">
              <div className="panel-header">
                <div className="panel-header-content__wrapper">
                  <h4 className="panel-header-title">Ongoing Campaigns</h4>
                  <p className="panel-header-subtitle">Resently listed or finiished campaigns</p>
                </div>
              </div>
              <div className="panel-content">
                asas
              </div>
            </div> */}
      </div>
      <div className="db-grid-inner__wrapper db-grid-2__wrapper">
        <img src={featureImg} alt="" srcSet="" className="mob-d-none" />
        {/* <div class="card sp-styled-type-1 bg-type-2">
                                  <div class="stats-container__wrapper">
                                     <div class="card"><i class="bi bi-bar-chart-fill"></i></div>
                                     <div class="stats-details-content__wrapper">
                                      <span class="stats-title">Expenace Amount(Rs.)</span>
                                      <span class="stats-data">98680.00</span>
                                     </div>
                                  </div>
                                  <div class="stats-container__wrapper">
                                      <div class="card"><i class="bi bi-bar-chart-fill"></i></div>
                                      <div class="stats-details-content__wrapper">
                                       <span class="stats-title">Taxes(Rs.)</span>
                                       <span class="stats-data">100000.00</span>
                                      </div>
                                   </div>
                                   <div class="stats-container__wrapper">
                                      <div class="card"><i class="bi bi-bar-chart-fill"></i></div>
                                      <div class="stats-details-content__wrapper">
                                       <span class="stats-title">Total Expeses(Rs.)</span>
                                       <span class="stats-data">100000.00</span>
                                      </div>
                                   </div>
                                  </div> */}
        {/* <div className="card panel content-holder-panels">
              <div className="panel-header">
                <div className="panel-header-content__wrapper">
                  <h4 className="panel-header-title">Expenase as percentage by each app</h4>
                  <p className="panel-header-subtitle">Profit sharing and earning over the period of last 3 months</p>
                </div>
              </div>
              <div className="panel-content">
                chart pluging that plan on using
                https://nivo.rocks/components/
              </div>
            </div>
            <div className="card panel content-holder-panels">
              <div className="panel-header">
                <div className="panel-header-content__wrapper">
                  <h4 className="panel-header-title">Expenase as percentage by each app</h4>
                  <p className="panel-header-subtitle">Profit sharing and earning over the period of last 3 months</p>
                </div>
              </div>
              <div className="panel-content">
                chart pluging that plan on using
                https://nivo.rocks/components/
              </div>
            </div>      */}
      </div>
    </div>
  )
}

export { Events }