import React, { lazy, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Empty, Form, message, Modal, Row, Spin } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import {
    commonPostDispatch,
    dynamicLoadingStop,
    dynamicWidgetLoading,
    fetchOneConfig,
    getDynamicWidget,
    resetDynamicRedux,
    fetchSingleApp
} from "../../../store/actions";
import AuthHandler from "../../../helper/AuthHandler";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { PageHeaderDynamicFormComponent } from '../components/PageHeaderDynamicForm.component';
import * as Constants from '../../../constants';
import { useAppDispatch } from '../../../store';

// import sample from './sample-json/sample-widget-all.json';

const { confirm } = Modal;
const importView = (componentName) =>
    lazy(() =>
        import(`./component/${componentName}`).catch(() => import(`./component/WidgetComponent`))
    );

const DynamicWidget = (props) => {
    const { wid } = useParams();

    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const { userDetails } = useSelector(({ user }) => user);
    const {
        singleAppDetails
    } = useSelector(({ apps }) => apps);
    const { commonFormSubmittingLoading, widgetLoading, dynamicWidget } = useSelector(({ dynamicForm }) => dynamicForm);
    const [widget, setWidget] = useState(true);
    const [views, setViews] = useState([]);
    const [viewVal, setViewVal] = useState(null);
    const navigate = useNavigate();
    const paths = window.location.pathname.split('/');

    useEffect(() => {
        if(userDetails) {
            const tenantId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
            if(tenantId) {
                dispatch(fetchSingleApp(wid, tenantId));
            }
        }
    }, [userDetails])


    useEffect(() => {
        const path = window.location.pathname.split('/');
        let customizedWidgetBody = typeof dynamicWidget?.widgetBody === 'string' ? JSON.parse(dynamicWidget?.widgetBody) : dynamicWidget?.widgetBody;
        // console.log("@@@@@@@@@@@@@@@@", customizedWidgetBody);
        const formattedRoute = customizedWidgetBody?.formGetUrl?.startsWith('/api/') ? customizedWidgetBody?.formGetUrl.slice(4) : customizedWidgetBody?.formGetUrl;

        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";

        if ((path[Constants.BASEURLPATHLENGTH + 3] === 'view' || path[Constants.BASEURLPATHLENGTH + 3] === 'update') && singleAppDetails && singleAppDetails.widgetDetails && formattedRoute) {
            fetchOneConfig(tenantGUID, singleAppDetails.widgetDetails.uuid, path[Constants.BASEURLPATHLENGTH + 4], formattedRoute).then(response => {
                // console.log("############", response.data);
                setViewVal(typeof response.data === 'string' ? JSON.parse(response.data) : response.data);
                dispatch(dynamicLoadingStop());
            }).catch(error => {
                message.error("Error in getting widget info");
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dynamicWidget, userDetails]);

    useEffect(() => {
        dispatch(resetDynamicRedux());
        dispatch(dynamicWidgetLoading());
        const type = window.location.pathname.split('/')[Constants.BASEURLPATHLENGTH + 3];
        if (singleAppDetails && singleAppDetails.widgetDetails) {
            dispatch(getDynamicWidget(singleAppDetails.widgetDetails.uuid, type))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleAppDetails]);

    useEffect(() => {
        if (dynamicWidget) {
            const widgetBody = typeof dynamicWidget.widgetBody === 'string' ? JSON.parse(dynamicWidget.widgetBody) : dynamicWidget.widgetBody;
            // const widgetBody = sample;
            setWidget(widgetBody);
            const components = widgetBody.component.map(data => {
                const View = importView(data.componentName);
                return <View key={Math.random()} inputData={data} form={form} viewVal={viewVal}
                    loading={commonFormSubmittingLoading} userDetails={userDetails} />;
            });

            setViews(components);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dynamicWidget, viewVal]);

    const onFinish = values => {
        const formattedRoute = widget.formSubmitUrl?.startsWith('/api/') ? widget.formSubmitUrl.slice(4) : widget.formSubmitUrl;
        if (widget.confirmationMessage) {
            confirm({
                icon: <ExclamationCircleOutlined />,
                content: widget.confirmationMessage,
                onOk() {
                    values['widgetTitle'] = dynamicWidget.widgetTitle;
                    const tenantId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
                    dispatch(commonPostDispatch(values, formattedRoute, tenantId, singleAppDetails.widgetDetails.uuid, widget.redirectToList, singleAppDetails.widgetDetails.signupUrl, navigate));
                    if (widget.formResetFields && widget.formResetFields.length > 0) {
                        // console.log("!!!!!!!!!", widget);
                        form.resetFields(widget.formResetFields);
                    } else {
                        // console.log("$$$$$$$$$$$$");
                        form.resetFields();
                    }
                },
                cancelText: widget.confirmationCancelText,
                okText: widget.confirmationOkText
            });
        } else {
            values['widgetTitle'] = dynamicWidget.widgetTitle;
            const tenantId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
            dispatch(commonPostDispatch(values, formattedRoute, tenantId, singleAppDetails.widgetDetails.uuid, widget.redirectToList, singleAppDetails.widgetDetails.signupUrl, navigate));
            if (widget.formResetFields && widget.formResetFields.length > 0) {
                form.resetFields(widget.formResetFields);
            } else {
                form.resetFields();
            }
        }
    }

    return (
        <React.Fragment>
            <Spin
                size="large"
                // spinning={widgetLoading}
                spinning={false}
            >
                {
                    AuthHandler.isAuthorized(props.auth, widget.screenLevelAccess) && userDetails !== null ?
                        <div className="main-content main-spacer-x-y">
                            <PageHeaderDynamicFormComponent
                                page={'create'}
                            />
                            <div className="card added-app-thumbnnail__wrapper">
                                <div
                                    className="added-app-thumbnail__inner"
                                    style={{
                                        backgroundImage: `url(${(singleAppDetails && singleAppDetails.widgetDetails !== null) ? singleAppDetails.widgetDetails.leftImage : ""})`
                                    }}
                                ></div>
                            </div>
                            <div className="card panel content-holder-panels added-app-form__wrapper">
                                <div className="panel-header">
                                    <div className="panel-header-content__wrapper">
                                        <h4 className="panel-header-title">{widget.widgetTitle}</h4>
                                    </div>
                                </div>
                                <div className="panel-content">
                                    <div className="wiz-content-form__wrapper">
                                        {widget.widgetDescription2 ?
                                            <p>
                                                {widget.widgetDescription}&nbsp;
                                                {widget.widgetDescriptionLink && <a target="_blank" href={widget.widgetDescriptionLink}>{widget.widgetDescriptionLinkText}</a>}&nbsp;
                                                {widget.widgetDescription2}
                                            </p> :
                                            <p>
                                                {widget.widgetDescription}&nbsp;
                                                {widget.widgetDescriptionLink && <a target="_blank" href={widget.widgetDescriptionLink}>{widget.widgetDescriptionLinkText}</a>}&nbsp;
                                            </p>
                                        }
                                        <br />
                                        <Form
                                            form={form}
                                            layout={widget.formLayout}
                                            onFinish={onFinish}
                                            size={widget.formsElementSize}
                                            scrollToFirstError
                                        >
                                            <Row gutter={[0, 0]}>
                                                <Col className="gutter-row" xs={24} sm={24} md={24}
                                                    lg={24}
                                                    xl={24}>
                                                    {
                                                        dynamicWidget ?
                                                            <React.Suspense fallback="Loading views...">
                                                                {views}
                                                            </React.Suspense>
                                                            :
                                                            <Empty
                                                                image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                    }
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </div>

                            </div>
                        </div>
                        :
                        <>Access denied</>
                }
            </Spin>
        </React.Fragment>
    );
}
    ;

export default DynamicWidget;