import React, { useState, useEffect } from "react";
import Auth from "../../models/response/auth.model";
import Keycloak from 'keycloak-js';
import { useNavigate, useParams } from "react-router-dom";
import { updateGenieCardDetails } from "../../services/settings.service";
// import { ClipLoader } from "react-spinners";
// import { UploadOutlined, UserOutlined, VideoCameraOutlined, WindowsFilled } from '@ant-design/icons';
// import { Layout, Menu, Skeleton, Space, Breadcrumb, message } from 'antd';
// import { updateGenieCardDetails } from '../services/genie.service';
// import CONSTANTS from "../constants";
// import {useHistory, useParams} from "react-router-dom";
// import { createBrowserHistory } from 'history';
// import { css } from "@emotion/core";

// const { Header, Content, Footer, Sider } = Layout;

// const override = css`
//   display: block;
//   margin: 0 auto;
// `;

type Props = {
    auth: Auth;
    keycloak: Keycloak | undefined;
}

const PaymentCheckout = ({ auth, keycloak }: Props) => {

    const navigate = useNavigate();
    const { code } = useParams();

    useEffect(() => {
        callGenieSaveCardDetails();
    }, []);


    const callGenieSaveCardDetails = async () => {
        const tenantGUID = window.location.pathname.split('/')[3];
        const params = new URLSearchParams(window.location.search);
        let invoiceNumber = params.get('invoiceNumber')
        if (invoiceNumber) {
            let res = await updateGenieCardDetails(tenantGUID, invoiceNumber);
            let status = (res.status === 200 && res.data) ? true : false;
            // navigate({pathname: `/ent_profile/${tenantGUID}/card`,
            //     state: {cardSaveStatus: status} });
            navigate('/settings/payment', { state: { cardSaveStatus: status } });
        }
    }

    return (
        <section className="content__wrapper">
            <div className="primaryBrandLoaderBg">
                <div className="primaryBrandLoader">
                    <img src={require('../../assets/images/loader-bg-primary.svg').default} alt="" srcSet="" />
                </div>
            </div>
        </section>
    )
}

export default PaymentCheckout;