import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchBusinessRegistrationDocuments,
    fetchEnterpriseDocs,
    fetchProfileIdentificationImages,
    fetchSignedDocuments,
} from "../../store/actions";
import ProfileIdentificationImage from "./components/ProfileIdentificationImage";
import SignedDocument from "./components/SignedDocument";
import NoData from "./components/NoData";
import BRDocumentEdit from "./components/BRDocumentEdit";

function DocumentAndTerms() {
    const settings = useSelector((state: any) => state.settings);
    const dispatch = useDispatch();

    const [showContent , setShowContent] = useState(false);
    
    useEffect(() => {
        const reqBody = {};
        const uuid = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";;
        dispatch(fetchProfileIdentificationImages(reqBody));
        dispatch(fetchBusinessRegistrationDocuments(reqBody));
        dispatch(fetchSignedDocuments(reqBody));
        console.log("documentsNTerms: ", uuid)
        dispatch<any>(fetchEnterpriseDocs(uuid));
    }, []);

    const {
        userDetails
    } = useSelector<any, any>(({ user }) => user);

    return (
        <div className="sub-nav-inner-container__wrapper">
            <div className="sub-nav-inner-container content-holder">
                <div className="content-holder-header__wrapper">
                    <div className="content-holder-header">
                        <h4 className="inner-title">Documents &amp; Terms</h4>
                    </div>
                </div>
                {/* file attached table*/}
                <div className="card panel content-holder-panels">
                    <div className="panel-header">
                        <div className="panel-header-content__wrapper">
                            <h4 className="panel-header-title">Profile Identification</h4>
                            <p className="panel-header-subtitle">
                                National ID Image or related documents for Profile Verification.
                            </p>
                        </div>
                    </div>
                    {/* panel table */}
                    <div className="panel-table-listing__wrapper">
                        <div className="panel-table-listing">
                            {(!settings.enterpriseDocs || settings.enterpriseDocs.length === 0) && <NoData></NoData>}
                            {settings.enterpriseDocs && settings.enterpriseDocs.length !== 0 && (
                                <div className="panel-table-content-list">
                                    {/* item */}
                                    {settings.enterpriseDocs
                                        .filter((item: any) => item.fieldName === "ownerNICCopy")
                                        .map((item: any, index: number) => {
                                            return (
                                                <ProfileIdentificationImage
                                                    key={index}
                                                    file={item.image}
                                                    imageUrl={item.imageUrl}
                                                />
                                            );
                                        })}
                                    {/* / item */}
                                    {/* item */}
                                    {/*<div className="panel-table-list-item">*/}
                                    {/*    <div className="panel-table-listing-item__inner">*/}
                                    {/*        <button className="btn btn-primary btn-xsmall">*/}
                                    {/*            <i className="bi bi-pencil-fill" />*/}
                                    {/*            &nbsp;Edit or update files*/}
                                    {/*        </button>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/* / item */}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {/*  file attached table */}
                {/* file attached table*/}
                <div className="card panel content-holder-panels">
                    <div className="panel-header">
                        <div className="panel-header-content__wrapper">
                            <h4 className="panel-header-title">
                                Business Registration Documents
                            </h4>
                            <p className="panel-header-subtitle">
                                Business Registration Documents
                            </p>
                        </div>
                    </div>
                    {/* panel table */}
                    <div className="panel-table-listing__wrapper">
                        <div className="panel-table-listing">
                            {(!settings.enterpriseDocs || settings.enterpriseDocs.length === 0) && <NoData></NoData>}
                                {settings.enterpriseDocs && settings.enterpriseDocs.length !== 0 && (
                                    <div className="panel-table-content-list">
                                        {/* item */}
                                        {settings.enterpriseDocs
                                        .filter((item: any) => item.fieldName.includes("entBussinessRegDoc"))
                                        .map((item: any, index: number) => {
                                            return (
                                                <ProfileIdentificationImage
                                                    key={index}
                                                    file={item.image}
                                                    imageUrl={item.imageUrl}
                                                />
                                            );
                                        })}
                                        {/* / item */}
                                        {/* item */}
                                        {/*<div className="panel-table-list-item">*/}
                                        {/*    <div className="panel-table-listing-item__inner">*/}
                                        {/*        <div className="panel-bottom-btn__wrapper">*/}
                                        {/*            <button className="btn btn-secondary">Cancel</button>*/}
                                        {/*            <button className="btn btn-primary">*/}
                                        {/*                Save &amp; Update*/}
                                        {/*            </button>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {/* / item */}
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
                {/*  file attached table */}
                {/* file attached table*/}
                {/* <div className="card panel content-holder-panels">
                    <div className="panel-header">
                        <div className="panel-header-content__wrapper">
                            <h4 className="panel-header-title">
                                Signed Documents &amp; Terms
                            </h4>
                            <p className="panel-header-subtitle">Signed Document</p>
                        </div>
                    </div>
                    {/* panel table */}
                    {/* <div className="panel-table-listing__wrapper">
                        <div className="panel-table-listing">
                            {mockSignedDocuments.length == 0 && <NoData></NoData>}
                            {mockSignedDocuments.length != 0 && (
                                <div className="panel-table-content-list">
                                    {/* item */}
                                    {/* {mockSignedDocuments.map((file, index) => {
                                        return (
                                            <SignedDocument
                                                key={index}
                                                name={file.name}
                                                isNew={file.isNew}
                                                isSigned={file.isSigned}
                                                updatedDate={file.updatedDate}
                                            ></SignedDocument>
                                        );
                                    })} */}
                                    {/* / item */}
                                {/* </div>
                            )}
                        </div>
                    </div> */}
                {/* </div> */}
                {/*  file attached table */}
            </div>
        </div>
    );
}

export default DocumentAndTerms;
