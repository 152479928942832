import ScreenLevelAccess from '../screen-level-access';
import TaskLevelAccess from '../task-level-access';
import MenuAccess from '../menu-access';
import Auth from '../models/response/auth.model';

const isAuthorized = (auth: Auth, screen: string) => {
    // console.log("AH auth:", auth)
    // console.log("AH screen:", screen)
    type ObjectKey = keyof typeof ScreenLevelAccess
    const key = screen as ObjectKey
    let requiredRoles = ScreenLevelAccess[key];

    // console.log("AH requiredRoles:", requiredRoles)
    let role;

    if (requiredRoles === undefined || requiredRoles.length < 0) {
        return false;
    }
    for (role of requiredRoles) {
        if (auth?.roles.includes(role)) {
            return true;
        }
    }
    for (role of requiredRoles) {
        if (auth?.realmRoles.includes(role)) {
            return true;
        }
    }
    return false;
}

//this is a temporary method. Use next method
const isPerformable = (auth: Auth, task: string) => {
    type ObjectKey = keyof typeof TaskLevelAccess
    const key = task as ObjectKey
    let validRoles = TaskLevelAccess[key];
    let role;
    if (validRoles === undefined || validRoles.length < 0) {
        return false;
    }    
    for (role of validRoles) {
        if (auth?.roles.includes(role)) {
        return true;
        }
    }
    for (role of validRoles) {
        if (auth?.realmRoles.includes(role)) {
        return true;
        }
    }
    return false;
}

const isAccessible = (auth: Auth, menu: string) => {

    type ObjectKey = keyof typeof MenuAccess
    const key = menu as ObjectKey
    let requiredRoles = MenuAccess[key];

    let role;

    if (requiredRoles === undefined || requiredRoles.length < 0) {
        return false;
    }
    if (auth !== null) {
        for (role of requiredRoles) {
            if (auth?.roles.includes(role)) {
                return true;
            }
        }
    }
    for (role of requiredRoles) {
        if (auth?.realmRoles.includes(role)) {
            return true;
        }
    }
    return false;
}

export default {
    isAuthorized,
    isPerformable,
    isAccessible
}