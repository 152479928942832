import fixedBBStore from "../reducers/fixed-bb-store";
import {
    checkCoverage,
    getAllOrders,
    getDeviceData,
    getPackageData,
    getSingleOrder,
    paymentSubmit,
    postCartOrder,
    requestAdminApproval
} from "../../services/fixed-bb-service";
import {message} from "antd";
import {OrderReqModel} from "../../pages/apps/fixedBB/models/orderReq.model";

export const fetchCoverage = (lat: number, lng: number): any => {
    return (dispatch: any) => {
        dispatch(fixedBBStore.actions.fetchCoverage);
        checkCoverage(lat, lng)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(fixedBBStore.actions.fetchCoverageSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(fixedBBStore.actions.fetchCoverageFail(error));
                message.error("Check coverage failed.");
            });
    }
};

export const fetchPackageDetails = (): any => {
    return (dispatch: any) => {
        dispatch(fixedBBStore.actions.fetchPackageDetails);
        getPackageData()
            .then((response) => {
                if (response.status === 200) {
                    dispatch(fixedBBStore.actions.fetchPackageDetailsSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(fixedBBStore.actions.fetchPackageDetailsFail(error));
                // message.error("Fetch package details failed.");
            });
    }
};

export const fetchDeviceDetails = (traceId: string): any => {
    return (dispatch: any) => {
        dispatch(fixedBBStore.actions.fetchDeviceDetails);
        getDeviceData(traceId)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(fixedBBStore.actions.fetchDeviceDetailsSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(fixedBBStore.actions.fetchDeviceDetailsFail(error));
                // message.error("Fetch device details failed.");
            });
    }
};

export const createOrderRecord = (req: OrderReqModel, tenantGUID: string, widgetUUID: string, traceId: string): any => {
    return (dispatch: any) => {
        dispatch(fixedBBStore.actions.postCartOrder);
        postCartOrder(req, tenantGUID, widgetUUID, traceId)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(fixedBBStore.actions.postCartOrderSuccess(response.data));
                    message.success("Order created successfully.");
                }
            })
            .catch((error) => {
                dispatch(fixedBBStore.actions.postCartOrderFail(error));
                message.error("Order creation failed.");
            });
    }
};

export const fetchAllOrders = (tenantGUID: string, widgetUUID: string): any => {
    return (dispatch: any) => {
        dispatch(fixedBBStore.actions.fetchAllOrders);
        getAllOrders(tenantGUID, widgetUUID)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(fixedBBStore.actions.fetchAllOrdersSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(fixedBBStore.actions.fetchAllOrdersFail(error));
                // message.error("Order creation failed.");
            });
    }
};

export const fetchAnOrder = (tenantGUID: string, widgetUUID: string, orderUUID: string): any => {
    return (dispatch: any) => {
        dispatch(fixedBBStore.actions.fetchSingleOrder);
        getSingleOrder(tenantGUID, widgetUUID, orderUUID)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(fixedBBStore.actions.fetchSingleOrderSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(fixedBBStore.actions.fetchSingleOrderFail(error));
                message.error("Unable to fetch order details.");
            });
    }
};

export const submitForPayment = (req: { orderUuid: string }, tenantGUID: string, widgetUUID: string): any => {
    return (dispatch: any) => {
        dispatch(fixedBBStore.actions.putSubmitPayment);
        paymentSubmit(req, tenantGUID, widgetUUID)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(fixedBBStore.actions.putSubmitPaymentSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(fixedBBStore.actions.putSubmitPaymentFail(error));
                if(error.response.data.error) {
                    message.error(error.response.data.error);
                } else {
                    message.error("Payment failed.");
                }
            });
    }
};

export const requestForInvoice = (req: { orderUuid: string }, tenantGUID: string, widgetUUID: string): any => {
    return (dispatch: any) => {
        dispatch(fixedBBStore.actions.requestAdminApproval);
        requestAdminApproval(req, tenantGUID, widgetUUID)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(fixedBBStore.actions.requestAdminApprovalSuccess(response.data));
                    if(response.data.message) {
                        message.success(response.data.message).then(()=> {
                            window.location.reload();
                        });
                    } else {
                        message.success("Request created.");
                    }
                }
            })
            .catch((error) => {
                dispatch(fixedBBStore.actions.requestAdminApprovalFail(error));
                if(error.response.data.error) {
                    message.error(error.response.data.error);
                } else {
                    message.error("Request failed.");
                }
            });
    }
};
