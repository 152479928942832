import { log } from 'console'
import React, { SyntheticEvent, FocusEvent } from 'react'
import SimpleReactValidator from 'simple-react-validator'

type Props = {
    name: string,
    label?: string,
    placeholder: string,
    value: string,
    setValue: (name: string) => (event: any) => void,
    validator: SimpleReactValidator,
    rules: Array<any>,
    disabled: boolean,
    status?: string, //defult, verified, notVerified, invalid
    validationMessage?: string,
    onBlurValue?: (event: FocusEvent<HTMLInputElement>) => any,
    errorText?: string,
    forceUpdate: () => void,
    specificClasses?: string
    type?: string,
    readOnly?: boolean
    tooltipComponent?: any
}

const InputTextFieldForEsms = ({ name, label, placeholder, value, setValue, validator, rules, disabled, status, validationMessage, onBlurValue, errorText, forceUpdate, specificClasses, type, readOnly, tooltipComponent }: Props) => {
    // console.log("validator.message(name, value, rules)", validator.message(name, value, rules));
    return (
        <div className={specificClasses ? specificClasses : "form-element__wrapper validated mb-3"}>
            <label htmlFor="" className="form-label">{label}
                <span aria-label="required"></span>
                {tooltipComponent && <div className="tooltip"><span><i className="bi bi-info-circle"></i></span>
                    <span className="tooltiptext">{tooltipComponent}</span>
                </div>}
            </label>
            <input
                type={type}
                className={status === "verified" ? "form-control valid"
                    : status === "invalid" ? "form-control invalid"
                        : status === "notVerified" ? "form-control pending"
                            : "form-control"}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={e => {
                    // console.log("$$$$$$$$$$$$$$$", e.target.value);
                    
                    setValue(name)(e)
                    validator.showMessageFor(name)
                    forceUpdate();
                }}
                onBlur={onBlurValue}
                required={true}
                disabled={disabled}
                readOnly={readOnly}
            />
            {status === "verified" ?
                <div className="validation valid-feedback">{validationMessage}</div>
                : status === "invalid" ?
                    <div className="validation invalid-feedback">{validationMessage}</div>
                    : status === "notVerified" ?
                        <div className="validation panding-feedback">{validationMessage}</div>
                        : errorText !== "" ?
                            <div className="validation invalid-feedback">{errorText}</div> :
                            <></>
            }
            <div className="validation invalid-feedback">{validator.message(name, value, rules)}</div>
        </div>
    )
}

export { InputTextFieldForEsms }