import { getExpensesByTenantsForPartner, getExpensesByWidgetsForOwner, getExpensesForLqOwner, getRevenueForLqPartner, getTenantAnalyticsForPartner, getWidgetAnalyticsForOwner } from "../../services/dashboard.service";
import dashboardStore from "../reducers/dashboard.store";

export const fetchWidgetAnalytics = (tenantId: string): any => {
    return (dispatch: any) => {
        getWidgetAnalyticsForOwner(tenantId)
            .then((response: any) => {
                console.log("fetchWidgetAnalytics: ", response.data)
                // if (response.status === 200 || response.status === 201) {
                    dispatch(dashboardStore.actions.getWidgetAnalytics(response.data));
                //} 
            })
            .catch((error : any) => {
                console.log(error);
            });
    }
}

export const fetchExpensesByWidgets = (tenantId: string, limit: number, offset: number): any => {
    return (dispatch: any) => {
        getExpensesByWidgetsForOwner(tenantId, limit, offset)
            .then((response: any) => {
                console.log("fetchExpensesByWidgets: ", response.data)
                // if (response.status === 200 || response.status === 201) {
                    dispatch(dashboardStore.actions.getExpensesByWidgets(response.data));
                //} 
            })
            .catch((error : any) => {
                console.log(error);
            });
    }
}

export const fetchExpensesForLastQuater = (tenantId: string): any => {
    return (dispatch: any) => {
        getExpensesForLqOwner(tenantId)
            .then((response: any) => {
                console.log("fetchExpensesForLastQuater: ", response)
                // if (response.status === 200 || response.status === 201) {
                    dispatch(dashboardStore.actions.getLastQuaterExpenses(response.data));
                //} 
            })
            .catch((error : any) => {
                console.log(error);
            });
    }
}

export const fetchTenantAnalytics = (tenantId: string): any => {
    return (dispatch: any) => {
        getTenantAnalyticsForPartner(tenantId)
            .then((response: any) => {
                console.log("fetchTenantAnalytics: ", response)
                // if (response.status === 200 || response.status === 201) {
                    dispatch(dashboardStore.actions.getTenantAnalytics(response.data));
                //} 
            })
            .catch((error : any) => {
                console.log(error);
            });
    }
}

export const fetchExpensesByTenantsForPartner = (tenantId: string,  limit: number, offset: number): any => {
    return (dispatch: any) => {
        getExpensesByTenantsForPartner(tenantId, limit, offset)
            .then((response: any) => {
                console.log("fetchExpensesByTenantsForPartner: ", response)
                //if (response.status === 200 || response.status === 201) {
                    dispatch(dashboardStore.actions.getExpensesByTenants(response.data));
                //} 
            })
            .catch((error : any) => {
                console.log(error);
            });
    }
}

export const fetchRevenueForLastQuater = (tenantId: string): any => {
    return (dispatch: any) => {
        getRevenueForLqPartner(tenantId)
            .then((response: any) => {
                console.log("fetchRevenueForLastQuater: ", response)
                //if (response.status === 200 || response.status === 201) {
                    dispatch(dashboardStore.actions.getLastQuaterRevenue(response.data));
                //} 
            })
            .catch((error : any) => {
                console.log(error);
            });
    }
}