import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd';
import type { TabsProps } from 'antd'
import { useSelector } from 'react-redux';
import Auth from '../../models/response/auth.model';
import { Link, useNavigate } from 'react-router-dom';
import { OfferModel } from './models/offer.model';
import { fetchAllOffers, fetchAppList } from '../../store/actions';
import { useAppDispatch } from '../../store';
import { SingleWidgetCard } from './Components/SingleWidgetCard';

type Props = {
    auth: Auth
}

const Offers = ({ auth }: Props) => {

    const navigate = useNavigate();

    const [tenantData, setTenantData] = useState({
        tenantGroupId: ""
    });
    const [searchText, setSearchText] = useState("");
    const [limit, setLimit] = useState(20);
    const [offset, setOffset] = useState(0);

    const {
        userDetails
    } = useSelector<any, any>(({ user }) => user);

    const {
        allOffersList,
        eligibleOffersList,
        claimedOffersList
    } = useSelector<any, any>(({ offers }) => offers);

    const {
        appList
    } = useSelector<any, any>(({ settings }) => settings);

    useEffect(() => {
        const groupId = (userDetails && userDetails.OwnedEnterprises[0]) ? JSON.stringify(userDetails.OwnedEnterprises[0].groupId) : "";
        setTenantData({
            ...tenantData,
            tenantGroupId: groupId
        })

    }, [userDetails]);

    useEffect(() => {
        const tenantGroupId = (userDetails && userDetails?.OwnedEnterprises[0]) ? userDetails?.OwnedEnterprises[0].groupId : "";
        if(userDetails) {
          dispatch(fetchAllOffers(userDetails?.uuid, tenantGroupId));
        } else {
          dispatch(fetchAllOffers("0", "0"));
        }
    }, [userDetails]);

    useEffect(() => {
        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        if (tenantGroupId !== "" && searchText == "") {
            dispatch<any>(fetchAppList(tenantGroupId, searchText !== "" ? searchText : '0', limit, offset, appList));
        }
    }, [userDetails, offset, searchText]);

    const onChange = (key: string) => {
        console.log(key);
    };

    const dispatch = useAppDispatch();

    const openExternalURLInNewTab = (url: string) => {
        window.open(url, '_blank');
    };

    const {
        authData
    } = useSelector<any, any>(({ authentication }) => authentication);

    const redirectToApp = (uuid: string | null) => {
        // console.log('Auth ***** ', auth)
        if (auth && auth.accessToken !== '') {
            return `/discover/widget/${uuid}`;
        } else {
            return `/widget/${uuid}`;
        }
    }

    const redirectToCategories = (uuid: string | null) => {
        // console.log('Auth ***** ', auth)
        if (auth && auth.accessToken !== '') {
            return `/discover/apps/${uuid}`;
        } else {
            return `/apps/${uuid}`;
        }
    }

    const onRedirect = (uuid: string | null) => {
        if (auth && auth.accessToken !== '') {
            navigate(`/discover/widget/${uuid}`);
        }
        else {
            navigate(`/widget/${uuid}`);
        }
    }

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Common Offers',
            children:
                <>
                    <div className="app-listing-section not-at-right type-1-inner" id="listingScrollDiv">
                        {Array.isArray(allOffersList?.allOffersWidgetDetailsList) && allOffersList?.allOffersWidgetDetailsList.length > 0 ?
                            (allOffersList?.allOffersWidgetDetailsList.map((value: OfferModel, index: number) => {
                                const hasSubscribed = Array.isArray(appList) && appList.some((appId: { uuid: string }) => appId.uuid === value.uuid);
                                return (
                                    (userDetails ? (
                                        !hasSubscribed ? (
                                            <SingleWidgetCard
                                                singleWidgetDetails={value}
                                                onRedirect={onRedirect}
                                                redirectToApp={redirectToApp}
                                                redirectToCategories={redirectToCategories}
                                            />
                                        ) : (
                                            <h5 style={{ textAlign: 'center' }}>No offers available at the moment</h5>
                                        )) : (
                                            <SingleWidgetCard
                                                singleWidgetDetails={value}
                                                onRedirect={onRedirect}
                                                redirectToApp={redirectToApp}
                                                redirectToCategories={redirectToCategories}
                                            />
                                        )
                                    )
                                );
                            }))
                            :
                            <h5 style={{ textAlign: 'center' }}>No offers available at the moment</h5>
                        }
                    </div>
                </>
        },
        {
            key: '2',
            label: 'Eligible Offers',
            children:
                <>
                    <div className="app-listing-section not-at-right type-1-inner" id="listingScrollDiv">
                        {Array.isArray(eligibleOffersList?.eligibleOffersWidgetDetailsList) && eligibleOffersList?.eligibleOffersWidgetDetailsList.length > 0 ?
                            (eligibleOffersList?.eligibleOffersWidgetDetailsList.map((value: OfferModel, index: number) => (
                                <SingleWidgetCard
                                    singleWidgetDetails={value}
                                    onRedirect={onRedirect}
                                    redirectToApp={redirectToApp}
                                    redirectToCategories={redirectToCategories}
                                />
                            )))
                            :
                            <h5 style={{ textAlign: 'center' }}>No eligible offers available at the moment</h5>
                        }
                    </div>
                </>
        },
        {
            key: '3',
            label: 'Claimed Offers',
            children:
                <>
                    <div className="app-listing-section not-at-right type-1-inner" id="listingScrollDiv">
                        {Array.isArray(claimedOffersList) && claimedOffersList.length > 0 ?
                            (claimedOffersList.map((value: OfferModel, index: number) => (
                                <SingleWidgetCard
                                    singleWidgetDetails={value}
                                    onRedirect={onRedirect}
                                    redirectToApp={redirectToApp}
                                    redirectToCategories={redirectToCategories}
                                />
                            )))
                            :
                            <h5 style={{ textAlign: 'center' }}>No claimed offers available at the moment</h5>
                        }
                    </div>
                </>
        },
    ];

    return (
        <div className="main-content main-spacer-x-y">
            <div className="section__wrapper app-listing-section__wrapper">
                <div className="app-listing-section-title__wrapper">
                    <span>Offers and Promotions</span>
                </div>
                <div className="feature-search-bar card">
                    <div className="primary-searchbar__wrapper">
                    </div>
                </div>
            </div>

            <div className="app-listing-section__wrapper type-1">
                <div className="app-listing-section-title__wrapper">
                    <span>Available Offers</span>
                </div>
                <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
            </div>
        </div>
    )
}
export { Offers }