import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import AuthHandler from '../../../helper/AuthHandler';
import { Button, DatePicker, Form, Input, message, Select, Space, Tooltip, Modal } from "antd";
import {
    fetchSettlementTyps,
    fetchSingleApp,
    fetchTenantMasksByType,
    fetchMsisdnsList,
    fetchDistricts,
    fetchReachCost,
    fetchManageCampaignDetails,
    postNewBizEmailConfig
} from '../../../store/actions';
import L from 'leaflet';
import SimpleReactValidator from 'simple-react-validator';
import * as CONSTANTS from '../../../constants';
import { confirmAlert } from 'react-confirm-alert';
import { onSearch } from "../../../services/widgets.service";
import dayjs from 'dayjs';
import MarkerMapIcon from '../../../assets/images/Marker.svg';
import { PageHeaderStatic } from "../components/PageHeaderStatic";
import { InputTextArea, InputTextField } from "../../../components";
import { Option } from "antd/es/mentions";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { InfoCircleOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;

const override: CSSProperties = {
    display: "block",
    margin: "0 auto"
};

const BizEmailConfigCreate = ({ auth }: any) => {

    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [recoveryEmail, setRecoveryEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [domainList, setDomainList] = useState(CONSTANTS.BIZ_EMAIL_DOMAIN_LIST);
    const [selectedDomain, setSelectedDomain] = useState(CONSTANTS.BIZ_EMAIL_DOMAIN_LIST[0]);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [emailError, setEmailError] = useState("");

    const { wid } = useParams();
    const [screen, setScreen] = useState('add-biz-email-config');

    const [validator] = useState(new SimpleReactValidator({
        messages: {
            required: 'You can’t keep this as empty',
            min: 'Minimum length is :min',
            max: 'Maximum length is :max',
        },
        validators: {
            all_mobiles: {
                message: 'The entered :attribute number is invalid.',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^[0]{1}[123456789]{2}\d{7}$/) && params.indexOf(val) === -1
                }
            },
            dialog: {
                message: 'The :attribute is invalid.',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^([0]{1}[7]{1}[764]{1}\d{7}|[7]{1}[764]{1}\d{7})$/) && params.indexOf(val) === -1
                }
            },
            reachCount: {
                message: 'The selected payment type/or the number will not be applicable for this lead request.',
                rule: (val: any, params?: any, validator?: any): boolean => !!(val && val <= 50000)
            },
            reachCost: {
                message: 'Value must be greater than zero.',
                rule: (val: any, params?: any, validator?: any): boolean => !!(val > 0)
            }
        },
        autoForceUpdate: this
    }));

    const [passwordValidator] = useState(new SimpleReactValidator({
        messages: {
            required: 'You can’t keep this as empty',
            min: ':attribute is too short',
            max: ':attribute is too lengthy',
        },
        validators: {
            lowerCase: {
                message: 'Password must include lowercase value',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^(?=.*[a-z])/) && params.indexOf(val) === -1
                }
            },
            upperCase: {
                message: 'Password must include uppercase value',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^(?=.*[A-Z])/) && params.indexOf(val) === -1
                }
            },
            numberValue: {
                message: 'Must include at least one special character',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^(?=.*[!@#$%^&*._-])/) && params.indexOf(val) === -1
                }
            },
            specialCharacter: {
                message: 'Must include at least one number',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^(?=.*[0-9])/) && params.indexOf(val) === -1
                }
            },
            spaceValidate: {
                message: 'Spaces are not allowed',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^[^\s]{1,}$/) && params.indexOf(val) === -1
                }
            }
        },
        autoForceUpdate: this
    }));

    const navigate = useNavigate();
    const {
        singleAppDetails
    } = useSelector(({ apps }: any) => apps);


    const {
        termsAndConditionsDetails,
        locationSubmitError,
        locationSubmitErrorMessage,
        locationSubmitLoading,
        locationSubmitSuccess,
        settlement_types
    } = useSelector(({ location }: any) => location);

    const {
        msisdnData,
        msisdnOtpData,
        userDetails
    } = useSelector<any, any>(({ user }) => user);

    const {
        masksList,
        msisdnsList,
        districtList
    } = useSelector<any, any>(({ ent }) => ent);

    const {
        reachCostData,
        campaignDetails
    } = useSelector<any, any>(({ mc }) => mc);

    const dispatch = useAppDispatch();

    const useForceUpdate = () => {
        const [value, setValue] = useState(0);
        return () => setValue(value => value + 1);
    }

    const forceUpdate = useForceUpdate()


    const onChangeFields = (e: any) => {
        let name = e.target.name;

        console.log('field name = ', e.target.name)
        if (name === 'fname') {
            setFname(e.target.value)
        } else if (name === 'lname') {
            setLname(e.target.value);
        } else if (name === 'email') {
            setEmail(e.target.value);
        } else if (name === 'password') {
            setPassword(e.target.value);
        } else if (name === 'recoveryEmail') {
            setRecoveryEmail(e.target.value);
        } else if (name === 'mobileNumber') {
            setMobileNumber(e.target.value);
        }
        validator.showMessageFor(name);
        forceUpdate();
        // this.setState({
        //     [e.target.name]: e.target.value
        // }, function () {
        //     this.validator.showMessageFor(name);
        //     this.forceUpdate();
        // });
    }

    const onChangePassword = (e: any) => {
        setPassword(e.target.value);
        passwordValidator.showMessageFor('password');
        forceUpdate();
        // this.setState({
        //     password: e.target.value
        // }, function () {
        //     this.passwordValidator.showMessageFor('password');
        //     this.forceUpdate();
        // });
    }

    const onChangeDomain = (e: any) => {
        setSelectedDomain(e.target.value);
    }

    const submitEmailRequest = async () => {
        try {
            let newEmailConfig = {
                "firstName": fname,
                "lastName": lname,
                "email": email + "@" + selectedDomain,
                "msisdn": mobileNumber,
                "recoveryEmail": recoveryEmail,
                "password": password,
            }
            const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
            setFormSubmitted(true);
            dispatch(postNewBizEmailConfig(newEmailConfig, tenantGUID, wid!))
            // this.setState({
            //     formSubmitted: true
            // }, async function () {
            //     if (this.props.widgetData !== null) {
            //         await this.props.postNewBizEmailConfig(newEmailConfig, tenantGUID, this.props.widgetData.uuid);
            //     }
            // });
            setSubmitDisabled(false);
            setSelectedDomain(CONSTANTS.BIZ_EMAIL_DOMAIN_LIST[0]);
            setFname('');
            setLname('');
            setPassword('');
            setRecoveryEmail('');
            setEmail('');
            setMobileNumber('');

            // await this.setState({
            //     saveSuccess: this.props.postSuccess,
            //     saveError: this.props.postError,
            //     termsAndConditions: false,
            //     submitDisabled: true,
            //     selected_domain: CONSTANTS.BIZ_EMAIL_DOMAIN_LIST[0],
            //     fname: '',
            //     lname: '',
            //     email: '',
            //     mobile: '',
            //     recoveryEmail: '',
            //     password: '',
            // });
        } catch (e) {
            console.log("Error in submitting new email config = ", e);
        }
    }

    const handleRequestSave = async () => {
        // if (this.validator.allValid() && this.passwordValidator.allValid()) {
        confirmAlert({
            message: CONSTANTS.NEW_BIZ_EMAIL_SAVE,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => submitEmailRequest()
                },
                {
                    label: 'No',
                }
            ]
        });
        // } else {
        //     this.validator.showMessages();
        //     this.passwordValidator.showMessages();
        //     this.forceUpdate();
        //     this.setState({
        //         saveSuccess: false,
        //         saveError: false,
        //         saveLoading: false
        //     });
        // }
    }

    const renderSubmitButton = () => {
        // if (AuthHandler.isPerformable(this.props.enterpriseDetails, "add-biz-email")) {
        return (
            <button
                className="btn btn-primary"
                onClick={handleRequestSave}
                disabled={submitDisabled}
            >Create email account
            </button>
        )
        // } else {
        //     return (
        //         <button type="button" className="btn btn-primary btn-block" disabled={true}>You don't have access to
        //             create a DialogBiz email</button>
        //     )
        // }
    }

    const renderDomains = () => {
        if (domainList !== null && typeof domainList !== 'undefined') {
            if (Array.isArray(domainList)) {
                return (
                    domainList.map((value, index) => {
                        return (
                            <option value={value}>@{value}</option>
                        );
                    })
                )
            }
        }
    }

    if (AuthHandler.isAuthorized(auth, 'add-biz-email-config') && userDetails !== null) {
        // return (
        if (userDetails) {
            return (
                <React.Fragment>
                    <div className="main-content main-spacer-x-y">
                        <PageHeaderStatic
                            page={'create'}
                        />
                        <div className="card added-app-thumbnnail__wrapper">
                            <div
                                className="added-app-thumbnail__inner"
                                style={{
                                    backgroundImage: `url(${(singleAppDetails && singleAppDetails.widgetDetails !== null) ? singleAppDetails.widgetDetails?.leftImage : ""})`
                                }}
                            ></div>
                        </div>
                        <div className="card panel content-holder-panels added-app-form__wrapper">
                            <div className="panel-header">
                                <div className="panel-header-content__wrapper">
                                    <h4 className="panel-header-title">DialogBiz Email New Request</h4>
                                </div>
                            </div>
                            <div className="panel-content">
                                <div className="wiz-content-form__wrapper">
                                    <p>
                                        {(singleAppDetails && singleAppDetails.widgetDetails !== null) ? singleAppDetails.widgetDetails.description : ""}
                                    </p>
                                    <p>
                                        <a href="https://www.zoho.com/mail/help/getting-started.html" target="_blank" style={{ marginLeft: 5 }}>Zoho User Guide</a>
                                    </p>
                                    <br />
                                    <div className="form-group__wrapper">
                                        <div className="form-group">
                                            <div className="form-element__wrapper mb-3">
                                                <label htmlFor="" className="form-label">
                                                    First Name<span aria-label="required"></span>
                                                </label>
                                                <input
                                                    type="text"
                                                    id="fname"
                                                    name="fname"
                                                    className="form-control"
                                                    value={fname}
                                                    onChange={onChangeFields}
                                                    placeholder="Enter your first name"
                                                />
                                                <div
                                                    className="validation invalid-feedback">{validator.message('fname', fname, ['required', 'alpha_num_dash_space', { max: 100 }])}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group__wrapper">
                                        <div className="form-group">
                                            <div className="form-element__wrapper mb-3">
                                                <label htmlFor="" className="form-label">
                                                    Last Name<span aria-label="required"></span>
                                                </label>
                                                <input
                                                    type="text"
                                                    id="lname"
                                                    name="lname"
                                                    className="form-control"
                                                    value={lname}
                                                    onChange={onChangeFields}
                                                    placeholder="Enter your last name"
                                                />
                                                <div
                                                    className="validation invalid-feedback">{validator.message('lname', lname, ['required', 'alpha_num_dash_space', { max: 100 }])}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group__wrapper">
                                        <div className="form-group">
                                            <div className="form-element__wrapper mb-3">
                                                <label htmlFor="smsSenderName">Email address</label>
                                                <div className="row wow fadeIn">
                                                    <div className="col-md-7 mb-4"
                                                        style={{ paddingRight: 0 }}>
                                                        <input
                                                            type="text"
                                                            id="email"
                                                            name="email"
                                                            className="form-control form-control-md"
                                                            value={email}
                                                            onChange={onChangeFields}
                                                            placeholder="Enter an username for the new email account"
                                                        />
                                                        <div
                                                            className="validation invalid-feedback">{validator.message('email', email, 'required|email')}</div>
                                                    </div>
                                                    <div className="col-md-5 mb-4">
                                                        <select
                                                            className="browser-default custom-select custom-control-label biz-select"
                                                            onChange={onChangeDomain}>
                                                            {renderDomains()}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group__wrapper">
                                        <div className="form-group">
                                            <div className="form-element__wrapper mb-3">
                                                <label htmlFor="password"
                                                    className="input__label">Password <span>*</span>&nbsp;&nbsp;
                                                    <Tooltip title={<div>
                                                        <b>Password policy criteria</b>
                                                        <p>*Password must include uppercase, lowercase
                                                            value.</p>
                                                        <p>*Must include at least one number.</p>
                                                        <p>*Must include at least one Symbol.</p>
                                                        <p>*The minimum length is 8</p>
                                                        <p>*The maximum length is 30</p>
                                                    </div>}>
                                                        <InfoCircleOutlined style={{ color: "#555555" }} />
                                                    </Tooltip>
                                                </label>
                                                <input
                                                    type="password"
                                                    id="password"
                                                    name="password"
                                                    className="form-control form-control-md"
                                                    value={password}
                                                    onChange={onChangePassword}
                                                    placeholder="Enter a password according to the password policy"
                                                />
                                                <div
                                                    className="validation invalid-feedback">{passwordValidator.message('password', password, ['required', 'alpha_num_dash_space', { max: 100 }])}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group__wrapper">
                                        <div className="form-group">
                                            <div className="form-element__wrapper mb-3">
                                                <label htmlFor="smsSenderName">Recovery email address</label>
                                                <input
                                                    type="text"
                                                    id="recoveryEmail"
                                                    name="recoveryEmail"
                                                    className="form-control form-control-md"
                                                    value={recoveryEmail}
                                                    onChange={onChangeFields}
                                                    placeholder="Enter a recovery email address for this email account"
                                                />
                                                <div
                                                    className="validation invalid-feedback">{validator.message('recoveryEmail', recoveryEmail, ['required', 'email'])}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group__wrapper">
                                        <div className="form-group">
                                            <div className="form-element__wrapper mb-3">
                                                <label htmlFor="smsSenderName">Mobile number</label>
                                                <input
                                                    type="text"
                                                    id="mobileNumber"
                                                    name="mobileNumber"
                                                    className="form-control form-control-md"
                                                    value={mobileNumber}
                                                    onChange={onChangeFields}
                                                    placeholder="07xxxxxxxx/7xxxxxxxx"
                                                />
                                                <div
                                                    className="validation invalid-feedback">{validator.message('mobileNumber', mobileNumber, ['required', 'dialog', { max: 100 }])}</div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="form-group__wrapper">
                                        <div className="form-group">
                                            <div className="form-element__wrapper mb-3">
                                                <input className="form-check-input" type="checkbox"
                                                    checked={termsAndConditions}
                                                    id="terms-acceptence"
                                                    onChange={onConfirmationTerms} />&nbsp;&nbsp;
                                                <label className="form-check-label"
                                                    htmlFor="terms-acceptence">
                                                    I agree to <a onClick={onShowTnC}>Terms and conditions</a>
                                                </label>
                                            </div>
                                            <div className="validation invalid-feedback">{tncError}</div>
                                        </div>
                                    </div> */}
                                    <div className="panel-bottom-btn__wrapper">
                                        <button className="btn btn-secondary" onClick={() => { navigate(-1) }}>Cancel</button>
                                        {renderSubmitButton()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        } else {
            return (
                <div className="section__wrapper">
                    <div className="empty-state-new-action__wrapper">
                        <div className="new-action-content__wrapper">
                            <h4 className="sub">Oops, something went wrong!</h4>
                        </div>
                    </div>
                </div>
            );
        }
        // );
    } else return (<React.Fragment></React.Fragment>);
}

export default BizEmailConfigCreate;