import React, { useEffect, useState } from 'react';
import { AddedApp } from '../models/added-app.model';
import { useNavigate } from 'react-router-dom';
import { onRedirectTo } from '../../../store/actions';
import { useAppDispatch } from '../../../store';
import {notification} from "antd";
import { AnyAaaaRecord } from 'dns';

type Props = {
    appDetails: AddedApp,
    userDetails: any,
    hasOffer: boolean,
    hasSubscribed: boolean
}

const AppListItem = ({ appDetails, userDetails, hasOffer, hasSubscribed }: Props) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [isSubscriptionActive, setIsSubscriptionActive] = useState(true);

    const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";

    useEffect(() => {
        if (appDetails && appDetails.Subscriptions && appDetails.Subscriptions.length > 0 && appDetails.Subscriptions[0].WidgetSubscriptions && appDetails.Subscriptions[0].WidgetSubscriptions.isSubscriptionActive === false) {
            setIsSubscriptionActive(false);
        }
        {console.log("dddddddddd", hasOffer)}

    }, [appDetails])

    const onRedirect = () => {
        if (isSubscriptionActive) {
            onRedirectTo(tenantGroupId, navigate, appDetails, dispatch, null);
        } else {
            notification.warning({
                message: `Widget is Disabled`,
                description: <p>Pending annual subscription renewal. Renew your subscription via <b>{'Settings –> Billing & Plans'}</b></p>,
                placement: 'topRight'
            });
        }
    }

    return (
        <div className="app-listing-item__wrapper" onClick={() => onRedirect()}>
            <div className={!isSubscriptionActive ? "app-listing-item card app-disabled" : "app-listing-item card"}>
                <div className="app-item-content__wrapper app-details">
                    <a>
                        <div
                            className="card app-media-holder"
                            style={{
                                backgroundImage: `url(${appDetails.icon})`
                            }}
                        >{hasOffer && !hasSubscribed &&
                            <div className="discount-label">Offer</div>
                        }</div>
                    </a>
                    <div className="app-media-info-holder">
                        <a className="app-media-title">
                            {appDetails.title}
                        </a>
                        <div className="arrow-icon-holder__wrapper">
                            <div className="arrow-icon-holder">
                                <i className="bi bi-chevron-right" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppListItem;