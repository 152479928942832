/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {CSSProperties, useEffect, useState} from "react";
import {Button, Card, Form, Radio, Select, Space} from 'antd';
import {DeviceDataRespModel} from "../models/deviceDataResp.model";
import {PriceOptionModel} from "../models/priceOption.model";

const layout = {
    labelCol: { span: 1 },
    wrapperCol: { span: 23 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

type Props = {
    onDeviceSelect: (values: any) => any,
    deviceData: Array<DeviceDataRespModel>
}

const DeviceSelection = ({ onDeviceSelect, deviceData }: Props) => {

    const [priceOptList, setPriceOptList] = useState<PriceOptionModel[]>([]);

    const onChangeDevice = (e: any) => {
        console.log("change val: ", e.target.value);
        const selectedDevice = deviceData.find((item: DeviceDataRespModel) => item.productCode === e.target.value)
        if(selectedDevice) {
            setPriceOptList(selectedDevice.priceOptionList);
        }
    }
    return (
        <React.Fragment>
            <div className="card-body gws__body">
                <h4 className="gws-title">Select the Router Model</h4>
                <Form
                    {...layout}
                    name="add-domain"
                    style={{maxWidth: 600, marginInline: 'auto', textAlign: 'left'}}
                    initialValues={{remember: true}}
                    onFinish={onDeviceSelect}
                    autoComplete="off"
                    labelAlign={"left"}
                >
                    <Form.Item
                        name="device"
                        rules={[{
                            required: true,
                            message: 'This is Mandatory Field, Please select a device.',
                        }]}>
                        <Radio.Group style={{width: '100%'}} onChange={onChangeDevice}>
                            <Space direction="vertical" style={{width: '100%'}}>
                                {deviceData && deviceData.map(deviceObj => {
                                    return <Radio.Button value={deviceObj.productCode} className="device-selector-card">
                                        <div className="device-selector-card-box">
                                            <span><img src={deviceObj.imageUrl} alt="Device image" width="80"
                                                       height="80"/></span>
                                            <div>
                                                <span
                                                    className="device-selector-card-desc">{deviceObj.productDescription}</span><br/>
                                                {/*<span className="device-selector-card-small">{deviceObj.warranty}</span>*/}
                                            </div>
                                        </div>
                                    </Radio.Button>
                                })}
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                    {priceOptList && priceOptList.length > 0 &&
                        <>
                            <div style={{textAlign: "center", marginTop: 5}}>
                                <h4 className="gws-title">Select the Price Option</h4>
                            </div>
                            <Form.Item
                                name="priceOption"
                                rules={[{
                                    required: true,
                                    message: 'This is Mandatory Field, Please select a price option.',
                                }]}>
                                <Radio.Group style={{width: '100%'}}>
                                    <Space direction="vertical" style={{width: '100%'}}>
                                        {/*priceOptionList*/}
                                        {priceOptList && priceOptList.map(priceObj => {
                                            return <Radio.Button value={priceObj.offerCode} className="device-selector-card">
                                                <div className="device-selector-card-box">
                                                    {/*<span><img src={deviceObj.imageUrl} alt="Device image" width="80" height="80" /></span>*/}
                                                    <div>
                                                        <span
                                                            className="device-selector-card-desc">{priceObj.offerDescription}</span><br/>
                                                        <span className="device-selector-card-small">{priceObj.price}</span>
                                                    </div>
                                                </div>
                                            </Radio.Button>
                                        })}
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </>
                    }
                    <Form.Item {...tailLayout} className={"wizard-action"}>
                        <Button type="primary" htmlType="submit" className={"btn btn-primary"}>
                            Next
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </React.Fragment>
    );
}

export default DeviceSelection;