import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, message, notification, Space, Table, Tooltip } from "antd";
import {
    fetchSingleApp,
    dynamicLoadingStop,
    fetchAllConfigs,
    fetchOneConfig,
    postManualCharge,
    retryCharging
} from '../../../store/actions';
import AuthHandler from "../../../helper/AuthHandler";
import { DeleteOutlined, EyeOutlined, FormOutlined } from "@ant-design/icons";
import { useParams, useNavigate } from "react-router-dom";
import * as Constants from '../../../constants';
import { updateWidgetConfigStatus } from "../../../services/widgets.service";
import { PageHeaderDynamicFormComponent } from '../components/PageHeaderDynamicForm.component';
import { confirmAlert } from "react-confirm-alert";

const DynamicWidgetList = (props) => {
    const { wid } = useParams();

    const dispatch = useDispatch();
    const { userDetails } = useSelector(({ user }) => user);
    const {
        singleAppDetails
    } = useSelector(({ apps }) => apps);
    const { widgetConfigListLoading, widgetConfigList, manualChargeResponse, retryChargeResponse } = useSelector(({ dynamicForm }) => dynamicForm);
    const paths = window.location.pathname.split('/');
    const navigate = useNavigate();
    const [viewVal, setViewVal] = useState(null);

    useEffect(() => {
        if(userDetails) {
            const tenantId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
            if(tenantId) {
                dispatch(fetchSingleApp(wid, tenantId));
            }
        }
    }, [userDetails]);

    useEffect(() => {
        const tenantId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        if (singleAppDetails && singleAppDetails.widgetDetails && singleAppDetails.widgetDetails.dataUrlV2) {
            const formattedUrl = singleAppDetails.widgetDetails.dataUrlV2.startsWith('/api/') ? singleAppDetails.widgetDetails.dataUrlV2.slice(4) : singleAppDetails.widgetDetails.dataUrlV2;
            dispatch(fetchAllConfigs(formattedUrl, tenantId, singleAppDetails.widgetDetails.uuid))
        }
    }, [singleAppDetails]);

    const onChangeViewConfig = (uuid, configType) => {
        navigate(`/${paths[Constants.BASEURLPATHLENGTH + 1]}/${paths[Constants.BASEURLPATHLENGTH + 2]}/${configType}/${uuid}`)
    }

    const onRemoveConfig = (uuid) => {
        const tenantUUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        const widgetUUID = singleAppDetails?.widgetDetails?.uuid
        const body = {
            'status': Constants.STOPPED
        }
        confirmAlert({
            message: `Are you sure you want to remove the config?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        updateWidgetConfigStatus(body, uuid, tenantUUID, widgetUUID).then(res => {
                            if (res.status == 200) {
                                notification.success({
                                    message: `Success`,
                                    description: <div className="toatify_warning">The config successfully removed<br /></div>,
                                    placement: 'topRight'
                                });
                                if (singleAppDetails && singleAppDetails.widgetDetails) {
                                    dispatch(fetchAllConfigs(singleAppDetails.widgetDetails.dataUrlV2, tenantUUID, singleAppDetails.widgetDetails.uuid))
                                }
                            } else {
                                notification.error({
                                    message: `Error`,
                                    description: <div className="toatify_warning">Removing config failed<br /></div>,
                                    placement: 'topRight'
                                });
                            }
                        }).catch(e => {
                            notification.error({
                                message: `error`,
                                description: <div className="toatify_warning">Removing config failed<br />
                                </div>,
                                placement: 'topRight'
                            });
                        })
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    useEffect(() => {
        const tenantUUIDId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        if (manualChargeResponse != null) {
            if (manualChargeResponse.status == 200) {
                notification.success({
                    message: `Success`,
                    description: <div className="toatify_warning">Charging Success<br />
                    </div>,
                    placement: 'topRight'
                });
                if (singleAppDetails && singleAppDetails.widgetDetails) {
                    dispatch(fetchAllConfigs(singleAppDetails.widgetDetails.dataUrlV2, tenantUUIDId, singleAppDetails.widgetDetails.uuid))
                }

            } else {
                notification.error({
                    message: `error`,
                    description: <div className="toatify_warning">Charging Failed<br />
                    </div>,
                    placement: 'topRight'
                });
            }
        }

    }, [manualChargeResponse]);

    useEffect(() => {
        const tenantUUIDId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        if (retryChargeResponse != null) {
            if (retryChargeResponse.status == 200) {
                notification.success({
                    message: `Success`,
                    description: <div className="toatify_warning">Charging Success<br />
                    </div>,
                    placement: 'topRight'
                });
                if (singleAppDetails && singleAppDetails.widgetDetails) {
                    dispatch(fetchAllConfigs(singleAppDetails.widgetDetails.dataUrlV2, tenantUUIDId, singleAppDetails.widgetDetails.uuid))
                }
            } else {
                notification.error({
                    message: `error`,
                    description: <div className="toatify_warning">Charging Failed<br />
                    </div>,
                    placement: 'topRight'
                });
            }
        }
    }, [retryChargeResponse]);

    const onClickManualCharge = (uuid) => {
        const tenantUUId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        dispatch(postManualCharge(uuid, tenantUUId));
    }

    const onClickRetryCharge = (uuid) => {
        const tenantUUId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        dispatch(retryCharging(uuid, tenantUUId));
    }

    // console.log("widgetConfigList", widgetConfigList);

    return (
        <React.Fragment>
            {
                AuthHandler.isAuthorized(props.auth, 'dynamin-widget') && userDetails !== null ?
                    <div className="main-content main-spacer-x-y">
                        <PageHeaderDynamicFormComponent
                            backLink={`/apps/wizard`}
                            page={'list'}
                            // leadCount={`single`}
                            widgetConfigList={widgetConfigList}
                        />
                        {singleAppDetails && singleAppDetails.widgetDetails && singleAppDetails.widgetDetails.uuid === Constants.CRBT_WIDGET_ID &&
                            <div className="alert info">
                                <span className="alert-text__wrapper">
                                    <i className="alert-icon bi bi-info-circle-fill"/>
                                    <span className="alert-text">
                                        Tip: Remember to upload your favorite ringing tones to the 'Ringing Tone Library' before creating new tones.
                                    </span>
                                </span>
                            </div>
                        }
                        <div className="section__wrapper">
                            <div className="card panel content-holder-panels">
                                <div className="panel-header">
                                    <div className="panel-header-content__wrapper">
                                        <h4 className="panel-header-title">{widgetConfigList && widgetConfigList.tableHeader}</h4>
                                    </div>
                                </div>
                                <div className="panel-table-listing__wrapper">
                                    <div className="table-parent__wrapper">
                                        <Table
                                            className="gx-table-responsive"
                                            sortDirections={['descend', 'ascend']}
                                            showSorterTooltip={false}
                                            columns={widgetConfigList ? [...widgetConfigList.columns, {
                                                title: '',
                                                dataIndex: '',
                                                key: 'x',
                                                width: "8%",
                                                render: (text, row) => <Space size={10}>
                                                    {
                                                        row.viewButtonVisible &&
                                                        <Tooltip title="View">
                                                            <EyeOutlined
                                                                onClick={() => onChangeViewConfig(row.uuid, "view")}
                                                            />
                                                        </Tooltip>
                                                    }
                                                    {
                                                        row.updateButtonVisible &&
                                                        <Tooltip title="Update">
                                                            <FormOutlined
                                                                onClick={() => onChangeViewConfig(row.uuid, "update")}
                                                            />
                                                        </Tooltip>

                                                    }
                                                    {
                                                        row.removeButtonVisible &&
                                                        <Tooltip title="Remove">
                                                            <DeleteOutlined
                                                                onClick={() => onRemoveConfig(row.uuid)}
                                                            />
                                                        </Tooltip>

                                                    }
                                                    {
                                                        row.renewSubscription == true &&
                                                        <Button className="btn btn-sm btn-primary mt-0 no-y-padding"
                                                            onClick={() => onClickManualCharge(row.uuid)}
                                                        >
                                                            Renew Subscription
                                                        </Button>
                                                    }
                                                    {
                                                        row.paymentStatus == Constants.Failed &&
                                                        <Button className="btn btn-sm btn-primary mt-0 no-y-padding"
                                                                onClick={() => onClickRetryCharge(row.uuid)}
                                                        >
                                                            Retry Payment
                                                        </Button>
                                                    }
                                                </Space>
                                                ,
                                            }] : []}
                                            dataSource={widgetConfigList ? widgetConfigList.data : []}
                                            pagination={true}
                                            loading={widgetConfigListLoading}
                                            size={"small"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <>Access denied</>
            }
        </React.Fragment>
    )
};

export default DynamicWidgetList;