import manageCampaignStore from "../reducers/manage-campaign.store.js";
import {
    getManageCampaignDetails,
    getReachCost,
    postCampaignData,
    manageCampaignDetails,
    manageCampaignWorkflowRequestStatus,
    managedCampaignConfirm,
    // filterKycList,
    mcConfigFetch,
    putCampaignData
    // getKycConfigs,
    // getKycEvents,
    // getKycListForCsv,
    // getKycUsernames,
    // postAppSubscription,
    // postKycConfigs,
} from "../../services/manage-campaign.service";
import { KycFilterModel } from "../../pages/apps/kyc/models/request/kyc-filter.model";
import { KycConfigModel } from "../../pages/apps/kyc/models/request/kyc-config.model";
import { NavigateFunction } from "react-router-dom";
import { SingleAppModel } from "../../pages/single-app/models/response/single-app.model";
import { onRedirectTo } from "./app-wizard.action";
import {message} from "antd";

export const fetchManageCampaignConfigs = (tenantId: string): any => {
    return (dispatch: any) => {

        console.log('fetchManageCampaignConfigs | tenantId',tenantId)
        dispatch(manageCampaignStore.actions.mcConfigFetch());

        mcConfigFetch(tenantId)
            .then((response) => {
                if (response.status === 200) {
                    console.log('responseresponseresponseresponseresponseresponse',response)
                    // dispatch(manageCampaignStore.actions.mcConfigFetchSuccess(mockResp));
                    dispatch(manageCampaignStore.actions.mcConfigFetchSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(manageCampaignStore.actions.mcConfigFetchFail(error));
            });
    }
};

export const getCampaignWorkflowStatus = (configUUID: string): any => {
    return (dispatch: any) => {
        dispatch(manageCampaignStore.actions.manageCampaignWorkflowRequestPending());
        manageCampaignWorkflowRequestStatus(configUUID)
            .then((response) => {
                if (response.status === 200) {
                    console.log('campaign status trtrieve | getCampaignWorkflowStatus | response',response)
                    // dispatch(manageCampaignStore.actions.manageCampaignWorkflowRequestSuccess(resp));
                    dispatch(manageCampaignStore.actions.manageCampaignWorkflowRequestSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(manageCampaignStore.actions.manageCampaignWorkflowRequestFailed(error));
            });
    }
};

export const fetchManageCampaignData = (tenantId: string,configUUID: string): any => {
    return (dispatch: any) => {
        let resp = {
            messageType:"longMsg"
        }

        console.log('fetchManageCampaignDetails',configUUID)
        console.log('fetchManageCampaignDetails | tenantId',tenantId)
        dispatch(manageCampaignStore.actions.manageCampaignDetailRequestPending());
        manageCampaignDetails(tenantId,configUUID)
            .then((response) => {
                if (response.status === 200) {
        // dispatch(manageCampaignStore.actions.manageCampaignDetailRequestSuccess(resp));
                    dispatch(manageCampaignStore.actions.manageCampaignDetailRequestSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(manageCampaignStore.actions.manageCampaignDetailRequestFailed(error));
            });
    }
};

export const confirmCampaign = (workflowData:any,tenantId: string,configUUID: string,messageTypeUuid:string): any => {
    return (dispatch: any) => {
        let resp = {
            messageType:"longMsg"
        }

        console.log('fetchManageCampaignDetails',configUUID)
        console.log('fetchManageCampaignDetails | tenantId',tenantId)
        dispatch(manageCampaignStore.actions.confirmCampaignRequestPending());
        managedCampaignConfirm(workflowData,tenantId,configUUID,messageTypeUuid)
            .then((response) => {
                if (response.status === 200) {
                    console.log('fetchManageCampaignDetails | Confirm Campaign | response',response)
                    dispatch(manageCampaignStore.actions.confirmCampaignRequestSuccess(response.data));
                }
            })
            .catch((error) => {
                message.error(error.response?.data?.error ?  error.response?.data?.error : "Error occurred. Please try again.");
                dispatch(manageCampaignStore.actions.confirmCampaignRequestFailed(error));
            });
    }
};

export const fetchReachCost = (body: any, tenantId: string, widgetId: string, msgType: string): any => {
    return (dispatch: any) => {
        dispatch(manageCampaignStore.actions.reachCostFetch());
        getReachCost(body, tenantId, widgetId, msgType)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    dispatch(manageCampaignStore.actions.reachCostFetchSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(manageCampaignStore.actions.reachCostFetchFail(error));
            });
    }
};

export const fetchManageCampaignDetails = (tenantId: string, configId: string): any => {
    return (dispatch: any) => {
        dispatch(manageCampaignStore.actions.fetchCampaignDetails());
        getManageCampaignDetails(tenantId, configId)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    dispatch(manageCampaignStore.actions.fetchCampaignDetailsSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(manageCampaignStore.actions.fetchCampaignDetailsFail(error));
            });
    }
};

export const saveCampaign = (body: any, tenantId: string, widgetId: string, navigate:any): any => {
    return (dispatch: any) => {
        message.loading("Submitting...")
        dispatch(manageCampaignStore.actions.campaignPost());
        postCampaignData(body, tenantId, widgetId)
            .then((response: any) => {
                if (response.status === 200) {
                    message.success("New campaign has been added successfully");
                    navigate(`/manage-campaign/${widgetId}/list`);
                    dispatch(manageCampaignStore.actions.campaignPostSuccess(response.data));
                }
            })
            .catch((error: any) => {
                message.error("Error occurred while adding new campaign. Please try again.");
                dispatch(manageCampaignStore.actions.campaignPostFail(error.response.data.error));
            });
    }
};

export const updateManageCampaingDetails = (body: any, tenantId: string, widgetId: string, configId:string, navigate:any): any => {
    return (dispatch: any) => {
        message.loading("Submitting...")
        dispatch(manageCampaignStore.actions.campaignPost());
        putCampaignData(body, tenantId, widgetId, configId)
            .then((response: any) => {
                if (response.status === 200) {
                    message.success("Campaign has been resubmitted successfully");
                    navigate(`/manage-campaign/${widgetId}/manage-campaign-workflow/${configId}`);
                    dispatch(manageCampaignStore.actions.campaignPostSuccess(response.data));
                }
            })
            .catch((error: any) => {
                message.error("Error occurred while resubmitting the campaign. Please try again.");
                dispatch(manageCampaignStore.actions.campaignPostFail(error.response.data.error));
            });
    }
};