import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AuthHandler from '../helper/AuthHandler';
import Auth from '../models/response/auth.model';
import Keycloak from 'keycloak-js';
import { useSelector } from 'react-redux';
import PercentageOutlined from '@ant-design/icons/lib/icons/PercentageOutlined';
import StarFilled from '@ant-design/icons/lib/icons/StarFilled';

type AppRoutesProps = {
    auth: Auth;
    keycloak: Keycloak | undefined;
    entStatus: string,
    sideBarOpen: boolean,
    setSideBarOpen: (value: boolean) => void
}

const SideBar = ({ auth, entStatus, sideBarOpen, setSideBarOpen, keycloak }: AppRoutesProps) => {
    const navRef = useRef<HTMLDivElement>(null);
    let navigate = useNavigate();
    const { userDetails } = useSelector<any, any>(({ user }) => user);

    const [showBottomWindow, setShowBottomWindow] = useState(false);
    const onChangeSideBar = () => {
        setSideBarOpen(!sideBarOpen);
    }


    const redirectToHome = (): string => {
        // console.log('Auth ***** ', auth)
        if (auth && auth.accessToken !== '') {
            return '/home';
        } else {
            return '/signup';
        }
    }

    const redirectToDiscover = (): string => {
        if (auth && auth.accessToken !== '') {
            return '/discover/apps/wizard';///discover/apps/wizard
        } else {
            return '/apps/wizard';
        }
    }


    const redirectToAnalytics = (): string => {
        if (auth && auth.accessToken !== '') {
            if (AuthHandler.isAuthorized(auth, 'owner-dashboard')) {
                return '/owner-dashboard';
            } else if (AuthHandler.isAuthorized(auth, 'partner-dashboard') ){
                return '/partner-dashboard';
            } else {
                return '/';
            }
        } else {
            return '/';
        }
    }

    const redirectToCommonWidgetCreation = (): string => {
        // console.log('Auth ***** ', auth)
        if (auth && auth.accessToken !== '') {
            return '/commonWidget/create';
        } else {
            return '/signup';
        }
    }
    
    const redirectToOffers = (): string => {
        if (auth && auth.accessToken !== '') {
            return '/offers';///discover/apps/wizard
        } else {
            return '/offers';
        }
    }
    // useEffect(() => {
    //     function handleClickOutside(event: MouseEvent): void {
    //         if (navRef.current && !navRef.current.contains(event.target as Node)) {
    //             setSideBarOpen(false);
    //         }
    //     }
    //     // Bind the event listener
    //     document.addEventListener('mousedown', handleClickOutside);
    //     return () => {
    //         // Unbind the event listener on clean up
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // });

    return (
        <nav className={"nav-side-bar " + (sideBarOpen ? 'show' : 'close')} ref={navRef}>
            <header className="desk-toggle">
                <span className="nav-toggle" typeof="button" onClick={onChangeSideBar}><i className={"bi " + (sideBarOpen ? 'bi-chevron-right' : 'bi-chevron-left')} /></span>
            </header>
            <div className="menu-bar">
                <div className="menu__wrapper">
                    <div className="menu-link__wrapper">
                        <ul className="menu-links">
                            {/* <li className="nav-link nav-link-primary-searchbar desk-d-none">
                                <div className="nav-element__wrapper center">
                                    <div className="primary-searchbar__wrapper">
                                        <input type="text" className="form-control primary-searchbar" id="" placeholder="Search by app names, categories, solutions" />
                                        <div className="primary-searchbar-list">
                                        </div>
                                    </div>
                                </div>
                            </li> */}
                            {/* <li className="nav-link desk-d-none profile-placeholder__wrapper"> */}
                            <li>
                                <div className="pop-dropdown__wrapper dropdown desk-d-none">
                                    <a className="pop-dropdown " onClick={() => setShowBottomWindow(true)}>
                                        {userDetails && <div className="profile-placeholder__wrapper left-align full-width">
                                            <div
                                                className="profile-img-holder sm"
                                                style={{ backgroundImage: "url()" }}
                                            >
                                                {userDetails.firstName.toUpperCase().charAt(0)}{userDetails.lastName.toUpperCase().charAt(0)}
                                            </div>
                                            <div className="profile-details txt-black">
                                                <span className="main-title">{userDetails.firstName} {userDetails.lastName}</span>
                                                <span className="sub-title">{(userDetails && userDetails.role) ? userDetails.role == "mp_enterprise_owner" ? "Business Owner" : userDetails.role == "mp_marketing_admin" ? "Partner" : "" : ""}</span>
                                            </div>
                                            <span className="pop-dropdown-icon txt-black">
                                                <i className="bi bi-chevron-down" />
                                            </span>
                                        </div>}
                                    </a>
                                    {/* ++ add ~ show ~ class*/}
                                    <ul className={"pop-dropdown-menu dropdown-menu-end mob-bottom-slider " + (showBottomWindow ? 'show' : 'close')}>
                                        <button className="btn btn-secondary btn-small btn-round d-none mob-bottom-slider-close-btn" onClick={() => setShowBottomWindow(false)}>
                                            ✕&nbsp;Close
                                        </button>
                                        {userDetails && <li>
                                            <a className="dropdown-item">
                                                <div className="sub-pro-details">
                                                    <div className="sub-pro-circle">
                                                        <i className="bi bi-person-fill" />
                                                    </div>
                                                    <div className="sub-pro-content__wrapper">
                                                        {userDetails.firstName} {userDetails.lastName}
                                                    </div>
                                                </div>
                                            </a>
                                        </li>}
                                        {userDetails && userDetails.email && <li>
                                            <a className="dropdown-item">
                                                <div className="sub-pro-details">
                                                    <div className="sub-pro-circle">
                                                        <i className="bi bi-envelope-fill" />
                                                    </div>
                                                    <div className="sub-pro-content__wrapper">
                                                        {userDetails.email}
                                                    </div>
                                                </div>
                                            </a>
                                        </li>}
                                        <li>
                                            <hr className="dropdown-divider" />
                                        </li>
                                        <li>
                                            <a className="dropdown-item">
                                                Profile settings
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item">
                                                Logout
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>

                            {!(auth?.realmRoles.includes('mp_content_generator')) &&
                                <li className="nav-link">
                                    <Link to={redirectToHome()}>
                                        <span className="nav-icon"><i className="bi bi-house-door-fill" /></span>
                                        <span className="nav-text">Home</span>
                                    </Link>
                                </li>
                            }

                            <li className="nav-link">
                                {/* <Link to={'/apps/wizard'}> */}
                                <Link to={redirectToDiscover()}>
                                    <span className="nav-icon"><i className="bi bi-globe2" /></span>
                                    <span className="nav-text">Discover</span>
                                </Link>
                            </li>

                            {
                                AuthHandler.isAccessible(auth, 'analytics-tab') &&
                                <li className="nav-link"> 
                                    <Link to={redirectToAnalytics()}>
                                        <span className="nav-icon"><i className="bi bi-clipboard-data-fill" /></span>
                                        <span className="nav-text">Analytics</span>
                                    </Link>
                                </li>
                            }


                            {(auth?.realmRoles.includes('mp_content_generator')) &&
                                <li className="nav-link">
                                    <Link to={redirectToCommonWidgetCreation()}>
                                        <span className="nav-icon">
                                            <span className="idea-icon">
                                                {/*?xml version="1.0" encoding="UTF-8"?*/}
                                                <svg
                                                    width="19px"
                                                    height="19px"
                                                    viewBox="0 0 19 19"
                                                    version="1.1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                >
                                                    <title>icon-apps</title>
                                                    <defs>
                                                        <path
                                                            d="M637.504727,798.265079 L634.7241,798.265079 C633.191418,798.265079 631.943473,799.514118 631.943473,801.048142 L631.943473,803.831204 C631.943473,805.365228 633.191418,806.614267 634.7241,806.614267 L637.504727,806.614267 C639.037409,806.614267 640.285354,805.365228 640.285354,803.831204 L640.285354,801.048142 C640.285354,799.514118 639.037409,798.265079 637.504727,798.265079 Z M649.193844,800.641202 L647.381195,798.826965 C646.381317,797.826211 644.755028,797.826958 643.755896,798.826965 L641.943247,800.641202 C640.943369,801.641956 640.943369,803.268922 641.943247,804.269676 L643.755896,806.083913 C644.755774,807.084667 646.381317,807.084667 647.381195,806.083913 L649.193844,804.269676 C650.192976,803.269669 650.193722,801.641956 649.193844,800.641202 Z M637.504727,807.727492 L634.7241,807.727492 C633.190274,807.727492 631.943473,808.975385 631.943473,810.510555 L631.943473,813.293617 C631.943473,814.828786 633.190274,816.07668 634.7241,816.07668 L637.504727,816.07668 C639.038553,816.07668 640.285354,814.828786 640.285354,813.293617 L640.285354,810.510555 C640.285354,808.975385 639.038553,807.727492 637.504727,807.727492 Z M646.958859,807.727492 L644.178232,807.727492 C642.644406,807.727492 641.397605,808.975385 641.397605,810.510555 L641.397605,813.293617 C641.397605,814.828786 642.644406,816.07668 644.178232,816.07668 L646.958859,816.07668 C648.492684,816.07668 649.739486,814.828786 649.739486,813.293617 L649.739486,810.510555 C649.739486,808.975385 648.492684,807.727492 646.958859,807.727492 Z"
                                                            id="path-1"
                                                        />
                                                    </defs>
                                                    <g
                                                        id="Marketing-material's"
                                                        stroke="none"
                                                        strokeWidth={1}
                                                        fill="none"
                                                        fillRule="evenodd"
                                                    >
                                                        <g id="ideaicon" transform="translate(-631.000000, -798.000000)">
                                                            <mask id="mask-2" fill="white">
                                                                <use xlinkHref="#path-1" />
                                                            </mask>
                                                            <use
                                                                id="icon-apps"
                                                                fill="#6C757D"
                                                                fillRule="nonzero"
                                                                xlinkHref="#path-1"
                                                                className="ideaicon"
                                                            />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </span>
                                        </span>
                                        <span className="nav-text">Common widget onboarding flow</span>
                                    </Link>
                                </li>
                            }

                            {!(auth?.realmRoles.includes('mp_content_generator')) && (AuthHandler.isAccessible(auth, 'added-apps')) ?
                                <li className="nav-link" area-label="">
                                    <Link to={'/subscriptions'}>
                                        <span className="nav-icon">
                                            <span className="idea-icon">
                                                {/*?xml version="1.0" encoding="UTF-8"?*/}
                                                <svg
                                                    width="19px"
                                                    height="19px"
                                                    viewBox="0 0 19 19"
                                                    version="1.1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                >
                                                    <title>icon-apps</title>
                                                    <defs>
                                                        <path
                                                            d="M637.504727,798.265079 L634.7241,798.265079 C633.191418,798.265079 631.943473,799.514118 631.943473,801.048142 L631.943473,803.831204 C631.943473,805.365228 633.191418,806.614267 634.7241,806.614267 L637.504727,806.614267 C639.037409,806.614267 640.285354,805.365228 640.285354,803.831204 L640.285354,801.048142 C640.285354,799.514118 639.037409,798.265079 637.504727,798.265079 Z M649.193844,800.641202 L647.381195,798.826965 C646.381317,797.826211 644.755028,797.826958 643.755896,798.826965 L641.943247,800.641202 C640.943369,801.641956 640.943369,803.268922 641.943247,804.269676 L643.755896,806.083913 C644.755774,807.084667 646.381317,807.084667 647.381195,806.083913 L649.193844,804.269676 C650.192976,803.269669 650.193722,801.641956 649.193844,800.641202 Z M637.504727,807.727492 L634.7241,807.727492 C633.190274,807.727492 631.943473,808.975385 631.943473,810.510555 L631.943473,813.293617 C631.943473,814.828786 633.190274,816.07668 634.7241,816.07668 L637.504727,816.07668 C639.038553,816.07668 640.285354,814.828786 640.285354,813.293617 L640.285354,810.510555 C640.285354,808.975385 639.038553,807.727492 637.504727,807.727492 Z M646.958859,807.727492 L644.178232,807.727492 C642.644406,807.727492 641.397605,808.975385 641.397605,810.510555 L641.397605,813.293617 C641.397605,814.828786 642.644406,816.07668 644.178232,816.07668 L646.958859,816.07668 C648.492684,816.07668 649.739486,814.828786 649.739486,813.293617 L649.739486,810.510555 C649.739486,808.975385 648.492684,807.727492 646.958859,807.727492 Z"
                                                            id="path-1"
                                                        />
                                                    </defs>
                                                    <g
                                                        id="Marketing-material's"
                                                        stroke="none"
                                                        strokeWidth={1}
                                                        fill="none"
                                                        fillRule="evenodd"
                                                    >
                                                        <g id="ideaicon" transform="translate(-631.000000, -798.000000)">
                                                            <mask id="mask-2" fill="white">
                                                                <use xlinkHref="#path-1" />
                                                            </mask>
                                                            <use
                                                                id="icon-apps"
                                                                fill="#6C757D"
                                                                fillRule="nonzero"
                                                                xlinkHref="#path-1"
                                                                className="ideaicon"
                                                            />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </span>
                                        </span>
                                        <span className="nav-text">Subscribed Apps</span>
                                    </Link>
                                </li> :

                                <li></li>}

                            <li className="nav-link">
                                {/* <Link to={'/apps/wizard'}> */}
                                <Link to={redirectToOffers()}>
                                    <span className="nav-icon"><StarFilled /></span>
                                    <span className="nav-text">Offers</span>
                                </Link>
                            </li>

                            {/* {(AuthHandler.isAccessible(auth, 'marketing')) ?
                                <li className="nav-link">
                                    <a >
                                        <span className="nav-icon"><i className="bi bi-megaphone-fill" /></span>
                                        <span className="nav-text">Marketing</span>
                                    </a>
                                </li>
                                :
                                <li></li>} */}
                            {/* {(AuthHandler.isAccessible(auth, 'analytics')) ?
                                <li className="nav-link">
                                    <a >
                                        <span className="nav-icon"><i className="bi bi-clipboard-data-fill" /></span>
                                        <span className="nav-text">Analytics</span>
                                    </a>
                                    <ul />
                                </li>
                                :
                                <li></li>} */}
                            {/* {(AuthHandler.isAccessible(auth, 'notification')) ?
                                <li className="nav-link">
                                    <a >
                                        <span className="nav-icon"><i className="bi bi-bell-fill" /></span>
                                        <span className="nav-text">Notification</span>
                                    </a>
                                </li>
                                :
                                <li></li>} */}
                        </ul >
                    </div >
                    <div className="menu-link__wrapper">
                        <ul className="menu-links">
                            {
                                <>
                                    <li className="nav-link">
                                        {(entStatus == "Active" && AuthHandler.isAccessible(auth, 'settings')) &&
                                            <Link to={'/settings/business-details'}>
                                                <span className="nav-icon"><i className="bi bi-gear-fill" /></span>
                                                <span className="nav-text">Settings</span>
                                            </Link>
                                        }
                                    </li>
                                    {/* <li className="nav-link desk-d-none">
                                        {!keycloak && <a>
                                            <span className="nav-text"><button className="btn btn-primary" onClick={() => navigate('/signup')}>Sign Up</button></span>
                                            <span className="nav-text"><button className="btn btn-secondary" onClick={() => window.location.href = "/home"}>Sign in</button></span>
                                        </a>}
                                    </li> */}
                                </>
                            }
                        </ul>
                    </div>
                </div >
            </div >
        </nav >
    );
}

export { SideBar };
