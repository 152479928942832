import React, { useEffect, useState, FocusEvent } from 'react';

interface Props {
    children?: any,
    headerTitle?: string,
    headerSubTitle?: string,
    panelHeaderTitle?: string,
    searchValue?: string,
    onSearchValue?: (widgetType: string) => any,
    onChangeValue?: (value: string, widgetType: string) => any,
    searchAvailable?: boolean,
    widgetType?: string
}

const CardPanel = ({ children, headerTitle, headerSubTitle, panelHeaderTitle, searchValue, onSearchValue, onChangeValue, searchAvailable, widgetType }: Props) => {

    return (
        <React.Fragment>
            <div className="card panel content-holder-panels">
                <div className="panel-header">
                    <div className="panel-header-content__wrapper">
                        <h4 className="panel-header-title">{headerTitle}</h4>
                        <p className="panel-header-subtitle">
                            {headerSubTitle}
                        </p>
                    </div>
                </div>
                {/* panel table */}
                <div className="panel-table-listing__wrapper">
                    <div className="panel-table-listing">
                        <div className="panel-table-listing-header__wrapper">
                            <div className="panel-table-listing-header">
                                <div className="panel-table-listing-header__inner">
                                    <p>
                                        <strong>{panelHeaderTitle}</strong>
                                    </p>
                                </div>
                                {searchAvailable && <div className="panel-table-listing-header__inner">
                                    <div className="form-group__wrapper">
                                        <div className="form-group">
                                            <div className="form-element__wrapper ">
                                                <div className="icon-inside right">
                                                    {/*<span className="in-icon">*/}
                                                    {/*    <i className="bi bi-search" />*/}
                                                    {/*</span>*/}
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id=""
                                                        value={searchValue}
                                                        onChange={(e) => {
                                                            if (onChangeValue && widgetType) {
                                                                onChangeValue(e.target.value, widgetType)
                                                            }
                                                        }}
                                                        // onBlur={() => {
                                                        //     if (onSearchValue && widgetType) {
                                                        //         onSearchValue(widgetType)
                                                        //     }
                                                        // }}
                                                        onKeyDown={event => {
                                                            if (event.key === 'Enter') {
                                                                if (onSearchValue && widgetType) {
                                                                    onSearchValue(widgetType)
                                                                }
                                                            }
                                                        }}
                                                        placeholder="Search by keywords"
                                                        spellCheck="false"
                                                        data-ms-editor="true"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                        <div className="panel-table-content-list">
                            {children}
                        </div>
                    </div>
                </div>
                {/* panel table */}
            </div>
        </React.Fragment>
    );
}

export { CardPanel };