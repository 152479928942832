/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  getPermissionDetails,
  editPermissionDetails,
  fetchPartnerRequests,
  rewokePartnerRequest,
  newPartnerRequest,
  removePartner,
  changePartnerAccessLevel,
  fetchPartnerDetails,
  fetchOwnerDetails,
  editOwnerDetails
} from '../../store/actions'
import { profileAndUserPermissionModel, } from '../home/models/request/profileAndUserPermission.model';
import { OwnerDetailsModel } from './models/response/owner-details.model';
import Auth from '../../models/response/auth.model';
import { AutoComplete, Input, message, Tooltip } from 'antd';
import { useAppDispatch } from '../../store';
import axios from 'axios';
import SimpleReactValidator from 'simple-react-validator';
import { InputTextField } from '../../components';
import { getPartnerSearchSuggestions, sendPartnerRequest } from '../../services/settings.service';
import * as CONSTANTS from '../../constants';
import RoleAddImage from '../../assets/images/featured-cover-role-add.png';
import Owner from '../../assets/images/icons/access-owner-full.svg';
import { PartnerRequestModel } from './models/response/partner-requests';
import { Link } from 'react-router-dom';
import ownerBadge from "./../../assets/images/icons/access-owner-full.svg";
import partnerBadge from "./../../assets/images/icons/access-partner-full.svg";
import adminBadge from "./../../assets/images/icons/access-admin-full.svg";
import * as Constants from "../../constants";
import { validate } from 'uuid';

type Props = {
  auth: Auth
}

const mockVal = (str: string, repeat = 1) => ({
  value: str.repeat(repeat),
});

interface AutocompleteItem {
  status: string
}

interface PartnerData {
  id: number;
  uuid: string;
  accessLevel: string;
  status: string | null;
  subsWidgetList: any[];
  contact: string;
  email: string;
  firstName: string;
  lastName: string;
}

interface Partner {
  firstName: string,
  lastName: string,
  uuid: string
}

const ProfileAndUserPermission = ({ auth }: Props) => {

  const dispatch = useAppDispatch();
  const { partnerRequestDetails, partnerDetails, partnerAccessChanged, ownerDetails } = useSelector<any, any>(({ settings }) => settings);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const [getIsEditDetailsClicked, setSsEditDetailsClicked] = useState(false);
  const [ownerDetailsChanged, setOwnerDetailsChanged] = useState(false);

  const [profileAndUserPermissionDetials, setProfileAndUserPermissionDetials] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contact: "",
    nic: "",
    partnerToAdmin: "",
    adminToPartner: ""
  });

  // const { isEditDetailsClicked, profileAndUserPermission } = useSelector<any, any>((state) => state.settings.isEditDetailsClicked);

  const [partnerRequestDetailsData, setPartnerRequestDetailsData] = useState({
    partnerFirstName: "",
    partnerLastName: "",
    logo: "",
    uuid: ""
  });

  const [ownerDetailsData, setOwnerDetailsData] = useState({
    ownerFirstName: "",
    ownerLastName: "",
    ownerEmail: "",
    ownerMobile: "",
    logo: "",
    ownerNic: "",
    partnerToAdmin: "",
    adminToPartner: ""
  })

  const [partnerDetailsData, setPartnerDetailsData] = useState<PartnerData[]>([]);

  const [accessLevelList, setaccessLevelList] = useState<string[]>([])

  const [userProfileValidator] = useState(new SimpleReactValidator({ autoForceUpdate: this }))

  const [selectedPartner, setSelectedPartner] = useState("");

  const [adminUserId, setAdminUserId] = useState("");

  const [isAdminToPartner, setIsAdminToPartner] = useState(false);
  const [isPartnerToAdmin, setIsPartnerToAdmin] = useState(false);

  const [showPartnerFlow, setShowPartnerFlaw] = useState(true);

  const [groupId, setGroupId] = useState("");
  const [userId, setUserId] = useState("");

  const useForceUpdate = () => {
    const [value, setValue] = useState(0);
    return () => setValue(value => value + 1);
  }

  const forceUpdate = useForceUpdate()

  const {
    userDetails
  } = useSelector<any, any>(({ user }) => user);

  const [viewAll, setViewAll] = useState({
    isClicked: false,
    partnerId: ''
  });

  useEffect(() => {
    if (userDetails && userDetails.OwnedEnterprises[0]) {
      //setGroupId(userDetails.OwnedEnterprises[0]);
      setUserId(JSON.parse(JSON.stringify(userDetails)).uuid);
    }
  }, [userDetails])


  useEffect(() => {
    const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
    setaccessLevelList(["admin", "partner"]);
    if (tenantGroupId) {
      dispatch<any>(fetchOwnerDetails(tenantGroupId));
      dispatch<any>(fetchPartnerDetails(tenantGroupId));
    }
  }, [userDetails, getIsEditDetailsClicked])

  useEffect(() => {
    (Array.isArray(partnerDetailsData) && partnerDetailsData.length > 0) ? partnerDetailsData.filter((item: any) => item && (item.status == 'Active' || item.status == null || item.status == 'null')).map((item: any, index: number) => {
      if (item.accessLevel === "admin") {
        setAdminUserId(item.id);
        //setIsAdminToPartner(true);
        // console.log("Admin user id:", adminUserId);
      }
    }) : forceUpdate();
  }, [partnerDetails, partnerAccessChanged])


  useEffect(() => {
    setOwnerDetailsData({
      ...ownerDetailsData,
      ownerFirstName: ownerDetails ? ownerDetails.ownerFirstName : "",
      ownerLastName: ownerDetails ? ownerDetails.ownerLastName : "",
      ownerEmail: ownerDetails ? ownerDetails.ownerEmail : "",
      ownerMobile: ownerDetails ? ownerDetails.ownerMobile : "",
      ownerNic: ""

    })
  }, [ownerDetails])

  useEffect(() => {
    const partnerArray = partnerDetails;
    if (Array.isArray(partnerArray) && partnerArray.length > 0) {
      setIsAdminToPartner(false);

      const groupedItems = partnerArray.reduce((acc: any, item: any) => {
        const { uuid } = item;
        if (!acc[uuid]) {
          acc[uuid] = [];
        }
        acc[uuid].push(item);
        return acc;
      }, {});

      const adminItems: any[] = [];
      Object.values(groupedItems).forEach((group: any) => {
        const adminItem = group.find((item: any) => item && item.accessLevel === "admin" && (item.status === "Active" || item.status === "null" || item.status === null));
        const partnerItem = group.find((item: any) => item && item.accessLevel === "partner" && (item.status === "Active" || item.status === "null" || item.status === null));
        if (adminItem) {
          adminItems.push(adminItem);
        } else {
          adminItems.push(partnerItem);
        }

      });

      setPartnerDetailsData(adminItems);
    }
  }, [partnerDetails, getIsEditDetailsClicked]);

  const onEditDetailsSave = () => {
    let request: any = {
      ownerFirstName: ownerDetailsData.ownerFirstName,
      ownerLastName: ownerDetailsData.ownerLastName,
    }

    let userId = userDetails.uuid;

    dispatch<any>(editOwnerDetails(request, userId))
  }

  const onEditPartnerDetailsSave = () => {
    const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
    if (userProfileValidator.allValid()) {
      let req: any;
      if (isPartnerToAdmin) {
        req = {
          partnerToAdmin: adminUserId
        }
      } else {
        req = {
          adminToPartner: adminUserId
        }
      }
      dispatch<any>(changePartnerAccessLevel(tenantGroupId, req));
      dispatch<any>(fetchPartnerDetails(tenantGroupId));
      setSsEditDetailsClicked(false);
    } else {
      userProfileValidator.showMessages()
      forceUpdate()
    }
  }

  const onHandleChange = (name: string) => (event: any) => {
    setOwnerDetailsData({ ...ownerDetailsData, [name]: event.target.value });
    setOwnerDetailsChanged(true);
  };

  const onHandleChangeAccessLevel = (name: string, uuid: string, userId: string, isPartnerToAdmin: boolean) => (event: any) => {
    setPartnerDetailsData(partnerDetailsData => partnerDetailsData.map(partner => {
      if (partner) {
        if (partner.id.toString() != userId && partner.accessLevel == "admin") {
          return { ...partner, accessLevel: "partner" };
        }
      }
      return partner;
    }));


    setPartnerDetailsData(partnerDetailsData => partnerDetailsData.map(partner => {
      if (partner) {
        if (partner.uuid === uuid) {
          return { ...partner, accessLevel: event.target.value };
        }
      }
      return partner;
    }));

    if (isPartnerToAdmin) {
      const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
      setIsPartnerToAdmin(true);
      setAdminUserId(userId);

    }
  };

  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [newPartnerUuid, setNewpartnerUuid] = useState('');

  useEffect(() => {
    const fetchSuggestions = async () => {
      try {

        const response = await getPartnerSearchSuggestions(inputValue).then((response) => {
          return response;
        }).catch((error: any) => {
          return error;
        })

        const partners = response.data.data.filter((data: any) =>
          data.uuid != Constants.REACT_APP_COMMON_AGENT_USER_UUID
        );
        setSuggestions(partners);
        //setNewpartnerUuid(extractedUuid);
        setShowDropdown(true);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    };

    // Only fetch suggestions if there is input value
    if (inputValue) {
      fetchSuggestions();
    } else {
      setSuggestions([]);
      setNewpartnerUuid('');
      setShowDropdown(false);
    }
  }, [inputValue]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);

  };

  const setPartnerRequest = () => {
    const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
    if (tenantGroupId) {
      dispatch<any>(fetchPartnerRequests(tenantGroupId));
    }
    setaccessLevelList(["partner", "admin"]);
  }

  useEffect(() => {
    setPartnerRequest();
  }, [userDetails, getIsEditDetailsClicked]);

  const handleRewokeRequest = (uuid: string) => {
    const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
    const req = {
      status: "Inactive"
    }
    // console.log("rewoke partner uuid", uuid);
    dispatch<any>(rewokePartnerRequest(req, tenantGroupId, uuid));
    // alert("Rewoke request sent");
  }

  const renderAccessLevelList = () => {
    let list: JSX.Element[] = [<option key={'-1'} value="" disabled hidden>Not selected</option>]
    accessLevelList.map((accessLevelList, index) => {
      list.push(<option key={index} value={accessLevelList}>{accessLevelList}</option>)
    })
    return list
  }

  const handleDropDownItem = (item: any) => {
    setInputValue(item.firstName + " " + item.lastName);
    setSuggestions([]);
    setShowDropdown(false);
    setNewpartnerUuid(item.uuid);
    // console.log("dropdownd selected uuid", newPartnerUuid);
  }

  function handleSendInvitation(): void {
    const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
    // if (Array.isArray(partnerRequestDetails) && partnerRequestDetails.length > 0) {
    //   message.warning("You have already requested to add a marketing agent. Please wait for the approval.")
    // } else {
    const filteredData = partnerDetailsData.filter((item) => item && (item.status == 'Active' || item.status == null || item.status == 'null') && item.uuid === newPartnerUuid);

    if (filteredData.length > 0) {
      message.warning("The partner you entered already exists. Please try another partner");
    } else {
      dispatch<any>(newPartnerRequest("Pending", tenantGroupId, newPartnerUuid));
    }
    // }
    setInputValue('');
  }

  function handleRemovePartner(accessLevel: string, uuid: string): void {
    const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
    dispatch<any>(removePartner(tenantGroupId, uuid, accessLevel));
  }

  const onPressViewAll = (e: SyntheticEvent, partnerId: string) => {
    e.preventDefault();
    console.log("view all before:" + JSON.stringify(viewAll));
    setViewAll({
      ...viewAll,
      isClicked: true,
      partnerId: partnerId
    });
  }

  const onPressClose = () => {
    setViewAll({
      ...viewAll,
      isClicked: false,
      partnerId: ""
    });
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      handleClickOutside(event);
    };

    if (showDropdown) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [showDropdown]);


  return (
    <div className="sub-nav-inner-container__wrapper">
      <div className="sub-nav-inner-container content-holder">
        <div className="content-holder-header__wrapper">
          <div className="content-holder-header">
            <h4 className="inner-title">Profile &amp; User permission</h4>
            {getIsEditDetailsClicked &&
              <button className="btn back-btn" onClick={() => setSsEditDetailsClicked(false)}>&#x2715;&nbsp;Close </button>
            }
          </div>
        </div>
        {/* business details preview - step 1 */}
        {!getIsEditDetailsClicked &&
          <div className="card panel content-holder-panels">
            <div className="top-profile-details__wrapper">
              <div className="top-profile-details">
                <div className="profile-placeholder__wrapper left-align business-placeholder__wrapper">
                  <div className="profile-img-holder xlg" style={{ backgroundImage: `url()` }}>
                    {(ownerDetailsData.ownerFirstName && ownerDetailsData.ownerLastName) ? (ownerDetailsData.ownerFirstName.charAt(0)).toUpperCase() + (ownerDetailsData.ownerLastName.charAt(0)).toUpperCase() : ""}
                  </div>
                  <div className="profile-details txt-black">
                    <span className="main-title mt-bg">{ownerDetailsData.ownerFirstName} {ownerDetailsData.ownerLastName}</span>
                    <span className="sub-title">
                      <div className="access-control__wrapper">
                        <div className="access-badge__wrapper owner">
                          <div className="access-icon owner"><img src={require('../../assets/images/icons/access-owner-full.svg').default} alt="" /></div>
                        </div>
                      </div>
                    </span>
                    {ownerDetailsData.ownerEmail &&
                      <span className="sub-title">
                        <span className="shade-icon__wrapper round">
                          <i className="bi bi-envelope-fill" />
                        </span>{ownerDetailsData.ownerEmail}
                      </span>}
                  </div>
                  {showPartnerFlow &&
                    <button className="btn btn-secondary btn-xsmall details-edit-btn" onClick={() => setSsEditDetailsClicked(true)}><i className="bi bi-pencil-fill" />&nbsp;Edit details</button>
                  }
                </div>
              </div>
            </div>
            <>
              <div className="role-details__wrapper">
                <h4 className="role-details__title">Partner 1</h4>
                <div className="role-details-inner__wrapper">
                  <div className="profile-placeholder__wrapper left-align txt-black">
                    <div className="profile-img-holder sm" style={{ backgroundImage: 'url()' }}>
                      {(ownerDetailsData.ownerFirstName && ownerDetailsData.ownerLastName) ? (ownerDetailsData.ownerFirstName.charAt(0)).toUpperCase() + (ownerDetailsData.ownerLastName.charAt(0)).toUpperCase() : ""}
                    </div>
                    <div className="profile-details txt-black">
                      <span className="main-title">{ownerDetailsData.ownerFirstName} {ownerDetailsData.ownerLastName} (You)<button className="btn btn-info btn-round btn-xsmall"><i className="bi bi-patch-check-fill" /></button></span>
                      <span className="sub-title"><a className="txt-black">{ownerDetailsData.ownerEmail}</a></span>
                      <span className="sub-title"><a className="txt-black">{ownerDetailsData.ownerMobile}</a></span>
                    </div>
                  </div>
                  <div className="access-control__wrapper">
                    <div className="access-badge__wrapper owner">
                      <div className="access-icon owner"><img src={ownerBadge} alt="" srcSet="" /></div>
                    </div>
                  </div>
                </div>
              </div>
            </>
            {(Array.isArray(partnerDetailsData) && partnerDetailsData.length > 0) ? partnerDetailsData.filter((item: any) => item && (item.status == 'Active' || item.status == null || item.status == 'null') && item.uuid != CONSTANTS.REACT_APP_COMMON_AGENT_USER_UUID)
              .map((item: any, index: number) => {
                return <React.Fragment><div className="role-details__wrapper" key={index}>
                  <h4 className="role-details__title">Partner {index + 2}</h4>
                  <div className="role-details-inner__wrapper">
                    <div className="profile-placeholder__wrapper left-align txt-black">
                      <div className="profile-img-holder sm" style={{ backgroundImage: 'url()' }}>
                        {(item.firstName && item.lastName) ? (item.firstName.charAt(0)).toUpperCase() + (item.lastName.charAt(0)).toUpperCase() : ""}
                      </div>
                      <div className="profile-details txt-black">
                        <span className="main-title">{item.firstName} {item.lastName} <button className="btn btn-info btn-round btn-xsmall"><i className="bi bi-patch-check-fill" /></button></span>
                        <span className="sub-title"><a className="txt-black">{item.email}</a></span>
                        <span className="sub-title"><a className="txt-black">{item.cantact}</a></span>
                      </div>
                    </div>
                    <div className="access-control__wrapper">
                      <div className="access-badge__wrapper partner">
                        <div className="access-icon partner">
                          {item.accessLevel.toLowerCase() == 'admin' ? <img src={adminBadge} alt="" srcSet="" /> : <img src={partnerBadge} alt="" srcSet="" />}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="overview-list-element">
                    <div className="overview-list-content__wrapper">
                      <p>Assigned Apps</p>
                    </div>
                    <div className="overview-list-content__wrapper"><span className="stats-view">{item.subsWidgetList.length}</span>{(item.subsWidgetList.length > 0) && <a href="" onClick={(e) => onPressViewAll(e, item.id)}>View All</a>}</div>
                  </div>
                </div>
                  {(viewAll.isClicked && viewAll.partnerId == item.id) && <div
                    className="modal show scale-in "
                    role="dialog"
                    tabIndex={-1}
                  >
                    {/* modal set to default size, modal-xl will make it bigger, modal-sm will make it smallest*/}
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        {/* .no-liner will remove the bttom line from header*/}
                        <div className="modal-header ">
                          <div className="modal-header-content__wrapper">
                            <h4 className="modal-header-title">Subscribed Apps List</h4>
                          </div>
                          <div className="modal-header-content__wrapper">
                            <button className="btn btn-small primary-action" aria-label="close" onClick={onPressClose}>
                              ✕
                            </button>
                          </div>
                        </div>
                        <div className="modal-body">
                          <div className="panel-table-listing__wrapper">
                            <div className="panel-table-listing">
                              <div className="panel-table-content-list">
                                {item.subsWidgetList.map((item: any) => {
                                  return <div className="panel-table-list-item">
                                    <div className="panel-table-listing-item__inner">
                                      <div className="feature-details__wrapper">
                                        <div className="card app-media-holder" style={{ backgroundImage: `url(${item.thumbnail})` }}
                                        ></div>
                                        <div className="feature-details-title__wrapper no-shade">
                                          <span className="title">{item.name}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>}
                </React.Fragment>
              }) : null}


            <hr />
            <div className="role-details__wrapper">
              {/* <h4 className="role-details__title">Partner 2</h4>
              <div className="role-details-inner__wrapper">
                <div className="profile-placeholder__wrapper left-align txt-black">
                  <div className="profile-img-holder sm" style={{ backgroundImage: 'url()' }}>
                    SS
                  </div>
                  <div className="profile-details txt-black">
                    <span className="main-title">Svasthika Sehan<button className="btn btn-info btn-round btn-xsmall"><i className="bi bi-patch-check-fill" /></button></span>
                    <span className="sub-title "><a href="" className="txt-black">usermain@domain.com</a></span>
                    <span className="sub-title"><a href="" className="txt-black">07799999999</a></span>
                  </div>
                </div>
                <div className="access-control__wrapper">
                  <span className="badge badge-access partner"><span className="idea-icon idea-iconbriefcase" />&nbsp;Partner</span>
                </div>
              </div> */}
            </div>
            <div className="panel-footer">
              <span>Learn business owner  <Link to={'https://d2xt8w8fh2bnjt.cloudfront.net/qa/userguides/Terms.pdf'} target="_blank" rel="noopener noreferrer">permission &amp; terms</Link></span>
            </div>
          </div>
        }
        {/* / business details preview - step 1 */}
        {/* business partner/user role invitation */}

        {/* Below temporarily removed using showPartnerFlow flag */}
        {!getIsEditDetailsClicked &&
          <div className="card panel content-holder-panels">
            <div className="featured-panel-content__wrapper">
              <div className="featured-panel-content">
                <div className="featured-panel-content-text__wrapper">
                  <div className="featured-panel-content-text">
                    <h4 className="main-title mt-bg">Business Partner?</h4>
                    <div className="featured-panel-content-text-desc__wrapper">
                      <p>Seeking assistance or guidance from experts?</p>
                      <p>Manage your enterprise requirements through our Marketplace partners. Partners will receive commissions for overseeing enterprise applications.</p>
                    </div>
                    <a className="btn btn-outline btn-round" type="button" role="button" href={CONSTANTS.PARTNER_PORTAL_URL} target="_blank">Learn more about partner program<span className="arrow-head right" /></a>
                  </div>
                </div>
                <div className="featured-panel-content-cover__wrapper">
                  <div className="featured-panel-content-cover" style={{ backgroundImage: `url(${RoleAddImage})` }} />
                </div>
              </div>
            </div>
            <div className="featured-panel-content__wrapper">
              <div className="featured-panel-content">
                <div className="form-group__wrapper">
                  <div className="form-group">
                    <div className="form-element__wrapper mb-3 has-dropdown">
                      <label htmlFor="" className="form-label">Assign a new partner to your enterprise</label>
                      <div className="icon-inside left d-flex btn-with-group">
                        <span className="in-icon"><i className="bi bi-search" /></span>
                        <input type="text" className="form-control" id="userRoleAssignSearch" placeholder="Type partner name" spellCheck="false" data-ms-editor="true" value={inputValue} onChange={handleInputChange} autoComplete="off" />
                        <button className="btn btn-primary" onClick={() => handleSendInvitation()} disabled={!newPartnerUuid}>Send Invitation</button>
                      </div>
                      {suggestions && suggestions.length > 0 && (
                        <div className="dropdown" style={{ maxHeight: '200px', overflowY: 'auto' }} ref={dropdownRef}>
                          <ul className="dropdown-list">
                            {suggestions.map((item: Partner) => (
                              <li className="dropdown-item" key={item.uuid} onClick={() => handleDropDownItem(item)}><a href="javascript:void(0)">{item.firstName} {item.lastName}</a></li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group__wrapper">
                  <div className="form-group">
                    <div className="form-element__wrapper mb-3 ">
                      <div className="form-attachment__wrapper">
                        {Array.isArray(partnerRequestDetails) && partnerRequestDetails.length > 0 && <label htmlFor="" className="form-label">Sent Requests</label>}
                        {Array.isArray(partnerRequestDetails) && partnerRequestDetails.length > 0 && partnerRequestDetails.map((value: PartnerRequestModel, index: number) => {
                          return (
                            <div className="form-attachment d-flex flex-start" key={index}>
                              <div className="attachment-type-holder" style={{ backgroundImage: `url(${value.entLogo ? value.entLogo : ""})` }} />
                              <div className="attachment-details">
                                <h5 className="title">{value.userDetails.firstName} {value.userDetails.lastName}</h5>
                                <p>Partner</p>
                              </div>
                              <div className="attachment-action">
                                {/* <button className="btn btn-secondary btn-small" onClick={() => handleRewokeRequest(value.User.uuid)}>Rewoke Request</button> */}
                                <span>{value.status === "Requested" ? "Pending" : value.status}</span>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                {Array.isArray(partnerRequestDetails) && partnerRequestDetails.length > 0 && partnerRequestDetails.some(item => item.status === 'Requested') ?
                  <div className="alert warning">
                    <span className="alert-text__wrapper"><i className="alert-icon bi bi-exclamation-circle-fill" /><span className="alert-text">Pending Partner Request – This means your request has not yet been approved.</span></span>
                  </div>
                  :
                  <div className="alert info">
                    <span className="alert-text__wrapper"><i className="alert-icon bi bi-info-circle-fill" /><span className="alert-text">Your Partner – This partner is not granted any admin privileges. Granted admin privileges will provide the same access level as the owner. Admins cannot delete business profiles.</span></span>
                  </div>
                }

              </div>
            </div>
            <div className="panel-footer">
              <span>Learn business owner  <a href="https://d2xt8w8fh2bnjt.cloudfront.net/qa/userguides/Terms.pdf" target="_blank" rel="noopener noreferrer">permission &amp; terms</a></span>
            </div>
          </div>
        }
        {/* /Dialog Partner invitation */}
        {/* business details preview - step 1 */}
        {getIsEditDetailsClicked &&
          <div className="card panel content-holder-panels">
            <div className="panel-header">
              <div className="panel-header-content__wrapper">
                <h4 className="panel-header-title">Owner details</h4>
              </div>
            </div>
            <div className="panel-content">
              <div className="form-group__wrapper">
                <div className="form-group">
                  <div className="form-element__wrapper mb-3">
                    <InputTextField
                      name='userName'
                      label='User name'
                      placeholder='User Name'
                      value={userDetails.userName}
                      setValue={onHandleChange}
                      validator={userProfileValidator}
                      disabled={true}
                      status="default"
                      validationMessage="you can't keep this as empty" rules={[]}
                      forceUpdate={forceUpdate} />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-element__wrapper mb-3">
                    <InputTextField
                      name='Email'
                      label='Email'
                      placeholder='Email'
                      value={ownerDetailsData.ownerEmail}
                      setValue={onHandleChange}
                      validator={userProfileValidator}
                      disabled={true}
                      status="default"
                      validationMessage="you can't keep this as empty" rules={[]}
                      forceUpdate={forceUpdate} />
                  </div>
                </div>
              </div>
              <div className="form-group__wrapper">
                <div className="form-group">
                  <div className="form-element__wrapper mb-3">
                    <InputTextField
                      name='ownerFirstName'
                      label='First Name'
                      placeholder='First Name'
                      value={ownerDetailsData.ownerFirstName}
                      setValue={onHandleChange}
                      validator={userProfileValidator}
                      disabled={false}
                      status="default"
                      validationMessage="you can't keep this as empty" rules={[]}
                      forceUpdate={forceUpdate} />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-element__wrapper mb-3">
                    <InputTextField
                      name='ownerLastName'
                      label='Last Name'
                      placeholder='Last Name'
                      value={ownerDetailsData.ownerLastName}
                      setValue={onHandleChange}
                      validator={userProfileValidator}
                      disabled={false}
                      status="default"
                      validationMessage="you can't keep this as empty" rules={[]}
                      forceUpdate={forceUpdate} />
                  </div>
                </div>
              </div>
              <div className="form-group__wrapper">
                <div className="form-group">
                  <div className="form-element__wrapper mb-3">
                    <InputTextField
                      name='ownerMobile'
                      label='Mobile'
                      placeholder='Mobile Number'
                      value={ownerDetailsData.ownerMobile}
                      setValue={onHandleChange}
                      validator={userProfileValidator}
                      disabled={true}
                      status="default"
                      validationMessage="you can't keep this as empty" rules={[]}
                      forceUpdate={forceUpdate}
                    />
                  </div>
                </div>
              </div>
              <div className="panel-bottom-btn__wrapper">
                <button className="btn btn-secondary" onClick={() => setSsEditDetailsClicked(false)}>Cancel</button><button
                  className="btn btn-primary"
                  disabled={!ownerDetailsChanged || !ownerDetailsData.ownerFirstName || !ownerDetailsData.ownerLastName || ownerDetailsData.ownerFirstName.trim() == "" || ownerDetailsData.ownerLastName.trim() == "" || !userProfileValidator.allValid()}
                  onClick={onEditDetailsSave}>
                  Save &amp; Continue
                </button>
              </div>
              {/* <div className="alert info">
                <span className="alert-text__wrapper"><i className="alert-icon bi bi-info-circle-fill" /><span className="alert-text">Note - Use 'Edit Details' option to change user account details & modify partner privileges. Edit user account details is currently unavailable.</span></span>
              </div> */}
            </div>
            <hr />
            {(Array.isArray(partnerDetailsData) && partnerDetailsData.length > 0) && <div className="role-details__wrapper">
              <h4 className="role-details__title">Change partner role settings</h4>
              {partnerDetailsData.filter((item: any) => item && (item.status == 'Active' || item.status == null || item.status == 'null') && item.uuid != CONSTANTS.REACT_APP_COMMON_AGENT_USER_UUID)
                .map((item: any, index: number) => {
                  return <React.Fragment>
                    <div className="role-details-inner__wrapper">
                      <div className="profile-placeholder__wrapper left-align txt-black">
                        <div className="profile-img-holder sm" style={{ backgroundImage: 'url()' }}>
                          {(item.firstName && item.lastName) ? (item.firstName.charAt(0)).toUpperCase() + (item.lastName.charAt(0)).toUpperCase() : ""}
                        </div>
                        <div className="profile-details txt-black">
                          <span className="main-title">{item.firstName} {item.lastName} <button className="btn btn-info btn-round btn-xsmall"><i className="bi bi-patch-check-fill" /></button></span>
                          <span className="sub-title"><a href="" className="txt-black">{item.email}</a></span>
                          <span className="sub-title"><a href="" className="txt-black">{item.contact}</a></span>
                        </div>
                      </div>
                      <div className="access-control__wrapper ">
                        <div className="form-group__wrapper">
                          <div className="form-group">
                            <div className="form-element__wrapper mb-3">
                              <label htmlFor="" className="form-label">Access level <Tooltip
                                title={<p>Note: If you provide admin rights, your partner will be having enough rights to configure any application within the portal. All cost will be deducted.</p>}>
                                <span><i className="bi bi-info-circle"></i></span>
                              </Tooltip></label>
                              <div className="icon-inside right">
                                {/* <span className="in-icon"><i className="bi bi-chevron-down" /></span>
                              <input type="text" className="form-control" id="" placeholder="Not selected" spellCheck="false" data-ms-editor="true" defaultValue="Partner" /> */}
                                <select name="industry" id="" className="form-control" value={item.accessLevel}
                                  onChange={onHandleChangeAccessLevel('accessLevel', item.uuid, item.id, item.accessLevel == "partner" ? true : false)}
                                >
                                  {renderAccessLevelList()}
                                </select>
                              </div>
                            </div>
                          </div>
                          {/* <div className="form-group">
                            <label htmlFor="" className="form-label">Remove Partner</label>
                            <div className="form-element__wrapper mb-3 ">
                              <button className="btn btn-outline-red"><i className="bi bi-trash-fill" onClick={() => handleRemovePartner(item.accessLevel, item.uuid)} />&nbsp;Remove</button>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>

                  </React.Fragment>
                })} </div>}

            {(Array.isArray(partnerDetailsData) && partnerDetailsData.length > 0) && <div className="panel-bottom-btn__wrapper">
              <button className="btn btn-secondary" onClick={() => setSsEditDetailsClicked(false)}>Cancel</button>
              <button className="btn btn-primary" onClick={() => onEditPartnerDetailsSave()} >Save & Continue</button>
            </div>}
            <div className="panel-footer">
              <span>Learn business owner  <a href="https://d2xt8w8fh2bnjt.cloudfront.net/qa/userguides/Terms.pdf" target="_blank" rel="noopener noreferrer">permission &amp; terms</a></span>
            </div>
          </div>
        }
        {/* / business details preview - step 1 */}
      </div>
    </div>
  );
}

export default ProfileAndUserPermission;
