import TitleBanner from "./components/titleBanner";
import ToAppsCard from "./components/card/ToAppsCard";
import {useEffect, useState} from "react";
import {getAllApps, getCategories, getTopApps} from "../../services/app.service";
import AllAppsCard from "./components/card/AllAppsCard";
import CategoryCard from "./components/card/CategoryCard";

const FilterApps = () => {
    const [topAppList, setTopAppList] = useState<string[]>([]);
    const [allAppList, setAllAppList] = useState<string[]>([]);
    const [categoriesList, setCategoriesList] = useState<string[]>([]);

    const fetchTopAppListData = () => {
        getTopApps()
            .then((response) => {
                if (response.status === 200) {
                    setTopAppList(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const fetchAllAppListData = () => {
        getAllApps()
            .then((response) => {
                if (response.status === 200) {
                    setAllAppList(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const fetchCategoryListData = () => {
        getCategories()
            .then((response) => {
                if (response.status === 200) {
                    setCategoriesList(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        fetchTopAppListData();
        fetchAllAppListData();
        fetchCategoryListData();
    }, []);

    return (
        <section className="content__wrapper">
            <TitleBanner/>
            <div className="container inner-container">
                <div className="inner-content-section__wrapper">
                    <div className="app-listing-section__wrapper type-1">
                        <div className="app-listing-section-title__wrapper">
                            <span>Top Apps for Startups</span>
                        </div>
                        <div className="app-listing-section not-at-right type-1-inner" id="listingScrollDiv">
                            {
                                topAppList.length !== 0 &&
                                topAppList.map((row, index) => (
                                    <ToAppsCard app={row}/>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className=" app-listing-section__wrapper type-2 full-bleed__wrapper light">
                <div className="container inner-container full-bleed ">
                    <div className="app-listing-section-title__wrapper">
                        <span>All Apps</span>
                    </div>
                    <div className="app-listing-section not-at-right type-2-inner">
                        {
                            allAppList.length !== 0 &&
                            allAppList.map((row, index) => (
                                <AllAppsCard app={row}/>
                            ))
                        }
                    </div>
                </div>
            </div>

            <div className=" app-listing-section__wrapper type-5 full-bleed__wrapper light">
                <div className="container inner-container full-bleed ">
                    <div className="app-listing-section-title__wrapper">
                        <span>All Categories</span>
                    </div>
                    <div className="app-listing-section not-at-right type-5-inner">
                        {
                            categoriesList.length !== 0 &&
                            categoriesList.map((row, index) => (
                                <CategoryCard category={row}/>
                            ))
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FilterApps;