import React from "react";
import Visa from '../../../assets/images/icons/pay-icon-visa.svg';
import Master from '../../../assets/images/icons/payment-icon-mastercard.svg';
import AddToBill from '../../../assets/images/icons/pay-icon-addtobill.svg';
interface Prop {
    paymentDetails: any;
    mode: string,
    onChangeNumberStatus: (e:any, paymentDetails: any, index: number) => void
    index: number,
    onPressDelete: (index: number, type: string) => void
}

function PaymentMethodItem({ paymentDetails, mode, onChangeNumberStatus, index, onPressDelete }: Prop) {
    const renderStatus = () => {
        if (mode == 'view') {
            if (paymentDetails.maskCardNo) {
                return (
                    <span className="step-status-badge"><span className="status-icon__wrapper txt-green"><i className="bi bi-check-circle-fill" /></span>{paymentDetails.cardStatus == "Active" ? "In use" : paymentDetails.cardStatus == "Pending" ? "Pending" : "Inactive"}</span>
                )
            } else {
                return (
                    <span className="step-status-badge"><span className="status-icon__wrapper txt-green"><i className="bi bi-check-circle-fill" /></span>{paymentDetails.status == "Active" ? "In use" : paymentDetails.status == "Pending" ? "Pending" : "Inactive"}</span>
                )
            }
        } else if(mode == 'edit') {
            return (
                <a onClick={() => onPressDelete(index, paymentDetails.maskCardNo ? "card" : "msisdn")} className="txt-red" type="button" style={{ pointerEvents: paymentDetails.isPrimary ? 'none' : 'all' }}><i className="bi bi-trash-fill"></i>&nbsp;Remove</a>
            )
        }
    }

    return <div className="panel-table-list-item">
        <div className="panel-table-listing-item__inner">
            <div className="feature-details__wrapper">
                <div className="card app-media-holder" style={{ backgroundImage: paymentDetails.maskCardNo ? (paymentDetails.cardType === 'Visa' ? `url(${Visa})` : `url(${Master})`) : `url(${AddToBill})` }}>
                </div>
                <div className="feature-details-title__wrapper no-shade">
                    <span className="title">{paymentDetails.maskCardNo ? paymentDetails.maskCardNo : paymentDetails.msisdn}</span>
                </div>
            </div>
        </div>
        <div className="panel-table-listing-item__inner">
            <div className="panel-table-right-align-item__wrapper">
                <div className="panel-table-right-align-item">
                    {mode === 'view' && <p className="">
                        <i className={paymentDetails.isPrimary ? "bi bi-1-circle-fill" : "bi bi-2-circle-fill"} />
                        &nbsp;{paymentDetails.isPrimary ? "Primary" : "Secondary"}
                    </p>}
                </div>
                <div className="panel-table-right-align-item">
                    {renderStatus()}
                </div>
            </div>
        </div>
    </div>
}

export default PaymentMethodItem;