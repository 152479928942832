export const enum AccountCreationSectionsEnums {
    HOME_ACCOUNT_TYPE = 'home-account-type',
    HOME_YOUR_PROFILE = 'home-your-profile',
    HOME_BUSINESS_PROFILE = 'home-business-profile',
    HOME_TERMS_AND_CONDITIONS = 'home-terms-and-conditions',
}

export const enum TextFieldStatusEnums {
    VERIFIED = 'verified',
    INVALID = 'invalid',
    NOT_VERIFIED = 'notVerified',
}

export const enum EmailStatusEnums {
    VERIFIED = 'VERIFIED',
    PENDING = 'PENDING',
    VALIDATED = 'VALIDATED',
}

export const enum MsisdnStatusEnums {
    VERIFIED = 'VERIFIED',
    PENDING = 'PENDING',
    VALIDATED = 'VALIDATED',
    INVALID = 'INVALID',
}

export const enum WidgetTypes {
    PER_USAGE = 'PER_USAGE',
    NO_CHARGE = 'NO_CHARGE',
    SUBSCRIPTION_BASED = 'SUBSCRIPTION_BASED',
    ONETIME = 'ONETIME'
}

export const enum PaymentMethodTitles {
    ADD_TO_BILL = 'AddToBill',
    CARD_PAYMENT = 'Card payment',
    INVOICE = 'Invoice'
}