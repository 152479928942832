import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBusinessesList } from '../../store/actions';

const mockBusinessList = [
    {
        name: "Iron co enterprises(pvt)LTD",
        desc: "Own by Sukumala Kalukumara"
    },
    {
        name: "Softlogic Sri lanka(pvt)LTD",
        desc: "Own by Softlogic Group"
    },
    {
        name: "Sleek Camelia Hotel - Pinnawala",
        desc: "Sleek Hotel Group - Kegalle"
    }
];

function Businesses() {

    const settings = useSelector((state: any) => state.settings);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch<any>(fetchBusinessesList());
    }, []);

    return (<div className="sub-nav-inner-container__wrapper">
        <div className="sub-nav-inner-container content-holder">
            <div className="content-holder-header__wrapper">
                <div className="content-holder-header">
                    <h4 className="inner-title">Businesses</h4>
                </div>
            </div>
            {/* business details preview - step 1 */}
            <div className="card panel content-holder-panels">
                <div className="panel-header">
                    <div className="panel-header-content__wrapper">
                        <h4 className="panel-header-title">
                            List of all Businesses you control
                        </h4>
                    </div>
                </div>
                {/* panel table */}
                <div className="panel-table-listing__wrapper">
                    <div className="panel-table-listing">
                        <div className="panel-table-listing-header__wrapper">
                            <div className="panel-table-listing-header">
                                <div className="panel-table-listing-header__inner">
                                    <p>
                                        <strong>{mockBusinessList.length} Total </strong> Busiesses
                                    </p>
                                </div>
                                <div className="panel-table-listing-header__inner">
                                    <div className="form-group__wrapper">
                                        <div className="form-group">
                                            <div className="form-element__wrapper ">
                                                <div className="icon-inside right">
                                                    <span className="in-icon">
                                                        <i className="bi bi-chevron-down" />
                                                    </span>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id=""
                                                        placeholder="Not selected"
                                                        spellCheck="false"
                                                        data-ms-editor="true"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="panel-table-content-list">
                            {/* item */}
                            {mockBusinessList.map((item, index) => {
                                return <div className="panel-table-list-item" key={index}>
                                    <div className="panel-table-listing-item__inner">
                                        <div className="feature-details__wrapper">
                                            <div
                                                className="card app-media-holder"
                                                style={{
                                                    backgroundImage:
                                                        "url(/src/assets/images/icons/app-media-placeholder.svg)"
                                                }}
                                            ></div>
                                            <div className="feature-details-title__wrapper">
                                                <span className="title">{item.name}</span>
                                                <span className="small-desc">{item.desc}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel-table-listing-item__inner">
                                        <div className="panel-table-right-align-item__wrapper">
                                            {/* <div className="panel-table-right-align-item">
                                                <a href="" className="" type="button">
                                                    {" "}
                                                    Contact Owner
                                                </a>
                                            </div>
                                            <div className="panel-table-right-align-item">
                                                <a href="" className="" type="button">
                                                    {" "}
                                                    Settings
                                                </a>
                                            </div>
                                            <div className="panel-table-right-align-item">
                                                <a href="" className="txt-red" type="button">
                                                    <i className="bi bi-escape" />
                                                    &nbsp;Leave
                                                </a>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
                {/* panel table */}
                <div className="panel-footer">
                    <span>
                        Learn business owner/ partner/ admin{" "}
                        <a href="#" target="_blank" rel="noopener noreferrer">
                            permission &amp; terms
                        </a>
                    </span>
                </div>
            </div>
            {/* / business details preview - step 1 */}
        </div>
    </div>

    );
}

export default Businesses;