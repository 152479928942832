import React from 'react'

type Props = {}

const AccountCreationLoading = (props: Props) => {
    return (
        <main className="account-creation ac-status">
            <section className="ac-status__wrapper">
                <div className="ac-status-animation__wrapper">
                    {/* <div className="loader-5 center"><span></span></div> */}
                    <div className="circle-loader">
                        {/* <div className="checkmark"></div> */}
                    </div>
                </div>
                <h2 className="title"> <span>We are</span> <span>creating</span> <span>your account,</span> <br /> almost there...</h2>
                {/* <p className="sub-title">Redirecting to Marketplace dashboard....</p> */}
            </section>
            {/* success message status update*/}
            {/* status js lib -- 
            https://codepen.io/alimuzzaman/pen/LyoyPj
        */}
        </main>
    )
}

export { AccountCreationLoading }