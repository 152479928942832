import _ from 'lodash';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../store';
import { fetchTopApps, fetchCategories, fetchFeatureApps, fetchUserDetails, fetchAllWidgets, fetchTrendingApps, fetchAllOffers, fetchAppList } from '../../store/actions';
import {
    InnerTopFeatureItem,
    PricingComponent,
    SliderListingItem,
    SupportItem
} from './../single-app/components'
import Auth from '../../models/response/auth.model';
import { RatePlanModel } from './../single-app/models/response/rate-plan.model';
import { SingleAppModel } from './../single-app/models/response/single-app.model';
import { AppWizardModel, FeatureAppModel } from './../discover/models/response/app-wizard.model';
import { CategoryListModel, CategoryModel } from './models/response/app-category.model';
import SingleApp from '../single-app/SingleApp';
import BackgroundImage from '../../assets/images/ZOHO-mails-2.webp';
type Props = {
    auth: Auth
}

const DiscoverMoreApps = ({ auth }: Props) => {
    const { wuuid } = useParams();

    const [appsList, setAppList] = useState<Array<any>>([]);
    const [featureAppDetails, setFeatureAppDetails] = useState<FeatureAppModel | null>(null);
    const [categoryItems, setCategoryItems] = useState<CategoryListModel | null>(null);
    const [topAppsOffset, setTopAppsOffset] = useState<number>(0);
    const [topAppsLimit, setTopAppsLimit] = useState<number>(9);
    const [newAppsOffset, setNewAppsOffset] = useState<number>(0);
    const [newAppsLimit, setNewAppsLimit] = useState<number>(9);
    const [featuredAppsOffset, setFeaturedAppsOffset] = useState<number>(0);
    const [featuredAppsLimit, setFeaturedAppsLimit] = useState<number>(9);
    const [categoriesOffset, setCategoriesOffset] = useState<number>(0);
    const [categoriesLimit, setCategoriesLimit] = useState<number>(8);
    const [allAppsOffset, setAllAppsOffset] = useState<number>(0);
    const [allAppsLimit, setAllAppsLimit] = useState<number>(9);
    const [searchText, setSearchText] = useState("");
    const [limit, setLimit] = useState(20);
    const [offset, setOffset] = useState(0);

    const dispatch = useAppDispatch();

    const {
        featureAppListDetails,
        categoryList,
        loading,
        topAppsList,
        newApps,
        trendingApps,
        allApps
    } = useSelector<any, any>(({ appWizard }) => appWizard);

    const {
        authData
    } = useSelector<any, any>(({ authentication }) => authentication);

    const { userDetails } = useSelector<any, any>(({ user }) => user);

    const {
        allOffersList
    } = useSelector<any, any>(({ offers }) => offers);

    const {
        appList
    } = useSelector<any, any>(({ settings }) => settings);

    const navigate = useNavigate();
    var route = window.location.pathname.split('/')[2];
    var routeLogged = window.location.pathname.split('/')[3];

    useEffect(() => {
        if (route == 'top' || routeLogged == 'top') {
            dispatch(fetchTopApps(topAppsLimit, topAppsOffset));
        } else if (routeLogged == 'top') {
            dispatch(fetchTopApps(topAppsLimit, topAppsOffset));
        }
        else if (route == 'new' || routeLogged == 'new') {
            dispatch(fetchFeatureApps(featuredAppsLimit, featuredAppsOffset));
        } else if (route == 'categories' || routeLogged == 'categories') {
            dispatch(fetchCategories());
        } else if (route == 'all' || routeLogged == 'all') {
            dispatch(fetchAllWidgets(allAppsLimit, allAppsOffset));
        }
    }, [topAppsOffset, featuredAppsOffset, allAppsOffset]);


    useEffect(() => {
        console.log("url 3", routeLogged);
        if (route == 'top' || routeLogged == 'top') {
            dispatch(fetchTopApps(topAppsLimit, topAppsOffset));
        } else if (routeLogged == 'top') {
            dispatch(fetchTopApps(topAppsLimit, topAppsOffset));
        } else if (route == 'new' || routeLogged == 'new') {
            dispatch(fetchFeatureApps(featuredAppsLimit, featuredAppsOffset));
        } else if (route == 'categories' || routeLogged == 'categories') {
            dispatch(fetchCategories());
        } else if (route == 'all' || routeLogged == 'all') {
            dispatch(fetchAllWidgets(allAppsLimit, allAppsOffset));
        }
    }, []);

    useEffect(() => {
        if (route == 'top') {
            let newAppList = appsList.concat(topAppsList);
            let non_duplidated_data = _.uniqBy(newAppList, 'uuid');
            setAppList(non_duplidated_data);
        }
    }, [topAppsList]);

    useEffect(() => {
        if (route == 'new') {
            let newAppList = appsList.concat(featureAppListDetails);
            let non_duplidated_data = _.uniqBy(newAppList, 'uuid');
            setAppList(non_duplidated_data);
        }
    }, [featureAppListDetails]);

    useEffect(() => {
        if (route == 'all') {
            let newAppList = appsList.concat(allApps);
            let non_duplidated_data = _.uniqBy(newAppList, 'uuid');
            setAppList(non_duplidated_data);
        }
    }, [allApps]);

    const onLoadMore = () => {
        if (route == 'top') {
            setTopAppsOffset(topAppsOffset + 9);
        } else if (route == 'new') {
            setFeaturedAppsOffset(featuredAppsOffset + 9);
        } else if (route == 'all') {
            setAllAppsOffset(allAppsOffset + 9);
        }
    }

    useEffect(() => {
        let route = window.location.pathname.split('/')[1];
        let routeName = window.location.pathname.split('/')[2];
        //redirect to app categories
        if (route == 'apps' && routeName == 'categories' && authData) {
            navigate('/discover/apps/categories');
        } else if (route == 'apps' && routeName == 'top' && authData) {
            navigate('/discover/apps/top');
        }
        // else if (route == 'apps' && routeName == 'new' && authData){
        //     navigate('/discover/apps/new'); 
        // } else if (route == 'apps' && routeName == 'all' && authData){
        //     navigate('/discover/apps/all'); 
        // }

    }, []);

    useEffect(() => {
        const tenantGroupId = (userDetails && userDetails?.OwnedEnterprises[0]) ? userDetails?.OwnedEnterprises[0].groupId : "";
        dispatch(fetchAllOffers(userDetails?.uuid, tenantGroupId));
    }, [userDetails]);

    useEffect(() => {
        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        if (tenantGroupId !== "" && searchText == "") {
            dispatch<any>(fetchAppList(tenantGroupId, searchText !== "" ? searchText : '0', limit, offset, appList));
        }
    }, [userDetails, offset, searchText]);

    return (
        <div className="main-content main-spacer-x-y">
            <div className="inner-top-feature-holder" style={{ backgroundImage: `url(${require('../../assets/images/featured-cover-dialog-emp2.png')})` }}>
                <div className="container">
                    <button onClick={() => navigate(-1)} className="btn back-btn" aria-label="Back to the previous page"> <span /> Go back
                    </button>
                    <div className="inner-feature-content">
                        <h1 className="app-title" itemProp="name" aria-label="App title">{route == 'top' ? 'Top apps' : route == 'new' ? 'Featured Apps' : route == 'all' ? 'All Apps' : 'All categories'}</h1>
                        <span className="app-desc">See all the category type of apps available for you, or simply select your business type to see the available app specifically for you.</span>
                        <div className="app-stats__container inner-top">
                            <div className="left-content__wrapper">
                                <div className="app-stats__wrapper">
                                    <ul className="app-stats-list">
                                        <li className="app-stats-item">
                                            <div className="app-stats-inner__wrapper">
                                                {/* <span className="stats-value text-gradient-highlighter">80%
                                                    match+</span> */}
                                            </div>
                                            <div className="app-stats-inner__wrapper">
                                                {/* <span className="stats-desc">Your business type</span> <button className="btn btn-info btn-round btn-xsmall"><i className="bi bi-info-circle-fill" /></button> */}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {route !== 'categories' && Array.isArray(appsList) && appsList.length > 0 && <div className=" app-listing-section__wrapper type-2 full-bleed__wrapper light">
                <div className="container inner-container full-bleed ">
                    <div className="app-listing-section-title__wrapper">
                        <span>{route == 'top' ? 'Top apps' : route == 'new' ? 'Featured Apps' : route == 'all' ? 'All Apps' : 'All categories'}</span>
                    </div>
                    <div className="app-listing-section not-at-right type-2-inner">
                        {appsList.map((value: AppWizardModel, index: number) => {
                            const hasOffer = Array.isArray(allOffersList?.allOffersWidgetDetailsList) && allOffersList?.allOffersWidgetDetailsList.some((offer: { uuid: string | null; }) => offer.uuid === value.uuid);
                            const hasSubscribed = Array.isArray(appList) && appList.some((appId: { uuid: string | null; }) => appId.uuid === value.uuid);
                            
                            return (
                                <div className="app-listing-item__wrapper" key={index}>
                                    <a>
                                        <div className="app-listinng-item">
                                            <div className="app-item-content__wrapper app-details">
                                                <div className="card app-media-holder"
                                                    style={{ backgroundImage: `url(${value.thumbnail})` }}>
                                                    {hasOffer && !hasSubscribed &&
                                                        <div className="discount-label">Offer</div>
                                                    }
                                                </div>
                                                <div className="app-media-info-holder">
                                                    <Link to={`/widget/${value.uuid}`} className="app-media-title">{value.title}</Link>
                                                    <p className="app-media-desc">{value.description}</p>
                                                    <Link to={`/app-category/${value.uuid}`} className="app-media-category">{value.title} </Link>
                                                    <div className="app-stats__wrapper">
                                                        <ul className="app-stats-list">
                                                            {/* <li className="app-stats-item"><span className="stats-icon"><i className="bi bi-download" /></span><span className="stats-value">400+</span></li>
                                                        <li className="app-stats-item"><span className="stats-divider"><i className="bi bi-dot" /></span></li>
                                                        <li className="app-stats-item"><span className="stats-icon ico-highlighter"><i className="bi bi-star-fill" /></span><span className="stats-value">4.8</span></li> */}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )
                        })}
                    </div>
                    <div className="app-listing-section-footer">
                        <button className="btn btn-primary" onClick={onLoadMore}>View more</button>
                    </div>
                </div>
            </div>}
            {(route == 'categories' || routeLogged == 'categories') && Array.isArray(categoryList.data) && categoryList.data.length > 0 && <div className=" app-listing-section__wrapper type-5 full-bleed__wrapper light">
                <div className="container inner-container full-bleed ">
                    <div className="app-listing-section-title__wrapper">
                        <span>All Categories</span>
                    </div>
                    <div className="app-listing-section not-at-right type-5-inner">
                        {categoryList.data.map((value: CategoryModel, index: number) => {
                            return (
                                <div className="app-listing-item__wrapper" key={index} onClick={() => navigate(`/app-category/${value.uuid}`)}>
                                    <a aria-label="Click to see category details">
                                        <div className="app-listing-item">
                                            <div className="app-item-content__wrapper app-details">
                                                <div className="card app-thumbnail-holder" style={{ backgroundSize: "40%", backgroundRepeat: "no-repeat", borderRadius: "8px 0px", opacity: "60%", backgroundImage: value.thumbnail ? `url(https://d2xt8w8fh2bnjt.cloudfront.net/qa/categories/${value.thumbnail})` : '' }}>
                                                    <div className="app-media-overlay__wrapper
                                                    ">
                                                    </div>
                                                </div>
                                                <div className="app-media-info-holder" style={{ color: "black", backgroundColor: "#457ede4d", width: "100%", padding: "6px", backgroundClip: "initial", borderRadius: "0px 0px 8px 8px", boxShadow: "0 4px 12px #0000001a", display: "flex", flexDirection: "column", minWidth: "100%", paddingLeft: "12px" }}>
                                                    <span className="app-media-title" aria-label="App name">{value.title}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default DiscoverMoreApps;