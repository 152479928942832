import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Auth from "../../models/response/auth.model";

interface AuthSliceModel {
  isLoggedIn: boolean;
  authData: Auth | null;
  authenticatedData: any;
  keycloakData: any;
  showNoticeBanner: boolean;
}

const authenticationStore = createSlice({
  name: "authentication-store",
  initialState: {
    isLoggedIn: false,
    authData: null,
    authenticatedData: null,
    showNoticeBanner: true
  } as AuthSliceModel,
  reducers: {
    userLoggedIn: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        isLoggedIn: action.payload
      }
    },
    setAuth: (state: AuthSliceModel, action: PayloadAction<Auth>) => {
      // console.log("sfafafafafafa", action.payload);
      return {
        ...state,
        authData: action.payload
      }
    },
    setAuthenticated: (state: AuthSliceModel, action: PayloadAction<any>) => {
      // console.log("sfafafafafafa", action.payload);
      return {
        ...state,
        authenticatedData: action.payload
      }
    },
    setKeycloak: (state: AuthSliceModel, action: PayloadAction<any>) => {
      // console.log("sfafafafafafa", action.payload);
      return {
        ...state,
        keycloakData: action.payload
      }
    },
    removeAuth: (state: AuthSliceModel) => {
      return {
        ...state,
        isLoggedIn: false,
        authData: null,
        authenticatedData: null,
        keycloakData: null
      }
    },
    closeNoticeBanner: (state: AuthSliceModel) => {
      return {
        ...state,
        showNoticeBanner: false
      }
    }
  },
});

export default authenticationStore;