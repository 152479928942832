import axiosInstance from "../axios-instance";
import * as CONSTANTS from "../constants";

const getTopApps = () => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/top-apps`
    });
};

const getAllApps = () => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/all-apps`
    });
};

const getCategories = () => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/categories`
    });
};

const getAppDetails = (wid: string, tenantId: string) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/widget/${wid}/${tenantId}`
    });
};

const getSliderItems = () => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/slider-items`
    });
};

const getActiveMasksByTenant = (tid: string) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/ent/mask/active/${tid}`,
    });
}

const getOriginalUrl = (uuid: string) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/url/${uuid}`
    });
};

export {
    getTopApps,
    getAllApps,
    getCategories,
    getAppDetails,
    getSliderItems,
    getActiveMasksByTenant,
    getOriginalUrl
};