import bannerImage from '../../../assets/images/ZOHO-mails-2.webp';

const TitleBanner = () => {
    return (
        <div className="main-content">
            <div className="inner-top-feature-holder"
                 style={{backgroundImage: `url(${bannerImage})`}}>
                <div className="container">
                    <button className="btn back-btn" aria-label="Back to the previous page"><span/> Go back
                    </button>
                    <div className="inner-feature-content">
                        <h1 className="app-title" itemProp="name" aria-label="App title">Startups</h1>
                        <span className="app-desc">Specifically designed and selected for small size organization that
                  has less than 10 -15 employees</span>
                        <div className="app-stats__container inner-top">
                            <div className="left-content__wrapper">
                                <div className="app-stats__wrapper">
                                    <ul className="app-stats-list">
                                        <li className="app-stats-item">
                                            <div className="app-stats-inner__wrapper">
                            <span className="stats-value text-gradient-highlighter">80%
                              match+</span>
                                            </div>
                                            <div className="app-stats-inner__wrapper">
                                                <span className="stats-desc">Your business type</span>
                                                <button className="btn btn-info btn-round btn-xsmall"><i
                                                    className="bi bi-info-circle-fill"/></button>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TitleBanner;