/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Constants from '../../../../constants';
import { Modal } from 'antd';

type Props = {
    type: string,
    show: boolean,
    close: () => any
}

const VerificationInfoModal = ({ type, show, close }: Props) => {

    return (
        type === Constants.GWS_METHOD_TXT ?
            <Modal
                title="Verify your domain with a TXT record"
                visible={show}
                width={1000}
                onCancel={() => close()}
                footer={null}>
                {/*<p>To add the TXT record to your domain host, follow the steps below.</p>*/}
                {/*<br />*/}
                <p>1.&nbsp;&nbsp;&nbsp; Copy the verification code (also known as a TXT record).</p>
                <p>2.&nbsp;&nbsp;&nbsp; Sign in to your domain registrar's web site.</p>
                <p>3.&nbsp;&nbsp;&nbsp; Create a new TXT record.</p>
                <p>4.&nbsp;&nbsp;&nbsp; Look for a field called Name (This field might also be called Host, Hostname, or Alias.</p>
                <p>5.&nbsp;&nbsp;&nbsp; Many registrars want you to enter @ in this field. Some might want you to enter your domain name or a subdomain. Contact your registrar if you need help with this).</p>
                <p>6.&nbsp;&nbsp;&nbsp; Paste the verification code you copied into the Value field (This field might also be called Data, Answer, or Destination).</p>
                <p>7.&nbsp;&nbsp;&nbsp; Save your new TXT record.</p>
                <br />
                <p>Reference : <a onClick={()=>{window.open('https://support.google.com/a/answer/183895/','_blank')}}>https://support.google.com/a/answer/183895/</a></p>
            </Modal> : type === Constants.GWS_METHOD_CNAME ?
                <Modal
                    title="Verify your domain with a CNAME record"
                    visible={show}
                    width={1000}
                    onCancel={() => close()}
                    footer={null}>
                    {/*<p>To add the CNAME record to your domain host, follow the steps below.</p>*/}
                    {/*<br />*/}
                    <p>1.&nbsp;&nbsp;&nbsp; Copy the verification code (also known as a CNAME record).</p>
                    <p>2.&nbsp;&nbsp;&nbsp; Sign in to your domain registrar's web site.</p>
                    <p>3.&nbsp;&nbsp;&nbsp; Create a new CNAME record.</p>
                    <p>4.&nbsp;&nbsp;&nbsp; Look for a field called Label/Host(Contact your registrar if you need help with this).</p>
                    <p>5.&nbsp;&nbsp;&nbsp; Paste the verification code you copied into the Value field (This field might also be called Destination or Target).</p>
                    <p>6.&nbsp;&nbsp;&nbsp; Save your new CNAME record.</p>
                    <br />
                    <p>Reference : <a onClick={()=>{window.open('https://support.google.com/a/answer/47283/','_blank')}}>https://support.google.com/a/answer/47283/</a></p>
                </Modal> : <></>
    );
}

export default VerificationInfoModal;