import React, { useEffect, useMemo, useState } from 'react'
import SimpleReactValidator from 'simple-react-validator'

type Props = {
    name: string,
    label?: string,
    placeholder: string,
    value: string,
    setValue: (name: string) => (event: any) => void,
    validator: SimpleReactValidator,
    rules: Array<any>,
    disabled: boolean,
    status?: string, //defult, verified, notVerified, invalid
    validationMessage?: string,
    buttonLable: string,
    showButton: boolean,
    onClickFunc: () => void,
    buttonDisabled: boolean,
    forceUpdate: () => void,
    specificClasses?: string,
    preValueCheck?: boolean,
    errorText?: string
    showRemainingTime?: boolean,
    remainingTime?: number
}

const InputTextFieldWithButton = ({ name, label, placeholder, value, setValue, validator, rules, disabled, status, validationMessage, buttonLable, showButton, onClickFunc, buttonDisabled, forceUpdate, specificClasses, preValueCheck, errorText, showRemainingTime, remainingTime }: Props) => {
    const [resetValue, setResetValue] = useState(false);

    const inputPrevValue = useMemo(() => {
        if (!resetValue) {
            return value;
        } else {
            return "";
        }
    }, [resetValue]);

    const setEnableInput = () => {
        if (preValueCheck) {
            return (!validator.check(value, rules)) || buttonDisabled;
        } else {
            return (!validator.check(value, rules)) || buttonDisabled;
        }
    }

    return (
        <div className={specificClasses ? specificClasses : "form-element__wrapper validated form-element__wbtn right mb-3"}>
            {label && <label className="form-label">{label}
                <span aria-label="required"></span>
            </label>}
            <div className="input__wrapper">
                <input
                    className={status === "verified" ? "form-control valid"
                        : status === "invalid" ? "form-control invalid"
                            : status === "notVerified" ? "form-control pending"
                                : "form-control"}
                    name={name}
                    // type={type}
                    placeholder={placeholder}
                    value={value}
                    onChange={e => {
                        setValue(name)(e)
                        validator.showMessageFor(name)
                        forceUpdate();
                    }}
                    // required={required}
                    disabled={disabled}
                // defaultValue={defaultValue}
                />
                {showButton && <span><button type='button' className={`btn ${status === "verified" ? 'btn-outline-green' : 'btn-outline-warning'} light-shade btn-xsmall`} onClick={() => {
                    setResetValue(true);
                    onClickFunc();
                }} disabled={setEnableInput()}>{buttonLable}</button></span>}
                {/* {showButton && <button type='button' className="btn btn-outline-warning light-shade btn-xsmall" onClick={onClickFunc} disabled={buttonDisabled}>{buttonLable}</button>} */}
            </div>
            {/* <div className="validation invalid-feedback"></div> */}
            {status === "verified" ?
                <div className="validation valid-feedback">{validationMessage}</div>
                : status === "invalid" ?
                    <div className="validation invalid-feedback">{validationMessage}</div>
                    : status === "notVerified" ?
                        <div className="validation panding-feedback">{validationMessage}</div>
                        : errorText !== "" ?
                            <div className="validation invalid-feedback">{errorText}</div> :
                            <></>}
            <div className="validation invalid-feedback">{validator.message(name, value, rules)}</div>
            {(remainingTime && showRemainingTime === true && remainingTime > 0) ? (
                <div className="validation ">
                   Please wait <strong>{remainingTime} sec</strong> to get a new code
                </div>) : showRemainingTime === true ? (
                    <div className="validation ">Time's up! You can click "Verify" to try again.</div>
                ) : <></>}
        </div>
    )
}

export { InputTextFieldWithButton };