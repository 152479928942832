import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Tooltip } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import * as Constants from '../../../constants';


const PageHeaderDynamicFormComponent = (props) => {
    const { wid } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        singleAppDetails
    } = useSelector(({ apps }) => apps);
    const onPressGoBack = () => {
        if (props.backLink) {
            navigate(props.backLink);
        } else {
            navigate(-1);
        }
    }
    // console.log("####################### 0", props.leadCount === 'single' && singleAppDetails && singleAppDetails.widgetDetails && singleAppDetails.widgetDetails.CommonWidgets && singleAppDetails.widgetDetails.CommonWidgets.length > 0);

    return (
        <>
            <nav className="nav-inner-bleed">
                <div className="nav-content__wrapper">
                    <h4 className="main-title">{(singleAppDetails && singleAppDetails.widgetDetails) ? singleAppDetails.widgetDetails.title : ''}</h4>
                </div>
                {singleAppDetails?.widgetDetails?.title != 'eSMS' ?

                    <div className="nav-content__wrapper">
                        {/* {props.page === 'list' && <button className="btn btn-primary" onClick={() => navigate(`/location/${wid}/create`)}>New request</button>} */}
                        {(props.page === 'create' || props.page === 'workflow' || props.page === 'history-list') && <button className="btn back-btn" aria-label="Back to the previous page" onClick={onPressGoBack}> <span />Go back</button>}
                        {props.page === 'list' && <>
                            {
                                props.leadCount === 'single' ? singleAppDetails && singleAppDetails.widgetDetails && singleAppDetails.widgetDetails.CommonWidgets && singleAppDetails.widgetDetails.CommonWidgets.length > 0 && singleAppDetails.widgetDetails.CommonWidgets.map((btn, index) => {
                                    // console.log("####################### 1", props.widgetConfigList);
                                    if (props.widgetConfigList !== null && Array.isArray(props.widgetConfigList.data) && props.widgetConfigList.data.length == 0) {
                                        if (btn.configType === "create" || btn.configType === "config") {
                                            return (
                                                <button key={index} className="btn btn-primary" onClick={() => {
                                                    const paths = window.location.pathname.split('/');
                                                    navigate(`/${paths[Constants.BASEURLPATHLENGTH + 1]}/${paths[Constants.BASEURLPATHLENGTH + 2]}/${btn.configType}`)
                                                }}>
                                                    <i className="fas fa-plus"></i> &nbsp;
                                                    {btn.headerTitle}
                                                </button>
                                            )
                                        }
                                    }
                                }) : singleAppDetails && singleAppDetails.widgetDetails && singleAppDetails.widgetDetails.CommonWidgets && singleAppDetails.widgetDetails.CommonWidgets.length > 0 && singleAppDetails.widgetDetails.CommonWidgets.map((btn, index) => {
                                    // console.log("@@@@@@@@@@@@@@@@@@@@",btn.configType)
                                    if (btn.configType === "create" || btn.configType === "config") {
                                        return (
                                            <button key={index} className="btn btn-primary" onClick={() => {
                                                const paths = window.location.pathname.split('/');
                                                navigate(`/${paths[Constants.BASEURLPATHLENGTH + 1]}/${paths[Constants.BASEURLPATHLENGTH + 2]}/${btn.configType}`)
                                            }}>
                                                <i className="fas fa-plus"></i> &nbsp;
                                                {btn.headerTitle}
                                            </button>
                                        )
                                    }
                                })
                            }
                            {
                                props.createNew &&
                                <button className="btn btn-primary" onClick={() => {
                                    const paths = window.location.pathname.split('/');
                                    navigate(`/${paths[Constants.BASEURLPATHLENGTH + 1]}/${paths[Constants.BASEURLPATHLENGTH + 2]}/${props.createNew.url}`)
                                }}>
                                    <i className="fas fa-plus"></i> &nbsp;
                                    {props.createNew.btnName}
                                </button>
                            }
                        </>}
                        <button className="btn back-btn" onClick={() => navigate(`/subscriptions`)}>
                            <i className="bi bi-x-lg" />
                            &nbsp;Exit the app
                        </button>
                    </div>
                    :
                    <button className="btn back-btn" onClick={() => navigate(`/subscriptions`)}>
                        <i className="bi bi-x-lg" />
                        &nbsp;Exit the app
                    </button>
                }
            </nav>
        </>
    )

}
export { PageHeaderDynamicFormComponent };