import React from 'react'

type Props = {
  status: string,
  accountStatus: string,
  profileStatus: string,
  businessStatus: string,
  tncStatus: string
}

const ProgressBar = ({ status, accountStatus, profileStatus, businessStatus, tncStatus }: Props) => {
  return (
    status !== "processing" ?
      <div className="wiz-progress-bar">
        <div className={(profileStatus === "completed") ? "progress-item completed" : (profileStatus === "pending") ? "progress-item pending" : (profileStatus === "locked") ? "progress-item" : "progress-item rejected"} />
        <div className={(accountStatus === "completed") ? "progress-item completed" : (accountStatus === "pending") ? "progress-item pending" : (accountStatus === "locked") ? "progress-item" : "progress-item rejected"} />
        {/* ~ wiz-processing ~ class - will add animation to the final account creationg animation loading bar in top*/}
        <div className={(businessStatus === "completed") ? "progress-item completed" : (businessStatus === "pending") ? "progress-item pending" : (businessStatus === "locked") ? "progress-item" : "progress-item rejected"} />
        <div className={(tncStatus === "completed") ? "progress-item completed" : (tncStatus === "pending") ? "progress-item pending" : (tncStatus === "locked") ? "progress-item" : "progress-item rejected"} />
      </div>
      : <div className="wiz-progress-bar">
        <div className="progress-item wiz-processing" />
      </div>

  )
}

export { ProgressBar }