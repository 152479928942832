import React, { useEffect, useState } from 'react';
import ReduxThunk from 'redux-thunk';
import { Routes, BrowserRouter as Router, Route } from "react-router-dom";
import RouteHandler from './routes/RouteHandler';
import Keycloak from 'keycloak-js';
import Auth from './models/response/auth.model';
import { useAppDispatch } from './store';
import { setAuthInStore, setAuthenticatedInStore, setKeycloakInStore, submitUserLoggedIn } from './store/actions';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import { Spinner } from './components/Spinner';

const antIcon = <Spinner />;

function App() {
  const [keycloak, setKeycloak] = useState<Keycloak>()
  const [authenticated, setAuthenticated] = useState(false)
  const [auth, setAuth] = useState<Auth>({
    uuid: '',
    name: '',
    userName: '',
    email: '',
    roles: [],
    realmRoles: [],
    groups: [],
    accessToken: ''
  })

  const dispatch = useAppDispatch();

  const {
    authData, authenticatedData, keycloakData
  } = useSelector<any, any>(({ authentication }) => authentication);

  const paths = window.location.pathname.split('/');

  useEffect(() => {
    let token = localStorage.getItem('kc_token')!;
    let refreshToken = localStorage.getItem('kc_refreshToken')!;
    // console.log("token:", token)

    // console.log(paths);
    // console.log("refreshtoken:", refreshToken)
    // console.log("#################", localStorage.getItem('persist:root'));
    
    if (paths[1] !== 'signup' && paths[1] !== 'agreement' && (paths[1] !== "apps") && (paths[1] !== "widget") &&paths[1] !== 'password-reset' && (paths[1] !== "customer-sentiment")) {
      const keycloak = new Keycloak('../../../../../keycloak.json');
      const redirectUri = window.location.origin + '/home';
      (keycloak)['redirectUri'] = redirectUri; 
      // console.log("keycloak:", keycloak)
      keycloak.init({ onLoad: 'login-required', 'checkLoginIframe': false, token, refreshToken }).then(authenticated => {
        // console.log("authenticated:", authenticated, keycloak)
        if (authenticated) {
          dispatch(submitUserLoggedIn())

          let authentication = auth;
          if (keycloak.idTokenParsed && keycloak.realmAccess) {
            authentication = {
              uuid: keycloak.idTokenParsed.sub ? keycloak.idTokenParsed.sub : '',
              name: keycloak.idTokenParsed.name ? keycloak.idTokenParsed.name : '',
              userName: keycloak.idTokenParsed.preferred_username ? keycloak.idTokenParsed.preferred_username : '',
              email: keycloak.idTokenParsed.email ? keycloak.idTokenParsed.email : '',
              roles: keycloak.idTokenParsed.client_roles ? keycloak.idTokenParsed.client_roles : [],
              realmRoles: keycloak.realmAccess.roles ? keycloak.realmAccess.roles : [],
              groups: keycloak.idTokenParsed.group ? keycloak.idTokenParsed.group : [],
              accessToken: keycloak.token ? keycloak.token : ''
            }
          }
          // window.accessToken = keycloak.token;
          // window.isTokenExpired = keycloak.isTokenExpired();
          localStorage.setItem('kc_token', keycloak.token!);
          localStorage.setItem('kc_refreshToken', keycloak.refreshToken!);
          setKeycloak(keycloak)
          setAuthenticated(authenticated)
          setAuth(authentication)
          dispatch(setAuthInStore(authentication));
          dispatch(setAuthenticatedInStore(authenticated));
          dispatch(setKeycloakInStore(keycloak));

        }
      }).catch(error => {
        // console.log(error);
        setKeycloak(keycloak)
        setAuthenticated(false)
      })
    } else {
      if (token != (null)) {
        setAuth(authData);
        setKeycloak(keycloakData);
        setAuthenticated(authenticatedData);
      }
    }
  }, [])

  const onLogout = () => {
    if (keycloak) {
      keycloak.logout();
    }
  }

  return (
    <React.Fragment>
      <Router>
        <Spin size="large" spinning={!keycloak && paths[1] !== 'signup' && paths[1] !== 'agreement' && (paths[1] !== "apps") && (paths[1] !== "widget") && (paths[1] !== "password-reset") && (paths[1] !== "customer-sentiment")} indicator={antIcon}>
          <RouteHandler isLogin={authenticated} keycloak={keycloak} auth={auth} setAuth={setAuth} />
        </Spin>
        {/* {!authenticated && <AccessDenied screenClass={'access_denied_container'} onLogout={onLogout} />}*/}
      </Router>
    </React.Fragment>
  );
}

export default App;