/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {Button, Divider, Form, Input, InputNumber, Tooltip} from 'antd';
import {AddressModel} from "../models/address.model";
import {CartRecordModel} from "../models/cartRecord.model";

type Props = {
    onCreateOrder: () => void,
    cartRecord: CartRecordModel | null,
    onChangeQty: (values: number | null) => void,
}

const CartView = ({onCreateOrder, cartRecord, onChangeQty}: Props) => {

    return (
        <React.Fragment>
            <div>
                <div className="card-body gws__body">
                    <h4 className="gws-title">Shopping Cart</h4>
                    <div className="cart-billing-data">
                        <p>Name: {cartRecord?.contactInfo.name}</p>
                        <p>Contact Number: {cartRecord?.contactInfo.contact}</p>
                        <p>Email Address: {cartRecord?.contactInfo.email}</p>
                        <p>Billing Address: {cartRecord?.billingAddress.billAddress1},{cartRecord?.billingAddress.billAddress2},{cartRecord?.billingAddress.billCity}</p>
                    </div>
                    <div className="section__wrapper">
                        <div className="card panel content-holder-panels">
                            <div className="panel-table-listing__wrapper">
                                <div className="table-parent__wrapper">
                                    <table className="table table-default mp-cart-table">
                                        <tbody>
                                            <tr className="table-header">
                                                {/*<th className="txt-left">#</th>*/}
                                                <th className="txt-left" style={{width: '60%'}}>Description</th>
                                                <th className="txt-left" style={{width: '15%'}}>Qty</th>
                                                <th className="txt-left" style={{width: '25%'}}>Amount</th>
                                            </tr>
                                            {cartRecord &&
                                                <tr className="table-row ">
                                                    {/*<td className="txt-left">{1}</td>*/}
                                                    <td className="txt-left">
                                                        <div>
                                                            <b>{cartRecord.deviceTitle}</b><br/>
                                                            <span>Device code: {cartRecord.deviceCode}</span><br/>
                                                            <span>Device price: LKR {parseFloat(cartRecord.deviceAmount).toFixed(2)} + Tax</span>
                                                            <Divider className="cart-item-divider"/>
                                                            <span>Selected package: {cartRecord.packageTitle}</span><br/>
                                                            <span>Package code: {cartRecord.packageCode}</span><br/>
                                                            <span>Package price: LKR {cartRecord.packageAmount.toFixed(2)} + Tax</span>
                                                            <Divider className="cart-item-divider"/>
                                                            <span>Installation Address: {cartRecord.installationAddress.address1},{cartRecord.installationAddress.address2},{cartRecord.installationAddress.city}</span><br/>
                                                            <span>Coverage: {cartRecord.hasCoverage ? "Available" : "Not Available"}</span>
                                                        </div>
                                                    </td>
                                                    {/*<td className="txt-left"><InputNumber min={1} max={10} defaultValue={1} value={cartRecord.qty} onChange={onChangeQty} /></td>*/}
                                                    <td className="txt-left">{cartRecord.qty}</td>
                                                    <td className="txt-left">LKR {parseFloat(cartRecord.deviceAmount)*cartRecord.qty} + Tax</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    {cartRecord && <div className="cart-billing-tot">
                        <p>Total Amount: LKR {parseFloat(cartRecord.deviceAmount) * cartRecord.qty} + Tax</p>
                    </div>}
                    <div className="alert warning">
                        <span className="alert-text__wrapper">
                            <i className="alert-icon bi bi-exclamation-circle-fill"/>
                            <span className="alert-text">Only setup fee will be charge from Marketplace. The monthly rental will be charge after connection establishment.</span>
                        </span>
                    </div>
                    <Button type="primary" className={"btn btn-primary btn-sm"} onClick={onCreateOrder}>
                        Checkout
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
}

export default CartView;