import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Space, Table, Tooltip } from "antd";
import { fetchAllConfigs, fetchSingleApp } from "../../../store/actions";
import AuthHandler from "../../../helper/AuthHandler";
import { EyeOutlined, FormOutlined } from "@ant-design/icons";
import { PageHeaderDynamicFormComponent } from "../components/PageHeaderDynamicForm.component";
import { useParams, useNavigate } from "react-router-dom";
import * as Constants from '../../../constants';

const DynamicLeadWidgetDetails = (props) => {
    const { wid } = useParams();

    const dispatch = useDispatch();
    const { userDetails } = useSelector(({ user }) => user);
    const {
        singleAppDetails
    } = useSelector(({ apps }) => apps);
    const { widgetConfigListLoading, widgetConfigList } = useSelector(({ dynamicForm }) => dynamicForm);
    const paths = window.location.pathname.split('/');
    const navigate = useNavigate();

    useEffect(() => {
        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        dispatch(fetchSingleApp(wid, tenantGroupId));
    }, [userDetails])

    useEffect(() => {
        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        if (singleAppDetails && singleAppDetails.widgetDetails) {
            const formattedUrl = singleAppDetails.widgetDetails.dataUrlV2.startsWith('/api/') ? singleAppDetails.widgetDetails.dataUrlV2.slice(4) : singleAppDetails.widgetDetails.dataUrlV2;

            dispatch(fetchAllConfigs(formattedUrl, tenantGroupId, singleAppDetails.widgetDetails.uuid))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleAppDetails, userDetails]);

    const onChangeViewConfig = (uuid, configType) => {
        navigate(`/${paths[Constants.BASEURLPATHLENGTH + 1]}/${paths[Constants.BASEURLPATHLENGTH + 2]}/${configType}/${uuid}`)
    }

    const onViewWorkflow = (uuid) => {
        navigate(`/${paths[Constants.BASEURLPATHLENGTH + 1]}/${paths[Constants.BASEURLPATHLENGTH + 2]}/lead-widget-workflow/${uuid}`)
    }

    // console.log("$$$$$$$$$$$$", widgetConfigList);

    return (
        <React.Fragment>
            {
                AuthHandler.isAuthorized(props.auth, 'dynamin-widget') && userDetails !== null ?
                    <div className="main-content main-spacer-x-y">
                        <PageHeaderDynamicFormComponent
                            backLink={`/${paths[Constants.BASEURLPATHLENGTH + 1]}/${paths[Constants.BASEURLPATHLENGTH + 2]}`}
                            leadCount={'single'}
                            widgetConfigList={widgetConfigList}
                            page={'list'}
                        />
                        <div className="section__wrapper">
                            <div className="card panel content-holder-panels">
                                <div className="panel-header">
                                    <div className="panel-header-content__wrapper">
                                        <h4 className="panel-header-title">{widgetConfigList && widgetConfigList.tableHeader}</h4>
                                    </div>
                                </div>
                                <div className="panel-table-listing__wrapper">
                                    <div className="table-parent__wrapper">
                                        <Table
                                            className="gx-table-responsive"
                                            sortDirections={['descend', 'ascend']}
                                            showSorterTooltip={false}
                                            columns={widgetConfigList ? [...widgetConfigList.columns, {
                                                title: 'View',
                                                dataIndex: '',
                                                key: 'x',
                                                width: "8%",
                                                render: (text, row) => <Space size={10}>
                                                    {
                                                        row.viewButtonVisible &&
                                                        <Tooltip title="View">
                                                            <EyeOutlined
                                                                onClick={() => onChangeViewConfig(row.uuid, "view")}
                                                            />
                                                        </Tooltip>
                                                    }
                                                    {
                                                        row.updateButtonVisible &&
                                                        <Tooltip title="Update">
                                                            <FormOutlined
                                                                onClick={() => onChangeViewConfig(row.uuid, "update")}
                                                            />
                                                        </Tooltip>

                                                    }
                                                </Space>
                                                ,
                                            }, {
                                                title: 'Workflow',
                                                dataIndex: '',
                                                key: 'x',
                                                width: "8%",
                                                render: (text, row) => <Space size={10}>
                                                    {
                                                        row.viewButtonVisible &&
                                                        <Tooltip title="View">
                                                            <EyeOutlined
                                                                onClick={() => onViewWorkflow(row.uuid)}
                                                            />
                                                        </Tooltip>
                                                    }
                                                </Space>
                                                ,
                                            }] : []}
                                            dataSource={widgetConfigList ? widgetConfigList.data : []}
                                            pagination={true}
                                            loading={widgetConfigListLoading}
                                            size={"small"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <></>
            }
        </React.Fragment>
    )
};

export default DynamicLeadWidgetDetails;