import { NavigateFunction } from "react-router-dom";
import { getAllOffers, claimOffers } from "../../services/offers.service"
import offersStore from "../reducers/offers.store";
import { message, notification } from "antd";

export const fetchAllOffers = (userUuid: string, tenantGroupId: string): any => {
    return (dispatch: any) => {
        getAllOffers(userUuid, tenantGroupId)
            .then((response) => {
                console.log("response1", response.data)
                if (response.status === 200 || response.status === 201) {
                    dispatch(offersStore.actions.allOffersListFetchSuccess(response.data.allOffersDetails));
                    dispatch(offersStore.actions.eligibleOffersListFetchSuccess(response.data.eligibleOffersDetails));
                    dispatch(offersStore.actions.claimedOffersListFetchSuccess(response.data.claimedOffersWidgetDetailsList));
                } else {
                    dispatch(offersStore.actions.allOffersListFetchFailed());
                    dispatch(offersStore.actions.eligibleOffersListFetchFailed());
                    dispatch(offersStore.actions.claimedOffersListFetchFailed());
                }
            })
            .catch((error) => {
                dispatch(offersStore.actions.allOffersListFetchFailed());
                dispatch(offersStore.actions.eligibleOffersListFetchFailed());
                dispatch(offersStore.actions.claimedOffersListFetchFailed());
            });
    }
}
