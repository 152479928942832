import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SingleAppModel } from "../../pages/single-app/models/response/single-app.model";
import { CategoryModel } from "../../pages/discover/models/response/app-category.model";
import { AddedApp } from "../../pages/subscribed-apps/models/added-app.model";

interface AuthSliceModel {
    recentApps: AddedApp[];
}

const memoryStore = createSlice({
    name: "memory-store",
    initialState: {
        recentApps: []
    } as AuthSliceModel,
    reducers: {
        memorizeApp: (state: AuthSliceModel, action: PayloadAction<any>) => {
            let newArray: any[];
            if (state.recentApps.length === 8) {
                newArray = [action.payload, ...state.recentApps.slice(0, 7)];
            } else {
                newArray = [action.payload, ...state.recentApps];
            }
            newArray = newArray.filter((v, i, a) => a.findIndex(v2 => (v2.uuid === v.uuid)) === i);
            // newArray.sort((a, b) => newArray.indexOf(b) - newArray.indexOf(a))
            return {
                ...state,
                recentApps: newArray
            }
        }
    },
});

export default memoryStore;