import React, { useEffect, useState } from 'react'
import Auth from '../../models/response/auth.model';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../store';
import { fetchAllOffers, fetchAppList } from '../../store/actions';
import AppListItem from './components/AppListItem';
import { AddedApp } from './models/added-app.model';
import { WarningAlertBox } from "../home/components";
import * as CONSTANTS from "../../constants";

type Props = {
    auth: Auth;
}

const SubscribedApps = (props: Props) => {

    const [searchText, setSearchText] = useState("");
    const [limit, setLimit] = useState(20);
    const [offset, setOffset] = useState(0);
    const [beforeRegDate, setBeforeRegDate] = useState<Date>(new Date("2023-11-28"));

    const [subscribedApps, setSubscribedApps] = useState([]);
    const dispatch = useAppDispatch();

    const {
        userDetails
    } = useSelector<any, any>(({ user }) => user);

    const {
        recentApps
    } = useSelector<any, any>(({ memory }) => memory);

    const {
        appList,
        hasMoreData
    } = useSelector<any, any>(({ settings }) => settings);

    useEffect(() => {
        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        if (tenantGroupId !== "" && searchText == "") {
            dispatch<any>(fetchAppList(tenantGroupId, searchText !== "" ? searchText : '0', limit, offset, appList));
        }
    }, [userDetails, offset, searchText]);

    useEffect(() => {
        if (appList) {
            setSubscribedApps(appList.filter((item: any) => item.featured == true))
            console.log("sub apps 1", appList);
            console.log("sub apps 2", subscribedApps);
        }
    }, [appList])

    const onSearchWidget = () => {
        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        setOffset(0);
        if (tenantGroupId !== "") {
            dispatch<any>(fetchAppList(tenantGroupId, searchText !== "" ? searchText.split(" ").toString() : '0', limit, offset, []));
        }
    }

    const {
        allOffersList
    } = useSelector<any, any>(({ offers }) => offers);

    useEffect(() => {
        const tenantGroupId = (userDetails && userDetails?.OwnedEnterprises[0]) ? userDetails?.OwnedEnterprises[0].groupId : "";
        dispatch(fetchAllOffers(userDetails?.uuid, tenantGroupId));
    }, [userDetails]);

    return (
        <div className="main-content main-spacer-x-y">
            <div className="section__wrapper app-listing-section__wrapper">
                <div className="app-listing-section-title__wrapper">
                    <span>Your subscribed apps</span>
                </div>
                <div className="feature-search-bar card">
                    <div className="primary-searchbar__wrapper">
                        <input
                            type="text"
                            className="form-control primary-searchbar lg"
                            id=""
                            placeholder="Search by app name"
                            spellCheck="false"
                            data-ms-editor="true"
                            onChange={(e) => {
                                let val = e.target.value;
                                if (val === "") {
                                    setOffset(0);
                                }
                                setSearchText(val);
                            }}
                            value={searchText}
                            onKeyDown={event => {
                                if (event.key === 'Enter') {
                                    onSearchWidget();
                                }
                            }}
                        />
                    </div>
                </div>
            </div>

            {userDetails && userDetails.createdAt && (new Date(userDetails.createdAt) < new Date(beforeRegDate)) &&
                // <WarningAlertBox closable={false} message={"To view other subscribed applications, please visit the old Marketplace."}/>
                <div className="alert warning">
                    <span className="alert-text__wrapper">
                        <i className="alert-icon bi bi-exclamation-circle-fill" />
                        <span className="alert-text">{"To view other applications, please visit the old Marketplace."}</span>
                        <button className="btn btn-small secondary-action" onClick={() => { window.location.replace(`${CONSTANTS.REACT_APP_CLASSIC_UI_URL}`) }}>Switch to classic view</button>
                    </span>
                </div>
            }

            {/* Removed temporarily */}
            {/* {searchText === "" && Array.isArray(recentApps) && recentApps.length > 0 && <div className="app-listing-section__wrapper type-6">
                <div className="app-listing-section-title__wrapper">
                    <span>Recently Opened</span>
                </div>
                <div className="app-listing-section not-at-right type-6-inner">
                    {recentApps.map((value: any, index: number) => {
                        const found = appList.some((item: AddedApp) => item.uuid === value?.uuid);
                        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";

                        if (found && value?.tenantId == tenantGroupId) {
                            return (
                                <AppListItem
                                    key={index}
                                    appDetails={value}
                                    userDetails={userDetails}
                                />
                            )
                        }
                    })}
                </div>
            </div>} */}
            <div className="app-listing-section__wrapper type-6">
                <div className="app-listing-section-title__wrapper">
                    {/* <span> Apps</span> */}
                </div>
                <div className="app-listing-section not-at-right type-6-inner">
                    {Array.isArray(subscribedApps) && subscribedApps.length > 0 && subscribedApps.map((value: AddedApp, index: number) => {
                        const hasOffer = Array.isArray(allOffersList?.allOffersWidgetDetailsList) && allOffersList?.allOffersWidgetDetailsList.some((offer: { uuid: string | null; }) => offer.uuid === value.uuid);
                        const hasSubscribed = Array.isArray(appList) && appList.some((appId: { uuid: string | null; }) => appId.uuid === value.uuid);

                        return (
                            <AppListItem
                                key={index}
                                appDetails={value}
                                userDetails={userDetails}
                                hasOffer={hasOffer}
                                hasSubscribed={hasSubscribed}
                            />
                        )
                    })}
                </div>
                <div className="app-listing-section-footer">
                    {Array.isArray(subscribedApps) && subscribedApps.length > (limit - 1) && <button className="btn btn-primary" onClick={() => setOffset(offset + limit)} disabled={!hasMoreData}>Load more apps</button>}
                </div>
            </div>
        </div>
    )
}

export default SubscribedApps;