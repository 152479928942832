import { getAppDetails, getSliderItems } from "../../services/app.service"
import { getTenantNumberBases, getWidgetsByCategory, getWidgetStats } from "../../services/widgets.service";
import { getActiveMasksByTenant } from "../../services/app.service"
import appStore from "../reducers/app.store";

export const fetchSingleApp = (appId: string, tenantId: string): any => {
    return (dispatch: any) => {
        getAppDetails(appId, tenantId)
            .then((response) => {
                console.log("Single app data ", response.data);
                if (response.status === 200 || response.status === 201) {
                    dispatch(appStore.actions.getAppDetailsSuccess(response.data.data));
                } else {
                    dispatch(appStore.actions.getAppDetailsFailed());
                }
            })
            .catch((error) => {
                dispatch(appStore.actions.getAppDetailsFailed());
            });
    }
}

export const fetchSliderItems = (): any => {
    return (dispatch: any) => {
        getSliderItems()
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    dispatch(appStore.actions.getSliderItemsSuccess(response.data));
                } else {
                    dispatch(appStore.actions.getSliderItemsFailed());
                }
            })
            .catch((error) => {
                dispatch(appStore.actions.getSliderItemsFailed());
            });
    }
}

export const fetchNumberBase = (tenantId: string, widgetId: string): any => {
    return (dispatch: any) => {
        getTenantNumberBases(tenantId, widgetId)
            .then((response) => {
                dispatch(appStore.actions.getNumberListSuccess(response.data));

            })
            .catch((error) => {
                dispatch(appStore.actions.getNumberListFailed());
            });
    }
};

export const fetchWidgetsByCategory = (catId: string, tenantId: string, limit: number, offset: number): any => {
    return (dispatch: any) => {
        getWidgetsByCategory(catId, tenantId, limit, offset)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    if (Array.isArray(response.data.data) && response.data.data.length > 0) {
                        dispatch(appStore.actions.getCategoryDetailsSuccess(response.data.data[0]));
                    } else {
                        dispatch(appStore.actions.getCategoryDetailsFailed());
                    }
                } else {
                    dispatch(appStore.actions.getCategoryDetailsFailed());
                }
            })
            .catch((error) => {
                dispatch(appStore.actions.getCategoryDetailsFailed());
            });
    }
}

export const subscribeClicked = (): any => {
    return (dispatch: any) => {
        dispatch(appStore.actions.isSubscribeClickedTrue());
    }
};

export const subscribeNotClicked = (): any => {
    return (dispatch: any) => {
        dispatch(appStore.actions.isSubscribeClickedFalse());
    }
};

export const fetchWidgetStats = (tid: string): any => {
    return (dispatch: any) => {
        getWidgetStats(tid)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    dispatch(appStore.actions.getWidgetStatSuccess(response.data));
                }else{
                    dispatch(appStore.actions.getWidgetStatFailed());
                }
            })
            .catch((error) => {
                dispatch(appStore.actions.getWidgetStatFailed());
            });
    }
}

export const fetchActiveMasksByTenant = (tid: string): any => {
    return (dispatch: any) => {
        dispatch(appStore.actions.getEnterpriseActiveMaskFetch());

        getActiveMasksByTenant(tid)
        .then((response) => {
            if(response.status === 200 || response.status === 201) {
                dispatch(appStore.actions.getEnterpriseActiveMaskSuccess(response.data));
            }else {
                dispatch(appStore.actions.getEnterpriseActiveMaskFail());
            }
        })
        .catch((error) => {
            dispatch(appStore.actions.getEnterpriseActiveMaskFail());
        });
    }
}