import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import { fetchTracklist, fetchSingleApp, fetchTrackHistory } from '../../../store/actions';
import { MapContainer, Marker, Popup, TileLayer, LayersControl, CircleMarker, Circle, useMapEvents } from 'react-leaflet';
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from '@emotion/react';
import AuthHandler from '../../../helper/AuthHandler';
import MarkerMapIcon from '../../../assets/images/Marker.svg';
import TableIcon from '../../../assets/images/icons/app-media-placeholder.svg';
import * as Constants from '../../../constants';
import moment from "moment";
import L from "leaflet";

const override: CSSProperties = {
    display: "block",
    margin: "0 auto"
};

const MapLocation = ({ auth }: any) => {
    const mapRef = useRef<any>(null);
    const { wid, configId } = useParams();
    const [logList, setLogList] = useState<any[]>([]);
    const [limit, setLimit] = useState<number>(10);
    const [offset, setOffset] = useState<number>(0);
    const [address, setAddress] = useState<string>('');
    const [lat, setLat] = useState<string>('');
    const [lng, setLng] = useState<string>('');
    const [zoom, setZoom] = useState(13);

    const navigate = useNavigate();

    const {
        singleAppDetails
    } = useSelector(({ apps }: any) => apps);
    const { success, loading, error, trackHistory } = useSelector((state: any) => state.location);
    const { userDetails } = useSelector(({ user }: any) => user);
    const [initialDataLoaded, setInitialDataLoaded] = useState(false);

    const dispatch = useAppDispatch();

    const onSetZoom = (e: any) => {
        try {
            setZoom(e.target._zoom);
        } catch (err) {
            console.log(err);
        }
    }

    var path = window.location.pathname.split('/');
    var location = path[Constants.BASEURLPATHLENGTH + 6].split('&');
    var savedLng = location[0];
    var savedLat = location[1];
    const savedRadius = parseInt(location[2]);
    var currentLng = location[3];
    var currentLat = location[4];

    const expected_position = { lat: parseFloat(currentLat), lng: parseFloat(currentLng) };
    const expected_position_marker_icon = L.icon({
        iconUrl: MarkerMapIcon,
        iconRetinaUrl: MarkerMapIcon,
        iconSize: new L.Point(50, 50),
        className: 'leaflet-div-icon'
    });


    const current_position = { lat: parseFloat(currentLat), lng: parseFloat(currentLng) };
    const current_position_marker_icon = L.icon({
        iconUrl: MarkerMapIcon,
        iconRetinaUrl: MarkerMapIcon,
        iconSize: new L.Point(50, 50),
        className: 'leaflet-div-icon'
    });

    useEffect(() => {
        if (mapRef.current) {
            // Perform animation after the map is initialized
            mapRef.current.setView(expected_position, 13, { animate: true });
            mapRef.current.setView(current_position, 13, { animate: true });

            // Add event listener for zoomstart event
            mapRef.current.on('zoomstart', onSetZoom);

        }

        // Remove event listener on component unmount
        return () => {
            if (mapRef.current) {
                mapRef.current.off('zoomstart', onSetZoom);
            }
        };
    }, [mapRef, expected_position, current_position]);

    if (AuthHandler.isAuthorized(auth, 'tracklist') && userDetails !== null) {
        return (
            <React.Fragment>
                <div className="main-content main-spacer-x-y">
                    <MapContainer
                        ref={mapRef}
                        // animate={true}
                        center={expected_position}
                        zoom={zoom}
                        zoomControl={true}
                        doubleClickZoom={true}
                        // style={{ height: 500 }}
                        id="map_style"
                    >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                        />
                        <Marker
                            // draggable
                            title="Expected location"
                            opacity={0.8}
                            position={expected_position}
                            icon={expected_position_marker_icon}>
                        </Marker>
                        <Marker
                            // draggable
                            title="Current location"
                            opacity={0.8}
                            position={current_position}
                            icon={current_position_marker_icon}>
                        </Marker>
                        {isNaN(savedRadius) === false && <Circle
                            center={expected_position}
                            radius={savedRadius}
                            fillColor='red'
                        // strokeColor='red'
                        />}
                    </MapContainer>
                </div>
            </React.Fragment>
        );
    } else return (<React.Fragment></React.Fragment>);
}

export default MapLocation;