import React, { CSSProperties, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import { fetchTracklist, fetchSingleApp } from '../../../store/actions';
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from '@emotion/react';
import AuthHandler from '../../../helper/AuthHandler';
import TableIcon from '../../../assets/images/icons/app-media-placeholder.svg';
import { PageHeaderStatic } from "../components/PageHeaderStatic";

const override: CSSProperties = {
    display: "block",
    margin: "0 auto"
};

const LocationTracking = ({ auth }: any) => {
    const { wid } = useParams();
    const [trackList, setTrackList] = useState<any[]>([]);
    const [limit, setLimit] = useState<number>(10);
    const [offset, setOffset] = useState<number>(0);

    const navigate = useNavigate();

    const {
        singleAppDetails
    } = useSelector(({ apps }: any) => apps);
    const { success, loading, error, trackListData } = useSelector((state: any) => state.location);
    const { userDetails } = useSelector(({ user }: any) => user);
    const [initialDataLoaded, setInitialDataLoaded] = useState(false);

    const dispatch = useAppDispatch();

    useEffect(() => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        dispatch(fetchSingleApp(wid!, tenantGUID));
    }, [wid, userDetails]);

    useEffect(() => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        if (initialDataLoaded && singleAppDetails?.widgetDetails?.uuid !== null) {
            dispatch(fetchTracklist(tenantGUID, singleAppDetails?.widgetDetails?.uuid, limit, offset));
        }
    }, [initialDataLoaded, offset]);

    useEffect(() => {
        if (Array.isArray(trackListData) && trackListData.length > 0) {
            const newLocationTracks = trackListData[0].LocationTrack;
            // Check if the newLocationTracks already exist in trackList
            const uniqueLocationTracks = newLocationTracks.filter(
                (track: any) => !trackList.some((existingTrack) => existingTrack.uuid === track.uuid)
            );
            // Add uniqueLocationTracks to the trackList
            setTrackList([...trackList, ...uniqueLocationTracks]);
        }
    }, [trackListData]);

    useEffect(() => {
        if (singleAppDetails && userDetails) {
            setInitialDataLoaded(true);
        }
    }, [singleAppDetails, userDetails]);

    const onViewMore = () => {
        setOffset(offset + 10);
    }

    const renderTrackList = () => {
        if (success) {
            if (trackList.length > 0) {
                return (
                    <div className="section__wrapper">
                        <div className="card panel content-holder-panels">
                            <div className="panel-header">
                                <div className="panel-header-content__wrapper">
                                    <h4 className="panel-header-title">List of Trackings</h4>
                                    {/* <p className="panel-header-subtitle">List of </p> */}
                                </div>
                            </div>
                            <div className="panel-table-listing__wrapper">
                                <div className="table-parent__wrapper">
                                    <table className="table table-default">
                                        <tbody>
                                            <tr className="table-header">
                                                <th className="txt-left">Track ref</th>
                                                <th className="txt-left">Title</th>
                                                <th className="txt-left">Tracking person's name</th>
                                                <th className="txt-left">Tracking time</th>
                                                <th className="txt-left">Tracking number</th>
                                                <th className="txt-left">Location</th>
                                                <th className="txt-left">Details</th>
                                                <th className="txt-left">Tracking history</th>
                                            </tr>
                                            {trackList.map((value: any, index: number) => {
                                                return (
                                                    <tr className="table-row ">
                                                        <td>
                                                            <div className="feature-details__wrapper">
                                                                <div
                                                                    className="card app-media-holder"
                                                                    style={{
                                                                        backgroundImage: `url(${TableIcon})`
                                                                    }}
                                                                ></div>
                                                                <div className="feature-details-title__wrapper">
                                                                    <span className="title">{value.uuid}</span>
                                                                    {/* <span className="small-desc">Tracking time {value.trackTime}</span> */}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="txt-left">{value.title}</td>
                                                        <td className="txt-left">{value.personName}</td>
                                                        <td className="txt-left">{value.trackTime}</td>
                                                        <td className="txt-left">{value.trackingNumber}</td>
                                                        <td className="txt-left">{value.location}</td>
                                                        <td className="txt-left">
                                                            <Link to={`/location/${wid}/view/${value.uuid}`}>
                                                                Edit <i className="bi bi-pen-fill" />
                                                            </Link>
                                                        </td>
                                                        <td className="txt-left">
                                                            <Link to={`/location/${wid}/logs/${value.uuid}`}>
                                                                View history <i className="bi bi-eye-fill"></i>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            <tr className="table-row">
                                                <td>
                                                    <a onClick={onViewMore}>View All</a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="section__wrapper">
                        <div className="empty-state-new-action__wrapper">
                            <div className="new-action-content__wrapper">
                                <h4 className="sub">No Records available</h4>
                                <h4 className="title">Create new request</h4>
                                <button className="btn btn-primary" onClick={() => navigate(`/location/${wid}/create`)}>New request</button>
                            </div>
                        </div>
                    </div>
                )
            }
        } else if (loading) {
            return (
                <div className="section__wrapper">
                    <div className="empty-state-new-action__wrapper">
                        <section className="content__wrapper">
                            <div className="primaryBrandLoaderBg">
                                <div className="primaryBrandLoader">
                                    <img src={require('../../../assets/images/loader-bg-primary.svg').default} alt="" srcSet="" />
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            )
        } else if (error) {
            return (
                <div className="section__wrapper">
                    <div className="empty-state-new-action__wrapper">
                        <div className="new-action-content__wrapper">
                            <h4 className="sub">Oops, something went wrong!</h4>
                        </div>
                    </div>
                </div>
            )
        }
    }

    if (AuthHandler.isAuthorized(auth, 'tracklist') && userDetails !== null) {
        return (
            <React.Fragment>
                <div className="main-content main-spacer-x-y">
                    <PageHeaderStatic
                        listShow={Array.isArray(trackList) && trackList.length > 0}
                        page={'list'}
                    />
                    {renderTrackList()}
                </div>
            </React.Fragment>
        );
    } else return (<React.Fragment></React.Fragment>);
}

export default LocationTracking;