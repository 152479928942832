import React, {useEffect, useState} from 'react'
import {WidgetRate} from "../../pages/single-app/models/response/single-app.model";
import {RateCardSelect} from "../../pages/single-app/components";
import SimpleReactValidator from "simple-react-validator";

type Props = {
    appDetails: any
}

const ChargeableTypeOneComponent = ({ appDetails }: Props) => {
    const [selectedRate, setSelectedRate] = React.useState('');
    const [validator] = useState(new SimpleReactValidator())

    useEffect(() => {
        if(appDetails && appDetails.widgetDetails && appDetails.widgetDetails.WidgetRate && Array.isArray(appDetails.widgetDetails.WidgetRate)) {
            appDetails.widgetDetails.WidgetRate.map((widgetRate: WidgetRate) => {
                if(Array.isArray(widgetRate.RatePlaneDetails) && widgetRate.RatePlaneDetails.length > 0) {
                    setSelectedRate(widgetRate.uuid ? widgetRate.uuid : '')
                }
            })
        }
    }, [appDetails])

    const onChangePackage = (e: any) => {
        if (appDetails && appDetails.widgetDetails && appDetails.widgetDetails.isPaymentMethodEditable) {
            setSelectedRate(e.target.value);
        }
    }

    return (
        <div className="panel-table-content-list">
            {appDetails && appDetails.widgetDetails && Array.isArray(appDetails.widgetDetails.WidgetRate) && appDetails.widgetDetails.WidgetRate.map((value: WidgetRate, index: number) => {
                return (
                    <RateCardSelect
                        key={index}
                        widgetRate={value}
                        onChangeRate={onChangePackage}
                        selectedRate={selectedRate}
                        validator={validator}

                    />
                )
            })}
            <div className="validation invalid-feedback">{validator.message('rate', selectedRate, 'required')}</div>
        </div>
    )
}

export { ChargeableTypeOneComponent }