import { existingUserDetailsModel } from '../../pages/home/models/request/existingUserDetails.model';
import { newEsmsUserDetailsModel } from "../../pages/home/models/request/newEsmsUserDetails.model";
import { message, notification } from 'antd';

import {
    getUserDetails,
    postNewEsmsUser,
    getImageAsBase64
} from '../../services/esms.service';
import esmsStore from '../reducers/esms.store';


export const getExistingUserDetails = (uuid: string) => {
    return (dispatch: any) => {
        getUserDetails(uuid)
            .then((response) => {
                console.log('response', response.data.data)
                let ent_res = response.data.data;
                if (ent_res !== null && ent_res.entData !== null) {
                    let doc_uuid = ent_res.entData.docType === "BRC" ? ent_res.entData.entBussinessRegDoc1 : ent_res.entData.ownerNICCopy;
                    getImageAsBase64(doc_uuid)
                        .then((res) => {
                            console.log("res", res.data.data);
                            ent_res["brc_doc"] = res.data !== null ? res.data : '';
                            if (response.status === 200) {
                                const existingUserData = {
                                    appId: "",
                                    maId: response.data.data.marketingAgents.MarketingAdmins[0].uuid,
                                    username: response.data.data.userDetails.userName,
                                    fname: response.data.data.userDetails.firstName,
                                    lname: response.data.data.userDetails.lastName,
                                    mobile: response.data.data.userDetails.contact,
                                    mobileList: response.data.data.msisdnList,
                                    address: response.data.data.enterpriseAddress,
                                    email: response.data.data.userDetails.email,
                                    nicOrBrc: response.data.data.entData.entBussinessRegNumber === "" ?
                                        response.data.data.entData.ownerNIC :
                                        response.data.data.entData.entBussinessRegNumber,
                                    image: res.data.data,
                                    defaultMask: response.data.data.defaultMask,
                                    marketingAgentList: response.data.data.marketingAgents,
                                    marketingAgent: response.data.data.marketingAgents.MarketingAdmins[0].uuid,
                                    accountUsedFor: '',
                                    password: '',
                                    confirm_password: '',
                                    agreeToTerms: false
                                }
                                dispatch(esmsStore.actions.getExistingUserDetails(existingUserData));
                            }
                        })
                }
            })
            .catch((error: existingUserDetailsModel) => {
                dispatch(esmsStore.actions.getExistingUserDetails(error));
            });
    }
};

export const submitNewEsmsUser = (tenantId: string, widgetId: string, req: newEsmsUserDetailsModel) => {
    console.log("req req", req)
    return (dispatch: any) => {
        message.loading('Creating your account...')
        postNewEsmsUser(tenantId, widgetId, req)
            .then((response: any) => {
                if (response.status === 200) {
                    console.log("response", response.data.data)
                    if (response.data.data.comment === "User created") {
                        notification.success({
                            message: `SUCCESS`,
                            description: 'Successfully created an account for eSMS. Please login.',
                            placement: 'bottomRight'
                        });
                        window.open('https://esms.dialog.lk/login', "_blank") || window.location.replace('https://esms.dialog.lk/login');
                    } else if (response.data.data.comment === "Authentication Token Expired" ||
                        response.data.data.comment === "Authentication Token Invalid" ||
                        response.data.data.comment === "Agent Data Not Found" ||
                        response.data.data.comment === "Error Occurred" ||
                        response.data.data.comment === "Authentication Failure") {
                        notification.error({
                            message: `ERROR`,
                            description: "Oops… Something went wrong! Please contact the Marketplace administrator",
                            placement: 'bottomRight'
                        });
                    } else if (response.data.data.comment === "Your Account is suspended") {
                        notification.error({
                            message: `ERROR`,
                            description: "Your account is suspended, please contact the Marketplace administrator",
                            placement: 'bottomRight'
                        });
                    } else if (response.data.data.comment === "Email is in use") {
                        notification.error({
                            message: `ERROR`,
                            description: "The email address already exists",
                            placement: 'bottomRight'
                        });
                    } else if (response.data.data.comment === "Invalid Email") {
                        notification.error({
                            message: `ERROR`,
                            description: "The email address is invalid. Please check and resubmit.",
                            placement: 'bottomRight'
                        });
                    } else if (response.data.data.comment === "Mobile is in use") {
                        notification.error({
                            message: `ERROR`,
                            description: "Mobile number already exists",
                            placement: 'bottomRight'
                        });
                    } else if (response.data.data.comment === "Username is in use") {
                        notification.error({
                            message: `ERROR`,
                            description: "Username already exists",
                            placement: 'bottomRight'
                        });
                    } else if (response.data.data.comment === "Mask or Port is in use") {
                        notification.error({
                            message: `ERROR`,
                            description: "Mask already exists",
                            placement: 'bottomRight'
                        });
                    } else if (response.data.data.comment === "Registration Failure") {
                        notification.error({
                            message: `ERROR`,
                            description: "Your account registration is failed, please contact the Marketplace administrator",
                            placement: 'bottomRight'
                        });
                    } else if (response.data.data.comment === "Username can only have letters and numbers") {
                        notification.error({
                            message: `ERROR`,
                            description: "Username can only have letters and numbers",
                            placement: 'bottomRight'
                        });
                    } else if (response.data.data.comment === "Invalid character length for NIC or BRC") {
                        notification.error({
                            message: `ERROR`,
                            description: "The entered NIC or BRC is invalid",
                            placement: 'bottomRight'
                        });
                    } else if (response.data.data.comment === "Invalid Username") {
                        notification.error({
                            message: `ERROR`,
                            description: "Authentication token is invalid, please contact the Marketplace administrator",
                            placement: 'bottomRight'
                        });
                    } else if (response.data.data.comment === "You haven't activated your account") {
                        notification.error({
                            message: `ERROR`,
                            description: "You haven't activated your account, please contact the Marketplace administrator",
                            placement: 'bottomRight'
                        });
                    } else if (response.data.data.comment === "Your Account is pending approval") {
                        notification.error({
                            message: `ERROR`,
                            description: "Your Account is pending approval, please contact the Marketplace administrator",
                            placement: 'bottomRight'
                        });
                    } else if (response.data.data.comment === "Your Account is rejected") {
                        notification.error({
                            message: `ERROR`,
                            description: "Your Account is rejected, please contact the Marketplace administrator",
                            placement: 'bottomRight'
                        });
                    } else if (response.data.data.comment === "Account Created,But none of the admins were notified") {
                        notification.error({
                            message: `ERROR`,
                            description: "'Sending admin notification' has been failed regarding your account creation. Please contact marketplace admin team",
                            placement: 'bottomRight'
                        });
                    } else if (response.data.data.comment === "Your account temporary deactived.") {
                        notification.error({
                            message: `ERROR`,
                            description: "Your account temporary deactivated.",
                            placement: 'bottomRight'
                        });
                    } else if (response.data.data.comment === "Error occurred while image upload") {
                        notification.error({
                            message: `ERROR`,
                            description: "Error occurred while image upload! Please contact the Marketplace administrator",
                            placement: 'bottomRight'
                        });
                    } else if (response.data.data.comment === "Illegal characters in the Last Name") {
                        notification.error({
                            message: `ERROR`,
                            description: "Illegal characters in the Last Name",
                            placement: 'bottomRight'
                        });
                    } else {
                        notification.error({
                            message: `ERROR`,
                            description: "Oops… Something went wrong! Please contact the Marketplace administrator",
                            placement: 'bottomRight'
                        });
                    }

                    dispatch(esmsStore.actions.postNewEsmsUserDetails(response.data));
                }
            })
            .catch((error: newEsmsUserDetailsModel) => {
                dispatch(esmsStore.actions.postNewEsmsUserDetails(error));
            });
    }
};
