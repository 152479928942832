/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { CSSProperties, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import { fetchSentimentData, fetchSingleApp } from '../../../store/actions';
import AuthHandler from '../../../helper/AuthHandler';
import TableIcon from '../../../assets/images/icons/app-media-placeholder.svg';
import { PageHeaderStatic } from "../components/PageHeaderStatic";
import { confirmAlert } from "react-confirm-alert";
import { Tooltip } from "antd";
import { EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import Moment from 'moment';

const override: CSSProperties = {
    display: "block",
    margin: "0 auto"
};

const SentimentList = ({ auth }: any) => {

    const { wid } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { userDetails } = useSelector(({ user }: any) => user);
    const { success, loading, error, sentimentList } = useSelector(({ sentiment }: any) => sentiment);

    const [limit, setLimit] = useState<number>(10);
    const [offset, setOffset] = useState<number>(0);

    useEffect(() => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        dispatch(fetchSingleApp(wid!, tenantGUID));
    }, [wid, userDetails]);

    useEffect(() => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        dispatch(fetchSentimentData(tenantGUID, wid!));
    }, [wid, userDetails]);

    // const onViewMore = () => {
    //     setOffset(offset + 10);
    // }

    const showDeletePopup = (domainUuid: string) => {
        confirmAlert({
            message: "Are you sure, you want to remove this domain?",
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => onDeleteDomain(domainUuid)
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    const onDeleteDomain = (domainUuid: string) => {
        console.log("Domain to be deleted: ", domainUuid)
        return true;
    }

    const onViewConfig = (sentimentId: string) => {
        navigate(`/sentiment/${wid}/sentiment-update/${sentimentId}`);
    }

    const onDetailConfig = (sentimentId: string) => {
        navigate(`/sentiment/${wid}/sentimentlogs/${sentimentId}`);
    }

    const renderConfigList = () => {

        if (success && sentimentList) {
            if (sentimentList.length > 0) {
                return (
                    <div className="section__wrapper">
                        <div className="card panel content-holder-panels">
                            <div className="panel-header">
                                <div className="panel-header-content__wrapper">
                                    <h4 className="panel-header-title">List of Configs</h4>
                                </div>
                            </div>
                            <div className="panel-table-listing__wrapper">
                                <div className="table-parent__wrapper">
                                    <table className="table table-default">
                                        <tbody>
                                            <tr className="table-header">
                                                <th className="txt-left">#</th>
                                                <th className="txt-left">Sentiment Ref</th>
                                                <th className="txt-left">SMS Sender Name</th>
                                                <th className="txt-left">Number</th>
                                                <th className="txt-left">Mask</th>
                                                <th className="txt-left">Start Time</th>
                                                <th className="txt-left">End Time</th>
                                                <th className="txt-left">Status</th>
                                                <th className="txt-left">View</th>
                                                <th className="txt-left">Details</th>
                                            </tr>
                                            {sentimentList.map((value: any, index: number) => {
                                                return (
                                                    <tr className="table-row ">
                                                        <td className="txt-left">{index + 1}</td>
                                                        <td className="txt-left">{value.uuid}</td>
                                                        <td className="txt-left">{value.createdBy}</td>
                                                        <td className="txt-left">{value.ownerNumber}</td>
                                                        <td className="txt-left">{decodeURIComponent(value.smsMask)}</td>
                                                        <td className="txt-left">{Moment(value.startTime).format('YYYY-MM-DD HH:mm')}</td>
                                                        <td className="txt-left">{Moment(value.endTime).format('YYYY-MM-DD HH:mm')}</td>
                                                        <td className="txt-left">{value.status}</td>
                                                        <td className="txt-left" style={{ textAlign: 'center' }}>
                                                            <Tooltip title="View">
                                                                <EyeOutlined onClick={() => onViewConfig(value.uuid)} />
                                                            </Tooltip>
                                                        </td>
                                                        <td className="txt-left" style={{ textAlign: 'center' }}>
                                                            <Tooltip title="View">
                                                                <EyeOutlined onClick={() => onDetailConfig(value.uuid)} />
                                                            </Tooltip>
                                                            {/* <Tooltip title="Delete">
                                                                <DeleteOutlined onClick={() => showDeletePopup(value.uuid)} />
                                                            </Tooltip> */}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            {/*<tr className="table-row">*/}
                                            {/*    <td>*/}
                                            {/*        <a onClick={onViewMore}>View All</a>*/}
                                            {/*    </td>*/}
                                            {/*</tr>*/}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else {
                let path = window.location.pathname.split('/');
                return (
                    <div className="section__wrapper">
                        <div className="empty-state-new-action__wrapper">
                            <div className="new-action-content__wrapper">
                                <h4 className="sub">No Records available</h4>
                                <h4 className="title">Create new request</h4>
                                <button className="btn btn-primary" onClick={() => navigate(path[0] + '/' + path[1] + '/' + path[2] + '/sentiment-create')}>New request</button>
                            </div>
                        </div>
                    </div>
                )
            }
        } else if (loading) {
            return (
                <div className="section__wrapper">
                    <div className="empty-state-new-action__wrapper">
                        <section className="content__wrapper">
                            <div className="primaryBrandLoaderBg">
                                <div className="primaryBrandLoader">
                                    <img src={require('../../../assets/images/loader-bg-primary.svg').default} alt="" srcSet="" />
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            )
        } else if (error) {
            return (
                <div className="section__wrapper">
                    <div className="empty-state-new-action__wrapper">
                        <div className="new-action-content__wrapper">
                            <h4 className="sub">Oops, something went wrong!</h4>
                        </div>
                    </div>
                </div>
            )
        }
    }

    if (AuthHandler.isAuthorized(auth, 'sentiment-face-2-list') && userDetails !== null) {
        let path = window.location.pathname.split('/');
        return (
            <React.Fragment>
                <div className="main-content main-spacer-x-y">
                    <PageHeaderStatic
                        listShow={Array.isArray(sentimentList) && sentimentList.length > 0}
                        page={'list'}
                        newButtonLink={path[0] + '/' + path[1] + '/' + path[2] + '/sentiment-create'}
                    />
                    {renderConfigList()}
                </div>

            </React.Fragment>
        );
    } else return (<React.Fragment></React.Fragment>);
}

export default SentimentList;