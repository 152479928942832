import React, {FocusEvent, useEffect, useState} from 'react'
import {useSelector} from 'react-redux';
import {useAppDispatch} from '../../../store';
import {
    WidgetRate,
} from '../models/response/single-app.model';
import {RateItemTypeTenComponent} from "./RateItemTypeTenComponent";
import {InputTextField} from "../../../components";
import SimpleReactValidator from "simple-react-validator";
import {fetchBetterHrTotAmountData} from "../../../store/actions/better-hr.action";

type Props = {
    appDetails: any
}

const ChargeableTypeTenComponent = ({appDetails}: Props) => {
    const [count, setCount] = useState<string>('');
    const [amount, setAmount] = useState<string>('');
    const [countErrorTxt, setCountErrorTxt] = useState<string>('');

    const [validator] = useState(new SimpleReactValidator({
        messages: {
            regex: 'The :attribute field cannot be null.',
            min: ':attribute is too short',
            max: ':attribute is too lengthy',
        }
    }))

    const {
        totAmountData,
        licenceCount
    } = useSelector<any, any>(({ betterhr }) => betterhr);

    const {
        isSubscribeClicked
    } = useSelector<any, any>(({ apps }) => apps);

    const dispatch = useAppDispatch();

    const useForceUpdate = () => {
        const [value, setValue] = useState(0);
        return () => setValue(value => value + 1);
    }

    const forceUpdate = useForceUpdate()

    useEffect(() => {
        if(licenceCount && licenceCount >= 0) {
            setCount(licenceCount)
            setCountErrorTxt("");
        }
    }, [])

    useEffect(() => {
        if(totAmountData && totAmountData.totalAmount) {
            setAmount(totAmountData.totalAmount)
        }
    }, [totAmountData])

    useEffect(() => {
        if(isSubscribeClicked) {
            validator.showMessages();
            forceUpdate();
        }
    }, [isSubscribeClicked])

    const handleChange = (name: string) => (event: any) => {
        if (name === "count") {
            if(event.target.value >= 0) {
                setCount(event.target.value);
                setCountErrorTxt("");
            }
        } else if (name === "amount") {
            // no change
        }
        forceUpdate();
    };

    const onBlur = (event: FocusEvent<HTMLInputElement>) => {
        if(!isNaN(parseInt(count)) && parseInt(count) >= 0 && appDetails.widgetDetails && appDetails.widgetDetails.uuid) {
            dispatch(fetchBetterHrTotAmountData(appDetails.widgetDetails.uuid, parseInt(count)))
            if(parseInt(count) < 10) {
                setCountErrorTxt("Minimum licence count is 10");
            }
        }
    }

    return (
        <>
            <p className="pricing-type-ten-note">The charging will be calculated based on number of licences - Rs. {appDetails.widgetDetails.WidgetRate[0].rate} per licence</p>
            <div style={{display: "flex", alignItems: "center"}}>
                <label style={{marginRight: 10}} htmlFor="" className="form-label">Number of licences
                    <span aria-label="required"></span>{" :"}
                </label>
                <InputTextField
                    name='count'
                    type={"number"}
                    placeholder='Enter licences count'
                    value={count}
                    setValue={handleChange}
                    validator={validator}
                    rules={['required']}
                    disabled={appDetails.subscriptionStatus === "Subscribed" || appDetails.subscriptionStatus === "Pending"}
                    status="default"
                    validationMessage=""
                    forceUpdate={forceUpdate}
                    onBlurValue={onBlur}
                    specificClasses={"form-element__wrapper validated mb-0"}
                    errorText={countErrorTxt}
                />
            </div>
            <div className="pricing-grid-list__wrapper">
                {appDetails.widgetDetails && Array.isArray(appDetails.widgetDetails.WidgetRate) && appDetails.widgetDetails.WidgetRate.map((value: WidgetRate, index: number) => {
                    return (
                        <RateItemTypeTenComponent
                            key={index}
                            rateDetails={value}
                        />
                    )
                })}
            </div>
            <div style={{display: "flex", alignItems: "center"}}>
                <label style={{marginRight: 10}} htmlFor="" className="form-label">Total subscription amount :
                </label>
                <InputTextField
                    name='amount'
                    placeholder=''
                    value={(!isNaN(parseInt(count)) && parseInt(count) >= 10) ? amount : '0.00'}
                    setValue={handleChange}
                    validator={validator}
                    rules={[]}
                    disabled={appDetails.subscriptionStatus === "Subscribed" || appDetails.subscriptionStatus === "Pending"}
                    status="default"
                    validationMessage=""
                    forceUpdate={forceUpdate}
                    specificClasses={"form-element__wrapper validated mb-0"}
                />
                <label style={{marginLeft: 10}} htmlFor="" className="form-label"> + Tax
                </label>
            </div>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "center", marginTop: 30}}>
                <p style={{marginRight: 5}}>Please refer Better HR</p>
                <a href={"https://d2xt8w8fh2bnjt.cloudfront.net/prod/fe/marketplace/pdf/Terms & Conditions for Better HR.pdf"} target="_blank">
                    Terms and Conditions</a>
            </div>
        </>
    )
}

export {ChargeableTypeTenComponent};