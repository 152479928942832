/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useEffect, useRef, useState} from 'react';
import {Alert, Button, Form} from 'antd';
import {MapContainer, Marker, TileLayer} from "react-leaflet";
import L from "leaflet";
import MarkerMapIcon from "../../../../assets/images/Marker.svg";
import {onSearch} from "../../../../services/widgets.service";
import {CoverageModel} from "../models/coverage.model";

const layout = {
    labelCol: {span: 4},
    wrapperCol: {span: 20},
};
const tailLayout = {
    wrapperCol: {offset: 0, span: 24},
};

type Props = {
    checkCoverage: (values: CoverageModel) => void,
    coverageStatus: string,
    hasCoverage: boolean,
    setLocationMap: () => void,
}

const LocationMapForm = ({checkCoverage, coverageStatus, hasCoverage, setLocationMap}: Props) => {

    const [searchText, setSearchText] = useState("");
    const [locationSelected, setLocationSelected] = useState(false);
    const [searchResult, setSearchResult] = useState([]);
    const [position, setPosition] = useState({lat: 6.927079, lng: 79.861244});
    const [latitude, setLatitude] = useState(6.927079);
    const [longitude, setLongitude] = useState(79.861244);
    const [zoom, setZoom] = useState(13);

    const mapRef = useRef<any>(null);

    const MarkerIcon = L.icon({
        iconUrl: MarkerMapIcon,
        iconRetinaUrl: MarkerMapIcon,
        iconSize: new L.Point(50, 50),
        className: 'leaflet-div-icon'
    });

    useEffect(() => {
        if (searchText !== '' && !locationSelected) {
            var searchString = searchText.replace(/,/g, "%2C");
            searchString = searchString.replace(/ /g, "+");
            onSearch(searchString).then((data) => {
                // if(routePath !== "view-manage-campaign") {
                setSearchResult(data.data);
                // }
            });
        }
    }, [searchText]);

    useEffect(() => {
        if (mapRef.current) {
            // Perform animation after the map is initialized
            mapRef.current.setView(position, 13, {animate: true});
            // Add event listener for zoomstart event
            mapRef.current.on('zoomstart', onSetZoom);
            mapRef.current.on('click', onMapPress);

        }
        // Remove event listener on component unmount
        return () => {
            if (mapRef.current) {
                mapRef.current.off('zoomstart', onSetZoom);
                mapRef.current.off('click', onMapPress);
            }
        };
    }, [mapRef, position]);

    useEffect(() => {
        setPosition({lat: latitude !== null ? latitude : 6.927079, lng: longitude !== null ? longitude : 79.861244});
    }, [latitude, longitude]);

    const onSearchLocation = (e: any) => {
        setLocationSelected(false);
        setSearchText(e.target.value);
    }

    const onSelectSearch = (value: any) => {
        let lat = parseFloat(value.lat);
        let lon = parseFloat(value.lon);
        setSearchText(value.display_name);
        setLocationSelected(true);
        setSearchResult([]);
        setLatitude(lat);
        setLongitude(lon)
    }

    const renderSearchList = () => {
        return (
            <ul className="dropdown-list">
                {searchResult && searchResult.map(function (value: any, index: number) {
                    return (
                        <li className="dropdown-item" key={index}>
                            <a onClick={() => onSelectSearch(value)}>{value.display_name}</a>
                        </li>
                    )
                })}
            </ul>
        )
    }

    const onMapPress = (e: any) => {
        setLongitude(e.latlng.lng);
        setLatitude(e.latlng.lat);
    }

    const onDragMarker = (e: any) => {
        try {
            setLongitude(e.target._latlng.lng);
            setLatitude(e.target._latlng.lat);
        } catch (err) {
            console.log(err);
        }
    }
    const onSetZoom = (e: any) => {
        try {
            setZoom(e.target._zoom);
        } catch (err) {
            console.log(err);
        }
    }

    const checkCoverageAvailability = () => {
        const data = {
            searchText,
            position
        }
        checkCoverage(data);
    }

    return (
        <React.Fragment>
            <div>
                <div className="card-body gws__body">
                    <h4 className="gws-title">Check on the coverage availability in your area</h4>
                    <Form
                        {...layout}
                        name="address-form"
                        style={{maxWidth: 600, marginInline: 'auto', textAlign: 'left'}}
                        initialValues={{remember: true}}
                        onFinish={setLocationMap}
                        autoComplete="off"
                        labelAlign={"right"}
                    >
                        <>
                            <div className="form-group__wrapper">
                                <div className="form-group" style={{marginInlineEnd: 0}}>
                                    <div className="form-element__wrapper mb-3 has-dropdown">
                                        <label htmlFor="" className="form-label">
                                            Search location
                                        </label>
                                        <div className="icon-inside right">
                                                <span className="in-icon" onClick={() => {
                                                    setSearchText('');
                                                    setLocationSelected(false);
                                                    setSearchResult([]);
                                                }}>
                                                    <i className={searchText == '' ? "bi bi-search" : "bi bi-x"}/>
                                                </span>
                                            <input
                                                type="text"
                                                id="otp"
                                                className="form-control form-control-md"
                                                value={searchText}
                                                onChange={onSearchLocation}
                                                placeholder="Enter location"
                                                disabled={false}
                                            />
                                        </div>
                                        {(searchResult.length !== 0) && <div className="dropdown first__element">
                                            {renderSearchList()}
                                        </div>}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group__wrapper">
                                <div className="form-group" style={{marginInlineEnd: 0}}>
                                    <div className="form-element__wrapper mb-3 second__element">
                                        <label htmlFor="" className="form-label">
                                            Select location on the map
                                        </label>
                                        <div className="inner-feature-element-container__wrapper">
                                            <MapContainer
                                                ref={mapRef}
                                                center={position}
                                                zoom={zoom}
                                                zoomControl={true}
                                                doubleClickZoom={true}
                                                id="map"
                                                style={{height: '400px'}}
                                            >
                                                <TileLayer
                                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                    attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                                                />
                                                <Marker
                                                    draggable
                                                    opacity={0.8}
                                                    position={position}
                                                    icon={MarkerIcon}
                                                    eventHandlers={{
                                                        dragend: onDragMarker,
                                                    }}
                                                >
                                                </Marker>
                                            </MapContainer>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        <div className="coverage-status">
                            {(coverageStatus && hasCoverage) ? <Alert message={coverageStatus} type="success" showIcon />
                                : (coverageStatus && !hasCoverage) ? <Alert message={coverageStatus} type="error" showIcon />
                                    : <></>}
                        </div>
                        <Form.Item {...tailLayout} className={"wizard-action"}>
                            {!coverageStatus ? <Button type="primary" className={"btn btn-primary btn-sm"} onClick={checkCoverageAvailability}>
                                Check Coverage
                            </Button>
                                : (coverageStatus && hasCoverage) ? <Button type="primary" htmlType="submit" className={"btn btn-primary btn-sm"}>
                                    Next
                                </Button>
                                    : <></>}
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    );
}

export default LocationMapForm;