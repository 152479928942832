import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllConfigs, fetchEmojotWorkflowDetails, fetchSingleApp } from "../../../store/actions";
import AuthHandler from "../../../helper/AuthHandler";
import { Col, Row, Form, Input, Modal, Steps, Spin, Upload } from "antd";
import { EyeOutlined, FormOutlined } from "@ant-design/icons";
import { PageHeaderDynamicFormComponent } from "../components/PageHeaderDynamicForm.component";
import { useNavigate, useParams } from "react-router-dom";
import * as Constants from '../../../constants';

const { Step } = Steps;

const DynamicWidgeWorkflow = (props) => {
    const { wid } = useParams();

    const dispatch = useDispatch();
    const { userDetails } = useSelector(({ user }) => user);
    const {
        singleAppDetails
    } = useSelector(({ apps }) => apps);
    const { widgetConfigListLoading, widgetConfigList, workflowLoading, workflowResponse } = useSelector(({ dynamicForm }) => dynamicForm);
    const paths = window.location.pathname.split('/');
    const navigate = useNavigate();

    useEffect(() => {
        const tenantId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        dispatch(fetchSingleApp(wid, tenantId));
    }, [userDetails])

    useEffect(() => {
        const tenantId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        const configId = window.location.pathname.split('/')[Constants.BASEURLPATHLENGTH + 4];
        if (singleAppDetails && singleAppDetails.widgetDetails) {
            dispatch(fetchAllConfigs(singleAppDetails.widgetDetails.dataUrlV2, tenantId, singleAppDetails.widgetDetails.uuid))
            dispatch(fetchEmojotWorkflowDetails(tenantId, singleAppDetails.widgetDetails.uuid, configId))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleAppDetails]);

    // console.log("workflowResponse", workflowResponse,workflowResponse !== null && Array.isArray(workflowResponse.emojotLeadHistory), workflowResponse !== null && (workflowResponse.emojotLeadHistory[workflowResponse.emojotLeadHistory.length - 1].status !== 'Solution Deployed' || workflowResponse.emojotLeadHistory[workflowResponse.emojotLeadHistory.length - 1].status !== 'Account Created'));
    // console.log("workflowLoading", workflowLoading);

    return (
        <React.Fragment>
            <Spin size="large" spinning={workflowLoading}>
                {
                    AuthHandler.isAuthorized(props.auth, 'dynamin-widget') && userDetails !== null ?
                        <div className="main-content main-spacer-x-y">
                            <PageHeaderDynamicFormComponent
                                page={'workflow'}
                                leadCount={'single'}
                                widgetConfigList={widgetConfigList}
                            />
                            <div className="section__wrapper">
                                <div className="card panel content-holder-panels">
                                    <div className="panel-header">
                                        <div className="panel-header-content__wrapper">
                                            <h4 className="panel-header-title">{(singleAppDetails && singleAppDetails.widgetDetails) ? singleAppDetails.widgetDetails.title : ''} Workflow</h4>
                                        </div>
                                    </div>
                                    <div className="panel-table-listing__wrapper">
                                        <Row>
                                            <Col span={12}>                                                <div className="table-parent__wrapper">
                                                <Steps current={(workflowResponse !== null && Array.isArray(workflowResponse.emojotLeadHistory)) ? workflowResponse.emojotLeadHistory.length : 1} direction="vertical">
                                                    {workflowResponse !== null && Array.isArray(workflowResponse.emojotLeadHistory) && workflowResponse.emojotLeadHistory.map((value, index) => {
                                                        return (
                                                            <Step
                                                                title={value.status}
                                                                description={value.updatedTime}
                                                            />
                                                        )
                                                    })}
                                                    {workflowResponse !== null && Array.isArray(workflowResponse.emojotLeadHistory) && workflowResponse.emojotLeadHistory[workflowResponse.emojotLeadHistory.length - 1] !== undefined && (workflowResponse.emojotLeadHistory[workflowResponse.emojotLeadHistory.length - 1].status !== 'Solution Deployed' && workflowResponse.emojotLeadHistory[workflowResponse.emojotLeadHistory.length - 1].status !== 'Account Created') &&
                                                        <Step
                                                            title={'...'}
                                                            description={'..............'}
                                                        />}
                                                </Steps>
                                            </div></Col>
                                            <Col span={12}>
                                                <div className="table-parent__wrapper">
                                                    {workflowResponse !== null && workflowResponse.emojotLeadConfigs !== null && <div>
                                                        WFM ID
                                                        <Input value={workflowResponse.emojotLeadConfigs?.leadId} readOnly />
                                                    </div>}
                                                    {workflowResponse && workflowResponse.proposalDocs &&
                                                        <div style={{ marginTop: '20px' }}>
                                                            Proposal Documents
                                                            <Upload
                                                                listType="text"
                                                                fileList={workflowResponse.proposalDocs}
                                                                disabled
                                                            />
                                                        </div>
                                                    }
                                                    {workflowResponse && workflowResponse.supportingDocs &&
                                                        <div style={{ marginTop: '20px' }}>
                                                            Supporting Documents
                                                            <Upload
                                                                listType="text"
                                                                fileList={workflowResponse.supportingDocs}
                                                                disabled
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <></>
                }
            </Spin>
        </React.Fragment>
    )
};

export default DynamicWidgeWorkflow;