import sentimentStore from "../reducers/sentiment.store";

import {
    getSentimentList,
    postSentimentData,
    getNumberCount,
    getSentimentIndividualStatus,
    putSentimentStatus,
    getSentimentLogs,
    getSentimentWidget,
    getSentimentQuestionsListForCustomers,
    putSentimentCustomerResponses
} from "../../services/sentiment.service";
import { notification } from "antd";

export const fetchSentimentData = (tenantUuid: string, configUuid: string): any => {
    return (dispatch: any) => {
        dispatch(sentimentStore.actions.setimentFetch());

        getSentimentList(tenantUuid, configUuid)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(sentimentStore.actions.sentimentFetchSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(sentimentStore.actions.sentimentFetchFail(error));
            });
    }
};

export const createSentimentData = (request_payload: any, tenantUuid: string, widgetUuid: string): any => {
    return (dispatch: any) => {
        dispatch(sentimentStore.actions.sentimentDataPost());
        postSentimentData(request_payload, tenantUuid, widgetUuid)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(sentimentStore.actions.sentimentDataPostSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(sentimentStore.actions.sentimentDataPostFail(error));
                console.log("error",error)
                notification.error({
                    message: `ERROR`,
                    description: error?.response?.data?.error,
                    placement: 'topRight'
                });
            });
    }
};

export const fetchNumberCount = (entId: string, numberBaseId: string): any => {
    return (dispatch: any) => {
        dispatch(sentimentStore.actions.numberCountGet());

        getNumberCount(entId, numberBaseId)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(sentimentStore.actions.numberCountGetSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(sentimentStore.actions.numberCountGetFail(error));
                notification.error({
                    message: `ERROR`,
                    description: "Error occurred while adding new sentiment. Please try again.",
                    placement: 'bottomRight'
                });
            });
    }
};

export const fetchIndividualSentimentDetails = (tenantUuid: string, sentimentUuid: string): any => {
    return (dispatch: any) => {
        dispatch(sentimentStore.actions.sentimentIndividualDetailsFetch());

        getSentimentIndividualStatus(tenantUuid, sentimentUuid)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(sentimentStore.actions.sentimentIndividualDetailsFetchSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(sentimentStore.actions.sentimentIndividualDetailsFetchFail(error));
            });
    }
};

export const updateSentimentStatus = (tenantId: string, sentimentId: string, data: any): any => {
    return (dispatch: any) => {
        dispatch(sentimentStore.actions.sentimentUpdateFetch());

        putSentimentStatus(tenantId, sentimentId, data)
            .then((response) => {
                if (response.status == 200) {
                    dispatch(sentimentStore.actions.sentimentUpdateSuccess(response.data));
                } else {

                }
            })
            .catch((error) => {
                dispatch(sentimentStore.actions.sentimentUpdateFail(error));
                notification.error({
                    message: `ERROR`,
                    description: error?.response?.data?.message,
                    placement: 'topRight'
                });
            });
    }
};

export const fetchSentimentLogs = (tenantUuid: string, sentimentUuid: string): any => {
    return (dispatch: any) => {
        dispatch(sentimentStore.actions.sentimentLogFetch());

        getSentimentLogs(tenantUuid, sentimentUuid)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(sentimentStore.actions.sentimentLogFetchSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(sentimentStore.actions.sentimentLogFetchFail(error));
            });
    }
};

export const fetchSentimentQuestionsForCustomer = (sentimentId: string, msisdn: string): any => {
    return (dispatch: any) => {
        dispatch(sentimentStore.actions.sentimentWidgetFetch());

        getSentimentWidget(sentimentId)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(sentimentStore.actions.sentimentWidgetFetchSuccess(response.data));

                    dispatch(sentimentStore.actions.sentimentIndividualDataFetch());
                    getSentimentQuestionsListForCustomers(sentimentId, msisdn)
                        .then((response) => {
                            if (response.status === 200) {
                                dispatch(sentimentStore.actions.sentimentIndividualDataFetchSuccess(response.data));
                            }
                        })
                        .catch((error) => {
                            dispatch(sentimentStore.actions.sentimentIndividualDataFetchFail(error));
                        });
                }
            })
            .catch((error) => {
                dispatch(sentimentStore.actions.sentimentWidgetFetchFail(error));
            });
    }
}

export const putCustomerResponses = (data: any): any => {
    return (dispatch: any) => {
        dispatch(sentimentStore.actions.sentimentCustomerResponseFetch());

        putSentimentCustomerResponses(data)
        .then((response) => {
            if (response.status == 200) {
                dispatch(sentimentStore.actions.sentimentCustomerResponseSuccess(response.data));
            }
        })
        .catch((error) => {
            dispatch(sentimentStore.actions.sentimentCustomerResponseFail(error));
        });
    }
}