
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {OrderRespModel} from "../../pages/apps/fixedBB/models/orderResp.model";
import {SingleOrderRespModel} from "../../pages/apps/fixedBB/models/singleOrderResp.model";

interface AuthSliceModel {
}

const fixedBBStore = createSlice({
    name: "fixed-bb-store",
    initialState: {
        coverageRes: null,
        loading: false,
        success: false,
        error: false,
        packageData: null,
        traceId: "",
        deviceData: null,
        ordersList: [],
        singleOrder: null,
        createOrderResp: null,
        submitPaymentResp: null,
        adminApprovalReqCreated: false
    } as AuthSliceModel,
    reducers: {
        fetchCoverage: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        },
        fetchCoverageSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                coverageRes: action.payload,
                success: true,
                error: false
            }
        },
        fetchCoverageFail: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                coverageRes: null
            }
        },
        fetchPackageDetails: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        },
        fetchPackageDetailsSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                packageData: action.payload.packageArr,
                traceId: action.payload.traceId,
                success: true,
                error: false
            }
        },
        fetchPackageDetailsFail: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                packageData: null
            }
        },
        fetchDeviceDetails: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        },
        fetchDeviceDetailsSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                deviceData: action.payload,
                success: true,
                error: false
            }
        },
        fetchDeviceDetailsFail: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                deviceData: null
            }
        },
        postCartOrder: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true,
                success: false,
                createOrderResp: null,
                error: false
            }
        },
        postCartOrderSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                success: true,
                createOrderResp: action.payload,
                error: false
            }
        },
        postCartOrderFail: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false,
                success: false,
                createOrderResp: null,
                error: true
            }
        },
        fetchAllOrders: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        },
        fetchAllOrdersSuccess: (state: AuthSliceModel, action: PayloadAction<OrderRespModel>) => {
            return {
                ...state,
                loading: false,
                ordersList: action.payload,
                success: true,
                error: false
            }
        },
        fetchAllOrdersFail: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                ordersList: []
            }
        },
        fetchSingleOrder: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        },
        fetchSingleOrderSuccess: (state: AuthSliceModel, action: PayloadAction<SingleOrderRespModel>) => {
            return {
                ...state,
                loading: false,
                singleOrder: action.payload,
                success: true,
                error: false
            }
        },
        fetchSingleOrderFail: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                singleOrder: null
            }
        },
        putSubmitPayment: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true,
                success: false,
                submitPaymentResp: null,
                error: false
            }
        },
        putSubmitPaymentSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                success: true,
                submitPaymentResp: action.payload,
                error: false
            }
        },
        putSubmitPaymentFail: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false,
                success: false,
                submitPaymentResp: null,
                error: true
            }
        },
        requestAdminApproval: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true,
                success: false,
                adminApprovalReqCreated: false,
                error: false
            }
        },
        requestAdminApprovalSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                success: true,
                adminApprovalReqCreated: true,
                error: false
            }
        },
        requestAdminApprovalFail: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false,
                success: false,
                adminApprovalReqCreated: false,
                error: true
            }
        }
    },
});

export default fixedBBStore;