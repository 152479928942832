import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../store';
import { fetchAllOffers, fetchAppList, fetchWidgetsByCategory } from '../../store/actions';
import { WidgetModel } from '../discover/models/response/app-category.model';

type Props = {}

const AppCategory = (props: Props) => {
    const { catId } = useParams();
    const [categoriesOffset, setCategoriesOffset] = useState<number>(0);
    const [categoriesLimit, setCategoriesLimit] = useState<number>(8);
    const [searchText, setSearchText] = useState("");
    const [limit, setLimit] = useState(20);
    const [offset, setOffset] = useState(0);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const {
        categoryDetails
    } = useSelector<any, any>(({ apps }) => apps);

    const {
        allOffersList
    } = useSelector<any, any>(({ offers }) => offers);

    const {
        appList
    } = useSelector<any, any>(({ settings }) => settings);

    const {
        userDetails
    } = useSelector<any, any>(({ user }) => user);


    useEffect(() => {
        dispatch(fetchWidgetsByCategory(catId!, '0', categoriesLimit, categoriesOffset));
    }, []);

    const onRedirect = (uuid: string | null) => {
        navigate(`/widget/${uuid}`);
    }
    useEffect(() => {
        const tenantGroupId = (userDetails && userDetails?.OwnedEnterprises[0]) ? userDetails?.OwnedEnterprises[0].groupId : "";
        dispatch(fetchAllOffers(userDetails?.uuid, tenantGroupId));
    }, [userDetails]);

    useEffect(() => {
        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        if (tenantGroupId !== "" && searchText == "") {
            dispatch<any>(fetchAppList(tenantGroupId, searchText !== "" ? searchText : '0', limit, offset, appList));
        }
    }, [userDetails, offset, searchText]);

    return (
        categoryDetails ? <section className="content__wrapper">
            <div className="main-content">
                <div className="inner-top-feature-holder" style={{ backgroundImage: `url(https://d2xt8w8fh2bnjt.cloudfront.net/qa/categories/${categoryDetails.thumbnail})` }}>
                    <div className="container">
                        <button className="btn back-btn" aria-label="Back to the previous page" onClick={() => navigate(-1)}> <span />Go back
                        </button>
                        <div className="inner-feature-content">
                            <h1 className="app-title" itemProp="name" aria-label="App title">{categoryDetails.title}</h1>
                            <span className="app-desc">{categoryDetails.description}</span>
                            <div className="app-stats__container inner-top">
                                <div className="left-content__wrapper">
                                    <div className="app-stats__wrapper">
                                        <ul className="app-stats-list">
                                            <li className="app-stats-item">
                                                <div className="app-stats-inner__wrapper">
                                                    {/* <span className="stats-value text-gradient-highlighter">80%
                                                        match+</span> */}
                                                </div>
                                                <div className="app-stats-inner__wrapper">
                                                    {/* <span className="stats-desc">Your business type</span> <button className="btn btn-info btn-round btn-xsmall"><i className="bi bi-info-circle-fill" /></button> */}
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="container inner-container">
                    <div className="inner-content-section__wrapper">
                        <div className="app-listing-section__wrapper type-1">
                            <div className="app-listing-section-title__wrapper">
                                <span>Most popular</span>
                            </div>
                            <div className="app-listing-section not-at-right type-1-inner" id="listingScrollDiv">
                                <div className="app-listing-item__wrapper">
                                    <a href="http://">
                                    </a>
                                    <div className="app-listinng-item"><a href="http://">
                                        <div className="app-item-content__wrapper">
                                            <div className="card app-thumbnail-holder" style={{ backgroundImage: 'url(/src/assets/images/icons/app-thumbail-placeholder.webp)' }}>
                                            </div>
                                        </div>
                                    </a>
                                        <div className="app-item-content__wrapper app-details"><a href="http://">
                                            <div className="card app-media-holder" style={{ backgroundImage: 'url(/src/assets/images/icons/app-media-placeholder.svg)' }}>
                                            </div>
                                        </a>
                                            <div className="app-media-info-holder"><a href="http://">
                                            </a><a className="app-media-title">Cold room monitoring</a>
                                                <a href="http://" className="app-media-category">Automation </a>
                                                <div className="app-stats__wrapper">
                                                    <ul className="app-stats-list">
                                                        <li className="app-stats-item"><span className="stats-icon"><i className="bi bi-download" /></span><span className="stats-value">200+</span></li>
                                                        <li className="app-stats-item"><span className="stats-divider"><i className="bi bi-dot" /></span></li>
                                                        <li className="app-stats-item"><span className="stats-icon ico-highlighter"><i className="bi bi-star-fill" /></span><span className="stats-value">5.0</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="app-listing-item__wrapper">
                                    <a href="http://">
                                    </a>
                                    <div className="app-listinng-item"><a href="http://">
                                        <div className="app-item-content__wrapper">
                                            <div className="card app-thumbnail-holder" style={{ backgroundImage: `url(${require('../../assets/images/icons/app-thumbail-placeholder.webp')}` }}>
                                            </div>
                                        </div>
                                    </a>
                                        <div className="app-item-content__wrapper app-details"><a href="http://">
                                            <div className="card app-media-holder" style={{ backgroundImage: 'url(/src/assets/images/icons/app-media-placeholder.svg)' }}>
                                            </div>
                                        </a>
                                            <div className="app-media-info-holder"><a href="http://">
                                            </a><a className="app-media-title">App media title App media
                                                title</a>
                                                <a href="http://" className="app-media-category">App category long like this
                                                    some times </a>
                                                <div className="app-stats__wrapper">
                                                    <ul className="app-stats-list">
                                                        <li className="app-stats-item"><span className="stats-icon"><i className="bi bi-download" /></span><span className="stats-value">400+</span></li>
                                                        <li className="app-stats-item"><span className="stats-divider"><i className="bi bi-dot" /></span></li>
                                                        <li className="app-stats-item"><span className="stats-icon ico-highlighter"><i className="bi bi-star-fill" /></span><span className="stats-value">4.8</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="app-listing-item__wrapper">
                                    <a href="http://">
                                    </a>
                                    <div className="app-listinng-item"><a href="http://">
                                        <div className="app-item-content__wrapper">
                                            <div className="card app-thumbnail-holder" style={{ backgroundImage: 'url(/src/assets/images/icons/app-thumbail-placeholder.webp)' }}>
                                            </div>
                                        </div>
                                    </a>
                                        <div className="app-item-content__wrapper app-details"><a href="http://">
                                            <div className="card app-media-holder" style={{ backgroundImage: 'url(/src/assets/images/icons/app-media-placeholder.svg)' }}>
                                            </div>
                                        </a>
                                            <div className="app-media-info-holder"><a href="http://">
                                            </a><a className="app-media-title">App media title App media
                                                title</a>
                                                <a href="http://" className="app-media-category">App category long like this
                                                    some times </a>
                                                <div className="app-stats__wrapper">
                                                    <ul className="app-stats-list">
                                                        <li className="app-stats-item"><span className="stats-icon"><i className="bi bi-download" /></span><span className="stats-value">400+</span></li>
                                                        <li className="app-stats-item"><span className="stats-divider"><i className="bi bi-dot" /></span></li>
                                                        <li className="app-stats-item"><span className="stats-icon ico-highlighter"><i className="bi bi-star-fill" /></span><span className="stats-value">4.8</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="app-listing-item__wrapper">
                                    <a href="http://">
                                    </a>
                                    <div className="app-listinng-item"><a href="http://">
                                        <div className="app-item-content__wrapper">
                                            <div className="card app-thumbnail-holder" style={{ backgroundImage: 'url(/src/assets/images/icons/app-thumbail-placeholder.webp)' }}>
                                            </div>
                                        </div>
                                    </a>
                                        <div className="app-item-content__wrapper app-details"><a href="http://">
                                            <div className="card app-media-holder" style={{ backgroundImage: 'url(/src/assets/images/icons/app-media-placeholder.svg)' }}>
                                            </div>
                                        </a>
                                            <div className="app-media-info-holder"><a href="http://">
                                            </a><a className="app-media-title">App media title App media
                                                title</a>
                                                <a href="http://" className="app-media-category">App category long like this
                                                    some times </a>
                                                <div className="app-stats__wrapper">
                                                    <ul className="app-stats-list">
                                                        <li className="app-stats-item"><span className="stats-icon"><i className="bi bi-download" /></span><span className="stats-value">400+</span></li>
                                                        <li className="app-stats-item"><span className="stats-divider"><i className="bi bi-dot" /></span></li>
                                                        <li className="app-stats-item"><span className="stats-icon ico-highlighter"><i className="bi bi-star-fill" /></span><span className="stats-value">4.8</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="app-listing-item__wrapper">
                                    <a href="http://">
                                    </a>
                                    <div className="app-listinng-item"><a href="http://">
                                        <div className="app-item-content__wrapper">
                                            <div className="card app-thumbnail-holder" style={{ backgroundImage: 'url(/src/assets/images/icons/app-thumbail-placeholder.webp)' }}>
                                            </div>
                                        </div>
                                    </a>
                                        <div className="app-item-content__wrapper app-details"><a href="http://">
                                            <div className="card app-media-holder" style={{ backgroundImage: 'url(/src/assets/images/icons/app-media-placeholder.svg)' }}>
                                            </div>
                                        </a>
                                            <div className="app-media-info-holder"><a href="http://">
                                            </a><a className="app-media-title">App media title App media
                                                title</a>
                                                <a href="http://" className="app-media-category">App category long like this
                                                    some times </a>
                                                <div className="app-stats__wrapper">
                                                    <ul className="app-stats-list">
                                                        <li className="app-stats-item"><span className="stats-icon"><i className="bi bi-download" /></span><span className="stats-value">400+</span></li>
                                                        <li className="app-stats-item"><span className="stats-divider"><i className="bi bi-dot" /></span></li>
                                                        <li className="app-stats-item"><span className="stats-icon ico-highlighter"><i className="bi bi-star-fill" /></span><span className="stats-value">4.8</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className=" app-listing-section__wrapper type-2 full-bleed__wrapper light">
                    <div className="container inner-container full-bleed ">
                        <div className="app-listing-section-title__wrapper">
                            <span>Recommended for you</span>
                        </div>
                        <div className="app-listing-section not-at-right type-2-inner">
                            {categoryDetails && Array.isArray(categoryDetails.Widgets) && ((categoryDetails.Widgets).filter((item: any) => item.featured == true)).map((value: WidgetModel, index: number) => {
                                const hasOffer = Array.isArray(allOffersList?.allOffersWidgetDetailsList) && allOffersList?.allOffersWidgetDetailsList.some((offer: { uuid: string | null; }) => offer.uuid === value.uuid);
                                const hasSubscribed = Array.isArray(appList) && appList.some((appId: { uuid: string | null; }) => appId.uuid === value.uuid);

                                return (
                                    <div className="app-listing-item__wrapper" key={index} onClick={() => onRedirect(value.uuid)}>
                                        <a>
                                            <div className="app-listinng-item">
                                                <div className="app-item-content__wrapper app-details">
                                                    <div className="card app-media-holder"
                                                        style={{ backgroundImage: `url(${value.thumbnail})` }}>
                                                        {hasOffer && !hasSubscribed &&
                                                            <div className="discount-label">Offer</div>
                                                        }
                                                    </div>
                                                    <div className="app-media-info-holder">
                                                        <Link to={`/widget/${value.uuid}`} className="app-media-title">{value.title}</Link>
                                                        <p className="app-media-desc">{value.description}</p>
                                                        <a className="app-media-category">{categoryDetails.title}</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                )
                            })}
                        </div>

                    </div>
                </div>
            </div>
        </section> : <></>
    )
}

export default AppCategory;