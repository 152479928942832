import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Form, Modal, Radio, Row, Switch, Tooltip } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import * as Constants from '../../../constants';
import Auth from "../../../models/response/auth.model";
import { SingleAppModel } from "../../single-app/models/response/single-app.model";

type Props = {
    listShow?: boolean,
    backLink?: string,
    page: string,
    consentConfig?: any,
    addConsentConfigs?: any,
    auth?: Auth,
    newButtonLink?: string,
    newButtonText?: string,
    appDetails?: SingleAppModel,
    callBackend?: any,
    disableSMrequest?: boolean,
    externalLink?: string,
    externalLinkText?: string
}

const PageHeaderStatic = ({ listShow, backLink, page, consentConfig, addConsentConfigs, auth, newButtonLink, newButtonText, appDetails, callBackend, disableSMrequest, externalLink, externalLinkText }: Props) => {
    const { wid } = useParams();
    const [showEntConsentModal, setShowEntConsentModal] = useState<boolean>(false);
    const [isConsentTrue, setIsConsentTrue] = useState<boolean>(false);
    const { userDetails, redisStatus } = useSelector(({ user }: any) => user);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        singleAppDetails
    } = useSelector(({ apps }) => apps);

    const onPressGoBack = () => {
        if (backLink) {
            navigate(backLink);
        } else {
            navigate(-1);
        }
    }

    const onNavigateToNew = () => {
        // appDetails.signupUrl && (appDetails.signupUrl.startsWith("https") || appDetails.signupUrl.startsWith("http"))
        // setShowEntConsentModal(true);
        // console.log("$$$$", consentConfig && Object.keys(consentConfig).length == 0);

        if (consentConfig && Object.keys(consentConfig).length == 0) {
            setShowEntConsentModal(true);
        } else if (callBackend) {
            callBackend();
        } else {
            if (singleAppDetails && singleAppDetails.widgetDetails && singleAppDetails.widgetDetails.portalUrl) {
                if (singleAppDetails.widgetDetails.portalUrl.startsWith("https") || singleAppDetails.widgetDetails.portalUrl.startsWith("http")) {
                    window.open(singleAppDetails.widgetDetails.portalUrl, "_blank") || window.location.replace(singleAppDetails.widgetDetails.portalUrl);
                } else {
                    navigate(newButtonLink!);
                }
            } else {
                navigate(newButtonLink!);
            }
        }
    }

    const onNavigateToExternalLink = () => {
        if (externalLink) {
            window.open(externalLink, '_blank');
        }
    }

    const closeModalEntConsentModal = async () => {
        setShowEntConsentModal(false);
    }

    const addEntConsentDetails = async (formData: any) => {
        let req = {
            consentStatus: formData.consentStatus === true ? 'yes' : 'no',
            consentMedium: formData.consentMedium
        }
        // const tenantGUID = window.location.pathname.split('/')[Constants.BASEURLPATHLENGTH + 2];
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        dispatch(addConsentConfigs(tenantGUID, wid, req, navigate, appDetails, newButtonLink, callBackend));
        // (tenantId: string, widgetId: string, req: KycConfigModel, navigate: NavigateFunction, appDetails: SingleAppModel | null | any, callBackend: any | null)
        setShowEntConsentModal(false);
    }

    const onConsentStatusChange = async (val: any) => {
        console.log('onConsentStatusChange val = ', val);
        setIsConsentTrue(val)
    }

    return (
        <>
            <nav className="nav-inner-bleed">
                <div className="nav-content__wrapper">
                    <h4 className="main-title">{(singleAppDetails && singleAppDetails.widgetDetails) ? singleAppDetails.widgetDetails.title : ''}</h4>
                </div>
                <div className="nav-content__wrapper">
                    {page === 'list' && listShow === true && !disableSMrequest &&
                        <button className="btn btn-primary" onClick={() => onNavigateToNew()}>{newButtonText ? newButtonText : 'New request'}</button>
                    }
                    {page !== 'list' && <button className="btn back-btn" onClick={onPressGoBack}>
                        <i className="bi bi-arrow-left" />
                        &nbsp;Go back
                    </button>}
                    {page === 'list' && externalLink && externalLinkText &&
                        <button className="btn btn-primary" onClick={() => onNavigateToExternalLink()}>{externalLinkText}</button>
                    }
                    {/*<button className="btn back-btn" onClick={() => navigate(`/discover/widget/${wid}`)}>*/}
                    <button className="btn back-btn" onClick={() => navigate(`/subscriptions`)}>
                        <i className="bi bi-x-lg" />
                        &nbsp;Exit the app
                    </button>
                </div>
            </nav>
            <Modal
                title="User Consent"
                open={showEntConsentModal}
                onCancel={closeModalEntConsentModal}
                footer={null}>
                <div className="container-fluid">
                    <p>You have successfully subscribed to the widget. Please select below consent status and medium in order to obtain customer consent before verifying the customer data.</p>
                    <div className="mt-4 wow fadeIn">
                        <Form
                            className="form cf"
                            layout="horizontal"
                            onFinish={(values) => addEntConsentDetails(values)}
                            size="large">
                            <Form.Item
                                label="Consent Status"
                                name="consentStatus"
                                valuePropName="checked">
                                <Switch
                                    checkedChildren={"YES"}
                                    unCheckedChildren={"NO"}
                                    defaultChecked={false}
                                    onChange={(value) => onConsentStatusChange(value)}
                                />
                            </Form.Item>
                            {isConsentTrue &&
                                <Form.Item name="consentMedium" label="Consent Medium:"
                                    rules={[
                                        () => ({
                                            validator(rule, value) {
                                                if (!value) {
                                                    return Promise.reject('You can’t keep this empty');
                                                } else {
                                                    return Promise.resolve();
                                                }
                                            },
                                        }),
                                    ]}>
                                    <Radio.Group>
                                        <Radio value="sms">SMS</Radio>
                                        <Radio value="ussd">USSD</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            }
                            <Row gutter={[24, 0]} className="row-wrap" align="middle">
                                <Form.Item shouldUpdate={true}>
                                    {() => (
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className="btn btn-primary btn-sm">
                                            Save
                                        </Button>
                                    )}
                                </Form.Item>
                            </Row>
                        </Form>
                    </div>
                </div>
            </Modal>
        </>
    )

}
export { PageHeaderStatic };