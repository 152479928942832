import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthSliceModel {
    createSentimentResponse: any,
    sentimentIndividualDetails: any,
    sentimentUpdateResponse: any,
    sentimentLogs: any,
    sentimentWidgetData: any,
    sentimentIndividualData: any,
    customerResponseSuccess: boolean,
}

const sentimentStore = createSlice({
    name: "sentiment-store",
    initialState: {
        sentimentList: [],
        createSentimentResponse: [],
        numberCountResponse: [],
        numberBaseList: [],
        sentimentIndividualDetails: [],
        sentimentUpdateResponse: [],
        sentimentLogs: [],
        sentimentWidgetData: [],
        sentimentIndividualData: [],
        sentimentCustomerReponse: [],
        customerResponseSuccess: false,
        customerResponseError: false
    } as AuthSliceModel,
    reducers: {
        setimentFetch: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        },
        sentimentFetchSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                sentimentList: action.payload,
                success: true,
                error: false
            }
        },
        sentimentFetchFail: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                sentimentList: []
            }
        },
        sentimentDataPost: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        },
        sentimentDataPostSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                createSentimentResponse: action.payload,
                success: true,
                error: false
            }
        },
        sentimentDataPostFail: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                createSentimentResponse: null
            }
        },
        numberCountGet(state: AuthSliceModel) {
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        },
        numberCountGetSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                numberCountResponse: action.payload,
                success: true,
                error: false
            }
        },
        numberCountGetFail: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                numberCountResponse: null
            }
        },
        numberBaseListFetch: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        },
        numberBaseListSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                numberBaseList: action.payload,
                success: true,
                error: false
            }
        },
        numberBaseListFail: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                numberBaseList: []
            }
        },
        sentimentIndividualDetailsFetch: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        },
        sentimentIndividualDetailsFetchSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                sentimentIndividualDetails: action.payload,
                success: true,
                error: false
            }
        },
        sentimentIndividualDetailsFetchFail: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                sentimentIndividualDetails: []
            }
        },
        sentimentUpdateFetch: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        },
        sentimentUpdateSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                sentimentUpdateResponse: action.payload,
                success: true,
                error: false
            }
        },
        sentimentUpdateFail: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                sentimentUpdateResponse: []
            }
        },
        sentimentLogFetch: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        },
        sentimentLogFetchSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                sentimentLogs: action.payload,
                success: true,
                error: false
            }
        },
        sentimentLogFetchFail: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                sentimentLogs: []
            }
        },
        sentimentWidgetFetch: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        },
        sentimentWidgetFetchSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                sentimentWidgetData: action.payload,
                success: true,
                error: false
            }
        },
        sentimentWidgetFetchFail: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                sentimentWidgetData: []
            }
        },
        sentimentIndividualDataFetch: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        },
        sentimentIndividualDataFetchSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                sentimentIndividualData: action.payload,
                success: true,
                error: false
            }
        },
        sentimentIndividualDataFetchFail: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                sentimentIndividualData: []
            }
        },
        sentimentCustomerResponseFetch: (state: AuthSliceModel) => {
            return {
                ...state,
                cutomerResponseLoading: true,
                customerResponseSuccess: false,
                customerResponseError: false
            }
        },
        sentimentCustomerResponseSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                cutomerResponseLoading: false,
                sentimentCustomerReponse: action.payload,
                customerResponseSuccess: true,
                customerResponseError: false
            }
        },
        sentimentCustomerResponseFail: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                cutomerResponseLoading: false,
                customerResponseSuccess: false,
                customerResponseError: true,
                sentimentCustomerReponse: []
            }
        }
    }
});

export default sentimentStore;