import React from 'react';
import { Routes, BrowserRouter as Router, Route } from "react-router-dom";

type Props = {
    children: any,
    display: boolean,
    onClose: (e: any) => void,
    onConfirm: (e: any) => void
}


const Modal = ({ children, display, onClose, onConfirm }: Props) => {
    return (
        <div
            className={"modal scale-in " + (display ? "show" : "d-none")}
            // aria-modal="modal"
            role="dialog"
            tabIndex={-1}
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header ">
                        <div className="modal-header-content__wrapper">
                            <h4 className="modal-header-title">Are you sure?</h4>
                        </div>
                        <div className="modal-header-content__wrapper">
                            <button className="btn btn-small primary-action" aria-label="close" onClick={onClose}>
                                ✕
                            </button>
                        </div>
                    </div>
                    <div className="modal-body center">
                        {children}
                        <div className="body-btn-group__wrapper">
                            {/* <button className="btn btn-secondary">Cancel</button> */}
                            <button className="btn btn-primary" onClick={onConfirm}>Confirm</button>
                        </div>
                    </div>
                    {/* <div class="modal-footer">
              <button class="btn btn-secondary">Cancel</button>
              <button class="btn btn-primary">Confirm</button>
             </div> */}
                </div>
            </div>
        </div>
    );
}

export { Modal };