import React from 'react'

type Props = {}

const AccountCreationError = (props: Props) => {
    return (
        <main className="account-creation ac-status">
            <section className="ac-status__wrapper">
                <div className="ac-status-animation__wrapper">
                    {/* <div class="loader-5 center"><span></span></div> */}
                    <div className="circle-loader load-error">
                        <div className="checkmark error" />
                    </div>
                </div>
                <h2 className="title">
                    <span>Error!</span><br />
                    <span>Something happened!</span><br />
                    <span>Account creation unsuccessful</span>
                </h2>
                <p className="sub-title">Redirecting back to account creation form....</p>
            </section>
            {/* success message status update*/}
            {/* status js lib -- 
            https://codepen.io/alimuzzaman/pen/LyoyPj
        */}
        </main>
    )
}

export { AccountCreationError }