import axios from 'axios';
import qs from 'qs';
import * as CONSTANTS from '../constants'

const getTokenRefreshed = async () => {
    let refreshToken = localStorage.getItem('kc_refreshToken');
    return await axios({
        method: `post`,
        url: `${CONSTANTS.KEYCLOAK_URL}/auth/realms/${CONSTANTS.KEYCLOAK_REALM}/protocol/openid-connect/token`,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: qs.stringify({
            "client_id": `${CONSTANTS.KEYCLOAK_CLIENT}`,
            "grant_type": "refresh_token",
            "refresh_token": `${refreshToken}`
        })
    });
};

export { getTokenRefreshed };