import React, { CSSProperties, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import AuthHandler from '../../../helper/AuthHandler';

import { Form, Modal, Checkbox } from "antd";
import {
    fetchSingleApp,
} from '../../../store/actions';
import SimpleReactValidator from 'simple-react-validator';
import { PageHeaderStatic } from "../components/PageHeaderStatic";
import { InputTextArea, InputTextField } from "../../../components";
import { fetchSocialMediaRatePlan, fetchSocialMediaSingleConfig, saveSocialMediaRequest } from "../../../store/actions/social-media.action";
import { ConfirmModal } from "../../../components/ConfirmPopup";
import modal from "antd/es/modal";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { confirmAlert } from 'react-confirm-alert';
import * as CONSTANTS from '../../../constants';
// import { Checkbox } from "@mui/material";


const override: CSSProperties = {
    display: "block",
    margin: "0 auto"
};

const SocialMediaConfigCreate = ({ auth }: any) => {

    const { wid } = useParams();
    const [termsAndConditions, setTermsAndConditions] = useState<boolean>(false);
    const [widgetUuid, setWidgetUuid] = useState("");
    const [msgContentError, setMsgContentError] = useState('');
    const [isView, setIsView] = useState(false);

    const [showConfirm, setShowConfirm] = useState(false);
    const [selectedPlatforms, setSelectedPlatforms] = useState<string[]>([]);

    const [requestData, setRequestData] = useState({
        enterpriseName: "",
        email: "",
        mobile: "",
        package: "",
        note: "",
        contactPersonName: "",
        contactPersonNumber: "",
        contactPersonEmail: "",
        platforms: []
    })

    const [platformList, setPlatformList] = useState<{ id: string, title: string }[]>([]);
    const [maxPlatformCount, setMaxPlatformCount] = useState<number | undefined>();

    const routePath = window.location.pathname.split('/')[3];
    const widgetroutePath = window.location.pathname.split('/')[1];

    const [validator] = useState(new SimpleReactValidator({
        messages: {
            required: 'You can’t keep this as empty',
            min: 'Minimum length is :min',
            max: 'Maximum length is :max',
            email: 'This does not look like an email.',
        },
        validators: {
            all_mobiles: {
                message: 'The entered :attribute number is invalid.',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^[0]{1}[123456789]{2}\d{7}$/) && params.indexOf(val) === -1
                }
            },
            dialog: {
                message: 'The :attribute is invalid.',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^([0]{1}[7]{1}[764]{1}\d{7}|[7]{1}[764]{1}\d{7})$/) && params.indexOf(val) === -1
                }
            },
            numberValue: {
                message: 'Must include at least one special character',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^(?=.*[!@#$%^&*._-])/) && params.indexOf(val) === -1
                }
            },
        },
        autoForceUpdate: this
    }));

    const navigate = useNavigate();


    const { success,
        requestDetails,
        smRatePlan
    } = useSelector(({ sm }: any) => sm);

    const {
        singleAppDetails
    } = useSelector(({ apps }: any) => apps);

    const {
        userDetails
    } = useSelector<any, any>(({ user }) => user);

    const dispatch = useAppDispatch();

    const useForceUpdate = () => {
        const [value, setValue] = useState(0);
        return () => setValue(value => value + 1);
    }

    const forceUpdate = useForceUpdate()

    useEffect(() => {
        if (isView) {
            setRequestData({
                ...requestData,
                enterpriseName: requestDetails && requestDetails.enterpriseName ? requestDetails.enterpriseName : "",
                email: requestDetails && requestDetails.email ? requestDetails.email : "",
                mobile: requestDetails && requestDetails.contactNum ? requestDetails.contactNum : "",
                package: requestDetails && requestDetails.selectedPackage ? requestDetails.selectedPackage : "",
                note: requestDetails && requestDetails.specialNote ? requestDetails.specialNote : "",
                contactPersonName: requestDetails && requestDetails.contactPersonName ? requestDetails.contactPersonName : "",
                contactPersonNumber: requestDetails && requestDetails.contactPersonNumber ? requestDetails.contactPersonNumber : "",
                contactPersonEmail: requestDetails && requestDetails.contactPersonEmail ? requestDetails.contactPersonEmail : "",
                platforms: requestDetails && requestDetails.platforms ? requestDetails.platforms : [],

            })
        }
    }, [requestDetails, isView, success])

    useEffect(() => {
        if (widgetUuid === "" && singleAppDetails && singleAppDetails.widgetDetails && singleAppDetails.widgetDetails.uuid) {
            setWidgetUuid(singleAppDetails.widgetDetails.uuid)
        }
    }, [singleAppDetails]);

    useEffect(() => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        dispatch(fetchSingleApp(wid!, tenantGUID));
        if (wid != undefined) {
            dispatch(fetchSocialMediaRatePlan(tenantGUID, wid!));
        }
    }, [wid, userDetails]);


    useEffect(() => {
        // setSubmitDisabled(!termsAndConditions);
    }, [termsAndConditions]);

    useEffect(() => {
        console.log("rate plan", smRatePlan);
        if (smRatePlan != null && smRatePlan.code != "ERR_BAD_REQUEST") {
            setRequestData({ ...requestData, package: smRatePlan && smRatePlan.RatePlanDetails[0].RatePlan ? smRatePlan.RatePlanDetails[0].RatePlan.title : "" });
        }
    }, [smRatePlan])

    useEffect(() => {
        console.log("path", routePath);
        if (routePath == `view-${widgetroutePath}`) {
            setIsView(true);
            const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
            let configId = window.location.pathname.split('/')[4];
            dispatch(fetchSocialMediaSingleConfig(tenantGUID, configId));
        }
    }, [routePath, userDetails])

    const handleRequestContentChange = (name: string) => (event: any) => {
        setRequestData({ ...requestData, [name]: event.target.value });
    };

    const handlePlatformSelection = (e: any) => {
        if (e.target.checked) {
            setSelectedPlatforms([...selectedPlatforms, e.target.name]);
        } else {
            setSelectedPlatforms(selectedPlatforms.filter((item) => item !== e.target.name));
        }
    };

    const onSubmitSmRequest = () => {
        let req = {
            email: requestData.email,
            contactNum: requestData.mobile,
            selectedPackage: requestData.package,
            specialNote: requestData.note,
            platforms: selectedPlatforms.join(" , "),
            contactPersonName: requestData.contactPersonName,
            contactPersonNumber: requestData.contactPersonNumber,
            contactPersonEmail: requestData.contactPersonEmail
        }
        console.log("req data", req);
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        dispatch(saveSocialMediaRequest(req, tenantGUID, wid!, navigate));
    }

    const handleSubmit = () => {
        setShowConfirm(true);
        confirmAlert({
            message: CONSTANTS.SM_WIDGET_SAVE,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => onSubmitSmRequest()
                },
                {
                    label: 'No',
                    onClick: () => handleCancel()
                }
            ]
        });
    }

    const handleCancel = () => {
        setShowConfirm(false);
    }

    useEffect(() => {
        if (userDetails && userDetails != null) {
            setRequestData({ ...requestData, email: userDetails && userDetails.email ? userDetails.email : "", mobile: userDetails && userDetails.contact ? userDetails.contact : "" });
        }
    }, [userDetails])

    useEffect(() => {
        // Extract platformList from the JSON response
        const platforms = singleAppDetails?.widgetDetails.WidgetRate[0].RatecardAddtionalDetails.detailsJson.platformList || [];
        setPlatformList(platforms);
    }, []);

    useEffect(() => {
        // Extract platformList and maxPlatformCount from the JSON response
        const detailsJson = singleAppDetails?.widgetDetails.WidgetRate[0].RatecardAddtionalDetails.detailsJson;
        const platforms = detailsJson.platformList || [];
        const maxCount = detailsJson.details.find((detail: any) => detail.maxplatforms)?.maxplatforms;
        console.log("maxplatforms", maxCount);
        setPlatformList(platforms);
        setMaxPlatformCount(maxCount);
    }, [singleAppDetails]);

    const renderSubmitButton = () => {
        if (AuthHandler.isPerformable(auth, "add-social-media-config")) {
            return (
                <button
                    className="btn btn-primary"
                    onClick={handleSubmit}
                    disabled={requestData.email === '' || requestData.mobile === '' || !validator.allValid()}
                >
                    Submit
                </button>
            )
        } else {
            return (
                <button type="button" className="btn btn-primary" disabled={true}>You don't have access to
                    create a social media request</button>
            )
        }
    }

    const platformsArray = typeof requestDetails?.platforms === 'string' ? requestDetails?.platforms.split(', ') : [];
    console.log("platformsArray", platformsArray);

    if (AuthHandler.isAuthorized(auth, 'add-social-media-config') && userDetails !== null) {
        // return (
        if (userDetails) {
            return (
                <React.Fragment>
                    <div className="main-content main-spacer-x-y">
                        <PageHeaderStatic
                            page={'create'}
                        />
                        <div className="card added-app-thumbnnail__wrapper">
                            <div
                                className="added-app-thumbnail__inner"
                                style={{
                                    backgroundImage: `url(${(singleAppDetails && singleAppDetails.widgetDetails !== null) ? singleAppDetails.widgetDetails.leftImage : ""})`
                                }}
                            ></div>
                        </div>
                        <div className="card panel content-holder-panels added-app-form__wrapper">
                            <div className="panel-header">
                                <div className="panel-header-content__wrapper">
                                    <h4 className="panel-header-title">Create Your Request</h4>
                                </div>
                            </div>
                            <div className="panel-content">
                                <div className="wiz-content-form__wrapper">
                                    <p>
                                        {(singleAppDetails && singleAppDetails.widgetDetails !== null) ? singleAppDetails.widgetDetails.description : ""}
                                    </p>
                                    <br />
                                    <div className="form-group__wrapper">
                                        <div className="form-group">
                                            <div className="form-element__wrapper mb-3">
                                                <InputTextField
                                                    name='enterpriseName'
                                                    label='Enterprise Name'
                                                    placeholder='Enterprise Name'
                                                    value={userDetails && userDetails.OwnedEnterprises[0] && userDetails.OwnedEnterprises[0].Enterprise && userDetails.OwnedEnterprises[0].Enterprise.entName ? userDetails.OwnedEnterprises[0].Enterprise.entName : ""}
                                                    setValue={handleRequestContentChange}
                                                    validator={validator}
                                                    disabled={true}
                                                    status="default"
                                                    validationMessage="you can't keep this as empty" rules={[]}
                                                    forceUpdate={forceUpdate} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group__wrapper">
                                        <div className="form-group">
                                            <InputTextField
                                                name='email'
                                                label='Email'
                                                placeholder='Email'
                                                value={requestData.email}
                                                setValue={handleRequestContentChange}
                                                validator={validator}
                                                disabled={isView ? true : false}
                                                status="default"
                                                validationMessage="you can't keep this as empty"
                                                rules={['required', 'email']}
                                                forceUpdate={forceUpdate} />
                                        </div>
                                    </div>
                                    <div className="form-group__wrapper">
                                        <div className="form-group">
                                            <div className="form-element__wrapper mb-3">
                                                <InputTextField
                                                    name='mobile'
                                                    label='Contact Number'
                                                    placeholder='Contact Number'
                                                    value={requestData.mobile}
                                                    setValue={handleRequestContentChange}
                                                    validator={validator}
                                                    disabled={isView ? true : false}
                                                    status="default"
                                                    validationMessage="you can't keep this as empty"
                                                    rules={['required', 'all_mobiles', { max: 10 }]}
                                                    forceUpdate={forceUpdate}
                                                />
                                                <div className="validation invalid-feedback">{msgContentError}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group__wrapper">
                                        <div className="form-group">
                                            <div className="form-element__wrapper mb-3">
                                                <InputTextField
                                                    name='package'
                                                    label='Selected Package'
                                                    placeholder='Selected Package'
                                                    value={requestData.package}
                                                    setValue={handleRequestContentChange}
                                                    validator={validator}
                                                    disabled={true}
                                                    status="default"
                                                    validationMessage=""
                                                    rules={[]}
                                                    forceUpdate={forceUpdate}
                                                />
                                                <div className="validation invalid-feedback">{msgContentError}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group__wrapper">
                                        <div className="form-group">
                                            <div className="form-element__wrapper mb-3">
                                                {!isView ? (
                                                    <>
                                                        <label className="form-label">Choose the Social Media Platforms that you prefer</label>
                                                        {platformList.map((platform) => (
                                                            <div key={platform.id} className="platform-item">
                                                                <Checkbox
                                                                    name={platform.title}
                                                                    onChange={handlePlatformSelection}
                                                                    checked={selectedPlatforms.includes(platform.title)}
                                                                    disabled={maxPlatformCount !== undefined && maxPlatformCount !== null &&
                                                                        !selectedPlatforms.includes(platform.title) &&
                                                                        selectedPlatforms.length >= maxPlatformCount}
                                                                >
                                                                    {platform.title}
                                                                </Checkbox>
                                                            </div>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <>
                                                        <label className="form-label">Preferred Social Media Platforms</label>
                                                        {platformsArray.map((platform: any) => (
                                                            <div key={platform.id} className="platform-item">
                                                                <Checkbox style={{color: 'black'}}
                                                                    name={platform}
                                                                    checked={platformsArray.includes(platform)}
                                                                    disabled={true}
                                                                >
                                                                    {platform}
                                                                </Checkbox>
                                                            </div>
                                                        ))}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group__wrapper">
                                        <div className="form-group">
                                            <div className="form-element__wrapper mb-3">
                                                <InputTextField
                                                    name='contactPersonName'
                                                    label='Contact Person Name'
                                                    placeholder='Contact person Name'
                                                    value={requestData.contactPersonName}
                                                    setValue={handleRequestContentChange}
                                                    validator={validator}
                                                    disabled={isView ? true : false}
                                                    status="default"
                                                    validationMessage="you can't keep this as empty" 
                                                    rules={['required']}
                                                    forceUpdate={forceUpdate} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group__wrapper">
                                        <div className="form-group">
                                            <div className="form-element__wrapper mb-3">
                                                <InputTextField
                                                    name='contactPersonNumber'
                                                    label='Contact Person Number'
                                                    placeholder='Contact person Number'
                                                    value={requestData.contactPersonNumber}
                                                    setValue={handleRequestContentChange}
                                                    validator={validator}
                                                    disabled={isView ? true : false}
                                                    status="default"
                                                    validationMessage="you can't keep this as empty"
                                                    rules={['required', 'all_mobiles', { max: 10 }]}
                                                    forceUpdate={forceUpdate}
                                                />
                                                <div className="validation invalid-feedback">{msgContentError}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group__wrapper">
                                        <div className="form-group">
                                            <InputTextField
                                                name='contactPersonEmail'
                                                label='Contact Person Email'
                                                placeholder='Contact person Email'
                                                value={requestData.contactPersonEmail}
                                                setValue={handleRequestContentChange}
                                                validator={validator}
                                                disabled={isView ? true : false}
                                                status="default"
                                                validationMessage="you can't keep this as empty"
                                                rules={['required', 'email']}
                                                forceUpdate={forceUpdate} />
                                        </div>
                                    </div>
                                    <div className="form-group__wrapper">
                                        <div className="form-group">
                                            <div className="form-element__wrapper mb-3">
                                                <InputTextArea
                                                    name='note'
                                                    label='Special Note'
                                                    placeholder='Special Note'
                                                    value={requestData.note}
                                                    setValue={handleRequestContentChange}
                                                    validator={validator}
                                                    disabled={isView ? true : false}
                                                    status="default"
                                                    validationMessage="you can't keep this as empty"
                                                    rules={[{ max: 400 }]}
                                                    forceUpdate={forceUpdate} rows={3} />
                                                <div className="validation invalid-feedback">{msgContentError}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    {routePath !== `view-${widgetroutePath}` ?
                                        <div className="panel-bottom-btn__wrapper">
                                            <button className="btn btn-secondary" onClick={() => { navigate(-1) }}>Cancel</button>
                                            {renderSubmitButton()}
                                        </div>
                                        : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <ConfirmModal message="Are you sure you want to send the request?" a handleConfirm={onSubmitSmRequest} handleCancel={handleCancel} remark="" /> */}
                </React.Fragment>
            );
        } else {
            return (
                <div className="section__wrapper">
                    <div className="empty-state-new-action__wrapper">
                        <div className="new-action-content__wrapper">
                            <h4 className="sub">Oops, something went wrong!</h4>
                        </div>
                    </div>
                </div>
            );
        }
        // );
    } else return (<React.Fragment></React.Fragment>);
}

export default SocialMediaConfigCreate;