import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../store';
import { fetchTopApps, fetchCategories, fetchFeatureApps, fetchUserDetails, fetchAllWidgets, fetchTrendingApps, fetchBusinessTypes, fetchAllOffers, fetchAppList, fetchSearchResults } from '../../store/actions';
import {
    InnerTopFeatureItem,
    PricingComponent,
    SliderListingItem,
    SupportItem
} from './../single-app/components'
import Auth from '../../models/response/auth.model';
import { RatePlanModel } from './../single-app/models/response/rate-plan.model';
import { SingleAppModel } from './../single-app/models/response/single-app.model';
import { AppWizardModel, FeatureAppModel } from './../discover/models/response/app-wizard.model';
import { CategoryListModel, CategoryModel } from './models/response/app-category.model';
import SingleApp from '../single-app/SingleApp';
import BusinessType1 from '../../assets/images/business-type-wiz-feature-1.svg';
import { BusinessType } from './components/BusinessType';
import { BusinessTypes } from './models/response/business-types.model';
import { WarningAlertBox } from "../home/components";
import * as CONSTANTS from "../../constants";

type Props = {
    auth: Auth
}

const AppWizard = ({ auth }: Props) => {
    const { wuuid } = useParams();

    const dropdownRef = useRef<HTMLDivElement>(null);

    const [appDetails, setAppDetails] = useState<SingleAppModel | null>(null);
    const [featureAppDetails, setFeatureAppDetails] = useState<FeatureAppModel | null>(null);
    const [categoryItems, setCategoryItems] = useState<CategoryListModel | null>(null);
    const [allAppDetails, setAllAppDetails] = useState<FeatureAppModel | null>(null);
    const [topAppsOffset, setTopAppsOffset] = useState<number>(0);
    const [topAppsLimit, setTopAppsLimit] = useState<number>(4);
    const [newAppsOffset, setNewAppsOffset] = useState<number>(0);
    const [newAppsLimit, setNewAppsLimit] = useState<number>(9);
    const [showSections, setShowSections] = useState(false);
    const [beforeRegDate, setBeforeRegDate] = useState<Date>(new Date("2023-11-28"));
    const [featuredAppsOffset, setFeaturedAppsOffset] = useState<number>(0);
    const [featuredAppsLimit, setFeaturedAppsLimit] = useState<number>(8);
    const [categoriesOffset, setCategoriesOffset] = useState<number>(0);
    const [categoriesLimit, setCategoriesLimit] = useState<number>(8);
    const [allAppsOffset, setAllAppsOffset] = useState<number>(0);
    const [allAppsLimit, setAllAppsLimit] = useState<number>(9);
    const [searchText, setSearchText] = useState("");
    const [limit, setLimit] = useState(20);
    const [offset, setOffset] = useState(0);
    const [hasSearchResults, setHasSearchResults] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);

    const dispatch = useAppDispatch();

    const {
        featureAppListDetails,
        categoryList,
        loading,
        topAppsList,
        newApps,
        trendingApps,
        businessTypes,
        allApps,
        searchResults
    } = useSelector<any, any>(({ appWizard }) => appWizard);

    const { userDetails } = useSelector<any, any>(({ user }) => user);

    const {
        authData
    } = useSelector<any, any>(({ authentication }) => authentication);

    const {
        allOffersList
    } = useSelector<any, any>(({ offers }) => offers);

    const {
        appList
    } = useSelector<any, any>(({ settings }) => settings);

    const navigate = useNavigate();

    const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";

    const onRedirect = (uuid: string | null) => {
        if (auth && auth.accessToken !== '') {
            navigate(`/discover/widget/${uuid}`);
        }
        else {
            navigate(`/widget/${uuid}`);
        }
    }

    const onRedirectTopApps = () => {
        if (auth && auth.accessToken !== '') {
            navigate(`/discover/apps/top`);
        }
        else {
            navigate(`/apps/top`);
        }
    }

    const onRedirectAllApps = () => {
        if (auth && auth.accessToken !== '') {
            navigate(`/discover/apps/all`);
        }
        else {
            navigate(`/apps/all`);
        }
    }

    const onRedirectTopCategories = () => {
        if (auth && auth.accessToken !== '') {
            navigate(`/discover/apps/categories`);
        }
        else {
            navigate(`/apps/categories`);
        }
    }

    const redirectToApp = (uuid: string | null) => {
        // console.log('Auth ***** ', auth)
        if (auth && auth.accessToken !== '') {
            return `/discover/widget/${uuid}`;
        } else {
            return `/widget/${uuid}`;
        }
    }

    const redirectToCategories = (uuid: string | null) => {
        // console.log('Auth ***** ', auth)
        if (auth && auth.accessToken !== '') {
            return `/discover/apps/${uuid}`;
        } else {
            return `/apps/${uuid}`;
        }
    }

    const redirectToAll = (uuid: string | null) => {
        // console.log('Auth ***** ', auth)
        if (auth && auth.accessToken !== '') {
            return `/discover/widget/${uuid}`;
        } else {
            return `/widget/${uuid}`;
        }
    }

    useEffect(() => {
        dispatch(fetchFeatureApps(featuredAppsLimit, featuredAppsOffset));
        dispatch(fetchTopApps(topAppsLimit, topAppsOffset));
        // dispatch(fetchNewlyArrivedApps(newAppsLimit, newAppsOffset));
        dispatch(fetchCategories());
        dispatch(fetchTrendingApps());
        dispatch(fetchBusinessTypes());
        dispatch(fetchAllWidgets(allAppsLimit, allAppsOffset))
    }, []);

    useEffect(() => {
        setAppDetails(appDetails)
    });

    useEffect(() => {
        if (featureAppListDetails) {
            setFeatureAppDetails(featureAppListDetails)
        }
    }, [featureAppListDetails])

    useEffect(() => {
        if (categoryList) {
            setCategoryItems(categoryList)
        }
    }, [categoryList])

    useEffect(() => {
        if (allApps) {
            setAllAppDetails(allApps)
        }
    }, [allApps])

    useEffect(() => {
        let route = window.location.pathname.split('/')[1];
        if (route == 'apps' && authData) {
            navigate('/discover/apps/wizard');
        }
    }, []);

    useEffect(() => {
        setShowSections(true)
    });

    const onSearchWidget = () => {
        setOffset(0);
        dispatch<any>(fetchSearchResults(searchText !== "" ? searchText.split(" ").toString() : '0', limit, offset, []));
    }

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setShowDropdown(false);
        }
    };

    const handleDropDownItem = (item: any) => {
        setShowDropdown(false);
        if (auth && auth.accessToken !== '') {
            navigate(`/discover/widget/${item.uuid}`);
        }
        else {
            navigate(`/widget/${item.uuid}`);
        }
    }

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            handleClickOutside(event);
        };

        if (showDropdown) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [showDropdown]);

    useEffect(() => {
        if (searchResults && Array.isArray(searchResults) && searchResults.length > 0) {
            setShowDropdown(true);
        }
    }, [searchResults])

    useEffect(() => {
        const tenantGroupId = (userDetails && userDetails?.OwnedEnterprises[0]) ? userDetails?.OwnedEnterprises[0].groupId : "";
        if (userDetails) {
            dispatch(fetchAllOffers(userDetails?.uuid, tenantGroupId));
        } else {
            dispatch(fetchAllOffers("0", "0"));
        }
    }, [userDetails]);

    useEffect(() => {
        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        if (tenantGroupId !== "" && searchText == "") {
            dispatch<any>(fetchAppList(tenantGroupId, searchText !== "" ? searchText : '0', limit, offset, appList));
        }
    }, [userDetails, offset, searchText]);

    return (
        <div className="main-content main-spacer-x-y">
            <div className="top-feature-slider__wrapper">
                <div className="top-feature-slider__inner">
                    <div className="main-slider" aria-label="gallery" >
                        <div className="slider-item sliderItem fading" style={{ backgroundImage: `url(${require('../../assets/images/main-slider-4.webp')})` }}>
                            <div className="slider-item-caption__wrapper">
                                <div className="feature-search-bar card" style={{ background: "transparent", border: "none", boxShadow: "none", paddingLeft: "15vw", alignItems: "flex-start", overflow: "visible" }}>
                                    <div className="primary-searchbar__wrapper">
                                        <input
                                            type="text"
                                            className="form-control primary-searchbar lg"
                                            id=""
                                            placeholder="Search by app name/keyword"
                                            spellCheck="false"
                                            data-ms-editor="true"
                                            onChange={(e) => {
                                                let val = e.target.value;
                                                if (val === "") {
                                                    setOffset(0);
                                                    setHasSearchResults(false);
                                                    setShowDropdown(false);
                                                }
                                                setSearchText(val);
                                                onSearchWidget();
                                            }}
                                            value={searchText}
                                            onKeyDown={event => {
                                                if (event.key === 'Enter') {
                                                    if (searchText.trim() !== "") {
                                                        onSearchWidget();
                                                        setHasSearchResults(true);
                                                        setShowDropdown(false);
                                                    }
                                                }
                                            }}
                                        />
                                        {!hasSearchResults && showDropdown && searchResults && Array.isArray(searchResults) && searchResults.length > 0 && (
                                            <div className="nav-brand" style={{ maxHeight: '100px', overflowY: 'auto', position: "absolute", minWidth: "18.75rem", display: "block", width: "18.75rem" }} ref={dropdownRef}>
                                                <ul className="pop-dropdown-menu dropdown-menu-end mob-bottom-slider show widgets-show " style={{ listStyleType: "none" }}>
                                                    {searchResults.map((item: any) => (
                                                        <li style={{ background: "aliceblue", display: "block", borderRadius: "5px" }} onClick={() => handleDropDownItem(item)}>
                                                            <a className="dropdown-item" href="#">
                                                                <div className="profile-placeholder__wrapper left-align business-placeholder__wrapper">
                                                                    <div className="business-img-holder sm" style={{ backgroundImage: `url(${item.icon})` }}></div>
                                                                    <div className="profile-details">
                                                                        <span className="main-title mt-bg">{item.title}</span>
                                                                        <span className="sub-title">{item.Category.title}</span>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </li>))}
                                                </ul>
                                            </div>)
                                        }
                                    </div>
                                </div>
                                <div className="slider-top-caption" style={{ backgroundImage: 'url("/src/assets/images/vdomeet-primary-negative-darker-bg.png")' }}></div>
                                <div className="slider-bottom-caption__wrapper">
                                    <span className="caption-content">
                                        My call center is a Hosted PABX service that extends rich fixed-line PBX functionality to any mobile device from basic to a high-end smart phone.                                    </span>
                                    <span className="">
                                        {/* <button className="btn btn-primary">
                                            Learn more&nbsp;
                                            <i className="bi bi-arrow-right" />
                                        </button> */}
                                    </span>
                                </div>
                            </div>
                        </div>


                        <div className="slider-item sliderItem fading" style={{ backgroundImage: "url(/src/assets/images/slider-2.webp)" }}>
                            <div className="slider-item-caption__wrapper">
                                <div className="slider-top-caption" style={{ backgroundImage: 'url("")' }}>
                                    Main caption ttle goes like this
                                </div>
                                <div className="slider-bottom-caption__wrapper">
                                    <span className="caption-content">
                                        The most affordable &amp; convenient Digital bill solution to
                                        transform your business!
                                    </span>
                                    <span className="">
                                        <button className="btn btn-primary">
                                            Learn more&nbsp;
                                            <i className="bi bi-arrow-right" />
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>


                        <div
                            className="slider-item sliderItem fading"
                            style={{ backgroundImage: "url(/src/assets/images/slider-3.webp)" }}
                        // alt=""
                        >
                            <div className="slider-item-caption__wrapper">
                                <div className="slider-top-caption" style={{ backgroundImage: 'url("")' }}>
                                    Main caption title goes like this
                                </div>
                                <div className="slider-bottom-caption__wrapper">
                                    <span className="caption-content">
                                        The most affordable &amp; convenient Digital bill solution to
                                        transform your business!
                                    </span>
                                    <span className="">
                                        <button className="btn btn-primary">
                                            Learn more&nbsp;
                                            <i className="bi bi-arrow-right" />
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {userDetails && userDetails.createdAt && (new Date(userDetails.createdAt) < new Date(beforeRegDate)) &&
                <>
                    <div className="alert warning">
                        <span className="alert-text__wrapper">
                            <i className="alert-icon bi bi-exclamation-circle-fill" />
                            <span className="alert-text">{"To view other applications, please visit the old Marketplace."}</span>
                            <button className="btn btn-small secondary-action" onClick={() => { window.location.replace(`${CONSTANTS.REACT_APP_CLASSIC_UI_URL}`); }}>Switch to classic view</button>
                        </span>
                    </div><br></br></>
            }

            {hasSearchResults &&
                <>
                    <div className="app-listing-section__wrapper type-1">
                        <div className="app-listing-section-title__wrapper">
                            <span>Search Results</span>
                        </div>
                        <div className="app-listing-section not-at-right type-1-inner" id="listingScrollDiv">
                            {searchResults && Array.isArray(searchResults) && searchResults.length > 0 ? searchResults.map((item: any) => {
                                return (
                                    <div className="app-listing-item__wrapper" onClick={() => onRedirect(item.uuid)}>
                                        <a>
                                            <div className="app-listinng-item">
                                                <div className="app-item-content__wrapper">
                                                    <div className="card app-thumbnail-holder" style={{ backgroundImage: `url(${item.thumbnail})` }}>
                                                    </div>
                                                </div>
                                                <div className="app-item-content__wrapper app-details">
                                                    <div className="card app-media-holder" style={{ backgroundImage: `url(${item.icon})` }}>
                                                    </div>
                                                    <div className="app-media-info-holder">
                                                        {/* <Link to={`/widget/${value.uuid}`} className="app-media-title">{value.title}</Link> */}
                                                        <Link to={redirectToApp(item.uuid)} className="app-media-title">{item.title}</Link>
                                                        <Link to={redirectToCategories(item.Category.uuid)} className="app-media-category">{item.Category.title} </Link>
                                                        <div className="app-stats__wrapper">
                                                            <ul className="app-stats-list">
                                                                {/* <li className="app-stats-item"><span className="stats-icon"><i className="bi bi-download" /></span><span className="stats-value">200+</span></li> */}
                                                                {/* <li className="app-stats-item"><span className="stats-divider"><i className="bi bi-dot" /></span></li> */}
                                                                {/* <li className="app-stats-item"><span className="stats-icon ico-highlighter"><i className="bi bi-star-fill" /></span><span className="stats-value">5.0</span></li> */}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                )
                            })
                                :
                                <>
                                    <div className="panel-table-content-list">
                                        <div className="empty-stack__wrapper">
                                            {/* <img src={""} alt="" /> */}
                                            <h5>No results found</h5>
                                        </div>
                                    </div>
                                </>}
                        </div>
                    </div>
                </>
            }
            {!hasSearchResults &&
                <>

                    <div className="app-listing-section__wrapper type-1">
                        <div className="app-listing-section-title__wrapper">
                            <span>Featured Apps</span>
                        </div>
                        <div className="app-listing-section not-at-right type-1-inner" id="listingScrollDiv">
                            {featureAppDetails && Array.isArray(featureAppDetails) && featureAppDetails.map((value: AppWizardModel, index: number) => {
                                const hasOffer = Array.isArray(allOffersList?.allOffersWidgetDetailsList) && allOffersList?.allOffersWidgetDetailsList.some((offer: { uuid: string | null; }) => offer.uuid === value.uuid);
                                const hasSubscribed = Array.isArray(appList) && appList.some((appId: { uuid: string | null; }) => appId.uuid === value.uuid);
                                return (
                                    <div className="app-listing-item__wrapper" key={index} onClick={() => onRedirect(value.uuid)}>
                                        <a>
                                            <div className="app-listinng-item">
                                                <div className="app-item-content__wrapper">
                                                    <div className="card app-thumbnail-holder" style={{ backgroundImage: `url(${value.thumbnail})` }}>
                                                        {userDetails ? (
                                                            hasOffer && !hasSubscribed &&
                                                            <div className="discount-label">Offer</div>
                                                        ) : <></>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="app-item-content__wrapper app-details">
                                                    <div className="card app-media-holder" style={{ backgroundImage: `url(${value.icon})` }}>
                                                    </div>
                                                    <div className="app-media-info-holder">
                                                        <Link to={redirectToApp(value.uuid)} className="app-media-title">{value.title}</Link>
                                                        <Link to={redirectToCategories(value.Category.uuid)} className="app-media-category">{value.Category.title}</Link>
                                                        <div className="app-stats__wrapper">
                                                            <ul className="app-stats-list">
                                                                {/* Additional stats can be added here */}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                );
                            })}

                        </div>
                        {/* Disable view more apps button in Discover page - MW- 8414 */}
                        {/* <div className="app-listing-section-footer">
                    <button className="btn btn-primary" onClick={() => navigate("/apps/new")}>View more apps</button>
                </div> */}
                    </div>
                </>
            }

            {showSections &&
                <div className="app-listing-section__wrapper type-2">
                    <div className="app-listing-section-title__wrapper">
                        <span>Top Apps</span>
                    </div>
                    <div className="app-listing-section not-at-right type-1-inner">
                        {Array.isArray(topAppsList) && topAppsList.map((value: AppWizardModel, index: number) => {
                            const hasOffer = Array.isArray(allOffersList?.allOffersWidgetDetailsList) && allOffersList?.allOffersWidgetDetailsList.some((offer: { uuid: string | null; }) => offer.uuid === value.uuid);
                            const hasSubscribed = Array.isArray(appList) && appList.some((appId: { uuid: string | null; }) => appId.uuid === value.uuid);
                            return (
                                <div className="app-listing-item__wrapper" key={index} onClick={() => onRedirect(value.uuid)}>
                                    <a>
                                        <div className="app-listinng-item">
                                            <div className="app-item-content__wrapper app-details">
                                                <div className="card app-media-holder"
                                                    style={{ backgroundImage: `url(${value.thumbnail})` }}>
                                                    {userDetails ? (
                                                        hasOffer && !hasSubscribed &&
                                                        <div className="discount-label">Offer</div>
                                                    ) : <></>
                                                    }
                                                </div>
                                                <div className="app-media-info-holder">
                                                    <Link to={redirectToApp(value.uuid)} className="app-media-title">{value.title}</Link>
                                                    <p className="app-media-desc">{value.description}</p>
                                                    {/* <Link to={`/app-category/${value.Category.uuid}`} className="app-media-category">{value.Category.title} </Link> */}
                                                    <Link to={redirectToCategories(value.Category.uuid)} className="app-media-category">{value.Category.title} </Link>
                                                    <div className="app-stats__wrapper">
                                                        <ul className="app-stats-list">
                                                            {/* <li className="app-stats-item"><span className="stats-icon"><i className="bi bi-download" /></span><span className="stats-value">400+</span></li>
                                                        <li className="app-stats-item"><span className="stats-divider"><i className="bi bi-dot" /></span></li>
                                                        <li className="app-stats-item"><span className="stats-icon ico-highlighter"><i className="bi bi-star-fill" /></span><span className="stats-value">4.8</span></li> */}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )
                        })}
                    </div>
                    {/* <div className="app-listing-section-footer">
                        <button className="btn btn-primary" onClick={() => onRedirectTopApps()}>View more apps</button>
                    </div> */}
                </div>}

            {
                showSections &&
                <div className="app-listing-section__wrapper type-2">
                    <div className="app-listing-section-title__wrapper">
                        <span>All Apps</span>
                    </div>
                    <div className="app-listing-section not-at-right type-2-inner">
                        {allAppDetails && allAppDetails && allAppDetails.map((value: AppWizardModel, index: number) => {
                            const hasOffer = Array.isArray(allOffersList?.allOffersWidgetDetailsList) && allOffersList?.allOffersWidgetDetailsList.some((offer: { uuid: string | null; }) => offer.uuid === value.uuid);
                            const hasSubscribed = Array.isArray(appList) && appList.some((appId: { uuid: string | null; }) => appId.uuid === value.uuid);
                            return (
                                <div className="app-listing-item__wrapper" key={index} onClick={() => onRedirect(value.uuid)}>
                                    <a>
                                        <div className="app-listinng-item">
                                            <div className="app-item-content__wrapper app-details">
                                                <div className="card app-media-holder"
                                                    style={{ backgroundImage: `url(${value.thumbnail})` }}>
                                                    {userDetails ? (
                                                        hasOffer && !hasSubscribed &&
                                                        <div className="discount-label">Offer</div>
                                                    ) : <></>
                                                    }
                                                </div>
                                                <div className="app-media-info-holder">
                                                    <Link to={redirectToAll(value.uuid)} className="app-media-title">{value.title}</Link>
                                                    <p className="app-media-desc">{value.description}</p>
                                                    <Link to={redirectToCategories(value.Category.uuid)} className="app-media-category">{value.Category.title} </Link>
                                                    <div className="app-stats__wrapper">
                                                        <ul className="app-stats-list">

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )
                        })}
                    </div>
                    <div className="app-listing-section-footer">
                        <button className="btn btn-primary" onClick={() => navigate("/apps/all")}>View more apps</button>
                    </div>
                </div>
            }

            {/* { showSections &&
            <div className="app-listing-section__wrapper type-2">
                <div className="app-listing-section-title__wrapper">
                    <span>Trending Apps</span>
                </div>
                <div className="app-listing-section not-at-right type-2-inner">
                    {Array.isArray(trendingApps) && trendingApps.map((value: AppWizardModel, index: number) => {
                        return (
                            <div className="app-listing-item__wrapper" key={index} onClick={() => onRedirect(value.uuid)}>
                                <a>
                                    <div className="app-listinng-item">
                                        <div className="app-item-content__wrapper app-details">
                                            <div className="card app-media-holder"
                                                style={{ backgroundImage: `url(${value.thumbnail})` }}>
                                            </div>
                                            <div className="app-media-info-holder">
                                                <Link to={redirectToApp(value.uuid)} className="app-media-title">{value.title}</Link>
                                                <p className="app-media-desc">{value.description}</p>
                                                <Link to={redirectToCategories(value.Category.uuid)} className="app-media-category">{value.Category.title} </Link>
                                                <div className="app-stats__wrapper">
                                                    <ul className="app-stats-list">
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        )
                    })}
                </div>
            </div>
        } */}
            <div className="listing-slider-carousel with-titles">
                <div className="listing-slider-section-title__wrapper">
                    <span>Apps by categories</span>
                </div>
                <div className="listing-slider-control-icons ">
                    <button className="btn"><i className="bi bi-chevron-left" /></button>
                    <button className="btn"><i className="bi bi-chevron-right" /></button>
                </div>
                <div className="listing-slider__wrapper dragscroll">
                    {categoryItems && categoryItems.data && categoryItems.data.map((value: CategoryModel, index: number) => {
                        return (
                            <div className="slider-listing-item__wrapper" onClick={() => navigate(`/app-category/${value.uuid}`)} key={index}>
                                <a>
                                    <div className="slider-listing-item">
                                        <div className="slider-item-content__wrapper">
                                            <div className="slider-item-content__wrapper">
                                                <div className="card slider-thumbnail-holder" style={{ backgroundSize: "40%", backgroundRepeat: "no-repeat", borderRadius: "8px 0px", opacity: "60%", backgroundImage: value.thumbnail ? `url(https://d2xt8w8fh2bnjt.cloudfront.net/qa/categories/${value.thumbnail})` : '' }}>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="slider-item-content__wrapper">
                                            <div className="slider-media-info-holder" style={{ color: "black", backgroundColor: "#457ede4d", width: "100%", padding: "6px", backgroundClip: "initial", borderRadius: "0px 0px 8px 8px", boxShadow: "0 4px 12px #0000001a", display: "flex", flexDirection: "column", minWidth: "100%", marginTop: "0px", paddingLeft: "12px" }}>
                                                <a className="slider-media-title">{value.title}</a>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        )
                    })}
                </div>
                <div className="listing-slider-section-footer">
                    <button className="btn btn-primary" onClick={() => onRedirectTopCategories()}>View all categories</button>
                </div>
            </div>
            <div className="feature-inner-section-wrapper center bg-primary-gradient-overlay__wrapper">
                <div className="feature-inner-section-inner bg-primary-gradient-overlay__inner">
                    <div className="top-content__wrapper">
                        <h2 className="title">What Type of Business Do You Run?</h2>
                        <p>
                            Explore Available Solutions for Your Business Type with Our Assistance.
                        </p>
                    </div>
                    <div className="feature-inner-section-lister__wrapper">
                        {Array.isArray(businessTypes) && businessTypes.length > 0 && businessTypes.map((value: BusinessTypes, index: number) => {
                            return (
                                <BusinessType
                                    backgroundImage={BusinessType1}
                                    title={value.businessType}
                                    description={value.description}
                                    uuid={value.uuid}
                                />
                            )
                        })}
                    </div>
                </div>
            </div>

        </div >
    )
}

export default AppWizard