import { FC, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { SideBar, Header, Footer } from '../components';
import Keycloak from 'keycloak-js';
import Auth from '../models/response/auth.model';
import SettingsSideBar from '../pages/settings/components/SettingsSideBar';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { setMobileNavbarOpen } from '../store/actions';
import { useAppDispatch } from '../store';
interface RestrictedRouteProps {

}

type AppRoutesProps = {
    auth: Auth;
    keycloak: Keycloak | undefined;
}

const RestrictedRoutes: FC<AppRoutesProps> = ({ auth, keycloak }) => {
    const routeName = window.location.pathname.split('/')[1];
    let isSettingsPage = routeName === "settings";
    let navigate = useNavigate();
    const [screenWidth, setWidth] = useState(window.innerWidth);
    const [isNavbarOpen, setIsNavbarOpen] = useState(false);
    const { navbarOpen } = useSelector<any, any>((state) => state.settings);
    const [entStatus, setEntStatus] = useState<string>("");
    const [sideBarOpen, setSideBarOpen] = useState(false);

    const dispatch = useAppDispatch();

    const {
        isLoggedIn , authData, authenticatedData, keycloakData
    } = useSelector<any, any>(({ authentication }) => authentication);

    useEffect(() => {
        window.addEventListener("resize", () => setWidth(window.innerWidth));
        if (screenWidth >= 768) {
            setIsNavbarOpen(true);
        }

    }, [])

    const handleNavbarOpen = () => {
        if (isNavbarOpen) {
            setIsNavbarOpen(false);
            dispatch(setMobileNavbarOpen(false));

        } else {
            setIsNavbarOpen(true);
            dispatch(setMobileNavbarOpen(true));

        }

    }

    const {
        userDetails
    } = useSelector<any, any>(({ user }) => user);

    useEffect(() => {
        if (userDetails) {
            if (userDetails.OwnedEnterprises && Array.isArray(userDetails.OwnedEnterprises) && userDetails.OwnedEnterprises.length > 0 && userDetails.OwnedEnterprises[0].Enterprise) {
                setEntStatus(userDetails.OwnedEnterprises[0].Enterprise.status);
            };
        }
    }, [userDetails])

    return (
        <main className='max-width-set'>
            <Header
                auth={auth}
                keycloak={keycloak}
                sideBarOpen={sideBarOpen}
                setSideBarOpen={setSideBarOpen}
                entStatus={entStatus}
            />
            {!isSettingsPage && <SideBar
                auth={auth}
                keycloak={keycloak}
                entStatus={entStatus}
                sideBarOpen={sideBarOpen}
                setSideBarOpen={setSideBarOpen}
            />}
            {!isSettingsPage ? <section className={"content__wrapper with-nav-side-bar"}>
                <Outlet />
                <Footer />
            </section> : <section className="content__wrapper">
                <div className="container sub-nav-inner-page">
                    <header className="header simple">
                        <button className="btn back-btn" onClick={() => navigate("/")}> <span></span> Go back </button>

                    </header>
                    <div className="sub-nav-inner-page-title__wrapper">
                        <h4 className="inner-title bottom-hr">Settings</h4>
                    </div>
                    <button className="btn btn-menu-hamburg" onClick={() => handleNavbarOpen()}><span className="icon__wrapper"><i
                        className="bi bi-three-dots"></i></span><span>Menu</span></button>
                    {isNavbarOpen ?
                        (
                            <div className="sub-nav-layout__wrapper">
                                <SettingsSideBar auth={auth} />
                                <Outlet />
                            </div>) :
                        (<div className="sub-nav-layout__wrapper"> <Outlet />
                        </div>)}
                </div>
                <Footer />
            </section>}
        </main>
    )
}

export default RestrictedRoutes;