import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { useDispatch, useSelector } from "react-redux";
import reducers from "./reducers";
import rootReducer from './reducers'

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['authentication', 'memory']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
//thunk middleware is used to intercept actions so as to make API call before dispatching result to reducer
const store = createStore(persistedReducer, {}, applyMiddleware(thunk));
type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export default store;
export const persistor = persistStore(store);