/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import visaIcon from '../../assets/images/icons/payment-icon-visa.svg';
import masterIcon from '../../assets/images/icons/payment-icon-mastercard.svg';
import mobileIcon from '../../assets/images/icons/pay-icon-addtobill.svg';
import PaymentMethodItem from './components/PaymentMethodItem';
import { fetchAppList, fetchNoPayMsisdnList, fetchPaymentDetails, fetchPaymentOptionsList } from '../../store/actions';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../store';
import NoData from './components/NoData';
import Auth from '../../models/response/auth.model';
import { addPaymentMsisdn, deleteCardDetails, deletePaymentMsisdn, editPaymentMsisdn, getAddGenieCardView, updateGenieCard } from '../../services/settings.service';
import AddToBillIcon from '../../assets/images/icons/pay-icon-addtobill.svg';
import { message } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, RadioButton } from '../../components';
import NoPaymentMethods from '../../components/NoPaymentMethods';

interface Props {
    auth: Auth
}

const Payment = ({ auth }: Props) => {
    const [mode, setMode] = useState('view');
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('1');
    const [selectedMsisdn, setSelectedMsisdn] = useState('');
    const [genieCard, setGenieCard] = useState('');
    const [payList, setPayList] = useState<any>(null);
    const [editPaymentIndex, setEditPaymentIndex] = useState<number>(0);
    const [payForm, setPayForm] = useState(null);
    const [searchText, setSearchText] = useState('null');
    const [displayMsisdnModal, setDisplayMsisdnModal] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const {
        userDetails,
        msisdnData,
        msisdnOtpData
    } = useSelector<any, any>(({ user }) => user);

    const {
        paymentInfo,
        subscriptionBasedWidgetList,
        perUsagedWidgetList,
        freeWidgetList,
        oneTimeWidgetList,
        paymentOptionsList,
        noPayMsisdnList
    } = useSelector<any, any>(({ settings }) => settings);

    useEffect(() => {
        setSelectedMsisdn((Array.isArray(noPayMsisdnList) && noPayMsisdnList.length > 0) ? noPayMsisdnList[0].msisdn : '');
    }, [noPayMsisdnList]);

    useEffect(() => {
        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        if (tenantGroupId !== "") {
            dispatch<any>(fetchPaymentOptionsList(tenantGroupId, searchText));
        }
    }, [userDetails, searchText]);

    useEffect(() => {
        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        if (tenantGroupId !== "") {
            dispatch<any>(fetchNoPayMsisdnList(tenantGroupId));
        }
    }, [userDetails]);

    useEffect(() => {
        setPayList(paymentOptionsList);
    }, [paymentOptionsList]);

    const onChangeMethod = (name: string) => (e: any) => {
        setSelectedPaymentMethod(e.target.value);
    }

    const onChangeMsisdn = (e: any) => {
        setSelectedMsisdn(e.target.value);
    }

    useEffect(() => {
        if (payForm) {
            const formElement = document.getElementById('ext-merchant-frm') as HTMLFormElement;

            if (formElement) {
                formElement.submit();
            } else {
                console.error("Element 'ext-merchant-frm' not found.");
            }
        }
    }, [payForm]);

    useEffect(() => {
        // const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        const route = window.location.pathname.split('/')[3];
        if (route !== undefined && route == 'card') {
            if (location.state) {
                let cardSaveStatus = location.state.cardSaveStatus;
                if (location.state && typeof cardSaveStatus !== 'undefined' && cardSaveStatus !== '' && cardSaveStatus !== null) {
                    if (cardSaveStatus === true) {
                        message.success(`Your card details has been saved successfully`);
                    } else {
                        message.error(`Error occurred when saving your card details`);
                    }
                }
                // this.props.history.push({ state: { cardSaveStatus: null } });
                // navigate();
            }
        }
    }, []);

    const onSaveMsisdn = async () => {
        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        if (mode == 'add') {
            if (selectedPaymentMethod == '1') {
                addPaymentMsisdn(tenantGroupId, selectedMsisdn).then((response) => {
                    if (response.data && response.data.status === "SUCCESS") {
                        setMode('view');
                        dispatch<any>(fetchPaymentOptionsList(tenantGroupId, searchText));
                        dispatch<any>(fetchNoPayMsisdnList(tenantGroupId));
                        message.success("Msisdn added successfully");
                    } else {
                        message.error("Msisdn adding failed");
                    }
                }).catch((error) => {
                    message.error("Msisdn adding failed");
                });
            } else {
                let res = await getAddGenieCardView(tenantGroupId);
                setPayForm(res.data);
            }
        } else {
            if (payList[editPaymentIndex].msisdn) {
                editPaymentMsisdn(tenantGroupId, payList[editPaymentIndex].msisdn, payList[editPaymentIndex].isPrimary).then((response) => {
                    if (response.data && response.data.status === "SUCCESS") {
                        setMode('view');
                        dispatch<any>(fetchPaymentOptionsList(tenantGroupId, searchText));
                        message.success("Msisdn updated successfully");
                    } else {
                        message.error("Msisdn update failed");
                    }
                }).catch((error) => {
                    message.error("Msisdn update failed");
                });
            } else {
                updateGenieCard(tenantGroupId, payList[editPaymentIndex].invoiceNo, payList[editPaymentIndex].isPrimary).then((response) => {
                    if (response.data && response.data.status === "SUCCESS") {
                        setMode('view');
                        dispatch<any>(fetchPaymentOptionsList(tenantGroupId, searchText));
                        message.success("Card updated successfully");
                    } else {
                        message.error("Card update failed");
                    }
                }).catch((error) => {
                    message.error("Card update failed");
                });
            }
        }
    }

    const onChangeNumberStatus = (e: any, paymentDetails: any, key: number) => {
        var payListVar = payList;

        var updatedArray = payListVar.map((obj: any, index: number) => {
            if (index === key) {
                // Create a new object with the updated isPrimary property
                return { ...obj, isPrimary: e.target.value === "Primary" ? true : false };
            }
            return obj; // Keep other objects unchanged
        });
        setPayList(updatedArray);
        setEditPaymentIndex(key);
    }

    const onPressDelete = (index: number, type: string) => {
        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        if (type === "msisdn") {
            deletePaymentMsisdn(tenantGroupId, payList[index].msisdn).then((response) => {
                if (response.data && response.data.status === "SUCCESS") {
                    setMode('view');
                    dispatch<any>(fetchPaymentOptionsList(tenantGroupId, searchText));
                    dispatch<any>(fetchNoPayMsisdnList(tenantGroupId));
                    message.success("Msisdn deleted successfully");
                } else {
                    message.error("Msisdn delete failed");
                }
            }).catch((error) => {
                message.error("Msisdn delete failed");
            })
        } else {
            deleteCardDetails(tenantGroupId, payList[index].invoiceNo).then((response) => {
                if (response.data && response.data.status === "SUCCESS") {
                    setMode('view');
                    dispatch<any>(fetchPaymentOptionsList(tenantGroupId, searchText));
                    dispatch<any>(fetchNoPayMsisdnList(tenantGroupId));
                    message.success("Msisdn deleted successfully");
                } else {
                    message.error("Msisdn delete failed");
                }
            }).catch((error) => {
                message.error("Msisdn delete failed");
            })
        }
    }

    const renderPaymentForm = () => {
        if (!payForm) {
            return;
        }
        if (payForm) {
            return (
                <div dangerouslySetInnerHTML={{ __html: payForm }} />
            );
        }
    }


    return (
        <div className="sub-nav-inner-container__wrapper">
            <div className="sub-nav-inner-container content-holder">
                <div className="content-holder-header__wrapper">
                    <div className="content-holder-header">
                        <h4 className="inner-title">Payment Methods</h4>
                    </div>
                </div>
                {/* bpayment methods*/}
                <div className="card panel content-holder-panels">
                    <div className="panel-header">
                        <div className="panel-header-content__wrapper">
                            <h4 className="panel-header-title">Payment methods</h4>
                            <p className="panel-header-subtitle">List of payment methods available for your business</p>
                        </div>
                    </div>
                    {/* panel table */}
                    <div className="panel-table-listing__wrapper">
                        <div className="panel-table-listing">
                            {userDetails && userDetails.OwnedEnterprises[0] && userDetails.OwnedEnterprises[0].Enterprise && userDetails.OwnedEnterprises[0].Enterprise.billingStatus !== 'Invoice' && <div className="panel-table-listing-header__wrapper">
                                <div className="panel-table-listing-header">
                                    <div className="panel-table-listing-header__inner">
                                        <p><strong>{`${(payList && Array.isArray(payList) && payList.length > 0) ? payList.length : '0'} Total`}</strong></p>
                                    </div>
                                    <div className="panel-table-listing-header__inner">
                                        <div className="form-group__wrapper">
                                            <div className="form-group">
                                                <div className="form-element__wrapper ">
                                                    <div className="icon-inside right">
                                                        <span className="in-icon"><i className="bi bi-search" /></span>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id=""
                                                            placeholder="Search"
                                                            spellCheck="false"
                                                            data-ms-editor="true"
                                                            onChange={(e) => {
                                                                let val = e.target.value;
                                                                if (val !== '') {
                                                                    setSearchText(e.target.value);
                                                                } else {
                                                                    setSearchText('null');
                                                                }
                                                            }}
                                                            value={searchText !== 'null' ? searchText : ''}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {userDetails && userDetails.OwnedEnterprises[0] && userDetails.OwnedEnterprises[0].Enterprise && userDetails.OwnedEnterprises[0].Enterprise.billingStatus !== 'Invoice' ? <>
                                {payList && Array.isArray(payList) && payList.length == 0 && <NoData></NoData>}
                                <div className="panel-table-content-list">
                                    {payList && Array.isArray(payList) && payList.length != 0 && payList.map((value: any, index: number) => {
                                        return (
                                            <PaymentMethodItem
                                                key={index}
                                                paymentDetails={value}
                                                mode={mode}
                                                onChangeNumberStatus={onChangeNumberStatus}
                                                index={index}
                                                onPressDelete={onPressDelete}
                                            />
                                        )
                                    })}
                                    {mode === 'add' && <>
                                        <div className="panel-content">
                                            <div className="status__wrapper">
                                                <div className="panel-inner-feature-content__wrapper">
                                                    <span className="panel-inner-feature-content-title">
                                                        Payment
                                                    </span>
                                                    <div className="panel-inner-feature-content">
                                                        {/* <p>
                                                        Card payment method will be available soon
                                                        </p> */}
                                                        <div className="form-group__wrapper">
                                                            <RadioButton
                                                                label="Add to bill"
                                                                name="paymentOption"
                                                                id={'1'}
                                                                value={1}
                                                                data={selectedMsisdn}
                                                                selectedValue={selectedPaymentMethod}
                                                                iconClass="bi-phone-fill"
                                                                icon=""
                                                                canChange={true}
                                                                setSelctedItem={onChangeMethod}
                                                                disabled={false}
                                                                onChangeOption={() => setDisplayMsisdnModal(true)}
                                                            />
                                                            <RadioButton
                                                                label="Card payment"
                                                                name="paymentOption"
                                                                id={'9'}
                                                                value={9}
                                                                data={'Card'}
                                                                selectedValue={selectedPaymentMethod}
                                                                iconClass="bi-phone-fill"
                                                                icon={''}
                                                                canChange={false}
                                                                setSelctedItem={onChangeMethod}
                                                                disabled={true}
                                                                onChangeOption={() => { }}
                                                                paymentDisableNotice={true}
                                                            />
                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>}

                                    <div className="panel-table-list-item">
                                        {mode === 'view' ? <div className="panel-table-listing-item__inner">
                                            <button className="btn btn-primary btn-xsmall" onClick={() => {
                                                setMode('add');
                                            }}><i className="bi bi-plus-lg" />&nbsp;Add payment method</button>
                                            <button className="btn btn-primary btn-xsmall" onClick={() => {
                                                setMode('edit');
                                            }}><i className="bi bi-pencil-fill" />&nbsp;Edit payment methods</button>
                                        </div> : <div className="panel-bottom-btn__wrapper">
                                            <button className="btn btn-secondary"
                                                onClick={() => {
                                                    setMode('view');
                                                    // setMaskStatus('');
                                                    // setNewMask('');
                                                    const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
                                                    dispatch<any>(fetchPaymentOptionsList(tenantGroupId, searchText));
                                                }}>
                                                Cancel
                                            </button>
                                            <button
                                                className="btn btn-primary"
                                                onClick={onSaveMsisdn}
                                            // disabled={((maskMode === 'add' && isDuplicate !== true && !(validator.check(newMask, ['required', 'mask', { min: 3 }, { max: 11 }]))) ? true : false) || (maskMode === 'edit' && (updatedMask ? (isEditDuplicate[updatedMask.index] === true || isEditDuplicate[updatedMask.index] === null) : false) ? true : false)}
                                            // disabled={(maskMode === 'add' || maskMode === 'edit') && (isDuplicate === true || isDuplicate === null || isEditDuplicate[updatedMask.index] === true)}
                                            >Save &amp; Update
                                            </button>
                                        </div>}
                                    </div>
                                </div>
                            </> : <NoPaymentMethods></NoPaymentMethods>}
                            {renderPaymentForm()}
                        </div>
                    </div>
                </div>
                <Modal
                    display={displayMsisdnModal}
                    onClose={() => setDisplayMsisdnModal(false)}
                    onConfirm={() => setDisplayMsisdnModal(false)}
                >
                    <div className="card panel content-holder-panels">
                        <div className="panel-header">
                            <div className="panel-header-content__wrapper">
                                <h4 className="panel-header-title">Your Numbers</h4>
                                <p className="panel-header-subtitle">
                                    List of numbers reserved for charging; choose one.
                                </p>
                            </div>
                        </div>
                        {/* panel table */}
                        <div className="panel-table-listing__wrapper">
                            <div className="panel-table-listing">
                                <div className="panel-table-content-list">
                                    {noPayMsisdnList && Array.isArray(noPayMsisdnList) && noPayMsisdnList.map((value: any, index: number) => {
                                        return (
                                            <div className="panel-table-list-item" key={index}>
                                                <div className="panel-table-listing-item__inner">
                                                    <div className="feature-details__wrapper">
                                                        <div className="card app-media-holder orange-shade sm-center-icon-card">
                                                            <span className="icon__wrapper">
                                                                <i className="bi bi-sim-fill" />
                                                            </span>
                                                        </div>
                                                        <div className="feature-details-title__wrapper no-shade">
                                                            <span className="title">{value.msisdn}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="panel-table-listing-item__inner">
                                                    <div className="panel-table-right-align-item__wrapper">
                                                        <div className="panel-table-right-align-item">
                                                            <div className="form-group__wrapper"></div>
                                                        </div>
                                                        <label className="switch">
                                                            <input
                                                                type="radio"
                                                                name='number-change'
                                                                onChange={onChangeMsisdn}
                                                                value={value.msisdn}
                                                                checked={selectedMsisdn == value.msisdn}
                                                            />
                                                            <span className="slider round" />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>
                        </div>
                    </div>

                </Modal>
                {/* panel table */}
            </div>
            {/* / payment methods*/}
        </div>
    );
}

export default Payment;