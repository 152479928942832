import axios from 'axios';
import axiosInstance from '../axios-instance';
import * as Constants from '../constants';


const getLocationTrackingList = (tenantId: string, widgetId: string, limit: number, offset: number) => {
    return axiosInstance.get(`${Constants.REACT_APP_BACKEND_URL}/widget/location-tracking/revamp/${tenantId}/${widgetId}?limit=${limit}&offset=${offset}`);
}

const onSearch = (searchString: string) => {
    return axios.get(`https://nominatim.openstreetmap.org/?addressdetails=2&q=${searchString}&format=json&limit=5`)
        .then(function (response) {
            //handle success
            return response;
        }).catch(function (response) {
            //handle error
            // console.log(response);
            return response;
        });
}

const postLocationTrackData = async (data: any, tenantId: string, widgetId: string) => {
    return await axiosInstance({
        method: `post`,
        url: `${Constants.REACT_APP_BACKEND_URL}/widget/location-tracking/revamp/${tenantId}/${widgetId}`,
        data: data
    })
}

const getIndividualTrackData = (tenantId: string, trackId: string) => {
    return axiosInstance.get(`${Constants.REACT_APP_BACKEND_URL}/widget/location-tracking/revamp/view/${tenantId}/${trackId}`);
}

const getTrackHistory = (tenantId: string, trackId: string, limit: number, offset: number) => {
    return axiosInstance.get(`${Constants.REACT_APP_BACKEND_URL}/widget/location-tracking/revamp/logs/${tenantId}/${trackId}?limit=${limit}&offset=${offset}`);
}


const getTermsAndConditions = async (widgetId: string) => {
    return axiosInstance.get(`${Constants.REACT_APP_BACKEND_URL}/widgets/termscondtions?widgetId=${widgetId}`)
        .then(function (response) {
            //handle success
            return response;
        }).catch(function (response) {
            //handle error
            // console.log(response);
            return response;
        });
}

const updateTrackData = (tenantId: string, trackId: string, expiredAt: string, status: string) => {
    return axiosInstance.put(`${Constants.REACT_APP_BACKEND_URL}/widget/location-tracking/revamp/${tenantId}/${trackId}`, {
        expiredAt: expiredAt,
        status: status
    });
}

const getSettlementTypes = async (tid: string, wid: string) => {
    return axiosInstance({
        method: `get`,
        url: `${Constants.REACT_APP_BACKEND_URL}/widgets/widgetplans/${tid}/${wid}`,
    });
};

export {
    getLocationTrackingList,
    postLocationTrackData,
    onSearch,
    getTermsAndConditions,
    getIndividualTrackData,
    getTrackHistory,
    updateTrackData,
    getSettlementTypes
};