import React, { CSSProperties, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
    fetchSingleApp, fetchSurveyLogs
} from '../../../store/actions';
import { useAppDispatch } from '../../../store';
import { css } from '@emotion/react';
import AuthHandler from '../../../helper/AuthHandler';
import { PageHeaderStatic } from "../components/PageHeaderStatic";
import { confirmAlert } from "react-confirm-alert";
import { Divider, Steps, notification, DatePicker, Modal, Space, Spin, Switch, Row, Col } from "antd";
import * as CONSTANTS from '../../../constants';
// import {
//     Chart as ChartJS,
//     CategoryScale,
//     LinearScale,
//     BarElement,
//     Title,
//     Tooltip,
//     Legend,
// } from 'chart.js';
import 'chart.js/auto';
import { Pie } from "react-chartjs-2";
import moment from "moment";

// ChartJS.register(
//     CategoryScale,
//     LinearScale,
//     BarElement,
//     Title,
//     Tooltip,
//     Legend
// );

const QuickSurveyHistory = ({ auth }: any) => {

    const { wid, sentimentId } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [screen, setScreen] = useState<string>("sentiment-history");
    const [sentimentLogStatus, setSentimentLogsStatus] = useState([]);

    const { userDetails } = useSelector(({ user }: any) => user);
    const { success, loading, error, surveyLogs } = useSelector(({ quicksurvey }: any) => quicksurvey);

    useEffect(() => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        const surveyId = window.location.pathname.split('/')[4];
        if (tenantGUID && surveyId) {
            dispatch(fetchSurveyLogs(tenantGUID, surveyId));
        }
    }, [userDetails]);

    const renderSurveyLogs = () => {
        if (Array.isArray(surveyLogs)) {
            if (surveyLogs.length > 0) {
                return (
                    surveyLogs.map((value, index) => {
                        if (Array.isArray(value.deliveryStatus) && Array.isArray(value.userResponses)) {
                            return (
                                <div className="card-body" key={value.questionId}>
                                    <div className="row wow fadeIn">
                                        <div className="row wow fadeIn">
                                            <div className="col-md-12 mb-4 text-center">
                                                <h4>Question {index + 1} : {value.question}</h4></div>
                                        </div>
                                    </div >
                                    <Row gutter={[12, 12]} className="row-wrap sentiment-log-wrapper">
                                        <Col xs={2} sm={4} md={6} lg={8} xl={10}>
                                            <div className="text-center"><h4>SMS delivery status</h4></div>
                                            <div className="text-center">{renderDeliveryPieChart(value)}</div>
                                        </Col>
                                        <Col xs={20} sm={16} md={12} lg={8} xl={4}>

                                        </Col>
                                        <Col xs={2} sm={4} md={6} lg={8} xl={10}>
                                            <div className="text-center"><h4>User responses</h4></div>
                                            {renderResponsesPieChart(value)}
                                        </Col>
                                    </Row>
                                    <div className="row wow fadeIn">
                                        <div className="panel-table-listing__wrapper">
                                            <div className="table-parent__wrapper">
                                                <table className="table table-default">
                                                    <tbody>
                                                        <tr className="table-header">
                                                            <th className="txt-left">#</th>
                                                            <th className="txt-left">Mobile number</th>
                                                            <th className="txt-left">User response</th>
                                                            <th className="txt-left">Sent at</th>
                                                        </tr>
                                                        {value && value.molist && value.molist.map((value: any, index: number) => {
                                                            return (
                                                                <tr className="table-row ">
                                                                    <td className="txt-left">{value.uuid}</td>
                                                                    <td className="txt-left">{'0' + value.msisdn.substring(7, 16)}</td>
                                                                    <td className="txt-left">{value.response !== null ? value.response : 'PENDING'}</td>
                                                                    <td className="txt-left">{moment(value.createdAt).format('YYYY-MM-DD hh:mm:ss A')}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            )

                        }
                    })
                )
            }
        }
    }

    const renderDeliveryPieChart = (obj: any) => {
        if (obj.deliveryStatus.length > 0) {
            const newChartData: { labels: string[], datasets: any[] } = {
                labels: [],
                datasets: [
                    {
                        data: [],
                        backgroundColor: [
                            "#F7464A",
                            "#46BFBD",
                            "#FDB45C"
                        ],
                        hoverBackgroundColor: [
                            "#FF5A5E",
                            "#5AD3D1",
                            "#FFC870"
                        ]
                    }
                ]
            };
            for (let i = 0; i < obj.deliveryStatus.length; i++) {
                newChartData?.labels.push(obj.deliveryStatus[i].status);
                newChartData.datasets[0].data.push(obj.deliveryStatus[i].statusCount);
            }
            return (
                <Pie data={newChartData} options={{ responsive: true }} />
            )
        } else {
            return (
                <div className="text-center">SMS for this question has not been sent yet to anyone.</div>
            )
        }
    }

    const renderResponsesPieChart = (obj: any) => {
        if (obj.userResponses.length > 0) {
            const newChartData: { labels: string[], datasets: any[] } = {
                labels: [],
                datasets: [
                    {
                        data: [],
                        backgroundColor: [
                            "#F7464A",
                            "#46BFBD",
                            "#FDB45C"
                        ],
                        hoverBackgroundColor: [
                            "#FF5A5E",
                            "#5AD3D1",
                            "#FFC870"
                        ]
                    }
                ]
            };
            for (let i = 0; i < obj.userResponses.length; i++) {
                newChartData.labels.push(obj.userResponses[i].response);
                newChartData.datasets[0].data.push(obj.userResponses[i].responseCount);
            }
            return (
                <Pie data={newChartData} options={{ responsive: true }} />
            )
        } else {
            return (
                <div className="text-center">User responses for this question has not been received yet from anyone.</div>
            )
        }
    }

    if (AuthHandler.isAuthorized(auth, 'add-new-sentiment') && userDetails !== null) {
        if (userDetails) {
            return (
                <React.Fragment>
                    <div className="main-content main-spacer-x-y">
                        <PageHeaderStatic
                            page={'historyLogs'}
                        />
                        <div className="section__wrapper">
                            <div className="card panel content-holder-panels">
                                <div className="panel-header">
                                    <div className="panel-header-content__wrapper">
                                        <h4 className="panel-header-title">Customer sentiment History Details</h4>
                                    </div>
                                </div>
                                {renderSurveyLogs()}
                            </div>
                        </div>

                    </div>
                </React.Fragment>
            );
        } else {
            return (
                <div className="section__wrapper">
                    <div className="empty-state-new-action__wrapper">
                        <div className="new-action-content__wrapper">
                            <h4 className="sub">Oops, something went wrong!</h4>
                        </div>
                    </div>
                </div>
            );
        }

    } else return (<React.Fragment></React.Fragment>);

}

export default QuickSurveyHistory;