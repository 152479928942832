import bizEmailStore from "../reducers/biz-email.store";
import {
    postBizEmailConfig,
    getBizEmailConfigsList,
    getBizEmailConfig,
    putBizEmailConfig

} from "../../services/biz-email.service";

export const postNewBizEmailConfig = (data: any, tenantId: string, widgetId: string): any => {
    return (dispatch: any) => {
        dispatch(bizEmailStore.actions.bizEmailConfigPost());

        postBizEmailConfig(data, tenantId, widgetId)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(bizEmailStore.actions.bizEmailConfigPostSuccess(response.data));
                }
            })
            .catch((error) => {
                console.log(error)
                dispatch(bizEmailStore.actions.bizEmailConfigPostFail(error.response?.data?.error));
            });
    }
};

export const fetchBizEmailConfigData = (tenantId: string): any => {
    return (dispatch: any) => {
        dispatch(bizEmailStore.actions.bizEmailAllConfigFetch());

        getBizEmailConfigsList(tenantId)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(bizEmailStore.actions.bizEmailAllConfigFetchSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(bizEmailStore.actions.bizEmailAllConfigFetchFail(error));
            });
    }
};

export const fetchBizEmailConfig = (tenantId: string, configUuid: string): any => {
    return (dispatch: any) => {
        dispatch(bizEmailStore.actions.bizEmailConfigFetch());
        getBizEmailConfig(tenantId, configUuid)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(bizEmailStore.actions.bizEmailConfigFetchSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(bizEmailStore.actions.bizEmailConfigFetchFail(error));
            });
    }
};

export const updateBizEmailConfig = (data: any, tenantId: string, configUuid: string): any => {
    return (dispatch: any) => {
        dispatch(bizEmailStore.actions.bizEmailConfigUpdate());
        putBizEmailConfig(data, tenantId, configUuid)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(bizEmailStore.actions.bizEmailConfigUpdateSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(bizEmailStore.actions.bizEmailConfigUpdateFail(error));
            });
    }
};