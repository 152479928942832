import React, { useState, useEffect, CSSProperties } from "react";
import { ClipLoader } from "react-spinners";
import InvalidUrl from '../../../assets/images/invalidUrl.svg';
import ExpiredUrl from '../../../assets/images/expiredUrl.svg';
import { getOriginalUrl } from '../../../services/app.service';
import * as CONSTANTS from '../../../constants';
// import { css } from "@emotion/core";

// const override = css`
//   display: block;
//   margin: 0 auto;
// `;

const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
};

type Props = {}

const SentimentWaiting = (props: Props) => {

    const [responseStatus, setResponseStatus] = useState('');

    const fetchOriginalUrl = () => {
        let uuid = window.location.pathname.split('/')[CONSTANTS.BASEURLPATHLENGTH + 3];
        getOriginalUrl(uuid).then((response) => {
            if (response.status === 200) {
                setResponseStatus('');
                window.location.replace(CONSTANTS.HOST_URL + response.data.originalUrl);
            }
        }).catch((error) => {
            if (error.response?.data.error === "Url is invalid") {
                setResponseStatus('INVALID');
            } else if (error.response?.data.error === "Url is expired") {
                setResponseStatus('EXPIRED');
            } else {
                setResponseStatus('INVALID');
            }
        });
    }

    useEffect(() => {
        fetchOriginalUrl();
    }, []);

    return (
        <React.Fragment>
            {responseStatus === "INVALID" ? <div className={"container main "}>
                <div className="main__wrapper col">
                    <div className="row">
                        <div className="idea__card access__messages denied">
                            <img src={InvalidUrl} className="img-fluid" alt="Access denied" />
                            <h2 className="access__title">
                                Invalid Url
                            </h2>
                            <h4 className="access__description">
                                Sorry! you have visited a invalid URL
                            </h4>
                        </div>
                    </div>
                </div>
            </div> : responseStatus === "EXPIRED" ? <div className={"container main "}>
                <div className="main__wrapper col">
                    <div className="row">
                        <div className="idea__card access__messages denied">
                            <img src={ExpiredUrl} className="img-fluid" alt="Access denied" />
                            <h2 className="access__title">
                                Expired Url
                            </h2>
                            <h4 className="access__description">
                                Sorry! you have visited a expired URL
                            </h4>
                        </div>
                    </div>
                </div>
            </div> : <div className="account-creation ac-status">
                <section className="ac-status__wrapper">
                    <div className="ac-status-animation__wrapper">
                        <div className="loader-5 center"><span>
                            <ClipLoader
                                cssOverride={override}
                                size={40}
                                color={"#4e286d"}
                                loading={true}
                            />
                        </span></div>
                        {/* <div className="circle-loader load-error">
                            <div className="checkmark error" />
                        </div> */}
                    </div>
                    <h2 className="title"> <span>Redirecting to</span> <span>your</span> <span>questionaire,</span> <br /> almost there...
                    </h2>
                </section>
            </div>}
        </React.Fragment>
    )
}

export default SentimentWaiting;