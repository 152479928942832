import { Table } from 'antd';
import React from 'react';
import { WidgetDetails, WidgetRate } from '../../single-app/models/response/single-app.model';

interface WidgetRateItem {
    uuid: string;
    title: string;
    rate: string;
    description: string;
    RateCardId: number;
    RatecardAddtionalDetails: {
        detailsJson: {
            title: string;
            details: {
                [key: string]: string;
            }[];
        };
    };
}

type Props = {
    rateDetails: WidgetDetails
}

const WidgetRateTable: React.FC<Props> = ({ rateDetails }: Props) => {
    const DetailsJsonArray: { title: string; details: { [key: string]: string }[] }[] = [];
    const TitleArray: string[] = [];
    //const keysToExtract = ["PRICE", "Social Media", "Engaging Posts with Graphics - Per Month", "Stories/Reels - Per Month", "Short Videos (15-30 sec) Promotional Video", "Free Web Site (Not include Domain & Hosting", "500-1000 Words Articles about Business - Monthly", "Social Media Marketing Strategy", "Monthly Report with Analysis", "Content Language", "Consultation for Paid Advertistment Campaigns", "Dedicated Account Manager", "Cost-All costs & perks are per month", "Social Media Strategy"];
    let keysToExtract: string[] = [];
    if(rateDetails && rateDetails.WidgetRate && rateDetails.WidgetRate.length > 0){
      keysToExtract = rateDetails?.WidgetRate[0]?.RatecardAddtionalDetails?.detailsJson.details.map((detail: {}) => {
        console.log('Keyyy: ', Object.keys(detail)[0]);
        return Object.keys(detail)[0];
      });
    }
   
    const ExtractedValues: Record<string, any[]> = {};

    const widgetRates: WidgetRate[] | null = rateDetails.WidgetRate;

    widgetRates?.forEach((item: WidgetRate) => {
        const detailsJson = item.RatecardAddtionalDetails?.detailsJson;
        if (detailsJson) {
            DetailsJsonArray.push(detailsJson);
            TitleArray.push(detailsJson.title);
        }
    });

    keysToExtract.forEach(key => {
        ExtractedValues[key] = [];
    });

    DetailsJsonArray.forEach(detailsJson => {
        detailsJson.details.forEach(detail => {
            keysToExtract.forEach(key => {
                const value = detail[key];
                console.log("val rate= ", value)
                if (value !== undefined && value !== null) {
                    ExtractedValues[key].push(value);
                }
            });
        });
    });

    const columns = [
        {
            title: '', dataIndex: '', key: 'key', render: (text: string, record: any, index: number) => {
                return {
                    props: {
                        style: { background: '#faa7d8' }, // Apply background color to the first column of the table
                    },
                    children: <span>{keysToExtract[index] }</span>,
                };
            }
        },
        ...TitleArray.map((title, index) => ({ title: title, dataIndex: title, key: `title_${index}` }))
    ];

    const dataSource = keysToExtract.map((key, keyIndex) => ({
        key: `row_${keyIndex}`,
        ...TitleArray.reduce((acc, title, titleIndex) => ({ ...acc, [title]: ExtractedValues[key][titleIndex] }), {})
    }));

    return <Table columns={columns.map(column => ({
        ...column,
        title: (
            <div style={{ textAlign: 'center', color:'white' }}>
                <strong>{column.title.split(' ')[0]}</strong>{' '}
                <br></br>
                {column.title.substring(column.title.indexOf(' ') + 1)}
            </div>
        ),
    }))} dataSource={dataSource} bordered pagination={false} />;
};

export default WidgetRateTable;

