import React, { useEffect, useState } from 'react';
import Auth from '../../models/response/auth.model';
import backgroundImage from "./../../assets/images/cover-123.jpeg";
import { FileUpload } from '../../components';
import SimpleReactValidator from 'simple-react-validator';
import { useSelector } from 'react-redux';
import { editBusinessDetails } from '../../store/actions';
import { useAppDispatch } from '../../store';
import { BusinessDetailsModel } from './models/request/business-details.model';

type Props = {
    auth: Auth
}

const BusinessBrands = () => {
    const [logo, setLogo] = useState<any>([]);
    const [showLogoUpload, setShowLogoUpload] = useState(false);
    const [currentLogo, setCurrentLogo] = useState("");
    const [currentLogoUuid, setCurrentLogoUuid] = useState("");
    const [logoUploadSizeError, setLogoUploadSizeError] = useState("");
    const [getIsEditLogoClicked, setIsEditLogoClicked] = useState(false)

    const {enterpriseDetailsUpdateStatus,
        businessDetails
    } = useSelector<any, any>(({ settings }) => settings);
    const {
        userDetails
    } = useSelector<any, any>(({ user }) => user);
    const dispatch = useAppDispatch();

    const handleLogoUpload = () => {
        setShowLogoUpload(true);
        //set image upload window
    }

    const [businessDetailsData, setBusinessDetailsData] = useState({
        logo: ""
    })

    const setBusinessDetails = (details: any) => {
        setBusinessDetailsData({
            ...businessDetailsData,
            logo: details ? details.logo : ""
        });
    };

    const [validator] = useState(new SimpleReactValidator({
        messages: {
            required: 'You can’t keep this as empty.',
            min: 'This should not be less than :min:type.',
            max: 'Maximum :max:type are allowed.',
            email: 'This does not look like an email.',
        }
    }))

    const onChangeLogo = (file: any) => {
        const fileSize = file.file.size / 1024 / 1024;
        const fileType = file.file.type;
        console.log("file type", fileType);

        if (fileSize > 5) {
            setLogoUploadSizeError("Filesize should be less than 5mb. Upload again.");
            validator.hideMessageFor('logo');
        } else if (fileType !== "image/jpeg" && fileType !== "image/png" && fileType !== "application/pdf" && fileType !== "document/pdf") {
            setLogoUploadSizeError("Only png, jpg or pdf file types are allowed");
            validator.hideMessageFor('logo');
        } else {
            setBusinessDetailsData({
                ...businessDetailsData,
                logo: file // Update state with supportDoc
            });
            setLogoUploadSizeError("");
            setLogo(file.fileList);
            validator.showMessageFor('logo');
            forceUpdate();
        }
    }

    const onDropLogo = () => {
        setLogo([]);
        validator.showMessageFor('logo');
        forceUpdate();
    }

    const useForceUpdate = () => {
        const [value, setValue] = useState(0);
        return () => setValue(value => value + 1);
    }

    const forceUpdate = useForceUpdate()
    const beforeUploadLogo = (file: any) => {
        return false;
    }

    useEffect(() => {
        console.log("setting.businessDetails");
        setCurrentLogo(businessDetails ? businessDetails.logo : "");
    }, [businessDetails])


    const onLogoSave = () => {
        const fmData = new FormData();
        if (Array.isArray(logo) && logo.length > 0) {
            fmData.append("logo", (logo[0].originFileObj));
          }
        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        const enterpriseId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].Enterprise.uuid : "";

        dispatch<any>(editBusinessDetails(fmData, enterpriseId, tenantGroupId));
    }

    return (<div className="sub-nav-inner-container__wrapper">
        <div className="sub-nav-inner-container content-holder">
            <div className="content-holder-header__wrapper">
                <div className="content-holder-header">
                    <h4 className="inner-title">Business Brand</h4>
                </div>
            </div>
            {/* branding update container*/}
            <div className="card panel content-holder-panels">
                <div className="panel-header">
                    <div className="panel-header-content__wrapper">
                        <h4 className="panel-header-title">Logos/ Cover</h4>
                        <p className="panel-header-subtitle">
                            Your business logo and cover image
                        </p>
                    </div>
                </div>
                <div className="panel-content">
                    <div className="branding-preview-container__wrapper">
                        <div className="branding-preview-container">
                            <div className="profile-cover-holder card" style={{ backgroundImage: `url(${backgroundImage})` }}
                            >
                                {/* <button className="btn btn-upload-trigger">
                                    <i className="bi bi-camera-fill" />
                                </button> */}
                                <div className="profile-pic-holder card" style={{ backgroundImage: `url(${currentLogo})` }}>
                                    <button className="btn" onClick={() => handleLogoUpload()}>
                                        <i className="bi bi-camera-fill" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {showLogoUpload &&
                        (<div className="form-group__wrapper">
                            <div className="form-group">
                                <FileUpload
                                    label={"Upload your business logo"}
                                    required={true}
                                    classes={""}
                                    onChangeFile={onChangeLogo}
                                    fileList={logo}
                                    name={'logo'}
                                    rules={['required']}
                                    onDropFile={onDropLogo}
                                    beforeUploadFile={beforeUploadLogo}
                                    maxCount={1}
                                    multiple={false}
                                    fileTypes={'.png,.jpg,.jpeg'}
                                    validator={validator}
                                    disabled={false}
                                    maxSize={2}
                                />
                            </div>
                        </div>)
                    }
                    {/* <div className="alert info">
                        <span className="alert-text__wrapper">
                            <i className="alert-icon bi bi-info-circle-fill" />
                            <span className="alert-text">
                                Note - If you'd like to replace the logo, kindly reach out to the Marketplace team via info.marketplace@dialog.lk
                            </span>
                        </span>
                    </div> */}
                    <div className="panel-bottom-btn__wrapper">
                        <button className="btn btn-secondary" onClick={() => setIsEditLogoClicked(false)}>Cancel</button>
                        <button
                            className="btn btn-primary"
                            onClick={() => onLogoSave()}
                            disabled={logo.length === 0 || validator.allValid()}>Save &amp; Continue</button>
                    </div>

                    {enterpriseDetailsUpdateStatus === "Pending" &&
                        <div className="alert warning">
                            <span className="alert-text__wrapper"><i className="alert-icon bi bi-exclamation-circle-fill" /><span className="alert-text">Enterpsrise details changes are under admin approval process!</span></span>
                        </div>
                    }
                </div>
            </div>
            {/*  branding update container */}
        </div>
    </div>

    );
}

export default BusinessBrands;