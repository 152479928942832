import React from 'react'
import image from '../../../assets/images/icons/app-binding-chain.svg';

type Props = {
    entImage: any,
    entName: string,
    appImage: any,
    appName: string,
    status: string
}

const AppEntBindingComponent = ({ entImage, entName, appImage, appName, status }: Props) => {
    return (
        <div className="app-binding-showcase__wrapper">
            <div className="app-binding-app__wrapper">
                {entImage ?
                    <div
                        className="card app-media-holder"
                        style={{
                            backgroundImage: `url(${(entImage)}`
                        }}
                    /> : <div className="card app-media-holder ent-name-txt">
                        {(entName) ? (entName.charAt(0)).toUpperCase() : ""}
                    </div>
                }
                <p className="">{entName}</p>
            </div>
            <div
                className="app-binding-chain"
                style={{
                    backgroundImage: `url(${image})`
                }}
            >
                {/* ~ not-confirmed ~ class. will remain as the default class for the  */}
                {status === "pending" ? <span className="app-binging-icon-holder not-confirmed"><i className="bi bi-question-circle-fill" /></span>
                    : status === "confirmed" ? <span className="app-binging-icon-holder confirmed txt-green"><i className="bi bi-check-circle-fill"></i></span>
                        : status === "error" ? <span className="app-binging-icon-holder error txt-red"><i className="bi bi-x-circle-fill"></i></span>
                            : <></>}
                {/* ~ confirmed ~ class. will apply after app binding confirmed */}
                {/* <span class="app-binging-icon-holder confirmed"><i class="bi bi-check-circle-fill"></i></span> */}
                {/* ~ error accured ~ class. will apply after app binding confirmed */}
                {/* <span class="app-binging-icon-holder error"><i class="bi bi-check-circle-fill"></i></span> */}
            </div>
            <div className="app-binding-app__wrapper">
                <div
                    className="card app-media-holder"
                    style={{
                        backgroundImage: `url(${appImage})`
                    }}
                />
                <p className="">{appName}</p>
            </div>
        </div>
    )
}

export { AppEntBindingComponent }