/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { useSelector } from 'react-redux';
import { Card, Col, Form, Row, Tag, Alert } from 'antd';
import * as Constants from '../../../../constants';

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
};

type Props = {
    verifyDomainFinal: (values: any) => any,
    onClickGoBack: () => any
}

const DomainVerificationSummary = ({ verifyDomainFinal, onClickGoBack }: Props) => {

    const { success, loading, error, configData } = useSelector(({ gws }: any) => gws);

    const getStatusIcon = (status: string, verifiedStatus: string) => {
        if (status === 'Active' && verifiedStatus) {
            return <span className="workflow-status success">
                <i className="bi bi-hourglass-split" />
            </span>
        } else {
            return <span className="workflow-status pending">
                <i className="bi bi-exclamation-circle-fill" />
            </span>
        }
        // return <span className="workflow-status failed">
        //             <i className="bi bi bi-x-circle-fill" />
        //         </span>
    }

    return (
        <React.Fragment>
            <div className="card-body gws__body">
                <h4 className="gws-title">Domain Creation Summary</h4>
                <Row>
                    <div className="workflow__wrapper">
                        <p>Status</p>
                        <span className="workflow-item">
                            {getStatusIcon(configData.status, configData.WidgetGwsProvision.verified)}
                            <h5>{(configData.status === "Active" && configData.WidgetGwsProvision.verified) ? 'Verified' : 'Pending'}</h5>
                        </span>
                    </div>
                </Row>
                <Row>
                    {/* <Col span={configData.status === "Active" && configData.WidgetGwsProvision.verified ? 24 : 14}> */}
                    <Col span={24}>
                        <Card>
                            <Form
                                {...layout}
                                name="create-user"
                                style={{ marginInline: 'auto', textAlign: 'left' }}
                                initialValues={{ remember: true }}
                                onFinish={verifyDomainFinal}
                                autoComplete="off"
                                labelAlign={"left"}
                            >
                                <Form.Item
                                    label="Domain"
                                    style={{ marginBottom: "0px", paddingBottom: "0px" }}
                                >
                                    <Row>
                                        <p style={{ marginRight: '10px' }}>{configData.domain}</p>
                                        {/* {(configData.status === "Active" && configData.WidgetGwsProvision.verified) ?
                                            <Tag color="success">Verified</Tag>
                                            : <Tag color="warning">Pending</Tag>} */}
                                    </Row>
                                </Form.Item>
                                <Form.Item
                                    label="Verification Method"
                                    style={{ marginBottom: "0px", paddingBottom: "0px" }}
                                >
                                    <p>{configData.verificationMethod}</p>
                                </Form.Item>
                                {configData.verificationMethod === Constants.GWS_METHOD_TXT && <Form.Item
                                    label="Verification Code"
                                    style={{ marginBottom: "0px", paddingBottom: "0px" }}
                                >
                                    <p>{configData.verificationData}</p>
                                </Form.Item>}
                                {configData.verificationMethod === Constants.GWS_METHOD_CNAME && configData.verificationData.split(' ').length > 1 && <>
                                    <Form.Item
                                        label="CNAME Lable / Host"
                                        style={{marginBottom: "0px", paddingBottom: "0px"}}
                                    >
                                        <p>{configData.verificationData.split(' ')[0]}</p>
                                    </Form.Item>
                                    <Form.Item
                                        label="CNAME Destination / target"
                                        style={{marginBottom: "0px", paddingBottom: "0px"}}
                                    >
                                        <p>{configData.verificationData.split(' ')[1]}</p>
                                    </Form.Item>
                                </>}
                                <Form.Item
                                    label="Customer Name"
                                    style={{ marginBottom: "0px", paddingBottom: "0px" }}
                                >
                                    <p>{configData.contactName}</p>
                                </Form.Item>
                                <Form.Item
                                    label="Customer Gmail Address"
                                    style={{ marginBottom: "0px", paddingBottom: "0px" }}
                                >
                                    <p>{configData.gmailAddress}</p>
                                </Form.Item>
                                <Form.Item
                                    label="Organization"
                                    style={{ marginBottom: "0px", paddingBottom: "0px" }}
                                >
                                    <p>{configData.organizationName}</p>
                                </Form.Item>
                                <Form.Item
                                    label="Postal Code"
                                    style={{ marginBottom: "0px", paddingBottom: "0px" }}
                                >
                                    <p>{configData.postalCode}</p>
                                </Form.Item>
                                <Form.Item
                                    label="Admin Name"
                                    style={{ marginBottom: "0px", paddingBottom: "0px" }}
                                >
                                    <p>{configData.adminFirstName + " " + configData.adminLastName}</p>
                                </Form.Item>
                                <Form.Item
                                    label="Admin Email"
                                    style={{ marginBottom: "0px", paddingBottom: "0px" }}
                                >
                                    <p>{configData.adminEmail}</p>
                                </Form.Item>
                                <Form.Item
                                    label="Created At"
                                    style={{ marginBottom: "0px", paddingBottom: "0px" }}
                                >
                                    <p>{configData.createdAt}</p>
                                </Form.Item>
                                <Form.Item
                                    label="Last Updated At"
                                    style={{ marginBottom: "0px", paddingBottom: "0px" }}
                                >
                                    <p>{configData.updatedAt}</p>
                                </Form.Item>
                            </Form>
                            {configData.status === "Pending" && configData.WidgetGwsProvision.verified === false && <div>
                                {/* <Tag color="orange" style={{ fontSize: 18, padding: 10, marginTop: 20, marginBottom: 10 }}>Verification Pending</Tag> */}
                                <Alert message="Important: Your registrar may require up to 48 hours to publish your verification code. If the setup tool gives you an error message, wait an hour before you try again." type="warning" showIcon style={{ marginTop: 10, marginBottom: 20 }} />
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <button
                                        className="btn btn-primary btn-sm"
                                        onClick={verifyDomainFinal}
                                    >
                                        Verify
                                    </button>
                                </div>
                            </div>}
                            <div>
                                <p>Recovering administrator access to your account: <a onClick={()=>{window.open('https://accounts.google.com/signin/recovery','_blank')}}>https://accounts.google.com/signin/recovery/</a></p>
                                <p>Admin Guide: <a onClick={()=>{window.open('https://support.google.com/a/answer/3035792/','_blank')}}>https://accounts.google.com/signin/recovery/</a></p>
                                <p>User Guide: <a onClick={()=>{window.open('https://support.google.com/a/users','_blank')}}>https://accounts.google.com/signin/recovery/</a></p>
                            </div>
                        </Card>
                    </Col>
                    {/* <Col span={configData.status === "Active" && configData.WidgetGwsProvision.verified ? 0 : 10}>
                        {configData.status === "Pending" && configData.WidgetGwsProvision.verified === false && <div>
                            <Tag color="orange" style={{ fontSize: 18, padding: 10, marginTop: 20, marginBottom: 10 }}>Verification Pending!</Tag>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={verifyDomainFinal}
                                >
                                    Verify
                                </button>
                            </div>
                        </div>}
                    </Col> */}
                </Row>
            </div>
        </React.Fragment>
    );
}

export default DomainVerificationSummary;