/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { CSSProperties, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import {fetchAnOrder, submitForPayment} from '../../../store/actions';
import ScaleLoader from "react-spinners/ScaleLoader";
import AuthHandler from '../../../helper/AuthHandler';
import { PageHeaderStatic } from "../components/PageHeaderStatic";
import * as Constants from '../../../constants';
import {Button, Card, Col, Input, Row, notification, Divider, Alert, Steps} from "antd";

const { Step } = Steps;

const override: CSSProperties = {
    display: "block",
    margin: "0 auto"
};

const FixedBBConfigStatusView = ({ auth }: any) => {

    const { wid } = useParams();
    const dispatch = useAppDispatch();

    const { userDetails } = useSelector(({ user }: any) => user);
    const { singleAppDetails } = useSelector(({ apps }: any) => apps);
    const { success, loading, error, singleOrder } = useSelector(({ fixedBB }: any) => fixedBB);

    useEffect(() => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        const orderUuid = window.location.pathname.split('/')[Constants.BASEURLPATHLENGTH + 4];
        if (orderUuid && tenantGUID) {
            dispatch(fetchAnOrder(tenantGUID, wid!, orderUuid));
        }
    }, [userDetails]);

    if (AuthHandler.isAuthorized(auth, 'gws-config-view') && userDetails !== null) {
        return (
            <React.Fragment>
                <div className="main-content main-spacer-x-y">
                    <PageHeaderStatic
                        page={'create'}
                        backLink={`/fixed-bb/${wid}/list`}
                    />
                    <div className="card added-app-thumbnnail__wrapper">
                        <div
                            className="added-app-thumbnail__inner"
                            style={{
                                backgroundImage: `url(${(singleAppDetails && singleAppDetails.widgetDetails !== null) ? singleAppDetails.widgetDetails.leftImage : ""})`
                            }}
                        ></div>
                    </div>
                    <div className="card panel content-holder-panels added-app-form__wrapper">
                        <div className="panel-header">
                            <div className="panel-header-content__wrapper">
                                <h4 className="panel-header-title">Order And Connection Details</h4>
                            </div>
                        </div>
                        <div className="panel-content">
                            <div className="wiz-content-form__wrapper">
                                {loading ?
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', height: 200 }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                            <ScaleLoader
                                                cssOverride={override}
                                                // size={20}
                                                color={"#EB8C20"}
                                                loading={true}
                                            />
                                        </div>
                                    </div>
                                    : (singleOrder) ?

                                        <div className="card-body">
                                            {/*{true &&*/}
                                            {singleOrder.orderStatus === "OrderCreated" &&
                                                <div className="alert warning">
                                                    <span className="alert-text__wrapper">
                                                        <i className="alert-icon bi bi-exclamation-circle-fill"/>
                                                        <span className="alert-text">You dont have an One CRM account – Please contact Marketplace team</span>
                                                    </span>
                                                </div>
                                            }
                                            {singleOrder.orderStatus === "UserVerified" &&
                                                <div className="alert warning">
                                                        <span className="alert-text__wrapper">
                                                            <i className="alert-icon bi bi-exclamation-circle-fill"/>
                                                            <span className="alert-text">Payment has not been done yet – Please do the payment and experience the widget</span>
                                                        </span>
                                                </div>
                                            }
                                            <div className="order-sum-right">
                                                <p>Order ID: <b>{singleOrder.uuid}</b></p>
                                                <p>Order Created Date: {singleOrder.createdAt}</p>
                                                {singleOrder.status === "Pending" ? <Alert message={singleOrder.status.toUpperCase()} type="warning" style={{width: "fit-content"}} />
                                                    : singleOrder.status === "Active" ? <Alert message={singleOrder.status.toUpperCase()} type="success" style={{width: "fit-content"}} />
                                                        : singleOrder.status === "Inactive" ? <Alert message={singleOrder.status.toUpperCase()} type="error" style={{width: "fit-content"}} />
                                                            : <></>}
                                            </div>
                                            <div className="order-sum-center">
                                                <h4>Order Summary</h4>
                                            </div>
                                            <Card>
                                                {/*<Row>*/}
                                                {/*    <Col span={4}>*/}
                                                {/*        <label>Created Date</label>*/}
                                                {/*    </Col>*/}
                                                {/*    <Col span={1}>*/}
                                                {/*        <label>:</label>*/}
                                                {/*    </Col>*/}
                                                {/*    <Col span={13}>*/}
                                                {/*        <p>{singleOrder.createdAt}</p>*/}
                                                {/*    </Col>*/}
                                                {/*</Row>*/}
                                                {/*<Row>*/}
                                                {/*    <Col span={4}>*/}
                                                {/*        <label>Contact Name</label>*/}
                                                {/*    </Col>*/}
                                                {/*    <Col span={1}>*/}
                                                {/*        <label>:</label>*/}
                                                {/*    </Col>*/}
                                                {/*    <Col span={13}>*/}
                                                {/*        <p>{singleOrder.CartAdditionalFields.find((item: any) => item.fieldName === "name").value}</p>*/}
                                                {/*    </Col>*/}
                                                {/*</Row>*/}
                                                {/*<Row>*/}
                                                {/*    <Col span={4}>*/}
                                                {/*        <label>Contact Number</label>*/}
                                                {/*    </Col>*/}
                                                {/*    <Col span={1}>*/}
                                                {/*        <label>:</label>*/}
                                                {/*    </Col>*/}
                                                {/*    <Col span={13}>*/}
                                                {/*        <p>{singleOrder.CartAdditionalFields.find((item: any) => item.fieldName === "contact").value}</p>*/}
                                                {/*    </Col>*/}
                                                {/*</Row>*/}
                                                {/*<Row>*/}
                                                {/*    <Col span={4}>*/}
                                                {/*        <label>Email Address</label>*/}
                                                {/*    </Col>*/}
                                                {/*    <Col span={1}>*/}
                                                {/*        <label>:</label>*/}
                                                {/*    </Col>*/}
                                                {/*    <Col span={13}>*/}
                                                {/*        <p>{singleOrder.CartAdditionalFields.find((item: any) => item.fieldName === "email").value}</p>*/}
                                                {/*    </Col>*/}
                                                {/*</Row>*/}
                                                {/*<Row>*/}
                                                {/*    <Col span={4}>*/}
                                                {/*        <label>Billing Address</label>*/}
                                                {/*    </Col>*/}
                                                {/*    <Col span={1}>*/}
                                                {/*        <label>:</label>*/}
                                                {/*    </Col>*/}
                                                {/*    <Col span={13}>*/}
                                                {/*        <p>{singleOrder.CartAdditionalFields.find((item: any) => item.fieldName === "billingAddress1").value*/}
                                                {/*            + ', ' + singleOrder.CartAdditionalFields.find((item: any) => item.fieldName === "billingAddress2").value*/}
                                                {/*            + ', ' + singleOrder.CartAdditionalFields.find((item: any) => item.fieldName === "billingCity").value}</p>*/}
                                                {/*    </Col>*/}
                                                {/*</Row>*/}
                                                <Row>
                                                    <Col>
                                                        {/*<Divider className="cart-item-divider"/>*/}
                                                        {singleOrder.CartItem.map((itemObj: any) => {
                                                            return <Row>
                                                                <div>
                                                                    <b>{itemObj.CartItemAdditionalFields.find((item: any) => item.fieldName === "deviceTitle").value} ({itemObj.CartItemAdditionalFields.find((item: any) => item.fieldName === "deviceCode").value})</b><br/>
                                                                    <span>Selected package: {itemObj.CartItemAdditionalFields.find((item: any) => item.fieldName === "packageTitle").value} - {itemObj.CartItemAdditionalFields.find((item: any) => item.fieldName === "deviceCode").value}</span><br/>
                                                                    {/*<span>Package price: LKR {parseFloat(itemObj.CartItemAdditionalFields.find((item: any) => item.fieldName === "packageAmount").value).toFixed(2)}</span><br/>*/}
                                                                    {/*<span>Device price: LKR {parseFloat(itemObj.CartItemAdditionalFields.find((item: any) => item.fieldName === "deviceAmount").value).toFixed(2)}</span><br/>*/}
                                                                    <span>Installation Address: {itemObj.CartItemAdditionalFields.find((item: any) => item.fieldName === "installationAddress1").value}, {itemObj.CartItemAdditionalFields.find((item: any) => item.fieldName === "installationAddress2").value}, {itemObj.CartItemAdditionalFields.find((item: any) => item.fieldName === "installationCity").value}</span><br/>
                                                                    <Divider className="cart-item-divider"/>
                                                                </div>
                                                            </Row>
                                                        })}
                                                    </Col>
                                                </Row>
                                                {/*{singleOrder.orderStatus !== "UserVerified" && <Row>*/}
                                                {/*    <Col span={4}>*/}
                                                {/*        <label>Total Price</label>*/}
                                                {/*    </Col>*/}
                                                {/*    <Col span={1}>*/}
                                                {/*        <label>:</label>*/}
                                                {/*    </Col>*/}
                                                {/*    <Col span={13}>*/}
                                                {/*        <p>LKR {parseFloat(singleOrder.total).toFixed(2)}</p>*/}
                                                {/*    </Col>*/}
                                                {/*</Row>}*/}
                                                {/*<Row>*/}
                                                {/*    <Col span={4}>*/}
                                                {/*        <label>Payment Method</label>*/}
                                                {/*    </Col>*/}
                                                {/*    <Col span={1}>*/}
                                                {/*        <label>:</label>*/}
                                                {/*    </Col>*/}
                                                {/*    <Col span={13}>*/}
                                                {/*        <p>{singleOrder?.Tenant?.Subscriptions[0]?.WidgetSubscriptions?.paymentMethodId == 1 ? "Add to bill"*/}
                                                {/*        : singleOrder?.Tenant?.Subscriptions[0]?.WidgetSubscriptions?.paymentMethodId == 4 ? "Invoice"*/}
                                                {/*        : singleOrder?.Tenant?.Subscriptions[0]?.WidgetSubscriptions?.paymentMethodId == 9 ? "Card Payment via Genie"*/}
                                                {/*        : 'N/A'}</p>*/}
                                                {/*    </Col>*/}
                                                {/*</Row>*/}
                                                {/*<Row>*/}
                                                {/*    <Col span={4}>*/}
                                                {/*        <label>Order Status</label>*/}
                                                {/*    </Col>*/}
                                                {/*    <Col span={1}>*/}
                                                {/*        <label>:</label>*/}
                                                {/*    </Col>*/}
                                                {/*    <Col span={13}>*/}
                                                {/*        <p>{singleOrder.orderStatus}</p>*/}
                                                {/*    </Col>*/}
                                                {/*</Row>*/}
                                            </Card>
                                            <div className="order-payment-box">
                                                <div className="order-sum-center">
                                                    <h4>Current Status</h4>
                                                </div>
                                                <Card>
                                                    <div className="order-sum-center payment-box">
                                                        <Steps current={singleOrder.orderStatus === "OrderSubmitted" ? 1 : singleOrder.orderStatus === "DeviceInstalled" ? 2 : singleOrder.orderStatus === "ConnectionActive" ? 3 : 0} direction="vertical" style={{width: "auto"}}>
                                                            <Step
                                                                title={"Order Submission"}
                                                                description={"Submitting order to process"}
                                                            />
                                                            <Step
                                                                title={"Device Installation"}
                                                                description={"The device will be delivered and installed"}
                                                            />
                                                            <Step
                                                                title={"Connection Status"}
                                                                description={"The connection is available to use"}
                                                            />
                                                        </Steps>
                                                    </div>
                                                </Card>
                                            </div>
                                        </div>
                                        : <></>}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    } else return (<React.Fragment></React.Fragment>);
}

export default FixedBBConfigStatusView;