import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface EntSliceModel {
  loading: boolean,
  success: boolean,
  error: boolean,
  masksList: any[],
  msisdnsList: any[],
  districtList: any[],
  tenantMaskList: any[],
  entConfigs: any
}

const enterpriseStore = createSlice({
  name: "ent-store",
  initialState: {
    loading: false,
    success: false,
    error: false,
    masksList: [],
    msisdnsList: [],
    districtList: [],
    tenantMaskList: [],
    entConfigs: null
  } as EntSliceModel,
  reducers: {
    masksListFetch: (state: EntSliceModel) => {
      return {
        ...state,
        error: false,
        loading: true,
        success: false
      }
    },
    masksListFetchSuccess: (state: EntSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        error: false,
        loading: false,
        success: true,
        masksList: action.payload
      }
    },
    masksListFetchFail: (state: EntSliceModel) => {
      return {
        ...state,
        error: true,
        loading: false,
        success: false
      }
    },
    msisdnsListFetch: (state: EntSliceModel) => {
      return {
        ...state,
        error: false,
        loading: true,
        success: false
      }
    },
    msisdnsListFetchSuccess: (state: EntSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        error: false,
        loading: false,
        success: true,
        msisdnsList: action.payload
      }
    },
    msisdnsListFetchFail: (state: EntSliceModel) => {
      return {
        ...state,
        error: true,
        loading: false,
        success: false
      }
    },
    districtListFetch: (state: EntSliceModel) => {
      return {
        ...state,
        error: false,
        loading: true,
        success: false
      }
    },
    districtListFetchSuccess: (state: EntSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        error: false,
        loading: false,
        success: true,
        districtList: action.payload
      }
    },
    districtListFetchFail: (state: EntSliceModel) => {
      return {
        ...state,
        error: true,
        loading: false,
        success: false
      }
    },
    tenantMasksListFetch: (state: EntSliceModel) => {
      return {
        ...state,
        error: false,
        loading: true,
        success: false
      }
    },
    tenantMasksListFetchSuccess: (state: EntSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        error: false,
        loading: false,
        success: true,
        tenantMaskList: action.payload.TenantMasks
      }
    },
    tenantMasksListFetchFail: (state: EntSliceModel) => {
      return {
        ...state,
        error: true,
        loading: false,
        success: false
      }
    },
    entConfigsFetch: (state: EntSliceModel) => {
      return {
        ...state,
        error: false,
        loading: true,
        success: false
      }
    },
    entConfigsFetchSuccess: (state: EntSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        error: false,
        loading: false,
        success: true,
        entConfigs: action.payload
      }
    },
    entConfigsFetchFail: (state: EntSliceModel) => {
      return {
        ...state,
        error: true,
        loading: false,
        success: false
      }
    }
  },
});

export default enterpriseStore;