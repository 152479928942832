import React, { useState } from 'react'
import { CollapsibleItemHeader } from './CollapsibleItemHeader'
import { AccountCreationSectionsEnums } from '../../../enum'
import { Spin } from 'antd'
import { useSelector } from 'react-redux'

type Props = {
    status: string,
    show: boolean,
    setShow: (show: boolean) => void,
    onSave: () => void,
    progress: number,
    setIsReadTnC: (show: boolean) => void,
    setIsReadAgreement: (show: boolean) => void,
    isReadTnC: boolean,
    isReadAgreement: boolean,
    loading: boolean
}

const TnCSection = ({ status, show, setShow, onSave, progress, setIsReadTnC, setIsReadAgreement, isReadTnC, isReadAgreement, loading }: Props) => {

    const {
        userDetails
      } = useSelector<any, any>(({ user }) => user);

    return (
        <div className="single-wiz-step__wrapper" id="tnc-section">
            <CollapsibleItemHeader
                title='Terms &amp; Conditions'
                desc='Read and accept our terms and conditions.'
                status={status}
                isVisible={show}
                name={AccountCreationSectionsEnums.HOME_TERMS_AND_CONDITIONS}
                handleCollapsButton={setShow}
            />
            <div className={"single-wiz-step__content " + (show ? "show" : "")}>
                <div className="wiz-content-form__wrapper">
                    <div className="fixed-content__wrapper fixed-height content-lister__wrapper">
                    <ul className="content-lister">  
                        <li>Dialog reserves the rights; at its sole discretion to;
                            <ul>
                                <li>accept or reject you as a user on this Platform;</li>
                                <li>remove or refuse to distribute any content on the Platform, to suspend or terminate you as a user of the Platform;</li>
                                <li>access & disclose the information to support applicable law, regulation, legal process, or governmental request;</li>
                                <li>update these terms and conditions from time to time, without any prior notice.</li>
                            </ul>
                        </li>
                        <li>Dialog shall not be;
                            <ul>
                                <li>liable for any damage, virus or code that may affect your hardware or devices during the use of this Platform.</li>
                                <li>endorsing any enterprise of brand name, product/Services communicate to any of its end users.</li>
                                <li>liable over any third-party content published on the Platform or its authenticity of any content, materials and anything uploaded and/or shared on Platform.</li>
                                <li>liable for any interruption, defect or fault in the Platform.</li>
                                <li>responsible for any nature of loss due to your Account being used by unauthorized users, manipulation of data or for any other reason.</li>
                            </ul>
                        </li>
                        <li>You shall at all times ensure that, any user authorized by you to access the Platform;
                            <ul>
                                <li>at the time of using this Platform and accessing the link provided, you are over the age of Eighteen (18) years, the legal age under the law of Sri Lanka.</li>
                                <li>submits true, accurate, complete information and documents, may it be submitted by you/your marketing agents/your enterprises and that it shall not be misleading by omission or otherwise.</li>
                                <li>shall only promote, display products and/or Services that are available for delivery or pick up in the normal course of your business and not promote products and or Services that are prohibited or banned in terms of the law of Sri Lanka.</li>
                                <li>shall provide any and all additional information which Dialog may reasonably request from time to time failing which it may result in the suspension or termination of your Account.</li>
                                <li>shall in the event of any defect, interruption or fault within/of the Platform, report to Dialog immediately.</li>
                                <li>shall not commit any form of forgery or use illegal methods to utilize the Platform.</li>
                                <li>shall for any inquiries or complaint, in the first instance reach your respective marketing agent and/or email to upport.marketplace@dialog.lk</li>
                            </ul>    
                        </li>
                        <li>You and any user authorized by you to access the Platform shall at all times prevent from;
                            <ul>
                                <li>using the Platform and the solutions to engage in any illegal or fraudulent activities under the rules, laws are regulations prevailing in Sri Lanka or any other jurisdiction.</li>
                                <li>fraudulently registering Accounts in the Platform. In the event Dialog identifies any fraudulent activity; including but not limited to; any instance where you are impersonating any other person or entity, you shall be solely responsible for any liability that may arise as a result of such fraudulent activity. Abusing the use and functions of the Platform may result in immediate termination / suspension of your Account and you will be immediately reported to the relevant authorities and legal action will be taken against you.</li>
                            </ul>   
                        </li>    
                        <li>Special Conditions on Widgets - All users shall;
                            <ul>
                                <li>agree and acknowledge that the tracking solutions, surveys and SMS voting provided within the Platform shall be applicable only for Dialog Subscribers.</li>
                                <li>bear the responsibility & ensure to obtain the express written consent of their respective employees, if any, who are tracked by the use of the Platform.</li>
                                <li>refrain from misusing the tracking solutions.</li>
                                <li>agree and acknowledge that in the event Dialog or your enterprise requests for any information/data on the history of your tracking or any nature of activities perform in the Platform Dialog shall cooperate with such requests and that it should be your responsibility to keep the tracks.</li>
                                <li>refrain from using any corporate/ non-profit/ private or public organization details for any negative publicity or malicious intentions.</li>
                            </ul>    
                        </li>
                        <li>Charging & Payment - All Users agree and acknowledge that;
                            <ul>
                                <li>applicable rates for each widget are given in the description and the nature of applicable charges may vary from solution to solution.</li>
                                <li>applicable rates are specified in the description of the widget which is accessible prior to purchasing or enabling the solution to your enterprise.</li>
                                <li>any dispute or discrepancy on the transaction fees or other charges subject to the Services provided in this Platform shall be brought to Dialogs’ attention within One (01) month from the received date of your invoices.</li>
                                <li>Dialog shall make all endeavors to settle or resolve such dispute and that the decision of Dialog with regards to such disputes shall be considered final and conclusive.</li>
                                <li>all Services provided in the Platform shall be subject to a defined tariff.</li>
                                <li>all rates provided herein shall be subject to the applicable government taxes.</li>
                                <li>all payments shall be based on the monthly bill generated by Dialog systems and logs based on your activity.</li>
                                <li>the transaction logs available with Dialog shall be deemed as an authentic source of information for any dispute and the information presented from the said logs shall be final and conclusive evidence for any dispute resolution.</li>
                                <li>add to bill option is exclusively for users with a Dialog Subscription.</li>
                                <li>all payments shall be settled by the User within Thirty (30) calendar days.</li>
                                <li>in the event of default payment by a User, Dialog shall with immediate effect discontinue the solution and any other service provided in the Platform and Dialog standard credit actions on Non-payment of the connectivity.</li>
                            </ul>    
                        </li>
                        <li>Proprietary rights
                            <ul>
                                <li>All Trade Marks used on the Platform and/or the Services are the trade marks of Dialog or one of Dialog’s affiliates or partnered companies. You shall only make fair use of the Trade Marks and will not use the Trade Marks, whether design or word marks:</li>
                                <ul>
                                    <li>as or as part of your own trade marks.</li>
                                    <li>in a manner which is likely to cause confusion.</li>
                                    <li>to identify products to which they do not relate.</li>
                                    <li>to imply endorsement or otherwise of products or Services to which they do not relate; or</li>
                                    <li>in any manner which does or may cause damage to the reputation of Dialog or the Trade Marks.</li>
                                </ul>
                                <li>You acknowledge and agree that the Services and the Platform or any part thereof, whether presented to you by Dialog, advertisers or any third party are protected by copyrights, trademarks, service marks, patents, or other proprietary rights and laws. All rights are expressly reserved.</li>
                                <li>You are only allowed to use the Platform and the Services as set out in these Terms and Conditions and nothing on the Platform and/or the Services shall be construed as conferring any licence or other transfer of rights to you of any intellectual property or other proprietary rights of Dialog, any member of the Dialog partnerships or any third party, whether by estoppel, implication or otherwise.</li>
                                <li>You hereby grant to Dialog a perpetual, irrevocable, royalty-free, world-wide licence to reproduce, transfer, modify, adapt and/or publish any Content provided by you to us by email, post or otherwise on the Platform and/or the Services as Dialog sees fit and without notice to you, unless you have indicated otherwise in such communication.</li>
                            </ul>    
                        </li>
                        <li>Liability for content
                            <ul>
                                <li>It is your sole responsibility to ensure yourself prior to using the Platform and the Services in any way that they are suitable for your purposes and up to date. The Services and in particular, prices are periodically updated and you should check the Platform and the Services regularly to ensure that you have the latest information. You should also refresh your browser each time you visit the Platform and the Services to ensure that you download the most up to date version of the Platform and the Services.</li>
                                <li>The Platform and the Services are provided on an “as is” basis. Although every effort has been made to provide accurate information on these pages, neither Dialog, nor any of its employees, nor any member of the Dialog’s affiliate or partner companies, their suppliers, nor any of their employees, make any warranty, expressed or implied, or assume any legal liability (to the extent permitted by law) or responsibility for the suitability, reliability, timeliness, accuracy or completeness of the Services or any part thereof contained on the Platform or in the Services.</li>
                                <li>You agree and acknowledge that Dialog is unable to exercise control over the security or subject matter of Content passing over the Dialog Network, the Platform or via the Services and Dialog hereby excludes all liability of any kind for the transmission or reception of infringing Content of whatever nature.</li>
                            </ul>    
                        </li>
                        <li>Liability for third party content
                            <ul>
                                <li>The Platform contains links to websites, web pages, products and services also operated or partnered by Dialog and you agree that your use of each web site, web page, products and services is also subject to the terms and conditions, if any, contained within each website or webpage or attached to any products or services. These Terms and Conditions shall be deemed as incorporated into each set of terms and conditions. In the event that there is any conflict, the terms and conditions contained within the relevant website or web page or attached to the relevant products or services shall prevail over these Terms and Conditions.</li>
                                <li>Dialog assumes no responsibility for and does not endorse unless expressly stated, Content created or published by third parties that is included in the Platform, products and the Services or which may be linked to and from the Platform.</li>
                                <li>The Platform and/or the Services may be used by you to link into other websites, resources and/or networks worldwide. Dialog accepts no responsibility for the Content, products, Services or otherwise in respect of these and you agree to conform to the acceptable use policies of such websites, resources and/or networks.</li>
                                <li>Subject to Clause 13, you agree that Dialog does not generally and is not required to monitor or edit the use to which you or others use the Platform and the Services or the nature of the Content and Dialog is excluded from all liability of any kind arising from the use of the Services, and in particular but without limitation to the foregoing, the nature of any Content. Notwithstanding the foregoing, Dialog reserves the right to edit, bar or remove any Services and/or Content, at any time as Dialog in its sole discretion believes to be necessary in order to prevent any breach of these Terms and Conditions or any breach of applicable laws or regulations.</li>
                            </ul>    
                        </li>
                        <li>Exclusion of liability
                            <ul>
                                <li>Dialog shall use its reasonable endeavours to ensure the maintenance and availability of the Platform, Products and the Services but availability may be affected by your equipment, other communications networks, user congestion of the Dialog Network or the Internet at the same time or other causes of interference and may fail or require maintenance without notice.</li>
                                <li>Neither Dialog nor any member of the Dialog shall be liable for any special, indirect or consequential damages or any damages whatsoever, whether in an action of contract, negligence or other tortuous action, arising out of or in connection with the performance of or use of Services available on the Platform and in particular, but without limitation to the foregoing, Dialog specifically excludes all liability whatsoever in respect of any loss arising as a result of:</li>
                                <ul>
                                    <li>use which you make of the Platform, Products and the Services or reliance on Services or any loss of any Services or your Content resulting from delays, non-deliveries, missed deliveries, or service interruptions; and;</li>
                                    <li>defects that may exist or for any costs, loss of profits, loss of your Content or consequential losses arising from your use of, or inability to use or access or a failure, suspension or withdrawal of all or part of the Platform, Products and the Services at any time.</li>
                                </ul>
                                <li>All conditions or warranties which may be implied or incorporated into these Terms and Conditions by law or otherwise are hereby expressly excluded to the extent permitted by law.</li>
                                <li>Your only remedy under these Terms and Conditions is to discontinue using the Platform, Products and the Services.</li>
                                <li>Dialog makes every effort to ensure the security of your communications. You are however advised that for reasons beyond our control, there is a risk that your communications may be unlawfully intercepted or accessed by those other than the intended recipient. For example, your communications may pass over third-party networks over which we have no control. The Internet is not a secure environment. Unwanted programs or material may be downloaded without your knowledge, which may give unauthorized persons access to your mobile phone and the information stored on your mobile phone. These programs may perform actions that you have not authorized, possibly without your knowledge.</li>
                            </ul>    
                        </li>
                        <li>Variation
                            <ul>
                                <li>Dialog reserves the right to modify the Platform, Products and/or the Services or suspend or terminate the Platform, Products and / or the Services or access to part or all of them at any time.</li>
                                <li>Dialog reserves the right to revise these Terms and Conditions at any time. Such variations shall become effective two weeks after being posted on the website. By continuing to use this website you will be deemed to have accepted the varied Terms and Conditions.</li>
                            </ul>    
                        </li>
                        <li>Indemnity
                            <ul>
                                <li>You hereby agree to fully indemnify and to hold Dialog harmless from and against any claim brought by a third party resulting from the use of the Platform, Products and the Services or the provision of Content to Dialog by you and in respect of all losses, costs, actions, proceedings, claims, damages, expenses (including reasonable legal costs and expenses), or liabilities, whatsoever suffered or incurred directly or indirectly by Dialog in consequence of such use of the Platform, Products and the Services or provision of Content or your breach or non-observance of any of these Terms and Conditions.</li>
                                <li>You shall defend and pay all costs, damages, awards, fees (including any reasonable legal fees) and judgments awarded against Dialog arising from the above claims and shall provide Dialog with notice of such claims, full authority to defend, compromise or settle such claims and reasonable assistance necessary to defend such claims, at your sole expense.</li>
                            </ul>    
                        </li>
                        <li>Monitoring / recording of communications
                            <ul>
                                <li>Monitoring or recording of your calls, emails, text messages or other communications may take place when required in accordance with the law, and in particular for Dialog’s business purposes, such as for quality control and training, to prevent unauthorized use of Dialog’s telecommunication systems and to ensure effective systems operation and in order to prevent or detect crime.</li>
                            </ul>    
                        </li>
                        <li>Processing your instructions
                            <ul>
                                <li>You request and authorize us to rely and act upon all apparently valid communications as instructions properly authorized by you, even if they may conflict with any other instructions given at any time concerning bill or service requirements.</li>
                                <li>An instruction will only be accepted by us if you have passed through certain security criteria.</li>
                                <li>You agree that we can act on any instructions given to us even if these instructions were not authorized by you.</li>
                                <li>We will make reasonable efforts to process any instruction where you request us to do so but we shall not be liable for any failure to comply with such a request unless it is due to Dialog’s failure to make reasonable efforts to do so.</li>
                                <li>You must make sure that any instruction is accurate and complete and we are not liable if this is not the case.</li>
                                <li>A transaction being carried out is not always simultaneous with an instruction being given. Some matters may take time to process and certain instructions may only be processed during normal working hours even though the service may be accessible outside such hours.</li>
                                <li>You will be responsible for all losses and payments (including the amount of any transaction carried out without your authority) if you have acted with gross negligence so as to facilitate that unauthorised transaction, or you have acted fraudulently. For the purposes of this Clause gross negligence shall be deemed to include failure to observe any of your security duties referred to in these Terms and Conditions.</li>
                            </ul>    
                        </li>
                        <li>Termination
                            <ul>
                                <li>Dialog may elect to suspend, vary or terminate the Services, Products and the Platform at any time without prior notice for repair or maintenance work or in order to upgrade or update the Platform, Products and the Services or for any other reason whatsoever.</li>
                                <li>Dialog may elect to terminate the Services or your access to the Platform and Products forthwith on breach of any of these Terms and Conditions by you, including, without limitation, delayed or non payment of sums due to Dialog or if Dialog ceases to offer the Platform, Products and the Services to its customers for any reason whatsoever.</li>
                            </ul>    
                        </li>
                        <li>General
                            <ul>
                                <li>Governing Law and Jurisdiction – These Terms and Conditions are governed by and construed in accordance with English law and you hereby submit to the non-exclusive jurisdiction of the Sri Lankan courts.</li>
                                <li>Severability – These Terms and Conditions are severable in that if any provision is determined to be illegal or unenforceable by any court of competent jurisdiction such provision shall be deemed to have been deleted without affecting the remaining provisions of these Terms and Conditions.</li>
                                <li>Waiver – Dialog’s failure to exercise any particular right or provision of these Terms and Conditions shall not constitute a waiver of such right or provision unless acknowledged and agreed to by Dialog in writing.</li>
                                <li>Representations – You acknowledge and agree that in entering into these Terms and Conditions you do not rely on, and shall have no remedy in respect of, any statement, representation, warranty or understanding (whether negligently or innocently made) of any person (whether party to these Terms and Conditions or not) other than as expressly set out in these Terms and Conditions as a warranty. Nothing in this Clause shall, however, operate to limit or exclude any liability for fraud.</li>
                                <li>Assignment – You in entering into these Terms and Conditions undertake that you will not assign, re-sell, sub-lease or in any other way transfer your rights or obligations under these Terms and Conditions or part thereof. Contravention of this restriction in any way, whether successful or not, will result in the Services being terminated by Dialog forthwith. Dialog may assign these Terms and Conditions in whole or in part to any third party at its discretion.</li>
                                <li>Rights of Third Parties – Except in the case of any permitted assignment of this Agreement under Clause 16.5, a person who is not a party to this Agreement has no right of enforcement of any term or condition contained in this Agreement.</li>
                                <li>Force Majeure – Dialog shall not be liable in respect of any breach of these Terms and Conditions due to any cause beyond its reasonable control including but not limited to, Act of God, pandemic, epidemic, inclement weather, act or omission of Government or public telephone operators or other competent authority or other party for whom Dialog is not responsible.</li>
                            </ul>    
                        </li>
                    </ul>
                    </div>
                    <div className="form-group__wrapper terms-checks__wrapper">
                        <div className="form-check mb-3">
                            <input className="form-check-input" type="checkbox" defaultValue="" id="flexCheckDefault1" disabled={progress == 100} checked={isReadTnC} onChange={() => setIsReadTnC(!isReadTnC)} />
                            <label className="form-check-label" htmlFor="flexCheckDefault1">
                                I do hereby agree to create this enterprise account and to abide by the mentioned Privacy policy & Terms and Conditions.
                            </label>
                        </div>
                        <div className="form-check mb-3">
                            <input className="form-check-input" type="checkbox" defaultValue="" id="flexCheckDefault2" disabled={progress == 100} checked={isReadAgreement} onChange={() => setIsReadAgreement(!isReadAgreement)} />
                            <label className="form-check-label" htmlFor="flexCheckDefault2">
                                I do have carefully read the
                            </label>
                            { userDetails && userDetails.role && userDetails.role == "mp_enterprise_owner" ? 
                            <a href="/agreement" target="_blank"> <u>Agreement</u> </a> : 
                            <a href="/maagreement" target="_blank"> <u>Agreement</u> </a>
                            }
                            <label className="form-check-label" htmlFor="flexCheckDefault2">
                                and understood the terms to my full consideration.
                            </label>
                        </div>
                    </div>
                    <div className="single-wiz-step-footer">
                        {/* <button className="btn btn-secondary">Cancel</button> */}
                        <Spin tip="" size="small" spinning={loading}>
                            {progress == 75 && <button className="btn btn-primary" onClick={onSave} disabled={!isReadAgreement || !isReadTnC}>Submit for approval</button>}
                        </Spin>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { TnCSection }