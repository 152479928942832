import React, { useState } from "react";

const ManageCampaignTnC = () => {
    return (
        <React.Fragment>
            <div className="scroller__wrapper">
                <div className="terms__content">
                    <p className='manage-campaign-agreement-small-text' >This Agreement is entered into by and between <b>ADA DIGITAL SINGAPORE PTE. LTD.</b>, a company incorporated in the Republic of Singapore having its Registered Office at 112 Robinson Road, #10-10, Robinson 112, Singapore (068902) bearing company registration number 201405137H and acting through its registered place of business in Sri Lanka (“ADA”) and the <b>Client</b> on [Date of Execution of the Agreement]</p>
                    <p><b>In terms of this Agreement the Client is prohibited from disseminating Prohibited Content as set out below;</b></p>
                    <ul>
                        <li>1. All Advertisements/Content and/or advertising related programmes submitted to ADA shall not;
                            <ul>
                                <li>1.1directly or indirectly contain information, views, stimulations and/ or images of politics, matrimonial agencies, clubs, unlicensed employment services bureaus, betting tips, betting, tobacco, alcohol, religion, medicine, obscenity and/or any other illegal/ antisocial subject matter;</li>
                                <li>1.2 infringe the intellectual property rights of any third party;</li>
                                <li>1.3 contain personal testimonial with specific reference to the character of a person;</li>
                                <li>1.4 contain anything which clearly offends against generally prevailing community standards relating to decency;</li>
                                <li>1.5 libel, defame, cause injury to, invade the privacy of or otherwise infringe or violate the rights of any Recipient or third party;</li>
                                <li>1.6 contain false or unwarranted claims for any products or services or any defamatory statements;</li>
                                <li>1.7 include any statement, picture, audio or video that promotes the services of any entity specified in writing by ADA. </li>
                            </ul>

                        </li>
                        <br/>
                        <li>The Client shall also comply with and adhere to such guidelines and directions as may be provided by ADA in writing from time to time, which shall form part and parcel of this Agreement.</li>
                        <li>2. The prohibited content shall not be limited to the above and ADA reserves the right to, at its sole discretion, regard any Advertisement or Content as prohibited content and refuse to disseminate such Advertisement.</li>
                        <br/>
                        <p><b>The Client is responsible for the following obligations and the breach of any of these may result in ADA discontinuing the Client from using/accessing the Digital Advertising Platform;</b> The Client shall:</p>
                        <li>
                            <ul>
                                <li>a) not use the Platform to transmit Prohibited Content as set out above in Clause 1 in the Front Sheet;  </li>
                                <li>b) not use the Platform to commit acts of terror, immoral, unauthorised or unlawful activities and/or for any improper, unlawful or abusive purpose and/or to send obscene or threatening messages;  </li>
                                <li>c) keep all Recipient information confidential and shall not use such information for any other purpose except for the purpose of fulfilling its obligations hereunder  </li><br/>
                                <li>d) strictly ensure that Advertisements disseminated as SMS and/or MMS through the Platform are ONLY used to contact Recipients and/or any third parties who have expressly consented to be contacted via the Digital Advertising Platform;  </li>
                                <li>e) ensure that each and every Advertisement/Content transmitted through the Platform is approved and monitored by  </li><br/>
                                <li>ADA prior to such release;</li>
                                <li>f) bear all stamp duty, service tax charges and any other cost or charge imposed by law from time to time with regard to the usage of the Platform;</li>
                                <li>g) not resell, transfer, share and/or reassign the Advertisement space and/or disseminate any Advertisement on behalf of a third party in any circumstances and/or use the Platform for any other purpose than that provided hereunder unless otherwise with the prior written consent of ADA;</li>
                                <li>h) ensure that only its employees who are authorized and trained to access the Platform shall use the same to transmit</li><br/>
                                <li>Advertisements;</li>
                                <li>i) ensure that any information relating to accessing and using the Platform, including but without limitation passwords, shall not be shared with any third party other than its authorized employees;</li>
                                <li>j) not use, reproduce, and/or display any trademarks, service marks, logos and brand names of ADA for any other promotional or advertising material without the prior written consent of ADA;</li>
                                <li>k) inform ADA in writing of any changes to any information provided to ADA.</li>
                            </ul>
                        </li>

                    </ul>

                    <p className='manage-campaign-agreement-small-text' >The Parties agree to abide by the terms and conditions of this Agreement and understand that the provision of the services of the Digital Advertising Platform is subject to the rules and regulations of Sri Lanka and/or any other regulatory or governmental authority of Sri Lanka. </p>
                    <ul>
                        <li><b>1. Rights and Responsibilities of ADA</b></li>
                        <li>
                            <ul>
                                <li>1.1 ADA reserves the right to:  </li>
                                <li>
                                    <ul>
                                        <li>1.1.1. reject any application or Advertisement submitted for the Platform for any reason whatsoever without assigning any reason and without any liability;  </li>
                                        <li>1.1.2. make any alteration to the Platform and such alteration shall be informed to the Client in writing prior to dissemination. ADA shall not be liable for any loss or inconvenience caused to the Client resulting therefrom;  </li>
                                        <li>1.1.3. vary, add to or amend the terms and conditions applicable to the Platform;  </li>
                                        <li>1.1.4. at anytime, share their database/ specific Client information with any one of its subsidiaries and/or holding companies in order to provide an integrated service to the Clients and/or with any Government authority in connection with any criminal investigation or for the purpose of any criminal proceedings.  </li>
                                    </ul>
                                </li>
                                <li>1.2 ADA shall execute the managed campaign as morefully set out in Schedule 1 (Managed Campaign Execution Process) hereto.  </li>
                                <li>1.3 ADA shall at its sole discretion be responsible for the implementation and maintenance of the technical infrastructure related to the Platform to allow Recipients to receive the Advertisements.  </li>
                                <li>1.4 ADA shall not be liable to the Client and/or any third party for any loss, damage or injury caused to or suffered by a person or property, whether it be direct, indirect, special, consequential, loss of business revenue or profits, or of any nature suffered by any Client or any person authorized by any Client in relation to the Platform. </li>

                            </ul>
                        </li><br/>
                        <li><b>2. Payment Terms  </b></li>
                        <li>
                            <ul>
                                <li>2.1. All charges and payments, where applicable, shall be as per the prices and payment terms set out in Schedule 2 (Price Schedule) hereto.  </li>
                                <li>2.2. All taxes, duties and levies applicable under this Agreement and / or on the payments made hereunder shall be borne and /or discharged by the Party charged with tax, in accordance with the relevant statute and /or amendment thereto.  </li>
                                <li>2.3. All prices and/or payments under this Agreement do not include the cost of Nation Building Tax (“NBT”) at prevailing rates and Value Added Taxes (“VAT”) imposed under the Value Added Tax Act, No 14 of 2002 and amendments thereto, which shall be added to the prices charged to the Client by ADA, provided however that VAT on such prices shall only be paid by the Client on the submission of a valid tax invoice by ADA as per the provisions contained in the Value Added Tax Act, No 14 of 2002, as amended from time to time. Provided further that the Client retains the right to reclaim from ADA such amounts charged by ADA in the event ADA is not registered for such taxes and / or in the event input credit on any VAT paid to ADA is disallowed by the Department of Inland Revenue on account of error, omission, mistake / misstatement and /or lapse of compliance with the VAT Act on the part of ADA without any time limit.  </li>
                                <li>2.4. In the event of any amendment to an existing statute by-law or regulation / ruling or introduction of any new tax during the Term of this Agreement, the Parties hereto shall follow the provisions of such amendment or new statute in accordance with its effective date of application.  </li>
                                <li>2.5. Where one Party is required under law to withhold and remit any tax or part thereof of the other Party, such withholding and remittance shall be done with prior notice to the other and subject to the receipt of any direction issued by the relevant authority at such time of withholding.  </li>
                                <li>2.6. Each Party shall also be responsible to obtain all relevant registrations, waivers and directions (including directions in respect of withholding tax) with regard to such taxes, duties and levies with the assistance of the other Party, where necessary. Neither Party shall suffer any additional tax, duty or levy on account of the failure or omission of the other Party to obtain such registration, waiver or direction.  </li>
                                <li>2.7. The Advertising Charges may be varied by ADA from time to time and such variation shall be informed to the Client in writing within a reasonable time period in advance </li>
                            </ul>
                        </li><br/>
                        <li><b>3. Indemnification  </b></li>
                        <li>
                            <ul>
                                <li>3.1 Client hereby agrees to defend, settle and pay damages on behalf of ADA and the Recipients with regard to any and all claims, actions, liabilities, losses, expenses, damages, and costs (including, without limitation, reasonable attorneys' fees) that may at any time be incurred by any of them by reason of any claims, suits or proceedings made by a third party for libel, defamation, violation of right of privacy or publicity, breach of contract, copyright infringement, trademark infringement or other infringement of any third party right, fraud, false advertising, misrepresentation, product liability or violation of any law, statute, ordinance, rule or regulation. </li>
                            </ul>
                        </li><br/>
                        <li><b>4. Confidentiality  </b></li>
                        <li>
                            <ul>
                                <li>4.1 Each Party hereto shall use its best efforts to keep in strict <small className='manage-campaign-agreement-small-text'>confidence, and shall bind all of its employees and agents to keep in strict confidence, all commercial and technical information in whatever form acquired by it (whether directly or indirectly) from or concerning the other Party or in connection with the performance of the Agreement (hereinafter called "Confidential Information"). No Party shall utilise such Confidential Information or at any time disclose any Confidential Information to any third party for any purposes other than those contemplated herein. </small></li>
                                <li>4.2 The following information shall be excluded from the foregoing scope of Confidential Information:  </li>
                                <li>
                                    <ul>
                                        <li>4.2.1 information which at the time of disclosure is generally <small className='manage-campaign-agreement-somewhat-larger-text'>available to the public;</small> </li>
                                        <li>4.2.2 information which after disclosure becomes generally available to the public through no fault of the receiving party;  </li>
                                        <li>4.2.3 information which the receiving party can show was in its possession prior to disclosure and which was not acquired directly or indirectly from the other Party;  </li>
                                        <li>4.2.4 information which the receiving party can show was received by it after the time of disclosure from any third Party without any obligation of confidentiality and which was not acquired directly or indirectly from the other Party; or  </li>
                                        <li>4.2.5 information which the Party concerned shall be compelled to divulge if required by Law.  </li>
                                    </ul>
                                </li>
                                <li>4.3 Any information provided or divulged by ADA to the Client under this Agreement shall be the sole and exclusive property of ADA and the Client shall not in any event whatsoever derive any right or license to use such information for such other purpose other than as specifically set out herein.  </li>
                                <li>4.4 The confidentiality obligations set out in this Clause shall survive the termination of this Agreement. </li>
                            </ul>
                        </li><br/>
                        <li><b>5. Force Majeure  </b></li>
                        <li>
                            <ul>
                                <li>5.1 If either Party is temporarily rendered unable, wholly or in part, by Force Majeure to perform its duties or accept performance by the other Party under this Agreement it is agreed that the affected Party shall give notice to the other Party with immediate effect giving full particulars of such Force Majeure.  </li>
                                <li>5.2 The duties of such Party as are affected by such Force Majeure shall, with the approval of the other Party, be suspended during the period of disability so caused, but for no longer period, and such cause shall be removed with all reasonable dispatch.  </li>
                                <li>5.3 The term "Force Majeure" as employed herein shall mean acts of God, strikes, lockouts, industrial disturbances, war, blockades, insurrections, riots, epidemics, civil disturbances, explosions, fire, floods, earthquakes, storms, lightning, electrical power failure and any other causes similar to the kind herein enumerated which are beyond the control of any Party and which by the exercise of due care and diligence neither Party is able to overcome.</li>
                            </ul>
                        </li><br/>
                        <li><b>6. Termination and the Consequences of Termination  </b></li>
                        <li>
                            <ul>
                                <li>6.1. Either Party by giving fourteen (14) Days’ notice in writing to the other, without prejudice to any other rights herein, may terminate this Agreement at the occurrence of any of the following circumstances.  </li>
                                <li>
                                    <ul>
                                        <li>6.1.1. If the other commits a breach of any of the Terms and Conditions of this Agreement and such breach is not remedied within thirty (30) Days from notification thereof to the Party in breach;  </li>
                                        <li>6.1.2. If the other Party becomes insolvent, goes into voluntary or compulsory liquidation or passes an effective resolution for winding up or makes an arrangement or composition with its creditors, or if any receiver be appointed on behalf of debenture holders or otherwise;  </li>
                                    </ul>
                                </li>
                                <li>6.2. Without prejudice to any other rights herein stipulated either Party may terminate this Agreement at any time by giving sixty (60) Days written notice without giving any reason whatsoever.  </li>
                                <li>6.3. ADA reserves the right to terminate this Agreement at any time if the provision of the said Service/s seeks to create mischief, has the tendency to mislead the public and /or contravenes any provisions of prevalent rules and regulations of Sri Lanka.  </li>
                                <li>6.4. In the event of termination of this Agreement, the Client shall no longer have access to the Digital Advertising Platform and the Client shall immediately settle all dues owed to ADA. </li>
                            </ul>
                        </li><br/>
                        <li><b>7. Miscellaneous  </b></li>
                        <li>
                            <ul>
                                <li>7.1. In the event of a dispute or difference in connection with the usage of the Platform, ADA and the Client shall use their best endeavours to resolve such dispute or difference amicably. Failing amicable resolution of such dispute or difference by the Parties, the dispute or difference shall then be finally resolved by reference to the courts of Law of Sri Lanka.  </li>
                                <li>7.2. All conditions, warranties and representations implied by law in relation to the Digital Advertising Platform are excluded to the extent permitted by law and except as expressly provided in these terms and conditions.  </li>
                                <li>7.3. These terms and conditions shall be governed by the Laws of Sri Lanka and subject to the exclusive jurisdiction of the courts of Sri Lanka.  </li>
                                <li>7.4. The English terms and conditions shall prevail over the Sinhala and Tamil translations (if any) </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </React.Fragment>
    )

}
export { ManageCampaignTnC };