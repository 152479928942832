import React, { useEffect, useState, FocusEvent } from 'react'
import { ProgressBar, AccountTypeSection, BusinessProfileSection, TnCSection, ProgressDescriptionBox, DoneButtonComponent } from './components'
import AuthHandler from '../../helper/AuthHandler';
import SimpleReactValidator from 'simple-react-validator';
import {
  mobileModel,
  mobileVendor,
  browserName,
  osVersion,
  osName,
  isMobile
} from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from '../../store';
import { AccountTypeModel } from './models/request/account-type.model';
import { submitAccountType, fetchUserDetails, submitProfile, submitBusinessDetails, submitEnterprise, checkDuplicate, submitMsisdn, submitMsisdnOtp, fetchRedisStatus, fetchProfileStatus, editBusinessProfileDetails, enterpriseResubmit } from '../../store/actions';
import { useSelector } from "react-redux";
import Auth from '../../models/response/auth.model';
import { Profile } from './models/request/profile.model';
import { BusinessProfile } from './models/request/business-profile.model';
import { Enterprise } from './models/request/enterprise.model';
import { DuplicateCheck } from './models/request/duplicate-check.model';
import { getBanks, getNicBrcDuplicateCheck, getEmailDuplicateCheck } from '../../services/user.service';
import { AccountCreationSectionsEnums, EmailStatusEnums, MsisdnStatusEnums } from '../../enum';
import axios from 'axios';
import { DeviceDetails } from './models/request/device-details.model';
import { YourProfileSectionUpdated } from './components/YourProfileSectionUpdated';
import { UserRoleSelection } from './components/UserRoleSelection';
import { notification } from 'antd';
import { Console } from 'console';
import Keycloak from 'keycloak-js';
import * as CONSTANTS from '../../constants';
import { Events } from './components/Events';


type Props = {
  auth: Auth;
  isLogin: boolean;
  setAuth: (value: React.SetStateAction<Auth>) => void;
}

const Home = ({ auth, isLogin, setAuth }: Props) => {

  const data = {
    districtList: ["Ampara", "Anuradhapura", "Badulla", "Batticaloa", "Colombo", "Galle", "Gampaha", "Hambantota", "Jaffna", "Kalutara", "Kandy", "Kegalle", "Kilinochchi", "Kurunegala", "Mannar", "Matara", "Matale", "Monaragala", "Mullaitivu", "Nuwara Eliya", "Polonnaruwa", "Puttalam", "Ratnapura", "Trincomalee", "Vavuniya"]
  }

  const [screen, setScreen] = useState("home")
  const [status, setStatus] = useState("") //"PROVISIONING", "processing", "Submitted", "Active"
  const [progress, setProgress] = useState(0)
  const [profileStatus, setProfileStatus] = useState("pending")
  const [accountStatus, setAccountStatus] = useState("locked")
  const [businessStatus, setBusinessStatus] = useState("locked")
  const [tncStatus, setTncStatus] = useState("locked")
  const [showAccType, setShowAccType] = useState(false)
  const [showYourProfile, setShowYourProfile] = useState(true)
  const [showBusinessProfile, setShowBusinessProfile] = useState(false)
  const [isOwnerSame, setIsOwnerSame] = useState(false)
  const [showTnC, setShowTnC] = useState(false);
  const [userFirstName, setUserFirstName] = useState('')
  const [districtList, setDistrictList] = useState<string[]>([])
  const [showMsisdnOtpField, setShowMsisdnOtpField] = useState(false)
  const [intervalObj, setIntervalObj] = useState<string | number | NodeJS.Timeout | undefined>()
  const [otp, setOtp] = useState({
    pin: "",
    serverRef: ""
  })
  const [isReadTnC, setIsReadTnC] = useState(false);
  const [isReadAgreement, setIsReadAgreement] = useState(false);
  const [nicCopy, setNicCopy] = useState<any>([]);
  const [nicError, setNicError] = useState("");
  const [brcError, setBrcError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [logoUploadSizeError, setLogoUploadSizeError] = useState("");
  const [brUploadSizeError, setBrUploadSizeError] = useState("");

  const [logo, setLogo] = useState<any>([]);
  const [brDoc, setBrDoc] = useState<any>([]);
  const [industryTypeList, setIndustryTypeList] = useState<string[]>([])

  const [accountType, setAccountType] = useState("");
  const [selectedPartnerUuid, setSelectedPartnerUuid] = useState("");

  const [selectPartnerErr, setSelectPartnerErr] = useState(false);
  const [isBusinessProfileOnSave, setIsBusinessProfileOnSave] = useState(false);

  const [deviceDetails, setDeviceDetails] = useState<DeviceDetails>({
    ipAddress: "",
    device: "",
    os: "",
    osVersion: "",
    browser: ""
  });

  const [profileDetails, setProfileDetails] = useState({
    first_name: "",
    last_name: "",
    email: "",
    emailStatus: "",
    msisdn: "",
    msisdnStatus: "",
    nic: ""
  });
  const [businessDetails, setBusinessDetails] = useState({
    business_name: "",
    business_email: "",
    business_description: "",
    business_reg_number: "",
    //industry: "",
    verificationDoc: "",
    number_and_lane: "",
    street: "",
    city: "",
    district: "",
    ownerNicNumber: "",
    ownerNICCopy: "nic",
    ownerNICCopy2: "",
    entBussinessRegDoc1: "",
    entBussinessRegDoc2: "",
    entBussinessRegDoc3: "",
    logo: "",
    industry: "",
    ownerFirstName: "",
    ownerLastName: "",
    ownerContact: "",
    selectedPartner: "",
    responsivePersonNo: "",
    responsivePersonName: ""
  });

  const [ownerDetails, setOwnerDetails] = useState({
    owner_first_name: "",
    owner_last_name: "",
    owner_mobile: ""
  });

  const [maDetails, setMaDetails] = useState({
    expertise: "",
    experience: "",
    personal_description: "",
    specification: "",
    account_holder: "",
    account_number: "",
    role: "",
    employee_id: "",
    employee_email: ""
  });

  const [bankList, setBankList] = useState([]);
  const [bankCode, setBankCode] = useState("");
  const [bankName, setBankName] = useState("");
  const [branchList, setBranchList] = useState([]);
  const [branchCode, setBranchCode] = useState("");
  const [branchName, setBranchName] = useState("");

  const [isBusinessProfileEdit, setIsBusinessProfileEdit] = useState(false);
  const [isEntRejected, setIsEntRejected] = useState(false);
  const [resetDispatched, setResetDispatched] = useState(false);
  const [error, setError] = useState('');

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [profileDetailValidator] = useState(new SimpleReactValidator({
    messages: {
      required: 'You can’t keep this as empty.',
      min: 'This should not be less than :min:type.',
      max: 'Maximum :max:type are allowed.',
      email: 'This does not look like an email.',
    },
    validators: {
      dialog: {
        message: 'The number you entered is invalid.',
        rule: (val: any, params?: any, validator?: any): boolean => {
          return validator.helpers.testRegex(val, /^([0]{1}[7]{1}[764]{1}\d{7}|[7]{1}[764]{1}\d{7})$/) && params.indexOf(val) === -1
        }
      },
      all_mobiles: {
        message: 'The entered :attribute number is invalid.',
        rule: (val: any, params?: any, validator?: any): boolean => {
          return validator.helpers.testRegex(val, /^[0]{1}[123456789]{2}\d{7}|[123456789]{2}\d{7}$/) && params.indexOf(val) === -1
        }
      },
      alpha_num_underscore: {
        message: 'The :attribute is invalid.',
        rule: (val: any, params?: any, validator?: any): boolean => {
          return validator.helpers.testRegex(val, /^[a-zA-Z0-9_]*$/) && params.indexOf(val) === -1
        }
      },
      alpha_num_underscore_space: {
        message: 'The :attribute is invalid.',
        rule: (val: any, params?: any, validator?: any): boolean => {
          return validator.helpers.testRegex(val, /^[a-zA-Z0-9_\s]*$/) && params.indexOf(val) === -1
        }
      },
      alpha_num_underscore_dash_space: {
        message: 'The :attribute is invalid.',
        rule: (val: any, params?: any, validator?: any): boolean => {
          return validator.helpers.testRegex(val, /^[a-zA-Z0-9_',.\s-]*$/) && params.indexOf(val) === -1
        }
      },
      address: {
        message: 'The :attribute is invalid.',
        rule: (val: any, params?: any, validator?: any): boolean => {
          return validator.helpers.testRegex(val, /^[a-zA-Z0-9.,/\s-]*$/) && params.indexOf(val) === -1
        }
      },
      nic: {
        message: 'The entered :attribute is invalid.',
        rule: (val: any, params?: any, validator?: any): boolean => {
          return validator.helpers.testRegex(val, /^([0-9]{9}[x|X|v|V]|[0-9]{12})$/) && params.indexOf(val) === -1
        }
      }
    },
    autoForceUpdate: this
  }));
  const [businessDetailValidator] = useState(new SimpleReactValidator({
    messages: {
      required: 'You can’t keep this as empty.',
      min: 'This should not be less than :min:type.',
      max: 'Maximum :max:type are allowed.',
      email: 'This does not look like an email.',
    },
    validators: {
      dialog: {
        message: 'The number you entered is invalid.',
        rule: (val: any, params?: any, validator?: any): boolean => {
          return validator.helpers.testRegex(val, /^([0]{1}[7]{1}[764]{1}\d{7}|[7]{1}[764]{1}\d{7})$/) && params.indexOf(val) === -1
        }
      },
      all_mobiles: {
        message: 'The entered :attribute number is invalid.',
        rule: (val: any, params?: any, validator?: any): boolean => {
          return validator.helpers.testRegex(val, /^[0]{1}[123456789]{2}\d{7}|[123456789]{2}\d{7}$/) && params.indexOf(val) === -1
        }
      },
      alpha_num_underscore: {
        message: 'The :attribute is invalid.',
        rule: (val: any, params?: any, validator?: any): boolean => {
          return validator.helpers.testRegex(val, /^[a-zA-Z0-9_]*$/) && params.indexOf(val) === -1
        }
      },
      alpha_num_underscore_space: {
        message: 'The :attribute is invalid.',
        rule: (val: any, params?: any, validator?: any): boolean => {
          return validator.helpers.testRegex(val, /^[a-zA-Z0-9_\s]*$/) && params.indexOf(val) === -1
        }
      },
      alpha_num_underscore_dash_space: {
        message: 'The :attribute is invalid.',
        rule: (val: any, params?: any, validator?: any): boolean => {
          return validator.helpers.testRegex(val, /^[a-zA-Z0-9_',.\s-]*$/) && params.indexOf(val) === -1
        }
      },
      address: {
        message: 'The :attribute is invalid.',
        rule: (val: any, params?: any, validator?: any): boolean => {
          return validator.helpers.testRegex(val, /^[a-zA-Z0-9.,/\s-]*$/) && params.indexOf(val) === -1
        }
      },
      nic: {
        message: 'The entered :attribute is invalid.',
        rule: (val: any, params?: any, validator?: any): boolean => {
          return validator.helpers.testRegex(val, /^([0-9]{9}[x|X|v|V]|[0-9]{12})$/) && params.indexOf(val) === -1
        }
      },
      exp_years: {
        message: 'The entered :attribute number is invalid.',
        rule: (val: any, params?: any, validator?: any): boolean => {
          return validator.helpers.testRegex(val, /^[0-9]{1,2}$/) && params.indexOf(val) === -1
        }
      }
    },
    autoForceUpdate: this
  }))

  const {
    userDetails,
    accountTypeSubmitResponse,
    profileSubmitResponse,
    businessSubmitResponse,
    msisdnData,
    msisdnOtpData,
    redisStatus,
    userCreationResponse,
    enterpriseSubmitResponse,
    loading,
    businessProfileEditResponse,
    enterpriseResubmitResponse
  } = useSelector<any, any>(({ user }) => user);

  const getDeviceInfo = async () => {
    try {
      const res = await axios.get('https://geolocation-db.com/json/');
      let device = {
        ipAddress: res.data.IPv4,
        device: isMobile ? `${mobileVendor}-${mobileModel}` : 'Desktop',
        os: osName,
        osVersion: osVersion,
        browser: browserName
      };
      setDeviceDetails(device);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (isLogin) {
      navigate('/');
    }
  }, [auth, isLogin]);

  useEffect(() => {
    setIsBusinessProfileOnSave(false);
    // dispatch(fetchProfileStatus());
    // setIndustryList(data.industryList)
    setDistrictList(data.districtList);
    setIndustryTypeList(["Apparel & Textiles", "Agriculture", "Banking & finance", "Construction", "Education", "Food &Manufacturing", "Healthcare", "IT & Technology", "Logistics", "Real Estate", "Retail", "Transport", "Tourism & Hospitality", "Other"]);
    getDeviceInfo();
  }, [])

  useEffect(() => {
    getBanks().then((res: any) => {
      setBankList(res.data.dataList);
    }).catch((error: any) => {
      console.log(error);
    })
  }, [])

  useEffect(() => {
    setNicError("");
  }, [businessDetails.ownerNicNumber]);

  useEffect(() => {
    setBrcError("");
  }, [businessDetails.business_reg_number]);

  useEffect(() => {
    setEmailError("");
  }, [businessDetails.business_email]);

  useEffect(() => {
    if (userDetails) {
      setUserFirstName(userDetails.firstName ? userDetails.firstName : "")
      setProfileDetails({
        ...profileDetails,
        first_name: userDetails.firstName ? userDetails.firstName : "",
        last_name: userDetails.lastName ? userDetails.lastName : "",
        email: (userDetails && userDetails.email) ? userDetails.email : "",
        emailStatus: "PENDING",
        // emailStatus: (userDetails.email) ? "VERIFIED" : "PENDING",
        msisdn: userDetails.contact ? userDetails.contact : "",
        msisdnStatus: (userDetails.contact) ? "VERIFIED" : "PENDING",
        // msisdnStatus: (userDetails.OwnedEnterprises && Array.isArray(userDetails.OwnedEnterprises) && userDetails.OwnedEnterprises.length > 0 && Array.isArray(userDetails.OwnedEnterprises[0].MsisdnConfigs) && userDetails.OwnedEnterprises[0].MsisdnConfigs.length > 0 && userDetails.OwnedEnterprises[0].MsisdnConfigs[0].status === 'Active') ? "VERIFIED" : "PENDING",
        nic: (userDetails.OwnedEnterprises && Array.isArray(userDetails.OwnedEnterprises) && userDetails.OwnedEnterprises.length > 0 && userDetails.OwnedEnterprises[0].Enterprise) ? userDetails.OwnedEnterprises[0].Enterprise.ownerNIC : ""
      })
      setMaDetails({
        ...maDetails,
        expertise: userDetails.Profile ? userDetails.Profile.expertise : "",
        experience: userDetails.Profile ? userDetails.Profile.yearsOfExperience : "",
        personal_description: userDetails.Profile ? userDetails.Profile.selfIntro : "",
        specification: userDetails.Profile ? userDetails.Profile.specification : "",
        account_holder: (Array.isArray(userDetails.BankAccount) && userDetails.BankAccount.length > 0) ? userDetails.BankAccount[0].accountHolderName : "",
        account_number: (Array.isArray(userDetails.BankAccount) && userDetails.BankAccount.length > 0) ? userDetails.BankAccount[0].accountNumber : "",
        role: userDetails.role,
        employee_id: userDetails.Profile ? userDetails.Profile.dialogEmpNo : "",
        employee_email: userDetails.Profile ? userDetails.Profile.email : "",
      })
      // setAuth(auth);
      setBankCode((Array.isArray(userDetails.BankAccount) && userDetails.BankAccount.length > 0) ? userDetails.BankAccount[0].bankCode : "");
      setBankName((Array.isArray(userDetails.BankAccount) && userDetails.BankAccount.length > 0) ? userDetails.BankAccount[0].bankName : "");
      // setNicCopy();
      if (Array.isArray(Array.isArray(userDetails.OwnedEnterprises) && userDetails.OwnedEnterprises.length > 0 && userDetails.OwnedEnterprises[0].EnterpriseImages)) {
        let brDocArr: any = [];
        let nicCopyArr: any = [];
        userDetails.OwnedEnterprises[0].EnterpriseImages.map((value: any, index: number) => {
          if (value.fieldName === "ownerNICCopy") {
            //setNicCopy([value.imgUrl]);
            nicCopyArr.push(value.imgUrl);
          }
          if (value.fieldName === "ownerNICCopy2") {
            // setNicCopy([value.imgUrl]);
            nicCopyArr.push(value.imgUrl);
          }
          if (value.fieldName === "entBussinessRegDoc1") {
            brDocArr.push(value.imgUrl);
          }
          if (value.fieldName === "entBussinessRegDoc2") {
            brDocArr.push(value.imgUrl);
          }
          if (value.fieldName === "entBussinessRegDoc3") {
            brDocArr.push(value.imgUrl);
          }
          setBrDoc(brDocArr);
          setNicCopy(nicCopyArr);
          if (value.fieldName === "entLogo") {
            setLogo([value.imgUrl]);
          }
        });
      }
      if (userDetails.OwnedEnterprises && Array.isArray(userDetails.OwnedEnterprises) && userDetails.OwnedEnterprises.length > 0 && userDetails.OwnedEnterprises[0].Enterprise) {
        let businessData = {
          business_name: userDetails.OwnedEnterprises[0].Enterprise.entName,
          business_email: userDetails.OwnedEnterprises[0].Enterprise.entEmail,
          business_description: userDetails.OwnedEnterprises[0].Enterprise.entDescription,
          business_reg_number: userDetails.OwnedEnterprises[0].Enterprise.entBussinessRegNumber,
          //industry: "",
          verificationDoc: userDetails.OwnedEnterprises[0].Enterprise.docType === "BRC" ? "businessReg" : "nic",
          number_and_lane: userDetails.OwnedEnterprises[0].Enterprise.entAddressNumber,
          street: userDetails.OwnedEnterprises[0].Enterprise.entAddressStreet,
          city: userDetails.OwnedEnterprises[0].Enterprise.entAddressCity,
          district: userDetails.OwnedEnterprises[0].Enterprise.entAddressDistrict,
          ownerNicNumber: (userDetails.OwnedEnterprises && Array.isArray(userDetails.OwnedEnterprises) && userDetails.OwnedEnterprises.length > 0 && userDetails.OwnedEnterprises[0].Enterprise) ? userDetails.OwnedEnterprises[0].Enterprise.ownerNIC : "",
          ownerNICCopy: "",
          ownerNICCopy2: "",
          entBussinessRegDoc1: "",
          entBussinessRegDoc2: "",
          entBussinessRegDoc3: "",
          logo: "",
          industry: userDetails.OwnedEnterprises[0].Enterprise.industry,
          ownerFirstName: userDetails.OwnedEnterprises[0].Enterprise.ownerFirstName,
          ownerLastName: userDetails.OwnedEnterprises[0].Enterprise.ownerLastName,
          ownerContact: userDetails.OwnedEnterprises[0].Enterprise.ownerMobile,
          selectedPartner: userDetails.OwnedEnterprises[0].MarketingAdmins && Array.isArray(userDetails.OwnedEnterprises[0].MarketingAdmins) && (userDetails.OwnedEnterprises[0].MarketingAdmins).length > 0 ? (userDetails.OwnedEnterprises[0].MarketingAdmins[0].firstName + " " + userDetails.OwnedEnterprises[0].MarketingAdmins[0].lastName) : "",
          responsivePersonNo: userDetails.OwnedEnterprises[0].Enterprise.responsivePersonNo ? userDetails.OwnedEnterprises[0].Enterprise.responsivePersonNo : "",
          responsivePersonName: userDetails.OwnedEnterprises[0].Enterprise.responsivePersonName ? userDetails.OwnedEnterprises[0].Enterprise.responsivePersonName : ""
        };
        setBusinessDetails(businessData);
        setIsOwnerSame(true);
        console.log("Business details:", businessDetails);
        setStatus(userDetails.OwnedEnterprises[0].Enterprise.status === "Submitted" ? "PENDING_APPROVAL"
            : userDetails.OwnedEnterprises[0].Enterprise.status === "DemoSubmitted" ? "PENDING_APPROVAL"
                : userDetails.OwnedEnterprises[0].Enterprise.status === "Resubmitted" ? "PENDING_APPROVAL"
                    : userDetails.OwnedEnterprises[0].Enterprise.status === "DemoResubmitted" ? "PENDING_APPROVAL"
                        : userDetails.OwnedEnterprises[0].Enterprise.status === "Active" ? "APPROVED"
                            : userDetails.OwnedEnterprises[0].Enterprise.status === "Demo" ? "APPROVED"
                                : userDetails.OwnedEnterprises[0].Enterprise.status === "Rejected" ? "REJECTED"
                                    : userDetails.OwnedEnterprises[0].Enterprise.status === "DemoRejected" ? "REJECTED"
                                        : "");
      }
      if (userDetails.ProfileSteps) {
        setSectionsStatus(userDetails.ProfileSteps)
      }
      setOwnerDetails({
        ...ownerDetails,
        owner_first_name: userDetails ? userDetails.firstName : "",
        owner_last_name: userDetails ? userDetails.lastName : "",
        owner_mobile: userDetails ? userDetails.contact : ""
      })

      if ((userDetails && Array.isArray(userDetails.OwnedEnterprises) && userDetails.OwnedEnterprises.length > 0 && userDetails.OwnedEnterprises[0].Enterprise && userDetails.OwnedEnterprises[0].Enterprise.status) && (userDetails.OwnedEnterprises[0].Enterprise.status == "Rejected" || userDetails.OwnedEnterprises[0].Enterprise.status == "DemoRejected")) {
        setIsEntRejected(true);
      } else {
        setIsEntRejected(false);
      }
    }

  }, [userDetails, auth])

  useEffect(() => {
    if (userDetails) {
      setBranchCode((Array.isArray(userDetails.BankAccount) && userDetails.BankAccount.length > 0) ? userDetails.BankAccount[0].branchCode : "");
      setBranchName((Array.isArray(userDetails.BankAccount) && userDetails.BankAccount.length > 0) ? userDetails.BankAccount[0].branchName : "");
    }
  }, [userDetails, branchList]);

  useEffect(() => {
    console.log("progress == 100", progress == 100);
    if (userDetails && userDetails.ProfileSteps) {
      setSectionsStatus(userDetails.ProfileSteps)
    }

    if (progress == 100) {
      setIsReadTnC(true);
      setIsReadAgreement(true);
    }
  }, [progress])

  useEffect(() => {
    // console.log("@@@@@@@@@@2 accountTypeSubmitResponse", accountTypeSubmitResponse);
    if (accountTypeSubmitResponse && accountTypeSubmitResponse.status == "SUCCESS") {
      // console.log("@@@@@@@@@@3 accountTypeSubmitResponse", accountTypeSubmitResponse.status);
      dispatch(fetchProfileStatus())
      setShowAccType(false);
      setShowBusinessProfile(true);
    }
  }, [accountTypeSubmitResponse])

  useEffect(() => {
    // console.log("$#$$#$%");

    if (profileSubmitResponse && profileSubmitResponse.status) {
      dispatch(fetchProfileStatus())
    }
  }, [profileSubmitResponse])

  useEffect(() => {
    if (businessSubmitResponse && businessSubmitResponse.status) {
      dispatch(fetchProfileStatus())
    }
  }, [businessSubmitResponse])

  useEffect(() => {
    if (enterpriseSubmitResponse && enterpriseSubmitResponse.status) {
      dispatch(fetchProfileStatus())
    }
  }, [enterpriseSubmitResponse])

  useEffect(() => {
    if (businessProfileEditResponse && businessProfileEditResponse.status) {
      dispatch(fetchProfileStatus())
      setResetDispatched(false);
    }
  }, [businessProfileEditResponse, resetDispatched])

  useEffect(() => {
    if (enterpriseResubmitResponse && enterpriseResubmitResponse.status) {
      dispatch(fetchProfileStatus());
      setResetDispatched(false);
    }
  }, [enterpriseResubmitResponse, resetDispatched])


  useEffect(() => {
    if (msisdnData && msisdnData.data) {
      setShowMsisdnOtpField(true)
      setProfileDetails({ ...profileDetails, msisdnStatus: '' })
      setOtp({ ...otp, serverRef: msisdnData.data.serverRef })
    }
  }, [msisdnData])

  useEffect(() => {
    // console.log("@@@@@@@@@@@@@@@@@", msisdnOtpData && msisdnOtpData.status === "ERROR", msisdnOtpData && msisdnOtpData.data && msisdnOtpData.data.data && msisdnOtpData.data.data.status === "SUCCESS");
    if (msisdnOtpData && msisdnOtpData.status === "ERROR") {
      if (msisdnOtpData.message == "Wrong Pin") {
        setProfileDetails({ ...profileDetails, msisdnStatus: 'INVALID' })
      } else {
        setProfileDetails({ ...profileDetails, msisdnStatus: 'PENDING' })
      }
    }
    if (msisdnOtpData && msisdnOtpData.data && msisdnOtpData.data.data && msisdnOtpData.data.data.status === "SUCCESS") {
      setProfileDetails({ ...profileDetails, msisdnStatus: 'VERIFIED' })
      setShowMsisdnOtpField(false)
    }
  }, [msisdnOtpData])

  useEffect(() => {
    if (redisStatus && redisStatus.data) {

      if (redisStatus.data.error == "false") {

        if (redisStatus.data.enterprise === "DONE" && redisStatus.data.ideabizaccount === "DONE" && redisStatus.data.ideabizapi === "DONE" && redisStatus.data.ideabizconfig === "DONE" && redisStatus.data.ideabizuser === "DONE" && redisStatus.data.submit === "DONE") {

          // setStatus("PENDING_APPROVAL")
          clearInterval(intervalObj)
          // dispatch(fetchProfileStatus())
        }
      } else {
        // console.log("@#$#@$@#$ 4");
        // clearInterval(intervalObj)
      }
    } else {
      // console.log("@#$#@$@#$ 5");
      // clearInterval(intervalObj)
    }
  }, [redisStatus])

  useEffect(() => {
    if (isOwnerSame == true) {
      setOwnerDetails({
        ...ownerDetails,
        owner_first_name: userDetails ? userDetails.firstName : "",
        owner_last_name: userDetails ? userDetails.lastName : "",
        owner_mobile: userDetails ? userDetails.contact : ""
      })

    }
  }, [isOwnerSame])

  const setSectionsStatus = (provisions: any[]) => {
    // console.log("###########", provisions);
    setShowAccType(false)
    setShowYourProfile(true)
    setShowBusinessProfile(false)
    setShowTnC(false)
    // if ( Array.isArray(userDetails.OwnedEnterprises) && userDetails.OwnedEnterprises.length > 0 && userDetails.OwnedEnterprises[0] && userDetails.OwnedEnterprises[0].Enterprise && userDetails.OwnedEnterprises[0].Enterprise.status != "Resubmitted") {
    setProgress((provisions.length) * 25)
    if (provisions.length === 0) {
      setProfileStatus("pending")
      setShowYourProfile(true)
    }
    provisions && provisions.map(async provision => {
      if (provision.step === "USER_CREATED") {
        setAccountStatus("pending");
        setProfileStatus("completed");
        setShowYourProfile(false);
        setShowAccType(true);
        setShowBusinessProfile(false);
      }
      if (provision.step === "PROFILE_CREATED") {
        setAccountStatus("completed")
        setBusinessStatus("pending")
        setShowBusinessProfile(true)
        setShowAccType(false)
      }
      if ((userDetails.OwnedEnterprises[0] && userDetails.OwnedEnterprises[0].Enterprise) && userDetails.OwnedEnterprises[0].Enterprise.status != "Rejected" && userDetails.OwnedEnterprises[0].Enterprise.status != "DemoRejected" && userDetails.OwnedEnterprises[0].Enterprise.status != "Redraft" && userDetails.OwnedEnterprises[0].Enterprise.status != "DemoRedraft") {
        if (provision.step === "BUSINESS_CREATED") {
          setBusinessStatus("completed")
          setTncStatus("pending")
          setShowTnC(true)
          setShowBusinessProfile(false)
        }
        if (provision.step === "TNC_SUBMITTED") {
          setTncStatus("completed")
          setShowTnC(false)
        }
      }
    })
    //

    if ((userDetails.OwnedEnterprises[0] && userDetails.OwnedEnterprises[0].Enterprise) && (userDetails.OwnedEnterprises[0].Enterprise.status === "Rejected" || userDetails.OwnedEnterprises[0].Enterprise.status === "DemoRejected")) {
      setProgress(50);
      setBusinessStatus("rejected");
      setTncStatus("locked");
      setShowTnC(false);
    } else if (userDetails.OwnedEnterprises[0] && userDetails.OwnedEnterprises[0].Enterprise && userDetails.OwnedEnterprises[0].Enterprise.status === "Resubmitted") {
      setProfileStatus("completed");
      setAccountStatus("completed");
      setBusinessStatus("completed");
      setTncStatus("completed");

      setProgress(100);
      setShowYourProfile(false);
      setShowAccType(false);
      setShowBusinessProfile(false);
      setShowTnC(false);

    } else if ((userDetails.OwnedEnterprises[0] && userDetails.OwnedEnterprises[0].Enterprise) && (userDetails.OwnedEnterprises[0].Enterprise.status === "Redraft" || userDetails.OwnedEnterprises[0].Enterprise.status === "DemoRedraft")) {
      setProgress(75);
      setBusinessStatus("completed");
      setTncStatus("pending");
      setShowBusinessProfile(false);
      setShowTnC(true);
    }
  }

  const handleBusinessDetailsChange = (name: string) => (event: any) => {
    // console.log(name, event.target.value);
    if (event.target.value === null || event.target.value === '') {
      setError('You can’t keep this as empty.');
    }

    setBusinessDetails({ ...businessDetails, [name]: event.target.value });
  };

  const handleOwnerDetailsChange = (name: string) => (event: any) => {
    setOwnerDetails({ ...ownerDetails, [name]: event.target.value });
  };

  const handleMaDetailsChange = (name: string) => (event: any) => {
    setMaDetails({ ...maDetails, [name]: event.target.value });
  };

  const useForceUpdate = () => {
    const [value, setValue] = useState(0);
    return () => setValue(value => value + 1);
  }

  const forceUpdate = useForceUpdate()

  const handleSignIn = () => {
    window.location.href = "/home";
  }

  const onAccountSave = () => {
    let selectedAccountType = accountType;
    // console.log("selectedAccountType in account save", selectedAccountType);
    let req: AccountTypeModel = {
      role: selectedAccountType
    }
    dispatch(submitAccountType(req));
    // window.location.hash = '#profile-section';
  }

  const onBusinessDetailsSave = () => {
    // console.log("businessDetails", businessDetails);
    const fmData = new FormData();

    if (maDetails.role !== "mp_dialog_agent") {
      fmData.append("businessName", businessDetails.business_name);
      fmData.append("businessEmail", businessDetails.business_email);
      fmData.append("description", businessDetails.business_description);
      fmData.append("entBussinessRegNumber", businessDetails.business_reg_number);
      fmData.append("ownerNIC", businessDetails.ownerNicNumber);
      fmData.append("poBoxNo", businessDetails.number_and_lane);
      fmData.append("street", businessDetails.street);
      fmData.append("city", businessDetails.city);
      fmData.append("district", businessDetails.district);
      fmData.append("responsivePersonNo", businessDetails.responsivePersonNo);
      fmData.append("responsivePersonName", businessDetails.responsivePersonName);
      fmData.append("industry", businessDetails.industry);
      fmData.append("role", maDetails.role);

      fmData.append("maUuid", selectedPartnerUuid);

      fmData.append("docType", businessDetails.verificationDoc == "nic" ? "NIC" : "BRC");
      if (Array.isArray(brDoc) && brDoc.length > 0 && businessDetails.verificationDoc == "businessReg") {
        fmData.append("entBussinessRegDoc1", (brDoc[0].originFileObj));
      }
      if (Array.isArray(brDoc) && brDoc.length > 1 && businessDetails.verificationDoc == "businessReg") {
        fmData.append("entBussinessRegDoc2", (brDoc[1].originFileObj));
      }
      if (Array.isArray(brDoc) && brDoc.length > 2 && businessDetails.verificationDoc == "businessReg") {
        fmData.append("entBussinessRegDoc3", (brDoc[2].originFileObj));
      }
      if (Array.isArray(nicCopy) && nicCopy.length > 0 && businessDetails.verificationDoc !== "businessReg") {
        fmData.append("nicCopy", (nicCopy[0].originFileObj));
      }
      if (Array.isArray(nicCopy) && nicCopy.length > 1 && businessDetails.verificationDoc !== "businessReg") {
        fmData.append("nicCopy2", (nicCopy[1].originFileObj));
      }
      if (Array.isArray(logo) && logo.length > 0) {
        fmData.append("entLogo", (logo[0].originFileObj));
      }
      // else {
      //   fmData.append("entLogo", '');
      // }
      //fmData.append("entLogo", (Array.isArray(logo) && logo.length > 0) ? logo[0].originFileObj : null);

      fmData.append("ownerContact", ownerDetails.owner_mobile);
      fmData.append("ownerFirstName", ownerDetails.owner_first_name);
      fmData.append("ownerLastName", ownerDetails.owner_last_name);
      fmData.append("ownerOTPReference", otp.serverRef ? otp.serverRef : "");

      // ma data
      fmData.append("expertise", maDetails.expertise);
      fmData.append("yearsOfExperience", maDetails.experience);
      fmData.append("selfIntro", maDetails.personal_description);
      fmData.append("specification", maDetails.specification);

      fmData.append("bankCode", bankCode);
      fmData.append("bankName", bankName);
      fmData.append("branchCode", branchCode);
      fmData.append("branchName", branchName);
      fmData.append("accountNumber", maDetails.account_number);
      fmData.append("accountHolderName", maDetails.account_holder);
    } else {
      fmData.append("role", maDetails.role);
      fmData.append("employeeId", maDetails.employee_id);
      fmData.append("businessEmail", maDetails.employee_email);
    }

    //Submit Business Details for Enterprise Owner
    if (userDetails.role == "mp_enterprise_owner") {
      if (businessDetailValidator.fieldValid('business_name')
          && businessDetailValidator.fieldValid('business_email')
          && businessDetailValidator.fieldValid('business_description')
          && (businessDetails.verificationDoc == "businessReg" ? businessDetailValidator.fieldValid('business_reg_number') : (businessDetailValidator.fieldValid('ownerNicNumber')))
          && businessDetailValidator.fieldValid('number_and_lane')
          && businessDetailValidator.fieldValid('street')
          && businessDetailValidator.fieldValid('city')
          && businessDetailValidator.fieldValid('district')
          && ((businessDetails.verificationDoc == "businessReg" ? businessDetailValidator.fieldValid('brDoc') : businessDetailValidator.fieldValid('nicCopy')))
          && brUploadSizeError == ""
          && logoUploadSizeError == ""
          && !(selectPartnerErr)
          && (businessDetails.verificationDoc == "businessReg" ? brcError == "" : nicError == "")
          && (emailError == "")
      ) {

        if (!isBusinessProfileEdit && !isEntRejected) {
          // console.log("Business Profile Details Submit");
          setIsBusinessProfileOnSave(true);
          dispatch(submitBusinessDetails(fmData));

        }
        else {
          onSaveEditedBusinessProfile();
        }
      }
      else {
        businessDetailValidator.showMessages()
        forceUpdate()
      }
    }
    if (userDetails.role == 'mp_content_generator') {
      if (businessDetailValidator.fieldValid('business_name')
          && businessDetailValidator.fieldValid('business_email')
          && businessDetailValidator.fieldValid('business_description')
          && (businessDetails.verificationDoc == "businessReg" ? businessDetailValidator.fieldValid('business_reg_number') : (businessDetailValidator.fieldValid('ownerNicNumber')))
          && businessDetailValidator.fieldValid('number_and_lane')
          && businessDetailValidator.fieldValid('street')
          && businessDetailValidator.fieldValid('city')
          && businessDetailValidator.fieldValid('district')
          && businessDetailValidator.fieldValid('responsivePersonNo')
          && businessDetailValidator.fieldValid('responsivePersonName')
          && ((businessDetails.verificationDoc == "businessReg" ? businessDetailValidator.fieldValid('brDoc') : businessDetailValidator.fieldValid('nicCopy')))
          && brUploadSizeError == ""
          && logoUploadSizeError == ""
          && !(selectPartnerErr)
          && (businessDetails.verificationDoc == "businessReg" ? brcError == "" : nicError == "")
          && (emailError == "")
      ) {

        if (!isBusinessProfileEdit && !isEntRejected) {
          // console.log("Business Profile Details Submit");
          setIsBusinessProfileOnSave(true);
          dispatch(submitBusinessDetails(fmData));

        }
        else {
          onSaveEditedBusinessProfile();
        }
      }
      else {
        businessDetailValidator.showMessages()
        forceUpdate()
      }
    }
    //Submit Business Details for Marketing agent - Marketing Agent
    else if ((userDetails.role == "mp_common_agent" && maDetails.role == "mp_marketing_admin") || (userDetails.role == "mp_marketing_admin")) {
      if (businessDetailValidator.fieldValid('business_name')
          && businessDetailValidator.fieldValid('business_email')
          && businessDetailValidator.fieldValid('business_description')
          && (businessDetails.verificationDoc == "businessReg" ? businessDetailValidator.fieldValid('business_reg_number') : (businessDetailValidator.fieldValid('ownerNicNumber')))
          && businessDetailValidator.fieldValid('number_and_lane')
          && businessDetailValidator.fieldValid('street')
          && businessDetailValidator.fieldValid('city')
          && businessDetailValidator.fieldValid('district')
          && ((businessDetails.verificationDoc == "businessReg" ? businessDetailValidator.fieldValid('brDoc') : businessDetailValidator.fieldValid('nicCopy')))
          && businessDetailValidator.fieldValid('expertise')
          && businessDetailValidator.fieldValid('experience')
          && businessDetailValidator.fieldValid('personal_description')
          && businessDetailValidator.fieldValid('specification')
          && businessDetailValidator.fieldValid('account_holder')
          && businessDetailValidator.fieldValid('bank')
          && businessDetailValidator.fieldValid('branch')
          && businessDetailValidator.fieldValid('account_number')
          && brUploadSizeError == ""
          && logoUploadSizeError == ""
          && (businessDetails.verificationDoc == "businessReg" ? brcError == "" : nicError == "")
          && (emailError == "")
      ) {
        if (!isBusinessProfileEdit && !isEntRejected) {
          // console.log("Business Profile Details Submit");
          setIsBusinessProfileOnSave(true);
          dispatch(submitBusinessDetails(fmData));

        }
        else {
          onSaveEditedBusinessProfile();
        }
        // // console.log("marketing admin data submit", fmData);
        // dispatch(submitBusinessDetails(fmData));
      } else {
        businessDetailValidator.showMessages()
        forceUpdate()
      }

    }
    //Submit Business Details for Marketing agent - Dialog Account Manager
    else if ((userDetails.role == "mp_common_agent" && maDetails.role == "mp_dialog_agent") || (userDetails.role == "mp_dialog_agent")) {
      if (businessDetailValidator.fieldValid('employee_id')) {
        console.log('Dagent test 1***');
        if (!isBusinessProfileEdit && !isEntRejected) {
          // console.log("Business Profile Details Submit");
          console.log('Dagent test 2***');
          setIsBusinessProfileOnSave(true);
          dispatch(submitBusinessDetails(fmData));
        }
        else {
          console.log('Dagent test 3***');
          onSaveEditedBusinessProfile();
        }
        // // console.log("dialog partner data submit", fmData);
        // dispatch(submitBusinessDetails(fmData));
      } else {
        businessDetailValidator.showMessages()
        forceUpdate()
      }
    }
  }

  const onTncSave = () => {
    let req: Enterprise = {
      otpReference: userDetails.otpRef,
      ownerMobile: userDetails.OwnedEnterprises[0].MsisdnConfigs[0].msisdn,
      deviceDetails: deviceDetails
    }
    if (userDetails && userDetails.OwnedEnterprises && (userDetails.OwnedEnterprises[0].Enterprise.status == "Redraft" || userDetails.OwnedEnterprises[0].Enterprise.status == "DemoRedraft")) {
      // console.log("in enterprise resubmit");
      dispatch(enterpriseResubmit(req, userDetails.OwnedEnterprises[0].Enterprise.uuid));
    } else {
      dispatch(submitEnterprise(req, userDetails.OwnedEnterprises[0].Enterprise.uuid));
    }
    setShowAccType(false)
    setShowYourProfile(false)
    setShowBusinessProfile(false)
    setShowTnC(false)
    setAccountStatus("locked")
    setProfileStatus("locked")
    setBusinessStatus("locked")
    setTncStatus("locked")
    setStatus("processing")
    if (userDetails && userDetails.role == "mp_enterprise_owner") {
      // getProvisionStatus()
      setStatus("PENDING_APPROVAL")
      dispatch(fetchProfileStatus())
    }
  }

  useEffect(() => {
    if (userDetails && userDetails.role == "mp_common_agent") {
      // getProvisionStatus()
      // console.log("@@@@@@@@@@@@@@", enterpriseSubmitResponse);

      if (enterpriseSubmitResponse && enterpriseSubmitResponse.status && enterpriseSubmitResponse.status == "SUCCESS") {
        setStatus("PENDING_APPROVAL");
        dispatch(fetchProfileStatus())
      }
    }
  }, [enterpriseSubmitResponse, userDetails])

  const getProvisionStatus = () => {
    try {
      const interval = setInterval(() => {
        dispatch(fetchRedisStatus(userDetails.OwnedEnterprises[0].Enterprise.uuid))
        setIntervalObj(interval)
      }, 1000)
    } catch (e) {
      console.log(e)
      clearInterval(intervalObj)
    }
  }

  const onBlurNIC = (event: FocusEvent<HTMLInputElement>) => {
    console.log("event", event.target.value)
    getNicBrcDuplicateCheck("NIC", event.target.value)
        .then((response) => {
          if (!isBusinessProfileEdit && response.data && response.data.data === false) {
            setNicError("NIC already exists");
            // profileDetailValidator.messageWhenPresent('NIC already exists');
            // profileDetailValidator.showMessageFor('nic');
            // forceUpdate()
          } else {
            setNicError("");
          }
        })
        .catch((error) => {

        });
  }

  const onBlurBrc = (event: FocusEvent<HTMLInputElement>) => {
    getNicBrcDuplicateCheck("BRC", event.target.value)
        .then((response) => {
          if (response.data && response.data.data === false) {
            setBrcError("Business registration number already exists");
          } else {
            setBrcError("");
          }
        })
        .catch((error) => {

        });
  }

  const onBlurEmail = (event: FocusEvent<HTMLInputElement>) => {
    getEmailDuplicateCheck(event.target.value)
        .then((response) => {
          if (response.data && response.data.status === false) {
            setEmailError("Email adddress is already exists");
          } else {
            setEmailError("");
          }
        })
        .catch((error) => {

        });
  }

  const renderDistrictList = () => {
    let list: JSX.Element[] = [<option key={'-1'} value="" disabled hidden>Not selected</option>]
    districtList.map((district, index) => {
      list.push(<option key={index} value={district}>{district}</option>)
    })
    return list
  }

  const submitOtp = () => {
    if (otp.pin && otp.serverRef) {
      dispatch(submitMsisdnOtp(otp, CONSTANTS.COMMON_TENANT_ID!))
    }
  }

  const handleOtpChange = (name: string) => (event: any) => {
    setOtp({ ...otp, pin: event.target.value });
  };

  const onDropFile = () => {
    // console.log("%%%%%%%%%");
    // this.setState({ files: [] });
    setNicCopy([]);
    profileDetailValidator.showMessageFor('nicCopy');
    forceUpdate();
  }

  const beforeUploadFile = (file: any) => {
    const fileSize = file.size / 1024 / 1024 / 1024;
    // console.log("fileSize", fileSize);
    // let fileError = this.state.fileError;
    if (fileSize > 2) {
      alert("file size exceeded");
      // fileError[index] = 'File size cannot be larger than 2MB';
      // this.setState({ fileError: fileError });
    } else {
      // fileError[index] = '';
      // this.setState({ fileError: fileError });
    }
    return false;
  }

  const onChangeLogo = (file: any) => {
    const fileSize = file.file.size / 1024 / 1024;
    const fileType = file.file.type;
    // console.log("file type", fileType);

    if (fileSize > 2) {
      setLogoUploadSizeError("Filesize should be less than 2mb. Upload again.");
      businessDetailValidator.hideMessageFor('logo');
    } else if (fileType !== "image/jpeg" && fileType !== "image/png") {
      setLogoUploadSizeError("Only png and jpg file types are allowed");
      businessDetailValidator.hideMessageFor('logo');
    } else {
      setLogoUploadSizeError("");
      setLogo(file.fileList);
      businessDetailValidator.showMessageFor('logo');
      forceUpdate();
    }
  }

  const onChangeBr = (file: any) => {
    const fileSize = file.file.size / 1024 / 1024;
    const fileType = file.file.type;
    console.log("fileType", fileType);

    if (fileSize > 2) {
      setBrUploadSizeError("Filesize should be less than 2mb. Upload again.");
      businessDetailValidator.hideMessageFor('brDoc');
    } else if (fileType !== "image/jpeg" && fileType !== "image/png" && fileType !== "document/pdf" && fileType !== "application/pdf") {
      setBrUploadSizeError("Only png,jpg and pdf file types are allowed");
      businessDetailValidator.hideMessageFor('brDoc');
    } else {
      setBrUploadSizeError("");
      setBrDoc(file.fileList);

      //Clear the NIC details and attachments if user switched to BR details and uploaded the attachment
      setBusinessDetails({ ...businessDetails, ["ownerNicNumber"]: "" });
      setNicCopy([]);

      businessDetailValidator.showMessageFor('brDoc');
      forceUpdate();
    }
  }

  const onChangeFile = (file: any) => {
    const fileSize = file.file.size / 1024 / 1024;
    const fileType = file.file.type;

    if (fileSize > 2) {
      setBrUploadSizeError("Filesize should be less than 2mb. Upload again.");
      profileDetailValidator.hideMessageFor('nicCopy');
    } else if (fileType !== "image/jpeg" && fileType !== "image/png" && fileType !== "document/pdf" && fileType !== "application/pdf") {
      setBrUploadSizeError("Only png,jpg and pdf file types are allowed");
      profileDetailValidator.hideMessageFor('nicCopy');
    } else {
      setBrUploadSizeError("");
      setNicCopy(file.fileList);

      //Clear the BR details and attachments if user switched to NIC details and uploaded the attachment
      setBusinessDetails({ ...businessDetails, ["business_reg_number"]: "" });
      setBrDoc([]);

      profileDetailValidator.showMessageFor('nicCopy');
      forceUpdate();
    }
  }

  const onDropLogo = () => {
    setLogo([]);
    businessDetailValidator.showMessageFor('logo');
    forceUpdate();
  }

  const beforeUploadLogo = (file: any) => {
    return false;
  }

  const onDropBr = () => {
    setBrDoc([]);
    businessDetailValidator.showMessageFor('brDoc');
    forceUpdate();
  }

  const beforeUploadBr = (file: any) => {
    return false;
  }

  useEffect(() => {
    if (userCreationResponse && userCreationResponse.status && userCreationResponse.status == "SUCCESS") {
      setStatus("success")
      notification.success({
        message: `SUCCESS`,
        description: 'User Created successfully',
        placement: 'bottomRight'
      });

      setShowAccType(true);
      //alert("user created successfully");
      console.log("user created successful");
      setTimeout(() => {
        handleSignIn();
      }, 1000)
    }
    if (userCreationResponse && !userCreationResponse.status) {
      setStatus("error")
      // setTimeout(() => {
      //   setStatus("init")
      // }, 2000)
      alert("user created failed");
    }
  }, [userCreationResponse]);
  // console.log("#@$#$%$%$#%$", auth.uuid)

  const onChangeBankCode = (e: any) => {
    setBankCode(e.target.value)
  }

  useEffect(() => {
    let bankObj: any = bankList.find(function (value: any, i: number, arr: any[]) {
      return value.code === bankCode;
    });
    if (bankObj !== null && typeof bankObj !== 'undefined') {
      setBankName(bankObj.name);
      setBranchList(bankObj.Branch);
    }
  }, [bankCode]);

  useEffect(() => {
    if (bankCode) {
      let bankObj: any = bankList.find(function (value: any, i: number, arr: any[]) {
        return value.code === bankCode;
      });
      if (bankObj !== null && typeof bankObj !== 'undefined') {
        setBankName(bankObj.name);
        setBranchList(bankObj.Branch);
      }
    }
  }, [bankList])

  useEffect(() => {
    let branchObj: any = branchList.find(function (value: any, i: number, arr: any[]) {
      return value.code === branchCode;
    });
    if (branchObj !== null && typeof branchObj !== 'undefined') {
      setBranchName(branchObj.name);
    }
  }, [branchCode]);
  //fix bug - ma uuid set as 'Non-Agent' when resubmitting or editing business profile
  useEffect(() => {
    if (isEntRejected || isBusinessProfileEdit) {
      if (userDetails && userDetails.OwnedEnterprises[0]) {
        if (userDetails.OwnedEnterprises[0].MarketingAdmins && Array.isArray(userDetails.OwnedEnterprises[0].MarketingAdmins) && userDetails.OwnedEnterprises[0].MarketingAdmins.length > 0 && (userDetails.OwnedEnterprises[0].MarketingAdmins[0].uuid)) {
          setSelectedPartnerUuid(userDetails.OwnedEnterprises[0].MarketingAdmins[0].uuid);
        }
      }
    }

  }, [isEntRejected, isBusinessProfileEdit, userDetails])

  const onChangeBranchCode = (e: any) => {
    setBranchCode(e.target.value);
  }

  const onEditBusinessProfile = () => {
    setIsBusinessProfileEdit(true);
    setIsBusinessProfileOnSave(false);
  }
  const onSaveEditedBusinessProfile = () => {
    const fmData = new FormData();
    console.log('Dagent test 4***');

    if (userDetails.role !== "mp_dialog_agent") {
      fmData.append("entName", businessDetails.business_name);
      fmData.append("entEmail", businessDetails.business_email);
      fmData.append("entDescription", businessDetails.business_description);
      fmData.append("entBussinessRegNumber", businessDetails.business_reg_number);
      fmData.append("ownerNIC", businessDetails.ownerNicNumber);
      fmData.append("entAddressNumber", businessDetails.number_and_lane);
      fmData.append("entAddressStreet", businessDetails.street);
      fmData.append("entAddressCity", businessDetails.city);
      fmData.append("entAddressDistrict", businessDetails.district);
      fmData.append("responsivePersonNo", businessDetails.responsivePersonNo);
      fmData.append("responsivePersonName", businessDetails.responsivePersonName);
      fmData.append("industry", businessDetails.industry);
      fmData.append("role", maDetails.role);
  
      fmData.append("maUuid", selectedPartnerUuid);
  
      fmData.append("docType", businessDetails.verificationDoc == "nic" ? "NIC" : "BRC");
      if (Array.isArray(brDoc) && brDoc.length > 0 && businessDetails.verificationDoc == "businessReg") {
        fmData.append("entBussinessRegDoc1", (brDoc[0].originFileObj));
      }
      if (Array.isArray(brDoc) && brDoc.length > 1 && businessDetails.verificationDoc == "businessReg") {
        fmData.append("entBussinessRegDoc2", (brDoc[1].originFileObj));
      }
      if (Array.isArray(brDoc) && brDoc.length > 2 && businessDetails.verificationDoc == "businessReg") {
        fmData.append("entBussinessRegDoc3", (brDoc[2].originFileObj));
      }
      if (Array.isArray(nicCopy) && nicCopy.length > 0 && businessDetails.verificationDoc == "nic") {
        fmData.append("ownerNICCopy", (nicCopy[0].originFileObj));
      }
      if (Array.isArray(nicCopy) && nicCopy.length > 1 && businessDetails.verificationDoc == "nic") {
        fmData.append("nicCopy2", (nicCopy[1].originFileObj));
      }
      if (Array.isArray(logo) && logo.length > 0) {
        fmData.append("entLogo", (logo[0].originFileObj));
      } else {
        fmData.append("entLogo", '');
      }
      //fmData.append("entLogo", (Array.isArray(logo) && logo.length > 0) ? logo[0].originFileObj : null);

      fmData.append("ownerFirstName", ownerDetails.owner_first_name);
      fmData.append("ownerLastName", ownerDetails.owner_last_name);
    } else{
      console.log('Dagent test 5***');
      fmData.append("role", maDetails.role);
      fmData.append("employeeId", maDetails.employee_id);
      fmData.append("businessEmail", maDetails.employee_email);
    }
    fmData.append("ownerMobile", ownerDetails.owner_mobile);
    fmData.append("ownerOTPReference", userDetails && userDetails.otpRef ? userDetails.otpRef : "");
    fmData.append("otpReference", userDetails && userDetails.otpRef ? userDetails.otpRef : "");
    fmData.append("status", userDetails && userDetails.OwnedEnterprises && userDetails.OwnedEnterprises[0].Enterprise.status ? userDetails.OwnedEnterprises[0].Enterprise.status : "");

    //Submit Business Details for Enterprise Owner
    if (userDetails.role == "mp_enterprise_owner" || userDetails.role == "mp_marketing_admin") {
      if (businessDetailValidator.fieldValid('business_name')
          && businessDetailValidator.fieldValid('business_email')
          && businessDetailValidator.fieldValid('business_description')
          && (businessDetails.verificationDoc == "businessReg" ? businessDetailValidator.fieldValid('business_reg_number') : (businessDetailValidator.fieldValid('ownerNicNumber')))
          && businessDetailValidator.fieldValid('number_and_lane')
          && businessDetailValidator.fieldValid('street')
          && businessDetailValidator.fieldValid('city')
          && businessDetailValidator.fieldValid('district')
          && ((businessDetails.verificationDoc == "businessReg" ? businessDetailValidator.fieldValid('brDoc') : businessDetailValidator.fieldValid('nicCopy')))
          && brUploadSizeError == ""
          && logoUploadSizeError == ""
          && !(selectPartnerErr)
          && (businessDetails.verificationDoc == "businessReg" ? brcError == "" : nicError == "")
          && (emailError == "")
      ) {
        fmData.forEach((value, key) => {
          console.log(`${key}: ${value}`);
        });

        let entUuid = userDetails && userDetails.OwnedEnterprises[0] && userDetails.OwnedEnterprises[0].Enterprise && userDetails.OwnedEnterprises[0].Enterprise.uuid ? userDetails.OwnedEnterprises[0].Enterprise.uuid : "";
        // console.log("ent Uuid", entUuid);
        dispatch(editBusinessProfileDetails(fmData, entUuid));
        setIsBusinessProfileEdit(false);
      }
      else {
        businessDetailValidator.showMessages()
        forceUpdate()
      }
    } else if(userDetails.role == "mp_dialog_agent"){
      console.log('Dagent test 6***');
      if (businessDetailValidator.fieldValid('employee_id')) {
        console.log('Dagent test 7***');
        let entUuid = userDetails && userDetails.OwnedEnterprises[0] && userDetails.OwnedEnterprises[0].Enterprise && userDetails.OwnedEnterprises[0].Enterprise.uuid ? userDetails.OwnedEnterprises[0].Enterprise.uuid : "";
        dispatch(editBusinessProfileDetails(fmData, entUuid));
        setIsBusinessProfileEdit(false);
    }
    else {
      businessDetailValidator.showMessages()
      forceUpdate()
    }
    }

  }

  useEffect(() => {
    if(status === 'APPROVED' && (auth?.realmRoles.includes('mp_content_generator'))) {
      navigate('/discover/apps/wizard')
    }
  }, [status]);

  return (
      <div className="main-content main-spacer-x-y">
        <div className="top-greeting__wrapper">
        <span className="day-greeting">
          <h4>Hello,<small>{userFirstName}</small></h4>
        </span>
        </div>
        {(status === "REJECTED") &&
            <div className="alert red">
          <span className="alert-text__wrapper">
            <i className="alert-icon bi bi-exclamation-circle-fill" />
            <span className="alert-text">Account creation request rejected! {userDetails && userDetails.OwnedEnterprises && userDetails.OwnedEnterprises.length > 0 && userDetails.OwnedEnterprises[0].Enterprise.remark ? userDetails.OwnedEnterprises[0].Enterprise.remark : ""}</span>
          </span>
            </div>
        }
        {(status != "APPROVED") &&
            // !(auth?.realmRoles.includes('mp_marketing_admin')) &&
            <div className="card radius-lg">
              <div className="main-wiz-container__wrapper">
                <div className="main-wiz-header__wrapper">
                  <ProgressBar status={status} accountStatus={accountStatus} profileStatus={profileStatus} businessStatus={businessStatus} tncStatus={tncStatus} />
                  <ProgressDescriptionBox
                      status={status}
                      progress={progress}
                  />
                  <div className="wiz-step__wrapper">
                    <YourProfileSectionUpdated
                        progress={progress}
                        status={profileStatus}
                        show={showYourProfile}
                        // show={true}
                        data={profileDetails}
                        validator={profileDetailValidator}
                        showMsisdnOtpField={showMsisdnOtpField}
                        otp={otp}
                        setShow={setShowYourProfile}
                        handleOtpChange={handleOtpChange}
                        submitOtp={submitOtp}
                        forceUpdate={forceUpdate}
                        userDetails={userDetails}
                    />

                    {/* {AuthHandler.isPerformable(auth, AccountCreationSectionsEnums.HOME_ACCOUNT_TYPE) && */}
                    <AccountTypeSection
                        progress={progress}
                        status={accountStatus}
                        show={showAccType}
                        // show={true}
                        realmRoles={auth.realmRoles}
                        setShow={setShowAccType}
                        onSave={onAccountSave}
                        setAccountType={setAccountType}
                        loading={loading}
                    />
                    {/* } */}
                    {/* {AuthHandler.isPerformable(auth, AccountCreationSectionsEnums.HOME_BUSINESS_PROFILE) && */}
                    <BusinessProfileSection
                        auth={auth}
                        progress={progress}
                        status={businessStatus}
                        show={showBusinessProfile}
                        isOwnerSame={isOwnerSame}
                        // show={true}
                        data={businessDetails}
                        ownerData={ownerDetails}
                        maData={maDetails}
                        handleMaDataChange={handleMaDetailsChange}
                        validator={businessDetailValidator}
                        nic={businessDetails.ownerNicNumber}
                        setShow={setShowBusinessProfile}
                        setIsOwnerSame={setIsOwnerSame}
                        onSave={onBusinessDetailsSave}
                        handleDataChange={handleBusinessDetailsChange}
                        handleOwnerDataChange={handleOwnerDetailsChange}
                        renderDistrictList={renderDistrictList}
                        setLogo={setLogo}
                        setBrDoc={setBrDoc}
                        onChangeLogo={onChangeLogo}
                        logo={logo}
                        onDropLogo={onDropLogo}
                        beforeUploadLogo={beforeUploadLogo}
                        maxLogoCount={1}
                        multipleLogo={false}
                        brDoc={businessDetails.verificationDoc == "businessReg" ? brDoc : nicCopy}
                        onChangeBr={businessDetails.verificationDoc == "businessReg" ? onChangeBr : onChangeFile}
                        onDropBr={businessDetails.verificationDoc == "businessReg" ? onDropBr : onDropFile}
                        beforeUploadBr={businessDetails.verificationDoc == "businessReg" ? beforeUploadBr : beforeUploadFile}
                        maxBrCount={businessDetails.verificationDoc == "businessReg" ? 3 : 2}
                        multipleBr={true}
                        onBlurBrc={businessDetails.verificationDoc == "businessReg" ? onBlurBrc : onBlurNIC}
                        brcError={businessDetails.verificationDoc == "businessReg" ? brcError : nicError}
                        onBlurEmail={onBlurEmail}
                        emailError={emailError}
                        forceUpdate={forceUpdate}
                        industryTypeList={industryTypeList}
                        brUploadSizeError={brUploadSizeError}
                        logoUploadSizeError={logoUploadSizeError}
                        bankList={bankList}
                        bankCode={bankCode}
                        onChangeBankCode={onChangeBankCode}
                        branchList={branchList}
                        branchCode={branchCode}
                        onChangeBranchCode={onChangeBranchCode}
                        loading={loading}
                        setSelectedPartnerUuid={setSelectedPartnerUuid}
                        setSelectPartnerErr={setSelectPartnerErr}
                        onEditBusinessProfile={onEditBusinessProfile}
                        isEditBusinessProfile={isBusinessProfileEdit}
                        isBusinessProfileOnSave={isBusinessProfileOnSave}
                    />
                    {/* } */}
                    {/* {AuthHandler.isPerformable(auth, AccountCreationSectionsEnums.HOME_TERMS_AND_CONDITIONS) && */}
                    <TnCSection
                        progress={progress}
                        status={tncStatus}
                        show={showTnC}
                        // show={true}
                        setShow={setShowTnC}
                        onSave={onTncSave}
                        setIsReadTnC={setIsReadTnC}
                        setIsReadAgreement={setIsReadAgreement}
                        isReadTnC={isReadTnC}
                        isReadAgreement={isReadAgreement}
                        loading={loading}
                    />
                    {/* } */}
                    {status === "Active" && <DoneButtonComponent />}
                  </div>
                </div>
              </div>
            </div>
        }
        {/* {status === "Active" && <DoneButtonComponent />} */}
        {/* <DoneButtonComponent /> */}
        {
            status === "APPROVED" &&
            <>
              {/* <div className="card radius-lg">
            <div className="main-wiz-container__wrapper">
              <div className="main-wiz-header__wrapper">
                <div className="wiz-step__wrapper">
                   <DoneButtonComponent />
                </div>
              </div>
            </div>
          </div> */}
              < Events />
            </>
        }
        {/* {
            (auth?.realmRoles.includes('mp_marketing_admin')) && status === "APPROVED" &&
            < Events />
        } */}
      </div>
  )
}

export default Home;