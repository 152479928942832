import React, {lazy, useEffect, useState} from "react";
import { useSelector } from "react-redux";
import {Col, Empty, Form, message, Modal, Radio, Row, Spin} from "antd";
import {useParams, useNavigate} from "react-router-dom";
import {
    commonPostDispatch,
    dynamicLoadingStop,
    dynamicWidgetLoading,
    fetchOneConfig,
    getDynamicWidget,
    resetDynamicRedux,
    fetchSingleApp,
    fetchWidgetPackages,
    fetchPartnerDetails,
    fetchPaymentMsisdnList,
    fetchGenieCardList,
    // postCommonWidgetCharging,
    formDataPostDispatch
} from "../../../store/actions";
import AuthHandler from "../../../helper/AuthHandler";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { PageHeaderDynamicFormComponent } from '../components/PageHeaderDynamicForm.component';
import * as Constants from '../../../constants';
import { useAppDispatch } from '../../../store';
import {AgentSelect} from "../../../components/AgentSelect";
import SimpleReactValidator from "simple-react-validator";
import {PaymentSelect} from "../../../components/PaymentSelect";
import { Modal as Model } from '../../../components';

// import sample from './sample-json/sample-widget-all.json';

const { confirm } = Modal;
const importView = (componentName) =>
    lazy(() =>
        import(`./component/${componentName}`).catch(() => import(`./component/WidgetComponent`))
    );

const DynamicWidgetV2 = (props) => {
    const { wid } = useParams();

    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const { userDetails } = useSelector(({ user }) => user);
    const {
        singleAppDetails
    } = useSelector(({ apps }) => apps);
    const { commonFormSubmittingLoading, widgetLoading, dynamicWidget, widgetPackageList } = useSelector(({ dynamicForm }) => dynamicForm);
    const {
        partnerDetails
    } = useSelector(({ settings }) => settings);
    const {
        paymentMsisdnList,
        genieCardList
    } = useSelector(({ appWizard }) => appWizard);
    const [widget, setWidget] = useState(true);
    const [views, setViews] = useState([]);
    const [viewVal, setViewVal] = useState(null);
    const navigate = useNavigate();
    const paths = window.location.pathname.split('/');
    const [agent, setAgent] = useState('');
    const [paymentNumber, setPaymentNumber] = useState(null);
    const [paymentCard, setPaymentCard] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState("add_to_bill");
    const [displayCardModal, setDisplayCardModal] = useState(false);
    const [displayMsisdnModal, setDisplayMsisdnModal] = useState(false);

    const [validator] = useState(new SimpleReactValidator({
        autoForceUpdate: this
    }))

    useEffect(() => {
        if(userDetails) {
            const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
            if(tenantGroupId && !singleAppDetails) {
                dispatch(fetchSingleApp(wid, tenantGroupId));
                // if (!partnerDetails || partnerDetails.length === 0) {
                //     dispatch(fetchPartnerDetails(tenantGroupId));
                // }
                // if (!paymentMsisdnList || paymentMsisdnList.length === 0) {
                //     dispatch(fetchPaymentMsisdnList(tenantGroupId, wid));
                // }
                // if (!genieCardList || genieCardList.length === 0) {
                //     dispatch(fetchGenieCardList(tenantGroupId));
                // }
            }
        }
    }, [userDetails, wid])

    // useEffect(() => {
    //     if (paymentMsisdnList) {
    //         paymentMsisdnList.map((value, index) => {
    //             if (value.isPrimary) {
    //                 setPaymentNumber(value);
    //             }
    //         });
    //     }
    // }, [paymentMsisdnList])
    //
    // useEffect(() => {
    //     if (genieCardList) {
    //         genieCardList.map((value, index) => {
    //             if (value.isPrimary) {
    //                 setPaymentCard(value);
    //             }
    //         });
    //     }
    // }, [genieCardList])

    useEffect(() => {
        const path = window.location.pathname.split('/');
        let customizedWidgetBody = typeof dynamicWidget?.widgetBody === 'string' ? JSON.parse(dynamicWidget?.widgetBody) : dynamicWidget?.widgetBody;
        // console.log("@@@@@@@@@@@@@@@@", customizedWidgetBody);
        const formattedRoute = customizedWidgetBody?.formGetUrl?.startsWith('/api/') ? customizedWidgetBody?.formGetUrl.slice(4) : customizedWidgetBody?.formGetUrl;

        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";

        if ((path[Constants.BASEURLPATHLENGTH + 3] === 'view' || path[Constants.BASEURLPATHLENGTH + 3] === 'update') && singleAppDetails && singleAppDetails.widgetDetails && formattedRoute) {
            fetchOneConfig(tenantGUID, singleAppDetails.widgetDetails.uuid, path[Constants.BASEURLPATHLENGTH + 4], formattedRoute).then(response => {
                // console.log("############", response.data);
                setViewVal(typeof response.data === 'string' ? JSON.parse(response.data) : response.data);
                dispatch(dynamicLoadingStop());
            }).catch(error => {
                message.error("Error in getting widget info");
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dynamicWidget, userDetails]);

    useEffect(() => {
        dispatch(resetDynamicRedux());
        dispatch(dynamicWidgetLoading());
        const type = window.location.pathname.split('/')[Constants.BASEURLPATHLENGTH + 3];
        if (singleAppDetails && singleAppDetails.widgetDetails) {
            dispatch(getDynamicWidget(singleAppDetails.widgetDetails.uuid, type))
            // dispatch(fetchWidgetPackages(singleAppDetails.widgetDetails.uuid));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleAppDetails]);

    useEffect(() => {
        if (dynamicWidget) {
            const widgetBody = typeof dynamicWidget.widgetBody === 'string' ? JSON.parse(dynamicWidget.widgetBody) : dynamicWidget.widgetBody;
            // const widgetBody = sample;
            setWidget(widgetBody);
            const components = widgetBody.component.map(data => {
                if(data.componentName !== 'MPTermsAndConditions') {
                    const View = importView(data.componentName);
                    return <View key={Math.random()} inputData={data} form={form} viewVal={viewVal}
                                 loading={commonFormSubmittingLoading} userDetails={userDetails}/>;
                }
            });

            setViews(components);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dynamicWidget, viewVal]);

    const onFinish = values => {
        const billingStatus = (userDetails && userDetails.OwnedEnterprises[0] && userDetails.OwnedEnterprises[0].Enterprise && userDetails.OwnedEnterprises[0].Enterprise.billingStatus) ? userDetails.OwnedEnterprises[0].Enterprise.billingStatus : "";
        const formattedRoute = widget.formSubmitUrl?.startsWith('/api/') ? widget.formSubmitUrl.slice(4) : widget.formSubmitUrl;
        if(validator.allValid()) {
            if (widget.confirmationMessage) {
                confirm({
                    icon: <ExclamationCircleOutlined />,
                    content: widget.confirmationMessage,
                    onOk() {
                        values['widgetTitle'] = dynamicWidget.widgetTitle;
                        const tenantId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";

                        // const ratePlansList = [];
                        // if (values["packageUUID"] !== undefined) {
                        //     ratePlansList.push(values["packageUUID"]);
                        // }

                        // const formObj = {
                        //     ratePlans: ratePlansList,
                        //     status: "Subscription",
                        //     paymentMethodId: billingStatus == "Non_invoice" ? (paymentMethod == "add_to_bill" ? 1 : 9) : 4,
                        //     msisdn: billingStatus == "Non_invoice" ? (paymentMethod == "add_to_bill" ? (paymentNumber ? paymentNumber.msisdn : null) : null) : null,
                        //     invoiceNo: billingStatus == "Non_invoice" ? (paymentMethod == "add_to_bill" ? null : (paymentCard ? paymentCard.invoiceNo : null)) : null,
                        //     maId: agent ? agent : null
                        // }

                        // dispatch(postCommonWidgetCharging(tenantId, singleAppDetails.widgetDetails.uuid, formObj, singleAppDetails.widgetDetails, navigate,values, formattedRoute, widget.redirectToList, singleAppDetails.widgetDetails.signupUrl, singleAppDetails));
                        dispatch(formDataPostDispatch(values,formattedRoute, tenantId, singleAppDetails.widgetDetails.uuid, widget.redirectToList, singleAppDetails.widgetDetails.signupUrl, navigate, singleAppDetails, singleAppDetails.widgetDetails.isApprovalRequired));
                        if (widget.formResetFields && widget.formResetFields.length > 0) {
                            // console.log("!!!!!!!!!", widget);
                            form.resetFields(widget.formResetFields);
                        } else {
                            // console.log("$$$$$$$$$$$$");
                            form.resetFields();
                        }
                    },
                    cancelText: widget.confirmationCancelText,
                    okText: widget.confirmationOkText
                });
            } else {
                values['widgetTitle'] = dynamicWidget.widgetTitle;
                const tenantId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
                dispatch(commonPostDispatch(values, formattedRoute, tenantId, singleAppDetails.widgetDetails.uuid, widget.redirectToList, singleAppDetails.widgetDetails.signupUrl, navigate));
                if (widget.formResetFields && widget.formResetFields.length > 0) {
                    form.resetFields(widget.formResetFields);
                } else {
                    form.resetFields();
                }
            }
        } else {
            validator.showMessages();
        }
    }

    const onChangeAgent = (e) => {
        setAgent(e.target.value)
    }

    const changePaymentMethod = (name) => (event) => {
        setPaymentMethod(event.target.value);
    };

    const onChangePayNumber = (e) => {
        setPaymentNumber(JSON.parse(e.target.value));
    }

    const onChangePayCard = (e) => {
        setPaymentCard(JSON.parse(e.target.value));
    }

    return (
        <React.Fragment>
            <Spin
                size="large"
                // spinning={widgetLoading}
                spinning={false}
            >
                {
                    AuthHandler.isAuthorized(props.auth, widget.screenLevelAccess) && userDetails !== null ?
                        <div className="main-content main-spacer-x-y">
                            <PageHeaderDynamicFormComponent
                                page={'create'}
                            />
                            <div className="card added-app-thumbnnail__wrapper">
                                <div
                                    className="added-app-thumbnail__inner"
                                    style={{
                                        backgroundImage: `url(${(singleAppDetails && singleAppDetails.widgetDetails !== null) ? singleAppDetails.widgetDetails.leftImage : ""})`
                                    }}
                                ></div>
                            </div>
                            <div className="card panel content-holder-panels added-app-form__wrapper">
                                <div className="panel-header">
                                    <div className="panel-header-content__wrapper">
                                        <h4 className="panel-header-title">{widget.widgetTitle}</h4>
                                    </div>
                                </div>
                                <div className="panel-content">
                                    <div className="wiz-content-form__wrapper dynamic_form_wrapper">
                                        {widget.widgetDescription2 ?
                                            <p>
                                                {widget.widgetDescription}&nbsp;
                                                {widget.widgetDescriptionLink && <a target="_blank" href={widget.widgetDescriptionLink}>{widget.widgetDescriptionLinkText}</a>}&nbsp;
                                                {widget.widgetDescription2}
                                            </p> :
                                            <p>
                                                {widget.widgetDescription}&nbsp;
                                                {widget.widgetDescriptionLink && <a target="_blank" href={widget.widgetDescriptionLink}>{widget.widgetDescriptionLinkText}</a>}&nbsp;
                                            </p>
                                        }
                                        <br />
                                        <Form
                                            form={form}
                                            layout={widget.formLayout}
                                            onFinish={onFinish}
                                            size={widget.formsElementSize}
                                            scrollToFirstError
                                        >
                                            {/*<div className="packages-common-widget">*/}
                                            {/*    <Form.Item*/}
                                            {/*        label="Select Your Package"*/}
                                            {/*        name="packageUUID"*/}
                                            {/*        required={true}*/}
                                            {/*        rules={[*/}
                                            {/*            {*/}
                                            {/*                "message": "You must select a package",*/}
                                            {/*                "required": true,*/}
                                            {/*                "whitespace": true*/}
                                            {/*            }*/}
                                            {/*        ]}*/}
                                            {/*    >*/}
                                            {/*        /!*<Radio.Group onChange={onChangeRadioButton} className="package-selection">*!/*/}
                                            {/*        <Radio.Group className="package-selection">*/}
                                            {/*            {widgetPackageList && widgetPackageList.map(d => (*/}
                                            {/*                <Radio value={d.UUID} className="package">*/}
                                            {/*                    <div className="d-block">*/}
                                            {/*                        <div className="p-2"><b>{d.DESCRIPTION}</b></div>*/}
                                            {/*                        <div className="p-2">*/}
                                            {/*                            <table>*/}
                                            {/*                                <tr>*/}
                                            {/*                                    <td>Description</td>*/}
                                            {/*                                    <td>  : {d.NAME}</td>*/}
                                            {/*                                </tr>*/}
                                            {/*                                <tr>*/}
                                            {/*                                    <td>Price Value</td>*/}
                                            {/*                                    <td>: {d.PRICE.VALUE}</td>*/}
                                            {/*                                </tr>*/}
                                            {/*                                <tr>*/}
                                            {/*                                    <td>Frequency</td>*/}
                                            {/*                                    <td> : {d.FREQUENCY}</td>*/}
                                            {/*                                </tr>*/}
                                            {/*                            </table>*/}
                                            {/*                        </div>*/}
                                            {/*                    </div>*/}
                                            {/*                </Radio>*/}
                                            {/*            ))}*/}
                                            {/*        </Radio.Group>*/}
                                            {/*    </Form.Item>*/}
                                            {/*</div>*/}
                                            {/*<PaymentSelect*/}
                                            {/*    userDetails={userDetails}*/}
                                            {/*    appDetails={singleAppDetails}*/}
                                            {/*    paymentNumber={paymentNumber}*/}
                                            {/*    setPaymentNumber={setPaymentNumber}*/}
                                            {/*    paymentCard={paymentCard}*/}
                                            {/*    paymentMethod={paymentMethod}*/}
                                            {/*    changePaymentMethod={changePaymentMethod}*/}
                                            {/*    paymentMsisdnList={paymentMsisdnList}*/}
                                            {/*    setDisplayMsisdnModal={setDisplayMsisdnModal}*/}
                                            {/*    genieCardList={genieCardList}*/}
                                            {/*    setDisplayCardModal={setDisplayCardModal}*/}
                                            {/*    validator={validator} />*/}
                                            {/*<AgentSelect*/}
                                            {/*    partnerDetails={partnerDetails}*/}
                                            {/*    onChangeAgent={onChangeAgent}*/}
                                            {/*    agent={agent}*/}
                                            {/*    validator={validator} />*/}
                                            <Row gutter={[0, 0]}>
                                                <Col className="gutter-row" xs={24} sm={24} md={24}
                                                    lg={24}
                                                    xl={24}>
                                                    {
                                                        dynamicWidget ?
                                                            <React.Suspense fallback="Loading views...">
                                                                {views}
                                                            </React.Suspense>
                                                            :
                                                            <Empty
                                                                image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                    }
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                            {/*<Model*/}
                            {/*    display={displayMsisdnModal}*/}
                            {/*    onClose={() => setDisplayMsisdnModal(false)}*/}
                            {/*    onConfirm={() => setDisplayMsisdnModal(false)}*/}
                            {/*>*/}
                            {/*    <div className="card panel content-holder-panels">*/}
                            {/*        <div className="panel-header">*/}
                            {/*            <div className="panel-header-content__wrapper">*/}
                            {/*                <h4 className="panel-header-title">Your Numbers</h4>*/}
                            {/*                <p className="panel-header-subtitle">*/}
                            {/*                    List of numbers reserved for charging; choose one.*/}
                            {/*                </p>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        /!* panel table *!/*/}
                            {/*        <div className="panel-table-listing__wrapper">*/}
                            {/*            <div className="panel-table-listing">*/}
                            {/*                <div className="panel-table-content-list">*/}
                            {/*                    {paymentMsisdnList.map((value, index) => {*/}
                            {/*                        if(value.status === 'Active'){*/}
                            {/*                            return (*/}
                            {/*                                <div className="panel-table-list-item" key={index}>*/}
                            {/*                                    <div className="panel-table-listing-item__inner">*/}
                            {/*                                        <div className="feature-details__wrapper">*/}
                            {/*                                            <div className="card app-media-holder orange-shade sm-center-icon-card">*/}
                            {/*                                    <span className="icon__wrapper">*/}
                            {/*                                        <i className="bi bi-sim-fill" />*/}
                            {/*                                    </span>*/}
                            {/*                                            </div>*/}
                            {/*                                            <div className="feature-details-title__wrapper no-shade">*/}
                            {/*                                                <span className="title">{value.msisdn}</span>*/}
                            {/*                                            </div>*/}
                            {/*                                        </div>*/}
                            {/*                                    </div>*/}
                            {/*                                    <div className="panel-table-listing-item__inner">*/}
                            {/*                                        <div className="panel-table-right-align-item__wrapper">*/}
                            {/*                                            <div className="panel-table-right-align-item">*/}
                            {/*                                                <div className="form-group__wrapper"></div>*/}
                            {/*                                            </div>*/}
                            {/*                                            <label className="switch">*/}
                            {/*                                                <input*/}
                            {/*                                                    type="radio"*/}
                            {/*                                                    name='number-change'*/}
                            {/*                                                    onChange={onChangePayNumber}*/}
                            {/*                                                    value={JSON.stringify(value)}*/}
                            {/*                                                    checked={paymentNumber?.msisdn == value.msisdn}*/}
                            {/*                                                />*/}
                            {/*                                                <span className="slider round" />*/}
                            {/*                                            </label>*/}
                            {/*                                        </div>*/}
                            {/*                                    </div>*/}
                            {/*                                </div>*/}
                            {/*                            )*/}
                            {/*                        }*/}
                            {/*                    })}*/}

                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}

                            {/*</Model>*/}
                            {/*<Modal*/}
                            {/*    display={displayCardModal}*/}
                            {/*    onClose={() => setDisplayCardModal(false)}*/}
                            {/*    onConfirm={() => setDisplayCardModal(false)}*/}
                            {/*>*/}
                            {/*    <div className="card panel content-holder-panels">*/}
                            {/*        <div className="panel-header">*/}
                            {/*            <div className="panel-header-content__wrapper">*/}
                            {/*                <h4 className="panel-header-title">Your Cards</h4>*/}
                            {/*                <p className="panel-header-subtitle">*/}
                            {/*                    List of your cards, reserved for payments, other, other, etc.*/}
                            {/*                </p>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        <div className="panel-table-listing__wrapper">*/}
                            {/*            <div className="panel-table-listing">*/}
                            {/*                <div className="panel-table-content-list">*/}
                            {/*                    {genieCardList.map((value, index) => {*/}
                            {/*                        // console.log("########## 2", paymentNumber?.msisdn, value.msisdn);*/}
                            {/*                        return (*/}
                            {/*                            <div className="panel-table-list-item" key={index}>*/}
                            {/*                                <div className="panel-table-listing-item__inner">*/}
                            {/*                                    <div className="feature-details__wrapper">*/}
                            {/*                                        <div className="card app-media-holder orange-shade sm-center-icon-card">*/}
                            {/*                                    <span className="icon__wrapper">*/}
                            {/*                                        <i className="bi bi-sim-fill" />*/}
                            {/*                                    </span>*/}
                            {/*                                        </div>*/}
                            {/*                                        <div className="feature-details-title__wrapper no-shade">*/}
                            {/*                                            <span className="title">{value.maskCardNo}</span>*/}
                            {/*                                        </div>*/}
                            {/*                                    </div>*/}
                            {/*                                </div>*/}
                            {/*                                <div className="panel-table-listing-item__inner">*/}
                            {/*                                    <div className="panel-table-right-align-item__wrapper">*/}
                            {/*                                        <div className="panel-table-right-align-item">*/}
                            {/*                                            <div className="form-group__wrapper"></div>*/}
                            {/*                                        </div>*/}
                            {/*                                        <label className="switch">*/}
                            {/*                                            <input*/}
                            {/*                                                type="radio"*/}
                            {/*                                                name='card-change'*/}
                            {/*                                                onChange={onChangePayCard}*/}
                            {/*                                                value={JSON.stringify(value)}*/}
                            {/*                                                checked={paymentCard?.invoiceNo === value.invoiceNo}*/}
                            {/*                                            />*/}
                            {/*                                            <span className="slider round" />*/}
                            {/*                                        </label>*/}
                            {/*                                    </div>*/}
                            {/*                                </div>*/}
                            {/*                            </div>*/}
                            {/*                        )*/}
                            {/*                    })}*/}

                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}

                            {/*</Modal>*/}
                        </div>
                        :
                        <>Access denied</>
                }
            </Spin>
        </React.Fragment>
    );
}
    ;

export default DynamicWidgetV2;