/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Form, Input } from 'antd';
import {AddressModel} from "../models/address.model";

const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

type Props = {
    setLocation: (values: AddressModel) => void,
    city: string
}

const AddressForm = ({ setLocation, city }: Props) => {

    return (
        <React.Fragment>
            <div>
                <div className="card-body gws__body">
                    <h4 className="gws-title">Installation Address</h4>
                    <Form
                        {...layout}
                        name="address-form"
                        style={{ maxWidth: 600, marginInline: 'auto', textAlign: 'left' }}
                        initialValues={{ remember: true }}
                        onFinish={setLocation}
                        autoComplete="off"
                        labelAlign={"right"}
                    >
                        <Form.Item
                            label="Address 1"
                            name="address1"
                            rules={[
                                {
                                    required: true,
                                    message: 'You can\'t keep this as empty.',
                                },
                                // {
                                //     max: 20,
                                //     message: 'Maximum length is exceeded.',
                                // }
                            ]}
                        >
                            <Input placeholder="Type the Address 1" />
                        </Form.Item>
                        <Form.Item
                            label="Address 2"
                            name="address2"
                            rules={[
                                // {
                                //     required: true,
                                //     message: 'You can\'t keep this as empty.',
                                // }
                            ]}
                        >
                            <Input placeholder="Type the Address 2" />
                        </Form.Item>
                        <Form.Item
                            label="City"
                            name="city"
                            initialValue={city}
                            rules={[
                                {
                                    required: true,
                                    message: 'You can\'t keep this as empty.',
                                }
                            ]}
                        >
                            <Input placeholder="Type the City" />
                        </Form.Item>
                        <Form.Item {...tailLayout} className={"wizard-action"}>
                            <Button type="primary" htmlType="submit" className={"btn btn-primary btn-sm"}>
                                Next
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    );
}

export default AddressForm;