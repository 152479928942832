import React, { FocusEvent, useEffect, useRef, useState } from 'react';
import { CollapsibleItemHeader } from './CollapsibleItemHeader'
import { AccountCreationSectionsEnums } from '../../../enum'
import { FileUpload, InputTextArea, InputTextField } from '../../../components'
import { RadioButton } from '../../../components/RadioButton'
import AuthHandler from '../../../helper/AuthHandler';
import Auth from '../../../models/response/auth.model';
import { Spin } from 'antd';
import { getPartnerSearchSuggestions } from '../../../services/settings.service';
import { useSelector } from 'react-redux';
import * as CONSTANTS from '../../../constants';

interface Partner {
  firstName: string,
  lastName: string,
  uuid: string
}

type Props = {
  auth: Auth,
  status: string,
  show: boolean,
  data: any,
  ownerData: any,
  validator: any,
  nic: string,
  isOwnerSame: boolean,
  setShow: (show: boolean) => void,
  setIsOwnerSame: (ownerSame: boolean) => void,
  onSave: () => void,
  handleDataChange: (name: string) => (event: any) => void,
  handleOwnerDataChange: (name: string) => (event: any) => void,
  renderDistrictList: () => JSX.Element[],
  setLogo: (logo: any) => void,
  setBrDoc: (brDoc: any) => void,
  onChangeLogo: (file: any) => void
  logo: any,
  onDropLogo: (file: any) => void
  beforeUploadLogo: (logo: any) => boolean
  maxLogoCount: number
  multipleLogo: boolean,
  brDoc: any,
  onChangeBr: (file: any) => void,
  onDropBr: (file: any) => void
  beforeUploadBr: (brDoc: any) => boolean,
  maxBrCount: number,
  multipleBr: boolean,
  onBlurBrc: (event: FocusEvent<HTMLInputElement>) => void,
  brcError: string,
  brUploadSizeError: string,
  logoUploadSizeError: string,
  forceUpdate: () => void,
  progress: number,
  industryTypeList: string[],
  maData: any,
  handleMaDataChange: (name: string) => (event: any) => void,
  bankList: any[],
  bankCode: string,
  onChangeBankCode: (e: any) => void,
  branchList: any[],
  branchCode: string,
  onChangeBranchCode: (e: any) => void,
  loading: boolean,
  setSelectedPartnerUuid: (uuid: string) => void,
  setSelectPartnerErr: (err: boolean) => void,
  onEditBusinessProfile: () => void,
  isEditBusinessProfile: boolean,
  isBusinessProfileOnSave: boolean,
  onBlurEmail: (event: FocusEvent<HTMLInputElement>) => void,
  emailError: string
}

const BusinessProfileSection = ({
                                  status,
                                  show,
                                  data,
                                  ownerData,
                                  validator,
                                  nic,
                                  isOwnerSame,
                                  setShow,
                                  setIsOwnerSame,
                                  onSave,
                                  handleDataChange,
                                  handleOwnerDataChange,
                                  renderDistrictList,
                                  setLogo,
                                  setBrDoc,
                                  onChangeLogo,
                                  logo,
                                  onDropLogo,
                                  beforeUploadLogo,
                                  maxLogoCount,
                                  multipleLogo,
                                  brDoc,
                                  onChangeBr,
                                  onDropBr,
                                  beforeUploadBr,
                                  maxBrCount,
                                  multipleBr,
                                  onBlurBrc,
                                  brcError,
                                  brUploadSizeError,
                                  logoUploadSizeError,
                                  forceUpdate,
                                  progress,
                                  industryTypeList,
                                  auth,
                                  maData,
                                  handleMaDataChange,
                                  bankList,
                                  bankCode,
                                  onChangeBankCode,
                                  branchList,
                                  branchCode,
                                  onChangeBranchCode,
                                  loading,
                                  setSelectedPartnerUuid,
                                  setSelectPartnerErr,
                                  onEditBusinessProfile,
                                  isEditBusinessProfile,
                                  isBusinessProfileOnSave,
                                  onBlurEmail,
                                  emailError
                                }: Props) => {

  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [newPartnerUuid, setNewpartnerUuid] = useState('NON_AGENT');
  const [partnerSelected, setPartnerSelected] = useState(false);
  const [partnerNone, setPartnerNone] = useState(true);
  const [agent, setAgent] = useState({
    agent: ''
  });
  const [error, setError] = useState('');

  const dropdownRef = useRef<HTMLDivElement>(null);

  const {
    userDetails
  } = useSelector<any, any>(({ user }) => user);

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      handleClickOutside(event);
    };

    if (showDropdown) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [showDropdown]);

  const renderIndustryList = () => {
    let list: JSX.Element[] = [<option key={'-1'} value="" disabled hidden>Not selected</option>]
    industryTypeList.map((industryType, index) => {
      list.push(<option key={index} value={industryType}>{industryType}</option>)
    })
    return list
  }

  const handleInputChange = (event: any) => {
    setPartnerSelected(false);
    setInputValue(event.target.value);
    if (event.target.value == null || event.target.value == '') {
      setError('You can’t keep this as empty.');
    }
  };

  const handleDropDownItem = (item: any) => {
    setInputValue(item.firstName + " " + item.lastName);
    setNewpartnerUuid(item.uuid);
    setPartnerSelected(true);
    setSuggestions([]);
    setShowDropdown(false);
    setSelectedPartnerUuid(item.uuid);
    //handleDataChange('selectedPartner')(event);
  }

  const onClickX = () => {
    setPartnerSelected(false);
    setInputValue('');
    setNewpartnerUuid('');
    setSelectedPartnerUuid('');
  }
  const fetchSuggestions = async () => {
    try {

      const response = await getPartnerSearchSuggestions(inputValue).then((response) => {
        return response;
      }).catch((error: any) => {
        return error;
      })

      // console.log("response", response);

      const extractedUserNames = response.data.data.map((data: any) => data.firstName);
      // console.log("extr names", extractedUserNames);
      const extractedUuid = response.data.data.map((data: any) => data.uuid);
      const partners = response.data.data;
      // console.log("partners", partners);
      setSuggestions(partners);
      // console.log("sugg", suggestions);
      // setNewpartnerUuid(extractedUuid);
      setShowDropdown(true);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  };
  useEffect(() => {
    //if partner set to None, bypass the useEffect and handle partnerNone in the immediate next useEffect
    if (!partnerNone) {
      // Only fetch suggestions if there is input value
      if (inputValue && (!partnerSelected)) {
        fetchSuggestions();
      } else if (!inputValue) {
        // fetchSuggestions();
        setSuggestions([]);
        // setNewpartnerUuid('');
        setShowDropdown(false);
        setNewpartnerUuid('NON_AGENT');
        // setSelectedPartnerUuid("NON_AGENT");
      }
      else if (!partnerSelected) {
        fetchSuggestions();
      }
      else {
        // setInputValue('None');
        setSuggestions([]);
        // setNewpartnerUuid('');
        setShowDropdown(false);
        setNewpartnerUuid('NON_AGENT');
        // setSelectedPartnerUuid("NON_AGENT");
      }
    }
  }, [inputValue]);


  useEffect(() => {
    if (partnerNone == true) {
      setNewpartnerUuid('NON_AGENT');
      setSelectedPartnerUuid("NON_AGENT");
      setInputValue('');
      setPartnerSelected(false);
    }
  }, [partnerNone]);

  //set selected partner from businessDetails object when the section is in view mode after submit
  useEffect(() => {
    if (userDetails && userDetails.OwnedEnterprises[0]) {
      setInputValue(userDetails.OwnedEnterprises[0].MarketingAdmins && Array.isArray(userDetails.OwnedEnterprises[0].MarketingAdmins) && (userDetails.OwnedEnterprises[0].MarketingAdmins).length > 0 ? (userDetails.OwnedEnterprises[0].MarketingAdmins[0].firstName + " " + userDetails.OwnedEnterprises[0].MarketingAdmins[0].lastName) : "");
      setPartnerNone(false);
    }
  }, [userDetails])

  useEffect(() => {
    if (userDetails && userDetails.OwnedEnterprises[0]) {
      if (userDetails.OwnedEnterprises[0].MarketingAdmins && Array.isArray(userDetails.OwnedEnterprises[0].MarketingAdmins) && userDetails.OwnedEnterprises[0].MarketingAdmins.length > 0 && (userDetails.OwnedEnterprises[0].MarketingAdmins[0].uuid === CONSTANTS.REACT_APP_COMMON_AGENT_USER_UUID)) {
        setPartnerNone(true);
      }
    }
  }, [userDetails])

  useEffect(() => {
    setSelectedPartnerUuid("NON_AGENT");
  }, []);

  useEffect(() => {
    if (!partnerNone && inputValue == '' && !partnerSelected) {
      setSelectPartnerErr(true);
    } else {
      setSelectPartnerErr(false);
    }
  }, [partnerNone, inputValue])

  useEffect(() => {
    if (isEditBusinessProfile) {
      if (userDetails && userDetails.OwnedEnterprises[0]) {
        if (userDetails.OwnedEnterprises[0].MarketingAdmins && Array.isArray(userDetails.OwnedEnterprises[0].MarketingAdmins) && userDetails.OwnedEnterprises[0].MarketingAdmins.length > 0 && (userDetails.OwnedEnterprises[0].MarketingAdmins[0].uuid)) {
          setSelectedPartnerUuid(userDetails.OwnedEnterprises[0].MarketingAdmins[0].uuid);
          setPartnerNone(false);
        }
      }
    }
  }, [userDetails, isEditBusinessProfile])

  return (
      <>
        <div className="single-wiz-step__wrapper" id="business-section">
          <CollapsibleItemHeader
              title='Business Profile'
              desc='Your business details.'
              status={status}
              isVisible={show}
              name={AccountCreationSectionsEnums.HOME_BUSINESS_PROFILE}
              handleCollapsButton={setShow}
          />
          <div className={"single-wiz-step__content " + (show ? "show" : "")}>
            <div className="wiz-content-form__wrapper">
              {AuthHandler.isAuthorized(auth, 'add-marketing-agent-details') && <>
                <h4 className="inner-title">Profile Type</h4>
                <div className="form-group__wrapper mb-3">
                  <RadioButton
                      label=""
                      name="role"
                      id="mp_marketing_admin"
                      value={"mp_marketing_admin"}
                      data={"Marketing Agent"}
                      selectedValue={maData.role}
                      iconClass="bi-person-fill"
                      icon=""
                      canChange={false}
                      setSelctedItem={handleMaDataChange}
                      disabled={progress !== 50}
                  />
                  <RadioButton
                      label=""
                      name="role"
                      id="mp_dialog_agent"
                      value={"mp_dialog_agent"}
                      data={"Dialog Account Manager"}
                      selectedValue={maData.role}
                      iconClass="bi-person-fill-gear"
                      icon=""
                      canChange={false}
                      setSelctedItem={handleMaDataChange}
                      disabled={progress !== 50}
                  />
                </div>
              </>}
              {maData.role != 'mp_dialog_agent' && <>
                <h4 className="inner-title">Business Details</h4>
                <div className="form-group__wrapper">
                  <div className="form-group">
                    <InputTextField
                        name='business_name'
                        label='Business name'
                        placeholder='Svasthika Pvt Ltd'
                        value={data.business_name}
                        setValue={handleDataChange}
                        validator={validator}
                        rules={['required', { max: 50 }]}
                        disabled={progress !== 50}
                        status="default"
                        validationMessage=""
                        forceUpdate={forceUpdate} />
                  </div>
                  <div className="form-group">
                    <InputTextField
                        name='business_email'
                        label='Business email'
                        placeholder='email@domain.com'
                        value={data.business_email}
                        setValue={handleDataChange}
                        validator={validator}
                        rules={['required', 'email']}
                        disabled={progress !== 50 && !isEditBusinessProfile}
                        status="default"
                        validationMessage=""
                        forceUpdate={forceUpdate}
                        onBlurValue={onBlurEmail}
                        errorText={emailError} />
                  </div>
                </div>
                <div className="form-group__wrapper">
                  <div className="form-group">
                    <InputTextArea
                        name='business_description'
                        label='Business description'
                        placeholder='Describe the nature of your business. Eg: Entertainment, Education, Retail business, Food and beverages, Financial etc..'
                        rows={3}
                        value={data.business_description}
                        setValue={handleDataChange}
                        validator={validator}
                        rules={['required', { min: 10 }, { max: 250 }]}
                        disabled={progress !== 50 && !isEditBusinessProfile}
                        status="default"
                        validationMessage=""
                        forceUpdate={forceUpdate} />
                  </div>
                </div>
                <div className="form-group__wrapper">
                  <div className="form-group">
                    {/* <InputTextField
                name='business_reg_number'
                label='Business registration number'
                placeholder='00**********'
                value={data.business_reg_number}
                setValue={handleDataChange}
                validator={validator}
                rules={['required', 'alpha_num', { max: 12 }]}
                disabled={progress !== 50}
                status="default"
                validationMessage=""
                onBlurValue={onBlurBrc}
                errorText={brcError}
                forceUpdate={forceUpdate}
              /> */}
                    <div className="form-element__wrapper mb-3 ">
                      <label htmlFor="" className="form-label">Industry</label>
                      <select
                          name="industry"
                          id="industry"
                          className="form-control"
                          value={data.industry}
                          onChange={handleDataChange('industry')}
                          disabled={progress !== 50 && !isEditBusinessProfile}
                      >
                        {renderIndustryList()}
                      </select>
                      <div className="validation invalid-feedback">{validator.message('industry', data.industry, "required")}</div>
                    </div>
                  </div>
                </div>
                {(!(auth?.realmRoles.includes('mp_marketing_admin')) && !(auth?.realmRoles.includes('mp_dialog_agent')) && !(auth?.realmRoles.includes('mp_common_agent')) && !(auth?.realmRoles.includes('mp_content_generator'))) &&
                    <div className="form-group__wrapper">
                      <div className="form-group">
                        <div className="form-element__wrapper mb-3 has-dropdown">
                          <label htmlFor="" className="form-label">Who introduced you to Marketplace?</label>
                          <div className="form-check mb-3">
                            <input className="form-check-input" type="checkbox" defaultValue="" id="flexCheckDefault2" onChange={() => setPartnerNone(!partnerNone)} checked={partnerNone} disabled={(progress !== 50 && !isEditBusinessProfile)} />
                            <label className="form-check-label" htmlFor="flexCheckDefault2">
                              None
                            </label>
                          </div>
                          <div className="icon-inside left d-flex btn-with-group">
                            <span className="in-icon"><i className="bi bi-search" /></span>
                            <input
                                type="text"
                                className={partnerSelected ? 'form-control highlighted selected' : 'form-control'}
                                id="userRoleAssignSearch"
                                placeholder="Type and select your partner"
                                spellCheck="false"
                                data-ms-editor="true"
                                value={inputValue}
                                onChange={handleInputChange}
                                required={partnerNone == true ? false : true}
                                disabled={(progress !== 50 && !isEditBusinessProfile) || (partnerSelected == true) || (partnerNone == true ? true : false)}
                                autoComplete="off"
                            />
                            {partnerSelected &&
                                <span className='cancel' onClick={onClickX}>X</span>
                            }
                          </div>
                          {!partnerNone && inputValue == '' && <div className="validation invalid-feedback">Please select a partner or select 'None'.</div>}
                          {showDropdown && suggestions && Array.isArray(suggestions) && suggestions.length > 0 &&
                              <div className="dropdown" style={{ maxHeight: '200px', overflowY: 'auto' }} ref={dropdownRef}>
                                <ul className="dropdown-list">
                                  {suggestions.map((item: Partner) => (
                                      <li className="dropdown-item" key={item.uuid} onClick={() => handleDropDownItem(item)} ><a href="javascript:void(0)">{item.firstName} {item.lastName}</a></li>
                                  ))}
                                </ul>
                              </div>
                          }
                        </div>
                      </div>
                    </div>
                }
                <div className="form-group__wrapper">
                  <div className="form-group">
                    <FileUpload
                        label={"Upload your business logo"}
                        required={false}
                        classes={""}
                        onChangeFile={onChangeLogo}
                        fileList={logo}
                        name={'logo'}
                        rules={[]}
                        onDropFile={onDropLogo}
                        beforeUploadFile={beforeUploadLogo}
                        maxCount={maxLogoCount}
                        multiple={multipleLogo}
                        fileTypes={'.png,.jpg,.jpeg,.jfif'}
                        validator={validator}
                        disabled={progress !== 50 && !isEditBusinessProfile}
                        uploadSizeError={logoUploadSizeError}
                        maxSize={2}
                    />
                  </div>
                  <div className="form-group">
                  </div>
                </div>
                <hr />
                <h4 className="inner-title">Contact Informations</h4>
                <div className="form-group__wrapper">
                  <div className="form-group">
                    <InputTextField
                        name='number_and_lane'
                        label='PO Box No/ Lane'
                        placeholder='PO BOX, House No.'
                        value={data.number_and_lane}
                        setValue={handleDataChange}
                        validator={validator}
                        rules={['required', { max: 25 }]}
                        disabled={progress !== 50 && !isEditBusinessProfile}
                        status="default"
                        validationMessage=""
                        forceUpdate={forceUpdate} />
                  </div>
                  <div className="form-group">
                    <InputTextField
                        name='street'
                        label='Street'
                        placeholder='Street/ Neighbourhood'
                        value={data.street}
                        setValue={handleDataChange}
                        validator={validator}
                        rules={['required', { max: 25 }]}
                        disabled={progress !== 50 && !isEditBusinessProfile}
                        status="default"
                        validationMessage=""
                        forceUpdate={forceUpdate} />
                  </div>
                </div>
                <div className="form-group__wrapper">
                  <div className="form-group">
                    <InputTextField
                        name='city'
                        label='City'
                        placeholder='City'
                        value={data.city}
                        setValue={handleDataChange}
                        validator={validator}
                        rules={['required', { max: 25 }]}
                        disabled={progress !== 50 && !isEditBusinessProfile}
                        status="default"
                        validationMessage=""
                        forceUpdate={forceUpdate} />
                  </div>
                  <div className="form-group">
                    <div className="form-element__wrapper mb-3 ">
                      <label htmlFor="" className="form-label">District
                        <span aria-label="required"></span>
                      </label>
                      {/* <div className="icon-inside right">
                                <span className="in-icon"><i className="bi bi-chevron-down" /></span>
                                <input type="text" className="form-control" id="" placeholder="Not selected" />
                              </div> */}
                      <select name="district" id="district" className="form-control" value={data.district}
                              onChange={handleDataChange('district')}
                              disabled={progress !== 50 && !isEditBusinessProfile}
                      >
                        {renderDistrictList()}
                      </select>
                      <div className="validation invalid-feedback">{validator.message('district', data.district, "required")}</div>
                    </div>
                  </div>
                </div>
                {auth?.realmRoles.includes('mp_content_generator') && <>
                  <div className="form-group__wrapper">
                    <div className="form-group">
                      <InputTextField
                          name='responsivePersonName'
                          label="Contact person's name"
                          placeholder="Contact person's name"
                          value={data.responsivePersonName}
                          setValue={handleDataChange}
                          validator={validator}
                          rules={['required', 'alpha_space', { min: 3 }, { max: 25 }]}
                          disabled={progress !== 50 && !isEditBusinessProfile}
                          status="default"
                          validationMessage=""
                          forceUpdate={forceUpdate}
                      />
                    </div>
                    <div className="form-group">
                      <InputTextField
                          name='responsivePersonNo'
                          label="Contact person's number"
                          placeholder="Contact person's number"
                          value={data.responsivePersonNo}
                          setValue={handleDataChange}
                          validator={validator}
                          rules={['required', 'all_mobiles']}
                          disabled={progress !== 50 && !isEditBusinessProfile}
                          status="default"
                          validationMessage=""
                          forceUpdate={forceUpdate} />
                    </div>
                  </div>
                </>
                }
                <hr />
                <h4 className="inner-title">Business Owner Information</h4>
                {/* <div className="form-group__wrapper">
              {progress <= 50 && <div className="form-check mb-3">
                <input className="form-check-input" type="checkbox" defaultValue="" id="flexCheckDefault" onChange={() => setIsOwnerSame(!isOwnerSame)} />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Owner details same as in the profile
                </label>
              </div>}
            </div> */}
                <div className="form-group__wrapper">
                  <div className="form-group">
                    <InputTextField
                        name='owner_first_name'
                        label='First name'
                        placeholder='First name'
                        value={ownerData.owner_first_name}
                        setValue={handleOwnerDataChange}
                        validator={validator}
                        rules={['required', 'alpha_space', { min: 3 }, { max: 25 }]}
                        disabled={true} //disabled={progress !== 50}
                        status="default"
                        validationMessage=""
                        forceUpdate={forceUpdate} />
                  </div>
                  <div className="form-group">
                    <InputTextField
                        name='owner_last_name'
                        label='Last name'
                        placeholder='Last name'
                        value={ownerData.owner_last_name}
                        setValue={handleOwnerDataChange}
                        validator={validator}
                        rules={['required', 'alpha_space', { min: 3 }, { max: 25 }]}
                        disabled={true}
                        status="default"
                        validationMessage=""
                        forceUpdate={forceUpdate} />
                  </div>
                </div>
                <div className="form-group__wrapper">
                  <div className="form-group">
                    <InputTextField
                        name='owner_mobile'
                        label='Mobile number'
                        placeholder='Mobile number'
                        value={ownerData.owner_mobile}
                        setValue={handleOwnerDataChange}
                        validator={validator}
                        rules={['required', { max: 25 }]}
                        disabled={true}
                        status="default"
                        validationMessage=""
                        forceUpdate={forceUpdate} />
                  </div>
                </div>
                <hr />
                <h4 className="inner-title" id="business-verification-docs-title">Business Verification Documents</h4>
                <div className="form-group__wrapper mb-3">
                <span>Choose only one option from the 2 available and provide required information.
                </span>
                </div>
                <div className="form-group__wrapper mb-3">
                  <RadioButton
                      label="NIC details"
                      name="verificationDoc"
                      id="nic"
                      value="nic"
                      data={nic ? nic : "Upload NIC documents"}
                      selectedValue={data.verificationDoc}
                      iconClass="bi-person-badge"
                      icon=""
                      canChange={false}
                      setSelctedItem={handleDataChange}
                      disabled={progress !== 50 && !isEditBusinessProfile}
                  />

                  <RadioButton
                      label="Business registration details"
                      name="verificationDoc"
                      id="businessReg"
                      value="businessReg"
                      data="Upload Registration documents"
                      selectedValue={data.verificationDoc}
                      iconClass=""
                      icon=""
                      canChange={false}
                      setSelctedItem={handleDataChange}
                      disabled={progress !== 50 && !isEditBusinessProfile}
                  />
                </div>

                {(data.verificationDoc == "businessReg" || data.verificationDoc == "nic") && <div className="form-group__wrapper">
                  <div className="form-group">
                    <InputTextField
                        name={data.verificationDoc == "businessReg" ? "business_reg_number" : "ownerNicNumber"}
                        label={data.verificationDoc == "businessReg" ? "Business registration number" : "NIC number"}
                        placeholder={data.verificationDoc == "businessReg" ? "00**********" : "1900000000"}
                        value={data.verificationDoc == "businessReg" ? data.business_reg_number : data.ownerNicNumber}
                        setValue={handleDataChange}
                        validator={validator}
                        rules={data.verificationDoc == "businessReg" ? ['required', 'alpha_num', { max: 45 }] : ['required', 'nic']}
                        disabled={progress !== 50 && !isEditBusinessProfile}
                        status="default"
                        validationMessage=""
                        onBlurValue={onBlurBrc}
                        errorText={brcError}
                        forceUpdate={forceUpdate} />

                    <FileUpload
                        label={"Upload a copy of any document related to the Business Registration / NIC (Multiple documents can be uploaded)"}
                        required={true}
                        classes={""}
                        onChangeFile={onChangeBr}
                        fileList={brDoc}
                        onDropFile={onDropBr}
                        beforeUploadFile={beforeUploadBr}
                        maxCount={maxBrCount}
                        multiple={multipleBr}
                        name={data.verificationDoc == "businessReg" ? 'brDoc' : 'nicCopy'}
                        rules={['required']}
                        fileTypes={'.png,.jpg,.jpeg,.jfif,.pdf'}
                        validator={validator}
                        disabled={progress !== 50 && !isEditBusinessProfile}
                        uploadSizeError={brUploadSizeError}
                        maxSize={2}
                    />
                  </div>
                </div>}
                {AuthHandler.isAuthorized(auth, 'add-marketing-agent-details') && <>
                  <h4 className="inner-title">Professional Details</h4>
                  <div className="form-group__wrapper">
                    <div className="form-group">
                      <InputTextField
                          name='expertise'
                          label='Expertise'
                          placeholder='Expertise'
                          value={maData.expertise}
                          setValue={handleMaDataChange}
                          validator={validator}
                          rules={['required', { max: 400 }]}
                          disabled={progress !== 50 && !isEditBusinessProfile}
                          status="default"
                          validationMessage=""
                          forceUpdate={forceUpdate} />
                    </div>
                    <div className="form-group">
                      <InputTextField
                          name='experience'
                          label='Years of experience'
                          placeholder='Years of experience'
                          value={maData.experience}
                          setValue={handleMaDataChange}
                          validator={validator}
                          rules={['required', 'exp_years']}
                          disabled={progress !== 50 && !isEditBusinessProfile}
                          status="default"
                          validationMessage=""
                          forceUpdate={forceUpdate} />
                    </div>
                  </div>
                  <div className="form-group__wrapper">
                    <div className="form-group">
                      <InputTextArea
                          name='personal_description'
                          label='Description on yourself'
                          placeholder='Brief explanation about your self'
                          rows={3}
                          value={maData.personal_description}
                          setValue={handleMaDataChange}
                          validator={validator}
                          rules={['required']}
                          disabled={progress !== 50 && !isEditBusinessProfile}
                          status="default"
                          validationMessage=""
                          forceUpdate={forceUpdate} />
                    </div>
                    <div className="form-group">
                      <InputTextField
                          name='specification'
                          label='Specification'
                          placeholder='Specification'
                          value={maData.specification}
                          setValue={handleMaDataChange}
                          validator={validator}
                          rules={['required']}
                          disabled={progress !== 50 && !isEditBusinessProfile}
                          status="default"
                          validationMessage=""
                          forceUpdate={forceUpdate} />
                    </div>
                  </div>
                  <h4 className="inner-title">Bank Account Details</h4>
                  <div className="form-group__wrapper">
                    <div className="form-group">
                      <InputTextField
                          name='account_holder'
                          label='Account holder name'
                          placeholder='Account holder name'
                          value={maData.account_holder}
                          setValue={handleMaDataChange}
                          validator={validator}
                          rules={['required', 'alpha_space']}
                          disabled={progress !== 50 && !isEditBusinessProfile}
                          status="default"
                          validationMessage=""
                          forceUpdate={forceUpdate} />
                    </div>
                    <div className="form-group">
                      <div className="form-element__wrapper mb-3 ">
                        <label htmlFor="" className="form-label">Bank
                          <span aria-label="required"></span>
                        </label>
                        <select
                            name="bank"
                            id="bank"
                            className="form-control"
                            value={bankCode || ''}
                            onChange={onChangeBankCode}
                            disabled={progress !== 50 && !isEditBusinessProfile}
                        >
                          <option disabled hidden selected value={''}>Select...</option>
                          {bankList && bankList.map((value: any, index: number) => {
                            return (
                                <option key={index} value={value.code}>{value.name}</option>
                            )
                          })}
                        </select>
                        <div className="validation invalid-feedback">{validator.message('bank', bankCode, "required")}</div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group__wrapper">
                    <div className="form-group">
                      <div className="form-element__wrapper mb-3 ">
                        <label htmlFor="" className="form-label">Branch
                          <span aria-label="required"></span>
                        </label>
                        <select
                            name="branch"
                            id="branch"
                            className="form-control"
                            value={branchCode || ''}
                            onChange={onChangeBranchCode}
                            disabled={progress !== 50 && !isEditBusinessProfile}
                        >
                          <option disabled hidden selected value={''}>Select...</option>
                          {branchList && branchList.map((value: any, index: number) => {
                            return (
                                <option key={index} value={value.code}>{value.name}</option>
                            )
                          })}
                        </select>
                        <div className="validation invalid-feedback">{validator.message('branch', branchCode, "required")}</div>
                      </div>
                    </div>
                    <div className="form-group">
                      <InputTextField
                          name='account_number'
                          label='Account number'
                          placeholder='Account number'
                          value={maData.account_number}
                          setValue={handleMaDataChange}
                          validator={validator}
                          rules={['required', 'numeric', { max: 25 }]}
                          disabled={progress !== 50 && !isEditBusinessProfile}
                          status="default"
                          validationMessage=""
                          forceUpdate={forceUpdate} />
                    </div>
                  </div>
                </>
                }
                {(auth?.realmRoles.includes('mp_content_generator')) && <>
                  <h4 className="inner-title">Bank Account Details</h4>
                  <div className="form-group__wrapper">
                    <div className="form-group">
                      <InputTextField
                          name='account_holder'
                          label='Account holder name'
                          placeholder='Account holder name'
                          value={maData.account_holder}
                          setValue={handleMaDataChange}
                          validator={validator}
                          rules={['required', 'alpha_space']}
                          disabled={progress !== 50 && !isEditBusinessProfile}
                          status="default"
                          validationMessage=""
                          forceUpdate={forceUpdate} />
                    </div>
                    <div className="form-group">
                      <div className="form-element__wrapper mb-3 ">
                        <label htmlFor="" className="form-label">Bank
                          <span aria-label="required"></span>
                        </label>
                        <select
                            name="bank"
                            id="bank"
                            className="form-control"
                            value={bankCode || ''}
                            onChange={onChangeBankCode}
                            disabled={progress !== 50 && !isEditBusinessProfile}
                        >
                          <option disabled hidden selected value={''}>Select...</option>
                          {bankList && bankList.map((value: any, index: number) => {
                            return (
                                <option key={index} value={value.code}>{value.name}</option>
                            )
                          })}
                        </select>
                        <div className="validation invalid-feedback">{validator.message('bank', bankCode, "required")}</div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group__wrapper">
                    <div className="form-group">
                      <div className="form-element__wrapper mb-3 ">
                        <label htmlFor="" className="form-label">Branch
                          <span aria-label="required"></span>
                        </label>
                        <select
                            name="branch"
                            id="branch"
                            className="form-control"
                            value={branchCode || ''}
                            onChange={onChangeBranchCode}
                            disabled={progress !== 50 && !isEditBusinessProfile}
                        >
                          <option disabled hidden selected value={''}>Select...</option>
                          {branchList && branchList.map((value: any, index: number) => {
                            return (
                                <option key={index} value={value.code}>{value.name}</option>
                            )
                          })}
                        </select>
                        <div className="validation invalid-feedback">{validator.message('branch', branchCode, "required")}</div>
                      </div>
                    </div>
                    <div className="form-group">
                      <InputTextField
                          name='account_number'
                          label='Account number'
                          placeholder='Account number'
                          value={maData.account_number}
                          setValue={handleMaDataChange}
                          validator={validator}
                          rules={['required', 'numeric', { max: 25 }, { min: 2 }]}
                          disabled={progress !== 50 && !isEditBusinessProfile}
                          status="default"
                          validationMessage=""
                          forceUpdate={forceUpdate} />
                    </div>
                  </div>
                </>}
              </>}
              {AuthHandler.isAuthorized(auth, 'add-marketing-agent-details') && maData.role === 'mp_dialog_agent' &&
                  <>
                    < h4 className="inner-title">Co-operate Details</h4>
                    <div className="form-group__wrapper">
                      <div className="form-group">
                        <InputTextField
                            name='employee_email'
                            label='Co-Operate Email Address'
                            placeholder='Co-Operate Email Address'
                            value={maData.employee_email}
                            setValue={handleMaDataChange}
                            validator={validator}
                            rules={['required', 'email']}
                            disabled={progress !== 50 && !isEditBusinessProfile}
                            status="default"
                            validationMessage=""
                            forceUpdate={forceUpdate} />
                      </div>
                      <div className="form-group">
                        <InputTextField
                            name='employee_id'
                            label='Employee ID'
                            placeholder='Employee ID'
                            value={maData.employee_id}
                            setValue={handleMaDataChange}
                            validator={validator}
                            rules={['required', { max: 50 }]}
                            disabled={progress !== 50 && !isEditBusinessProfile}
                            status="default"
                            validationMessage=""
                            forceUpdate={forceUpdate}
                        />
                      </div>
                    </div>
                  </>
              }
              <div className="single-wiz-step-footer">
                {/* <button className="btn btn-secondary">Cancel</button> */}
                <Spin tip="" size="small" spinning={loading}>
                  {(progress == 50 || (progress == 75 && isEditBusinessProfile)) && <button className="btn btn-primary" onClick={() => onSave()} disabled={loading}>Save &amp; Continue</button>}
                  {auth?.realmRoles.includes('mp_enterprise_owner') && progress == 75 && !isEditBusinessProfile && <button className="btn btn-primary" onClick={() => onEditBusinessProfile()}>Edit Details</button>}
                </Spin>
              </div>
            </div>
          </div>
          {/*/step*/}
        </div >
        {/* : ''} */}
      </>
  )
}

export { BusinessProfileSection }