import React, { useState } from 'react'
import SimpleReactValidator from 'simple-react-validator'

type Props = {
    name: string,
    label: string,
    placeholder: string,
    value: string,
    setValue: (name: string) => (event: any) => void,
    validator: SimpleReactValidator,
    rules: Array<any>,
    disabled: boolean,
    status: string, //defult, verified, notVerified, invalid
    validationMessage: string
    showEyeIcon: boolean,
    forceUpdate: () => void,
    tooltipComponent?: any
}

const InputPasswordField = ({ name, label, placeholder, value, setValue, validator, rules, disabled, status, validationMessage, showEyeIcon, forceUpdate, tooltipComponent }: Props) => {
    const [type, setType] = useState('password')
    const onClickEye = () => {
        if (type === 'password') {
            setType('text')
        } else {
            setType('password')
        }
    }
    return (
        <div className="form-element__wrapper validated form-element__wbtn mb-3">
            <label htmlFor="" className="form-label">{label}
                <span aria-label="required"></span>
                {tooltipComponent && <div className="tooltip"><span><i className="bi bi-info-circle"></i></span>
                    <span className="tooltiptext">{tooltipComponent}</span>
                </div>}
            </label>
            <div className="input__wrapper">
                <input
                    className={status === "verified" ? "form-control valid"
                        : status === "invalid" ? "form-control invalid"
                            : status === "notVerified" ? "form-control pending"
                                : "form-control"}
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    onChange={e => {
                        setValue(name)(e)
                        validator.showMessageFor(name)
                        forceUpdate();
                    }}
                    // required={required}
                    disabled={disabled} />
                {showEyeIcon ? <span>
                    <button type='button' className="btn btn-default light-shade btn-xsmall" onClick={onClickEye}>
                        <i className={(type === "password" ? "bi bi-eye-fill" : "bi bi-eye-slash-fill")} />
                    </button>
                </span> : <></>}
            </div>
            {status === "verified" ?
                <div className="validation valid-feedback">{validationMessage}</div>
                : status === "invalid" ?
                    <div className="validation invalid-feedback">{validationMessage}</div>
                    : status === "notVerified" ?
                        <div className="validation panding-feedback">{validationMessage}</div>
                        : validator.message(name, value, rules) ?
                            <div className="validation invalid-feedback">{validator.message(name, value, rules)}</div>
                            : <></>}
        </div>
    )
}

export { InputPasswordField }