export default {
    'home': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user','mp_content_generator','mp_user'],
    'discover': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user','mp_content_generator', 'mp_user'],
    'marketing': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user','mp_content_generator', 'mp_user'],
    'analytics': ['mp_enterprise_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user','mp_content_generator', 'mp_user'],
    'notification': ['mp_enterprise_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user','mp_content_generator'],
    'settings': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user','mp_content_generator'],
    'added-apps': ['mp_enterprise_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user','mp_content_generator', 'mp_user'],
    'settings-businesses': ['mp_marketing_admin','mp_dialog_agent'],
    'settings-owner-tabs': ['mp_enterprise_admin', 'mp_enterprise_owner', 'mp_user'],
    'analytics-tab': ['mp_marketing_admin','mp_dialog_agent', 'mp_enterprise_owner'],
}