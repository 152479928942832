import axiosInstance from "../axios-instance";
import * as CONSTANTS from '../constants';

const postBizEmailConfig = async (data: any, tenantId: string, widgetId: string) => {
    return await axiosInstance({
        method: `post`,
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widget/biz-email/create-new-email/${tenantId}/${widgetId}`,
        data: data
    })
}

const getBizEmailConfigsList = async (tenantId: string) => {
    return await axiosInstance({
        method: `get`,
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widget/biz-email/biz-email-configs/${tenantId}`
    })
};

const getBizEmailConfig = async (tenantId: string, configUuid: string) => {
    return await axiosInstance({
        method: `get`,
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widget/biz-email/biz-email-config/${tenantId}/${configUuid}`
    })
};

const putBizEmailConfig = async (data: any, tenantId: string, configUuid: string) => {
    return await axiosInstance({
        method: `put`,
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widget/biz-email/update-email-config/${tenantId}/${configUuid}`,
        data: data
    })
}

export {
    postBizEmailConfig,
    getBizEmailConfigsList,
    getBizEmailConfig,
    putBizEmailConfig
};
