/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import bcImage from "../../../src/assets/images/featured-cover-app-developer.png";
import AppItem from './components/AppItem';
import { fetchAppList, fetchUnsubscribedAppList, unsubcribeApps } from '../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import Auth from '../../models/response/auth.model';
import NoData from './components/NoData';
import UnsubscribedAppItem from './components/UnsubscribedAppItem';
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {Modal} from "antd";
const { confirm } = Modal;
interface Props {
    auth: Auth
}

function AddedApps({ auth }: Props) {

    const dispatch = useDispatch();
    // const settings = useSelector((state: any) => state.settings);
    const [viewAllClickCount, setViewAllClickCount] = useState(1);
    const [viewListLimit, setViewListLimit] = useState(15);
    const [showAll, setShowAll] = useState(false);
    const [appDataArray, setAppDataArray] = useState<any[]>([]);
    const [unsubbedAppsArray, setUnsubbedAppsArray] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [searchKey, setSearchKey] = useState('');
    const [unSubSearchKey, setUnSubSearchKey] = useState('');
    const [limit, setLimit] = useState(20);
    const [offset, setOffset] = useState(0);

    const [subscribedApps, setSubscribedApps] = useState([]);
    const {
        userDetails
    } = useSelector<any, any>(({ user }) => user);

    const {
        appList,
        unsubbedAppList,
        unsubscribeRes
    } = useSelector<any, any>(({ settings }) => settings);

    useEffect(() => {
        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";

        if (inputValue == "" && tenantGroupId !== "") {
            dispatch<any>(fetchAppList(tenantGroupId, inputValue !== '' ? inputValue : '0', limit, offset, appList));
        }
    }, [userDetails, offset, inputValue]);

    useEffect(() => {
        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        if (unSubSearchKey == "" && tenantGroupId !== "") {
            dispatch<any>(fetchUnsubscribedAppList(tenantGroupId, "0"));
        }
    }, [userDetails, unSubSearchKey]);

    useEffect(() => {
        setUnsubbedAppsArray(unsubbedAppList.filter((item: any) => item.featured == true));
    }, [unsubbedAppList, appList, subscribedApps]);

    const onPressViewMore = () => {
        setOffset(offset + limit);
    }

    const onPressPermissions = () => {
        console.log("click permissions");
    }

    const onPressContactDevelopers = () => {
        console.log("click contact app developers");
    }

    const onPressClose = () => {
        setShowAll(false);
        setViewListLimit(7);
        setViewAllClickCount(1);
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const onChangeUnSubSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUnSubSearchKey(e.target.value);
    }

    const onBlurUnSubSearch = () => {
        // console.log("###################33", unSubSearchKey);
        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        if (tenantGroupId !== "") {
            dispatch<any>(fetchUnsubscribedAppList(tenantGroupId, unSubSearchKey != "" ? unSubSearchKey : '0'));
        }
    }

    const onPressUnsubscribe = (uuid: string) => {
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: "Are you sure you want to unsubscribe this widget?",
            onOk() {
                const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
                dispatch<any>(unsubcribeApps(tenantGroupId, uuid, limit, offset));
                // dispatch<any>(fetchAppList(tenantGroupId, '0'));
            },
            centered: true,
            cancelText: "Cancel",
            okText: "Confirm"
        });
    }

    const onSearchWidget = () => {
        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        setOffset(0);
        if (tenantGroupId !== "") {
            dispatch<any>(fetchAppList(tenantGroupId, inputValue !== '' ? inputValue.split(" ").toString() : '0', limit, offset, []));
        }
    }

    
    useEffect(() => {
        setSubscribedApps(appList.filter((item: any) => item.featured == true))
        console.log("sub apps 1",appList);
        console.log("sub apps 2",subscribedApps);
    },[appList])

    return (
        <React.Fragment>
            <div className="sub-nav-inner-container__wrapper">
                <div className="sub-nav-inner-container content-holder">
                    <div className="content-holder-header__wrapper">
                        <div className="content-holder-header">
                            {!showAll && <h4 className="inner-title">Added Apps</h4>}
                            {showAll && <h4 className="inner-title">Added Apps&nbsp;<span><i className="bi bi-chevron-right"></i></span>All Apps</h4>}
                            {showAll && <button className="btn back-btn" onClick={onPressClose}>✕&nbsp;Close </button>}
                        </div>
                    </div>
                    {/* business details preview - step 1 */}
                    <div className="card panel content-holder-panels">
                        <div className="panel-header">
                            <div className="panel-header-content__wrapper">
                                <h4 className="panel-header-title" style={{fontSize: '16px'}}>List of all apps</h4>
                            </div>
                        </div>
                        {/* panel table */}
                        <div className="panel-table-listing__wrapper">
                            <div className="panel-table-listing">
                                <div className="panel-table-listing-header__wrapper">
                                    <div className="panel-table-listing-header">
                                        {Array.isArray(appList) && <div className="panel-table-listing-header__inner">
                                            {appList.length != 0 && <p><strong>{appList.length} Total Apps</strong> Added</p>}
                                            {appList.length == 0 && <p><strong>No records available</strong></p>}
                                        </div>}
                                        <div className="panel-table-listing-header__inner">
                                            <div className="form-group__wrapper">
                                                <div className="form-group">
                                                    <div className="form-element__wrapper ">
                                                        <div className="icon-inside right">
                                                            {/* <span className="in-icon"><i className="bi bi-chevron-down" /></span> */}
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Search by keywords"
                                                                spellCheck="false"
                                                                data-ms-editor="true"
                                                                value={inputValue}
                                                                onChange={handleInputChange}
                                                                onKeyDown={event => {
                                                                    if (event.key === 'Enter') {
                                                                        onSearchWidget();
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {appList.length == 0 && <NoData></NoData>}
                                {appList && appList.length != 0 && <div className="panel-table-content-list">
                                    {/* item */}
                                    {appList && appList.length > 0 && Array.isArray(appList) && appList.filter((item:any)=> item.featured == true).map((item: { uuid: string; title: string; description: string; needContactSupport: boolean; isUnsubscribable: boolean, icon: string; }, index) => {
                                        return (
                                            <AppItem
                                                key={index}
                                                uuid={item.uuid}
                                                appTitle={item.title}
                                                description={item.description}
                                                needContactSupport={item.needContactSupport}
                                                tenantGroupId={(userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : ""}
                                                onPressUnsubscribe={() => onPressUnsubscribe(item.uuid)}
                                                isUnsubscribable={item.isUnsubscribable}
                                                icon={item.icon}
                                            />
                                        )
                                    })}

                                    <div className="panel-table-list-item">
                                        <div className="panel-table-listing-item__inner">
                                            {Array.isArray(subscribedApps) && subscribedApps.length > (limit - 1) && !showAll && <a onClick={onPressViewMore}>View more</a>}
                                        </div>
                                    </div>
                                    {/* / item */}
                                </div>}

                            </div>
                            {/* <div className="alert info">
                                <span className="alert-text__wrapper"><i className="alert-icon bi bi-info-circle-fill" /><span className="alert-text">User Permission?- to change/ remove partners, user account details head over to profile &amp; User permission</span></span>
                            </div> */}
                        </div>
                        {/* panel table */}
                        {/* <div className="panel-footer">
                            <span>Learn business owner/ partner/ admin <a href="#" target="_blank" rel="noopener noreferrer" onClick={onPressPermissions}>permission &amp; terms</a></span>
                        </div> */}
                    </div>
                    {/* / business details preview - step 1 */}

                    <div className="card panel content-holder-panels">
                        <div className="panel-header">
                            <div className="panel-header-content__wrapper">
                                <h4 className="panel-header-title" style={{fontSize: '16px'}}>Recent Unsubscribed apps</h4>
                            </div>
                        </div>
                        {/* panel table */}
                        <div className="panel-table-listing__wrapper">
                            <div className="panel-table-listing">
                                <div className="panel-table-listing-header__wrapper">
                                    <div className="panel-table-listing-header">
                                        <div className="panel-table-listing-header__inner">
                                            {unsubbedAppsArray.length != 0 && <p><strong>{unsubbedAppsArray.length} Total Apps</strong> Unsubscribed</p>}
                                            {unsubbedAppsArray.length == 0 && <p><strong>No records available</strong></p>}
                                        </div>
                                        <div className="panel-table-listing-header__inner">
                                            <div className="form-group__wrapper">
                                                <div className="form-group">
                                                    <div className="form-element__wrapper ">
                                                        <div className="icon-inside left">
                                                            <span className="in-icon"><i className="bi bi-search" /></span>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id=""
                                                                placeholder="Search by keywords"
                                                                spellCheck="false"
                                                                data-ms-editor="true"
                                                                onChange={onChangeUnSubSearch}
                                                                // onBlur={onBlurUnSubSearch}
                                                                onKeyDown={event => {
                                                                    if (event.key === 'Enter') {
                                                                        onBlurUnSubSearch();
                                                                    }
                                                                }}
                                                                value={unSubSearchKey}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {unsubbedAppsArray.length == 0 && <NoData></NoData>}
                                {unsubbedAppsArray.length != 0 &&
                                    <div className="panel-table-content-list">
                                        {/* item */}
                                        {/* item */}
                                        {Array.isArray(unsubbedAppsArray) && unsubbedAppsArray.map((item: { uuid: string; title: string; description: string; date: string; icon: string;}, index) => {
                                            return <UnsubscribedAppItem key={index} uuid={item.uuid} appTitle={item.title} description={item.description} date={item.date} icon={item.icon}></UnsubscribedAppItem>
                                        })}
                                        {/* / item */}
                                    </div>}
                            </div>
                            {/* <div className="alert warning">
                                <span className="alert-text__wrapper"><i className="alert-icon bi bi-info-circle-fill" /><span className="alert-text">User Permission?- to change/ remove partners, user account details head over to profile &amp; User permission</span></span>
                            </div> */}
                        </div>
                        {/* panel table */}
                    </div>
                    {/* business partner/user role invitation */}
                    {/* {!showAll && <div className="card panel content-holder-panels">
                        <div className="featured-panel-content__wrapper">
                            <div className="featured-panel-content">
                                <div className="featured-panel-content-text__wrapper">
                                    <div className="featured-panel-content-text">
                                        <h4 className="main-title mt-bg">Have a unique need? Just Ask us to build Custom app for you.</h4>
                                        <div className="featured-panel-content-text-desc__wrapper">
                                            <p>Need help or Advice? from experts?</p>
                                        </div>
                                        <a className="btn btn-outline btn-round" type="button" role="button" onClick={onPressContactDevelopers}>Contact marketplace app developers<span className="arrow-head right" /></a>
                                    </div>
                                </div>
                                <div className="featured-panel-content-cover__wrapper">
                                    <div className="featured-panel-content-cover" style={{ backgroundImage: `url(${bcImage})` }} />
                                </div>
                            </div>
                        </div>
                    </div>} */}
                    {/* / business partner/user role invitation - step 1 */}
                </div>
            </div>
        </React.Fragment>
    );
}

export default AddedApps;