import React, { useEffect, useState } from 'react';
import { Modal, Row, Col } from 'antd';
import { SmileOutlined, MehOutlined, FrownOutlined } from '@ant-design/icons';
import { useAppDispatch } from '../store';
import { fetchSatisfactionScores, submitSatisfactionScore } from '../store/actions';
import { useSelector } from 'react-redux';

type ratingReq = {
  userId: number,
  rate: number
}

const SmileyRatingPopup: React.FC = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [rating, setRating] = useState<number>(0);
  const [statusCode, setStatusCode] = useState("");
  const [statusMsg, setStatusMsg] = useState("");
  const [isErr, setIsErr] = useState(false);

  const {
    userDetails,
    satisfactionScoreResponse,
    isSatisfactionScoreSubmitted
  } = useSelector<any, any>(({ user }) => user);

  const dispatch = useAppDispatch();

  const handleRatingChange = (value: number) => {
    setRating(value);
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    if (rating == 0) {
      setIsErr(true);
    } else {
      setIsErr(false);
      console.log('Rating submitted:', rating);
      let req: ratingReq = {
        userId: userDetails && userDetails.id ? userDetails.id : "",
        rate: rating
      }
      dispatch(submitSatisfactionScore(req));
      setVisible(false);
    }
  };

  const handleCancel = () => {
    setIsErr(false);
    setRating(0);
    let req: ratingReq = {
      userId: userDetails && userDetails.id ? userDetails.id : "",
      rate: rating
    }
    dispatch(submitSatisfactionScore(req));
    setVisible(false);
  };

  useEffect(() => {
    setVisible(true);
  }, [])

  useEffect(() => {
    const uuid = (userDetails && userDetails.uuid ? userDetails.uuid : "");
    dispatch(fetchSatisfactionScores(uuid));
  }, [userDetails]);

  useEffect(() => {
    setStatusCode(satisfactionScoreResponse && satisfactionScoreResponse.statusCode ? satisfactionScoreResponse.statusCode : "");
    setStatusMsg(satisfactionScoreResponse && satisfactionScoreResponse.statusMsg ? satisfactionScoreResponse.statusMsg : "");
    console.log("sc", satisfactionScoreResponse);
  }, [satisfactionScoreResponse])

  useEffect(() => {
    console.log("statuscode", statusCode);
  }, [statusCode])

  return (
    <>
      {/* <button onClick={showModal}>Open Rating Popup</button> */}
      {(statusCode == '0') && <Modal
        title="Rate Marketplace"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Row justify="center" style={{ marginBottom: "20px", marginTop: "20px" }}>
          <h4>Are you satisfied with our services?</h4>
        </Row>
        <Row justify="center">
          <Col span={4} style={{ textAlign: 'center' }}>
            <div
              style={{ fontSize: '24px', cursor: 'pointer', backgroundColor: rating == 1 ? '#fff2e8' : 'transparent', padding: '5px', borderRadius: '100%' }}
              onClick={() => handleRatingChange(1)}
            >
              <FrownOutlined style={{ color: '#f5222d' }} />
            </div>
            <p>Bad</p>
          </Col>
          <Col span={4} style={{ textAlign: 'center' }}>
            <div
              style={{ fontSize: '24px', cursor: 'pointer', backgroundColor: rating == 2 ? '#ffe7ba' : 'transparent', padding: '5px', borderRadius: '100%' }}
              onClick={() => handleRatingChange(2)}
            >
              <FrownOutlined style={{ color: '#fa8c16' }} />
            </div>
            <p>Not Good</p>
          </Col>
          <Col span={4} style={{ textAlign: 'center' }}>
            <div
              style={{ fontSize: '24px', cursor: 'pointer', backgroundColor: rating == 3 ? '#fffbe6' : 'transparent', padding: '5px', borderRadius: '100%' }}
              onClick={() => handleRatingChange(3)}
            >
              <MehOutlined style={{ color: '#fadb14' }} />
            </div>
            <p>Okay</p>
          </Col>
          <Col span={4} style={{ textAlign: 'center' }}>
            <div
              style={{ fontSize: '24px', cursor: 'pointer', backgroundColor: rating == 4 ? '#e6f7ff' : 'transparent', padding: '5px', borderRadius: '100%' }}
              onClick={() => handleRatingChange(4)}
            >
              <SmileOutlined style={{ color: '#a0d911' }} />
            </div>
            <p>Good</p>
          </Col>
          <Col span={4} style={{ textAlign: 'center' }}>
            <div
              style={{ fontSize: '24px', cursor: 'pointer', backgroundColor: rating == 5 ? '#e6f7ff' : 'transparent', padding: '5px', borderRadius: '100%' }}
              onClick={() => handleRatingChange(5)}
            >
              <SmileOutlined style={{ color: '#52c41a' }} />
            </div>
            <p>Excellent</p>
          </Col>
        </Row>
        {isErr &&
          <Row>
            <span>
              <div className="validation invalid-feedback">Please rate your experience.</div>
            </span>
          </Row>}
      </Modal>}
    </>
  );
};

export default SmileyRatingPopup;
