import React from 'react';
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { fetchWidgetStats } from "../../../store/actions";

const Stats = ({ }) => {

    const dispatch = useDispatch();

    const { userDetails } = useSelector<any, any>(({ user }) => user);
    const { widgetStats } = useSelector<any, any>(({ apps }) => apps);

    const [subscribedWidgetCount, setSubscribedWidgetCount] = useState<number>(0);
    const [lastMonthExpense, setLastMonthExpense] = useState<number>(0.00);

    useEffect(() => {
        if (userDetails) {
            const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
            if (tenantGroupId)
                dispatch(fetchWidgetStats(tenantGroupId));
        }
    }, [userDetails]);

    useEffect(() => {
        if (widgetStats) {
            if (widgetStats) {
                setSubscribedWidgetCount(widgetStats.subscriptionCount);
                setLastMonthExpense(widgetStats?.lastMonthExpense);
            }
        }

    }, [widgetStats])

    return (
        <>  
            <div className="stats-widget-item__wrapper">
                <div className="stats-widget-item card">
                    <span className="stats-title">Previous Month's Expenses</span>
                    <span className="stats-data"><span className="stats-unit">Rs.</span>{lastMonthExpense ? Number(lastMonthExpense).toFixed(2) : 0.00}</span>
                    {/* <span className="stats-peak">
                        <span className="icon__wrapper"> <i
                            className="bi bi-arrow-down txt-red"></i></span>+12% lower than last
                        month
                    </span> */}
                </div>
            </div>
            <div className="stats-widget-item__wrapper">
                <div className="stats-widget-item card">
                    <span className="stats-title">Subscribed Apps Count</span>
                    <span className="stats-data"><span className="stats-unit"></span>{subscribedWidgetCount ? subscribedWidgetCount : 0}</span>
                    {/* <span className="stats-peak">
                        <span className="icon__wrapper"> <i className="bi bi-arrow-up txt-red" /></span>+12% higher than last
                        month
                    </span> */}
                </div>
            </div>
        </>
    )
}

export { Stats }