/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { CSSProperties, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
// import { useHistory } from "react-router-dom";
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import {
    fetchTracklist,
    fetchSingleApp,
    fetchTrackHistory,
    fetchManageCampaignConfigs,
    getCampaignWorkflowStatus,
    fetchManageCampaignData,
    confirmCampaign
} from '../../../store/actions';
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from '@emotion/react';
import AuthHandler from '../../../helper/AuthHandler';
import TableIcon from '../../../assets/images/icons/app-media-placeholder.svg';
import { PageHeaderStatic } from "../components/PageHeaderStatic";
import moment from "moment";
import NoData from "../../settings/components/NoData";
import * as Constants from "../../../constants";
import {
    Alert,
    Button,
    Checkbox,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    message,
    Steps,
    Row,
    Select,
    Spin,
    Space,
    Timeline
} from "antd";
import * as CONSTANTS from "constants";

const { Option } = Select;
const { Step } = Steps;
// const [form] = Form.useForm();

const override: CSSProperties = {
    display: "block",
    margin: "0 auto"
};

const ManagedCampaignStatusWorkflow = ({ auth }: any) => {
    const { wid, configId } = useParams();
    const [logList, setLogList] = useState<any[]>([]);
    const [limit, setLimit] = useState<number>(10);
    const [offset, setOffset] = useState<number>(0);
    const [address, setAddress] = useState<string>('');
    const [lat, setLat] = useState<string>('');
    const [lng, setLng] = useState<string>('');
    const [radius, setRadius] = useState<number>(0);

    const navigate = useNavigate();

    // const {mcConfigList} = useSelector(({ mc }: any) => mc);
    // const { success, loading, error, trackHistory } = useSelector((state: any) => state.location);
    const { userDetails } = useSelector(({ user }: any) => user);
    const [initialDataLoaded, setInitialDataLoaded] = useState(false);


    const { enterpriseDetails, widgetData } = useSelector(({ memory }) => memory);
    const {success, loading, error, campaignStatus, campaignData, mcConfigList, campaignUpdate } = useSelector(({ mc }: any) => mc)
    const [messageTypeUuid, setMessageTypeUuid] = useState("9c6dc12f-6f9a-4fc6-8875-ed8dc31d8194");
    const [workflowStatus, setWorkflowStatus] = useState<any>([])
    const [submitStatus, setSubmitStatus] = useState<any>("");
    // const mapRef = createRef();
    // const [form] = Form.useForm();
    // const history = useHistory();

    const dispatch = useAppDispatch();
    const [type, setType] = useState('password')

    useEffect(() => {
        const enterpriseId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        const configId = window.location.pathname.split('/')[Constants.BASEURLPATHLENGTH + 4];

    }, []);


    useEffect(() => {
        // console.log('------------------>',campaignStatus);
        // console.log('=========================>>',workflowStatus);
        // setLimit(limit);
    }, [mcConfigList,campaignStatus]);

    useEffect(() => {
        if(success && campaignUpdate){
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
    }, [campaignUpdate])

    useEffect(() => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        const configId = window.location.pathname.split('/')[Constants.BASEURLPATHLENGTH + 4];
        if(tenantGUID){
            dispatch(fetchManageCampaignConfigs(tenantGUID));
            dispatch(fetchManageCampaignData(tenantGUID, configId));
        }
        dispatch(getCampaignWorkflowStatus(configId));
        
    }, [wid, userDetails]);


    useEffect(() => {
        console.log("Get Specific Campaign Detail | campaignData",campaignData)
        if (campaignData !== null) {
            if (campaignData.messageType == 'shortMsg') {
                setMessageTypeUuid(Constants.SHORT_MSG);
            }
            if (campaignData.messageType == 'longMsg') {
                setMessageTypeUuid(Constants.LONG_MSG);
            }
            if (campaignData.messageType == 'essayMsg1') {
                setMessageTypeUuid(Constants.ESSAY_MSG_1);
            }
            if (campaignData.messageType == 'essayMsg2') {
                setMessageTypeUuid(Constants.ESSAY_MSG_2);
            }
            if (campaignData.messageType == 'essayMsg3') {
                setMessageTypeUuid(Constants.ESSAY_MSG_3);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaignData]);


    useEffect(() => {
        setWorkflowStatus([])
        let status = campaignStatus != null ? campaignStatus.status.toUpperCase() : "";
        if (status === 'PENDING') {
            setWorkflowStatus([
                'Campaign verification pending'
            ])
        } else if (status === 'TEST_CAMPAIGN_PENDING') {
            setWorkflowStatus([
                'Campaign verification approved',
                'Test campaign pending'
            ])
            //
        } else if (status === 'TEST_CAMPAIGN_FAILED') {
            setWorkflowStatus([
                'Campaign verification approved',
                'Test campaign failed'
            ])
            //
        } else if (status === 'REJECTED') {
            setWorkflowStatus([
                'Campaign verification rejected'
            ])
        } else if (status === 'RESUBMITTED') {
            setWorkflowStatus([
                'Campaign verification resubmitted'
            ])
        } else if (status === 'CHARGING_PENDING') {
            setWorkflowStatus([
                'Campaign verification approved',
                'Test campaign completed',
                'Test campaign confirmation pending'
            ])
        } else if (status === 'CAMPAIGN_PENDING') {
            setWorkflowStatus([
                'Campaign verification approved',
                'Test campaign completed',
                'Test campaign confirmed',
                'Charging success',
                'Campaign pending'
            ])
        } else if (status === 'CHARGING_FAILED') {
            setWorkflowStatus([
                'Campaign verification approved',
                'Test campaign completed',
                'Test campaign confirmed',
                'Charging failed'
            ])
        } else if (status === 'CAMPAIGN_PASSED') {
            setWorkflowStatus([
                'Campaign verification approved',
                'Test campaign completed',
                'Test campaign confirmed',
                'Charging success',
                'Campaign passed'
            ])
        } else if (status === 'CAMPAIGN_FAILED') {
            setWorkflowStatus([
                'Campaign verification approved',
                'Test campaign completed',
                'Test campaign confirmed',
                'Charging success',
                'Campaign failed'
            ])
        }

    }, [campaignStatus])

    const onViewMore = () => {
        setOffset(offset + 10);
    }

    const onFinish = () => {

    }
    const onUpdateStatus = (submitStatus:any) => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        const configId = window.location.pathname.split('/')[Constants.BASEURLPATHLENGTH + 4];
        if (submitStatus === "confirm") {
            let workflowData = {
                "status": "CAMPAIGN_PENDING",
                "campaignStatus": "confirm"
            }
            //flag
            let res = dispatch(confirmCampaign(workflowData, tenantGUID, configId,messageTypeUuid));
            // console.log('ressssss ------>' + res)
            // setTimeout(() => {
            //     window.location.reload();
            // }, 1000);

        }
        else if (submitStatus === "resubmit") {
            navigate(`/manage-campaign/${wid}/manage-campaign-resubmit/${configId}`)
        } else if (submitStatus === "rejected_resubmit") {
            navigate(`/manage-campaign/${wid}/manage-campaign-rejected-resubmit/${configId}`)
        }
    }

    const selectResubmit = () => {
        return (
            <div className="resubmit_wrapper">
                <Select defaultValue="disabled" onChange={(value) => setSubmitStatus(value)} style={{ fontSize: '13px', width: '100%' }}>
                    <Option value="disabled" disabled>Select</Option>
                    <Option value="rejected_resubmit">Resubmit</Option>
                </Select><br />
                {(submitStatus === "rejected_resubmit") &&
                    <Button type="primary" size={'middle'} onClick={() => onUpdateStatus(submitStatus)} htmlType="submit"style={{ marginTop: '6%' }}>
                        Submit
                    </Button>}
            </div>
        )
    }
    const selectconfirm = () => {
        return (
            <div className="status_update_wrapper">
                <Select defaultValue="disabled" onChange={(value) => setSubmitStatus(value)} style={{ fontSize: '13px', width: '100%' }}>
                    <Option value="disabled" disabled>Select</Option>
                    <Option value="confirm">Confirm</Option>
                    <Option value="resubmit">Resubmit</Option>
                </Select><br />
                {(submitStatus === "confirm" || submitStatus === "resubmit") &&
                    <Button type="primary" size={'middle'} onClick={() => onUpdateStatus(submitStatus)} htmlType="submit" style={{ marginTop: '6%' }}>
                        Submit
                    </Button>}
            </div>
        )
    }

    const renderConfigList = () => {

        // console.log('cccccccccccccccccc',AuthHandler.isAuthorized(auth, 'manage-campaign-status-workflow'));
        // console.log('dddddddddddddddddddd',userDetails);
        // console.log('successsuccesssuccesssuccess',success);

        // if (success) {
            if (campaignData) {
                return (
                    <div className="section__wrapper">
                        <div className="card panel content-holder-panels">
                            <div className="panel-header">
                                <div className="panel-header-content__wrapper">
                                    <h4 className="panel-header-title">Managed Campaign Status Workflow</h4>
                                    {/* <p className="panel-header-subtitle">List of </p> */}
                                </div>
                            </div>

                            <div className="row wow fadeIn manage-campaign-mainWorkflow">
                                <div className="col-md-12 mb-4 text-center">
                                    <Form
                                        layout="vertical"
                                        // form={form}
                                        size="large"
                                        onFinish={onFinish}
                                        scrollToFirstError
                                    >
                                        <Row gutter={[6, 6]} className="row-wrap">

                                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                <Steps
                                                    size="small"
                                                    direction="vertical"
                                                    current={workflowStatus.length - 1}
                                                    className="site-navigation-steps"
                                                >
                                                    <Step
                                                        title={workflowStatus[0] === "Campaign verification pending" ? "Pending" : workflowStatus[0] === "Campaign verification rejected" ? "Rejected" : workflowStatus[0] === "Campaign verification resubmitted" ? "Resubmitted" : "Approved"}
                                                        status={workflowStatus[0] === "Campaign verification pending" ? "process" : workflowStatus[0] === "Campaign verification rejected" ? "error" : workflowStatus[0] === "Campaign verification resubmitted" ? "finish" : "finish"}
                                                        description="Campaign verification"
                                                    />
                                                    <Step
                                                        title={workflowStatus.length < 2 ? "Waiting" : workflowStatus[1] === "Test campaign pending" ? "Pending" : workflowStatus[1] === "Test campaign failed" ? "Rejected" : "Completed"}
                                                        status={workflowStatus.length < 2 ? "wait" : workflowStatus[1] === "Test campaign pending" ? "process" : workflowStatus[1] === "Test campaign failed" ? "error" : "finish"}
                                                        description="Test campaign"
                                                    />
                                                    <Step
                                                        title={workflowStatus.length < 3 ? "Waiting" : workflowStatus[2] === "Test campaign confirmation pending" ? "Pending" : "Confirmed"}
                                                        status={workflowStatus.length < 3 ? "wait" : workflowStatus[2] === "Test campaign confirmation pending" ? "process" : "finish"}
                                                        description="Test campaign"
                                                    />
                                                    <Step
                                                        title={workflowStatus.length < 4 ? "Waiting" : workflowStatus[3] === "Charging pending" ? "Pending" : workflowStatus[3] === "Charging failed" ? "Failed" : "Success"}
                                                        status={workflowStatus.length < 4 ? "wait" : workflowStatus[3] === "Charging pending" ? "process" : workflowStatus[3] === "Charging failed" ? "error" : "finish"}
                                                        description="Charging"
                                                    />
                                                    <Step
                                                        title={workflowStatus.length < 5 ? "Waiting" : workflowStatus[4] === "Campaign pending" ? "Pending" : workflowStatus[4] === "Campaign failed" ? "Failed" : "Passed"}
                                                        status={workflowStatus.length < 5 ? "wait" : workflowStatus[4] === "Campaign pending" ? "process" : workflowStatus[4] === "Campaign failed" ? "error" : "finish"}
                                                        description="Real campaign"
                                                    />
                                                </Steps>
                                            </Col>
                                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                {
                                                    workflowStatus.length >= 1 && workflowStatus[0] === "Campaign verification rejected" && selectResubmit()
                                                    // workflowStatus.length >= 1 && workflowStatus[0] === "Campaign verification rejected"
                                                }
                                                {
                                                    workflowStatus.length >= 3 && workflowStatus[2] === "Test campaign confirmation pending" && selectconfirm()
                                                    // workflowStatus.length >= 3 && workflowStatus[2] === "Test campaign confirmation pending"
                                                }
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            // else {
            //     return (
            //         <div className="section__wrapper">
            //             <div className="empty-state-new-action__wrapper">
            //                 <div className="new-action-content__wrapper">
            //                     <h4 className="sub">No Records available</h4>
            //                     <h4 className="title">Create new request</h4>
            //                     <button className="btn btn-primary" onClick={() => navigate(`/location/${wid}/create`)}>New request</button>
            //                 </div>
            //             </div>
            //         </div>
            //     )
            // }
        // } else if (loading) {
        //     return (
        //         <div className="section__wrapper">
        //             <div className="empty-state-new-action__wrapper">
        //                 <section className="content__wrapper">
        //                     <div className="primaryBrandLoaderBg">
        //                         <div className="primaryBrandLoader">
        //                             <img src={require('../../../assets/images/loader-bg-primary.svg').default} alt="" srcSet="" />
        //                         </div>
        //                     </div>
        //                 </section>
        //             </div>
        //         </div>
        //     )
        // } 
        // else if (error) {
        //     return (
        //         <div className="section__wrapper">
        //             <div className="empty-state-new-action__wrapper">
        //                 <div className="new-action-content__wrapper">
        //                     <h4 className="sub">Oops, something went wrong!</h4>
        //                 </div>
        //             </div>
        //         </div>
        //     )
        // }
    }

    if (AuthHandler.isAuthorized(auth, 'manage-campaign-status-workflow') && userDetails !== null) {
        return (
            <React.Fragment>
                <div className="main-content main-spacer-x-y">
                    <PageHeaderStatic
                        page={'workFlow'}
                    />
                    {renderConfigList()}
                </div>

            </React.Fragment>
        );
    } else return (<React.Fragment></React.Fragment>);
}

export default ManagedCampaignStatusWorkflow;