import React from "react";
import appMediaIcon from "../../../../src/assets/images/icons/app-media-placeholder.svg";

interface Prop {
    uuid: String;
    appTitle: String;
    description: String;
    date: String;
    icon: string;
}

function UnsubscribedAppItem({ appTitle, description, date, icon }: Prop) {

    return <React.Fragment>
        <div className="panel-table-list-item">
            <div className="panel-table-listing-item__inner">
                <div className="feature-details__wrapper">
                    <div className="card app-media-holder" style={{ backgroundImage: `url(${icon})` }}>
                    </div>
                    <div className="feature-details-title__wrapper">
                        <span className="title">{appTitle}</span>
                        <span className="small-desc">{description}</span>
                    </div>
                </div>
            </div>
            <div className="panel-table-listing-item__inner">
                <div className="panel-table-right-align-item__wrapper">
                    <div className="panel-table-right-align-item">
                        <span className="title">{date}</span>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
}

export default UnsubscribedAppItem;