
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthSliceModel {
}

const bizEmailStore = createSlice({
    name: "biz-email-store",
    initialState: {
        bizEmailConfigList: [],
        loading: false,
        success: false,
        error: false,
        addBizEmailConfigRes: null,
        bizEmailConfig: null,
        updateBizEmailConfigRes: null,
    } as AuthSliceModel,
    reducers: {
        bizEmailAllConfigFetch: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        },
        bizEmailAllConfigFetchSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                bizEmailConfigList: action.payload,
                success: true,
                error: false
            }
        },
        bizEmailAllConfigFetchFail: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                bizEmailConfigList: null
            }
        },
        bizEmailConfigFetch: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        },
        bizEmailConfigFetchSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                bizEmailConfig: action.payload,
                success: true,
                error: false
            }
        },
        bizEmailConfigFetchFail: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                bizEmailConfig: null
            }
        },
        bizEmailConfigPost: (state) => {
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            };
        },
        bizEmailConfigPostSuccess: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                loading: false,
                addBizEmailConfigRes: action.payload,
                success: true,
                error: false
            };
        },
        bizEmailConfigPostFail: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                addBizEmailConfigRes: action.payload
            };
        },
        bizEmailConfigUpdate: (state) => {
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            };
        },
        bizEmailConfigUpdateSuccess: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                loading: false,
                updateBizEmailConfigRes: action.payload,
                success: true,
                error: false
            };
        },
        bizEmailConfigUpdateFail: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                updateBizEmailConfigRes: null
            };
        },
    },
});

export default bizEmailStore;