
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthSliceModel {
}

const gwsStore = createSlice({
    name: "gws-store",
    initialState: {
        gwsConfigList: [],
        loading: false,
        success: false,
        error: false,
        addDomainRes: null,
        domainVerificationRes: null,
        createCustomerRes: null,
        createUserRes: null,
        configData: null,
        verifyDomainFinalRes: null,
        gwsLicenseCountRes: null,
        domainDeleteRes: null,
        requestDetails: null,
        requestSubmitError: true,
        requestSubmitLoading: false,
        requestSubmitSuccess: false,
        requestSubmitErrorMessage: "",
        smRatePlan: null
    } as AuthSliceModel,
    reducers: {
        gwsConfigFetch: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        },
        gwsConfigFetchSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                configData: action.payload,
                success: true,
                error: false
            }
        },
        gwsConfigFetchFail: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                configData: null
            }
        },
        gwsAllConfigFetch: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        },
        gwsAllConfigFetchSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                gwsConfigList: action.payload,
                success: true,
                error: false
            }
        },
        gwsAllConfigFetchFail: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                gwsConfigList: []
            }
        },
        gwsDomainPost: (state) => {
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            };
        },
        gwsDomainPostSuccess: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                loading: false,
                addDomainRes: action.payload,
                success: true,
                error: false
            };
        },
        gwsDomainPostFail: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                addDomainRes: action.payload
            };
        },
        gwsUserPost: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        },
        gwsUserPostSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                createUserRes: action.payload,
                success: true,
                error: false
            }
        },
        gwsUserPostFail: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                createUserRes: action.payload,
            }
        },
        gwsCustomerPost: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        },
        gwsCustomerPostSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                createCustomerRes: action.payload,
                success: true,
                error: false
            }
        },
        gwsCustomerPostFail: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                createCustomerRes: action.payload
            }
        },
        gwsDomainVerification: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        },
        gwsDomainVerificationSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                domainVerificationRes: action.payload,
                success: true,
                error: false
            }
        },
        gwsDomainVerificationFail: (state: AuthSliceModel, action: PayloadAction<string>) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                domainVerificationRes: action.payload
            }
        },
        gwsLicenseCount: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        },
        gwsLicenseCountSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                gwsLicenseCountRes: action.payload,
                success: true,
                error: false
            }
        },
        gwsLicenseCountFail: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                gwsLicenseCountRes: null
            }
        },
        gwsDomainDelete: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        },
        gwsDomainDeleteSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                domainDeleteRes: action.payload,
                success: true,
                error: false
            }
        },
        gwsDomainDeleteFail: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                domainDeleteRes: null
            }
        },
        gwsDomainVerify: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        },
        gwsDomainVerifySuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                verifyDomainFinalRes: action.payload,
                success: true,
                error: false
            }
        },
        gwsDomainVerifyFail: (state: AuthSliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                verifyDomainFinalRes: action.payload,
            }
        },
        gwsResetStatuses: (state: AuthSliceModel) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: false,
                addDomainRes: null,
                domainVerificationRes: null,
                createCustomerRes: null,
                createUserRes: null,
                configData: null,
                verifyDomainFinalRes: null
            }
        },
    },
});

export default gwsStore;