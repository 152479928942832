import React, { useEffect, useState } from 'react'

type Props = {
    commonFeatures: any
}

const RateItemCommonFeaturesComponent = ({ commonFeatures }: Props) => {

    return (
        <div className="pricing-grid-list-item bg-primary">
            <div className="pricing-grid-list-content">
                <h4 className="pricing-item-title">{commonFeatures.title}</h4>
                {commonFeatures && <ul className="pricing-grid-list-options-list">
                    {Object.keys(commonFeatures).map((key) => {
                        return <li className="options-item">{commonFeatures[key]}</li>
                    })}
                </ul>}
            </div>
        </div>
    )
}

export { RateItemCommonFeaturesComponent };