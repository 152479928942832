import axiosInstance from '../axios-instance';
import * as CONSTANTS from '../constants';
import { AccountTypeModel } from '../pages/home/models/request/account-type.model';
import { Enterprise } from '../pages/home/models/request/enterprise.model';
import { Profile } from '../pages/home/models/request/profile.model';
import { UserModel } from '../pages/signup/models/request/user.model';
import { BusinessProfile } from '../pages/home/models/request/business-profile.model';
import { DuplicateCheck } from '../pages/home/models/request/duplicate-check.model';
import BusinessDetails from '../pages/settings/BusinessDetails';

const postUser = (req: UserModel) => {
    return axiosInstance({
        method: "post",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/user`,
        data: req
    });
}

// const postAccountType = (req: AccountTypeModel, uuid: string) => {
//     return axiosInstance({
//         method: "post",
//         url: `${CONSTANTS.REACT_APP_BACKEND_URL}/user/role`
//     });
// }

const postAccountType = (req: AccountTypeModel) => {
    return axiosInstance({
        method: "put",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/user/update-role`,
        data: req
    });
}
const postProfileData = (req: any) => {
    return axiosInstance({
        method: "post",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/profile/submit`,
        headers: { 'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>' },
        data: req
    });
}

const postBusinessData = (req: BusinessProfile) => {
    console.log("res", req)
    return axiosInstance({
        method: "post",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/business-profile/submit`,
        data: req
    });
}

const postEnterprise = (req: Enterprise, uuid: string) => {
    return axiosInstance({
        method: "post",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/enterprise-submit/${uuid}`,
        data: req
    });
}

const postEmail = (req: object) => {
    return axiosInstance({
        method: "post",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/email/send`,
        data: req
    });
}

const postEmailOtp = (req: object) => {
    return axiosInstance({
        method: "post",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/email/verify`,
        data: req
    });
}

const getUserDetails = (keycloakId: string) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/user/profile/${keycloakId}`
    });
}

const getNicBrcDuplicateCheck = (type: string, value: string) => {
    return axiosInstance({
        method: "GET",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/checkunique/${type}/${value}`
    });
}

const postMsisdn = (req: object, tid: string) => {
    return axiosInstance({
        method: "post",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/otp/send?tid=${tid}`,
        data: req
    });
}

const postMsisdnOtp = (req: object, tid: string) => {
    console.log("######### 3");
    return axiosInstance({
        method: "post",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/otp/verify?tid=${tid}`,
        data: req
    });
}

const getRedisStatus = (uuid: string) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/enterprise-status/${uuid}`
    });
}

// const getUserSearch = (type:string, value:string) => {
//     return axiosInstance({
//         method: "get",
//         url: `${CONSTANTS.REACT_APP_BACKEND_URL}/searchuser/${type}/${value}`
//     });
// }
const getUserSearch = (type: string, value: string) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/search-user?type=username&value=${value}`
    });
}

const getProfileStatus = () => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/user`
    });
}

const getProfileTypes = () => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/user/profile-types`
    });
}

const getBanks = () => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/banks`
    });
}

const putBusinessProfileDetails = (req: BusinessProfile, entUuid: string) => {
    console.log("res", req)
    return axiosInstance({
        method: "put",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/ent/revamp/draft/${entUuid}`,
        data: req
    });
}

const postResubmitEnterprise = (req: BusinessProfile, entUuid: string) => {
    console.log("res", req)
    return axiosInstance({
        method: "post",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/ent/revamp/resubmit/${entUuid}`,
        data: req
    });
}

const postSatisfactionScore = (req: any) => {
    console.log("res", req)
    return axiosInstance({
        method: "post",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/user/user-satisfaction`,
        data: req
    });
}

const getSatisfactionScores = (uuid: string) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/user/user-satisfaction/${uuid}`,
    });
}

const getEmailDuplicateCheck = (value: string) => {
    return axiosInstance({
        method: "GET",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/ent/check/email/${value}`
    });
}

const postGeneratedLeadRequest = (req: any) => {
    console.log("res", req)
    return axiosInstance({
        method: "post",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widgets/lead-request`,
        data: req
    });
}

const postbusinessEmail = (req: object) => {
    return axiosInstance({
        method: "post",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/otp/v2/email/initiate`,
        data: req
    });
}

const postbusinessEmailOtp = (req: object) => {
    return axiosInstance({
        method: "post",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/otp/v2/email/validate`,
        data: req
    });
}


export {
    postUser,
    postAccountType,
    postProfileData,
    postBusinessData,
    postEnterprise,
    postEmail,
    postEmailOtp,
    getUserDetails,
    getNicBrcDuplicateCheck,
    postMsisdn,
    postMsisdnOtp,
    getRedisStatus,
    getUserSearch,
    getProfileStatus,
    getProfileTypes,
    getBanks,
    putBusinessProfileDetails,
    postResubmitEnterprise,
    postSatisfactionScore,
    getSatisfactionScores,
    getEmailDuplicateCheck,
    postGeneratedLeadRequest,
    postbusinessEmail,
    postbusinessEmailOtp
};