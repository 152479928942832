import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import AuthHandler from '../../../helper/AuthHandler';
import { MapContainer, Marker, TileLayer, Circle } from 'react-leaflet';
import {Button, DatePicker, Form, Input, message, Select, Space, Tooltip, Modal} from "antd";
import {
    fetchSettlementTyps,
    fetchSingleApp,
    fetchTenantMasksByType,
    fetchMsisdnsList,
    fetchDistricts,
    fetchReachCost,
    fetchManageCampaignDetails,
    saveCampaign,
    updateManageCampaingDetails
} from '../../../store/actions';
import L from 'leaflet';
import SimpleReactValidator from 'simple-react-validator';
import * as CONSTANTS from '../../../constants';
import { confirmAlert } from 'react-confirm-alert';
import { onSearch } from "../../../services/widgets.service";
import dayjs from 'dayjs';
import MarkerMapIcon from '../../../assets/images/Marker.svg';
import { PageHeaderStatic } from "../components/PageHeaderStatic";
import {InputTextArea, InputTextField} from "../../../components";
import {Option} from "antd/es/mentions";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {ManageCampaignTnC} from "./components/ManageCampaignTnC";

const { RangePicker } = DatePicker;

const override: CSSProperties = {
    display: "block",
    margin: "0 auto"
};

const ManageCampaignConfigCreate = ({ auth }: any) => {
    const mapRef = useRef<any>(null);
    const { wid } = useParams();
    const [screen, setScreen] = useState('add-manage-campaign-config');
    const [latitude, setLatitude] = useState(6.927079);
    const [longitude, setLongitude] = useState(79.861244);
    const [radius, setRadius] = useState(500);
    const [zoom, setZoom] = useState(13);
    const [animate, setAnimate] = useState(false);
    const [latlng, setLatlng] = useState({ lat: 6.927079, lng: 79.861244 });
    const [bounds, setBounds] = useState([
        [50.505, -29.09],
        [52.505, 29.09],
    ]);
    const [searchText, setSearchText] = useState("");
    const [receiverOtpError, setReceiverOtpError] = useState('');
    const [receiverServerRef, setReceiverServerRef] = useState('');
    const [trackerServerRef, setTrackerServerRef] = useState('');
    const [searchResult, setSearchResult] = useState([]);
    const [title, setTitle] = useState("");
    const [termsAndConditions, setTermsAndConditions] = useState<boolean>(false);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [paymentMethodId, setPaymentMethodId] = useState('');
    const [showReceiverMsisdnOtpField, setShowReceiverMsisdnOtpField] = useState(false);
    const [receiverRemainingTime, setReceiverRemainingTime] = useState(0);
    // const [receiverMobileVerified, setReceiverMobileVerified] = useState<string | undefined>();

    const [showTrackingMsisdnOtpField, setShowTrackingMsisdnOtpField] = useState(false);
    const [trackingRemainingTime, setTrackingRemainingTime] = useState(0);

    const [otpSender, setOtpSender] = useState('receiver');
    const [locationSelected, setLocationSelected] = useState(false);
    const [fieldDisabled, setFieldDisabled] = useState(false);

    const [tenantGuid, setTenantGuid] = useState("");
    const [widgetUuid, setWidgetUuid] = useState("");
    const [mask, setMask] = useState("");
    const [maskList, setMaskList] = useState<any[]>([]);
    const [msgType, setMsgType] = useState("");
    const [msgTypeUuid, setMsgTypeUuid] = useState(CONSTANTS.DEFAULT_MSG);
    const [msgContent, setMsgContent] = useState("");
    const [locationType, setLocationType] = useState("address");//"address","map"
    const [scheduleType, setScheduleType] = useState("now");//"now","schedule"
    const [msgContentError, setMsgContentError] = useState('');
    const [tncError, setTncError] = useState('');
    const [districtError, setDistrictError] = useState('');
    const [cityError, setCityError] = useState('');
    const [filterSectionError, setFilterSectionError] = useState('');
    const [msisdn, setMsisdn] = useState("");
    const [campaignStatus, setCampaignStatus] = useState("");
    const [msisdnList, setMsisdnList] = useState<any[]>([]);
    const [district, setDistrict] = useState("");
    const [cityList, setCityList] = useState<any[]>([]);
    const [city, setCity] = useState("");
    const [date, setDate] = useState("");
    const [dateRange, setDateRange] = useState<any[]>([]);
    const [reachCount, setReachCount] = useState("");
    const [reachCost, setReachCost] = useState("");
    const [msisdnError, setMsisdnError] = useState("");
    const [testNumbers, setTestNumbers] = useState<any[]>([]);
    const [position, setPosition] = useState({lat:6.927079, lng: 79.861244});
    const [showTnC, setShowTnC] = useState(false);
    // - Age checkbox -
    const [twentyThirty, setTwentyThirty] = useState(false);
    const [thirtyForty, setThirtyForty] = useState(false);
    const [fortySixty, setFortySixty] = useState(false);
    const [sixtyAbove, setSixtyAbove] = useState(false);
    // - Gender checkbox -
    const [male, setMale] = useState(false);
    const [female, setFemale] = useState(false);
    // - Bill value checkbox -
    const [billVal1, setBillVal1] = useState(false);
    const [billVal2, setBillVal2] = useState(false);
    const [billVal3, setBillVal3] = useState(false);
    const [billVal4, setBillVal4] = useState(false);
    // - Device type checkbox -
    const [deviceType1, setDeviceType1] = useState(false);
    const [deviceType2, setDeviceType2] = useState(false);
    const [deviceType3, setDeviceType3] = useState(false);
    // - Language checkbox -
    const [sinhala, setSinhala] = useState(false);
    const [english, setEnglish] = useState(false);
    const [tamil, setTamil] = useState(false);

    const [form] = Form.useForm();

    const routePath = window.location.pathname.split('/')[3];

    const [validator] = useState(new SimpleReactValidator({
        messages: {
            required: 'You can’t keep this as empty',
            min: 'Minimum length is :min',
            max: 'Maximum length is :max',
        },
        validators: {
            all_mobiles: {
                message: 'The entered :attribute number is invalid.',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^[0]{1}[123456789]{2}\d{7}$/) && params.indexOf(val) === -1
                }
            },
            dialog: {
                message: 'The :attribute is invalid.',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^([0]{1}[7]{1}[764]{1}\d{7}|[7]{1}[764]{1}\d{7})$/) && params.indexOf(val) === -1
                }
            },
            reachCount: {
                message: 'The selected payment type/or the number will not be applicable for this lead request.',
                rule: (val: any, params?: any, validator?: any): boolean => !!(val && val <= 50000)
            },
            reachCost: {
                message: 'Value must be greater than zero.',
                rule: (val: any, params?: any, validator?: any): boolean => !!(val > 0)
            }
        },
        autoForceUpdate: this
    }));

    const navigate = useNavigate();

    // const position = { lat: latitude, lng: longitude };

    const MarkerIcon = L.icon({
        iconUrl: MarkerMapIcon,
        iconRetinaUrl: MarkerMapIcon,
        iconSize: new L.Point(50, 50),
        className: 'leaflet-div-icon'
    });

    const otpTextValidator = new SimpleReactValidator({
        messages: {
            required: 'You can’t keep this as empty',
            min: 'Minimum length is :min',
            max: 'Maximum length is :max',
        },
        autoForceUpdate: this
    });

    const {
        singleAppDetails
    } = useSelector(({ apps }: any) => apps);

    const {
        termsAndConditionsDetails,
        locationSubmitError,
        locationSubmitErrorMessage,
        locationSubmitLoading,
        locationSubmitSuccess,
        settlement_types
    } = useSelector(({ location }: any) => location);

    const {
        msisdnData,
        msisdnOtpData,
        userDetails
    } = useSelector<any, any>(({ user }) => user);

    const {
        masksList,
        msisdnsList,
        districtList
    } = useSelector<any, any>(({ ent }) => ent);

    const {
        reachCostData,
        campaignDetails
    } = useSelector<any, any>(({ mc }) => mc);

    const dispatch = useAppDispatch();

    const useForceUpdate = () => {
        const [value, setValue] = useState(0);
        return () => setValue(value => value + 1);
    }

    const forceUpdate = useForceUpdate()

    useEffect(() => {
        dispatch(fetchDistricts());
    }, []);

    useEffect(() => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        if (routePath === "manage-campaign-resubmit" || routePath === "view-manage-campaign" || routePath === "manage-campaign-rejected-resubmit") {
            const configId = window.location.pathname.split('/')[4];
            if(tenantGUID){
                dispatch(fetchManageCampaignDetails(tenantGUID, configId));
            }
        }
        setMsisdn((userDetails && Array.isArray(userDetails.OwnedEnterprises) && userDetails.OwnedEnterprises.length > 0 && userDetails.OwnedEnterprises[0].Enterprise) ? userDetails.OwnedEnterprises[0].Enterprise.ownerMobile : '');
        if(tenantGUID && tenantGUID != tenantGuid) {
            setTenantGuid(tenantGUID)
            dispatch(fetchTenantMasksByType(tenantGUID, 'USER'));
        }
    }, [userDetails]);

    useEffect(() => {
        if (widgetUuid === "" && singleAppDetails && singleAppDetails.widgetDetails && singleAppDetails.widgetDetails.uuid) {
            setWidgetUuid(singleAppDetails.widgetDetails.uuid)
        }
    }, [singleAppDetails]);

    useEffect(() => {
        if (routePath === "manage-campaign-resubmit" || routePath === "view-manage-campaign" || routePath === "manage-campaign-rejected-resubmit") {
            setScheduleType('schedule')

            if (campaignDetails !== null) {
                setTitle(campaignDetails.title)
                setMsisdn(campaignDetails.msisdn)
                setMask(campaignDetails.mask)
                setMsgType(campaignDetails.messageType)
                setMsgContent(campaignDetails.messageContent)
                setDistrict(campaignDetails.district)
                setCity(campaignDetails.city !== null ? JSON.parse(campaignDetails.city) : '')
                setLatitude(campaignDetails.latitude !== null ? campaignDetails.latitude : 0)
                setLongitude(campaignDetails.longitude !== null ? campaignDetails.longitude : 0)
                setRadius(campaignDetails.radius)
                setDateRange([dayjs(campaignDetails.startTime), dayjs(campaignDetails.endTime)])
                setReachCount(campaignDetails.reachCount)
                setReachCost(campaignDetails.estimatedCost)
                setTestNumbers(campaignDetails.NumberBase.Numbers)
                setGenderCheckBox(campaignDetails.gender)
                setDeviceCheckBox(campaignDetails.deviceType)
                setLanguageCheckBox(campaignDetails.language)
                setAgeCheckBox(campaignDetails.age !== null ? JSON.parse(campaignDetails.age) : '')
                setBillValCheckBox(campaignDetails.avgBillValue !== null ? JSON.parse(campaignDetails.avgBillValue) : '')
                setScheduleType('schedule')
                setCity(campaignDetails.city !== null ? JSON.parse(campaignDetails.city) : '')
                form.setFieldsValue({
                    testNumbers: campaignDetails.NumberBase.Numbers,
                    // // city: campaignDetails.city !== null ? JSON.parse(campaignDetails.city) : '',
                });
                setMsgType(campaignDetails.messageType);
                setSearchText(campaignDetails.location ? campaignDetails.location : "");
                setLocationType(campaignDetails.latitude !== null ? 'map' : 'address')
                setPosition({
                    lat: campaignDetails.latitude !== null ? campaignDetails.latitude : 6.927079,
                    lng: campaignDetails.longitude !== null ? campaignDetails.longitude : 79.861244
                });
                setCampaignStatus(campaignDetails.status);
                setSubmitDisabled(true);
            }
        }
        if (routePath === "view-manage-campaign") {
            setFieldDisabled(true)
        }
    }, [campaignDetails]);

    useEffect(() => {
        if(masksList && Array.isArray(masksList)) {
            setMaskList(masksList)
        }
    }, [masksList]);

    useEffect(() => {
        if(msisdnsList && Array.isArray(msisdnsList)) {
            setMsisdnList(msisdnsList)
        }
    }, [msisdnsList]);

    useEffect(() => {
        if(reachCostData && reachCostData.reachCost !== undefined && reachCostData.reachCost !== null) {
            setReachCost(reachCostData.reachCost.toFixed(2).toString())
        }
    }, [reachCostData]);

    useEffect(() => {
        if (searchText !== '' && !locationSelected) {
            var searchString = searchText.replace(/,/g, "%2C");
            searchString = searchString.replace(/ /g, "+");
            onSearch(searchString).then((data) => {
                if(routePath !== "view-manage-campaign") {
                    setSearchResult(data.data);
                }
            });
        }
    }, [searchText]);

    useEffect(() => {
        setMsgContentError("");
        if (msgType == 'shortMsg') {
            setMsgTypeUuid(CONSTANTS.SHORT_MSG);
        }
        if (msgType == 'longMsg') {
            setMsgTypeUuid(CONSTANTS.LONG_MSG);
        }
        if (msgType == 'essayMsg1') {
            setMsgTypeUuid(CONSTANTS.ESSAY_MSG_1);
        }
        if (msgType == 'essayMsg2') {
            setMsgTypeUuid(CONSTANTS.ESSAY_MSG_2);
        }
        if (msgType == 'essayMsg3') {
            setMsgTypeUuid(CONSTANTS.ESSAY_MSG_3);
        }
    }, [msgType]);

    useEffect(() => {
        if (mapRef.current) {
            // Perform animation after the map is initialized
            mapRef.current.setView(position, 13, { animate: true });
            // Add event listener for zoomstart event
            mapRef.current.on('zoomstart', onSetZoom);
            mapRef.current.on('click', onMapPress);

        }

        // Remove event listener on component unmount
        return () => {
            if (mapRef.current) {
                mapRef.current.off('zoomstart', onSetZoom);
                mapRef.current.off('click', onMapPress);
            }
        };
    }, [mapRef, position]);

    useEffect(() => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        if(tenantGUID){
            dispatch(fetchSingleApp(wid!, tenantGUID));
        }
    }, [wid, userDetails]);

    useEffect(() => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        if (singleAppDetails && singleAppDetails.widgetDetails) {
            dispatch(fetchSettlementTyps(tenantGUID, singleAppDetails.widgetDetails.uuid));
            dispatch(fetchMsisdnsList(tenantGUID, singleAppDetails.widgetDetails.uuid));
        }
    }, [userDetails, singleAppDetails]);

    useEffect(() => {
        let intervalId: any = null;

        if (receiverRemainingTime > 0) {
            intervalId = setInterval(() => {
                setReceiverRemainingTime((prevTime) => prevTime - 1);
            }, 1000);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [receiverRemainingTime]);

    useEffect(() => {
        let intervalId: any = null;

        if (trackingRemainingTime > 0) {
            intervalId = setInterval(() => {
                setTrackingRemainingTime((prevTime) => prevTime - 1);
            }, 1000);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [trackingRemainingTime]);

    useEffect(() => {
        setSubmitDisabled(!termsAndConditions);
    }, [termsAndConditions]);

    useEffect(() => {
        setPosition({ lat: latitude !== null ? latitude : 6.927079, lng: longitude !== null ? longitude : 79.861244});
        if (routePath === "manage-campaign-resubmit" || routePath === "manage-campaign-rejected-resubmit") {
            setSubmitDisabled(false);
        }
    }, [latitude, longitude, radius]);

    // useEffect(() => {
    //     // // console.log('campaignUpdate ----> ', campaignUpdate)
    //     if (campaignUpdate == 'Campaign config updated successfully') {
    //         setBtnDisable(true);
    //         history.push(`/entsubwidgets/${tenantGUID}/manage-campaign-workflow/${campaignId}`)
    //     } else {
    //         // setBtnDisable(false);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [campaignUpdate]);

    const setGenderCheckBox = (dataArr: string[]) => {
        if(Array.isArray(dataArr) && dataArr.length > 0) {
            dataArr.map((data: string) => {
                if(data === "male"){
                    setMale(true)
                }
                if(data === "female"){
                    setFemale(true)
                }
            })
        }
    }

    const setDeviceCheckBox = (dataArr: string[]) => {
        if(Array.isArray(dataArr) && dataArr.length > 0) {
            dataArr.map((data: string) => {
                if(data === "smart_phone"){
                    setDeviceType1(true)
                }
                if(data === "feature_phone"){
                    setDeviceType2(true)
                }
                if(data === "basic_phone"){
                    setDeviceType3(true)
                }
            })
        }
    }

    const setLanguageCheckBox = (dataArr: string[]) => {
        if(Array.isArray(dataArr) && dataArr.length > 0) {
            dataArr.map((data: string) => {
                if(data === "sinhala"){
                    setSinhala(true)
                }
                if(data === "english"){
                    setEnglish(true)
                }
                if(data === "tamil"){
                    setTamil(true)
                }
            })
        }
    }

    const setAgeCheckBox = (dataArr: string[]) => {
        if(Array.isArray(dataArr) && dataArr.length > 0) {
            dataArr.map((data: string) => {
                if(data === "20-30"){
                    setTwentyThirty(true)
                }
                if(data === "30-40"){
                    setThirtyForty(true)
                }
                if(data === "40-60"){
                    setFortySixty(true)
                }
                if(data === "60 Above"){
                    setSixtyAbove(true)
                }
            })
        }
    }

    const setBillValCheckBox = (dataArr: string[]) => {
        if(Array.isArray(dataArr) && dataArr.length > 0) {
            dataArr.map((data: string) => {
                if(data === "500"){
                    setBillVal1(true)
                }
                if(data === "500-2000"){
                    setBillVal2(true)
                }
                if(data === "2000-500"){
                    setBillVal3(true)
                }
                if(data === "10000"){
                    setBillVal4(true)
                }
            })
        }
    }

    const setBtnEnableForResubmit = () => {
        if(campaignStatus && campaignStatus === 'REJECTED'){
            setSubmitDisabled(false);
        }
    }

    const onShowTnC = () => {
        setShowTnC(true);
    }

    const onHideTnC = () => {
        setShowTnC(false);
    }

    const onChangeTitle = (e: any) => {
        setTitle(e.target.value);
        validator.fieldValid('title');
        validator.showMessageFor('title')
        forceUpdate();
        setBtnEnableForResubmit();
    }

    const onChangeMask = (e: any) => {
        setMask(e.target.value);
        setBtnEnableForResubmit();
    }

    const onChangeMsisdn = (e: any) => {
        setMsisdn(e.target.value);
        setBtnEnableForResubmit();
    }

    const onChangeMsgType = (e: any) => {
        const value = e.target.value;
        if (msgContent !== undefined && typeof msgContent == 'string') {
            // let msgConByteLength = Buffer.from(msgContent).length;
            const textEncoder = new TextEncoder();
            const msgConByteLength: number = textEncoder.encode(msgContent).length;
            if (msgConByteLength <= 124) {
                setMsgType(value);
            } else if (msgConByteLength > 124 && msgConByteLength <= 284) {
                setMsgType(value == 'shortMsg' ? 'longMsg' : value);
            } else if (msgConByteLength > 284 && msgConByteLength <= 444) {
                setMsgType((value == 'shortMsg' || value == 'longMsg') ? 'essayMsg1' : value);
            } else if (msgConByteLength > 444 && msgConByteLength <= 600) {
                setMsgType((value == 'shortMsg' || value == 'longMsg' || value == 'essayMsg1') ? 'essayMsg2' : value);
            } else if (msgConByteLength > 600 && msgConByteLength <= 960) {
                setMsgType((value == 'shortMsg' || value == 'longMsg' || value == 'essayMsg1' || value == 'essayMsg2') ? 'essayMsg3' : value);
            }
        } else {
            setMsgType(value);
        }
        setReachCost("")
        setBtnEnableForResubmit();
    }

    const onChangeMsgContent = (name: string) => (e: any) => {
        setMsgContentError("")
        //get byte length
        // let byteLength = Buffer.from(e.target.value).length;
        const textEncoder = new TextEncoder();
        const byteLength: number = textEncoder.encode(e.target.value).length;
        if (byteLength > 124 && msgType == 'shortMsg') {
            message.warning("Message type changed to Long Message", 5);
            setMsgType('longMsg');
            setReachCost("")
        }
        if (byteLength > 284 && msgType == 'longMsg') {
            message.warning("Message type changed to Essay Message 1", 5);
            setMsgType('essayMsg1');
            setReachCost("")
        }
        if (byteLength > 444 && msgType == 'essayMsg1') {
            message.warning("Message type changed to Essay Message 2", 5);
            setMsgType('essayMsg2');
            setReachCost("")
        }
        setMsgContent(e.target.value);
        if (byteLength > 600 && msgType == 'essayMsg2') {
            message.warning("Message type changed to Essay Message 3", 5);
            setMsgType('essayMsg3');
            setReachCost("")
        }
        if (byteLength > 960 && msgType == 'essayMsg3') {
            setMsgContentError("Cannot exceed 960 characters");
        }
        setBtnEnableForResubmit();
    }

    const onChangeLocationType = (e: any) => {
        setLocationType(e.target.value);
        setBtnEnableForResubmit();
    }

    const onChangeScheduleType = (e: any) => {
        setScheduleType(e.target.value);
        setBtnEnableForResubmit();
    }

    const onChangeDistrict = (e: any) => {
        setDistrictError('')
        setDistrict(e.target.value)
        let districtObj = districtList.find((value: any) => {
            return value.name === e.target.value;
        });
        if (districtObj !== null && typeof districtObj !== 'undefined') {
            setCityList(districtObj.City)
        }
        setBtnEnableForResubmit();
    }

    const onChangeCity = (e: any) => {
        setCityError('')
        setCity(e);
        setBtnEnableForResubmit();
    }

    const onChangeDate = (e: any) => {
        setDate(e);
        setBtnEnableForResubmit();
    }

    const onChangeDateRange = (e: any) => {
        setDateRange(e);
        setBtnEnableForResubmit();
    }

    const onChangeTestNumber = () => {
        setMsisdnError("");
        setBtnEnableForResubmit();
    }

    const calculateReachCost = (e: any) => {
        validator.fieldValid('reachCount')
        validator.showMessageFor('reachCount')
        forceUpdate();
        if (parseInt(reachCount) < CONSTANTS.MANAGE_CAMPAIGN_MIN_REACH_COUNT) {
            setReachCount(CONSTANTS.MANAGE_CAMPAIGN_MIN_REACH_COUNT.toString())
        }
        let body = {};
        if (reachCount) {
            if(tenantGuid && msgTypeUuid && widgetUuid) {
                if (parseInt(reachCount) < CONSTANTS.MANAGE_CAMPAIGN_MIN_REACH_COUNT) {
                    body = {
                        "transactionCount": CONSTANTS.MANAGE_CAMPAIGN_MIN_REACH_COUNT
                    }
                } else {
                    body = {
                        "transactionCount": reachCount
                    }
                }
                dispatch(fetchReachCost(body, tenantGuid, widgetUuid, msgTypeUuid));
            }
        } else {
            message.error('You can’t keep campaign reach as empty')
        }
        setBtnEnableForResubmit();
    }

    const calculateReachCostForEmpty = (e: any) => {
        validator.fieldValid('reachCount')
        validator.showMessageFor('reachCount')
        forceUpdate();
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setReachCount(e.target.value)
            if (e.target.value == "") {
                if(tenantGuid && msgTypeUuid && widgetUuid) {
                    let body = {
                        "transactionCount": 0
                    }
                    dispatch(fetchReachCost(body, tenantGuid, widgetUuid, msgTypeUuid))
                }
            }
        } else {
            setReachCount("")
        }
        setBtnEnableForResubmit();
    }

    const onChangeFormValue = (values: any) => {
        setTestNumbers(values.testNumbers)
        setBtnEnableForResubmit();
    }
    const onSearchLocation = (e: any) => {
        setLocationSelected(false);
        setSearchText(e.target.value);
    }

    const onSelectSearch = (value: any) => {
        var lat = parseFloat(value.lat);
        var lon = parseFloat(value.lon);
        setSearchText(value.display_name);
        setLocationSelected(true);
        setSearchResult([]);
        setLatitude(lat);
        setLongitude(lon)
    }

    const onMapPress = (e: any) => {
        setLongitude(e.latlng.lng);
        setLatitude(e.latlng.lat);
    }
    const onDragMarker = (e: any) => {
        try {
            setLongitude(e.target._latlng.lng);
            setLatitude(e.target._latlng.lat);
        } catch (err) {
            console.log(err);
        }
    }
    const onSetZoom = (e: any) => {
        try {
            setZoom(e.target._zoom);
        } catch (err) {
            console.log(err);
        }
    }

    // useMapEvents({
    //     zoomstart: (e: any) => {
    //         setZoom(e.target._zoom);
    //     },
    //     click: (e: any) => {
    //         setLongitude(e.latlng.lng);
    //         setLatitude(e.latlng.lat);
    //     },
    // });

    const onChangeRadius = (e: any) => {
        setRadius(e.target.value);
    }

    // - Age checkbox -
    const onChangeTwentyThirty = (e: any) => {
        setTwentyThirty(e.target.checked);
        setBtnEnableForResubmit();
    }
    const onChangeThirtyForty = (e: any) => {
        setThirtyForty(e.target.checked);
        setBtnEnableForResubmit();
    }
    const onChangeFortySixty = (e: any) => {
        setFortySixty(e.target.checked);
        setBtnEnableForResubmit();
    }
    const onChangeSixtyAbove = (e: any) => {
        setSixtyAbove(e.target.checked);
        setBtnEnableForResubmit();
    }

    // - Gender checkbox -
    const onChangeMale = (e: any) => {
        setMale(e.target.checked);
        setBtnEnableForResubmit();
    }
    const onChangeFemale = (e: any) => {
        setFemale(e.target.checked);
        setBtnEnableForResubmit();
    }

    // - Bill value checkbox -
    const onChangeBillValue1 = (e: any) => {
        setBillVal1(e.target.checked);
        setBtnEnableForResubmit();
    }
    const onChangeBillValue2 = (e: any) => {
        setBillVal2(e.target.checked);
        setBtnEnableForResubmit();
    }
    const onChangeBillValue3 = (e: any) => {
        setBillVal3(e.target.checked);
        setBtnEnableForResubmit();
    }
    const onChangeBillValue4 = (e: any) => {
        setBillVal4(e.target.checked);
        setBtnEnableForResubmit();
    }

    // - Device type checkbox -
    const onChangeDeviceType1 = (e: any) => {
        setDeviceType1(e.target.checked);
        setBtnEnableForResubmit();
    }
    const onChangeDeviceType2 = (e: any) => {
        setDeviceType2(e.target.checked);
        setBtnEnableForResubmit();
    }
    const onChangeDeviceType3 = (e: any) => {
        setDeviceType3(e.target.checked);
        setBtnEnableForResubmit();
    }

    // - Language checkbox -
    const onChangeSinhala = (e: any) => {
        setSinhala(e.target.checked);
        setBtnEnableForResubmit();
    }
    const onChangeEnglish = (e: any) => {
        setEnglish(e.target.checked);
        setBtnEnableForResubmit();
    }
    const onChangeTamil = (e: any) => {
        setTamil(e.target.checked);
        setBtnEnableForResubmit();
    }

    const onConfirmationTerms = (e: any) => {
        setTermsAndConditions(e.target.checked);
        setTncError("")
    }

    const getCheckBoxData = () => {
        //age
        let ageArray = [];
        if(twentyThirty){
            ageArray.push("20-30")
        }
        if(thirtyForty){
            ageArray.push("30-40")
        }
        if(fortySixty){
            ageArray.push("40-60")
        }
        if(sixtyAbove){
            ageArray.push("60 Above")
        }
        //gender
        let genderArray = [];
        if(male){
            genderArray.push("male")
        }
        if(female){
            genderArray.push("female")
        }
        //bill values
        let billValArray = [];
        if(billVal1){
            billValArray.push("500")
        }
        if(billVal2){
            billValArray.push("500-2000")
        }
        if(billVal3){
            billValArray.push("2000-500")
        }
        if(billVal4){
            billValArray.push("10000")
        }
        //device types
        let deviceArray = [];
        if(deviceType1){
            deviceArray.push("smart_phone")
        }
        if(deviceType2){
            deviceArray.push("feature_phone")
        }
        if(deviceType3){
            deviceArray.push("basic_phone")
        }
        //language
        let langArray = [];
        if(sinhala){
            langArray.push("sinhala")
        }
        if(english){
            langArray.push("english")
        }
        if(tamil){
            langArray.push("tamil")
        }
        return {
            age: ageArray,
            gender: genderArray,
            avgBillValue: billValArray,
            deviceTypes: deviceArray,
            languages: langArray
        }
    }

    const onCreateCampaign = async () => {
        let startTime = null;
        let endTime = null;
        if (scheduleType === 'schedule') {
            startTime = dayjs(dateRange[0]).format("YYYY-MM-DD HH:mm:ss");
            endTime = dayjs(dateRange[1]).format("YYYY-MM-DD HH:mm:ss");
        } else {
            startTime = dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss");
            endTime = dayjs(date).format("YYYY-MM-DD HH:mm:ss");
        }
        let filteredArray = form.getFieldValue('testNumbers')

        if(filteredArray && Array.isArray(filteredArray)) {
            // Remove duplicate objects
            let msisdns = filteredArray.map(function (o: any) {
                if (o !== null && o !== undefined && o.msisdn) {
                    return o.msisdn;
                }
            });

            filteredArray = filteredArray.filter((value: any, index: number) => {
                if (value !== null && value !== undefined && value.msisdn) {
                    return !msisdns.includes(value.msisdn, index + 1)
                }
            })

            // Remove null values
            filteredArray = filteredArray.filter(function (el: any) {
                return el != null;
            });

            // Remove duplicate
            filteredArray = filteredArray.filter(function (value: any, index: number, array: any[]) {
                return array.indexOf(value) == index;
            });

            filteredArray = filteredArray.map(function (value: any) {
                if (value.msisdn.length === 9 || value.msisdn.length === 10) {
                    value.msisdn = value.msisdn.length === 10 ? value.msisdn.substring(1, 10) : value.msisdn;
                }
                return value;
            })
        }

        const checkboxValues = getCheckBoxData();

        const body = {
            "title": title,
            "msisdn": msisdn,
            "mask": mask,
            "age": JSON.stringify(checkboxValues.age),
            "gender": checkboxValues.gender,
            "avgBillValue": JSON.stringify(checkboxValues.avgBillValue),
            "deviceType": checkboxValues.deviceTypes,
            "language": checkboxValues.languages,
            "location": locationType === 'map' ? searchText : null,
            "latitude": locationType === 'map' ? latitude : null,
            "longitude": locationType === 'map' ? longitude : null,
            "radius": locationType === 'map' ? radius : null,
            "messageType": msgType,
            "messageContent": msgContent,
            "startTime": startTime,
            "endTime": endTime,
            "reachCount": reachCount,
            "estimatedCost": reachCost,
            "tncUpdated": termsAndConditions,
            "numberBase": filteredArray,
            "district": locationType === 'address' ? district : null,
            "city": locationType === 'address' ? JSON.stringify(city) : null,
            "slabTypeUuid": msgTypeUuid
        }
        // console.log("body:::___>", body)
        if (checkboxValues.age.length > 0 || checkboxValues.gender.length > 0 || checkboxValues.avgBillValue.length > 0 || checkboxValues.deviceTypes.length > 0 || checkboxValues.languages.length > 0) {
            setFilterSectionError('');
            let newarray: any = [];
            form.getFieldValue('testNumbers').map(function (value: any) {
                newarray.push(value.msisdn)
            })
            var valuesSoFar = Object.create(null);
            let error_available = false;
            for (var i = 0; i < newarray.length; ++i) {
                var value = newarray[i];
                if (value in valuesSoFar) {
                    error_available = true;
                }
                valuesSoFar[value] = true;
            }
            if (error_available) {
                setMsisdnError("Please Remove Duplicate Test Numbers")

            } else {
                setMsisdnError("");
                dispatch(saveCampaign(body, tenantGuid, widgetUuid, navigate));
                setPosition({lat: 6.927079, lng: 79.861244});
                setReachCost("")
                form.resetFields(['reachCost', 'estimatedCost']);
                setTitle("");
            }
        }
        else {
            window.scrollTo(0, 700);
            setFilterSectionError('Please fill at least one criteria');
        }
        setLatitude(6.927079);
        setLongitude(79.861244);
        setRadius(500);
        setZoom(13);
        setAnimate(false);
        setLatlng({ lat: 6.927079, lng: 79.861244 });
        setBounds([
            [50.505, -29.09],
            [52.505, 29.09],
        ]);
    }

    const checkTnc = () => {
        if(routePath === "manage-campaign-create" && !termsAndConditions){
            return false
        } else {
            return true
        }
    }

    const handleWidgetSave = async () => {
        form.submit()
        // onCreateCampaign()
        setFilterSectionError('');
        if (validator.allValid()
            && (twentyThirty || thirtyForty || fortySixty || sixtyAbove)
            && (male || female)
            && (deviceType1 || deviceType2 || deviceType3)
            && (billVal1 || billVal2 || billVal3 || billVal4)
            && (sinhala || english || tamil)
            && checkTnc()
            && testNumbers.length > 0
            && (locationType === 'map' || (locationType === 'address' && district && city))
            && !msgContentError
        ) {
            confirmAlert({
                message: CONSTANTS.MANAGE_CAMPAIGN_SAVE,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => onCreateCampaign()
                    },
                    {
                        label: 'No',
                    }
                ]
            });
        } else {
            validator.showMessages();
            forceUpdate();
            if ((!twentyThirty && !thirtyForty && !fortySixty && !sixtyAbove) ||
                (!male && !female) ||
                (!deviceType1 && !deviceType2 && !deviceType3) ||
                (!billVal1 && !billVal2 && !billVal3 && !billVal4) ||
                (!sinhala && !english && !tamil)
            ) {
                setFilterSectionError('Please fill at least one criteria');
            }
            if(!checkTnc()){
                setTncError("Please accept terms and conditions")
            }
            if(locationType === 'address') {
                if(!district) {
                    setDistrictError("You can’t keep this as empty")
                }
                if(!city) {
                    setCityError("You can’t keep this as empty")
                }
            }
        }
    }

    const onResubmitCampaign = (values: any) => {
        let startTime = null;
        let endTime = null;
        if (scheduleType === 'schedule') {
            startTime = dayjs(dateRange[0]).format("YYYY-MM-DD HH:mm:ss");
            endTime = dayjs(dateRange[1]).format("YYYY-MM-DD HH:mm:ss");
        } else {
            startTime = dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss");
            endTime = dayjs(date).format("YYYY-MM-DD HH:mm:ss");
        }
        let filteredArray = form.getFieldValue('testNumbers')

        if(filteredArray && Array.isArray(filteredArray)) {
            // Remove duplicate objects
            let msisdns = filteredArray.map(function (o: any) {
                if (o !== null && o !== undefined && o.msisdn) {
                    return o.msisdn;
                }
            });

            filteredArray = filteredArray.filter((value: any, index: number) => {
                if (value !== null && value !== undefined && value.msisdn) {
                    return !msisdns.includes(value.msisdn, index + 1)
                }
            })

            // Remove null values
            filteredArray = filteredArray.filter(function (el: any) {
                return el != null;
            });

            // Remove duplicate
            filteredArray = filteredArray.filter(function (value: any, index: number, array: any[]) {
                return array.indexOf(value) == index;
            });

            filteredArray = filteredArray.map(function (value: any) {
                if (value.msisdn.length === 9 || value.msisdn.length === 10) {
                    value.msisdn = value.msisdn.length === 10 ? value.msisdn.substring(1, 10) : value.msisdn;
                }
                return value;
            })
        }

        const checkboxValues = getCheckBoxData();

        const body = {
            "title": title,
            "msisdn": msisdn,
            "mask": mask,
            "age": JSON.stringify(checkboxValues.age),
            "gender": checkboxValues.gender,
            "avgBillValue": JSON.stringify(checkboxValues.avgBillValue),
            "deviceType": checkboxValues.deviceTypes,
            "language": checkboxValues.languages,
            "location": locationType === 'map' ? searchText : null,
            "latitude": locationType === 'map' ? latitude : null,
            "longitude": locationType === 'map' ? longitude : null,
            "radius": locationType === 'map' ? radius : null,
            "messageType": msgType,
            "messageContent": msgContent,
            "startTime": startTime,
            "endTime": endTime,
            "reachCount": reachCount,
            "estimatedCost": reachCost,
            "tncUpdated": termsAndConditions,
            "numberBase": filteredArray,
            "district": locationType === 'address' ? district : null,
            "city": locationType === 'address' ? JSON.stringify(city) : null,
            "slabTypeUuid": msgTypeUuid
        }
        dispatch(updateManageCampaingDetails(body, tenantGuid, widgetUuid, window.location.pathname.split('/')[4], navigate))
    }

    // const submitTrackingData = async () => {
    //     var trackingData = {
    //         "title": title,
    //         "trackingNumber": trackerNumber,
    //         "trackerNumber": alertReceiving,
    //         "personName": trackerName,
    //         "location": searchText,
    //         "longitude": longitude,
    //         "latitude": latitude,
    //         "radius": radius,
    //         "monday": mon,
    //         "tuesday": tue,
    //         "wednesday": wed,
    //         "thursday": thu,
    //         "friday": fri,
    //         "saturday": sat,
    //         "sunday": sun,
    //         "status": status,
    //         "trackTime": dayjs(time).format('HH:mm'),
    //         "expiredAt": dayjs(expiredate).format('YYYY-MM-DD HH:mm:ss'),
    //         "trackerOTPVerified": "Verify",
    //         "trackingOTPVerified": "Verify",
    //         "trackerOTPReference": receiverServerRef,
    //         "trackingOTPReference": trackerServerRef,
    //         "taAccepted": "2020-10-05 00:00:00"
    //     };
    //
    //     const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
    //
    //     setFormSubmitted(true);
    //     dispatch(postLocation(trackingData, tenantGUID, singleAppDetails?.widgetDetails?.uuid, navigate));
    //
    //     setTitle("");
    //     setSaveSuccess(locationSubmitSuccess);
    //     setSaveError(locationSubmitError);
    //     setSaveLoading(locationSubmitLoading);
    //     setLocationSubmitErrorMessage(locationSubmitErrorMessage);
    //     setLatitude(6.927079);
    //     setLongitude(79.861244);
    //     setRadius(500);
    //     setZoom(13);
    //     setAnimate(false);
    //     setLatlng({ lat: 6.927079, lng: 79.861244 });
    //     setBounds([
    //         [50.505, -29.09],
    //         [52.505, 29.09],
    //     ]);
    //     setSearchText("");
    //     setAlertReceiving("");
    //     setAlertReceivingError("");
    //     setReceiverOtp("");
    //     setReceiverOtpError("");
    //     setReceiverOtpLoading(false);
    //     setReceiverOtpSuccess(null);
    //     setReceiverServerRef("");
    //     setReceiverOtpReadOnly(false);
    //     setTrackerNumber("");
    //     setTrackerOtp("");
    //     setTrackerOtpError("");
    //     setTrackerOtpLoading(false);
    //     setTrackerOtpSuccess(null);
    //     setTrackerServerRef("");
    //     setTrackerOtpReadOnly(false);
    //     setCheckedDays([]);
    //     setMon(false);
    //     setTue(false);
    //     setWed(false);
    //     setThu(false);
    //     setFri(false);
    //     setSat(false);
    //     setSun(false);
    //     setDayError("");
    //     setTime("");
    //     setTimeError("");
    //     setStatus("Active");
    //     setTrackerName("");
    //     setTrackerNameError("");
    //     setSearchResult([]);
    //     setSubmitDisabled(true);
    //     setShowTermsAndConditions(false);
    //     setTermsAndConditionsDetails("");
    //     setPaymentMethodId("");
    //
    //     // The validator hideMessageFor calls can be removed as they are not relevant for hooks-based implementation.
    // };

    const renderSearchList = () => {
        return (
            <ul className="dropdown-list">
                {searchResult && searchResult.map(function (value: any, index: number) {
                    return (
                        <li className="dropdown-item" key={index}>
                            <a onClick={() => onSelectSearch(value)}>{value.display_name}</a>
                        </li>
                    )
                })}
            </ul>
        )
    }

    const renderSubmitButton = () => {
        if (AuthHandler.isPerformable(auth, "add-manage-campaign-config")) {
            return (
                <button
                    className="btn btn-primary"
                    onClick={(routePath !== "manage-campaign-resubmit" && routePath !== "manage-campaign-rejected-resubmit") ? handleWidgetSave : onResubmitCampaign}
                    disabled={(routePath === "manage-campaign-resubmit" || routePath === "manage-campaign-rejected-resubmit") ? submitDisabled : false}
                >
                    {(routePath !== "manage-campaign-resubmit" && routePath !== "manage-campaign-rejected-resubmit") ? "Submit" : "Resubmit"}
                </button>
            )
        } else {
            return (
                <button type="button" className="btn btn-primary" disabled={true}>You don't have access to
                    create a location track</button>
            )
        }
    }

    useEffect(() => {
        if (msisdnData && msisdnData.data) {
            if (otpSender === 'receiver') {
                setShowReceiverMsisdnOtpField(true);
                setReceiverRemainingTime(120);
                setReceiverServerRef(msisdnData.data.serverRef);
            } else {
                setShowTrackingMsisdnOtpField(true);
                setTrackingRemainingTime(120);
                setTrackerServerRef(msisdnData.data.serverRef);
            }
            forceUpdate();
        }
    }, [msisdnData])

    const renderMasksList = () => {
        let list: JSX.Element[] = [<option key={'-1'} value="" disabled hidden>Select the Mask</option>]
        if(maskList && Array.isArray(maskList) && maskList.length > 0) {
            maskList.map((maskObj, index) => {
                list.push(<option key={index} value={maskObj.mask}>{decodeURIComponent(maskObj.mask)}</option>)
            })
        }
        return list
    }

    const renderMsisdnList = () => {
        let list: JSX.Element[] = [<option key={'-1'} value="" disabled hidden>Select the charging number</option>]
        if(msisdnList && Array.isArray(msisdnList) && msisdnList.length > 0) {
            msisdnList.map((msisdnObj, index) => {
                list.push(<option key={index} value={msisdnObj.uuid}>{msisdnObj.msisdn}</option>)
            })
        }
        return list
    }

    const renderDistrictList = () => {
        let list: JSX.Element[] = [<option key={'-1'} value="" disabled hidden>Select District</option>]
        if(districtList && Array.isArray(districtList) && districtList.length > 0) {
            districtList.map((districtObj, index) => {
                list.push(<option key={index} value={districtObj.name}>{districtObj.name}</option>)
            })
        }
        return list
    }

    if (AuthHandler.isAuthorized(auth, 'add-manage-campaign-config') && userDetails !== null) {
        // return (
            if(userDetails) {
                return (
                <React.Fragment>
                    <div className="main-content main-spacer-x-y">
                        <PageHeaderStatic
                            page={'create'}
                        />
                        <div className="card added-app-thumbnnail__wrapper">
                            <div
                                className="added-app-thumbnail__inner"
                                style={{
                                    backgroundImage: `url(${(singleAppDetails && singleAppDetails.widgetDetails !== null) ? singleAppDetails.widgetDetails.leftImage : ""})`
                                }}
                            ></div>
                        </div>
                        <div className="card panel content-holder-panels added-app-form__wrapper">
                            <div className="panel-header">
                                <div className="panel-header-content__wrapper">
                                    <h4 className="panel-header-title">Create Your Campaign</h4>
                                </div>
                            </div>
                            <div className="panel-content">
                                <div className="wiz-content-form__wrapper">
                                    <p>
                                        {(singleAppDetails && singleAppDetails.widgetDetails !== null) ? singleAppDetails.widgetDetails.description : ""}
                                    </p>
                                    <br/>
                                    <div className="form-group__wrapper">
                                        <div className="form-group">
                                            <div className="form-element__wrapper mb-3">
                                                <label htmlFor="" className="form-label">
                                                    Campaign Title<span aria-label="required"></span>
                                                </label>
                                                <input
                                                    type="text"
                                                    id="tile"
                                                    className="form-control"
                                                    value={title}
                                                    onChange={onChangeTitle}
                                                    placeholder="Type the title of your campaign"
                                                    disabled={routePath === "manage-campaign-resubmit" || routePath === "view-manage-campaign" || routePath === "manage-campaign-rejected-resubmit"}
                                                />
                                                <div
                                                    className="validation invalid-feedback">{validator.message('title', title, ['required', 'alpha_num_dash_space', {max: 100}])}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group__wrapper">
                                        {userDetails && userDetails.OwnedEnterprises[0] && userDetails.OwnedEnterprises[0].Enterprise && userDetails.OwnedEnterprises[0].Enterprise.billingStatus == 'Invoice' ?
                                            <div className="form-group">
                                                {/*{renderOwnersNumber()}*/}
                                                <InputTextField
                                                    name='ownerMsisdn'
                                                    label='Enterprise owner MSISDN'
                                                    placeholder='Charging number'
                                                    value={msisdn}
                                                    setValue={() => () => {
                                                    }}
                                                    validator={validator}
                                                    rules={['required', 'dialog']}
                                                    disabled={fieldDisabled}
                                                    status={"verified"}
                                                    forceUpdate={forceUpdate}
                                                    errorText={''}
                                                    readOnly={true}
                                                />
                                            </div> : <div className="form-group">
                                                <div className="form-element__wrapper mb-3 ">
                                                    <label htmlFor="ownerMsisdn" className="form-label">Enterprise owner
                                                        MSISDN<span aria-label="required"></span>&nbsp;</label>
                                                    <div className="icon-inside right">
                                                        <select name="ownerMsisdn"
                                                                id="" className="form-control"
                                                                value={msisdn}
                                                                onChange={onChangeMsisdn}
                                                                disabled={fieldDisabled}
                                                        >
                                                            {renderMsisdnList()}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>}
                                        <div className="form-group">
                                            <div className="form-group">
                                                <div className="form-element__wrapper mb-3 ">
                                                    <label htmlFor="numberMask" className="form-label">Number Mask<span
                                                        aria-label="required"></span>&nbsp;
                                                        <Tooltip
                                                            title={<p>Note: If you’re unable to see masks in below list,
                                                                please add mask/s via your enterprise profile "Add mask
                                                                section"</p>}>
                                                            <span><i className="bi bi-info-circle"></i></span>
                                                        </Tooltip></label>
                                                    <div className="icon-inside right">
                                                        <select name="numberMask"
                                                                id=""
                                                                className="form-control"
                                                                value={mask}
                                                                onChange={onChangeMask}
                                                                disabled={fieldDisabled}
                                                        >
                                                            {renderMasksList()}
                                                        </select>
                                                    </div>
                                                    <div className="validation invalid-feedback">{validator.message('mask', mask, ['required'])}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group__wrapper">
                                        <div className="form-group">
                                            <div className="form-group">
                                                <div className="form-element__wrapper mb-3 ">
                                                    <label htmlFor="messageType" className="form-label">Message
                                                        type<span aria-label="required"></span></label>
                                                    <div className="icon-inside right">
                                                        <select name="industry" id=""
                                                                className="form-control"
                                                                value={msgType}
                                                                onChange={onChangeMsgType}
                                                                disabled={fieldDisabled}
                                                        >
                                                            <option key={'-1'} value="" disabled hidden>Select the
                                                                Message type
                                                            </option>
                                                            <option key={"shortMsg"} value={"shortMsg"}>Short Message
                                                            </option>
                                                            <option key={"longMsg"} value={"longMsg"}>Long Message
                                                            </option>
                                                            <option key={"essayMsg1"} value={"essayMsg1"}>Essay Type
                                                                Message(1)
                                                            </option>
                                                            <option key={"essayMsg2"} value={"essayMsg2"}>Essay Type
                                                                Message(2)
                                                            </option>
                                                            <option key={"essayMsg3"} value={"essayMsg3"}>Essay Type
                                                                Message(3)
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div className="validation invalid-feedback">{validator.message('msgType', msgType, ['required'])}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group"/>
                                    </div>
                                    <div className="form-group__wrapper">
                                        <div className="form-group">
                                            <div className="form-element__wrapper mb-3">
                                                <InputTextArea
                                                    name='messageContent'
                                                    label='Message content'
                                                    placeholder='Please enter a message content'
                                                    value={msgContent}
                                                    setValue={onChangeMsgContent}
                                                    validator={validator}
                                                    rules={['required']}
                                                    disabled={fieldDisabled}
                                                    status="default"
                                                    validationMessage=""
                                                    rows={5}
                                                    forceUpdate={forceUpdate}
                                                />
                                                <div className="validation invalid-feedback">{msgContentError}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                    <h4 className="inner-title bottom-hr">How would be your campaign base</h4>
                                    <div className="validation invalid-feedback">{filterSectionError}</div>
                                    <div className="form-group__wrapper">
                                        <div className="form-group">
                                            <div className="form-element__wrapper mb-3">
                                                <label htmlFor="" className="form-label">
                                                    Age
                                                </label>
                                                <div
                                                    className="inner-multiple-feature-list-element__wrapper horizontal">
                                                    <div className="form-check mb-4">
                                                        <input
                                                            type="checkbox"
                                                            id="twentyThirty"
                                                            name="twentyThirty"
                                                            className="form-check-input"
                                                            onChange={onChangeTwentyThirty}
                                                            checked={twentyThirty}
                                                            disabled={fieldDisabled}
                                                        />&nbsp;
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="flexCheckDefault"
                                                        >
                                                            20 - 30
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-4">
                                                        <input
                                                            type="checkbox"
                                                            id="thirtyForty"
                                                            name="thirtyForty"
                                                            className="form-check-input"
                                                            onChange={onChangeThirtyForty}
                                                            checked={thirtyForty}
                                                            disabled={fieldDisabled}
                                                        />&nbsp;
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="flexCheckDefault"
                                                        >
                                                            30 - 40
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-4">
                                                        <input
                                                            type="checkbox"
                                                            id="fortySixty"
                                                            name="fortySixty"
                                                            className="form-check-input"
                                                            onChange={onChangeFortySixty}
                                                            checked={fortySixty}
                                                            disabled={fieldDisabled}
                                                        />&nbsp;
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="flexCheckDefault"
                                                        >
                                                            40 - 60
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-4">
                                                        <input
                                                            type="checkbox"
                                                            id="sixtyAbove"
                                                            name="sixtyAbove"
                                                            className="form-check-input"
                                                            onChange={onChangeSixtyAbove}
                                                            checked={sixtyAbove}
                                                            disabled={fieldDisabled}
                                                        />&nbsp;
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="flexCheckDefault"
                                                        >
                                                            60 Above
                                                        </label>
                                                    </div>
                                                </div>
                                                {/*<div className="validation invalid-feedback">{ageError}</div>*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group__wrapper">
                                        <div className="form-group">
                                            <div className="form-element__wrapper mb-3">
                                                <label htmlFor="" className="form-label">
                                                    Gender
                                                </label>
                                                <div
                                                    className="inner-multiple-feature-list-element__wrapper horizontal">
                                                    <div className="form-check mb-4">
                                                        <input
                                                            type="checkbox"
                                                            id="male"
                                                            name="male"
                                                            className="form-check-input"
                                                            onChange={onChangeMale}
                                                            checked={male}
                                                            disabled={fieldDisabled}
                                                        />&nbsp;
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="flexCheckDefault"
                                                        >
                                                            Male
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-4">
                                                        <input
                                                            type="checkbox"
                                                            id="female"
                                                            name="female"
                                                            className="form-check-input"
                                                            onChange={onChangeFemale}
                                                            checked={female}
                                                            disabled={fieldDisabled}
                                                        />&nbsp;
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="flexCheckDefault"
                                                        >
                                                            Female
                                                        </label>
                                                    </div>
                                                </div>
                                                {/*<div className="validation invalid-feedback">{genderError}</div>*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group__wrapper">
                                        <div className="form-group">
                                            <div className="form-element__wrapper mb-3">
                                                <label htmlFor="" className="form-label">
                                                    Average Bill Value
                                                </label>
                                                <div
                                                    className="inner-multiple-feature-list-element__wrapper horizontal">
                                                    <div className="form-check mb-4">
                                                        <input
                                                            type="checkbox"
                                                            id="billVal1"
                                                            name="billVal1"
                                                            className="form-check-input"
                                                            onChange={onChangeBillValue1}
                                                            checked={billVal1}
                                                            disabled={fieldDisabled}
                                                        />&nbsp;
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="flexCheckDefault"
                                                        >
                                                            {'< Rs 500'}
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-4">
                                                        <input
                                                            type="checkbox"
                                                            id="billVal2"
                                                            name="billVal2"
                                                            className="form-check-input"
                                                            onChange={onChangeBillValue2}
                                                            checked={billVal2}
                                                            disabled={fieldDisabled}
                                                        />&nbsp;
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="flexCheckDefault"
                                                        >
                                                            {'Rs 500-2000'}
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-4">
                                                        <input
                                                            type="checkbox"
                                                            id="billVal3"
                                                            name="billVal3"
                                                            className="form-check-input"
                                                            onChange={onChangeBillValue3}
                                                            checked={billVal3}
                                                            disabled={fieldDisabled}
                                                        />&nbsp;
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="flexCheckDefault"
                                                        >
                                                            {'Rs 2000-5000'}
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-4">
                                                        <input
                                                            type="checkbox"
                                                            id="billVal4"
                                                            name="billVal4"
                                                            className="form-check-input"
                                                            onChange={onChangeBillValue4}
                                                            checked={billVal4}
                                                            disabled={fieldDisabled}
                                                        />&nbsp;
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="flexCheckDefault"
                                                        >
                                                            {'> Rs 10000'}
                                                        </label>
                                                    </div>
                                                </div>
                                                {/*<div className="validation invalid-feedback">{billValueError}</div>*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group__wrapper">
                                        <div className="form-group">
                                            <div className="form-element__wrapper mb-3">
                                                <label htmlFor="" className="form-label">
                                                    Device Type
                                                </label>
                                                <div
                                                    className="inner-multiple-feature-list-element__wrapper horizontal">
                                                    <div className="form-check mb-4">
                                                        <input
                                                            type="checkbox"
                                                            id="deviceType1"
                                                            name="deviceType1"
                                                            className="form-check-input"
                                                            onChange={onChangeDeviceType1}
                                                            checked={deviceType1}
                                                            disabled={fieldDisabled}
                                                        />&nbsp;
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="flexCheckDefault"
                                                        >
                                                            Smart phone
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-4">
                                                        <input
                                                            type="checkbox"
                                                            id="deviceType2"
                                                            name="deviceType2"
                                                            className="form-check-input"
                                                            onChange={onChangeDeviceType2}
                                                            checked={deviceType2}
                                                            disabled={fieldDisabled}
                                                        />&nbsp;
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="flexCheckDefault"
                                                        >
                                                            Feature Phone
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-4">
                                                        <input
                                                            type="checkbox"
                                                            id="deviceType3"
                                                            name="deviceType3"
                                                            className="form-check-input"
                                                            onChange={onChangeDeviceType3}
                                                            checked={deviceType3}
                                                            disabled={fieldDisabled}
                                                        />&nbsp;
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="flexCheckDefault"
                                                        >
                                                            Basic Phone
                                                        </label>
                                                    </div>
                                                </div>
                                                {/*<div className="validation invalid-feedback">{deviceTypeError}</div>*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group__wrapper">
                                        <div className="form-group">
                                            <div className="form-element__wrapper mb-3">
                                                <label htmlFor="" className="form-label">
                                                    Preferred Language
                                                </label>
                                                <div
                                                    className="inner-multiple-feature-list-element__wrapper horizontal">
                                                    <div className="form-check mb-4">
                                                        <input
                                                            type="checkbox"
                                                            id="sinhala"
                                                            name="sinhala"
                                                            className="form-check-input"
                                                            onChange={onChangeSinhala}
                                                            checked={sinhala}
                                                            disabled={fieldDisabled}
                                                        />&nbsp;
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="flexCheckDefault"
                                                        >
                                                            Sinhala
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-4">
                                                        <input
                                                            type="checkbox"
                                                            id="english"
                                                            name="english"
                                                            className="form-check-input"
                                                            onChange={onChangeEnglish}
                                                            checked={english}
                                                            disabled={fieldDisabled}
                                                        />&nbsp;
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="flexCheckDefault"
                                                        >
                                                            English
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-4">
                                                        <input
                                                            type="checkbox"
                                                            id="tamil"
                                                            name="tamil"
                                                            className="form-check-input"
                                                            onChange={onChangeTamil}
                                                            checked={tamil}
                                                            disabled={fieldDisabled}
                                                        />&nbsp;
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="flexCheckDefault"
                                                        >
                                                            Tamil
                                                        </label>
                                                    </div>
                                                </div>
                                                {/*<div className="validation invalid-feedback">{languageError}</div>*/}
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="form-group__wrapper">
                                        <div className="form-group">
                                            <div className="form-element__wrapper mb-3">
                                                <div
                                                    className="inner-multiple-feature-list-element__wrapper horizontal">
                                                    <div className="form-check mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="address"
                                                            id="address"
                                                            value="address"
                                                            // value={JSON.stringify(subWidget)}
                                                            onChange={onChangeLocationType}
                                                            checked={locationType === "address"}
                                                            disabled={fieldDisabled}
                                                        />&nbsp;
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="flexCheckDefault"
                                                        >
                                                            Select Location
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="map"
                                                            id="map"
                                                            value="map"
                                                            // value={JSON.stringify(subWidget)}
                                                            onChange={onChangeLocationType}
                                                            checked={locationType === "map"}
                                                            disabled={fieldDisabled}
                                                        />&nbsp;
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="flexCheckDefault"
                                                        >
                                                            Select from the map
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {locationType === "address" ? <>
                                        <div className="form-group__wrapper">
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <div className="form-element__wrapper mb-3 ">
                                                        <label htmlFor="messageType"
                                                               className="form-label">District<span
                                                            aria-label="required"></span></label>
                                                        <div className="icon-inside right">
                                                            <select name="industry" id="" className="form-control"
                                                                    value={district}
                                                                    disabled={fieldDisabled}
                                                                    onChange={onChangeDistrict}
                                                            >
                                                                {renderDistrictList()}
                                                            </select>
                                                        </div>
                                                        <div className="validation invalid-feedback">{districtError}</div>
                                                        {/*<div className="validation invalid-feedback">{validator.message('district', district, ['required'])}</div>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group__wrapper">
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <div className="form-element__wrapper mb-3 ">
                                                        <label htmlFor="messageType" className="form-label">City<span
                                                            aria-label="required"></span></label>
                                                        <div className="icon-inside right">
                                                            <Select
                                                                placeholder="Select City"
                                                                showSearch
                                                                disabled={fieldDisabled}
                                                                allowClear
                                                                optionFilterProp="children"
                                                                mode="multiple"
                                                                style={{width: '100%'}}
                                                                onChange={onChangeCity}
                                                                value={city ? city : []}
                                                            >
                                                                {cityList && cityList.length > 0 && cityList.map(item => (
                                                                    <Option key={item.name}
                                                                            value={item.name}>{item.name}</Option>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                        <div className="validation invalid-feedback">{cityError}</div>
                                                        {/*<div className="validation invalid-feedback">{validator.message('city', city, ['required'])}</div>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </> : locationType === "map" ? <>
                                        <div className="form-group__wrapper">
                                            <div className="form-group">
                                                <div className="form-element__wrapper mb-3 has-dropdown">
                                                    <label htmlFor="" className="form-label">
                                                        Search location
                                                    </label>
                                                    <div className="icon-inside right">
                                                <span className="in-icon" onClick={() => {
                                                    setSearchText('');
                                                    setLocationSelected(false);
                                                    setSearchResult([]);
                                                }}>
                                                    <i className={searchText == '' ? "bi bi-search" : "bi bi-x"}/>
                                                </span>
                                                        <input
                                                            type="text"
                                                            id="otp"
                                                            className="form-control form-control-md"
                                                            value={searchText}
                                                            onChange={onSearchLocation}
                                                            placeholder="Enter location"
                                                            disabled={fieldDisabled}
                                                        />
                                                    </div>
                                                    {(searchResult.length !== 0) && <div className="dropdown first__element">
                                                        {renderSearchList()}
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group__wrapper">
                                            <div className="form-group">
                                                <div className="form-element__wrapper mb-3 second__element">
                                                    <label htmlFor="" className="form-label">
                                                        Select location on the map
                                                    </label>
                                                    <div className="inner-feature-element-container__wrapper">
                                                        <MapContainer
                                                            ref={mapRef}
                                                            center={position}
                                                            zoom={zoom}
                                                            zoomControl={true}
                                                            doubleClickZoom={true}
                                                            id="map"
                                                        >
                                                            <TileLayer
                                                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                                attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                                                            />
                                                            <Marker
                                                                draggable
                                                                opacity={0.8}
                                                                position={position}
                                                                icon={MarkerIcon}
                                                                eventHandlers={{
                                                                    dragend: onDragMarker,
                                                                }}
                                                            >
                                                            </Marker>
                                                            <Circle
                                                                center={position}
                                                                radius={radius}
                                                                fillColor='red'
                                                            />
                                                        </MapContainer>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group__wrapper">
                                            <div className="form-group">
                                                <div className="form-element__wrapper mb-3">
                                                    <label htmlFor="" className="form-label">
                                                        Select fence radius
                                                    </label>
                                                    <div>
                                                        <input
                                                            type="range"
                                                            className="custom-range"
                                                            value={radius}
                                                            onChange={onChangeRadius}
                                                            min="0"
                                                            max="5000"
                                                            step="500"
                                                            id="range"
                                                            disabled={fieldDisabled}
                                                        />
                                                        <span
                                                            className="font-weight-bold text-primary ml-2 mt-1 valueSpan">{radius / 1000} km</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </> : <></>}
                                    {routePath === "manage-campaign-resubmit" ?
                                        <div className="alert info">
                                            <span className="alert-text__wrapper"><i
                                                className="alert-icon bi bi-info-circle-fill"/><span
                                                className="alert-text">Note: Your campaign dispatch time will be within 4-5 days after the resubmission.</span></span>
                                        </div>
                                        : (routePath === "manage-campaign-create" || routePath === "manage-campaign-rejected-resubmit") ?
                                            <div className="alert info">
                                                <span className="alert-text__wrapper"><i
                                                    className="alert-icon bi bi-info-circle-fill"/><span
                                                    className="alert-text">Note: Your campaign dispatch time will be within 4-5 days of the submission time.</span></span>
                                            </div> : ''}
                                    <div className="form-group__wrapper">
                                        <div className="form-group">
                                            <div className="form-element__wrapper mb-3">
                                                <label htmlFor="" className="form-label">
                                                    Campaign execute date/time<span aria-label="required"></span>
                                                </label>
                                                <div
                                                    className="inner-multiple-feature-list-element__mc-wrapper horizontal">
                                                    <div className="form-check mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="now"
                                                            id="radio1"
                                                            value="now"
                                                            onChange={onChangeScheduleType}
                                                            checked={scheduleType === "now"}
                                                            disabled={fieldDisabled}
                                                        />&nbsp;
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="flexCheckDefault"
                                                        >
                                                            Start Now
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="schedule"
                                                            id="radio1"
                                                            value="schedule"
                                                            // value={JSON.stringify(subWidget)}
                                                            onChange={onChangeScheduleType}
                                                            checked={scheduleType === "schedule"}
                                                            disabled={fieldDisabled}
                                                        />&nbsp;
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="flexCheckDefault"
                                                        >
                                                            Schedule
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {scheduleType === "now" ? <div className="form-group">
                                            <div className="form-element__wrapper mb-3">
                                                <label htmlFor="" className="form-label">
                                                    End date/time<span aria-label="required"></span>
                                                </label>
                                                {(date && date != '') ? <DatePicker
                                                    disabled={fieldDisabled}
                                                    className="gx-mb-3 gx-w-100"
                                                    format="YYYY-MM-DD HH:mm:ss"
                                                    style={{width: "100%"}}
                                                    defaultValue={dayjs(date)}
                                                    disabledDate={(current) => {
                                                        return current && current.valueOf() < Date.now();
                                                    }}
                                                    showTime={{defaultValue: dayjs('00:00:00', 'HH:mm:ss')}}
                                                    onChange={onChangeDate}
                                                /> : <DatePicker
                                                    disabled={fieldDisabled}
                                                    className="gx-mb-3 gx-w-100"
                                                    format="YYYY-MM-DD HH:mm:ss"
                                                    style={{width: "100%"}}
                                                    disabledDate={(current) => {
                                                        return current && current.valueOf() < Date.now();
                                                    }}
                                                    showTime={{defaultValue: dayjs('00:00:00', 'HH:mm:ss')}}
                                                    onChange={onChangeDate}
                                                />}
                                                <div
                                                    className="validation invalid-feedback">{validator.message('stop date', date, 'required')}</div>
                                            </div>
                                        </div> : scheduleType === "schedule" ? <div className="form-group">
                                            <div className="form-element__wrapper mb-3">
                                                <label htmlFor="" className="form-label">
                                                    Start - End date/time<span aria-label="required"></span>
                                                </label>
                                                {(dateRange && dateRange.length > 0) ? <RangePicker
                                                    disabled={fieldDisabled}
                                                    className="gx-mb-3 gx-w-100"
                                                    format="YYYY-MM-DD HH:mm:ss"
                                                    style={{width: "100%"}}
                                                    value={[dayjs(dateRange[0]), dayjs(dateRange[1])]}
                                                    disabledDate={(current) => {
                                                        return dayjs().add(-1, 'days') >= current
                                                    }}
                                                    showTime={{
                                                        defaultValue: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')]
                                                    }}
                                                    onChange={onChangeDateRange}
                                                /> : <RangePicker
                                                    disabled={fieldDisabled}
                                                    className="gx-mb-3 gx-w-100"
                                                    format="YYYY-MM-DD HH:mm:ss"
                                                    style={{width: "100%"}}
                                                    disabledDate={(current) => {
                                                        return dayjs().add(-1, 'days') >= current
                                                    }}
                                                    showTime={{
                                                        defaultValue: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')]
                                                    }}
                                                    onChange={onChangeDateRange}
                                                />}
                                                <div
                                                    className="validation invalid-feedback">{validator.message('stop date', dateRange, 'required')}</div>
                                            </div>
                                        </div> : <></>}
                                    </div>
                                    <div className="form-group__wrapper">
                                        <div className="form-group">
                                            <div className="form-element__wrapper mb-3">
                                                <label htmlFor="" className="form-label">
                                                    Campaign Reach<span aria-label="required"></span>&nbsp;
                                                    <Tooltip title={<p>Note: Minimum reach count
                                                        is {CONSTANTS.MANAGE_CAMPAIGN_MIN_REACH_COUNT}</p>}>
                                                        <span><i className="bi bi-info-circle"></i></span>
                                                    </Tooltip>
                                                </label>
                                                <input
                                                    type="text"
                                                    id="reachCount"
                                                    className="form-control"
                                                    value={reachCount}
                                                    onBlur={calculateReachCost}
                                                    onChange={calculateReachCostForEmpty}
                                                    placeholder="Type the reach count that you want to send the campaign"
                                                    disabled={fieldDisabled}
                                                />
                                                <div
                                                    className="validation invalid-feedback">{validator.message('reachCount', reachCount, 'required|numeric')}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group__wrapper">
                                        <div className="form-group">
                                            <div className="form-element__wrapper mb-3 has-dropdown">
                                                <label htmlFor="" className="form-label">Estimated Campaign Cost
                                                    (Rs.)<span aria-label="required"></span></label>
                                                <div className="left d-flex btn-with-group">
                                                    <input
                                                        type="text"
                                                        id="reachCost"
                                                        className="form-control"
                                                        value={reachCost}
                                                        // onChange={onChangeTitle}
                                                        readOnly
                                                    />
                                                    <button className="btn btn-primary"
                                                            onClick={calculateReachCost}>Calculate
                                                    </button>
                                                </div>
                                                <div
                                                    className="validation invalid-feedback">{validator.message('reachCost', reachCost, 'required|numeric|reachCost')}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group__wrapper">
                                        <div className="form-group">
                                            <div className="form-element__wrapper mb-3">
                                                <Form
                                                    onValuesChange={onChangeFormValue}
                                                    layout="vertical"
                                                    form={form}
                                                    size="large"
                                                >
                                                    <Form.List
                                                        name="testNumbers"
                                                        rules={[
                                                            {
                                                                validator: async (_, testNumbers) => {
                                                                    if (!testNumbers || testNumbers.length < 1) {
                                                                        return Promise.reject(new Error("You can't keep this as empty"));
                                                                    }
                                                                },
                                                            },
                                                        ]}>
                                                        {(fields, {add, remove}, {errors}) => (
                                                            <>
                                                                {fields.map(({key, name, fieldKey, ...restField}) => (
                                                                    <Space key={key}
                                                                           style={{display: 'flex', marginBottom: 8}}
                                                                           align="baseline">
                                                                        <Form.Item
                                                                            {...restField}

                                                                            name={[name, 'name']}
                                                                            fieldKey={[key, 'name']}
                                                                            rules={[() => ({
                                                                                validator(rule, value) {
                                                                                    if (!value) {
                                                                                        return Promise.reject('You can’t keep this empty');
                                                                                    } else if (value.length > 31) {
                                                                                        return Promise.reject('Name of the numbers are too lengthy');
                                                                                    } else {
                                                                                        return Promise.resolve();
                                                                                    }
                                                                                },
                                                                            }),
                                                                            ]}
                                                                        >
                                                                            <Input placeholder="Name"
                                                                                   disabled={fieldDisabled} style={{fontSize: '14px'}}/>
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[name, 'msisdn']}
                                                                            fieldKey={[key, 'msisdn']}
                                                                            rules={[() => ({
                                                                                validator(rule, value) {
                                                                                    if (!value) {
                                                                                        return Promise.reject('You can’t keep this empty');
                                                                                    } else if (value.length < 9) {
                                                                                        return Promise.reject('Contains invalid mobile numbers');
                                                                                    } else if (value.length > 10) {
                                                                                        return Promise.reject('Contains invalid mobile numbers');
                                                                                    } else {
                                                                                        return Promise.resolve();
                                                                                    }
                                                                                },
                                                                            }),
                                                                            ]}
                                                                        >
                                                                            <Input placeholder="MSISDN"
                                                                                   disabled={fieldDisabled}
                                                                                   onChange={onChangeTestNumber} style={{fontSize: '14px'}}/>
                                                                        </Form.Item>
                                                                        {routePath !== "view-manage-campaign" ?
                                                                            <MinusCircleOutlined onClick={() => {
                                                                                remove(name);
                                                                                onChangeTestNumber()
                                                                            }}/> : ''}

                                                                        <br/><br/>
                                                                    </Space>

                                                                ))}
                                                                {fields.length < 5 && <Form.Item>
                                                                    <Button disabled={fieldDisabled} type="dashed"
                                                                            onClick={() => add()} block
                                                                            icon={<PlusOutlined/>}
                                                                            style={{width: "auto", fontSize: '14px'}}>
                                                                        Add test mobile number/s
                                                                    </Button>
                                                                </Form.Item>}
                                                                <p style={{margin: "10px"}}>
                                                                    <Form.ErrorList errors={errors} className="validation invalid-feedback"/>
                                                                </p>
                                                                {/*<p style={{margin: "10px", color: "Red"}}>*/}
                                                                {/*    {msisdnError}*/}
                                                                {/*</p>*/}
                                                                <div className="validation invalid-feedback">{msisdnError}</div>
                                                            </>
                                                        )}
                                                    </Form.List>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                    {routePath !== "manage-campaign-resubmit" && routePath !== "manage-campaign-rejected-resubmit" && routePath !== "view-manage-campaign" &&
                                        <div className="form-group__wrapper">
                                            <div className="form-group">
                                                <div className="form-element__wrapper mb-3">
                                                    <input className="form-check-input" type="checkbox"
                                                           checked={termsAndConditions}
                                                           id="terms-acceptence"
                                                           onChange={onConfirmationTerms}/>&nbsp;&nbsp;
                                                    <label className="form-check-label"
                                                           htmlFor="terms-acceptence">
                                                        I agree to <a onClick={onShowTnC}>Terms and conditions</a>
                                                    </label>
                                                </div>
                                                <div className="validation invalid-feedback">{tncError}</div>
                                            </div>
                                        </div>
                                    }
                                    {routePath !== "view-manage-campaign" ?
                                        <div className="panel-bottom-btn__wrapper">
                                            <button className="btn btn-secondary" onClick={() => {navigate(-1)}}>Cancel</button>
                                            {renderSubmitButton()}
                                        </div>
                                        : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal
                        title="Terms and Conditions"
                        open={showTnC}
                        onCancel={onHideTnC}
                        footer={null}
                        style={{minWidth: 800}}
                    >
                        <div style={{maxHeight: '350px', overflowY: "auto"}}>
                            <ManageCampaignTnC/>
                        </div>
                    </Modal>
                </React.Fragment>
                );
            }  else {
                return (
                    <div className="section__wrapper">
                        <div className="empty-state-new-action__wrapper">
                            <div className="new-action-content__wrapper">
                                <h4 className="sub">Oops, something went wrong!</h4>
                            </div>
                        </div>
                    </div>
                );
            }
        // );
    } else return (<React.Fragment></React.Fragment>);
}

export default ManageCampaignConfigCreate;