import React, { CSSProperties, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import { fetchTracklist, fetchSingleApp, fetchKycList, fetchKycUsernames, fetchKycConfigs, fetchFilterKycList, fetchKycListForCsv, submitKycConfigs, submitKYCAppSubscription, fetchRedisStatus } from '../../../store/actions';
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from '@emotion/react';
import AuthHandler from '../../../helper/AuthHandler';
import TableIcon from '../../../assets/images/icons/app-media-placeholder.svg';
import { PageHeaderStatic } from "../components/PageHeaderStatic";
import { Button, DatePicker, Input, Select, Spin, Tooltip, Upload, message } from "antd";
import moment from "moment";
import dayjs, { Dayjs } from "dayjs";
import SimpleReactValidator from "simple-react-validator";
import { KycFilterModel } from "./models/request/kyc-filter.model";
import { getKycConfigs, getKycListForCsv } from "../../../services/kyc.service";
import { CopyOutlined } from "@ant-design/icons";
import * as Constants from '../../../constants';

const { RangePicker } = DatePicker;
const { Option } = Select;

const KycAPIConfigs = ({ auth }: any) => {
    const { wid } = useParams();
    const [workflowLoading, setWorkflowLoading] = useState(true);
    const [kycConfigDetails, setKycConfigDetails] = useState<any>(null);

    const [validator] = useState(new SimpleReactValidator({
        autoForceUpdate: this
    }));

    const navigate = useNavigate();

    const {
        singleAppDetails
    } = useSelector(({ apps }: any) => apps);
    const { success, loading, error, kycList, userNames, csvData, kycConfigs } = useSelector(({ kyc }) => kyc);
    const { userDetails, redisStatus } = useSelector(({ user }: any) => user);

    const dispatch = useAppDispatch();

    useEffect(() => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        dispatch(fetchSingleApp(wid!, tenantGUID));
    }, [wid, userDetails]);

    useEffect(() => {
        if (singleAppDetails && singleAppDetails.widgetDetails) {
            getClientDetails();
        }
    }, [singleAppDetails]);

    const getClientDetails = async () => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        await getKycConfigs(tenantGUID, singleAppDetails.widgetDetails.uuid).then(response => {
            setKycConfigDetails(response.data.data);
        }).catch(error => {
        });
        setWorkflowLoading(false);
    }

    if (AuthHandler.isAuthorized(auth, 'tracklist') && userDetails !== null) {
        return (
            <React.Fragment>
                <Spin size="large" spinning={workflowLoading}>
                    <div className="main-content main-spacer-x-y">
                        <PageHeaderStatic
                            page={'logs'}
                            backLink={`/dynamic-api/${wid}/list`}
                        />
                        <div className="section__wrapper">
                            <div className="card panel content-holder-panels">
                                <div className="panel-header">
                                    <div className="panel-header-content__wrapper">
                                        <h4 className="panel-header-title">{(singleAppDetails && singleAppDetails.widgetDetails) ? singleAppDetails.widgetDetails.title : ''}</h4>
                                        <p className="panel-header-subtitle">API Configurations </p>
                                    </div>
                                </div>
                                <div className="panel-content">
                                    {
                                        kycConfigDetails && kycConfigDetails?.apiDetails && 
                                        <div className="form-group__wrapper is-validated">
                                            <div className="form-group">
                                                <div className="form-element__wrapper validated mb-3">
                                                    <label htmlFor="" className="form-label">
                                                        API URL
                                                    </label>
                                                    <Input value={kycConfigDetails.apiDetails.apiUrl[0].apiUrl} readOnly />
                                                </div>
                                                <div className="form-element__wrapper form-element__wbtn validated mb-3">
                                                    <label htmlFor="" className="form-label">
                                                        Application Tier
                                                    </label>
                                                    <Input value={kycConfigDetails.apiDetails.appTier} readOnly />
                                                </div>
                                                <div className="form-element__wrapper form-element__wbtn validated mb-3">
                                                    <label htmlFor="" className="form-label">
                                                        Application Scope
                                                    </label>
                                                    <Input value={kycConfigDetails.apiDetails.appScope} readOnly />
                                                </div>
                                                <div className="form-element__wrapper form-element__wbtn right mb-3">
                                                    <label htmlFor="" className="form-label">
                                                        Documentation
                                                    </label>
                                                    <Upload
                                                        listType="text"
                                                        fileList={[{
                                                            uid: 'kyc_api_doc',
                                                            name: 'API Document',
                                                            url: Constants.KYC_API_WIDGETS_API_DOC
                                                        }]}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-element__wrapper mb-3">
                                                    <label htmlFor="" className="form-label">
                                                        Consumer key
                                                    </label>
                                                    <Input.Group compact>
                                                        <Input.Password style={{
                                                            width: 'calc(100% - 100px)',
                                                        }} value={kycConfigDetails.apiDetails.consumerKey} readOnly />
                                                        <Tooltip title="copy key">
                                                            <Button icon={<CopyOutlined />} onClick={() => {
                                                                navigator.clipboard.writeText(kycConfigDetails.apiDetails.consumerKey);
                                                                message.success({ content: 'Copied to clipboard' });
                                                            }} />
                                                        </Tooltip>
                                                    </Input.Group>
                                                </div>
                                                <div className="form-element__wrapper mb-3">
                                                    <label htmlFor="" className="form-label">
                                                        Consumer secret
                                                    </label>
                                                    <Input.Group compact>
                                                        <Input.Password style={{
                                                            width: 'calc(100% - 100px)',
                                                        }} value={kycConfigDetails.apiDetails.consumerSecret} readOnly />
                                                        <Tooltip title="copy secret">
                                                            <Button icon={<CopyOutlined />} onClick={() => {
                                                                navigator.clipboard.writeText(kycConfigDetails.apiDetails.consumerSecret);
                                                                message.success({ content: 'Copied to clipboard' });
                                                            }} />
                                                        </Tooltip>
                                                    </Input.Group>
                                                </div>
                                                <div className="form-bottom__wrapper">
                                                    <div className="form-terms-mention__wrapper">
                                                        <p>
                                                            You can generate an access token for this API using above consumer key and secret with the following cURL command.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="code-snippet">
                                                    <Tooltip className="pull-right" title="copy cURL">
                                                        <Button icon={<CopyOutlined />} onClick={() => {
                                                            navigator.clipboard.writeText('curl -k -d "grant_type=client_credentials" -H "Authorization: Basic Base64(consumer-key:consumer-secret)"  https://ideabiz.lk/apicall/token ');
                                                            message.success({ content: 'Copied to clipboard' });
                                                        }} />
                                                    </Tooltip>
                                                    <br /><br />
                                                    <pre>
                                                        <code>curl -k -d "grant_type=client_credentials" \<br />
                                                            -H "Authorization: Basic Base64(consumer-key:consumer-secret)"\<br />
                                                            https://ideabiz.lk/apicall/token
                                                        </code></pre>
                                                </div>
                                            </div>
                                            {/* <div class="sign-in-advice-inner"><span>OR</span></div> */}
                                        </div>}
                                </div>
                                {/* panel table */}
                                {/* <div class="panel-footer">
                  footer
              </div> */}
                            </div>

                        </div>
                    </div>
                </Spin>
            </React.Fragment>
        );
    } else return (<React.Fragment></React.Fragment>);
}

export default KycAPIConfigs;