/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { CSSProperties, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import { fetchConfigData, fetchLicenseCount, deleteDomainById } from '../../../store/actions';
import ScaleLoader from "react-spinners/ScaleLoader";
import AuthHandler from '../../../helper/AuthHandler';
import { PageHeaderStatic } from "../components/PageHeaderStatic";
import { confirmAlert } from "react-confirm-alert";
import * as Constants from '../../../constants';
import { Button, Card, Col, Input, Row, notification } from "antd";
import { RedoOutlined } from "@ant-design/icons";

const override: CSSProperties = {
    display: "block",
    margin: "0 auto"
};

const GwsDomainConfigView = ({ auth }: any) => {

    const domainUuidParam = window.location.pathname.split('/')[Constants.BASEURLPATHLENGTH + 4];

    const { wid } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { userDetails } = useSelector(({ user }: any) => user);
    const { singleAppDetails } = useSelector(({ apps }: any) => apps);
    const { success, loading, error, configData, gwsLicenseCountRes, domainDeleteRes } = useSelector(({ gws }: any) => gws);

    const [domainUuid, setDomainUuid] = useState(domainUuidParam !== Constants.GWS_INIT ? domainUuidParam : '');
    const [isGetStatusOnGoing, setIsGetStatusOnGoing] = useState(false);
    const [isGetLicenseCountOnGoing, setIsGetLicenseCountOnGoing] = useState(false);
    const [isDeleteDomainOnGoing, setIsDeleteDomainOnGoing] = useState(false);
    const [licenseCount, setLicenseCount] = useState(0);

    useEffect(() => {
        getStatus();
    }, []);

    useEffect(() => {
        if (isGetStatusOnGoing && success) {
            setIsGetStatusOnGoing(false);
        } else if (isGetStatusOnGoing && error) {
            setIsGetStatusOnGoing(false);
            notification.error({
                message: 'Error in retrieving config data.',
                placement: 'topRight',
            });
        }
    }, [configData]);

    useEffect(() => {
        setIsGetLicenseCountOnGoing(false);
        if (gwsLicenseCountRes && !error) {
            setLicenseCount(gwsLicenseCountRes.count)
        } else {
            notification.error({
                message: "Error in getting license count.",
                placement: 'topRight',
            });
        }
    }, [gwsLicenseCountRes]);

    useEffect(() => {
        setIsDeleteDomainOnGoing(false);
        if (domainDeleteRes && !error) {
            navigate(-1);
        } else {
            notification.error({
                message: "Error in deleting domain.",
                placement: 'topRight',
            });
        }
    }, [domainDeleteRes]);

    const getStatus = async () => {
        setIsGetStatusOnGoing(true);
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        const domainUuid = window.location.pathname.split('/')[Constants.BASEURLPATHLENGTH + 4];
        if (domainUuid !== Constants.GWS_INIT) {
            dispatch(fetchConfigData(tenantGUID, domainUuid));
        }
    }

    const getLicenseCount = async () => {
        setIsGetLicenseCountOnGoing(true);
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        const domainUuid = window.location.pathname.split('/')[Constants.BASEURLPATHLENGTH + 4];
        if (domainUuid !== Constants.GWS_INIT) {
            dispatch(fetchLicenseCount(tenantGUID, domainUuid));
        }
    }

    const handleDeleteDomain = () => {
        confirmAlert({
            message: "Are you sure you want to delete the domain?",
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteDomain()
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    const deleteDomain = async () => {
        setIsDeleteDomainOnGoing(true);
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        const domainUuid = window.location.pathname.split('/')[Constants.BASEURLPATHLENGTH + 4];
        if (domainUuid !== Constants.GWS_INIT) {
            dispatch(deleteDomainById(tenantGUID, wid!, domainUuid));
        }
    }

    if (AuthHandler.isAuthorized(auth, 'gws-config-view') && userDetails !== null) {
        return (
            <React.Fragment>
                <div className="main-content main-spacer-x-y">
                    <PageHeaderStatic
                        page={'create'}
                    // backLink={`/${paths[Constants.BASEURLPATHLENGTH + 1]}/${paths[Constants.BASEURLPATHLENGTH + 2]}/google-workspace`}
                    />
                    <div className="card added-app-thumbnnail__wrapper">
                        <div
                            className="added-app-thumbnail__inner"
                            style={{
                                backgroundImage: `url(${(singleAppDetails && singleAppDetails.widgetDetails !== null) ? singleAppDetails.widgetDetails.leftImage : ""})`
                            }}
                        ></div>
                    </div>
                    <div className="card panel content-holder-panels added-app-form__wrapper">
                        <div className="panel-header">
                            <div className="panel-header-content__wrapper">
                                <h4 className="panel-header-title">Google Workspace</h4>
                            </div>
                        </div>
                        <div className="panel-content">
                            <div className="wiz-content-form__wrapper">
                                {loading ?
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', height: 200 }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                            <ScaleLoader
                                                cssOverride={override}
                                                // size={20}
                                                color={"#EB8C20"}
                                                loading={true}
                                            />
                                        </div>
                                    </div>
                                    : (configData) ?
                                        <div className="card-body gws__body">
                                            <h4>Site Verification</h4>
                                            <Card
                                                bordered={false}
                                            >
                                                <Row>
                                                    <Col span={4}>
                                                        <label>Domain</label>
                                                    </Col>
                                                    <Col span={1}>
                                                        <label>:</label>
                                                    </Col>
                                                    <Col span={13}>
                                                        <p>{configData.domain}</p>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={4}>
                                                        <label>Status</label>
                                                    </Col>
                                                    <Col span={1}>
                                                        <label>:</label>
                                                    </Col>
                                                    <Col span={13}>
                                                        <p>{configData.status}</p>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={4}>
                                                        <label>Created at</label>
                                                    </Col>
                                                    <Col span={1}>
                                                        <label>:</label>
                                                    </Col>
                                                    <Col span={13}>
                                                        <p>{configData.createdAt}</p>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={4}>
                                                        <label>Last status update at</label>
                                                    </Col>
                                                    <Col span={1}>
                                                        <label>:</label>
                                                    </Col>
                                                    <Col span={13}>
                                                        <p>{configData.updatedAt}</p>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={4}>
                                                        <label>License Count</label>
                                                    </Col>
                                                    <Col span={1}>
                                                        <label>:</label>
                                                    </Col>
                                                    <Col span={13}>
                                                        <Row>
                                                            <Input value={licenseCount} placeholder="---" style={{ width: 100, marginRight: 10 }} />
                                                            <Button type="primary" onClick={getLicenseCount}><RedoOutlined /></Button>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Button type="primary" danger onClick={handleDeleteDomain} style={{ marginTop: 20 }}>Delete Domain</Button>
                                            </Card>
                                        </div>
                                        : <></>}
                            </div>
                        </div>
                    </div>
                    {/* <main className="pt-5 mx-lg-5">
                        <div className="container-fluid mt-5">
                            <div className="row wow fadeIn">
                                <div className="col-md-12 mb-4">
                                    <div className="card mb-4 profile__card">
                                        <div className="card-header text-center gws__header">
                                            <h4>GOOGLE WORKSPACE</h4>
                                        </div>
                                        {loading ?
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', height: 200 }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                    <ScaleLoader
                                                        cssOverride={override}
                                                        // size={20}
                                                        color={"#EB8C20"}
                                                        loading={true}
                                                    />
                                                </div>
                                            </div>
                                            : (configData) ?
                                                <div className="card-body gws__body">
                                                    <h4>Site Verification</h4>
                                                    <Card
                                                        bordered={false}
                                                    >
                                                        <Row>
                                                            <Col span={4}>
                                                                <label>Domain</label>
                                                            </Col>
                                                            <Col span={1}>
                                                                <label>:</label>
                                                            </Col>
                                                            <Col span={13}>
                                                                <p>{configData.domain}</p>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col span={4}>
                                                                <label>Status</label>
                                                            </Col>
                                                            <Col span={1}>
                                                                <label>:</label>
                                                            </Col>
                                                            <Col span={13}>
                                                                <p>{configData.status}</p>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col span={4}>
                                                                <label>Created at</label>
                                                            </Col>
                                                            <Col span={1}>
                                                                <label>:</label>
                                                            </Col>
                                                            <Col span={13}>
                                                                <p>{configData.createdAt}</p>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col span={4}>
                                                                <label>Last status update at</label>
                                                            </Col>
                                                            <Col span={1}>
                                                                <label>:</label>
                                                            </Col>
                                                            <Col span={13}>
                                                                <p>{configData.updatedAt}</p>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col span={4}>
                                                                <label>License Count</label>
                                                            </Col>
                                                            <Col span={1}>
                                                                <label>:</label>
                                                            </Col>
                                                            <Col span={13}>
                                                                <Row>
                                                                    <Input value={licenseCount} placeholder="---" style={{ width: 100, marginRight: 10 }} />
                                                                    <Button type="primary" onClick={getLicenseCount}><RedoOutlined /></Button>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Button type="primary" danger onClick={handleDeleteDomain} style={{ marginTop: 20 }}>Delete Domain</Button>
                                                    </Card>
                                                </div>
                                                : <></>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main> */}
                </div>
            </React.Fragment>
        );
    } else return (<React.Fragment></React.Fragment>);
}

export default GwsDomainConfigView;