import React from 'react';

type Props = {
    label: string,
    name: string,
    id: string,
    value: string | number,
    data: string,
    selectedValue: string | number,
    iconClass: string,
    icon: any,
    canChange: boolean,
    setSelctedItem: (name: string) => (event: any) => void,
    disabled?: boolean,
    onChangeOption?: (e: any) => void,
    onAddOption?: (e: any) => void,
    paymentDisableNotice? : boolean
}

const RadioButton = ({ label, name, id, value, data, selectedValue, iconClass, icon, canChange, setSelctedItem, disabled, onChangeOption,onAddOption, paymentDisableNotice }: Props) => {
    const handleChange = (e: any) => {
        setSelctedItem(e.target.value)
    }

    return (
        <div className="form-group">
            <div className="form-element__wrapper mb-3" style={{
                        cursor: paymentDisableNotice ? "not-allowed" : "pointer", opacity: paymentDisableNotice ? 0.8 : 1,
                    }}>
                <label htmlFor="" className="form-label">{label}</label>
                {/* ~ active ~ class*/}
                <div className={`form-check radio-outline-multi-selector__wrapper ${id === selectedValue ? "active" : ""}`}>
                    <div className=" radio-outline-multi-selector-inner">
                        <div className="radio-outline-multi-selector-header">
                            <input className="form-check-input secondary" type="radio" disabled={disabled} value={value} name={name} id={id} checked={id === selectedValue} onChange={e => {
                                setSelctedItem(name)(e)
                            }} />
                            <label className="form-check-label" htmlFor="radio1">{id === selectedValue ? "Selected" : "Not selected"}</label>
                        </div>
                        <div className="radio-outline-multi-selector-content">
                            <div className="radio-outline-multi-selector-content__wrapper">
                                <span>
                                    {iconClass !== "" ? <span className="icon-img__wrapper"><i className={`bi ${iconClass}`} /></span>
                                        : icon ? <span className="icon-img__wrapper"><img src={icon} alt="" srcSet="" /></span>
                                            : <></>}
                                    <span>{data}</span>
                                </span>
                            </div>
                            {canChange ? <div className="radio-outline-multi-selector-content__wrapper">
                                {disabled ? <a style={{cursor: "not-allowed"}} onClick={()=>{}}>Change</a> : <a onClick={onChangeOption}>Change</a>}
                            </div> : <div className="radio-outline-multi-selector-content__wrapper">
                                {disabled ? <a style={{cursor: "not-allowed"}} onClick={()=>{}}>Add</a> : <a onClick={onAddOption}>Add</a>}
                            </div>}
                        </div>
                    </div>
                    { paymentDisableNotice &&
                        <span>This payment method will be available soon.</span>
                    }
                </div>
            </div>
        </div>
    )
}

export { RadioButton }