import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface SliceModel {
    error: boolean,
    loading: boolean,
    success: boolean,
    workHubSubscriptionData: any,
    workhubConfigData: any,
    workhubUpdateResp: any
}

const workhubStore = createSlice({
    name: "workhub-store",
    initialState: {
        error: false,
        loading: false,
        success: false,
        workHubSubscriptionData: null,
        workhubConfigData: null,
        workhubUpdateResp: null
    } as SliceModel,
    reducers: {
        workhubSubscriptionDataFetch: (state: SliceModel) => {
            return {
                ...state,
                error: false,
                loading: true,
                success: false
            }
        },
        workhubSubscriptionDataFetchSuccess: (state: SliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                error: false,
                loading: false,
                success: true,
                workHubSubscriptionData: action.payload
            }
        },
        workhubSubscriptionDataFetchFail: (state: SliceModel) => {
            return {
                ...state,
                error: true,
                loading: false,
                success: false
            }
        },
        workhubSubscriptionDataSave: (state: SliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                workhubConfigData: action.payload
            }
        },
        workhubSubscriptionDataUpdate: (state: SliceModel) => {
            return {
                ...state,
                error: false,
                loading: true,
                success: false
            }
        },
        workhubSubscriptionDataUpdateSuccess: (state: SliceModel, action: PayloadAction<any>) => {
            return {
                ...state,
                error: false,
                loading: false,
                success: true,
                workhubUpdateResp: action.payload
            }
        },
        workhubSubscriptionDataUpdateFail: (state: SliceModel) => {
            return {
                ...state,
                error: true,
                loading: false,
                success: false
            }
        }
    },
});

export default workhubStore;