import React from 'react';
import { Routes, BrowserRouter as Router, Route } from "react-router-dom";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content__wrapper">
                <span className="copy__wrapper"><img src={require('../assets/images/Dialog_logo-grey.svg').default} alt="" /><span className="copy-text">© Copyright protected. Engineered by ideamart</span></span>
            </div>
            <div className="footer-content__wrapper">
                <ul className="footer-links">
                    <li><a href="https://d2xt8w8fh2bnjt.cloudfront.net/qa/userguides/Terms.pdf" target="_blank" rel="noopener noreferrer">Terms</a></li>
                    <li><a href="https://d2xt8w8fh2bnjt.cloudfront.net/qa/userguides/Privacy+Policy.pdf" target="_blank" rel="noopener noreferrer">Privacy</a></li>
                    {/* <li><a href="http://" target="_blank" rel="noopener noreferrer">License</a></li> */}
                </ul>
            </div>
        </footer>
    );
}

export { Footer };