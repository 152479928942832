/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { CSSProperties, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import {
    createOrderRecord,
    fetchCoverage,
    fetchDeviceDetails,
    fetchPackageDetails,
    fetchSingleApp
} from '../../../store/actions';
import {
    getLocationData
} from "../../../services/fixed-bb-service";
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from '@emotion/react';
import AuthHandler from '../../../helper/AuthHandler';
import { PageHeaderStatic } from "../components/PageHeaderStatic";
import {Button, Card, message, Steps} from "antd";
import PackageSelection from "./components/PackageSelection";
import AddressForm from "./components/AddressForm";
import LocationMapForm from "./components/LocationMapForm";
import BillingAddressForm from "./components/BillingAddressForm";
import {AddressModel} from "./models/address.model";
import {CoverageModel} from "./models/coverage.model";
import CartView from "./components/cartView";
import {CartRecordModel} from "./models/cartRecord.model";
import ContactInfoForm from "./components/ContactInfoForm";
import {ContactModel} from "./models/contact.model";
import {PackageDataRespModel} from "./models/packageDataResp.model";
import DeviceSelection from "./components/DeviceSelection";
import {DeviceDataRespModel} from "./models/deviceDataResp.model";
import * as Constants from "../../../constants";
import {confirmAlert} from "react-confirm-alert";
import {PriceOptionModel} from "./models/priceOption.model";

const override: CSSProperties = {
    display: "block",
    margin: "0 auto"
};

const FixedBBConfigCreate = ({ auth }: any) => {

    const { wid, configId } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { userDetails } = useSelector(({ user }: any) => user);
    const { singleAppDetails } = useSelector(({ apps }: any) => apps);
    const { success, loading, error, coverageRes, packageData, traceId, deviceData, createOrderResp } = useSelector(({ fixedBB }: any) => fixedBB);

    const [currentStep, setCurrentStep] = useState(0);
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [billAddress1, setBillAddress1] = useState("");
    const [billAddress2, setBillAddress2] = useState("");
    const [billCity, setBillCity] = useState("");
    const [position, setPosition] = useState({lat: 6.927079, lng: 79.861244});
    const [coverageStatus, setCoverageStatus] = useState("");
    const [hasCoverage, setHasCoverage] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState<PackageDataRespModel|null>(null);
    const [selectedDevice, setSelectedDevice] = useState<DeviceDataRespModel|null>(null);
    const [selectedPricePlan, setSelectedPricePlan] = useState<PriceOptionModel|null>(null);
    const [cartRecord, setCartRecord] = useState<CartRecordModel|null>(null);
    const [contactName, setContactName] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [contactEmail, setContactEmail] = useState("");
    const [isOrderCreationOnGoing, setIsOrderCreationOnGoing] = useState(false);

    useEffect(() => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        dispatch(fetchSingleApp(wid!, tenantGUID));
        if(!packageData || packageData.length === 0) {
            dispatch(fetchPackageDetails());
        }
        if(userDetails) {
            setContactName((userDetails.firstName && userDetails.lastName) ? `${userDetails.firstName} ${userDetails.lastName}` : "");
            setContactNumber(userDetails.contact ? userDetails.contact : "");
            setContactEmail(userDetails.email ? userDetails.email : "");
        }
    }, [wid, userDetails]);

    useEffect(() => {
        if(traceId){
            if (!deviceData || deviceData.length === 0) {
                dispatch(fetchDeviceDetails(traceId));
            }
        }
    }, [traceId]);

    useEffect(() => {
        setCoverageStatus("")
        setHasCoverage(false)
    }, []);

    useEffect(() => {
        if (coverageRes && success) {
            setCoverageStatus(coverageRes.message)
            setHasCoverage(coverageRes.hasCoverage)
        }
    }, [coverageRes]);

    useEffect(() => {
        if (createOrderResp && success && isOrderCreationOnGoing) {
            if(createOrderResp.status === "Success") {
                const paths = window.location.pathname.split('/');
                navigate(`/${paths[Constants.BASEURLPATHLENGTH + 1]}/${paths[Constants.BASEURLPATHLENGTH + 2]}/view-config/${createOrderResp.orderRefId}`);
                setIsOrderCreationOnGoing(false);
            } else {
                message.error("Order creation failed.");
            }
        }
    }, [createOrderResp]);

    const onPackageSelect = async (values: any) => {
        const selectedPack = packageData.find((item: PackageDataRespModel) => item.productCode === values.package)
        setSelectedPackage(selectedPack);
        setCurrentStep(2);
    }

    const onDeviceSelect = async (values: any) => {
        // console.log("device selection values: ", values)
        const selectedDevice = deviceData.find((item: DeviceDataRespModel) => item.productCode === values.device)
        setSelectedDevice(selectedDevice);
        const selectedPrice = selectedDevice.priceOptionList.find((priceObj: PriceOptionModel) => priceObj.offerCode === values.priceOption)
        setSelectedPricePlan(selectedPrice);
        setCurrentStep(3);
    }

    const checkCoverage = async (values: CoverageModel) => {
        if(values && values.position) {
            setPosition(values.position);
            await dispatch(fetchCoverage(values.position.lat, values.position.lng));
            const locationData = await getLocationData(values.position.lat, values.position.lng);
            if(locationData && locationData.data && locationData.data.address && locationData.data.address) {
                setCity(locationData.data.address.city ? locationData.data.address.city : locationData.data.address.town ? locationData.data.address.town : "");
            }
        }
    }

    const setLocationMap = () => {
        if(hasCoverage) {
            setCurrentStep(1);
        }
    }

    const setLocation = (values: AddressModel) => {
        const address = values.address1.replace(/,$/, '') + ", " + values.address2.replace(/,$/, '') + ", " + values.city;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <Card title="Confirm Address" bordered={true} style={{ width: 300, boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
                        <p>{address}</p>
                        <p>We will be delivering the router to this address</p>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <Button type="primary" className={"btn btn-secondary btn-sm"} onClick={() => {
                                onClose();
                            }}>
                                No
                            </Button>
                            <Button type="primary" className={"btn btn-primary btn-sm"} onClick={() => {setAddress1(values.address1.replace(/,$/, ''));
                                setAddress2(values.address2.replace(/,$/, ''));
                                setCity(values.city);
                                setBillAddress1(values.address1.replace(/,$/, ''));
                                setBillAddress2(values.address2.replace(/,$/, ''));
                                setBillCity(values.city);
                                setCurrentStep(4);
                                onClose();
                            }}>
                                Confirm
                            </Button>
                        </div>
                    </Card>
                );
            }
        });
    }

    const setBillAddress = (values: AddressModel) => {
        const address = values.address1.replace(/,$/, '') + ", " + values.address2.replace(/,$/, '') + ", " + values.city;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <Card title="Confirm Billing Address" bordered={true} style={{ width: 300, boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
                        <p>{address}</p>
                        <p>This will be the billing address for this order</p>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <Button type="primary" className={"btn btn-secondary btn-sm"} onClick={() => {
                                onClose();
                            }}>
                                No
                            </Button>
                            <Button type="primary" className={"btn btn-primary btn-sm"} onClick={() => {setAddress1(values.address1.replace(/,$/, ''));
                                setBillAddress1(values.address1.replace(/,$/, ''));
                                setBillAddress2(values.address2.replace(/,$/, ''));
                                setBillCity(values.city);
                                setCurrentStep(5);
                                onClose();
                            }}>
                                Confirm
                            </Button>
                        </div>
                    </Card>
                );
            }
        });
    }

    const onSetContactInfo = (values: ContactModel) => {
        setContactName(values.name);
        setContactNumber(values.contact);
        setContactEmail(values.email);
        onSaveCartReord(values);
        setCurrentStep(6);
    }

    const onChangeQty = (value: number | null) => {
        if(cartRecord && value && value > 0) {
            setCartRecord({
                ...cartRecord,
                qty: value
            });
        }
    }

    const onSaveCartReord = (values: ContactModel) => {
        // console.log("selectedPricePlan:", selectedPricePlan)
        if(selectedPackage && selectedDevice && selectedPricePlan) {
            const rec = {
                position: position,
                hasCoverage: hasCoverage,
                installationAddress: {
                    address1: address1,
                    address2: address2,
                    city: city
                },
                billingAddress: {
                    billAddress1: billAddress1,
                    billAddress2: billAddress2,
                    billCity: billCity
                },
                packageTitle: selectedPackage.title,
                packageCode: selectedPackage.productCode,
                packageAmount: selectedPackage.priceWithoutTax,
                deviceTitle: selectedDevice.productDescription,
                deviceCode: selectedDevice.productCode,
                deviceAmount: selectedPricePlan.price,//selectedDevice.offerPrice,
                contactInfo: {
                    name: values.name,
                    contact: values.contact,
                    email: values.email
                },
                qty: 1
            };
            setCartRecord(rec);
        }
    }

    const onCreateOrder = () => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        console.log("create order...");
        console.log("cart rec: ", cartRecord);
        if (cartRecord && tenantGUID && wid && traceId && selectedPricePlan) {
            setIsOrderCreationOnGoing(true);
            const orderReq = {
                cartAdditionalFields: {
                    lat: cartRecord.position.lat,
                    lng: cartRecord.position.lng,
                    billingAddress1: cartRecord.billingAddress.billAddress1,
                    billingAddress2: cartRecord.billingAddress.billAddress2,
                    billingCity: cartRecord.billingAddress.billCity,
                    name: cartRecord.contactInfo.name,
                    contact: cartRecord.contactInfo.contact,
                    email: cartRecord.contactInfo.email,
                },
                cartItems: [{
                    description: cartRecord.deviceTitle,
                    productCode: cartRecord.deviceCode,
                    rate: cartRecord.deviceAmount,
                    qty: cartRecord.qty,
                    cartItemAdditionalFields: {
                        hasCoverage: cartRecord.hasCoverage,
                        installationAddress1: cartRecord.installationAddress.address1,
                        installationAddress2: cartRecord.installationAddress.address2,
                        installationCity: cartRecord.installationAddress.city,
                        packageTitle: cartRecord.packageTitle,
                        packageCode: cartRecord.packageCode,
                        packageAmount: cartRecord.packageAmount,
                        deviceTitle: cartRecord.deviceTitle,
                        deviceCode: cartRecord.deviceCode,
                        deviceAmount: cartRecord.deviceAmount,
                        offerCode: selectedPricePlan.offerCode,
                    }
                }]
            }
            dispatch(createOrderRecord(orderReq, tenantGUID, wid, traceId));
        }
    }

    if (AuthHandler.isAuthorized(auth, 'fixed-bb-create-config') && userDetails !== null) {
        return (
            <React.Fragment>
                <div className="main-content main-spacer-x-y">
                    <PageHeaderStatic
                        page={'create'}
                        backLink={`/fixed-bb/${wid}/list`}
                    />
                    <div className="card added-app-thumbnnail__wrapper">
                        <div
                            className="added-app-thumbnail__inner"
                            style={{
                                backgroundImage: `url(${(singleAppDetails && singleAppDetails.widgetDetails !== null) ? singleAppDetails.widgetDetails.leftImage : ""})`
                            }}
                        ></div>
                    </div>
                    <div className="card panel content-holder-panels added-app-form__wrapper">
                        <div className="panel-header">
                            <div className="panel-header-content__wrapper" style={{width: '100%'}}>
                                <div style={{display: "flex", justifyContent: "space-between"}}>
                                    <h4 className="panel-header-title">Place Order</h4>
                                    {currentStep !== 0 &&
                                        <button className="btn back-btn" onClick={() => {
                                            setCurrentStep(currentStep - 1)
                                        }}>
                                            <i className="bi bi-arrow-left"/>
                                            &nbsp;Go back
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="panel-content">
                            <div className="wiz-content-form__wrapper">
                                <Steps
                                    current={currentStep}
                                    style={{ padding: '10px 50px 20px' }}
                                    size="small"
                                >
                                    <Steps.Step title="" style={{ color: 'red' }} />
                                    <Steps.Step title="" />
                                    <Steps.Step title="" />
                                    <Steps.Step title="" />
                                    <Steps.Step title="" />
                                    <Steps.Step title="" />
                                    <Steps.Step title="" />
                                </Steps>
                                {loading ?
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', height: 200 }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                            <ScaleLoader
                                                cssOverride={override}
                                                // size={20}
                                                color={"#EB8C20"}
                                                loading={true}
                                            />
                                        </div>
                                    </div>
                                    : currentStep === 0 ? <LocationMapForm checkCoverage={checkCoverage} coverageStatus={coverageStatus} hasCoverage={hasCoverage} setLocationMap={setLocationMap} />
                                        : currentStep === 1 ? <PackageSelection onPackageSelect={onPackageSelect} packageData={packageData} />
                                            : currentStep === 2 ? <DeviceSelection onDeviceSelect={onDeviceSelect} deviceData={deviceData} /> //device selection
                                                : currentStep === 3 ? <AddressForm setLocation={setLocation} city={city} />
                                                    : currentStep === 4 ? <BillingAddressForm setBillAddress={setBillAddress} billingData={{billAddress1, billAddress2, billCity}}/>
                                                        : currentStep === 5 ? <ContactInfoForm onSetContactInfo={onSetContactInfo} contactData={{contactName, contactNumber, contactEmail}}/>
                                                            : currentStep === 6 ? <CartView onCreateOrder={onCreateOrder} cartRecord={cartRecord} onChangeQty={onChangeQty} /> //cart
                                                        : <></>}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    } else return (<React.Fragment></React.Fragment>);
}

export default FixedBBConfigCreate;