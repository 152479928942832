import React, { useEffect, useState } from 'react';
import { Routes, BrowserRouter as Router, Route, useNavigate } from "react-router-dom";
import Home from '../pages/home/Home';
import RestrictedRoutes from './RestrictedRoutes';
import Keycloak from 'keycloak-js';
import Auth from '../models/response/auth.model';
import Agreement from '../pages/agreement/Agreement';
import FilterApps from "../pages/filter-apps/FilterApps";
import AppWizard from "../pages/discover/AppWizard";
import SingleApp from '../pages/single-app/SingleApp';
import AppCategory from '../pages/app-categories/AppCategories';
import BusinessDetails from '../pages/settings/BusinessDetails';
import DiscoverMoreApps from '../pages/discover/DiscoverMoreApps';
import AddedApps from '../pages/settings/AddedApps';
import BillingAndPlans from '../pages/settings/BillingAndPlans';
import Payment from '../pages/settings/Payment';
import DocumentAndTerms from '../pages/settings/DocumentAndTerms';
import BusinessBrands from '../pages/settings/BusinessBrand';
import Businesses from '../pages/settings/Businesses';
import NumberConfigures from '../pages/settings/NumberConfigures';
import Account from '../pages/settings/Account';
import ProfileAndUserPermission from '../pages/settings/ProfileAndUserPermission';
import DynamicWidgetList from '../pages/apps/DynamicWidget/DynamicWidgetList';
import { fetchProfileStatus } from '../store/actions';
import { useAppDispatch } from '../store';
import DynamicWidget from '../pages/apps/DynamicWidget/DynamicWidget';
import LocationTrackCreate from '../pages/apps/where-are-my-employees-with-concent/LocationTrackCreate';
import LocationTracking from '../pages/apps/where-are-my-employees-with-concent/LocationTracking';
import DynamicLeadWidgetDetails from '../pages/apps/DynamicWidget/DynamicLeadWidgetDetails';
import DynamicWidgeWorkflow from '../pages/apps/DynamicWidget/DynamicWidgeWorkflow';
import LocationTrackDetails from '../pages/apps/where-are-my-employees-with-concent/LocationTrackDetails';
import LocationLogs from '../pages/apps/where-are-my-employees-with-concent/LocationLogs';
import ManagedCampaignConfigList from '../pages/apps/ManagedCampaign/ManagedCampaignConfigList';
import ManagedCampaignStatusWorkflow from '../pages/apps/ManagedCampaign/ManagedCampaignStatusWorkflow';
import UserPasswordReset from '../pages/password-reset/self-password-reset';
import MapLocation from '../pages/apps/where-are-my-employees-with-concent/MapLocation';
import PaymentCheckout from '../pages/settings/PaymentCheckout';
import SubscribedApps from '../pages/subscribed-apps/SubscribedApps';
import { useSelector } from 'react-redux';
import KycPortal from '../pages/apps/kyc/KycPortal';
import BusinessTypeApps from '../pages/discover/BusinessTypeApps';
import SignupForm from '../pages/apps/esms/SignupForm';
import KycAPI from '../pages/apps/kyc/KycAPI';
import KycAPIConfigs from '../pages/apps/kyc/KycAPIConfigs';
import ManageCampaignConfigCreate from "../pages/apps/ManagedCampaign/ManageCampaignConfigCreate";
import SessionTimeoutMessage from '../components/SessionTimeoutMessage';
import * as CONSTANTS from "../constants"
import MaAgreement from '../pages/agreement/MaAgreement';
import OwnerDashboard from '../pages/dashboard/OwnerDashboard';
import PartnerDashboard from '../pages/dashboard/PartnerDashboard';
import SocialMediaConfigList from '../pages/apps/socialMedia/SocialMediaConfigList';
import SocialMediaConfigCreate from '../pages/apps/socialMedia/ScoialMediaConfigCreate';
import DynamicWidgetV2 from "../pages/apps/DynamicWidget/DynamicWidgetV2";
import DynamicWidgetBillingHistory from "../pages/apps/DynamicWidget/DynamicWidgetBillingHistory";
import DynamicWidgetConfigDetails from "../pages/apps/DynamicWidget/DynamicWidgetConfigDetails";
import GwsConfigList from '../pages/apps/gws/GwsConfigList';
import GwsDomainCreate from '../pages/apps/gws/GwsDomainCreate';
import GwsDomainConfigView from '../pages/apps/gws/GwsDomainConfigView';
import SentimentList from '../pages/apps/sentiment/sentiment-list.screen';
import SentimentCreate from '../pages/apps/sentiment/sentiment-create.screen';
import SentimentHistory from '../pages/apps/sentiment/sentiment-history.screen';
import CustomerSentiment from '../pages/apps/sentiment/customer-sentiment.screen';
import { getTokenRefreshed } from '../helper/refresh-token-handler';
import { Offers } from '../pages/offers/Offers';
import SentimentWaiting from '../pages/apps/sentiment/sentiment-waiting.screen';
import BizEmailConfigList from '../pages/apps/bizEmail/BizEmailConfigList';
import BizEmailConfigCreate from '../pages/apps/bizEmail/BizEmailConfigCreate';
import QuickSurveyList from '../pages/apps/quicksurvey/quick-survey-list.screen';
import QuickSurveyCreate from '../pages/apps/quicksurvey/add-quick-survey.screen';
import QuickSurveyHistory from '../pages/apps/quicksurvey/quick-survey-history.screen';
import FixedBBConfigList from "../pages/apps/fixedBB/FixedBBConfigList";
import FixedBBConfigCreate from "../pages/apps/fixedBB/FixedBBConfigCreate";
import FixedBBConfigView from "../pages/apps/fixedBB/FixedBBConfigView";
import FixedBBConfigStatusView from "../pages/apps/fixedBB/FixedBBConfigStatusView";

type AppRoutesProps = {
  isLogin: boolean;
  keycloak: Keycloak | undefined;
  auth: Auth;
  setAuth: (value: React.SetStateAction<Auth>) => void;
}

const RouteHandler = ({ isLogin, keycloak, auth, setAuth }: AppRoutesProps) => {
  const [login, setLogin] = useState(false);

  const {
    authData, authenticatedData, keycloakData
  } = useSelector<any, any>(({ authentication }) => authentication);

  const dispatch = useAppDispatch();

  const [sessionTimeout, setSessionTimeout] = useState(false);
  const [isPageVisible, setIsPageVisible] = useState(true);
  const [userActive, setUserActive] = useState(true);
  const navigate = useNavigate();

  const tokenRefreshSchedular = () => {
    setInterval(() => {
      if (userActive) {
        setTokenRefreshed();
      }
    }, 1000 * 60 * 15);
  }
  
  const setTokenRefreshed = () => {
    getTokenRefreshed()
      .then((response) => {
        localStorage.setItem('kc_token', response.data.access_token);
        localStorage.setItem('kc_refreshToken', response.data.refresh_token);
      })
      .catch((error) => {
        console.log('Error in getTokenRefreshed: ', error);
        // this.idleTimer.reset();
        // this.setState({ isTimedOut: true });
      });
  }
  
  useEffect(() => {
    let route = window.location.pathname.split('/')[1];
    if (route !== 'signup') {
      dispatch(fetchProfileStatus());
      tokenRefreshSchedular();
    }
  }, [auth, isLogin]);

  useEffect(() => {
    if (authenticatedData) {
      setLogin(true);
    }
    // console.log("Auth", auth);
    console.log("route handler", login);
  }, [authenticatedData])

  const handleLogout = () => {
    keycloak?.logout(); 
  };

 useEffect(() => {
  const sessionTimeoutMinutes = CONSTANTS.SESSION_TIMEOUT_MINUTES; // Adjust the session timeout duration as needed
  const timeoutMilliseconds = sessionTimeoutMinutes * 60 * 1000; // Convert minutes to milliseconds

  const lastActivityTime = localStorage.getItem('lastActivityTime');
  const currentTime = Date.now();

  const resetSessionTimeout = () => {
    localStorage.setItem('lastActivityTime', currentTime.toString());
  };

   const checkInactivity = () => {
    // if(authenticatedData){
    const timeSinceLastActivity = currentTime - parseInt(lastActivityTime || '0', 10);
    if (timeSinceLastActivity >= timeoutMilliseconds) {
      setUserActive(false);
      // Session has timed out
      setSessionTimeout(true);
      // handleLogout();
      // navigateRef.current('/session-timeout'); // Navigate to the session timeout route
      //navigate('/session-timeout');
      // window.location.href = '/session-timeout';
    }
   
  };

  const handleVisibilityChange = () => {
    setIsPageVisible(!document.hidden);
    if (!isPageVisible) {
      // If the page becomes hidden (inactive), reset the session timeout
      resetSessionTimeout();
    }
  };

  const handleWindowBlur = () => {
    // When the window loses focus (e.g., user switches tabs or applications)
    // reset the session timeout
    resetSessionTimeout();
  };

  // Add event listeners to reset the session timeout on user activity
  const activityEvents = ['mousemove', 'keydown'];
  // activityEvents.forEach((event) => {
  //   window.addEventListener(event, resetSessionTimeout);
  // });

  activityEvents.forEach((event) => {
    window.addEventListener(event, () => {
      resetSessionTimeout();
      clearTimeout(sessionTimeoutTimer);
      sessionTimeoutTimer = setTimeout(checkInactivity, timeoutMilliseconds);
    });
  });

  document.addEventListener('visibilitychange', handleVisibilityChange);
  window.addEventListener('blur', handleWindowBlur);

   // Check for inactivity periodically
  let sessionTimeoutTimer = setTimeout(checkInactivity, timeoutMilliseconds);

  // return () => {
  //   activityEvents.forEach((event) => {
  //     window.removeEventListener(event, resetSessionTimeout);
  //   });
  // };
  return () => {
    clearTimeout(sessionTimeoutTimer);
    activityEvents.forEach((event) => {
      window.removeEventListener(event, resetSessionTimeout);
    });
    document.removeEventListener('visibilitychange', handleVisibilityChange);
    window.removeEventListener('blur', handleWindowBlur);
  };
}, [isPageVisible]);

  return (
    <>
    {/* {sessionTimeout ? (
      <Routes>
         <Route path="/session-timeout" element={<SessionTimeoutMessage onLogout={handleLogout} keycloak={keycloak} />} />
      </Routes>
      ) :( */}
      <Routes>
        <Route path="/agreement" element={<Agreement />} />
        <Route path="/maagreement" element={<MaAgreement />} />
        <Route path="/payment/checkout/:tid" element={<PaymentCheckout keycloak={keycloak} auth={auth} />} />
        <Route path="/customer-sentiment" element={<CustomerSentiment />} />
        <Route path="/customer-sentiment/u/:uuid" element={<SentimentWaiting />} />

        {
          (isLogin || login) ?
            <Route path={'/'} element={<RestrictedRoutes auth={auth} keycloak={keycloak} />} >
              <Route path="/" element={<Home auth={auth} isLogin={isLogin} setAuth={setAuth} />} />
              <Route path="/home" element={<Home auth={auth} isLogin={isLogin} setAuth={setAuth}/>} />
              <Route path="/signup" element={<Home auth={auth} isLogin={isLogin} setAuth={setAuth} />} />
              <Route path="/app-category/:catId" element={<AppCategory />} />
              <Route path="/filterapps" element={<FilterApps />} />

              <Route path="/settings/business-details" element={<BusinessDetails auth={auth} />} />
              <Route path="/settings/business-details/true" element={<BusinessDetails auth={auth} />} />
              {/* <Route path="/apps/wizard" element={<AppWizard auth={auth} />} /> */}
              {/* <Route path="/apps/top" element={<DiscoverMoreApps auth={auth} />} /> */}
              <Route path="/apps/new" element={<DiscoverMoreApps auth={auth} />} />
              {/* <Route path="/apps/categories" element={<DiscoverMoreApps auth={auth} />} /> */}
              <Route path="/subscriptions" element={<SubscribedApps auth={auth} />} />
              <Route path="/settings/profile-and-user-permission" element={<ProfileAndUserPermission auth={auth} />} />
              <Route path="/settings/added-apps" element={<AddedApps auth={auth} />} />
              <Route path="/settings/billing-and-plans" element={<BillingAndPlans />} />
              <Route path="/settings/payment" element={<Payment auth={auth} />} />
              <Route path="/settings/payment/card" element={<Payment auth={auth} />} />
              <Route path="/settings/document-and-terms" element={<DocumentAndTerms />} />
              <Route path="/settings/business-brand" element={<BusinessBrands />} />
              <Route path="/settings/businesses" element={<Businesses />} />
              <Route path="/settings/number-configures" element={<NumberConfigures />} />
              {/* <Route path="/settings/account" element={<Account />} /> */}
              <Route path="/widget/:wid" element={<SingleApp />} />
              <Route path="/widget/:wid/:campaignUuid" element={<SingleApp />} />

              <Route path="/discover/apps/:businessType" element={<BusinessTypeApps auth={auth} />} />
              <Route path="/discover/apps/wizard" element={<AppWizard auth={auth} />} />
              <Route path="/discover/widget/:wid" element={<SingleApp />} />
              <Route path="/discover/widget/:wid/:campaignUuid" element={<SingleApp />} />
              {/* <Route path="/discover/apps/top" element={<DiscoverMoreApps auth={auth} />} /> */}
              {/* <Route path="/discover/apps/all" element={<DiscoverMoreApps auth={auth} />} /> */}
              <Route path="/discover/apps/categories" element={<DiscoverMoreApps auth={auth} />} />

              <Route path="/dynamic-widget-list/:wid/list" element={<DynamicWidgetList auth={auth} />} />
              <Route path="/dynamic-widget-list/:wid/:configType" element={<DynamicWidget auth={auth} />} />
              <Route path="/dynamic-widget-list/:wid/:configType/:uuid" element={<DynamicWidget auth={auth} />} />
              <Route path="/dynamic-widget-list/:wid/lead-widget-workflow/:uuid" element={<DynamicWidgeWorkflow auth={auth} />} />
              <Route path="/dynamic-widget/:wid/billing-history" element={<DynamicWidgetBillingHistory auth={auth} />} />
              <Route path="/dynamic-widget/:wid/list" element={<DynamicWidgetConfigDetails auth={auth} />} />

              <Route path="/lead-widget-details/:wid/list" element={<DynamicLeadWidgetDetails auth={auth} />} />
              <Route path="/lead-widget-details/:wid/:configType" element={<DynamicWidget auth={auth} />} />
              <Route path="/lead-widget-details/:wid/:configType/:uuid" element={<DynamicWidget auth={auth} />} />
              <Route path="/lead-widget-details/:wid/lead-widget-workflow/:uuid" element={<DynamicWidgeWorkflow auth={auth} />} />

              <Route path="/location/:wid/list" element={<LocationTracking auth={auth} />} />
              <Route path="/location/:wid/create" element={<LocationTrackCreate auth={auth} />} />
              <Route path="/location/:wid/view/:configId" element={<LocationTrackDetails auth={auth} />} />
              <Route path="/location/:wid/logs/:configId" element={<LocationLogs auth={auth} />} />
              <Route path="/location/:wid/logs/:configId" element={<LocationLogs auth={auth} />} />
              <Route path="/location/:wid/logs/:configId/maplocation/:location" element={<MapLocation auth={auth} />} />

              <Route path="/dynamic-portal/:wid/list" element={<KycPortal auth={auth} />} />
              <Route path="/dynamic-api/:wid/list" element={<KycAPI auth={auth} />} />
              <Route path="/dynamic-api/:wid/configs" element={<KycAPIConfigs auth={auth} />} />


              <Route path="/manage-campaign/:wid/list" element={<ManagedCampaignConfigList auth={auth} />} />
              <Route path="/manage-campaign/:wid/manage-campaign-workflow/:uuid" element={<ManagedCampaignStatusWorkflow auth={auth} />} />
              <Route path="/manage-campaign/:wid/manage-campaign-create" element={<ManageCampaignConfigCreate auth={auth} />} />
              <Route path="/manage-campaign/:wid/manage-campaign-resubmit/:campaignId" element={<ManageCampaignConfigCreate auth={auth} />} />
              <Route path="/manage-campaign/:wid/view-manage-campaign/:campaignId" element={<ManageCampaignConfigCreate auth={auth} />} />
              <Route path="/manage-campaign/:wid/manage-campaign-rejected-resubmit/:campaignId" element={<ManageCampaignConfigCreate auth={auth} />} />

              <Route path="/social-media/:wid/list" element={<SocialMediaConfigList auth={auth}/>} />
              <Route path="/social-media/:wid/social-media-create" element={<SocialMediaConfigCreate auth={auth} />} />
              <Route path="/social-media/:wid/view-social-media/:requestId" element={<SocialMediaConfigCreate auth={auth} />} />

              <Route path="/x-widget/:wid/list" element={<SocialMediaConfigList auth={auth}/>} />
              <Route path="/x-widget/:wid/x-widget-create" element={<SocialMediaConfigCreate auth={auth} />} />
              <Route path="/x-widget/:wid/view-x-widget/:requestId" element={<SocialMediaConfigCreate auth={auth} />} />

              <Route path="/esms/:wid/list" element={<SignupForm />} />

              {/* GWS */}
              <Route path="/google-workspace/:wid/list" element={<GwsConfigList auth={auth} />} />
              <Route path="/google-workspace/:wid/create-domain/:domainId" element={<GwsDomainCreate auth={auth} />} />
              <Route path="/google-workspace/:wid/view-google-workspace/:domainId" element={<GwsDomainConfigView auth={auth} />} />

              {/* FixedBB (Order LTE BB) */}
              <Route path="/fixed-bb/:wid/list" element={<FixedBBConfigList auth={auth} />} />
              <Route path="/fixed-bb/:wid/create-config/:configId" element={<FixedBBConfigCreate auth={auth} />} />
              <Route path="/fixed-bb/:wid/view-config/:configId" element={<FixedBBConfigView auth={auth} />} />
              <Route path="/fixed-bb/:wid/view-config-status/:configId" element={<FixedBBConfigStatusView auth={auth} />} />

              {/* Sentiment */}
              <Route path="/sentiment/:wid/list" element={<SentimentList auth={auth} />} />
              <Route path="/sentiment/:wid/sentiment-create" element={<SentimentCreate auth={auth} />} />
              <Route path="/sentiment/:wid/sentiment-update/:sentimentId" element={<SentimentCreate auth={auth} />} />
              <Route path="/sentiment/:wid/sentimentlogs/:sentimentId" element={<SentimentHistory auth={auth} />} />

              {/* QuickSurvey */}
              <Route path="/survey/:wid/list" element={<QuickSurveyList auth={auth} />} />
              <Route path="/survey/:wid/survey-create" element={<QuickSurveyCreate auth={auth} />} />
              <Route path="/survey/:wid/survey-update/:surveyId" element={<QuickSurveyCreate auth={auth} />} />
              <Route path="/survey/:wid/surveyLogs/:surveyId" element={<QuickSurveyHistory auth={auth} />} />

              {/* Biz Email */}
              <Route path="/biz-email/:wid/list" element={<BizEmailConfigList auth={auth} />} />
              <Route path="/biz-email/:wid/new-biz-email" element={<BizEmailConfigCreate auth={auth} />} />
              <Route path="/biz-email/:wid/view-config/:configId" element={<BizEmailConfigList auth={auth} />} />

              <Route path="/apps/:businessType" element={<BusinessTypeApps auth={auth} />} />
              <Route path="/apps/wizard" element={<AppWizard auth={auth} />} />
              <Route path="/apps/top" element={<DiscoverMoreApps auth={auth} />} />
              <Route path="/apps/all" element={<DiscoverMoreApps auth={auth} />} />
              <Route path="/apps/categories" element={<DiscoverMoreApps auth={auth} />} />
              <Route path="/widget/:wid" element={<SingleApp />} />
              <Route path="/widget/:wid/:campaignUuid" element={<SingleApp />} />

              <Route path="/owner-dashboard" element={<OwnerDashboard/>} />
              <Route path="/partner-dashboard" element={<PartnerDashboard/>} />
              <Route path="/offers" element={<Offers auth={auth}/>} />

              <Route path="/dynamic-widget/:wid/:configType" element={<DynamicWidgetV2 auth={auth} />} />

            </Route>
            : <Route path={'/'} element={<RestrictedRoutes auth={auth} keycloak={keycloak} />} >
              <Route path="/signup" element={<Home auth={auth} isLogin={isLogin} setAuth={setAuth} />} />
              <Route path="/apps/:businessType" element={<BusinessTypeApps auth={auth} />} />
              <Route path="/apps/wizard" element={<AppWizard auth={auth} />} />
              <Route path="/apps/top" element={<DiscoverMoreApps auth={auth} />} />
              <Route path="/apps/all" element={<DiscoverMoreApps auth={auth} />} />
              <Route path="/apps/categories" element={<DiscoverMoreApps auth={auth} />} />
              <Route path="/widget/:wid" element={<SingleApp />} />
              <Route path="/widget/:wid/:campaignUuid" element={<SingleApp />} />
              <Route path="/app-category/:catId" element={<AppCategory />} />
              <Route path="/password-reset/:client_Id" element={<UserPasswordReset />} />
              </Route>
        }
        {/* <Route path={'/'} element={<RestrictedRoutes auth={auth} keycloak={keycloak} />} >
          <Route path="/signup" element={<Home auth={auth} isLogin={isLogin} setAuth={setAuth} />} />
          <Route path="/apps/:businessType" element={<BusinessTypeApps auth={auth} />} />
          <Route path="/apps/wizard" element={<AppWizard auth={auth} />} />
          <Route path="/apps/top" element={<DiscoverMoreApps auth={auth} />} />
          <Route path="/apps/new" element={<DiscoverMoreApps auth={auth} />} />
          <Route path="/apps/categories" element={<DiscoverMoreApps auth={auth} />} />
        </Route> */}
      </Routes>
      {/* )} */}
    </>
  );
}

export default RouteHandler;