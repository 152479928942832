import React, { useEffect } from 'react';
import {ChargeableTypeTenPaymentComponent} from "../../../components/chargeable-types/ChargeableTypeTenComponent";
import {ChargeableTypeThreeComponent} from "../../../components/chargeable-types/ChargeableTypeThreeComponent";
import {ChargeableTypeFiveComponent} from "../../../components/chargeable-types/ChargeableTypeFiveComponent";
import {ChargeableTypeSixComponent} from "../../../components/chargeable-types/ChargeableTypeSixComponent";
import {ChargeableTypeSevenComponent} from "../../../components/chargeable-types/ChargeableTypeSevenComponent";
import {ChargeableTypeEightComponent} from "../../../components/chargeable-types/ChargeableTypeEightComponent";
import {ChargeableTypeOneComponent} from "../../../components/chargeable-types/ChargeableTypeOneComponent";
import {ChargeableTypeTwoComponent} from "../../../components/chargeable-types/ChargeableTypeTwoComponent";
import {ChargeableTypeNineComponent} from "../../../components/chargeable-types/ChargeableTypeNineComponent";
import {Form} from "antd";

interface Props {
    widget: any
}

const PaymentPackage = ({ widget }: Props) => {

    const [appDetails, setAppDetails] = React.useState<any>(null);

    useEffect(() => {
        if(widget) {
            let appData = {
                widgetDetails: {
                    uuid: widget.uuid,
                    WidgetRate: widget.WidgetRate,
                    SubWidgets: widget.SubWidgets,
                    WidgetConfigDetails: widget.WidgetConfigDetails,
                    Category: widget.Category,
                    isPaymentMethodEditable: widget.isPaymentMethodEditable
                },
                subscriptionStatus: widget.WidgetSubscriptions?.status,
                groupDetails: [{ widgetCount: widget.Category?.Widgets.length }]
            }
            setAppDetails(appData);
        }
    }, [widget])

    return (
        <React.Fragment>
            <div className="panel-inner-feature-content__wrapper">
                <span className="panel-inner-feature-content-title">
                    Package/Rental
                </span>
                <div className="panel-inner-feature-content">
                    <p>
                        Default selected payment method will be the payment method of this
                        app. Feel free to change
                    </p>
                    <div className="form-group__wrapper">
                        <Form.Item name="paymentPackage">
                            <div className="selectable-list-element__wrapper">
                                {widget.isChargeable && <div>
                                    {widget.ChargeableType == 1 ?
                                        <ChargeableTypeOneComponent appDetails={appDetails} />
                                    : (widget.ChargeableType == 2 && appDetails) ?
                                        <ChargeableTypeTwoComponent appDetails={appDetails}/>
                                    : (widget.ChargeableType == 3 && appDetails) ?
                                        <ChargeableTypeThreeComponent appDetails={appDetails}/>
                                    : (widget.ChargeableType == 5 && appDetails) ?
                                        <ChargeableTypeFiveComponent appDetails={appDetails}/>
                                    : (widget.ChargeableType == 6 && appDetails) ?
                                        <ChargeableTypeSixComponent appDetails={appDetails} />
                                    : (widget.ChargeableType == 7 && appDetails) ?
                                        <ChargeableTypeSevenComponent appDetails={appDetails} />
                                    : (widget.ChargeableType == 8 && appDetails) ?
                                        <ChargeableTypeEightComponent appDetails={appDetails} />
                                    : (widget.ChargeableType == 9 && appDetails) ?
                                        <ChargeableTypeNineComponent appDetails={appDetails} />
                                    : (widget.ChargeableType == 10 && appDetails) ?
                                        <ChargeableTypeTenPaymentComponent appDetails={appDetails}/>
                                    : (widget.ChargeableType == 11 && appDetails) ?
                                        <ChargeableTypeOneComponent appDetails={appDetails}/> //we can use chargeableType 1 for chargeableType 11
                                    : (widget.ChargeableType == 13 && appDetails) ?
                                        <ChargeableTypeOneComponent appDetails={appDetails}/> //we can use chargeableType 1 for chargeableType 13
                                    : <></>}
                                </div>}
                            </div>
                        </Form.Item>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export { PaymentPackage };