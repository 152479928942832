import quickSurveyStore from "../reducers/quicksurvey.store";

import { 
    getSurveyList,
    postSurveyData,
    getSurveyIndividualStatus,
    putQuickSurveyStatus,
    getSurveyLogs,
} from '../../services/quick-survey.service';

import { notification } from "antd";

export const fetchQuickSurveylist = (tenantUuid: string, configUuid: string): any => {
    return (dispatch: any) => {
        dispatch(quickSurveyStore.actions.quickSurveyFetch());

        getSurveyList(tenantUuid, configUuid)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(quickSurveyStore.actions.quickSurveyFetchSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(quickSurveyStore.actions.quickSurveyFetchFail(error));
            });
    }
};

export const createSurveyData = (request_payload: any, tenantUuid: string, widgetUuid: string): any => {
    return (dispatch: any) => {
        dispatch(quickSurveyStore.actions.surveyDataPost());
        postSurveyData(request_payload, tenantUuid, widgetUuid)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(quickSurveyStore.actions.surveyDataPostSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(quickSurveyStore.actions.surveyDataPostFail(error));
                notification.error({
                    message: `ERROR`,
                    description: error?.response?.data?.error,
                    placement: 'topRight'
                });
            });
    }
};

export const fetchIndividualSurveyDetails = (tenantUuid: string, surveyUuid: string): any => {
    return (dispatch: any) => {
        dispatch(quickSurveyStore.actions.surveyIndividualDetailsFetch());

        getSurveyIndividualStatus(tenantUuid, surveyUuid)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(quickSurveyStore.actions.surveyIndividualDetailsFetchSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(quickSurveyStore.actions.surveyIndividualDetailsFetchFail(error));
            });
    }
};

export const updateSurveyStatus = (tenantId: string, surveyId: string, data: any): any => {
    return (dispatch: any) => {
        dispatch(quickSurveyStore.actions.surveyUpdateFetch());

        putQuickSurveyStatus(tenantId, surveyId, data)
            .then((response) => {
                if (response.status == 200) {
                    dispatch(quickSurveyStore.actions.surveyUpdateSuccess(response.data));
                } else {

                }
            })
            .catch((error) => {
                dispatch(quickSurveyStore.actions.surveyUpdateFail(error));
                notification.error({
                    message: `ERROR`,
                    description: error?.response?.data?.message,
                    placement: 'topRight'
                });
            });
    }
};

export const fetchSurveyLogs = (tenantUuid: string, surveyUuid: string): any => {
    return (dispatch: any) => {
        dispatch(quickSurveyStore.actions.surveyLogFetch());

        getSurveyLogs(tenantUuid, surveyUuid)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(quickSurveyStore.actions.surveyLogFetchSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(quickSurveyStore.actions.surveyDataPostFail(error));
            });
    }
};