import React, {useEffect, useState} from 'react'
import SimpleReactValidator from "simple-react-validator";
import {SubWidgetSelect} from "../../pages/single-app/components/SubWidgetSelect";

type Props = {
    appDetails: any
}

const ChargeableTypeEightComponent = ({appDetails}: Props) => {

    const [selectedSubWidget, setSelectedSubWidget] = React.useState(null);
    const [validator] = useState(new SimpleReactValidator())

    useEffect(() => {
        if (appDetails && appDetails.widgetDetails && appDetails.widgetDetails.SubWidgets && Array.isArray(appDetails.widgetDetails.SubWidgets) && appDetails.widgetDetails.WidgetConfigDetails && appDetails.widgetDetails.WidgetConfigDetails.subWidgetId) {
            let selectedSubWidget = appDetails.widgetDetails.SubWidgets.filter(function (el: any) {
                return el.id === appDetails.widgetDetails.WidgetConfigDetails.subWidgetId;
            });
            setSelectedSubWidget(selectedSubWidget[0]);
        }
    }, [])
    return (
        <div className="panel-table-content-list">
            {appDetails && appDetails.widgetDetails && appDetails.widgetDetails.SubWidgets && Array.isArray(appDetails.widgetDetails.SubWidgets) &&
                <SubWidgetSelect
                    appDetails={appDetails}
                    onChangeWidget={() => {
                    }}
                    selectedSubWidget={selectedSubWidget}
                    validator={validator}
                />
            }
        </div>
    )
}

export {ChargeableTypeEightComponent}