import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Tooltip } from "antd";
import CSVReader from 'react-csv-reader'
import { Col, Form, Input, message, Radio, Row, Select, Spin, Switch, Table } from "antd";

type Props = {
    mainLabel: string,
    numberBase: any,
    newNumberBase: any,
    onSelectNumberBase: any,
    existingNumberBase: any,
    numberBaseName: any,
    onNumberbaseNameChange: any,
    numberBaseNameDisabled: any,
    numberBaseNameError: any,
    inputLoading: boolean,
    onChangeFile: (file: any, info: any) => void,
    numberBaseLabel: string,
    newNumberBaseDisabled: boolean,
    numberUploadError: string,
    onSelectExistingNumberBase: any,
    existingNumberBaseDisabled: boolean,
    tenantNumberBases: any,
    numberBaseError: string,
    all_operators: boolean,
}

const NumberbaseInput = ({ mainLabel, numberBase, newNumberBase, onSelectNumberBase, existingNumberBase,
    numberBaseName, onNumberbaseNameChange, numberBaseNameDisabled, numberBaseNameError, inputLoading, onChangeFile, numberBaseLabel,
    newNumberBaseDisabled, numberUploadError, onSelectExistingNumberBase, existingNumberBaseDisabled,
    tenantNumberBases, numberBaseError, all_operators
}: Props) => {

    const renderNumberBase = () => {
        if (tenantNumberBases !== null && typeof tenantNumberBases !== 'undefined') {
            if (Array.isArray(tenantNumberBases.NumberBases)) {
                return (
                    tenantNumberBases.NumberBases.map((value: any, index: any) => {
                        if (value.numberBaseName !== '' && value.numberBaseName !== null) {
                            return (
                                <option value={value.uuid}>{value.numberBaseName}</option>
                            );
                        }
                    })
                )
            }
        }
    }

    return (
        <>
            <div className="form-element__wrapper mb-3">
                <label htmlFor="" className="form-label">
                    <Tooltip
                        placement="left"
                        title="Upload .csv only"
                    // getPopupContainer={trigger => trigger.parentElement}
                    >
                        Select sentiment date-time<span aria-label="required"></span>
                    </Tooltip>
                </label>
                <div className="inner-multiple-feature-list-element__mc-wrapper horizontal">
                    <div className="form-check mb-4">
                        <div className="custom-control custom-radio custom-control-inline">
                            <input
                                type="radio"
                                className="form-check-input"
                                id="newNumberBase"
                                name="numberBase"
                                checked={numberBase === newNumberBase}
                                onChange={onSelectNumberBase}
                                value={newNumberBase}
                            />
                            <label className="custom-control-label">Upload new number base</label>
                        </div>
                        <div className="col-md-12">
                            {(mainLabel === "Employee base") ?
                                <a
                                    href="https://static.ideabiz.lk/marketplacepartner/Emp_Attendance_Sample_Number_Base.csv"
                                    target="_blank"
                                    className="btn btn-sm csv__sample"
                                >
                                    <i className="fas fa-file-csv"></i>&nbsp;
                                    Sample csv
                                </a>
                                :
                                <a
                                    href="https://static.ideabiz.lk/marketplacepartner/Sample.csv"
                                    target="_blank"
                                    className="btn btn-sm csv__sample"
                                >
                                    <i className="fas fa-file-csv"></i>&nbsp;
                                    Sample csv
                                </a>}
                        </div>
                    </div>
                    <div className="form-check mb-4">
                        <div className="custom-control custom-radio custom-control-inline">
                            <input
                                type="radio"
                                className="form-check-input"
                                id="existingNumberBase"
                                name="numberBase"
                                checked={numberBase === existingNumberBase}
                                onChange={onSelectNumberBase}
                                value={existingNumberBase} />
                            <label className="custom-control-label">Select from available number bases</label>
                        </div>
                    </div>
                </div>
            </div>
            {numberBase === newNumberBase && <div className="form-group__wrapper">
                <div className="form-group">
                    <div className="custom-file">
                        <input
                            type="text"
                            id="smsSenderName"
                            className="form-control form-control-md"
                            value={numberBaseName}
                            onChange={onNumberbaseNameChange}
                            placeholder="Name of the number base"
                            disabled={numberBaseNameDisabled}
                        />
                        <div className="validation invalid-feedback">{numberBaseNameError}</div><br />
                    </div>
                    <div className="custom-file">
                        {!inputLoading ? <CSVReader
                            cssClass="mt-6"
                            cssInputClass="custom-file-input"
                            cssLabelClass="custom-file-label-sentiment"
                            onFileLoaded={(data, fileInfo) => onChangeFile(data, fileInfo)}
                            label={numberBaseLabel}
                            disabled={newNumberBaseDisabled}
                        /> : <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                        {all_operators === false ? <small id="email" className="form-text text-muted ">Non dialog mobile numbers will be removed automatically from the numberbase</small> : null}
                        <br></br>
                        <div className="validation invalid-feedback">{numberUploadError}</div>
                    </div>
                </div>
            </div>}
            {numberBase === existingNumberBase && <div className="form-group">
                <div className="form-element__wrapper mb-3 ">
                    <div className="icon-inside right">
                        <select
                            className="form-control"
                            onChange={onSelectExistingNumberBase}
                            disabled={existingNumberBaseDisabled}>
                            <option selected disabled>Select a number base</option>
                            {renderNumberBase()}
                        </select>
                    </div>
                </div>
            </div>}
            <div className="row wow fadeIn">
                <div className="col-md-12 custom-control">
                    <small className="validation invalid-feedback">{numberBaseError}</small>
                </div>
            </div>
        </>
    )
}

export { NumberbaseInput };