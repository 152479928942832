import { NavigateFunction } from "react-router-dom";
import { v4 as uuid } from 'uuid';
import { WidgetSubscriptionModel } from "../../pages/single-app/models/request/widget-subscription.model";
import {
    getFeatureAppDetailsForAppWizard,
    getCategories,
    getTopApps,
    getNewlyArrivedApps,
    getTrendingApps,
    getPaymentMsisdnList,
    getGenieCardList,
    postWidgetSubscription,
    getBusinessTypes,
    getBusinessTypeDetails,
    getBusinessTypeTopApps,
    postKycWidgetSubscription,
    getAllWidgets,
    getSearchResults
} from "../../services/app-wizard.service"
import appStore from "../reducers/app-wizard.store";
import { SingleAppModel } from "../../pages/single-app/models/response/single-app.model";
import * as Constants from '../../constants';
import {saveBetterHrSubscribe, saveClick2PaySubscribe, saveWorkHubSubscribe } from "../../services/widgets.service";
import { message, notification } from "antd";
import { memorizeApp } from "./memory.action";
import {KycWidgetSubscriptionModel} from "../../pages/single-app/models/request/kyc-widget-subscription.model";
import { off } from "process";

export const fetchFeatureApps = (limit: number, offset: number): any => {
    return (dispatch: any) => {
        getFeatureAppDetailsForAppWizard(limit, offset)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    console.log('fetchFeatureApps-Action', response.data.data)
                    dispatch(appStore.actions.getAppDetailsSuccess(response.data.data));
                } else {
                    dispatch(appStore.actions.getAppDetailsFailed());
                }
            })
            .catch((error) => {
                dispatch(appStore.actions.getAppDetailsFailed());
            });
    }
}

export const fetchCategories = (): any => {
    return (dispatch: any) => {
        getCategories()
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    dispatch(appStore.actions.getCategorySuccess(response.data));
                } else {
                    dispatch(appStore.actions.getCategoryFailed());
                }
            })
            .catch((error) => {
                dispatch(appStore.actions.getCategoryFailed());
            });
    }
}

export const fetchTopApps = (limit: number, offset: number): any => {
    return (dispatch: any) => {
        getTopApps(limit, offset)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    dispatch(appStore.actions.getTopAppsSuccess(response.data.data));
                } else {
                    dispatch(appStore.actions.getTopAppsFailed());
                }
            })
            .catch((error) => {
                dispatch(appStore.actions.getTopAppsFailed());
            });
    }
}

export const fetchAllWidgets = (limit: number, offset: number): any => {
    return (dispatch: any) => {
        getAllWidgets(limit, offset)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    dispatch(appStore.actions.getAllWidgetsSuccess(response.data.data));
                } else {
                    dispatch(appStore.actions.getAllWidgetsFailed());
                }
            })
            .catch((error) => {
                dispatch(appStore.actions.getAllWidgetsFailed());
            });
    }
}

export const fetchTrendingApps = (): any => {
    return (dispatch: any) => {
        getTrendingApps()
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    dispatch(appStore.actions.getTrendingSuccess(response.data.data));
                } else {
                    dispatch(appStore.actions.getTrendingFailed());
                }
            })
            .catch((error) => {
                dispatch(appStore.actions.getTrendingFailed());
            });
    }
}

export const fetchPaymentMsisdnList = (tenantId: string, widgetId: string): any => {
    return (dispatch: any) => {
        getPaymentMsisdnList(tenantId, widgetId)
            .then((response) => {
                // console.log("################## 1", response.data.data);

                if (response.status === 200 || response.status === 201) {
                    dispatch(appStore.actions.getPaymentMsisdnListSuccess(response.data.data));
                } else {
                    dispatch(appStore.actions.getPaymentMsisdnListFailed());
                }
            })
            .catch((error) => {
                dispatch(appStore.actions.getPaymentMsisdnListFailed());
            });
    }
}

export const fetchGenieCardList = (tenantId: string): any => {
    return (dispatch: any) => {
        getGenieCardList(tenantId)
            .then((response) => {
                // console.log("################## 2", response.data.data);
                if (response.status === 200 || response.status === 201) {
                    dispatch(appStore.actions.getGenieCardListSuccess(response.data.data));
                } else {
                    dispatch(appStore.actions.getGenieCardListFailed());
                }
            })
            .catch((error) => {
                dispatch(appStore.actions.getGenieCardListFailed());
            });
    }
}

export const submitAppSubscription = (tenantId: string, widgetId: string, req: WidgetSubscriptionModel, navigate: NavigateFunction, appDetails: SingleAppModel | null): any => {
    return (dispatch: any) => {
        dispatch(appStore.actions.postAppSubLoading());
        postWidgetSubscription(tenantId, widgetId, req)
            .then((response) => {
                if ((response.status === 200 || response.status === 201 ) && response.data.status != "FAILED") {
                    dispatch(appStore.actions.postAppSubSuccess());
                    supportNavigation(tenantId, widgetId, navigate, appDetails);
                } else {
                    dispatch(appStore.actions.postAppSubFailed());
                    notification.error({
                        message: 'Failed',
                        description: 'App Subscription failed. Reason: ' + response.data.message
                    });
                }
            })
            .catch((error) => {
                dispatch(appStore.actions.postAppSubFailed());
            });
    }
}

export const submitBetterHrSubscription = (tenantId: string, widgetId: string, req: any, navigate: NavigateFunction, appDetails: SingleAppModel | null): any => {
    return (dispatch: any) => {
        dispatch(appStore.actions.postBetterHrSubLoading());
        saveBetterHrSubscribe(tenantId, widgetId, req)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    dispatch(appStore.actions.postBetterHrSubSuccess());
                    supportNavigation(tenantId, widgetId, navigate, appDetails);
                } else {
                    dispatch(appStore.actions.postBetterHrSubFailed());
                }
            })
            .catch((error) => {
                dispatch(appStore.actions.postBetterHrSubFailed());
            });
    }
}

export const submitWorkhubSubscription = (tenantId: string, widgetId: string, req: any, navigate: NavigateFunction, appDetails: SingleAppModel | null): any => {
    return (dispatch: any) => {
        dispatch(appStore.actions.postWorkhubSubLoading());
        saveWorkHubSubscribe(tenantId, widgetId, req)
            .then((response) => {
                if (response.data.statusCode === 200 || response.data.statusCode === 201) {
                    dispatch(appStore.actions.postWorkhubSubSuccess());
                    supportNavigation(tenantId, widgetId, navigate, appDetails);
                } else {
                    notification.error({
                        message: `ERROR`,
                        description: response.data.message,
                        placement: 'bottomRight'
                    });
                    dispatch(appStore.actions.postWorkhubSubFailed());
                }
            })
            .catch((error) => {
                dispatch(appStore.actions.postWorkhubSubFailed());
            });
    }
}

export const submitClick2PaySubscription = (tenantId: string, widgetId: string, req: any, navigate: NavigateFunction, appDetails: SingleAppModel | null): any => {
    return (dispatch: any) => {
        dispatch(appStore.actions.postC2PSubLoading());
        saveClick2PaySubscribe(tenantId, widgetId, req)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    dispatch(appStore.actions.postC2PSubSuccess());
                    supportNavigation(tenantId, widgetId, navigate, appDetails);
                } else {
                    dispatch(appStore.actions.postC2PSubFailed());
                }
            })
            .catch((error) => {
                dispatch(appStore.actions.postC2PSubFailed());
            });
    }
}

export const supportNavigation = (tenantId: string, widgetId: string, navigate: NavigateFunction, appDetails: SingleAppModel | null | any): any => {
    if (appDetails?.widgetDetails && appDetails?.widgetDetails.signupUrl && (appDetails?.widgetDetails.signupUrl.startsWith("https") || appDetails?.widgetDetails.signupUrl.startsWith("http"))) {
        if (appDetails?.widgetDetails.signupUrl.includes("<cid>")) {
            let redirectToUrl = appDetails?.widgetDetails.signupUrl.replace("<cid>", `${tenantId}`);
            if (!appDetails.widgetDetails.isApprovalRequired) {
                window.open(redirectToUrl, "_blank") || window.location.replace(redirectToUrl);
            } else {
                message.success(`${appDetails.widgetDetails.title} widget subscription will take place after the admin approval.`);
            }
            navigate("/discover/apps/wizard");
        } else if (appDetails?.widgetDetails.ExternalApp && appDetails?.widgetDetails.ExternalApp.grantType === 'authorization_code') {
            let replaced_clientid;
            if (Constants && Constants.MARKETPLACEAUTH) {
                replaced_clientid = Constants.MARKETPLACEAUTH.replace("<client_id>", `${appDetails?.widgetDetails.ExternalApp.clientId}`);
            }
            let replaced_code = replaced_clientid?.replace("<code>", uuid());
            let redirectToUrl;
            if (appDetails?.widgetDetails.signupUrl.includes("?")) {
                redirectToUrl = replaced_code + appDetails?.widgetDetails.signupUrl.replace("<entId>", `${tenantId}`);
            } else {
                redirectToUrl = replaced_code + appDetails?.widgetDetails.signupUrl.replace("<entId>", `?${tenantId}`);
            }
            if (!appDetails.widgetDetails.isApprovalRequired) {
                window.open(redirectToUrl, "_blank") || window.location.replace(redirectToUrl);
            } else {
                message.success(`${appDetails.widgetDetails.title} widget subscription will take place after the admin approval.`);
            }
            navigate("/discover/apps/wizard");
        } else {
            if (!appDetails.widgetDetails.isApprovalRequired) {
                window.open(appDetails?.widgetDetails.signupUrl, "_blank") || window.location.replace(appDetails?.widgetDetails.signupUrl);
            } else {
                message.success(`${appDetails.widgetDetails.title} widget subscription will take place after the admin approval.`);
            }
            navigate("/discover/apps/wizard");
        }
    } else {
        if (appDetails?.widgetDetails) {
            if (!appDetails.widgetDetails.isApprovalRequired) {
                navigate(`${appDetails?.widgetDetails.signupUrl}/${widgetId}/list`);
            } else {
                message.success(`${appDetails.widgetDetails.title} widget subscription will take place after the admin approval.`);
                navigate("/discover/apps/wizard");
            }
        }
    }
}

export const onRedirectTo = (tenantGroupId: string, navigate: NavigateFunction, appDetails: SingleAppModel | null | any, dispatch: any, callBackend: any | null): any => {
    let appObj = JSON.parse(JSON.stringify(appDetails));
    appObj.tenantId = tenantGroupId;
    dispatch(memorizeApp(appObj));
    // console.log("###############################", appObj);

    if (appDetails.signupUrl && (appDetails.signupUrl.startsWith("https") || appDetails.signupUrl.startsWith("http"))) {
        if (appDetails.signupUrl.includes("<cid>")) {
            let redirectToUrl = appDetails.signupUrl.replace("<cid>", `${tenantGroupId}`);
            window.open(redirectToUrl, "_blank") || window.location.replace(redirectToUrl);
        } else if (appDetails.ExternalApp && appDetails.ExternalApp.grantType === 'authorization_code') {
            let replaced_clientid;
            if (Constants && Constants.MARKETPLACEAUTH) {
                replaced_clientid = Constants.MARKETPLACEAUTH.replace("<client_id>", `${appDetails.ExternalApp.clientId}`);
            }
            let replaced_code = replaced_clientid?.replace("<code>", uuid());
            let redirectToUrl;
            if (appDetails.signupUrl.includes("?")) {
                redirectToUrl = replaced_code + appDetails.signupUrl.replace("<entId>", `${tenantGroupId}`);
            } else {
                redirectToUrl = replaced_code + appDetails.signupUrl.replace("<entId>", `?${tenantGroupId}`);
            }
            window.open(redirectToUrl, "_blank") || window.location.replace(redirectToUrl);
        } else {
            window.open(appDetails.signupUrl, "_blank") || window.location.replace(appDetails.signupUrl);
        }
    } else if (callBackend) {
        callBackend();
    } else {
        if (appDetails) {
            navigate(`${appDetails.signupUrl}/${appDetails.uuid}/list`);
        }
    }
}

export const fetchBusinessTypes = (): any => {
    return (dispatch: any) => {
        getBusinessTypes()
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    dispatch(appStore.actions.getBusinessTypesSuccess(response.data.data));
                } else {
                    dispatch(appStore.actions.getBusinessTypesFailed());
                }
            })
            .catch((error) => {
                dispatch(appStore.actions.getBusinessTypesFailed());
            });
    }
}

export const fetchBusinessTypeDetails = (businessTypeId: string, searchTerm: string | null, limit: number, offset: number): any => {
    return (dispatch: any) => {
        getBusinessTypeDetails(businessTypeId, searchTerm, limit, offset)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    dispatch(appStore.actions.getBusinessTypeDetailsSuccess({ data: response.data.data, offset }));
                } else {
                    dispatch(appStore.actions.getBusinessTypeDetailsFailed());
                }
            })
            .catch((error) => {
                dispatch(appStore.actions.getBusinessTypeDetailsFailed());
            });
    }
}

export const fetchBusinessTypeTopApps = (businessTypeId: string, limit: number, offset: number): any => {
    return (dispatch: any) => {
        getBusinessTypeTopApps(businessTypeId, limit, offset)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    dispatch(appStore.actions.getBusinessTypeTopAppsSuccess({ data: response.data.data, offset }));
                } else {
                    dispatch(appStore.actions.getBusinessTypeTopAppsFailed());
                }
            })
            .catch((error) => {
                dispatch(appStore.actions.getBusinessTypeTopAppsFailed());
            });
    }
}

export const submitKYCWidgetSubscription = (tenantId: string, widgetId: string, req: KycWidgetSubscriptionModel, navigate: NavigateFunction, appDetails: SingleAppModel | null): any => {
    return (dispatch: any) => {
        dispatch(appStore.actions.postAppSubLoading());
        postKycWidgetSubscription(tenantId, widgetId, req)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    dispatch(appStore.actions.postAppSubSuccess());
                    supportNavigation(tenantId, widgetId, navigate, appDetails);
                } else {
                    dispatch(appStore.actions.postAppSubFailed());
                }
            })
            .catch((error) => {
                dispatch(appStore.actions.postAppSubFailed());
            });
    }
}

export const fetchSearchResults = ( key: string, limit: number, offset: number, appDataArray: any[]) => {
    return (dispatch: any) => {
        getSearchResults(key, limit, offset)
            .then((res => {
                console.log("res Search", res);
                // if (res.status === 200) {
                    const updatedData = offset === 0 ? res.data : [...appDataArray, ...res.data];
                    let hasMoreData = res.data < limit ? false : true;
                    // let filterdArray = updatedData.filter( (ele: any, ind: number) => ind === updatedData.findIndex((elem:any) => elem.jobid === ele.jobid && elem.id === ele.id))
                    let filterdArray = updatedData.filter((tag: any, index: number, array: any[]) => array.findIndex(t => t.title == tag.title && t.uuid == tag.uuid) == index);
                    // console.log("filterdArray", filterdArray);

                    dispatch(appStore.actions.getSearchResults({ filterdArray, hasMoreData }));
                // }
            }))
            .catch((error) => {
                dispatch(appStore.actions.getSearchResults(error))
            })
    }
};