import axiosInstance from '../axios-instance';
import * as Constants from '../constants';

const getWorkhubSubscriptionData = (tenantId: string, widgetId: string) => {
    return axiosInstance.get(`${Constants.REACT_APP_NODE_BACKEND_URL}/widget/workhub/${tenantId}/${widgetId}`);
}

const putWorkHub = async (tid: string, WidegetId : string, formData: any) => {
    return axiosInstance({
        method: `PUT`,
        data: formData,
        url: `${Constants.REACT_APP_NODE_BACKEND_URL}/widget/workhub/${tid}/${WidegetId}`,
    });
}

export {
    getWorkhubSubscriptionData,
    putWorkHub
};