import { combineReducers } from 'redux';
import appWizardStore from "./app-wizard.store";
import appStore from './app.store';
import userStore from "./user.store";
import settingsStore from './settings.store';
import DynamicFormSlice from './DynamicFormSlice';
import whereAreMyEmpWithoutConcent from './where-are-my-employees.store';
import memoryStore from './memory.store';
import authenticationStore from './authentication.store';
import kycStore from './kyc.store';
import esmsStore from './esms.store';
import manageCampaignStore from './manage-campaign.store.js';
import gwsStore from './gws-store';
import betterHrStore from "./better-hr.store";
import enterpriseStore from "./enterprise.store";
import socialMediaStore from './social-media.store';
import workhubStore from "./workhub.store";
import dashboardStore from './dashboard.store';
import sentimentStore from './sentiment.store';
import offersStore from './offers.store';
import bizEmailStore from './biz-email.store';
import quickSurveyStore from './quicksurvey.store';
import fixedBBStore from './fixed-bb-store';

export default combineReducers({
  memory: memoryStore.reducer,
  user: userStore.reducer,
  appWizard: appWizardStore.reducer,
  apps: appStore.reducer,
  settings: settingsStore.reducer,
  dynamicForm: DynamicFormSlice.reducer,
  location: whereAreMyEmpWithoutConcent.reducer,
  authentication: authenticationStore.reducer,
  kyc: kycStore.reducer,
  esms: esmsStore.reducer,
  mc: manageCampaignStore.reducer,
  betterhr: betterHrStore.reducer,
  ent: enterpriseStore.reducer,
  workhub: workhubStore.reducer,
  dashboard: dashboardStore.reducer,
  sm: socialMediaStore.reducer,
  gws: gwsStore.reducer,
  sentiment: sentimentStore.reducer,
  offers: offersStore.reducer,
  bizEmail: bizEmailStore.reducer,
  quicksurvey: quickSurveyStore.reducer,
  fixedBB: fixedBBStore.reducer
});