import React, { CSSProperties, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
    fetchSingleApp,
    fetchNumberCount,
    createSurveyData,
    fetchNumberBase,
    fetchIndividualSurveyDetails,
    updateSurveyStatus,
    fetchSettlementTyps,
} from '../../../store/actions';
import { useAppDispatch } from '../../../store';
import AuthHandler from '../../../helper/AuthHandler';
import { PageHeaderStatic } from "../components/PageHeaderStatic";
import { confirmAlert } from "react-confirm-alert";
import { Divider, Steps, notification, DatePicker, Modal, Space, Spin, Switch } from "antd";
import { Window } from "react-bootstrap-icons";
import dayjs from 'dayjs';
import SimpleReactValidator from 'simple-react-validator';
import * as CONSTANTS from '../../../constants';
import { CloseOutlined } from "@ant-design/icons";
import { NumberbaseInput } from "../sentiment/components/number-base-input.component";
import { NewSentimentData } from "./models/new-sentiment-data.model";
import quickSurveyStore from "../../../store/reducers/quicksurvey.store";

const { RangePicker } = DatePicker;

const override: CSSProperties = {
    display: "block",
    margin: "0 auto"
};

const AddNewQuickSurvey = ({ auth }: any) => {

    const { wid } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [screen, setScreen] = useState('add-new-quick-survey');
    const [enterpriseName, setEnterpriseName] = useState("");
    const [surveyName, setSurveyName] = useState("");
    const [paymentMethodId, setPaymentMethodId] = useState<number>(0);
    const [enterpriseOwnerNumber, setEnterpiseOwnerNumber] = useState("");
    const [chargingNumber, setChargingNumber] = useState("");
    const [scheduleType, setScheduleType] = useState("now");
    const [date, setDate] = useState('');
    const [fieldDisabled, setFieldDisabled] = useState(false);
    const [dateRange, setDateRange] = useState<any[]>([]);
    const [surveyNumberBase, setSurveyNumberBase] = useState("NEW_NUMBER_BASE");
    const [newNumberBase, setNewNumberBase] = useState("NEW_NUMBER_BASE");
    const [numberBaseName, setNumberBaseName] = useState("");
    const [existingNumberBaseDisabled, setExistingNumberBaseDisabled] = useState(false);
    const [newNumberBaseDisabled, setNewNumberBaseDisabled] = useState(false);
    const [numberBaseNameDisabled, setNumberBaseNameDisabled] = useState(false);
    const [numberBaseLabel, setNumberBaseLabel] = useState("Select CSV");
    const [existingNumberBase, setExistingNumberBase] = useState("EXISTING_NUMBER_BASE");
    const [inputLoading, setInputLoading] = useState(false);
    const [numberBaseError, setNumberBaseError] = useState("");
    const [dropdownSelected, setDropdownSelected] = useState(false);
    const [tenantNumberBases, setTenantNumberBases] = useState([]);
    const [seletedMask, setSeletedMask] = useState("");
    const [question, setQuestion] = useState("");
    const [questionLength, setQuestionLength] = useState(0);
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
    const [questionListError, setQuestionListError] = useState("");
    const [submitUpdate, setSubmitUpdate] = useState<boolean>(true)
    const [numberListLength, setNumberListLength] = useState<number>(0);
    const [saveSuccess, setSaveSuccess] = useState<boolean>(false)
    const [saveError, setSaveError] = useState<boolean>(false)
    const [saveLoading, setSaveLoading] = useState<boolean>(false);
    const [numberUploadError, setNumberUploadError] = useState("");
    const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
    const [surveyStatus, setSurveyStatus] = useState<string>("");
    const [statusValue, setStatusValue] = useState<string>("");
    const [isView, setIsView] = useState<boolean>(false);
    const [settlementTypes, setSettlementTypes] = useState([]);
    const [newSurveyData, setNewSurveyData] = useState<NewSentimentData>({
        tenantId: "",
        smsMask: "",
        startTime: new Date(),
        endTime: '',
        questionList: [],
        numberBase: [],
        existingNumberBase: '',
        payment: {
            costAmount: "",
            taxAmount: ""
        }
    });
    const [termsAndConditions, setTermsAndConditions] = useState<boolean>(false);
    const [tncError, setTncError] = useState('');
    const [showTnC, setShowTnC] = useState(false);
    const [showTncContent, setShowTncContent] = useState("");

    const { userDetails } = useSelector(({ user }: any) => user);
    const { singleAppDetails, numberList, activeMaskList } = useSelector(({ apps }: any) => apps);
    const { success, loading, error, quickSurveyList, createSurveyResponse, surveyIndividualDetails, surveyUpdateResponse } = useSelector(({ quicksurvey }: any) => quicksurvey);
    const { settlement_types } = useSelector(({ location }: any) => location);

    const routePath = window.location.pathname.split('/')[3];

    const useForceUpdate = () => {
        const [value, setValue] = useState(0);
        return () => setValue(value => value + 1);
    }

    const forceUpdate = useForceUpdate();

    const [validator] = useState(new SimpleReactValidator({
        messages: {
            required: 'You can’t keep this as empty',
            min: 'Minimum length is :min',
            max: 'Maximum length is :max',
        },
        validators: {
            all_mobiles: {
                message: 'The entered :attribute number is invalid.',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^[0]{1}[123456789]{2}\d{7}$/) && params.indexOf(val) === -1
                }
            },
            dialog: {
                message: 'The :attribute is invalid.',
                rule: (val: any, params?: any, validator?: any): boolean => {
                    return validator.helpers.testRegex(val, /^([0]{1}[7]{1}[764]{1}\d{7}|[7]{1}[764]{1}\d{7})$/) && params.indexOf(val) === -1
                }
            },
            reachCount: {
                message: 'The selected payment type/or the number will not be applicable for this lead request.',
                rule: (val: any, params?: any, validator?: any): boolean => !!(val && val <= 50000)
            },
            reachCost: {
                message: 'Value must be greater than zero.',
                rule: (val: any, params?: any, validator?: any): boolean => !!(val > 0)
            }
        },
        autoForceUpdate: this
    }));

    const [questionValidator] = useState(new SimpleReactValidator({
        messages: {
            required: 'You can’t keep this as empty',
            min: 'Minimum length is :min',
            max: 'Maximum length is :max',
            not_regex: 'Unicode characters are not allowed.'
        },
        autoForceUpdate: this
    }));

    const onSurveyNameChange = (e: any) => {
        setSurveyName(e.target.value);
        validator.showMessageFor('survey name');
    }

    const onChangeScheduleType = (e: any) => {
        setScheduleType(e.target.value);
    }

    const onChangeDate = (e: any) => {
        setDate(e);
        // setBtnEnableForResubmit();
    }

    const onChangeDateRange = (e: any) => {
        setDateRange(e);
        // setBtnEnableForResubmit();
    }

    const onSelectNumberBase = (e: any) => {
        console.log(e.target.value)
        setSurveyNumberBase(e.target.value);
        setNumberBaseName("");
        validator.hideMessageFor('Number base name');
    }

    const onNumberbaseNameChange = (e: any) => {
        setNumberBaseName(e.target.value);
        validator.showMessageFor('Number base name');
        forceUpdate();
    }

    const onChangeFile = (data: any, fileInfo: any) => {
        const regexDialog = /^([764]{2}\d{7}|[0764]{3}\d{7})$/;
        const regexNumeric = /^\d+$/;
        var numberList = [];

        var numberBaseError = "";
        let numberObj = { "msisdn": "", "name": "" };

        var arrayData = data.map(function (value: any, index: any) {
            if (Array.isArray(value)) {
                if (value.length > 0) {
                    if (value[0] !== "") {
                        if (value.length === 2) {
                            numberList.push(value);
                            if (value[0].length < 31) {
                                if (value[1].length === 9 || value[1].length === 10) {
                                    numberObj = { "msisdn": "", "name": "" };
                                    numberObj.name = value[0];
                                    numberObj.msisdn = value[1].length === 10 ? value[1].substring(1, 10) : value[1];
                                } else {
                                    setNumberBaseError("Contains invalid mobile numbers. Please check and try again");
                                }
                            } else {
                                setNumberBaseError("Name of the numbers are too lengthy. Please shorten them and try again");
                            }
                        } else {
                            setNumberBaseError("Invalid csv format. Please check the data format and try again");
                        }
                    } else {
                        numberObj = { "msisdn": "", "name": "" };
                    }
                }
            } else {
                setNumberBaseError("Invalid csv format. Please check the data format and try again");
            }
            // console.log(numberList);
            return numberObj;
        });

        let filteredArray = arrayData.filter(function (value: any) {
            if (value !== null) {
                return regexNumeric.test(value.msisdn) === true;
            }
        });

        if (arrayData.length > 0 && filteredArray.length <= 0) {
            setNumberBaseError("Invalid csv format. Please check the data format and try again")
        }

        // Remove duplicate objects
        let msisdns = arrayData.map(function (o: any) {
            if (o !== null) {
                return o.msisdn;
            }
        });

        filteredArray = filteredArray.filter((value: any, index: any) => {
            if (value !== null) {
                return !msisdns.includes(value.msisdn, index + 1)
            }
        })

        // Remove null values
        filteredArray = filteredArray.filter(function (el: any) {
            return el != null;
        });

        // Remove duplicate objects
        filteredArray = filteredArray.filter(function (value: any, index: any, array: any) {
            return array.indexOf(value) == index;
        });

        let sentimentData = newSurveyData;

        if (numberBaseError === "") {
            sentimentData.numberBase = filteredArray;

            setNewSurveyData(sentimentData);
            setNumberBaseError('');
            setNumberBaseLabel(fileInfo.name);
            setNumberListLength(numberList.length);
            setDropdownSelected(true);
        } else {

            sentimentData.numberBase = [];
            setNewSurveyData(sentimentData);
            setNumberBaseLabel(fileInfo.name);
            setNumberListLength(0);
            setDropdownSelected(true);

        }
    }

    const onSelectExistingNumberBase = (e: any) => {
        var sentimentData = newSurveyData;
        sentimentData.existingNumberBase = e.target.value;
        sentimentData.numberBase = [];

        setNewSurveyData(sentimentData);
        setNumberBaseError("");
        setDropdownSelected(true);
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        dispatch(fetchNumberCount(tenantGUID, newSurveyData.existingNumberBase))

    }

    const onQuestionChange = (e: any) => {
        setQuestion(e.target.value);
    }

    const onConfirmationTerms = (e: any) => {
        setTermsAndConditions(e.target.checked);
        setTncError("");
        setSubmitDisabled(false);
    }

    const onShowTnC = () => {
        setShowTnC(true);
        setShowTncContent(singleAppDetails.widgetDetails.termsAndConditions)
    }

    const handleWidgetupdate = () => {
        confirmAlert({
            message: CONSTANTS.QUICKSURVEY_UPDATE,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => onUpdateSentimentData()
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    const onSubmitSurveyData = () => {

        let startTime = null;
        let endTime = null;
        if (scheduleType === 'schedule') {
            startTime = dayjs(dateRange[0]).format("YYYY-MM-DD HH:mm:ss");
            endTime = dayjs(dateRange[1]).format("YYYY-MM-DD HH:mm:ss");
        } else {
            startTime = dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss");
            endTime = dayjs(date).format("YYYY-MM-DD HH:mm:ss");
        }
        var questionList;
        if (singleAppDetails?.widgetDetails !== null) {
            if (singleAppDetails?.widgetDetails.uuid === CONSTANTS.MULTIPLE_QS_DIALOG) {
                questionList = newSurveyData.questionList;
            } else {
                questionList = [
                    { "question": question }
                ];
            }
        }

        var submitData = {
            "isNewNumberBase": surveyNumberBase === 'NEW_NUMBER_BASE' ? true : false,
            "numberBaseId": newSurveyData.existingNumberBase,
            "numberBaseName": numberBaseName,
            "qsOwnerNumber": enterpriseOwnerNumber,
            "numberBase": newSurveyData.numberBase,
            "surveyTitle": surveyName,
            "questionList": questionList,
            "startTime": startTime,
            "endTime": endTime
        };

        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";

        setFormSubmitted(true);
        if (singleAppDetails?.widgetDetails !== null) {
            dispatch(createSurveyData(submitData, tenantGUID, singleAppDetails?.widgetDetails.uuid));
        }
    }

    const onUpdateSentimentData = () => {
        var submitData = {
            "status": surveyStatus === "Inactive" ? "Stop" : statusValue,
            "expiredAt": dayjs(dateRange[1]).format("YYYY-MM-DD HH:mm:ss")
        };

        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        const configId = window.location.pathname.split('/')[CONSTANTS.BASEURLPATHLENGTH + 4];

        if (singleAppDetails?.widgetDetails !== null) {
            dispatch(updateSurveyStatus(tenantGUID, configId, submitData));
        }

    }

    const renderSubmitButton = () => {
        if (AuthHandler.isPerformable(auth, "survey-create") && routePath !== "survey-update") {
            return (
                <Spin tip="" size="small" spinning={loading}>
                    <button onClick={handleWidgetSave} className="btn btn-primary" type="submit"
                        disabled={submitDisabled || loading}>Pay and Send</button>
                </Spin>
            )
        }
        if (AuthHandler.isPerformable(auth, "survey-update") && routePath == "survey-update") {
            return (
                <Spin tip="" size="small" spinning={loading}>
                    <button onClick={handleWidgetupdate} className="btn btn-primary" type="submit" disabled={submitUpdate || loading}>Update</button>
                </Spin>
            )
        }
    }

    useEffect(() => {
        setSettlementTypes(settlement_types);
        if (settlement_types) {
            setPaymentMethodId(settlement_types?.paymentMethodId);
        }
    }, [settlement_types])

    useEffect(() => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        if (tenantGUID && singleAppDetails && singleAppDetails.widgetDetails) {
            dispatch(fetchNumberBase(tenantGUID, singleAppDetails?.widgetDetails?.uuid));
            dispatch(fetchSettlementTyps(tenantGUID, singleAppDetails?.widgetDetails?.uuid))
        }
        if (routePath == "survey-update") {
            const surveyId = window.location.pathname.split('/')[4];
            dispatch(fetchIndividualSurveyDetails(tenantGUID, surveyId))
        }
    }, [userDetails])

    useEffect(() => {
        setTenantNumberBases(numberList);
    }, [numberList])

    useEffect(() => {
        if (createSurveyResponse && createSurveyResponse.statusCode == 200) {
            notification.success({
                message: `Success`,
                description: "New Survey has been added successfully.",
                placement: 'topRight'
            });
            dispatch(quickSurveyStore.actions.surveyDataPostSuccess(null));
            navigate(`/survey/${singleAppDetails?.widgetDetails.uuid}/list`);
        }

    }, [createSurveyResponse]);

    useEffect(() => {
        if (surveyUpdateResponse && surveyUpdateResponse.statusCode == 200) {
            notification.success({
                message: `Success`,
                description: "sentiment has been updated successfully.",
                placement: 'topRight'
            });
            dispatch(quickSurveyStore.actions.surveyUpdateSuccess(null));
            navigate(`/survey/${singleAppDetails?.widgetDetails.uuid}/list`);
        }

    }, [surveyUpdateResponse])

    useEffect(() => {
        if (userDetails) {
            if (userDetails.OwnedEnterprises && userDetails.OwnedEnterprises.length > 0) {
                setEnterpriseName(userDetails.OwnedEnterprises[0].Enterprise.entName);
                setEnterpiseOwnerNumber(userDetails.OwnedEnterprises[0].Enterprise.ownerMobile)
            }
        }
    }, [userDetails])

    useEffect(() => {

        if (surveyIndividualDetails && surveyIndividualDetails != null && routePath == "survey-update") {
            setSurveyName(surveyIndividualDetails.surveyTitle);
            setDateRange([dayjs(surveyIndividualDetails.startTime), dayjs(surveyIndividualDetails.endTime)])
            setScheduleType('schedule');
            setIsView(true);
            setNumberBaseName(surveyIndividualDetails?.NumberBase?.numberBaseName);
            let newSurveyDataObj = newSurveyData;
            newSurveyDataObj.questionList = surveyIndividualDetails?.SurveyDetails;
            setNewSurveyData(newSurveyDataObj);
            setSurveyStatus(surveyIndividualDetails?.status);
            setStatusValue(surveyIndividualDetails?.status)
        }

    }, [surveyIndividualDetails])

    useEffect(() => {
        if (surveyNumberBase === "NEW_NUMBER_BASE") {
            let sentimentData = newSurveyData;
            sentimentData.existingNumberBase = '';
            setNewSurveyData(sentimentData);
            setExistingNumberBaseDisabled(true);
            setNewNumberBaseDisabled(false);
            setNumberBaseNameDisabled(false);
        } else {
            let sentimentData = newSurveyData;
            sentimentData.numberBase = [];
            setNewSurveyData(sentimentData);
            setExistingNumberBaseDisabled(false);
            setNewNumberBaseDisabled(true);
            setNumberBaseNameDisabled(true);
            setNumberBaseLabel('Select CSV');
        }

    }, [surveyNumberBase])

    const handleWidgetSave = async () => {
        let totalCost = 0;
        let finalCost;
        let msgCount = 0;
        if (singleAppDetails?.widgetDetails !== null) {
            if (singleAppDetails?.widgetDetails.uuid === CONSTANTS.MULTIPLE_QS_DIALOG) {


                var questionSepration: any = [];
                msgCount = 0;
                newSurveyData.questionList.map((value, index) => {
                    questionSepration[index] = value.question.length % 125 === 0 ? Math.floor(value.question.length / 125) : Math.floor(value.question.length / 125 + 1);
                    msgCount = msgCount + questionSepration[index];
                });

                if (userDetails?.OwnedEnterprises != null) {
                    if (userDetails?.OwnedEnterprises[0].Enterprise != null) {
                        if (userDetails?.OwnedEnterprises[0].Enterprise.status.toLowerCase() === 'active') {
                            totalCost = singleAppDetails?.widgetDetails !== null ? singleAppDetails?.widgetDetails.chargeAmount * numberListLength * msgCount : 0;
                        } else {
                            let numberListLengthex;
                            if (numberListLength < 5) {
                                numberListLengthex = numberListLength;
                            } else {
                                numberListLengthex = 5;
                            }
                            totalCost = CONSTANTS.DEMO_COST * numberListLengthex * msgCount;
                        }
                    }
                }

                finalCost = totalCost < 1 ? 'Rs ' + totalCost.toFixed(2) + ' (' + Math.floor(totalCost * 100) + ' cents)' : 'Rs ' + totalCost.toFixed(2);
                if (validator.fieldValid('end date') && validator.fieldValid('survey name') && newSurveyData.questionList.length !== 0 && ((newSurveyData.numberBase.length !== 0 && validator.fieldValid('Number base name')) || newSurveyData.existingNumberBase !== '')) {
                    confirmAlert({
                        childrenElement: () => <div>
                            {/* <b className="model_special_text">Total cost for this survey is {finalCost}</b><br/> */}
                            {CONSTANTS.QUICKSURVEY}</div>,

                        buttons: [
                            {
                                label: 'Yes',
                                onClick: () => onSubmitSurveyData()
                            },
                            {
                                label: 'No',
                            }
                        ]
                    });
                } else {
                    validator.showMessageFor('start date');
                    validator.showMessageFor('end date');
                    validator.showMessageFor('survey name');

                    if (surveyNumberBase === "NEW_NUMBER_BASE") {
                        validator.showMessageFor('Number base name');
                    }

                    setSaveSuccess(false);
                    setSaveError(false);
                    setSaveLoading(false);

                    if (newSurveyData.numberBase.length === 0 && newSurveyData.existingNumberBase === '' && numberUploadError === '') {
                        setNumberBaseError("Please select a number base");
                    }
                    if (newSurveyData.questionList.length === 0) {
                        setQuestionListError('No questions added');
                    }
                }

            } else {
                msgCount = question.length % 125 === 0 ? Math.floor(question.length / 125) : Math.floor(question.length / 125 + 1);
                if (userDetails?.OwnedEnterprises !== null) {
                    if (userDetails?.OwnedEnterprises[0].Enterprise !== null) {
                        if (userDetails?.OwnedEnterprises[0].Enterprise.status.toLowerCase() === 'active') {
                            totalCost = singleAppDetails?.widgetDetails !== null ? singleAppDetails?.widgetDetails.chargeAmount * numberListLength * msgCount : 0;
                        } else {
                            let numberListLengthex;
                            if (numberListLength < 5) {
                                numberListLengthex = numberListLength;
                            } else {
                                numberListLengthex = 5;
                            }

                            totalCost = CONSTANTS.DEMO_COST * numberListLengthex * msgCount;
                        }
                    }
                }
                finalCost = totalCost < 1 ? 'Rs ' + totalCost.toFixed(2) + ' (' + Math.floor(totalCost * 100) + ' cents)' : 'Rs ' + totalCost.toFixed(2);
                if (validator.fieldValid('end date') && validator.fieldValid('survey name') && questionValidator.fieldValid('question') && ((newSurveyData.numberBase.length !== 0 && validator.fieldValid('Number base name')) || newSurveyData.existingNumberBase !== '')) {
                    confirmAlert({
                        childrenElement: () => <div>
                            {/*<b className="model_special_text">Total cost for this survey is {finalCost}</b>
                            <br/>*/}
                            {CONSTANTS.QUICKSURVEY}</div>,
                        buttons: [
                            {
                                label: 'Yes',
                                onClick: () => onSubmitSurveyData()
                            },
                            {
                                label: 'No',
                            }
                        ]
                    });
                } else {

                    validator.showMessageFor('start date');
                    validator.showMessageFor('end date');
                    validator.showMessageFor('survey name');
                    questionValidator.showMessageFor('question');

                    if (surveyNumberBase === "NEW_NUMBER_BASE") {
                        validator.showMessageFor('Number base name');
                    }

                    setSaveSuccess(false);
                    setSaveError(false);
                    setSaveLoading(false);

                    if (newSurveyData.numberBase.length === 0 && newSurveyData.existingNumberBase === '' && numberUploadError === '') {
                        setNumberBaseError("Please select a number base");
                    }
                    if (newSurveyData.questionList.length === 0) {
                        setQuestionListError('No questions added');
                    }
                }
            }
        }

    }



    const onAddQuestion = () => {
        if (questionValidator.fieldValid('question')) {
            let newQuestion = {
                question: '',
            };
            newQuestion.question = question;
            const newSentimentDataObj = newSurveyData;
            newSentimentDataObj.questionList.push(newQuestion);
            setNewSurveyData(newSentimentDataObj);
            setQuestion("");
            forceUpdate();
        } else {
            questionValidator.showMessageFor('question');
        }
    }

    const onHideTnC = () => {
        setShowTnC(false);
    }

    const onChangeStatus = (checked: any) => {
        setSurveyStatus(checked == true ? 'Active' : 'Inactive');
        setSubmitUpdate(false);
        forceUpdate()
    }

    const renderOwnersNumber = () => {
        if (paymentMethodId == 1) {
            return (
                <div className="form-element__wrapper mb-3">
                    <label htmlFor="smsSenderName" style={{ paddingRight: '5px' }}>Enterprise owner's number</label>
                    {/* <Tooltip title={<p>Note: The charging number will be the selected number</p>}>
                        <InfoCircleOutlined style={{ color: "#555555" }} />
                    </Tooltip> */}
                    <input
                        type="text"
                        id="alertReceiving"
                        className="form-control form-control-md"
                        value={enterpriseOwnerNumber}
                        placeholder="charging number" readOnly />
                    <small className="validation invalid-feedback">{validator.message('charging number', chargingNumber, 'required')}</small>
                </div>
            )
        }
    }

    const renderQuestionLabel = () => {
        if (newSurveyData && newSurveyData?.questionList?.length > 0) {
            return (
                <div>
                    <label htmlFor="scheduleTime">Added questions</label>
                    <br />
                </div>
            )
        }
    }

    const renderQuestions = () => {
        if (singleAppDetails) {
            if (singleAppDetails.widgetDetails.uuid == CONSTANTS.MULTIPLE_QS_DIALOG) {
                return (
                    <div>
                        {routePath !== "survey-update" && <div className="form-element__wrapper mb-3">
                            <label htmlFor="" className="form-label">Enter Survey questions</label>
                            <div className="inner-multiple-feature-list-element__mc-wrapper horizontal">
                                <div className=" mb-4" style={{ paddingLeft: '0px !important' }}>
                                    <textarea
                                        className="form-control"
                                        id="sentimentQuestion"
                                        // rows="8"
                                        value={question}
                                        onChange={onQuestionChange}
                                        placeholder="How satisfied are you with your current work from home arrangement?
                                                                                A: Very Satisfied
                                                                                B: Somewhat Satisfied
                                                                                C: Neither Satisfied or Dissatisfied
                                                                                D: Somewhat Dissatisfied
                                                                                E: Very Dissatisfied"
                                    >
                                    </textarea>
                                    <div
                                        className="validation invalid-feedback">{questionValidator.message('question', question, 'required|not_regex:[^\u0000-\u00ff]')}</div>
                                </div>
                                <div className="form-check mb-4">
                                    <button type="button" className="btn btn-outline-primary waves-effect"
                                        onClick={onAddQuestion}><i className="fas fa-plus ml-1"></i> Add
                                        new
                                    </button>
                                </div>
                            </div>
                        </div>}
                        <div className="form-element__wrapper mb-3">
                            {renderQuestionLabel()}
                            {newSurveyData?.questionList?.length === 0 &&
                                <label htmlFor="" className="form-label">No questions added yet.</label>
                            }
                            <div className="inner-multiple-feature-list-element__one-wrapper horizontal">
                                {newSurveyData && newSurveyData?.questionList?.length > 0 &&
                                    <div className="section__wrapper">
                                        <div className="card panel content-holder-panels">
                                            <div className="panel-table-listing__wrapper">
                                                <div className="table-parent__wrapper">
                                                    <table className="table table-default">
                                                        <tbody>
                                                            <tr>
                                                                <th className="txt-left">Question ID</th>
                                                                <th className="txt-left">Question</th>
                                                                <th className="txt-left"></th>
                                                            </tr>
                                                            {newSurveyData?.questionList?.map((obj, index) => {
                                                                return (
                                                                    <tr className="table-row " key={index}>
                                                                        <td className="txt-left" scope="row">{index + 1}</td>
                                                                        <td className="txt-left" style={{ padding: "0px !important" }}>{obj.question}</td>
                                                                        {/* <td className="txt-left">
                                                                            <CloseOutlined onClick={() => onDeleteQuestion(index)} />
                                                                        </td> */}
                                                                    </tr>
                                                                );
                                                            })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>

                            <br />
                            <small className="validation invalid-feedback">{questionListError}</small>
                        </div>
                    </div >
                )
            } else {

                return (
                    <div>
                        {routePath !== "survey-update" && <div className="form-element__wrapper mb-3">
                            <label htmlFor="" className="form-label">Enter Survey questions</label>
                            <div className="inner-multiple-feature-list-element__one-wrapper horizontal">
                                <div className=" mb-4" style={{ paddingLeft: '0px !important' }}>
                                    <textarea
                                        className="form-control"
                                        style={{ height: '200px' }}
                                        id="sentimentQuestion"
                                        // rows="8"
                                        value={question}
                                        onChange={onQuestionChange}
                                        placeholder="How satisfied are you with your current work from home arrangement?
                                                                                A: Very Satisfied
                                                                                B: Somewhat Satisfied
                                                                                C: Neither Satisfied or Dissatisfied
                                                                                D: Somewhat Dissatisfied
                                                                                E: Very Dissatisfied"
                                    >
                                    </textarea>
                                    <div
                                        className="validation invalid-feedback">{questionValidator.message('question', question, 'required|not_regex:[^\u0000-\u00ff]')}</div>
                                </div>
                            </div>
                        </div>}
                        <div className="form-element__wrapper mb-3">
                            {renderQuestionLabel()}
                            {/* {newSurveyData?.questionList?.length === 0 &&
                                <label htmlFor="" className="form-label">No questions added yet.</label>
                            } */}
                            <div className="inner-multiple-feature-list-element__one-wrapper horizontal">
                                {newSurveyData && newSurveyData?.questionList?.length > 0 &&
                                    <div className="section__wrapper">
                                        <div className="card panel content-holder-panels">
                                            <div className="panel-table-listing__wrapper">
                                                <div className="table-parent__wrapper">
                                                    <table className="table table-default">
                                                        <tbody>
                                                            <tr>
                                                                <th className="txt-left">Question ID</th>
                                                                <th className="txt-left">Question</th>
                                                                <th className="txt-left"></th>
                                                            </tr>
                                                            {newSurveyData?.questionList?.map((obj, index) => {
                                                                return (
                                                                    <tr className="table-row " key={index}>
                                                                        <td className="txt-left" scope="row">{index + 1}</td>
                                                                        <td className="txt-left" style={{ padding: "0px !important" }}>{obj.question}</td>
                                                                        {/* <td className="txt-left">
                                                                            <CloseOutlined onClick={() => onDeleteQuestion(index)} />
                                                                        </td> */}
                                                                    </tr>
                                                                );
                                                            })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>

                            <br />
                            <small className="validation invalid-feedback">{questionListError}</small>
                        </div>
                    </div>
                )

            }
        }


    }

    if (AuthHandler.isAuthorized(auth, 'add-new-sentiment') && userDetails !== null) {
        if (userDetails) {
            return (
                <React.Fragment>
                    <div className="main-content main-spacer-x-y">
                        <PageHeaderStatic
                            page={'create'}
                        />
                        <div className="card added-app-thumbnnail__wrapper">
                            <div
                                className="added-app-thumbnail__inner"
                                style={{
                                    backgroundImage: `url(${(singleAppDetails && singleAppDetails.widgetDetails !== null) ? singleAppDetails.widgetDetails.leftImage : ""})`
                                }}
                            ></div>
                        </div>
                        <div className="card panel content-holder-panels added-app-form__wrapper">
                            <div className="panel-header">
                                <div className="panel-header-content__wrapper">
                                    <h4 className="panel-header-title">Create a Quick Survey</h4>
                                </div>
                            </div>
                            <div className="panel-content">
                                <div className="wiz-content-form__wrapper">
                                    <p>
                                        {(singleAppDetails && singleAppDetails.widgetDetails !== null) ? singleAppDetails.widgetDetails.description : ""}
                                    </p>
                                    <br />
                                    <div className="form-group__wrapper">
                                        <div className="form-group">
                                            <div className="form-element__wrapper mb-3">
                                                <label htmlFor="" className="form-label">
                                                    SMS sender name<span aria-label="required"></span>
                                                </label>
                                                <input
                                                    type="text"
                                                    id="tile"
                                                    className="form-control"
                                                    value={enterpriseName}
                                                    placeholder="SMS sender name"
                                                    disabled={true}
                                                />
                                            </div>
                                            {renderOwnersNumber()}
                                            <div className="form-element__wrapper mb-3">
                                                <label htmlFor="" className="form-label">
                                                    Survey Name<span aria-label="required"></span>
                                                </label>
                                                <input
                                                    type="text"
                                                    id="tile"
                                                    className="form-control"
                                                    value={surveyName}
                                                    placeholder="Name of the survey"
                                                    onChange={onSurveyNameChange}
                                                    disabled={routePath == "survey-update"}
                                                />
                                                <div
                                                    className="validation invalid-feedback">{validator.message('survey name', surveyName, ['required', 'alpha_num_dash_space', { max: 100 }])}</div>
                                            </div>
                                            <div className="form-element__wrapper mb-3">
                                                <label htmlFor="" className="form-label">
                                                    Select sentiment date-time<span aria-label="required"></span>
                                                </label>
                                                <div className="inner-multiple-feature-list-element__mc-wrapper horizontal">
                                                    <div className="form-check mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="now"
                                                            id="radio1"
                                                            value="now"
                                                            onChange={onChangeScheduleType}
                                                            checked={scheduleType === "now"}
                                                            disabled={routePath == "survey-update"}
                                                        />&nbsp;
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="flexCheckDefault"
                                                        >
                                                            Start Now
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="schedule"
                                                            id="radio1"
                                                            value="schedule"
                                                            // value={JSON.stringify(subWidget)}
                                                            onChange={onChangeScheduleType}
                                                            checked={scheduleType === "schedule"}
                                                            disabled={routePath == "survey-update"}
                                                        />&nbsp;
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="flexCheckDefault"
                                                        >
                                                            Schedule
                                                        </label>
                                                    </div>
                                                </div>
                                                {scheduleType === "now" ? <div className="form-group">
                                                    <div className="form-element__wrapper mb-3">
                                                        <label htmlFor="" className="form-label">
                                                            End date/time<span aria-label="required"></span>
                                                        </label>
                                                        {(date && date != '') ? <DatePicker
                                                            disabled={fieldDisabled || routePath == "survey-update"}
                                                            className="gx-mb-3 gx-w-100"
                                                            format="YYYY-MM-DD HH:mm:ss"
                                                            style={{ width: "100%" }}
                                                            defaultValue={dayjs(date)}
                                                            disabledDate={(current) => {
                                                                return current && current.valueOf() < Date.now();
                                                            }}
                                                            showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
                                                            onChange={onChangeDate}
                                                        /> : <DatePicker
                                                            disabled={fieldDisabled || routePath == "survey-update"}
                                                            className="gx-mb-3 gx-w-100"
                                                            format="YYYY-MM-DD HH:mm:ss"
                                                            style={{ width: "100%" }}
                                                            disabledDate={(current) => {
                                                                return current && current.valueOf() < Date.now();
                                                            }}
                                                            showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
                                                            onChange={onChangeDate}
                                                        />}
                                                        <div
                                                            className="validation invalid-feedback">{validator.message('end date', date, 'required')}</div>
                                                    </div>
                                                </div> : scheduleType === "schedule" ? <div className="form-group">
                                                    <div className="form-element__wrapper mb-3">
                                                        <label htmlFor="" className="form-label">
                                                            Start - End date/time<span aria-label="required"></span>
                                                        </label>
                                                        {(dateRange && dateRange.length > 0) ? <RangePicker
                                                            disabled={fieldDisabled || routePath == "survey-update"}
                                                            className="gx-mb-3 gx-w-100"
                                                            format="YYYY-MM-DD HH:mm:ss"
                                                            style={{ width: "100%" }}
                                                            value={[dayjs(dateRange[0]), dayjs(dateRange[1])]}
                                                            disabledDate={(current) => {
                                                                return dayjs().add(-1, 'days') >= current
                                                            }}
                                                            showTime={{
                                                                defaultValue: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')]
                                                            }}
                                                            onChange={onChangeDateRange}
                                                        /> : <RangePicker
                                                            disabled={fieldDisabled || routePath == "survey-update"}
                                                            className="gx-mb-3 gx-w-100"
                                                            format="YYYY-MM-DD HH:mm:ss"
                                                            style={{ width: "100%" }}
                                                            disabledDate={(current) => {
                                                                return dayjs().add(-1, 'days') >= current
                                                            }}
                                                            showTime={{
                                                                defaultValue: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')]
                                                            }}
                                                            onChange={onChangeDateRange}
                                                        />}
                                                        <div
                                                            className="validation invalid-feedback">{validator.message('end date', dateRange, 'required')}</div>
                                                    </div>
                                                </div> : <></>}
                                            </div>
                                            {routePath !== "survey-update" && <NumberbaseInput
                                                mainLabel={"Select number base"}
                                                numberBase={surveyNumberBase}
                                                newNumberBase={newNumberBase}
                                                onSelectNumberBase={onSelectNumberBase}
                                                existingNumberBase={existingNumberBase}
                                                numberBaseName={numberBaseName}
                                                onNumberbaseNameChange={onNumberbaseNameChange}
                                                numberBaseNameDisabled={numberBaseNameDisabled}
                                                numberBaseNameError={validator.message('Number base name', numberBaseName, 'required|max:30')}
                                                inputLoading={inputLoading}
                                                onChangeFile={onChangeFile}
                                                numberBaseLabel={numberBaseLabel}
                                                newNumberBaseDisabled={newNumberBaseDisabled}
                                                numberUploadError={""}
                                                onSelectExistingNumberBase={onSelectExistingNumberBase}
                                                existingNumberBaseDisabled={existingNumberBaseDisabled}
                                                tenantNumberBases={tenantNumberBases}
                                                numberBaseError={numberBaseError}
                                                all_operators={false}
                                            />}
                                            <br></br>
                                            {routePath == "survey-update" && <div className="form-element__wrapper mb-3">
                                                <label htmlFor="" className="form-label">
                                                    Number Base Name<span aria-label="required"></span>
                                                </label>
                                                <input
                                                    type="text"
                                                    id="tile"
                                                    className="form-control"
                                                    value={numberBaseName}
                                                    placeholder="Name of the sentiment"
                                                    disabled={routePath == "survey-update"}
                                                />
                                            </div>}
                                            {renderQuestions()}
                                            {routePath !== "survey-update" && <div className="form-group__wrapper">
                                                <div className="form-group">
                                                    <div className="form-element__wrapper mb-3">
                                                        <input className="form-check-input" type="checkbox"
                                                            checked={termsAndConditions}
                                                            id="terms-acceptence"
                                                            onChange={onConfirmationTerms} />&nbsp;&nbsp;
                                                        <label className="form-check-label"
                                                            htmlFor="terms-acceptence">
                                                            I agree to <a onClick={onShowTnC}>Terms and conditions</a>
                                                        </label>
                                                    </div>
                                                    <div className="validation invalid-feedback">{tncError}</div>
                                                </div>
                                            </div>}
                                            {routePath == "survey-update" && <div className="form-element__wrapper mb-3">

                                                <div className="inner-multiple-feature-list-element__mc-wrapper horizontal">
                                                    <div className="form-check mb-4">
                                                        <label htmlFor="" className="form-check-label">
                                                            Survey Status - {surveyStatus ? surveyStatus : ""}<span aria-label="required"></span>
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-4">
                                                        <Switch
                                                            onChange={onChangeStatus}
                                                            className="sentiment-toggle"
                                                            checked={surveyStatus === 'Active' ? true : surveyStatus === 'Running' ? true : false}
                                                            disabled={statusValue === 'Active' ? false : statusValue === 'Running' ? false : true}
                                                            defaultChecked />
                                                    </div>
                                                </div>
                                            </div>}
                                            <div className="panel-bottom-btn__wrapper">
                                                <button className="btn btn-secondary" onClick={() => { navigate(-1) }}>Cancel</button>
                                                {renderSubmitButton()}
                                            </div>
                                            <Modal
                                                title="Terms and Conditions"
                                                open={showTnC}
                                                onCancel={onHideTnC}
                                                footer={null}
                                                style={{ minWidth: 800 }}
                                            >
                                                <div style={{ maxHeight: '350px', overflowY: "auto" }}>
                                                    <div className="scroller__wrapper">
                                                        <div className="terms__content">
                                                            <p><b>{showTncContent}</b></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Modal>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        } else {
            return (
                <div className="section__wrapper">
                    <div className="empty-state-new-action__wrapper">
                        <div className="new-action-content__wrapper">
                            <h4 className="sub">Oops, something went wrong!</h4>
                        </div>
                    </div>
                </div>
            );
        }

    } else return (<React.Fragment></React.Fragment>);
}

export default AddNewQuickSurvey;