import { Form, Radio, Switch } from 'antd';
import React, { useEffect, useState, FocusEvent } from 'react';
import { useCollapse } from "react-collapsed";
import { Modal } from '../../../components';
import { useSelector } from 'react-redux';

interface Props {
    widget: any,
    onChangeMethod: (event: any) => void,
    msisdns: any[],
    cards: any[],
    setMsisdnList: (msisdns: any) => void,
    setDisplayMsisdnModal: (msisdn: any) => void,
    setActiveMsisdn: (msisdn: string) => void,
    activeMsisdn: string,
    setActiveCard: (card: any) => void,
    activeCard: any,
    setDisplayCardModal: (card: any) => void,
    setCardList: (cards: any) => void,
    activeMsisdnObj: any,
    setActiveMsisdnObj: (values: any) => void,
    activeCardObj: any,
    setActiveCardObj: (values: any) => void,
    paymentMethod: number,
    paymentError: string
}

const PaymentMethod = ({ widget, onChangeMethod, msisdns, cards, setMsisdnList, setDisplayMsisdnModal, setActiveMsisdn, activeMsisdn, setActiveCard, activeCard, setDisplayCardModal, setCardList, activeMsisdnObj, setActiveMsisdnObj, activeCardObj, setActiveCardObj, paymentMethod, paymentError }: Props) => {
    const geniePaymentsDisabled = true;
    const { userDetails } = useSelector<any, any>(({ user }) => user);
    const [number, setNumber] = useState<string>('');
    const [maskCardNo, setMaskCardNo] = useState<string>('');
    const [invoiceNo, setInvoiceNo] = useState<string>('');

    const setMsisdn = () => {
        if (Array.isArray(msisdns)) {
            const foundMsisdn = msisdns.find((value) => value.id === widget.WidgetSubscriptions.msisdnId);
            if (foundMsisdn) {
                setActiveMsisdn(foundMsisdn.msisdn);
                setNumber(foundMsisdn.msisdn)
            }
        }
    }

    const setCards = () => {
        if (Array.isArray(cards)) {
            const foundCard = cards.find((value) => value.id === widget.WidgetSubscriptions.cardId);
            if (foundCard) {
                setActiveCard(foundCard)
                setMaskCardNo(foundCard.maskCardNo)
                setInvoiceNo(foundCard.invoiceNo)
            }
        }
        return null;
    }

    useEffect(() => {
        setMsisdn();
        setCards();
    }, []);

    return (
        <React.Fragment>
            {userDetails && userDetails.OwnedEnterprises[0] && userDetails.OwnedEnterprises[0].Enterprise && userDetails.OwnedEnterprises[0].Enterprise.billingStatus !== 'Invoice' &&
                <div className="panel-inner-feature-content__wrapper">
                    <span className="panel-inner-feature-content-title">
                        Payment
                    </span>
                    <div className="panel-inner-feature-content">
                        <p>
                            Default selected payment method will be the payment method of this
                            app. Feel free to change
                        </p>
                        <div className="form-group__wrapper">
                            <Form.Item name="paymentMethod">
                                <Radio.Group>
                                    {widget.widgetPaymentMethods.map((value: any, index: number) => {
                                        if (value.paymentMethodId == 1) {
                                            return (
                                                <Radio value={1}>
                                                    <div className="form-group">
                                                        <div className="form-element__wrapper mb-3">
                                                            <label htmlFor="" className="form-label">
                                                                Add to bill
                                                            </label>
                                                            <div className="form-check radio-outline-multi-selector__wrapper">
                                                                <div className=" radio-outline-multi-selector-inner">
                                                                    {/* <div className="radio-outline-multi-selector-header">
                                                                        <input
                                                                            className="form-check-input secondary"
                                                                            type="radio"
                                                                            name="paymentOption"
                                                                            id="add_to_bill"
                                                                            value="add_to_bill"
                                                                            checked
                                                                        />
                                                                        <label className="form-check-label" htmlFor="radio1">
                                                                            Selected
                                                                        </label>
                                                                    </div> */}
                                                                    <div style={{margin: 10}}><span>{paymentMethod ===  1 ? "Selected" : "Not selected"}</span></div>
                                                                    <div className="radio-outline-multi-selector-content billing-and-plans-payement-radio">
                                                                        <div className="radio-outline-multi-selector-content__wrapper">
                                                                            <span>
                                                                                <span className="icon-img__wrapper">
                                                                                    <i className="bi bi-phone-fill" />
                                                                                </span>
                                                                                {(activeMsisdnObj && activeMsisdnObj.widgetUuid === widget.uuid && activeMsisdnObj.msisdn) ? activeMsisdnObj.msisdn : number}
                                                                            </span>
                                                                        </div>
                                                                        <div className="radio-outline-multi-selector-content__wrapper">
                                                                            <a onClick={() => {
                                                                                setMsisdnList(msisdns);
                                                                                setDisplayMsisdnModal(true)
                                                                                setActiveMsisdnObj({
                                                                                    widgetUuid: widget.uuid,
                                                                                    msisdn: (activeMsisdnObj && activeMsisdnObj.widgetUuid === widget.uuid && activeMsisdnObj.msisdn) ? activeMsisdnObj.msisdn : number
                                                                                });
                                                                            }}>Change</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Radio>
                                            )
                                        }
                                        if (value.paymentMethodId == 9) {
                                            return (
                                                <Radio value={9} disabled={geniePaymentsDisabled}>
                                                    <div className="form-group">
                                                        <div className="form-element__wrapper mb-3" style={{
                                                            cursor: geniePaymentsDisabled ? "not-allowed" : "pointer", opacity: geniePaymentsDisabled ? 0.8 : 1
                                                        }}>
                                                            <label htmlFor="" className="form-label">
                                                                Card payment via Genie
                                                            </label>
                                                            <div className="form-check radio-outline-multi-selector__wrapper">
                                                                <div className=" radio-outline-multi-selector-inner">
                                                                    <div style={{margin: 10}}><span>{paymentMethod ===  9 ? "Selected" : "Not selected"}</span></div>
                                                                    <div className="radio-outline-multi-selector-content billing-and-plans-payement-radio">
                                                                        <div className="radio-outline-multi-selector-content__wrapper">
                                                                            <span>
                                                                                <span className="icon-img__wrapper">
                                                                                    <img
                                                                                        src={require('../../../assets/images/icons/genie.svg').default}
                                                                                        alt=""
                                                                                        srcSet=""
                                                                                    />
                                                                                </span>
                                                                                {(activeCardObj && activeCardObj.widgetUuid === widget.uuid && activeCardObj.invoiceNo) ? cards.find((value) => value.invoiceNo === activeCardObj.invoiceNo).maskCardNo : maskCardNo}
                                                                            </span>
                                                                        </div>
                                                                        <div className="radio-outline-multi-selector-content__wrapper">
                                                                            <a
                                                                                onClick={() => {
                                                                                    setCardList(cards);
                                                                                    setDisplayCardModal(true)
                                                                                    setActiveCardObj({
                                                                                        widgetUuid: widget.uuid,
                                                                                        invoiceNo: (activeCardObj && activeCardObj.widgetUuid === widget.uuid && activeCardObj.invoiceNo) ? activeCardObj.invoiceNo : invoiceNo
                                                                                    });
                                                                                }}
                                                                                style={{pointerEvents: geniePaymentsDisabled ? "none" : "auto"}}
                                                                            >Change</a>
                                                                        </div>
                                                                    </div>
                                                                    {geniePaymentsDisabled && <span>This payment method will be available soon.</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Radio>
                                            )
                                        }

                                        // if (value.paymentMethodId == 1) {
                                        //     return (
                                        //         <div className="form-group">
                                        //             <div className="form-element__wrapper mb-3">
                                        //                 <label htmlFor="" className="form-label">
                                        //                     Add to bill
                                        //                 </label>
                                        //                 <div className="form-check radio-outline-multi-selector__wrapper active">
                                        //                     <div className=" radio-outline-multi-selector-inner">
                                        //                         <div className="radio-outline-multi-selector-header">
                                        //                             <input
                                        //                                 className="form-check-input"
                                        //                                 type="radio"
                                        //                                 name=""
                                        //                                 id="radio1"
                                        //                                 value={1}
                                        //                                 onChange={onChangeMethod}
                                        //                                 checked={widget.WidgetSubscriptions.paymentMethodId == 1}
                                        //                             />
                                        //                             <label
                                        //                                 className="form-check-label"
                                        //                                 htmlFor="radio1"
                                        //                             >
                                        //                                 {widget.WidgetSubscriptions.paymentMethodId == 1 ? 'Selected' : 'Not Selected'}
                                        //                             </label>
                                        //                         </div>
                                        //                         <div className="radio-outline-multi-selector-content">
                                        //                             <div className="radio-outline-multi-selector-content__wrapper">
                                        //                                 <span>
                                        //                                     <span className="icon-img__wrapper">
                                        //                                         <i className="bi bi-phone-fill" />
                                        //                                     </span>
                                        //                                     777333999
                                        //                                 </span>
                                        //                             </div>
                                        //                             <div className="radio-outline-multi-selector-content__wrapper">
                                        //                                 <a href="">Change</a>
                                        //                             </div>
                                        //                         </div>
                                        //                     </div>
                                        //                 </div>
                                        //             </div>
                                        //         </div>
                                        //     )
                                        // }
                                        // if (value.paymentMethodId == 9) {
                                        //     return (
                                        //         <div className="form-group">
                                        //             <div className="form-element__wrapper mb-3">
                                        //                 <label htmlFor="" className="form-label">
                                        //                     Card payment via Genie
                                        //                 </label>
                                        //                 <div className="form-check radio-outline-multi-selector__wrapper">
                                        //                     <div className=" radio-outline-multi-selector-inner">
                                        //                         <div className="radio-outline-multi-selector-header">
                                        //                             <input
                                        //                                 className="form-check-input"
                                        //                                 type="radio"
                                        //                                 name=""
                                        //                                 id="radio1"
                                        //                                 value={9}
                                        //                                 onChange={onChangeMethod}
                                        //                                 checked={widget.WidgetSubscriptions.paymentMethodId == 9}
                                        //                             />
                                        //                             <label
                                        //                                 className="form-check-label"
                                        //                                 htmlFor="radio1"
                                        //                             >
                                        //                                 {widget.WidgetSubscriptions.paymentMethodId == 9 ? 'Selected' : 'Not Selected'}

                                        //                             </label>
                                        //                         </div>
                                        //                         <div className="radio-outline-multi-selector-content">
                                        //                             <div className="radio-outline-multi-selector-content__wrapper">
                                        //                                 <span>
                                        //                                     <span className="icon-img__wrapper">
                                        //                                         <img
                                        //                                             src="/src/assets/images/icons/payment-icon-visa.svg"
                                        //                                             alt=""
                                        //                                             srcSet=""
                                        //                                         />
                                        //                                     </span>
                                        //                                     •••• 2345
                                        //                                 </span>
                                        //                             </div>
                                        //                             <div className="radio-outline-multi-selector-content__wrapper">
                                        //                                 <a href="">Change</a>
                                        //                             </div>
                                        //                         </div>
                                        //                     </div>
                                        //                 </div>
                                        //             </div>
                                        //         </div>
                                        //     )
                                        // }
                                    })}
                                </Radio.Group>
                            </Form.Item>
                        </div>
                        <div className="validation invalid-feedback">{paymentError}</div>
                    </div>
                </div>}
        </React.Fragment>
    );
}

export { PaymentMethod };