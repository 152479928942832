import { SlideshowLightbox } from 'lightbox.js-react'
import React, { useEffect, useRef, useState } from 'react'

type Props = { imageFile: string, images: Array<any> }

const SliderListingItem = ({ imageFile, images }: Props) => {

    let [isOpen, setIsOpen] = useState(false);

    const transformedImages = images.map(({ image }) => ({ src: image }));

    return (
        <>
            <SlideshowLightbox
                showThumbnails={true}
                images={transformedImages}
                open={isOpen}
                lightboxIdentifier="lbox1"
                onClose={() => { setIsOpen(false) }}>
            </SlideshowLightbox>
            <div className="slider-listing-item__wrapper" onClick={() => { setIsOpen(true) }} style={{ cursor: 'pointer' }}>
                <a>
                    <div className="slider-listing-item">
                        <div className="slider-item-content__wrapper">
                            <div className="slider-item-content__wrapper">
                                <div className="card slider-thumbnail-holder" style={{ backgroundImage: `url(${imageFile ? imageFile : require('../../../assets/images/icons/app-thumbail-placeholder.webp')})` }}>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </>
    )
}

export { SliderListingItem }