import React, { useEffect } from 'react'

type Props = {}

const AccountCreationSuccess = (props: Props) => {
    useEffect (()=>{
        setTimeout(() => {
            // navigate("/");
            window.location.href = "/";
          }, 3500)
        })
    return (
        <main className="account-creation ac-status">
            <section className="ac-status__wrapper">
                <div className="ac-status-animation__wrapper">
                    {/* <div className="loader-5 center"><span></span></div> */}
                    <div className="circle-loader load-complete">
                        <div className="checkmark draw" />
                    </div>
                </div>
                <h2 className="title"> <span>Success!</span><br /><span>Your account creation has been completed</span></h2>
                <p className="sub-title">Redirecting to Marketplace Sign in Screen....</p>
            </section>
            {/* success message status update*/}
            {/* status js lib -- 
            https://codepen.io/alimuzzaman/pen/LyoyPj
        */}
        </main>
    )
}

export { AccountCreationSuccess }