import React, { Component } from "react";
// import CryptoJS from "crypto-js";
import { connect } from 'react-redux';
// import { confirmAlert } from 'react-confirm-alert';
// import { fetchVoteNomineesForCustomer, fetchSentimentWidget, postVoteCustomerResponses,postUserDetailAndGetPIN } from "../actions";
import * as constants from "../../constants";
import { passwordResetValidatePin,passwordResetInitiatePin,passwordReset } from '../../services/user-password-reset.service';
import SimpleReactValidator from 'simple-react-validator';
import {toast, ToastContainer} from "react-toastify";
import { message, notification } from "antd";
import MapLocation from "../apps/where-are-my-employees-with-concent/MapLocation";


class UserPasswordReset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            screen: 'customer-vote',
            auth: props.auth,
            voteId: null,
            msisdn: null,
            respondedCount: 0,
            userResponses: [],
            selectedEmoji: {},
            voteTitle: '',
            userNameOrEmail: '',
            password: null,
            confirmPassword: null,
            deactivateConfirmPasswordField: true,
            submitPasswordEnterButton: false,
            trackerOtpReadOnly: false,
            requestPinButtonDeactivated: true,
            verifyPinButtonDeactivated: true,
            otpCountDownHidden: true,
            trackerOtpSuccess: null,
            trackerServerRef: null,
            trackerOtp: '',
            locationPath:window.location.pathname.split('/')[constants.BASEURLPATHLENGTH+2],
            trackerOtpLoading: false,
            keyCloakUserId: null,
            message: null,
            time: {},
            seconds: 119,
            userInputFieldDeactive:false
        };

        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);

        this.validator = new SimpleReactValidator({
            messages: {
                required: 'You can’t keep this as empty',
                min: 'Minimum length is :min',
                max: 'Maximum length is :max',
            },
            validators: {
                all_mobiles: {
                    message: 'The entered :attribute number is invalid.',
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(val, /^[0]{1}[123456789]{2}\d{7}$/) && params.indexOf(val) === -1
                    },
                    autoForceUpdate: this
                },
                dialog: {
                    message: 'The :attribute is invalid.',
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(val, /^[0]{1}[7]{1}[764]{1}\d{7}$/) && params.indexOf(val) === -1
                    },
                    autoForceUpdate: this
                },
                alpha_num_underscore: {
                    message: 'The :attribute is invalid.',
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(val, /^[a-zA-Z0-9_]*$/) && params.indexOf(val) === -1
                    },
                    autoForceUpdate: this
                },
                lowerCase: {
                    message: 'Password must include lowercase value',
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(val, /^(?=.*[a-z])/) && params.indexOf(val) === -1
                    },
                    autoForceUpdate: this
                },
                upperCase: {
                    message: 'Password must include uppercase value',
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(val, /^(?=.*[A-Z])/) && params.indexOf(val) === -1
                    },
                    autoForceUpdate: this
                },
                numberValue: {
                    message: 'Must include at least one special character',
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(val, /^(?=.*[!@#$%^&*._-])/) && params.indexOf(val) === -1
                    },
                    autoForceUpdate: this
                },
                specialCharacter: {
                    message: 'Must include at least one number',
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(val, /^(?=.*[0-9])/) && params.indexOf(val) === -1
                    },
                    autoForceUpdate: this
                },
                spaceValidate: {
                    message: 'Spaces are not allowed',
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(val, /^[^\s]{1,}$/) && params.indexOf(val) === -1
                    },
                    autoForceUpdate: this
                }
            },
            autoForceUpdate: this
        });
        this.longNumberValidator = new SimpleReactValidator({
            messages: {
                required: 'You can’t keep this as empty.',
                min: 'This should not be less than :min:type.',
                max: 'Maximum :max:type are allowed.',
                email: 'This does not look like an email.',
            },
            validators: {
                all_mobiles: {
                    message: 'The entered :attribute is invalid.',
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(val, /^[0]{1}[123456789]{2}\d{7}$/) && params.indexOf(val) === -1
                    },
                    autoForceUpdate: this
                }
            },
            autoForceUpdate: this
        });

        this.otpTextValidator = new SimpleReactValidator({
            messages: {
                required: 'You can’t keep this as empty',
                min: 'Minimum length is :min',
                max: 'Maximum length is :max',
            },
            autoForceUpdate: this
        });


    };



    componentDidMount() {
        let timeLeftVar = this.secondsToTime(this.state.seconds);
        this.setState({
            time: timeLeftVar,
            // locationPath:window.location.pathname.split('/')[constants.BASEURLPATHLENGTH]
        });
    };
    secondsToTime(secs){
        // let hours = Math.floor(secs / (60 * 60));
        //
        // let divisor_for_minutes = secs % (60 * 60);
        // let minutes = Math.floor(divisor_for_minutes / 60);
        //
        // let divisor_for_seconds = divisor_for_minutes % 60;
        // let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            // "h": hours,
            // "m": minutes,
            "s": secs
        };
        return obj;
    }
    startTimer(value) {
        if (value == null && this.timer == 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }else if (value !==null){
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
            requestPinButtonDeactivated:true
        });

        // Check if we're at zero.
        if (seconds == 0) {
            clearInterval(this.timer);
            this.setState({
                requestPinButtonDeactivated: false,
                verifyPinButtonDeactivated: true,
            });
        }
    }

    onUserNameOrEmailChange = (e) => {
        this.setState({
            userNameOrEmail: e.target.value,
            requestPinButtonDeactivated: false
        }, function () {
            this.validator.showMessageFor('userName or email');
            this.forceUpdate();
        });
    }

    enterPassword = (e) => {
        this.setState({
            password: e.target.value,
            deactivateConfirmPasswordField:false
        }, function () {
            this.validator.showMessageFor('password field');
            this.forceUpdate();
        });
    }

    confirmUserPassword = (e) => {
        this.setState({
            confirmPassword: e.target.value,
            submitPasswordEnterButton: true,
        }, function () {
            this.validator.showMessageFor('confirm password field');
            this.forceUpdate();
        })

    }

    renderSendPin() {
        if (this.state.trackerOtpSuccess === null || this.state.trackerOtpSuccess === false){
            return (
                <div className="row wow fadeIn">
                    <div className="col-md-12 mb-4" >
                        {/*<div className="row">*/}
                        {/*    <div className="container center-the-element">*/}
                        {/*        <div className="col-md-3">*/}
                        {/*            <p className="caption">User Name Or Email</p>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}

                        {/*</div>*/}
                        <div className="row">

                            <div className="col-md-12" >
                                <p className="caption">User Name Or Email</p>
                                <input
                                    type="text"
                                    id="trackerNumber"
                                    className="form-control form-control-md"
                                    value={this.state.userNameOrEmail}
                                    onChange={this.onUserNameOrEmailChange}
                                    placeholder="UserName or Email"
                                    disabled={this.state.userInputFieldDeactive}
                                    // disabled={true}
                                    // readOnly={this.state.trackerOtpReadOnly}
                                />
                                <small
                                    className="errorText">{this.validator.message('userName or email', this.state.userNameOrEmail, 'required|min:3|max:100')}
                                </small>
                            </div>

                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-md-12" style={{ marginTop: '-4px' }}>
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={this.onSendPINAndVerifyUser}
                                    hidden={this.state.trackerOtpSuccess === true ? true : false || this.state.requestPinButtonDeactivated}
                                >
                                    Request PIN
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

    }
    onSendPINAndVerifyUser = async () => {
        let data = {
            method:"ANC",
            msisdn:""
        }
        passwordResetInitiatePin(data,this.state.userNameOrEmail).then((data) => {
            if (data.status === 200 && data.data) {
                console.log('ffffffffffffffffff',data)
                this.setState({
                    trackerServerRef: data.data.referenceId,
                    keyCloakUserId: data.data.keyCloakUserId,
                    message: data.data.message,
                    verifyPinButtonDeactivated: false,
                    otpCountDownHidden: false,
                    seconds: 119,
                    userInputFieldDeactive:true,

                });

                this.startTimer(119);
                // toast.success(this.state.message, {
                //     onClose: async () => {
                //     }
                // });
                notification.success({
                    message: `SUCCESS`,
                    description: data.data.message,
                    placement: 'top'
                });
            }else{
                notification.error({
                    message: `ERROR`,
                    description: 'OTP Sending Failed.Please Insert Correct Your Username or Email',
                    placement: 'top'
                });
                // toast.error('OTP Sending Failed.Please Insert Correct Your Username or Email', {
                //     onClose: async () => {
                //     }
                // });
            }
        });
    }

    onPasswordChange = async () => {
        let data = {
            uuid:this.state.keyCloakUserId,
            password:this.state.confirmPassword
        }
        if (this.state.confirmPassword ==null || this.state.password == null){
            // toast.warning("Please Input your password in Both fields", {
            //     onClose: async () => {
            //     }
            // });
            notification.warning({
                message: `Warning`,
                description: "Please Input your password in Both fields",
                placement: 'top'
            });
        }else{
            if (this.validator.fieldValid("confirm password field") && this.validator.fieldValid("password field")){
                if (this.state.keyCloakUserId !==null && this.state.confirmPassword === this.state.password){
                    passwordReset(data).then((data) => {
                        if (data.status === 200) {
                            this.setState({

                            });
                            notification.success({
                                message: `SUCCESS`,
                                description: "Password Change Success",
                                placement: 'top',
                                onClose: async () => {
                                    if (this.state.locationPath === 'market-place-portal-ui'){
                                        window.location.replace(constants.REVAMP_HOST_URL)
                                    }else{
                                        window.location.replace(constants.HOST_URL)
                                    }

                                }
                            });

                            // toast.success("Password Change Success", {
                            //     onClose: async () => {
                            //         if (this.state.locationPath === 'market-place-portal-ui'){
                            //             window.location.replace(constants.REVAMP_HOST_URL)
                            //         }else{
                            //             window.location.replace(constants.HOST_URL)
                            //         }
                            //
                            //     }
                            // });
                        }
                        // this.setState({ searchResult: data.data });
                    });
                }else{
                    notification.warning({
                        message: `Warning`,
                        description: "Passwords Do not match",
                        placement: 'top'
                    });

                    // toast.warning("Passwords Do not match", {
                    //     onClose: async () => {
                    //     }
                    // });
                }
            }else{
                notification.warning({
                    message: `Warning`,
                    description: "There are Errors in the form.",
                    placement: 'top'
                });

                // toast.warning("There are Errors in the form.", {
                //     onClose: async () => {
                //     }
                // });
            }
        }
    }

    passwordEnter() {
        if (this.state.trackerOtpSuccess === null || this.state.trackerOtpSuccess === false ){
            return (
                <>
                </>
            )
        }else if(this.state.trackerOtpSuccess === true){
            return (
                <div className="row wow fadeIn">
                    <div className="col-md-12 mb-4">
                        <div className="row">
                            <div className="col-md-12">
                                <p className="caption">Enter Your Password</p>
                                <input
                                    // type="text"
                                    id="trackerNumber"
                                    className="form-control form-control-md"
                                    value={this.state.password}
                                    onChange={this.enterPassword}
                                    type="password"
                                    placeholder="Enter Password"
                                />
                                <small
                                    className="errorText">{this.validator.message('password field', this.state.password, 'required|spaceValidate|lowerCase|upperCase|numberValue|specialCharacter|min:8|max:30')}
                                </small>
                            </div>

                        </div>
                        <br/>
                        <div className="row">

                            <div className="col-md-12">
                                <p className="caption">Re Enter Your Password</p>
                                <input
                                    // type="text"
                                    id="trackerNumber"
                                    className="form-control form-control-md"
                                    value={this.state.confirmPassword}
                                    onChange={this.confirmUserPassword}
                                    type="password"
                                    placeholder="Confirm Password"
                                    disabled={this.state.deactivateConfirmPasswordField}
                                />
                                <small
                                    className="errorText">{this.validator.message('confirm password field', this.state.confirmPassword, 'required|spaceValidate|lowerCase|upperCase|numberValue|specialCharacter|min:8|max:30')}
                                </small>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12" style={{ marginTop: '-4px' }}>
                            <button
                                className="btn btn-primary btn-sm"
                                onClick={this.onPasswordChange}
                                disabled={this.state.trackerOtpSuccess == null ? true : false}
                                // disabled={this.state.submitPasswordEnterButton === true ? true : false}
                            >
                                Reset
                            </button>
                        </div>
                    </div>
                </div>
            )
        }

    }

    onTrackerOtpChange = (e) => {
        this.setState({
            trackerOtp: e.target.value,
            trackerOtpError: ''
        });
    }

    renderTrackerOtpButton() {
        if (this.state.trackerOtpLoading) {
            return (
                <button className="btn btn-success btn-sm btn-transparent">
                    <span className="spinner-border spinner-border-sm spinner-color" role="status"
                          aria-hidden="true"></span>
                </button>
            );
        } else {
            if (this.state.trackerOtpSuccess === null || this.state.trackerOtpSuccess === false) {
                return (
                    <button
                        style={{
                            backgroundColor:'#4E286D',
                            borderColor:'#4E286D',
                            color:'white'
                        }}
                        className="btn btn-success btn-sm"
                        onClick={this.onVerifyTrackerOtp}
                        hidden={this.state.verifyPinButtonDeactivated}
                    >
                        Verify
                    </button>
                );
            } else if (this.state.trackerOtpSuccess === true) {
                return (
                    <button className="btn btn-success btn-sm btn-transparent">
                        <i className="fa fa-check otp-success"></i>
                    </button>
                );
            }
        }
    }

    verifyPin() {
        if (this.state.trackerOtpSuccess === null || this.state.trackerOtpSuccess === false){
            return (
                <div className="row wow fadeIn">
                    <div className="col-md-12 mb-4">
                        <div className="row">

                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <p className="caption">Enter OTP received</p>
                                <input
                                    type="text"
                                    id="otp"
                                    className="form-control form-control-md"
                                    value={this.state.trackerOtp}
                                    onChange={this.onTrackerOtpChange}
                                    placeholder="OTP"
                                    readOnly={this.state.trackerOtpReadOnly}
                                />
                                <small
                                    className="errorText" hidden={this.state.otpCountDownHidden}>{this.state.time.s} S</small>
                                <small
                                    className="errorText">{this.otpTextValidator.message("otp for the tracker's number", this.state.trackerOtp, 'required')}</small>
                            </div>

                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-md-12" style={{ marginTop: '-4px' }}>
                                {this.renderTrackerOtpButton()}
                            </div>
                        </div>

                    </div>
                </div>
            )
        }

    }

    onVerifyTrackerOtp = async () => {
        await this.setState({ trackerOtpError: '' });
        let verifiedData = {
            pin:this.state.trackerOtp,
            serverRef:this.state.trackerServerRef
        }
        if (this.otpTextValidator.fieldValid("otp for the tracker's number")) {
            this.setState({ trackerOtpLoading: true, trackerOtpError: '' }, function () {
                const tenantGUID = window.location.pathname.split('/')[constants.BASEURLPATHLENGTH + 2];
                passwordResetValidatePin(verifiedData).then((data) => {
                    // console.log("Child otp: " + JSON.stringify(data));
                    this.setState({
                        trackerOtpLoading: false
                    }, function () {
                        if (data.data.statusCode === "SUCCESS") {
                            this.setState({
                                trackerOtpSuccess: true,
                                trackerOtpError: '',
                                trackerOtpReadOnly: true,
                                otpCountDownHidden:true
                            });
                        } else if (typeof data.data.statusCode === 'undefined') {
                            this.setState({
                                trackerOtpSuccess: false,
                                trackerOtpError: 'Please enter a valid OTP',
                                trackerOtpReadOnly: false
                            });
                            notification.error({
                                message: `ERROR`,
                                description: 'Please enter a valid OTP',
                                placement: 'top'
                            });
                            // toast.error('Please enter a valid OTP', {
                            //     onClose: async () => {
                            //     }
                            // });
                        }
                    });
                }).catch(error => {
                    // // console.log("Child num error: " + JSON.stringify(error.response.data.error));
                    if (error !== null) {
                        if (error.response !== null) {
                            if (error.response.data !== null) {
                                if (error.response.data.error === "Wrong Pin") {
                                    notification.error({
                                        message: `ERROR`,
                                        description: 'Please enter a valid OTP',
                                        placement: 'top'
                                    });

                                    // toast.error('Please enter a valid OTP', {
                                    //     onClose: async () => {
                                    //     }
                                    // });
                                    this.setState({
                                        trackerOtpLoading: false,
                                        trackerOtpSuccess: false,
                                        trackerOtpError: 'Please enter a valid OTP',
                                        trackerOtpReadOnly: false
                                    });
                                } else {
                                    notification.error({
                                        message: `ERROR`,
                                        description: 'Oops, something went wrong',
                                        placement: 'top'
                                    });

                                    // toast.error('Oops, something went wrong', {
                                    //     onClose: async () => {
                                    //     }
                                    // });
                                    this.setState({
                                        trackerOtpLoading: false,
                                        trackerOtpSuccess: false,
                                        trackerOtpError: 'Oops, something went wrong',
                                        trackerOtpReadOnly: false
                                    });
                                }
                            } else {
                                notification.error({
                                    message: `ERROR`,
                                    description: 'Oops, something went wrong',
                                    placement: 'top'
                                });

                                // toast.error('Oops, something went wrong', {
                                //     onClose: async () => {
                                //     }
                                // });
                                this.setState({
                                    trackerOtpLoading: false,
                                    trackerOtpSuccess: false,
                                    trackerOtpError: 'Oops, something went wrong',
                                    trackerOtpReadOnly: false
                                });
                            }
                        }
                    }
                });
            });
        } else {
            this.otpTextValidator.showMessageFor("otp for the tracker's number");
            this.forceUpdate();
        }
    }

    render() {
        return (

            <>
                <div className="container main">
                    {/*main content wrapper*/}
                    <div class="main__wrapper col" style={{marginTop:"20%"}}>
                        <div className="row">
                            <div className="col-md-12 mb-4">
                                <ToastContainer
                                    position="top-center"
                                    autoClose={1500}
                                />
                                <div className="mt-3">
                                    <h4 style={{textAlign:"center"}}>Password Change</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mb-4" style={{maxWidth:'20rem',marginInline:'Auto'}}>
                            <div className="mt-3">
                                {this.renderSendPin()}
                                {this.verifyPin()}
                                {this.passwordEnter()}
                            </div>
                        </div>

                    </div>
                    {/*/ main content wrapper*/}
                    {/*footer*/}

                    {/*/footer*/}
                </div>
                <footer className="main__footer container ">
                    <div className="row ">
                        <div className="col-md-12 no-padding " style={{textAlign:'center'}}>
                                    <span className="copy-rights ">
                                        {/*svg*/}
                                        {/*<div className="svg__wrapper ">*/}
                                        {/*    <img src={require("../../assets/images/mp-primary-logo.svg")}*/}
                                        {/*         className=""*/}
                                        {/*         alt=""/>*/}
                                        {/*</div>*/}
                                        {/*/svg*/}
                                        {/*© 2023 Copyright. Engineered by Ideamart*/}
                                    </span>
                        </div>
                    </div>
                </footer>
            </>
        )
    }
};


export default UserPasswordReset;

// const mapStateToProps = state => {
//     return {
//         votenomineeDetails: state.vote.votenomineeDetails,
//         votedata: state.vote.votedata,
//         customerResponseSuccess: state.vote.customerResponseSuccess,
//         customerResponseLoading: state.vote.customerResponseLoading,
//         customerResponseError: state.vote.customerResponseError,
//         customerResponseSubmittedMessage: state.vote.customerResponseSubmittedMessage,
//         sentimentWidgetError: state.sentiment.sentimentWidgetError,
//         ReferenceData: state.passwordreset.ReferenceData,
//     }
// }
// export default connect(mapStateToProps, {
//     fetchVoteNomineesForCustomer,
//     fetchSentimentWidget,
//     postVoteCustomerResponses,
//     postUserDetailAndGetPIN
// })(UserPasswordReset);
