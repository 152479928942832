import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { SlideshowLightbox } from 'lightbox.js-react';
import { useAppDispatch } from '../../../store';
import { SimilarApps, SingleAppModel, WidgetFeatures, WidgetRate, WidgetScreenshots, offerDetails, offerList } from '../models/response/single-app.model';
import { InnerTopFeatureItem } from './InnerTopFeatureItem';
import { SingleAppWidget } from './SingleAppWidget';
import { SliderListingItem } from './SliderListingItem';
import { RateListComponent } from './RateListComponent';
import { UserDetailsModel } from '../../home/models/response/user-details.model';
import AuthHandler from '../../../helper/AuthHandler';
import NoSimilarApps from '../../../components/NoSimilarApps';
import GeneratingLeadsPopup from '../../../components/GeneratingLeadsPopup';

type Props = {
  appDetails: SingleAppModel | null,
  userDetails: UserDetailsModel | null,
  onBack: () => void,
  onSubscribe: () => void,
  wid: string | undefined,
  campaignUuid: any,
  onNavigateTo: () => void,
  offerDescription: string | null,
  offer: boolean | null
  getOfferDescription: () => string
}

const AppDetails = ({ appDetails, onBack, onSubscribe, wid, campaignUuid, onNavigateTo, userDetails, getOfferDescription, offerDescription, offer 
}: Props) => {

  const navigate = useNavigate();

  const {
    authData
  } = useSelector<any, any>(({ authentication }) => authentication);

  function formatValue(value: number, step: number) {
    if (value < step) {
      return value.toString();
    }

    const level = Math.floor(value / step);
    const formattedValue = level * step + '+';

    return formattedValue;
  }

  return (
    appDetails &&
    <div className="main-content">
      {(campaignUuid != '' && campaignUuid != null && campaignUuid != 'undefined') ? 
        <GeneratingLeadsPopup campaignUuid={campaignUuid}/>
      : ""
      }
      <div className="inner-top-feature-holder" style={{ backgroundImage: `url(${appDetails.widgetDetails ? appDetails.widgetDetails.leftImage : ""})` }} aria-label="">
        <div className="container">
          <button className="btn back-btn" aria-label="Back to the previous page" onClick={onBack}> <span />Go back</button>
          <div className="inner-feature-content">
            <h1 className="app-title" itemProp="name" aria-label="App title">{appDetails.widgetDetails ? appDetails.widgetDetails.title : ''}</h1>
            <span className="app-developer">by<a href="" aria-label="App developer">Marketplace</a></span>
            <div className="app-stats__container inner-top">
              <div className="right-content__wrapper">
                <div className="card app-media-holder" style={{ backgroundImage: `url(${require('../../../assets/images/mp-symbol.png')})` }}></div>
              </div>
              <div className="left-content__wrapper">
                <div className="app-stats__wrapper">
                  <ul className="app-stats-list">
                    {/* Below Removed Temporarily */}
                    {/* <li className="app-stats-item">
                      <div className="app-stats-inner__wrapper">
                        <span className="stats-icon"><i className="bi bi-download" /></span>
                        <span className="stats-value">{formatValue(appDetails ? appDetails.subscribedCount : 0, 100)}</span>
                      </div>
                      <div className="app-stats-inner__wrapper">
                        <span className="stats-desc">Install</span>
                      </div>
                    </li> */}
                    {/* <li className="app-stats-item">
                                        <div className="app-stats-inner__wrapper">
                                            <span className="stats-icon ico-highlighter"><i className="bi bi-star-fill" /></span>
                                            <span className="stats-value">4.8</span>
                                        </div>
                                        <div className="app-stats-inner__wrapper">
                                            <span className="stats-desc">Reviews</span>
                                        </div>
                                        </li> */}
                    {/* <li className="app-stats-item">
                                        <div className="app-stats-inner__wrapper">
                                            <span className="stats-value text-gradient-highlighter">80%
                                            match+</span>
                                        </div>
                                        <div className="app-stats-inner__wrapper">
                                            <span className="stats-desc">Your business type</span> <button className="btn btn-info btn-round btn-xsmall"><i className="bi bi-info-circle-fill" /></button>
                                        </div>
                                        </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="app-call-to-action__wrapper">
              {/* <span aria-label="Price">Price - Pay as you go</span><button className="btn btn-info btn-round btn-xsmall" aria-label="Click  to learn more informations"><i className="bi bi-info-circle-fill" /></button> */}
              {(AuthHandler.isPerformable(authData, 'app-subscribe') && userDetails?.role != 'mp_content_generator') ?
                <div className="call-to-action-btn-group__wrapper">
                  {userDetails && userDetails.OwnedEnterprises[0] && userDetails.OwnedEnterprises[0].Enterprise?.status === 'Active' && <button className="btn btn-primary" onClick={onSubscribe} disabled={appDetails.subscriptionStatus === "Subscribed" || appDetails.subscriptionStatus === "Pending"}>{appDetails.subscriptionStatus === "Pending" ? 'Pending approval' : 'Subscribe'}</button>}
                  {appDetails && appDetails.widgetDetails && appDetails.subscriptionStatus === "Subscribed" && <button className="btn btn-secondary" onClick={() => onNavigateTo()}>Open app</button>}
                </div> :
                (userDetails?.role != 'mp_content_generator') &&
                <div className="call-to-action-btn-group__wrapper">
                  <button className="btn btn-primary" disabled={true}>Subscribe</button>
                  <span> Please login or signup to subscribe app.</span>
                </div>
              }
              {/* <button className="btn btn-outline-red light-shade btn-round btn-video-playback" id="btnVideoPlayBack"><i className="bi bi-play-fill" />&nbsp; Play trailer
                video</button> */}
            </div>
            {offer && appDetails && appDetails.widgetDetails && appDetails.subscriptionStatus === "Not Subscribed" &&
              <div className="banner-container">
                <div className="banner-icon">
                  {/* <div className="banner-star"> */}
                  <div className="banner-percentage">%</div>
                  </div>
                {/* </div> */}
                <div className="banner-text">
                  <p className="banner-title">OFFER</p>
                  <p className="banner-subtitle">{offerDescription}</p>
                </div>
              </div>
            }
            {/* {getOfferDescription()} */}
          </div>
        </div>
      </div>
      <div className="inner-top-feature-list" style={{ paddingTop: '82px' }}>
        <div className="container">
          {appDetails.widgetDetails && Array.isArray(appDetails.widgetDetails.widgetFeatures) && appDetails.widgetDetails.widgetFeatures.map((value: WidgetFeatures, index: number) => {
            return (
              <InnerTopFeatureItem
                key={index}
                title={value.title}
                description={value.description}
                image={value.icon}
              />
            )
          })}
        </div>
      </div>
      {appDetails.widgetDetails && Array.isArray(appDetails.widgetDetails.widgetScreenshots) && appDetails.widgetDetails.widgetScreenshots.length > 0 && <div className="container inner-container">
        <div className="listing-slider-carousel">
          <div className="listing-slider-control-icons ">
            <button className="btn"><i className="bi bi-chevron-left" /></button>
            <button className="btn"><i className="bi bi-chevron-right" /></button>
          </div>
          <div className="listing-slider__wrapper dragscroll">
            {/* <SlideshowLightbox showThumbnails={true}> */}
            {appDetails.widgetDetails && Array.isArray(appDetails.widgetDetails.widgetScreenshots) && appDetails.widgetDetails.widgetScreenshots.map((value: WidgetScreenshots, index: number) => {
              return (
                <SliderListingItem
                  imageFile={value.image}
                  images={appDetails.widgetDetails.widgetScreenshots}
                />
              )
            })}
            {/* </SlideshowLightbox> */}
          </div>
        </div>
      </div>}
      <div className="container inner-container">
        {/* Below Removed Temporarily */}
        {/* {(appDetails.widgetDetails && Array.isArray(appDetails.widgetDetails.widgetUpdates) && appDetails.widgetDetails.widgetUpdates.length > 0) && <div className="inner-content-section__wrapper">
          <div className="inner-content-header">
            <h4 className="inner-content-title" aria-label="">What's New</h4>
          </div>
          <h5 className="inner-content-subtitle" aria-label="Sub-title">{appDetails.widgetDetails.widgetUpdates[0].title}</h5>
          <div className="content-description__wrapper">
            <p aria-label="content-description">
              {appDetails.widgetDetails.widgetUpdates[0].description}
            </p>
          </div>
        </div>} */}
        <div className="inner-content-section__wrapper">
          <div className="inner-content-header">
            <h4 className="inner-content-title" aria-label="">About the app</h4>
          </div>
          <div className="card app-media-holder" style={{ backgroundImage: `url(${appDetails.widgetDetails ? appDetails.widgetDetails.icon : require('../../../assets/images/mp-symbol.png')})` }}>
          </div>
          <h5 className="inner-content-subtitle" aria-label="Sub-title">{appDetails.widgetDetails ? appDetails.widgetDetails.title : ''}</h5>
          <div className="content-description__wrapper">
            <p aria-label="content-description">
              {appDetails.widgetDetails ? appDetails.widgetDetails.description : ''}
            </p>
            {/* <p aria-label="content-description">Version - 1.45</p>
            <a href="">Show more</a> */}
          </div>
          {/* <div className="tag-pill__wrapper">
            <button className="btn btn-default tag-pill">#1 Top app campaign management</button>
            <button className="btn btn-default tag-pill">Category type 1</button>
            <button className="btn btn-default tag-pill">#4 2022 Best App Award</button>
            <button className="btn btn-default tag-pill">Campaign Managemet</button>
          </div> */}
        </div>
        {/* <div className="inner-content-section__wrapper">
          <div className="inner-content-header">
            <h4 className="inner-content-title" aria-label="">Support/ Developer contact</h4>
          </div>
          <div className="rich-list-grid__wrapper">
            <div className="content-desciption inner-content-list__wrapper">
              <h5 className="inner-content-subtitle" aria-label="Sub-title">How to get support</h5>
              <ul className="inner-content-list rich-list">
                <li className="inner-list-item rich-list-item">
                  <a href="" aria-label="Get Support from the developer">
                    <div className="rich-list-item__wrapper">
                      <span className="inner-list-item-icon green-shade">
                        <i className="bi bi-life-preserver" />
                      </span>
                      <span className="inner-list-content">
                        <span className="inner-list-item-title">Contact by form</span>
                        <span>about how developers declare sharing</span>
                      </span>
                    </div>
                  </a>
                </li>
                <li className="inner-list-item rich-list-item">
                  <a href="" aria-label="Get Support from the developer">
                    <div className="rich-list-item__wrapper">
                      <span className="inner-list-item-icon yellow-shade">
                        <i className="bi bi-envelope-fill" />
                      </span>
                      <span className="inner-list-content">
                        <span className="inner-list-item-title">Get Support</span>
                        <span>about how developers declare sharing</span>
                      </span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div className="content-desciption inner-content-list__wrapper">
              <h5 className="inner-content-subtitle" aria-label="Sub-title">Resources</h5>
              <ul className="inner-content-list rich-list">
                <li className="inner-list-item rich-list-item">
                  <a href="" aria-label="Get Support from the developer">
                    <div className="rich-list-item__wrapper">
                      <span className="inner-list-item-icon orange-shade">
                        <i className="bi bi-question-circle-fill" />
                      </span>
                      <span className="inner-list-content">
                        <span className="inner-list-item-title">FAQ</span>
                        <span>https://sietratechnologies.com/app-id/faq</span>
                      </span>
                    </div>
                  </a>
                </li>
                <li className="inner-list-item rich-list-item">
                  <a href="" aria-label="Get Support from the developer">
                    <div className="rich-list-item__wrapper">
                      <span className="inner-list-item-icon pink-shade">
                        <i className="bi bi-shield-fill-check" />
                      </span>
                      <span className="inner-list-content">
                        <span className="inner-list-item-title">Privacy policy</span>
                        <span>https://sietratechnologies.lk/privacy-policy</span>
                      </span>
                    </div>
                  </a>
                </li>
                <li className="inner-list-item rich-list-item">
                  <a href="" aria-label="Get Support from the developer">
                    <div className="rich-list-item__wrapper">
                      <span className="inner-list-item-icon blue-shade">
                        <i className="bi bi-globe2" />
                      </span>
                      <span className="inner-list-content">
                        <span className="inner-list-item-title">Visit the website</span>
                        <span>https://sientratechnologies.lk</span>
                      </span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div> */}
        {appDetails.widgetDetails && Array.isArray(appDetails.widgetDetails.WidgetRate) && appDetails.widgetDetails.WidgetRate.length > 0 && <div className="inner-content-section__wrapper">
          <div className="inner-content-header" id="pricing">
            <h4 className="inner-content-title" aria-label="">
              Pricing
              {/* <a className="btn btn-outline btn-round" type="button" role="button">
                View all
                <span className="arrow-head right" />
              </a> */}
            </h4>
          </div>
          <div className="content-desciption inner-content-list__wrapper bleed bg-white">
            <img
              src="/src/assets/images/arrow-pointer2.svg"
              alt=""
              className="pointer-arrow"
            />
            <div className=" flex-centered">
              {/* <div className="btn-group__wrapper">
                <button className="btn btn-outline-secondary active">
                  All
                </button>
                <button className="btn btn-outline-secondary">Per Day</button>
                <button className="btn btn-outline-secondary">Per Month</button>
                <button className="btn btn-outline-secondary">Per Year</button>
                <button className="btn btn-outline-secondary">Per Request</button>
              </div> */}
            </div>
            <div> {/* bg-yellow bg-orange bg-black bg-pink bg-primary */}
              <RateListComponent
                appDetails={appDetails}
              />
            </div>
          </div>
        </div>}
        {/* <div className="inner-content-section__wrapper">
          <div className="inner-content-header">
            <h4 className="inner-content-title" aria-label="">Data Safety<a className="btn btn-outline btn-round" type="button" role="button">More<span className="arrow-head right" /></a></h4>
          </div>
          <div className="content-desciption inner-content-list__wrapper bordered">
            <ul className="inner-content-list">
              <li className="inner-list-item">
                <span className="inner-list-item-icon"><i className="bi bi-share-fill" />&nbsp;</span>
                <span className="inner-list-content">
                  <span className="inner-list-title">No data shared with third parties</span>
                  <span><a href="">Learn more </a>about how developers declare sharing
                  </span>
                </span>
              </li>
              <li className="inner-list-item">
                <span className="inner-list-item-icon"><i className="bi bi-cloud-upload-fill" />&nbsp;</span>
                <span className="inner-list-content">
                  <span className="inner-list-title">Only save in proted cloud </span>
                  <span><a href="">Learn more </a>about how developers declare sharing</span>
                </span>
              </li>
              <li className="inner-list-item">
                <span className="inner-list-item-icon"><i className="bi bi-cash-coin" />&nbsp;</span>
                <span className="inner-list-content">
                  <span className="inner-list-title">No payment make without consent </span>
                  <span><a href="">Learn more </a>about how developers declare paymennt &amp; pricing</span>
                </span>
              </li>
              <li className="inner-list-item">
                <span className="inner-list-item-icon"><i className="bi bi-file-earmark-lock2" />&nbsp;</span>
                <span className="inner-list-content">Data keep as encripted </span></li>
            </ul>
            <a href="http://" target="_blank" rel="noopener noreferrer">Read full privacy and data
              safety policy</a>
          </div>
        </div> */}
      </div>
      <div className=" app-listing-section__wrapper type-4 full-bleed__wrapper light">
        <div className="container full-bleed ">
          <div className="app-listing-section-title__wrapper" >
            <span>Similar Apps</span>
          </div>
          {Array.isArray(appDetails.similarApps) && appDetails.similarApps.length > 0 ?
            <div className="app-listing-section type-4-inner">
              {/*{Array.isArray(appDetails.similarApps) && ((appDetails.similarApps).filter((item:any)=> item.featured == true)).map((value: SimilarApps, index: number) => {*/}
              {Array.isArray(appDetails.similarApps) && (appDetails.similarApps).map((value: SimilarApps, index: number) => {
                return (
                  <SingleAppWidget
                    widgetUuid={value.uuid}
                    image={value.thumbnail}
                    icon={value.icon}
                    title={value.title}
                    description={value.description}
                  />
                )
              })}
            </div>
            :
            "Currently, there are no other applications similar to this app"
          }

        </div>
      </div>
    </div>
  )
}

export { AppDetails };