/* eslint-disable import/no-anonymous-default-export */
export default {
    'home': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user', 'mp_content_generator'],
    'dynamin-widget': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user', 'mp_content_generator'],
    'profile': ['mp_user', 'mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user','mp_content_generator'],
    'marketing-level-landing': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'portfolio-summary': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent'],
    'expense-charts': ['mp_enterprise_owner'],
    'revenue-charts': ['mp_marketing_admin', 'mp_common_agent', 'mp_dialog_agent'],
    'profile-selection-modal': ['mp_user'],
    'total-expense-summary-table': ['mp_enterprise_owner'],
    'total-revenue-summary-table': ['mp_marketing_admin', 'mp_common_agent', 'mp_dialog_agent'],
    'expense-summary-by-enterprise-table': ['mp_enterprise_owner'],
    'revenue-summary-by-enterprise-table': ['mp_marketing_admin', 'mp_common_agent', 'mp_dialog_agent'],
    'expense-details': ['mp_enterprise_owner'],
    'revenue-details': ['mp_marketing_admin', 'mp_common_agent', 'mp_dialog_agent'],
    'total-revenue-summary': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent'],
    'revenue-enterprise': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent'],
    'revenue-share': ['mp_enterprise_owner'],
    'revenue-detail': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent'],
    'ent_sub_widgets': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'tracklist': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'manage-campaign-config-list': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'manage-campaign-status-workflow': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'add-location-track': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'individual-location-details': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'marketing-level-landing-static': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent'],
    'track-history': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'edit-profile': ['mp_dialog_agent', 'mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_enterprise_user'],
    'marketing-agent-editable': ['mp_marketing_admin', 'mp_common_agent', 'mp_dialog_agent'],
    'enterprise-owner-editable': ['mp_enterprise_owner', 'mp_marketing_admin', 'mp_common_agent', 'mp_dialog_agent', 'mp_enterprise_user'],
    'settings': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent'],
    'create-enterprise': ['mp_enterprise_owner'],
    'create-demo-enterprise': ['mp_marketing_admin', 'mp_common_agent', 'mp_dialog_agent'],
    'add-new-quick-survey': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'quick-survey': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'quick-survey-details': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'quick-survey-history': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'map-location': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'ent-profile': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent'],
    'sms-configs-list': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'sms-keywords-list': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'add-sms-configs': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'sms-mo-list': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'sms-mo-stat': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'sms-sessions-list': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'add-location-ondemand': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'location-ondemand-history': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'location-ondemand-list': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'new-quick-campaign': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'quick-campaign': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'quick-campaign-status': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'quick-campaign-details': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'number_base_list': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent'],
    'esms-landing': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'own-enterprises': ['mp_enterprise_owner'],
    'user-enterprises': ['mp_enterprise_user'],
    'demo-enterprises': ['mp_marketing_admin', 'mp_common_agent', 'mp_dialog_agent'],
    'marketing-enterprises': ['mp_marketing_admin', 'mp_common_agent', 'mp_dialog_agent'],
    'bank-details': ['mp_marketing_admin', 'mp_common_agent', 'mp_dialog_agent'],
    'ma-confirm-message': ['mp_marketing_admin', 'mp_common_agent', 'mp_dialog_agent'],
    'owner-confirm-message': ['mp_enterprise_owner'],
    'employee-attendance': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'add-employee-attendance': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'employee-attendance-details': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'employee-attendance-logs': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'add-ezcash-account': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'ezcash-account-list': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'genie-list': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'add-genie-account': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'geofence-list': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'geofence-details': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'sentiment-face-2-list': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'add-new-sentiment': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'sentiment-details': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'sentiment-history': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'customer-sentiment': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'add-location-mapping': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'mappinglist': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'location-mapping-details': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'location-mapping-logs': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'quick-pass-scanned-details': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'port-update': ['mp_enterprise_admin', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_user'],
    'lng-num-update': ['mp_enterprise_admin', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_user'],
    'add-new-quick-survey-all-operators': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'quick-survey-all-operators': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'quick-survey-details-all-operators': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'quick-survey-history-all-operators': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'cold-room-monitoring': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'add-cold-room-monitoring': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'view-cold-room-monitoring': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'power-and-energy-monitoring': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'add-power-and-energy-monitoring': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'view-power-and-energy-monitoring': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'environment-monitoring': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'add-environment-monitoring': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'view-environment-monitoring': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'weighbridge-solution': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'add-weighbridge-solution': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'view-weighbridge-solution': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'water-metering': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'add-water-metering': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'view-water-metering': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'server-room-monitoring': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'add-server-room-monitoring': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'view-server-room-monitoring': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'smart-fleet': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'add-smart-fleet': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'view-smart-fleet': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'smart-home-solution': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'add-smart-home-solution': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'view-smart-home-solution': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'cctv-solution': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'add-cctv-solution': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'view-cctv-solution': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'wifi-solution': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'add-wifi-solution': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'view-wifi-solution': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'add-special-data-packs': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'view-special-data-packs': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'special-data-packs': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'crbt-individual-tone': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'add-crbt-individual-tone': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'view-crbt-individual-tone': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'update-crbt-individual-tone': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'crbt-enterprise-tone': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'add-crbt-enterprise-tone': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'view-crbt-enterprise-tone': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'update-crbt-enterprise-tone': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'crbt-upload-tone': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'dynamic-widget': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'create-widget': ['mp_admin','mp_content_generator'],
    'normal-agreement': ['mp_enterprise_owner'],
    'demo-agreement': ['mp_marketing_admin', 'mp_common_agent', 'mp_dialog_agent'],
    'long-number-view': ['mp_enterprise_admin', 'mp_enterprise_owner'],
    'ent-profile-edit': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent'],
    'add-manage-campaign': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'marketing-agent-profile-edit': ['mp_marketing_admin', 'mp_common_agent'],
    'manage-agent-list': ['mp_enterprise_admin', 'mp_enterprise_owner', 'mp_marketing_admin', 'mp_common_agent'],
    'add-broadband-for-business': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'view-broadband-for-business': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'add-WIFI-for-business': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'add-ILL-and-VPN': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'add-voice-line': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'view-voice-line': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'create-hpax-solutions': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'view-hpax-solutions': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'add-agent-account': ['mp_enterprise_admin', 'mp_enterprise_owner', 'mp_marketing_admin', 'mp_common_agent'],
    'add-corporate-data-on-any-broadband': ['mp_enterprise_admin', 'mp_enterprise_owner', 'mp_marketing_admin', 'mp_common_agent'],
    'view-corporate-data-on-any-broadband': ['mp_enterprise_admin', 'mp_enterprise_owner', 'mp_marketing_admin', 'mp_common_agent'],
    'add-biz-pack': ['mp_enterprise_admin', 'mp_enterprise_owner', 'mp_marketing_admin', 'mp_common_agent'],
    'view-biz-pack': ['mp_enterprise_admin', 'mp_enterprise_owner', 'mp_marketing_admin', 'mp_common_agent'],
    'add-scope': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'view-scope': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'customize-lead-widgets': ['mp_content_generator'],
    'create-commom-lead-widgets': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'view-common-lead-widgets': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'metaverse': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'add-metaverse-event': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'metaverse-event-details': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'meta-verse-advertiser-details': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'meta-verse-wizard': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'meta-verse-advertiser-space-info': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'add-marketing-agent-details': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_dialog_agent'],
    'signup-button': ['mp_user', 'mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user', 'mp_content_generator'],
    'esms-signup': ['mp_enterprise_admin', 'mp_enterprise_owner', 'mp_enterprise_user'],
    'app-subscribe': ['mp_enterprise_admin', 'mp_enterprise_owner', 'mp_enterprise_user', 'mp_user'],
    'business-details': ['mp_enterprise_owner'],
    'add-manage-campaign-config': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'owner-dashboard':['mp_enterprise_owner'],
    'partner-dashboard':['mp_common_agent','mp_marketing_admin', 'mp_dialog_agent'],
    'social-media-config-list': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'add-social-media-config': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'gws-config-list': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'google-workspace': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'gws-config-domain': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'gws-config-view': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'biz-email-config-list': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'add-biz-email-config': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'fixed-bb-config-list': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_common_agent', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    // 'fixed-bb-workspace': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'fixed-bb-create-config': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
    'fixed-bb-view-config': ['mp_enterprise_admin', 'mp_marketing_admin', 'mp_enterprise_owner', 'mp_dialog_agent', 'mp_enterprise_user'],
}