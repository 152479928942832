import React, { useEffect, useState } from 'react'
import { getProfileTypes } from '../../../services/user.service';
import * as Constants from '../../../constants';
type Props = {
  status: string,
  roles: string[],
  setAccountType: (accountType: string) => void,
  progress: number
}

const UserRoleSelection = ({ roles, setAccountType, status, progress }: Props) => {

  const [roleDetails, setRoleDetails] = useState([]);
  const [selecedRole, setSelectedRole] = useState('mp_enterprise_owner');

  const onRoleSelection = () => {
    console.log("RoleClicked");
    alert("role clicked");
  }

  const handleRoleTypeSelection = (roleType: string) => {
    if (progress == 25) {
      setSelectedRole(roleType);
    }
  };

  useEffect(() => {
    setAccountType(selecedRole);
  }, [selecedRole])

  useEffect(() => {
    setAccountType(selecedRole);
    //dispatch get role details

    //setRoleDetails
  }, [])

  useEffect(() => {
    getProfileTypes().then((response: any) => {
      setRoleDetails(response.data.data);
    }).catch((error: any) => {
      setRoleDetails([]);
    });
  }, [])

  useEffect(() => {
    // setSelectedRole(userDetails && userDetails.role ? userDetails.role : 'mp_enterprise_owner' );
    if (roles && Array.isArray(roles) && roles.length > 0 ) {
      setSelectedRole(roles.includes("mp_enterprise_owner") ? "mp_enterprise_owner" : roles.includes("mp_common_agent") ? ("mp_common_agent") :roles.includes("mp_content_generator") ? ("mp_content_generator") :"");
    }
  }, [roles])

  return (
      <div className="wiz-content-form__wrapper">
        <div className="profile-type-selection__wrapper">
          {/* {roleDetails.length > 0 && ( */}
          <div className="profile-type-selection__inner">
            {roleDetails && roleDetails.map((item: any, index: number) => (
                <div className="profile-type-selection-item" onClick={() => handleRoleTypeSelection(item.role)} key={index} aria-disabled={(item.role != "mp_enterprise_owner" && item.role != "mp_common_agent" && item.role != "mp_content_generator")} style={{
                  cursor: (item.role != "mp_enterprise_owner" && item.role != "mp_common_agent" && item.role != "mp_content_generator") ? "not-allowed" : "pointer",
                  opacity: (item.role != "mp_enterprise_owner" && item.role != "mp_common_agent" && item.role != "mp_content_generator") ? 0.5 : 1,
                }}>
                  <div className={`profile-type-selection-item-inner ${(selecedRole === item.role) ? 'active' : ''}`}>
                    <div className="profile-type-header">
                      <span>{item.profile}</span>
                    </div>
                    <div className="profile-type-main-container">
                      <p>{item.description}</p>
                      <img src={item.img} alt="" srcSet="" />
                    </div>
                    <div className="profile-type-selector__wrapper">
                      <h4>
                        { (item.role == "mp_enterprise_owner" || item.role == "mp_common_agent" || item.role == "mp_content_generator") && <input
                            className="form-check-input secondary"
                            type="radio"
                            name="roleType"
                            id="radio1"
                            disabled={(item.role != "mp_enterprise_owner" && item.role != "mp_common_agent" && item.role != "mp_content_generator")}
                            checked={(selecedRole === item.role)}
                            onChange={() => handleRoleTypeSelection(item.role)}
                        />}&nbsp;I'm a {item.profile}
                      </h4>
                    </div>
                    <div className="profile-other-content">
                      <div className="other-content-list__wrapper">
                        {/* <p className="list-title">List title goes here</p> */}
                        {item.profile === 'Business Owner' ?
                            <ul className="other-content-list">
                              <li className="list-item">Engaged in running a business, regardless of its scale or industry. (You might be a business in LE/SME/SOHO etc.) </li>
                              <li className="list-item">Holds a valid Business Registration or NIC, signifying official business status.</li>
                              <li className="list-item">Actively seeking digital tools to enhance and streamline business operations.</li>
                            </ul>
                            : ''
                        }
                        {item.profile === 'Marketplace Partner' ?
                            <ul className="other-content-list">
                              <li className="list-item">Possesses sound sales expertise, demonstrating a strong understanding of effective sales strategies.</li>
                              <li className="list-item">Established sales network, excelling in connecting and engaging with potential clients.</li>
                              <li className="list-item">Aptitude for sales, understands client needs, communicates effectively, and drives successful transactions.</li>
                            </ul>
                            : ''
                        }
                        {item.profile === 'Service Provider' ?
                            <ul className="other-content-list">
                              <li className="list-item">Offers digitized solutions that enhance business efficiency and streamline operations.</li>
                              <li className="list-item">Provides effective and budget-friendly digital tools, ensuring value for clients without compromising quality.</li>
                              <li className="list-item">Adheres to industry regulations, ensuring legal and regulatory compliance in provided solutions.</li>
                            </ul>
                            : ''
                        }

                      </div>
                    </div>
                  </div>
                </div>
            ))}
          </div>
          {/* <div className="profile-type-selection-footer">
          <a href="">Read further more about benefits</a>
        </div> */}
        </div>
      </div>
  )
}

export { UserRoleSelection }