import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { profileAndUserPermissionModel } from "../../pages/home/models/request/profileAndUserPermission.model";
import { BusinessDetailsModel } from "../../pages/settings/models/request/business-details.model";
import { OwnerDetailsModel } from "../../pages/settings/models/response/owner-details.model";
import { PartnerRequestModel } from "../../pages/settings/models/response/partner-requests";
import { EnterpriseDocumentModel } from "../../pages/settings/models/response/enterprise-document.model";

interface AuthSliceModel {
  isEditDetailsClicked: boolean;
  profileAndUserPermission: profileAndUserPermissionModel | null;
  unsubscribeRes: any;
  appList: Array<any>;
  businessDetails: BusinessDetailsModel | null;
  businessDetailsEdited: any;
  paymentMethotDetails: Array<any>;
  profileIdentificationImages: Array<any>;
  businessRegistrationDocuments: Array<any>;
  signedDocuments: Array<any>;
  navbarOpen: boolean;
  ownerDetails: OwnerDetailsModel | null;
  industryList: Array<any>
  partnerDetails: Array<any>;
  partnerRequestDetails: PartnerRequestModel[] | null;
  partnerRewoked: any;
  partnerRequestSend: any;
  msisdnList: Array<any>;
  msisdnSubmitResponse: any;
  deletedPartner: any;
  unsubbedAppList: Array<any>;
  maskList: Array<any>;
  maskSubmitResponse: any;
  enterpriseDocuments: EnterpriseDocumentModel | null;
  isMaskDuplicate: null | boolean;
  portList: Array<any>;
  selectedPort: string;
  portSubmitResponse: any;
  businessList: Array<any>;
  selectedLongNumber: string;
  longNumberSubmitResponse: any;
  partnerList: Array<any>;
  partnerAccessChanged: boolean;
  paymentInfo: any;
  subscriptionBasedWidgetList: any;
  perUsagedWidgetList: any;
  freeWidgetList: any;
  oneTimeWidgetList: any;
  paymentOptionsList: any;
  paymentHistoryList: Array<any>;
  changeAccountStatusResponse: any;
  updateLogoResponse: any;
  noPayMsisdnList: any;
  hasMoreData: boolean;
  ratePlanUpdateResp: any;
  renewSubscriptionLoading: boolean;
  subWidgetsLoading: boolean;
  perUsageWidgetsLoading: boolean;
  oneTimeWidgetsLoading: boolean;
  noChargeWidgetsLoading: boolean;
  renewalChargingData: any;
  renewalChargingDataList: Array<any>;
  discountInfo: any;
  enterpriseDetailsUpdateStatus: any;
  ownerDetailsEdited: any;
}


const settingsStore = createSlice({
  name: "settings-store",
  initialState: {
    isEditDetailsClicked: false,
    profileAndUserPermission: null,
    unsubscribeRes: null,
    appList: [],
    businessDetails: null,
    businessDetailsEdited: null,
    paymentMethotDetails: [],
    profileIdentificationImages: [],
    businessRegistrationDocuments: [],
    signedDocuments: [],
    navbarOpen: false,
    ownerDetails: null,
    industryList: [],
    partnerDetails: [],
    partnerRequestDetails: [],
    isPartnerRewoked: false,
    partnerRequestSend: "",
    msisdnList: [],
    msisdnSubmitResponse: null,
    partnerRewoked: "",
    deletedPartner: "",
    unsubbedAppList: [],
    maskList: [],
    maskSubmitResponse: null,
    enterpriseDocuments: null,
    isMaskDuplicate: null,
    portList: [],
    selectedPort: '',
    portSubmitResponse: null,
    businessList: [],
    selectedLongNumber: '',
    longNumberSubmitResponse: null,
    partnerList: [],
    partnerAccessChanged: false,
    paymentInfo: null,
    subscriptionBasedWidgetList: null,
    perUsagedWidgetList: null,
    freeWidgetList: null,
    oneTimeWidgetList: null,
    paymentOptionsList: null,
    paymentHistoryList: [],
    loading: false,
    changeAccountStatusResponse: null,
    updateLogoResponse: null,
    noPayMsisdnList: [],
    hasMoreData: true,
    ratePlanUpdateResp: null,
    renewSubscriptionLoading: false,
    subWidgetsLoading: false,
    perUsageWidgetsLoading: false,
    oneTimeWidgetsLoading: false,
    noChargeWidgetsLoading: false,
    renewalChargingData: null,
    renewalChargingDataList: [],
    discountInfo: null,
    enterpriseDetailsUpdateStatus: null,
    ownerDetailsEdited: null
  } as AuthSliceModel,
  reducers: {
    editButtonClicked: (state: AuthSliceModel) => {
      return {
        ...state,
        isEditDetailsClicked: true
      }
    },
    mobileNavbarLinkClicked: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        navbarOpen: action.payload
      }
    },
    getProfileDetails: (state: AuthSliceModel, action: PayloadAction<profileAndUserPermissionModel>) => {
      return {
        ...state,
        profileAndUserPermission: action.payload

      }
    },
    editUserDetails: (state: AuthSliceModel, action: PayloadAction<OwnerDetailsModel>) => {
      return {
        ...state,
        ownerDetails: action.payload
      }
    },
    unsubscribe: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        unsubscribeRes: action.payload
      }
    },
    getAppList: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        appList: action.payload.filterdArray,
        hasMoreData: action.payload.hasMoreData
      }
    },
    getUnsubscribedAppList: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        unsubbedAppList: action.payload
      }
    },
    getBusinessDetails: (state: AuthSliceModel, action: PayloadAction<BusinessDetailsModel>) => {
      return {
        ...state,
        businessDetails: action.payload
      }
    },
    editBusinessDetails: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        businessDetailsEdited: action.payload,
      }
    },
    getOwnerDetails: (state: AuthSliceModel, action: PayloadAction<OwnerDetailsModel>) => {
      return {
        ...state,
        ownerDetails: action.payload
      }
    },
    getPartnerDetails: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        partnerDetails: action.payload
      }
    },
    getPaymentMethodDetails: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        paymentMethotDetails: action.payload
      }
    },
    getProfileIdentificationImages: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        profileIdentificationImages: action.payload
      }
    },
    getBusinessRegistrationDocuments: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        businessRegistrationDocuments: action.payload
      }
    },
    getSignedDocuments: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        signedDocuments: action.payload
      }
    },
    getIndustryTypeList: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,

      }
    },
    getPartnerRequests: (state: AuthSliceModel, action: PayloadAction<any[]>) => {
      return {
        ...state,
        partnerRequestDetails: action.payload
      }
    },
    getPartnerRequestsError: (state: AuthSliceModel) => {
      return {
        ...state,
        partnerRequestDetails: []
      }
    },
    sendPartnerRequest: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        partnerRequestSend: action.payload
      }
    },
    deletePartnerRequest: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        partnerRewoked: action.payload
      }
    },
    deletePartner: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        deletedPartner: action.payload
      }
    },
    getMsisdnListSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        msisdnList: action.payload
      }
    },
    getMsisdnListFail: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        msisdnList: action.payload
      }
    },
    postMsisdnListSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        msisdnSubmitResponse: action.payload
      }
    },
    postMsisdnListFail: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        msisdnSubmitResponse: action.payload
      }
    },
    updateMsisdnListSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        msisdnSubmitResponse: action.payload
      }
    },
    updateMsisdnListFail: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        msisdnSubmitResponse: action.payload
      }
    },
    deleteMsisdnSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        msisdnSubmitResponse: action.payload
      }
    },
    deleteMsisdnFail: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        msisdnSubmitResponse: action.payload
      }
    },
    getMaskListSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        maskList: action.payload
      }
    },
    getMaskListFail: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        maskList: action.payload
      }
    },
    postMaskListSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        maskSubmitResponse: action.payload
      }
    },
    postMaskListFail: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        maskSubmitResponse: action.payload
      }
    },
    updateMaskListSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        maskSubmitResponse: action.payload
      }
    },
    updateMaskListFail: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        maskSubmitResponse: action.payload
      }
    },
    deleteMaskSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        maskSubmitResponse: action.payload
      }
    },
    deleteMaskFail: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        maskSubmitResponse: action.payload
      }
    },
    checkDuplicateLoading: (state: AuthSliceModel) => {
      return {
        ...state,
        isMaskDuplicate: null
      }
    },
    checkDuplicateSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        isMaskDuplicate: action.payload.isDuplicate
      }
    },
    checkDuplicateFail: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        isMaskDuplicate: null
      }
    },
    getPortListSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        portList: action.payload
      }
    },
    getPortListFail: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        portList: action.payload
      }
    },
    getPortSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        selectedPort: action.payload.requestport
      }
    },
    getPortFail: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        selectedPort: ''
      }
    },
    postPortSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        portSubmitResponse: action.payload
      }
    },
    postPortFail: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        portSubmitResponse: action.payload
      }
    },
    getBusinessList: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        businessList: action.payload
      }
    },
    getLongNumberSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        selectedLongNumber: action.payload[0].requestLongNumber
      }
    },
    getLongNumberFail: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        selectedLongNumber: ''
      }
    },
    postLongNumberSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        longNumberSubmitResponse: action.payload
      }
    },
    postLongNumberFail: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        longNumberSubmitResponse: action.payload
      }
    },
    getEnterpriseDocs: (state: AuthSliceModel, action: PayloadAction<EnterpriseDocumentModel>) => {
      return {
        ...state,
        enterpriseDocs: action.payload
      }
    },
    updatePartnerAccessLevel: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        partnerAccessChanged: action.payload
      }
    },
    getPaymentInfoSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        paymentInfo: action.payload
      }
    },
    getPaymentInforFail: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        paymentInfo: null
      }
    },
    getWidgetList: (state: AuthSliceModel) => {
      return {
        ...state,
        subWidgetsLoading: true,
        perUsageWidgetsLoading: true,
        oneTimeWidgetsLoading: true,
        noChargeWidgetsLoading: true
      }
    },
    getSubBasedSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        subscriptionBasedWidgetList: action.payload,
        subWidgetsLoading: false
      }
    },
    getSubBasedFail: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        subscriptionBasedWidgetList: null,
        subWidgetsLoading: false
      }
    },
    getPerUsageSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        perUsagedWidgetList: action.payload,
        perUsageWidgetsLoading: false
      }
    },
    getPerUsageFail: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        perUsagedWidgetList: null,
        perUsageWidgetsLoading: false
      }
    },
    getFreeWidgetsSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        freeWidgetList: action.payload,
        noChargeWidgetsLoading: false
      }
    },
    getFreeWidgetsFail: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        freeWidgetList: null,
        noChargeWidgetsLoading: false
      }
    },
    getOnetimeSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        oneTimeWidgetList: action.payload,
        oneTimeWidgetsLoading: false
      }
    },
    getOnetimeFail: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        oneTimeWidgetList: null,
        oneTimeWidgetsLoading: false
      }
    },
    putPaymentMethodSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state
      }
    },
    putPaymentMethodFail: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state
      }
    },
    getPaymentOptionsListSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        paymentOptionsList: action.payload
      }
    },
    getPaymentOptionsListFail: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        paymentOptionsList: null
      }
    },
    putRatePlanSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        ratePlanUpdateResp: action.payload
      }
    },
    putRatePlanFail: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state
      }
    },
    getPaymentHistoryListSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        paymentHistoryList: action.payload
      }
    },
    getPaymentHistoryListFail: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        paymentHistoryList: []
      }
    },
    requestPending: (state: AuthSliceModel) => {
      return {
        ...state,
        loading: true
      }
    },
    terminateEntAccount: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        changeAccountStatusResponse: action.payload
      }
    },
    updateEntLogo: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        updateLogoResponse: action.payload,
        loading: false
      }
    },
    getNoPayMsisdnListSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        noPayMsisdnList: action.payload
      }
    },
    getNoPayMsisdnListFail: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        noPayMsisdnList: []
      }
    },
    renewSubscription: (state: AuthSliceModel) => {
      return {
        ...state,
        renewSubscriptionLoading: true
      }
    },
    renewSubscriptionSuccess: (state: AuthSliceModel) => {
      return {
        ...state,
        renewSubscriptionLoading: false
      }
    },
    renewSubscriptionFailed: (state: AuthSliceModel) => {
      return {
        ...state,
        renewSubscriptionLoading: false
      }
    },
    getRenewalChargingSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        renewalChargingData: action.payload
      }
    },
    getRenewalChargingFailed: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        renewalChargingData: null
      }
    },
    setRenewalChargingList: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        renewalChargingDataList: action.payload
      }
    },
    getDiscountInfoSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        discountInfo: action.payload
      }
    },
    getDiscountInfoFailed: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        discountInfo: null
      }
    },
    getEntUpdateStatus: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        enterpriseDetailsUpdateStatus: action.payload
      }
    },
    editOwnerDetails: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        ownerDetailsEdited: action.payload,
      }
    },
  },
});

export default settingsStore;