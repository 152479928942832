
import React, { CSSProperties, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import {fetchAllGwsConfigs, fetchAllOrders, fetchSingleApp, resetGwsStatuses} from '../../../store/actions';
import AuthHandler from '../../../helper/AuthHandler';
import { PageHeaderStatic } from "../components/PageHeaderStatic";
import { confirmAlert } from "react-confirm-alert";
import { Tooltip } from "antd";
import { EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import * as Constants from "../../../constants";

const FixedBBConfigList = ({ auth }: any) => {

    const { wid } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { userDetails } = useSelector(({ user }: any) => user);
    const { success, loading, error, ordersList} = useSelector(({ fixedBB }: any) => fixedBB);

    const [isFetchOrdersOnGoing, setIsFetchOrdersOnGoing] = useState(false);

    useEffect(() => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        dispatch(fetchSingleApp(wid!, tenantGUID));
    }, [wid, userDetails]);

    useEffect(() => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        dispatch(fetchAllOrders(tenantGUID, wid!));
        setIsFetchOrdersOnGoing(true);
    }, [wid, userDetails]);

    useEffect(() => {
        if(ordersList && success && isFetchOrdersOnGoing) {
            if(ordersList.length == 0) {
                const paths = window.location.pathname.split('/');
                navigate(`/${paths[Constants.BASEURLPATHLENGTH + 1]}/${paths[Constants.BASEURLPATHLENGTH + 2]}/create-config/init`);
            }
            setIsFetchOrdersOnGoing(false);
        }
    }, [ordersList]);

    const onViewConfig = (configId: string, orderStatus: string, status: string) => {
        if(orderStatus === "PaymentDone" || orderStatus === "OrderSubmitted" || orderStatus === "DeviceInstalled" || orderStatus === "ConnectionActive") {
            navigate(`/fixed-bb/${wid}/view-config-status/${configId}`);
        } else {
            navigate(`/fixed-bb/${wid}/view-config/${configId}`);
        }
    }

    const renderConfigList = () => {
        if (loading) {
            return (
                <div className="section__wrapper">
                    <div className="empty-state-new-action__wrapper">
                        <section className="content__wrapper">
                            <div className="primaryBrandLoaderBg">
                                <div className="primaryBrandLoader">
                                    <img src={require('../../../assets/images/loader-bg-primary.svg').default} alt="" srcSet="" />
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            )
        } else if (success && ordersList) {
            if (ordersList.length > 0) {
                return (
                    <div className="section__wrapper">
                        <div className="card panel content-holder-panels">
                            <div className="panel-header">
                                <div className="panel-header-content__wrapper">
                                    <h4 className="panel-header-title">List of Configs</h4>
                                </div>
                            </div>
                            <div className="panel-table-listing__wrapper">
                                <div className="table-parent__wrapper">
                                    <table className="table table-default">
                                        <tbody>
                                            <tr className="table-header">
                                                <th className="txt-left">#</th>
                                                <th className="txt-left">Order ID</th>
                                                <th className="txt-left">Created Date</th>
                                                <th className="txt-left">Amount</th>
                                                <th className="txt-left">Status</th>
                                                <th className="txt-left">Order Status</th>
                                                <th className="txt-left"></th>
                                            </tr>
                                            {ordersList.map((value: any, index: number) => {
                                                return (
                                                    <tr className="table-row ">
                                                        <td className="txt-left">{index + 1}</td>
                                                        <td className="txt-left">{value.uuid}</td>
                                                        <td className="txt-left">{value.createdAt}</td>
                                                        <td className="txt-left">{value.total}</td>
                                                        <td className="txt-left">{value.status}</td>
                                                        <td className="txt-left">{value.orderStatus}</td>
                                                        <td className="txt-left" style={{ textAlign: 'center' }}>
                                                            <Tooltip title="View">
                                                                <EyeOutlined onClick={() => onViewConfig(value.uuid, value.orderStatus, value.status)} />
                                                            </Tooltip>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="section__wrapper">
                        <div className="empty-state-new-action__wrapper">
                            <div className="new-action-content__wrapper">
                                <h4 className="sub">No Records available</h4>
                                <h4 className="title">Create new request</h4>
                                <button className="btn btn-primary" onClick={() => navigate(`/fixed-bb/${wid}/create-config/init`)}>New request</button>
                            </div>
                        </div>
                    </div>
                )
            }
        } else if (error) {
            return (
                <div className="section__wrapper">
                    <div className="empty-state-new-action__wrapper">
                        <div className="new-action-content__wrapper">
                            <h4 className="sub">Oops, something went wrong!</h4>
                        </div>
                    </div>
                </div>
            )
        }
    }

    if (AuthHandler.isAuthorized(auth, 'fixed-bb-config-list') && userDetails !== null) {
        let path = window.location.pathname.split('/');
        return (
            <React.Fragment>
                <div className="main-content main-spacer-x-y">
                    <PageHeaderStatic
                        listShow={Array.isArray(ordersList) && ordersList.length > 0}
                        page={'list'}
                        newButtonLink={path[0] + '/' + path[1] + '/' + path[2] + '/create-config/init'}
                        // externalLink={`https://admin.google.com/`}
                        // externalLinkText={`Google Workspace Login`}
                    />
                    {renderConfigList()}
                </div>

            </React.Fragment>
        );
    } else return (<React.Fragment></React.Fragment>);
}

export default FixedBBConfigList