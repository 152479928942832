import axiosInstance from "../axios-instance";
import * as CONSTANTS from "../constants";


const getTenantMasksByType = (tenantId: string, maskType: string) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/ent/mask/type/${tenantId}/${maskType}`
    });
};

const getMsisdnList = (tenantId: string, widgetId: string) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/ent/msisdn/${tenantId}/${widgetId}`
    });
};

const getDistricts = () => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/districts`
    });
};

const getTenantMasks = (tenantId: string) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/ent/tenantmasks/${tenantId}`
    });
};

const getEntConfigs = (tenantId: string) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/ent/configs/${tenantId}`
    });
};

export {
    getTenantMasksByType,
    getMsisdnList,
    getDistricts,
    getTenantMasks,
    getEntConfigs
}