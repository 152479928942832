import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
import {fetchBillingHistory, fetchSingleApp} from "../../../store/actions";
import AuthHandler from "../../../helper/AuthHandler";
import { PageHeaderDynamicFormComponent } from "../components/PageHeaderDynamicForm.component";
import { useParams } from "react-router-dom";
import * as Constants from '../../../constants';

const DynamicWidgetBillingHistory = (props) => {
    const { wid } = useParams();

    const dispatch = useDispatch();
    const { userDetails } = useSelector(({ user }) => user);
    const {
        singleAppDetails
    } = useSelector(({ apps }) => apps);
    const { dynamicBillingHistoryLoading, dynamicBillingHistory } = useSelector(({ dynamicForm }) => dynamicForm);
    const paths = window.location.pathname.split('/');

    useEffect(() => {
        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        dispatch(fetchSingleApp(wid, tenantGroupId));
    }, [userDetails])

    useEffect(() => {
        const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        if (singleAppDetails && singleAppDetails.widgetDetails) {
            dispatch(fetchBillingHistory(singleAppDetails.widgetDetails.uuid, tenantGroupId))
        }
    }, [singleAppDetails, userDetails]);

    return (
        <React.Fragment>
            {
                AuthHandler.isAuthorized(props.auth, 'dynamin-widget') && userDetails !== null ?
                    <div className="main-content main-spacer-x-y">
                        <PageHeaderDynamicFormComponent
                            backLink={`/${paths[Constants.BASEURLPATHLENGTH + 1]}/${paths[Constants.BASEURLPATHLENGTH + 2]}/list`}
                            leadCount={'single'}
                            widgetConfigList={null}
                            page={'history-list'}
                        />
                        <div className="section__wrapper">
                            <div className="card panel content-holder-panels">
                                <div className="panel-header">
                                    <div className="panel-header-content__wrapper">
                                        <h4 className="panel-header-title">Billing History - {(singleAppDetails && singleAppDetails.widgetDetails) ? singleAppDetails.widgetDetails.title : ''}</h4>
                                    </div>
                                </div>
                                <div className="panel-table-listing__wrapper">
                                    <div className="table-parent__wrapper">
                                        <Table
                                            className="gx-table-responsive"
                                            sortDirections={['descend', 'ascend']}
                                            showSorterTooltip={false}
                                            columns={[{
                                                title: "Id",
                                                dataIndex: "id"
                                            },{
                                                title: "Amount",
                                                dataIndex: "amount"
                                            },{
                                                title: "Tax",
                                                dataIndex: "tax"
                                            },{
                                                title: "Msisdn",
                                                dataIndex: "msisdn"
                                            },{
                                                title: "Status",
                                                dataIndex: "status"
                                            },{
                                                title: "Created At",
                                                dataIndex: "createdAt"
                                            },{
                                                title: "Updated At",
                                                dataIndex: "updatedAt"
                                            }]}
                                            dataSource={dynamicBillingHistory ? dynamicBillingHistory : []}
                                            pagination={true}
                                            loading={dynamicBillingHistoryLoading}
                                            size={"small"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <></>
            }
        </React.Fragment>
    )
};

export default DynamicWidgetBillingHistory;