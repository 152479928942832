/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Button, Form, Input } from 'antd';
import {ContactModel} from "../models/contact.model";

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

type Props = {
    onSetContactInfo: (values: ContactModel) => void,
    contactData: {
        contactName: string,
        contactNumber: string,
        contactEmail: string
    }
}

const ContactInfoForm = ({ onSetContactInfo, contactData }: Props) => {
    const emailRegex = /^[a-zA-Z0-9]+([._-]?[a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/;
    return (
        <React.Fragment>
            <div>
                <div className="card-body gws__body">
                    <h4 className="gws-title">Contact Informations</h4>
                    <Form
                        {...layout}
                        name="address-form"
                        style={{ maxWidth: 600, marginInline: 'auto', textAlign: 'left' }}
                        initialValues={{ remember: true }}
                        onFinish={onSetContactInfo}
                        autoComplete="off"
                        labelAlign={"right"}
                    >
                        <Form.Item
                            label="Contact Name"
                            name="name"
                            initialValue={contactData.contactName}
                            rules={[
                                {
                                    required: true,
                                    message: 'You can\'t keep this as empty.',
                                }
                            ]}
                        >
                            <Input placeholder="Type the Name" />
                        </Form.Item>
                        <Form.Item
                            label="Contact Number"
                            name="contact"
                            initialValue={contactData.contactNumber}
                            rules={[
                                {
                                    required: true,
                                    message: 'You can\'t keep this as empty.',
                                },
                                {
                                    pattern: /^[0]{1}[123456789]{2}\d{7}$/,
                                    message: 'The entered contact number is invalid.'
                                }
                            ]}
                        >
                            <Input placeholder="Type the Contact Number" />
                        </Form.Item>
                        <Form.Item
                            label="Email Address"
                            name="email"
                            initialValue={contactData.contactEmail}
                            rules={[
                                {
                                    required: true,
                                    message: 'You can\'t keep this as empty.',
                                },
                                {
                                    pattern: emailRegex,
                                    message: 'The entered email address is invalid. (Can not use special characters before @ sign)'
                                }
                            ]}
                        >
                            <Input placeholder="Type the Contact Number" />
                        </Form.Item>
                        <Form.Item {...tailLayout} className={"wizard-action"}>
                            <Button type="primary" htmlType="submit" className={"btn btn-primary btn-sm"}>
                                Next
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ContactInfoForm;