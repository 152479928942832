import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SingleAppModel } from "../../pages/single-app/models/response/single-app.model";
import { CategoryModel } from "../../pages/discover/models/response/app-category.model";

interface AuthSliceModel {
  singleAppDetails: SingleAppModel | null;
  sliderItemsList: Array<any> | null;
  numberList: Array<any> | null;
  categoryDetails: CategoryModel | null;
  isSubscribeClicked: boolean;
  widgetStats: {};
  activeMaskList: Array<any> | null;
}

const appStore = createSlice({
  name: "app-store",
  initialState: {
    singleAppDetails: null,
    sliderItemsList: [],
    numberList: [],
    categoryDetails: null,
    isSubscribeClicked: false,
    widgetStats: {},
    activeMaskList: [],
  } as AuthSliceModel,
  reducers: {
    requestPending: (state: AuthSliceModel) => {
      return {
        ...state,
        loading: true
      }
    },
    getAppDetailsSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        singleAppDetails: action.payload,
        loading: false
      }
    },
    getAppDetailsFailed: (state: AuthSliceModel) => {
      return {
        ...state,
        loading: false
      }
    },
    getSliderItemsSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        sliderItemsList: action.payload,
        loading: false
      }
    },
    getSliderItemsFailed: (state: AuthSliceModel) => {
      return {
        ...state,
        loading: false
      }
    },
    getNumberListSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        numberList: action.payload,
        loading: false
      }
    },
    getNumberListFailed: (state: AuthSliceModel) => {
      return {
        ...state,
        loading: false
      }
    },
    getCategoryDetailsSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        categoryDetails: action.payload,
        loading: false
      }
    },
    getCategoryDetailsFailed: (state: AuthSliceModel) => {
      return {
        ...state,
        loading: false
      }
    },
    isSubscribeClickedTrue: (state: AuthSliceModel) => {
      return {
        ...state,
        isSubscribeClicked: true
      }
    },
    isSubscribeClickedFalse: (state: AuthSliceModel) => {
      return {
        ...state,
        isSubscribeClicked: false
      }
    },
    getWidgetStatSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        widgetStats: action.payload,
        loading: false
      }
    },
    getWidgetStatFailed: (state: AuthSliceModel) => {
      return {
        ...state,
        loading: false
      }
    },
    getEnterpriseActiveMaskFetch: (state: AuthSliceModel) => {
      return {
        ...state,
        loading: true
      }
    },
    getEnterpriseActiveMaskSuccess: (state: AuthSliceModel, action: PayloadAction<any>) => {
      return {
        ...state,
        activeMaskList: action.payload,
        loading: false
      }
    },
    getEnterpriseActiveMaskFail: (state: AuthSliceModel) => {
      return {
        ...state,
        loading: false
      }
    }
  },
});

export default appStore;