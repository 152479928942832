import React, { useEffect, useState } from 'react'
import entImage from '../../../assets/images/icons/logo-ignition-auto-lanka.webp';
import appImage from '../../../assets/images/icons/logo-app-smart-home.webp';
import cardIcon from '../../../assets/images/icons/payment-icon-visa.svg'
import { AppEntBindingComponent, MultiRateCardSelect, PanelTableListCard, PrivacyComponent, RateCardDisplay, RateCardSelect } from '.';
import { RadioButton } from '../../../components';
import { UserDetailsModel } from '../../home/models/response/user-details.model';
import { SingleAppModel, SubWidgets, WidgetRate } from '../models/response/single-app.model';
import { GenieCardListModel } from '../models/response/genie-card-list.model';
import { PaymentMsisdnListModel } from '../models/response/payment-msisdn-list.model';
import { Modal } from '../../../components';
import SimpleReactValidator from 'simple-react-validator';
import { SubWidgetSelect } from './SubWidgetSelect';
import { subscribeNotClicked } from "../../../store/actions";
import { useAppDispatch } from "../../../store";
import * as CONSTANTS from '../../../constants';
import {KycConsentModal} from "../../apps/kyc/components/KycConsentModal";
import { useNavigate } from "react-router-dom";
import {Checkbox} from "antd";
import { getAddGenieCardView } from '../../../services/settings.service';
import Model from "react-bootstrap/Modal";

type Props = {
    appDetails: SingleAppModel | null,
    changePaymentMethod: (name: string) => (e: any) => void,
    loading: boolean,
    setLoading: (e: any) => void,
    paymentMethod: string,
    userDetails: UserDetailsModel | null,
    paymentMsisdnList: Array<PaymentMsisdnListModel>,
    genieCardList: Array<GenieCardListModel>,
    paymentNumber: PaymentMsisdnListModel | null,
    setPaymentNumber: (e: any) => void,
    paymentCard: GenieCardListModel | null,
    setPaymentCard: (e: any) => void,
    setStep: (e: any) => void,
    step: number,
    onAppSub: () => void,
    onChangeRate: (e: any) => void,
    selectedRate: string,
    partnerDetails: Array<any>,
    onChangeAgent: (e: any) => void,
    agent: string,
    setDisplayMsisdnModal: (e: any) => void,
    displayMsisdnModal: boolean,
    setDisplayCardModal: (e: any) => void,
    displayCardModal: boolean,
    validator: SimpleReactValidator,
    appSubLoading: boolean,
    onChangeWidgetCount: (value: any, rate: any) => void,
    maxWidgetCount: number,
    onChangeUsageCount: (e: any) => void,
    usageCount: string,
    subscriptionRate: number,
    onChangeWidget: (e: any) => void,
    selectedSubWidget: SubWidgets | null,
    showConsentModal: boolean,
    onConsentStatusChange: (e: any) => void,
    closeModalEntConsentModal: () => void,
    addEntConsentDetails: (e: any) => void,
    isConsentTrue: boolean,
    tncChecked: boolean,
    onChangeTnC: (e: any) => void,
    tncError: string
}

const AppSubscription = ({
    changePaymentMethod,
    loading,
    setLoading,
    paymentMethod,
    userDetails,
    appDetails,
    paymentMsisdnList,
    genieCardList,
    paymentNumber,
    setPaymentNumber,
    paymentCard,
    setPaymentCard,
    setStep,
    step,
    onAppSub,
    onChangeRate,
    selectedRate,
    partnerDetails,
    onChangeAgent,
    agent,
    setDisplayMsisdnModal,
    displayMsisdnModal,
    setDisplayCardModal,
    displayCardModal,
    validator,
    appSubLoading,
    onChangeWidgetCount,
    maxWidgetCount,
    onChangeUsageCount,
    usageCount,
    subscriptionRate,
    onChangeWidget,
    selectedSubWidget,
    showConsentModal,
    onConsentStatusChange,
    closeModalEntConsentModal,
    addEntConsentDetails,
    isConsentTrue,
    tncChecked,
    onChangeTnC,
    tncError
}: Props) => {
    const [logo, setLogo] = useState<any>("");

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(subscribeNotClicked())
    }, [])
    const [payForm, setPayForm] = useState(null);

    useEffect(() => {
        if (userDetails) {
            if (userDetails.OwnedEnterprises && Array.isArray(userDetails.OwnedEnterprises) && userDetails.OwnedEnterprises.length > 0 && userDetails.OwnedEnterprises[0].Enterprise) {
                userDetails.OwnedEnterprises[0].EnterpriseImages.map((value: any, index: number) => {
                    if (value.fieldName === "entLogo") {
                        setLogo(value.imgUrl.url);
                    }
                });
            }
        }
    }, [userDetails])

    useEffect(() => {
        if (paymentMsisdnList) {
            paymentMsisdnList.map((value: any, index: number) => {
                if (value.isPrimary) {
                    setPaymentNumber(value);
                }
            });
        }
    }, [paymentMsisdnList])

    useEffect(() => {
        if (genieCardList) {
            genieCardList.map((value: any, index: number) => {
                if (value.isPrimary) {
                    setPaymentCard(value);
                }
            });
        }
    }, [genieCardList])

    useEffect(() => {
        if (payForm) {
            const formElement = document.getElementById('ext-merchant-frm') as HTMLFormElement;

            if (formElement) {
                formElement.submit();
            } else {
                console.error("Element 'ext-merchant-frm' not found.");
            }
        }
    }, [payForm]);


    // console.log("paymentMsisdnList", paymentMsisdnList);
    // console.log("genieCardList", genieCardList);

    const onChangePayNumber = (e: any) => {
        // console.log("########## 1", JSON.parse(e.target.value));
        setPaymentNumber(JSON.parse(e.target.value));
    }

    const onChangePayCard = (e: any) => {
        setPaymentCard(JSON.parse(e.target.value));
    }

    const redirectToAddPayMethod = async () => {
        const tenantGUID = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
        let res = await getAddGenieCardView(tenantGUID);
        setPayForm(res.data);
        // navigate(`/settings/payment`)
    }

    const renderPaymentForm = () => {
        if (!payForm) {
            return;
        }
        if (payForm) {
            return (
                <div dangerouslySetInnerHTML={{ __html: payForm }} />
            );
        }
    }

    return (
        !appSubLoading ?
            <div className="main-content">
                <div className="container">
                    <div className="card panel mx-width-card detail-confirmation-panel">
                        <div className="panel-header">
                            <div className="panel-header-content__wrapper">
                                <h4 className="panel-header-title">Are you sure you want to subscribe this app?</h4>
                            </div>
                            <div className="panel-header-content__wrapper header-btn-group__wrapper">
                                <button className="btn btn-secondary" onClick={() => setStep(step - 1)}>Cancel</button>
                                <button className="btn btn-primary" onClick={onAppSub}><i className="bi bi-arrow-right" />&nbsp;Continue</button>
                            </div>
                        </div>
                        <div className="panel-content">
                            <div className="panerl-content-header__wrapper">
                                <h4 className="panel-inner-title">{(userDetails && Array.isArray(userDetails.OwnedEnterprises) && userDetails.OwnedEnterprises.length > 0 && userDetails.OwnedEnterprises[0].Enterprise) ? userDetails.OwnedEnterprises[0].Enterprise.entName : ''}</h4>
                                <span className="app-developer">
                                    by&nbsp;
                                    <a href="#" aria-label="app developer">
                                        Marketplace
                                    </a>
                                </span>
                            </div>
                            <AppEntBindingComponent
                                entImage={logo}
                                entName={(userDetails && Array.isArray(userDetails.OwnedEnterprises) && userDetails.OwnedEnterprises.length > 0 && userDetails.OwnedEnterprises[0].Enterprise) ? userDetails.OwnedEnterprises[0].Enterprise.entName : ''}
                                appImage={(appDetails && appDetails.widgetDetails && appDetails.widgetDetails.thumbnail) ? appDetails.widgetDetails.thumbnail : ''}
                                appName={(appDetails && appDetails.widgetDetails && appDetails.widgetDetails.title) ? appDetails.widgetDetails.title : ''}
                                status="pending"//"confirmed","error"
                            />
                            {userDetails && userDetails.OwnedEnterprises[0] && userDetails.OwnedEnterprises[0].Enterprise && userDetails.OwnedEnterprises[0].Enterprise.billingStatus !== 'Invoice' && <div className="status__wrapper">
                                <div className="panel-inner-feature-content__wrapper">
                                    <span className="panel-inner-feature-content-title">Payment</span>
                                    <div className="panel-inner-feature-content">
                                        {(appDetails && appDetails.widgetDetails && appDetails.widgetDetails.widgetPaymentMethods && appDetails.widgetDetails.widgetPaymentMethods.length > 0) ? <p>
                                            Default selected payment method will be the payment method of this
                                            app. Feel free to change
                                        </p> : <p>Payment method isn't available for the widget</p>}
                                        <div className="form-group__wrapper">
                                            {appDetails && appDetails.widgetDetails && appDetails.widgetDetails.widgetPaymentMethods.map((value: any, index: number) => {
                                                if (value.paymentMethodId == 1) {
                                                    return (
                                                        <RadioButton
                                                            key={index}
                                                            label="Add to bill"
                                                            name="paymentOption"
                                                            id="add_to_bill"
                                                            value={"add_to_bill"}
                                                            data={paymentNumber ? paymentNumber.msisdn : ''}
                                                            selectedValue={paymentMethod}
                                                            iconClass="bi-phone-fill"
                                                            icon=""
                                                            canChange={true}
                                                            setSelctedItem={changePaymentMethod}
                                                            disabled={(Array.isArray(paymentMsisdnList) && paymentMsisdnList.length > 0) ? false : true}
                                                            onChangeOption={() => setDisplayMsisdnModal(true)}
                                                        />
                                                    )
                                                }
                                                if (value.paymentMethodId == 9) {
                                                    return (
                                                        <RadioButton
                                                            key={index}
                                                            label="Card payment"
                                                            name="paymentOption"
                                                            id="card_pyment"
                                                            value="card_pyment"
                                                            data={paymentCard ? paymentCard.maskCardNo : 'No cards added'}
                                                            // selectedValue={paymentMethod}
                                                            selectedValue={paymentMethod}
                                                            iconClass="bi-credit-card-2-front-fill"
                                                            icon=""
                                                            canChange={(Array.isArray(genieCardList) && genieCardList.length > 1) ? true : false}//{true}
                                                            setSelctedItem={changePaymentMethod}
                                                            // disabled={(Array.isArray(genieCardList) && genieCardList.length > 0) ? false : true}
                                                            disabled={true}
                                                            onChangeOption={() => setDisplayCardModal(true)}
                                                            onAddOption={() => redirectToAddPayMethod()}
                                                            paymentDisableNotice={true}
                                                        />
                                                    )
                                                }
                                            })}
                                        </div>
                                        <div className="validation invalid-feedback">{validator.message('payment option', paymentMethod, 'required')}</div>
                                        {renderPaymentForm()}
                                    </div>
                                </div>

                            </div>}
                            {appDetails && appDetails.widgetDetails && Array.isArray(appDetails.widgetDetails.WidgetRate) && appDetails.widgetDetails.WidgetRate.length > 0 && <div className="status__wrapper ">
                                <div className="panel-inner-feature-content__wrapper">
                                    <span className="panel-inner-feature-content-title">Package/Rental</span>
                                    <div className="panel-inner-feature-content">
                                        <p>
                                            {appDetails.widgetDetails.chargeableType == 2 ? "Business Workflow Automation Suite" : "Default selected payment method will be the payment method of this app. Feel free to change"}
                                        </p>
                                        {appDetails && <div className="selectable-list-element__wrapper">
                                            {appDetails.widgetDetails.chargeableType == 1 ? <div className="panel-table-content-list">
                                                {appDetails.widgetDetails && Array.isArray(appDetails.widgetDetails.WidgetRate) && appDetails.widgetDetails.WidgetRate.map((value: WidgetRate, index: number) => {
                                                    return (
                                                        <RateCardSelect
                                                            key={index}
                                                            widgetRate={value}
                                                            onChangeRate={onChangeRate}
                                                            selectedRate={selectedRate}
                                                            validator={validator}
                                                        />
                                                    )
                                                })}
                                                <div className="validation invalid-feedback">{validator.message('rate', selectedRate, 'required')}</div>
                                            </div> : appDetails.widgetDetails.chargeableType == 2 ?
                                                <PanelTableListCard
                                                    appDetails={appDetails}
                                                    onChangeUsageCount={onChangeUsageCount}
                                                    usageCount={usageCount}
                                                    onChangeWidgetCount={onChangeWidgetCount}
                                                    maxWidgetCount={maxWidgetCount}
                                                    subscriptionRate={subscriptionRate}
                                                    validator={validator}
                                                />
                                                : appDetails.widgetDetails.chargeableType == 3 ? <div className="panel-table-content-list">
                                                    <RateCardDisplay
                                                        appDetails={appDetails}
                                                    />
                                                </div> : appDetails.widgetDetails.chargeableType == 5 ?
                                                    <div>
                                                        {appDetails.widgetDetails && Array.isArray(appDetails.widgetDetails.WidgetRate) && appDetails.widgetDetails.WidgetRate.map((value: WidgetRate, index1: number) => {
                                                            return (
                                                                <React.Fragment key={index1}>
                                                                    <table style={{ border: "1px solid black" }}>
                                                                        <thead>
                                                                            <th style={{
                                                                                border: "1px solid black",
                                                                                textAlign: 'center',
                                                                                fontWeight: 'bold',
                                                                                backgroundColor: '#e2e4e9'
                                                                            }}>Reach
                                                                            </th>
                                                                            <th style={{
                                                                                border: "1px solid black",
                                                                                textAlign: 'center',
                                                                                fontWeight: 'bold',
                                                                                backgroundColor: '#e2e4e9'
                                                                            }}>Price per SMS (Excluding tax)(Rs.) Short
                                                                                Message-124 Characters
                                                                            </th>
                                                                            <th style={{
                                                                                border: "1px solid black",
                                                                                textAlign: 'center',
                                                                                fontWeight: 'bold',
                                                                                backgroundColor: '#e2e4e9'
                                                                            }}>Price per SMS (Excluding tax)(Rs.) Long
                                                                                Message-284 Characters
                                                                            </th>
                                                                            <th style={{
                                                                                border: "1px solid black",
                                                                                textAlign: 'center',
                                                                                fontWeight: 'bold',
                                                                                backgroundColor: '#e2e4e9'
                                                                            }}>Price per SMS (Excluding tax)(Rs.) Essay Type
                                                                                Message-444 Characters
                                                                            </th>
                                                                            <th style={{
                                                                                border: "1px solid black",
                                                                                textAlign: 'center',
                                                                                fontWeight: 'bold',
                                                                                backgroundColor: '#e2e4e9'
                                                                            }}>Price per SMS (Excluding tax)(Rs.) Essay Type
                                                                                Message-600 Characters
                                                                            </th>
                                                                            <th style={{
                                                                                border: "1px solid black",
                                                                                textAlign: 'center',
                                                                                fontWeight: 'bold',
                                                                                backgroundColor: '#e2e4e9'
                                                                            }}>Price per SMS (Excluding tax)(Rs.) Essay Type
                                                                                Message-960 Characters
                                                                            </th>
                                                                        </thead>
                                                                        <tbody>
                                                                            {Array.isArray(value.RateCardSlabDetails) && value.RateCardSlabDetails.map((rateCardSlabType, index2) => {
                                                                                return (
                                                                                    <tr style={{ border: "1px solid black" }}>
                                                                                        <td style={{
                                                                                            border: "1px solid black",
                                                                                            textAlign: 'center',
                                                                                            fontWeight: 'bold',
                                                                                            backgroundColor: '#e2e4e9'
                                                                                        }}>
                                                                                            {rateCardSlabType.discription}
                                                                                        </td>
                                                                                        {rateCardSlabType?.RateCardSalbRate.length > 0 && rateCardSlabType.RateCardSalbRate?.map((rates, index) => {
                                                                                            return (
                                                                                                <td style={{
                                                                                                    border: "1px solid black",
                                                                                                    textAlign: 'center'
                                                                                                }}>
                                                                                                    {rates.rate}
                                                                                                </td>
                                                                                            )
                                                                                        })
                                                                                        }
                                                                                    </tr>
                                                                                )
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                </React.Fragment>
                                                            )
                                                        })}
                                                    </div>
                                                    :
                                                    appDetails.widgetDetails.chargeableType == 6 ? <div className="panel-table-content-list">
                                                        <MultiRateCardSelect
                                                            appDetails={appDetails}
                                                            onChangeRate={onChangeRate}
                                                            selectedRate={selectedRate}
                                                            validator={validator}
                                                        />
                                                    </div> : appDetails.widgetDetails.chargeableType == 7 ? <div className="panel-table-content-list"><RateCardDisplay
                                                        appDetails={appDetails}
                                                    /></div> : appDetails.widgetDetails.chargeableType == 8 ? <SubWidgetSelect
                                                        appDetails={appDetails}
                                                        onChangeWidget={onChangeWidget}
                                                        selectedSubWidget={selectedSubWidget}
                                                        validator={validator}
                                                    /> : appDetails.widgetDetails.chargeableType == 9 ? <div className="panel-table-content-list">
                                                        <RateCardDisplay
                                                            appDetails={appDetails}
                                                        />
                                                    </div> : appDetails.widgetDetails.chargeableType == 10 ? <div className="panel-table-content-list">
                                                        <RateCardDisplay
                                                            appDetails={appDetails}
                                                        />
                                                    </div> : appDetails.widgetDetails.chargeableType == 11 ? <div className="panel-table-content-list">
                                                        {/*<RateCardDisplay*/}
                                                        {/*    appDetails={appDetails}*/}
                                                        {/*/>*/}
                                                        {appDetails.widgetDetails && Array.isArray(appDetails.widgetDetails.WidgetRate) && appDetails.widgetDetails.WidgetRate.map((value: WidgetRate, index: number) => {
                                                            return (
                                                                <RateCardSelect
                                                                    key={index}
                                                                    widgetRate={value}
                                                                    onChangeRate={onChangeRate}
                                                                    selectedRate={selectedRate}
                                                                    validator={validator}
                                                                />
                                                            )
                                                        })}
                                                        <div className="validation invalid-feedback">{validator.message('rate', selectedRate, 'required')}</div>
                                                    </div> : appDetails.widgetDetails.chargeableType == 13 ? <div className="panel-table-content-list">
                                                        <div className="panel-table-content-list">
                                                            {appDetails.widgetDetails && Array.isArray(appDetails.widgetDetails.WidgetRate) && appDetails.widgetDetails.WidgetRate.map((value: WidgetRate, index: number) => {
                                                                return (
                                                                    <RateCardSelect
                                                                        key={index}
                                                                        widgetRate={value}
                                                                        onChangeRate={onChangeRate}
                                                                        selectedRate={selectedRate}
                                                                        validator={validator} />
                                                                )
                                                            })}
                                                        </div>
                                                        {/* <MultiRateCardSelect
                                                            appDetails={appDetails}
                                                            onChangeRate={onChangeRate}
                                                            selectedRate={selectedRate}
                                                            validator={validator}
                                                        /> */}
                                                    </div> : <></>}
                                        </div>}
                                    </div>
                                </div>
                            </div>}
                        </div>
                        <div className="panel-footer center">
                            <span>
                                <i className="bi bi-telephone-fill" />
                                &nbsp;&nbsp;If you having any issues please{" "}
                                <a href="mailto:info.marketplace@dialog.lk" target="_blank" rel="noopener noreferrer">
                                    Contact Support
                                </a>
                            </span>
                        </div>
                    </div>
                    {appDetails && appDetails.widgetDetails.chargeableType !== 4 && <>
                        {partnerDetails?.length > 0 && <div className="card panel mx-width-card privacy-confirmation-panel">
                            <div className="panel-header">
                                <div className="panel-header-content__wrapper">
                                    <h4 className="panel-header-title">
                                        Does this app have a marketing agent/ partner?
                                    </h4>
                                </div>
                            </div>
                            <div className="panel-content">
                                <div className="status__wrapper">
                                    <div className="panel-inner-feature-content__wrapper">
                                        <span className="panel-inner-feature-content-title">
                                            Marketing Agent
                                        </span>
                                        <div className="panel-inner-feature-content">
                                            <p>
                                                If you have no agent/partner selection, please select 'None' from the dropdown below
                                            </p>
                                            <div className="featured-panel-content">
                                                <div className="form-group__wrapper">
                                                    <div className="form-group">
                                                        <div className="form-element__wrapper mb-3 ">
                                                            <label htmlFor="" className="form-label">
                                                                Select your partner
                                                            </label>
                                                            <div className="icon-inside left d-flex btn-with-group">
                                                                <select
                                                                    className="form-control"
                                                                    onChange={onChangeAgent}
                                                                    value={agent}
                                                                >
                                                                    <option value={''} hidden disabled>Select agent/partner</option>
                                                                    {partnerDetails.filter((item) => item.accessLevel !== "admin").map((value: any, index: number) => {
                                                                        return (
                                                                            <option value={value.uuid} key={index}>{value.firstName} {value.lastName}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <div className="validation invalid-feedback">{validator.message('agent', agent, 'required')}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="alert info">
                                                    <span className="alert-text__wrapper">
                                                        <i className="alert-icon bi bi-info-circle-fill" />
                                                        <span className="alert-text">
                                                            {"Note - If you're planning to add partners to your enterprise, please follow the path below: "}<b>Settings <span style={{ fontSize: 20 }}>&#8594;</span> Profile & User permission tabs <span style={{ fontSize: 20 }}>&#8594;</span> Request partner</b>
                                                        </span>
                                                    </span>
                                                </div>
                                                {/*<div className="alert info">*/}
                                                {/*    <span className="alert-text__wrapper">*/}
                                                {/*        <i className="alert-icon bi bi-info-circle-fill" />*/}
                                                {/*        <span className="alert-text">*/}
                                                {/*            Your Partner - This partner does not retains any admin*/}
                                                {/*            privileges, given admin privileges will give same access level*/}
                                                {/*            as you in. But Admins can�t delete business profiles*/}
                                                {/*        </span>*/}
                                                {/*    </span>*/}
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </>}
                    {appDetails && appDetails.widgetDetails.shouldAcceptTnC && <>
                        {partnerDetails.length > 0 && <div className="card panel mx-width-card privacy-confirmation-panel">
                            <div className="panel-header">
                                <div className="panel-header-content__wrapper">
                                    <h4 className="panel-header-title">
                                        Terms and Conditions for Marketplace
                                    </h4>
                                </div>
                            </div>
                            <div className="panel-content">
                                <div className="status__wrapper">
                                    <div className="panel-inner-feature-content__wrapper">
                                        <span className="panel-inner-feature-content-title">
                                            Terms and Conditions
                                        </span>
                                        <div className="panel-inner-feature-content">
                                            <p>
                                                To subscribe this app you need to agreed to the terms and conditions of Marketplace.
                                            </p>
                                            <div className="featured-panel-content">
                                                <div className="form-group__wrapper">
                                                    {appDetails.widgetDetails.termsAndConditions && appDetails.widgetDetails.termsAndConditionsUrl ? <div className="form-group" style={{display: "flex"}}>
                                                        <Checkbox onChange={onChangeTnC} value={tncChecked} > {appDetails.widgetDetails.termsAndConditions}{appDetails.widgetDetails.termsAndConditions.slice(-1) == "." ? "" : "."} Refer <a href={appDetails.widgetDetails.termsAndConditionsUrl} target="_blank"> Terms and conditions</a></Checkbox>
                                                    </div> : appDetails.widgetDetails.termsAndConditions ? <div className="form-group">
                                                        <Checkbox onChange={onChangeTnC} value={tncChecked} > {appDetails.widgetDetails.termsAndConditions}{appDetails.widgetDetails.termsAndConditions.slice(-1) == "." ? "" : "."}</Checkbox>
                                                    </div> : appDetails.widgetDetails.termsAndConditionsUrl ? <div className="form-group">
                                                        <Checkbox onChange={onChangeTnC} value={tncChecked} > Agree <a href={appDetails.widgetDetails.termsAndConditionsUrl} target="_blank"> Terms and conditions</a></Checkbox>
                                                    </div> : <div className="form-group">
                                                        <Checkbox onChange={onChangeTnC} value={tncChecked} > {" Agree Terms and conditions."}</Checkbox>
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="validation invalid-feedback">{tncError}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </>}
                    <Model
                        show={displayMsisdnModal}
                        onHide={() => setDisplayMsisdnModal(false)}
                        size="lg"
                        dialogClassName="modal-50w"
                        aria-labelledby="example-custom-modal-styling-title"
                        className="common__modal payment_method_change_model"
                    >
                        <Model.Header className="common__modal__header" closeButton placeholder={"Are you sure?"}>
                            <Model.Title id="example-custom-modal-styling-title">
                                Are you sure?
                            </Model.Title>
                        </Model.Header>
                        <Model.Body className="common__modal__body">
                            <div className="card panel content-holder-panels">
                                <div className="panel-header">
                                    <div className="panel-header-content__wrapper">
                                        <h4 className="panel-header-title">Your Numbers</h4>
                                        <p className="panel-header-subtitle">
                                            List of numbers reserved for charging; choose one.
                                        </p>
                                    </div>
                                </div>
                                {/* panel table */}
                                <div className="panel-table-listing__wrapper">
                                    <div className="panel-table-listing">
                                        <div className="panel-table-content-list">
                                            {paymentMsisdnList?.map((value: any, index: number) => {
                                                if (value.status === 'Active') {
                                                    return (
                                                        <div className="panel-table-list-item" key={index}>
                                                            <div className="panel-table-listing-item__inner">
                                                                <div className="feature-details__wrapper">
                                                                    <div className="card app-media-holder orange-shade sm-center-icon-card">
                                                                    <span className="icon__wrapper">
                                                                        <i className="bi bi-sim-fill" />
                                                                    </span>
                                                                    </div>
                                                                    <div className="feature-details-title__wrapper no-shade">
                                                                        <span className="title">{value.msisdn}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="panel-table-listing-item__inner">
                                                                <div className="panel-table-right-align-item__wrapper">
                                                                    <div className="panel-table-right-align-item">
                                                                        <div className="form-group__wrapper"></div>
                                                                    </div>
                                                                    <label className="switch">
                                                                        <input
                                                                            type="radio"
                                                                            name='number-change'
                                                                            onChange={onChangePayNumber}
                                                                            value={JSON.stringify(value)}
                                                                            // checked={true}
                                                                            checked={paymentNumber?.msisdn === value.msisdn}
                                                                        />
                                                                        <span className="slider round"/>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })}

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="body-btn-group__wrapper">
                                {/* <button className="btn btn-secondary">Cancel</button> */}
                                <button className="btn btn-primary" onClick={() => setDisplayMsisdnModal(false)}>Confirm</button>
                            </div>
                        </Model.Body>
                    </Model>
                    <Model
                        show={displayCardModal}
                        onHide={() => setDisplayCardModal(false)}
                        size="lg"
                        dialogClassName="modal-50w"
                        aria-labelledby="example-custom-modal-styling-title"
                        className="common__modal payment_method_change_model"
                    >
                        <Model.Header className="common__modal__header" closeButton placeholder={"Are you sure?"}>
                            <Model.Title id="example-custom-modal-styling-title">
                                Are you sure?
                            </Model.Title>
                        </Model.Header>
                        <Model.Body className="common__modal__body">
                            <div className="card panel content-holder-panels">
                                <div className="panel-header">
                                    <div className="panel-header-content__wrapper">
                                        <h4 className="panel-header-title">Your Cards</h4>
                                        <p className="panel-header-subtitle">
                                            List of your cards, reserved for payments, other, other, etc.
                                        </p>
                                    </div>
                                </div>
                                <div className="panel-table-listing__wrapper">
                                    <div className="panel-table-listing">
                                        <div className="panel-table-content-list">
                                            {genieCardList?.map((value: any, index: number) => {
                                                // console.log("########## 2", paymentNumber?.msisdn, value.msisdn);
                                                return (
                                                    <div className="panel-table-list-item" key={index}>
                                                        <div className="panel-table-listing-item__inner">
                                                            <div className="feature-details__wrapper">
                                                                <div className="card app-media-holder orange-shade sm-center-icon-card">
                                                                <span className="icon__wrapper">
                                                                    <i className="bi bi-sim-fill" />
                                                                </span>
                                                                </div>
                                                                <div className="feature-details-title__wrapper no-shade">
                                                                    <span className="title">{value.maskCardNo}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="panel-table-listing-item__inner">
                                                            <div className="panel-table-right-align-item__wrapper">
                                                                <div className="panel-table-right-align-item">
                                                                    <div className="form-group__wrapper"></div>
                                                                </div>
                                                                <label className="switch">
                                                                    <input
                                                                        type="radio"
                                                                        name='card-change'
                                                                        onChange={onChangePayCard}
                                                                        value={JSON.stringify(value)}
                                                                        checked={paymentCard?.invoiceNo === value.invoiceNo}
                                                                    />
                                                                    <span className="slider round" />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="body-btn-group__wrapper">
                                {/* <button className="btn btn-secondary">Cancel</button> */}
                                <button className="btn btn-primary" onClick={() => setDisplayCardModal(false)}>Confirm</button>
                            </div>
                        </Model.Body>
                    </Model>
                    {/*<Modal*/}
                    {/*    display={displayMsisdnModal}*/}
                    {/*    onClose={() => setDisplayMsisdnModal(false)}*/}
                    {/*    onConfirm={() => setDisplayMsisdnModal(false)}*/}
                    {/*>*/}
                    {/*    <div className="card panel content-holder-panels">*/}
                    {/*        <div className="panel-header">*/}
                    {/*            <div className="panel-header-content__wrapper">*/}
                    {/*                <h4 className="panel-header-title">Your Numbers</h4>*/}
                    {/*                <p className="panel-header-subtitle">*/}
                    {/*                    List of numbers reserved for charging; choose one.*/}
                    {/*                </p>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        /!* panel table *!/*/}
                    {/*        <div className="panel-table-listing__wrapper">*/}
                    {/*            <div className="panel-table-listing">*/}
                    {/*                <div className="panel-table-content-list">*/}
                    {/*                    {paymentMsisdnList.map((value: any, index: number) => {*/}
                    {/*                        if (value.status === 'Active') {*/}
                    {/*                            return (*/}
                    {/*                                <div className="panel-table-list-item" key={index}>*/}
                    {/*                                    <div className="panel-table-listing-item__inner">*/}
                    {/*                                        <div className="feature-details__wrapper">*/}
                    {/*                                            <div className="card app-media-holder orange-shade sm-center-icon-card">*/}
                    {/*                                                <span className="icon__wrapper">*/}
                    {/*                                                    <i className="bi bi-sim-fill" />*/}
                    {/*                                                </span>*/}
                    {/*                                            </div>*/}
                    {/*                                            <div className="feature-details-title__wrapper no-shade">*/}
                    {/*                                                <span className="title">{value.msisdn}</span>*/}
                    {/*                                            </div>*/}
                    {/*                                        </div>*/}
                    {/*                                    </div>*/}
                    {/*                                    <div className="panel-table-listing-item__inner">*/}
                    {/*                                        <div className="panel-table-right-align-item__wrapper">*/}
                    {/*                                            <div className="panel-table-right-align-item">*/}
                    {/*                                                <div className="form-group__wrapper"></div>*/}
                    {/*                                            </div>*/}
                    {/*                                            <label className="switch">*/}
                    {/*                                                <input*/}
                    {/*                                                    type="radio"*/}
                    {/*                                                    name='number-change'*/}
                    {/*                                                    onChange={onChangePayNumber}*/}
                    {/*                                                    value={JSON.stringify(value)}*/}
                    {/*                                                    checked={paymentNumber?.msisdn == value.msisdn}*/}
                    {/*                                                />*/}
                    {/*                                                <span className="slider round" />*/}
                    {/*                                            </label>*/}
                    {/*                                        </div>*/}
                    {/*                                    </div>*/}
                    {/*                                </div>*/}
                    {/*                            )*/}
                    {/*                        }*/}
                    {/*                    })}*/}

                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*</Modal>*/}
                    {/*<Modal*/}
                    {/*    display={displayCardModal}*/}
                    {/*    onClose={() => setDisplayCardModal(false)}*/}
                    {/*    onConfirm={() => setDisplayCardModal(false)}*/}
                    {/*>*/}
                    {/*    <div className="card panel content-holder-panels">*/}
                    {/*        <div className="panel-header">*/}
                    {/*            <div className="panel-header-content__wrapper">*/}
                    {/*                <h4 className="panel-header-title">Your Cards</h4>*/}
                    {/*                <p className="panel-header-subtitle">*/}
                    {/*                    List of your cards, reserved for payments, other, other, etc.*/}
                    {/*                </p>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="panel-table-listing__wrapper">*/}
                    {/*            <div className="panel-table-listing">*/}
                    {/*                <div className="panel-table-content-list">*/}
                    {/*                    {genieCardList.map((value: any, index: number) => {*/}
                    {/*                        // console.log("########## 2", paymentNumber?.msisdn, value.msisdn);*/}
                    {/*                        return (*/}
                    {/*                            <div className="panel-table-list-item" key={index}>*/}
                    {/*                                <div className="panel-table-listing-item__inner">*/}
                    {/*                                    <div className="feature-details__wrapper">*/}
                    {/*                                        <div className="card app-media-holder orange-shade sm-center-icon-card">*/}
                    {/*                                            <span className="icon__wrapper">*/}
                    {/*                                                <i className="bi bi-sim-fill" />*/}
                    {/*                                            </span>*/}
                    {/*                                        </div>*/}
                    {/*                                        <div className="feature-details-title__wrapper no-shade">*/}
                    {/*                                            <span className="title">{value.maskCardNo}</span>*/}
                    {/*                                        </div>*/}
                    {/*                                    </div>*/}
                    {/*                                </div>*/}
                    {/*                                <div className="panel-table-listing-item__inner">*/}
                    {/*                                    <div className="panel-table-right-align-item__wrapper">*/}
                    {/*                                        <div className="panel-table-right-align-item">*/}
                    {/*                                            <div className="form-group__wrapper"></div>*/}
                    {/*                                        </div>*/}
                    {/*                                        <label className="switch">*/}
                    {/*                                            <input*/}
                    {/*                                                type="radio"*/}
                    {/*                                                name='card-change'*/}
                    {/*                                                onChange={onChangePayCard}*/}
                    {/*                                                value={JSON.stringify(value)}*/}
                    {/*                                                checked={paymentCard?.invoiceNo === value.invoiceNo}*/}
                    {/*                                            />*/}
                    {/*                                            <span className="slider round" />*/}
                    {/*                                        </label>*/}
                    {/*                                    </div>*/}
                    {/*                                </div>*/}
                    {/*                            </div>*/}
                    {/*                        )*/}
                    {/*                    })}*/}

                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*</Modal>*/}
                </div>
                {showConsentModal &&
                    <KycConsentModal
                        showEntConsentModal={showConsentModal}
                        closeModalEntConsentModal={closeModalEntConsentModal}
                        addEntConsentDetails={addEntConsentDetails}
                        onConsentStatusChange={onConsentStatusChange}
                        isConsentTrue={isConsentTrue}
                    />}
            </div>
            : <div className="main-content">
                <div className="container">
                    <div className="card panel mx-width-card detail-confirmation-panel">
                        <div className="panel-header">
                            <div className="panel-header-content__wrapper">
                                <h4 className="panel-header-title txt-green">Success!</h4>
                            </div>
                        </div>
                        <div className="panel-content">
                            <div className="panerl-content-header__wrapper">
                                <h4 className="panel-inner-title">{(userDetails && Array.isArray(userDetails.OwnedEnterprises) && userDetails.OwnedEnterprises.length > 0 && userDetails.OwnedEnterprises[0].Enterprise) ? userDetails.OwnedEnterprises[0].Enterprise.entName : ''}</h4>
                                <span className="app-developer">
                                    by&nbsp;
                                    <a href="#" aria-label="app developer">
                                        Marketplace
                                    </a>
                                </span>
                            </div>
                            <AppEntBindingComponent
                                entImage={logo}
                                entName={(userDetails && Array.isArray(userDetails.OwnedEnterprises) && userDetails.OwnedEnterprises.length > 0 && userDetails.OwnedEnterprises[0].Enterprise) ? userDetails.OwnedEnterprises[0].Enterprise.entName : ''}
                                appImage={(appDetails && appDetails.widgetDetails && appDetails.widgetDetails.thumbnail) ? appDetails.widgetDetails.thumbnail : ''}
                                appName={(appDetails && appDetails.widgetDetails && appDetails.widgetDetails.title) ? appDetails.widgetDetails.title : ''}
                                status="confirmed"//"confirmed","error"
                            />
                            <div className="status__wrapper status-update">
                                <p>App Successfully added to your enterpricess. </p>
                                <div className="primary-progress-spinner spinner-md" />
                                <p>Redirecting to the page</p>
                            </div>
                        </div>
                        <div className="panel-footer">
                            <span>
                                <i className="bi bi-telephone-fill" />
                                &nbsp;&nbsp;If you having any issues please{" "}
                                <a href="mailto:info.marketplace@dialog.lk" target="_blank" rel="noopener noreferrer">
                                    Contact Support
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export { AppSubscription };