import React, {useEffect, useState} from 'react'
import {WidgetRate} from "../../pages/single-app/models/response/single-app.model";

type Props = {
    appDetails: any
}

const ChargeableTypeSevenComponent = ({appDetails}: Props) => {
    return (
        <div className="panel-table-content-list">
            {appDetails && appDetails.widgetDetails && appDetails.widgetDetails.WidgetRate && Array.isArray(appDetails.widgetDetails.WidgetRate) && appDetails.widgetDetails.WidgetRate.map((widgetRate: WidgetRate) => {
                return <div>
                    <span className="panel-group-title">{widgetRate.RateCard.name}</span>
                    <div className={`panel-table-list-item card active`}>
                        <div className="panel-table-listing-item__inner">
                            <div className="feature-details__wrapper">
                                <div className="feature-details-title__wrapper no-shade billing-and-plans-package-select">
                                    <span className="title">{widgetRate.title}</span>
                                    <span className="small-desc">
                                        {widgetRate.description}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="panel-table-listing-item__inner">
                            <div className="panel-table-right-align-item__wrapper">
                                <div className="panel-table-right-align-item">
                                    <input
                                        className="form-check-input secondary"
                                        type="radio"
                                        name=""
                                        id="radio1"
                                        checked={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            })}
        </div>
    )
}

export {ChargeableTypeSevenComponent}