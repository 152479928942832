/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Col, Form, Input, Row } from 'antd';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

type Props = {
    createGwsUser: (values: any) => any
}

const CreateUser = ({ createGwsUser }: Props) => {

    const { success, loading, error, configData } = useSelector(({ gws }: any) => gws);

    return (
        <React.Fragment>
            <div>
                <div className="card-body gws__body">
                    <h4 className="gws-title">Create Admin User</h4>
                    <Form
                        {...layout}
                        name="create-user"
                        style={{ maxWidth: 600, marginInline: 'auto', textAlign: 'left' }}
                        initialValues={{ remember: true }}
                        onFinish={createGwsUser}
                        autoComplete="off"
                        labelAlign={"left"}
                    >
                        <Form.Item
                            label="First Name"
                            name="firstName"
                            rules={[
                                {
                                    required: true,
                                    message: 'You can\'t keep this as empty.',
                                },
                                {
                                    pattern: new RegExp(/^[a-zA-Z ]*$/),
                                    message: 'Please enter a valid name.',
                                },
                                {
                                    max: 20,
                                    message: 'Maximum length is exceeded.',
                                }
                            ]}
                        >
                            <Input placeholder="Type the first name" />
                        </Form.Item>
                        <Form.Item
                            label="Last Name"
                            name="lastName"
                            rules={[
                                {
                                    required: true,
                                    message: 'You can\'t keep this as empty.',
                                },
                                {
                                    pattern: new RegExp(/^[a-zA-Z ]*$/),
                                    message: 'Please enter a valid name.',
                                },
                                {
                                    max: 40,
                                    message: 'Maximum length is exceeded.',
                                }
                            ]}
                        >
                            <Input placeholder="Type the Last name" />
                        </Form.Item>
                        <Form.Item
                            label="Admin email address"
                            name="adminEmail"
                            rules={[
                                {
                                    required: true,
                                    message: 'You can\'t keep this as empty.',
                                },
                                {
                                    pattern: new RegExp(/^[0-9a-zA-Z.]*$/),
                                    message: 'Please enter a valid email address.',
                                }
                            ]}
                        >
                            <Row>
                                <Col span={12}>
                                    <div style={{ paddingRight: 5 }}>
                                        <Input placeholder="Type the email address" />
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <p style={{ paddingLeft: 5, marginBottom: 0, width: '100%' }}>
                                        <span className="gws__domain__badge badge badge-provitioning" >@{configData.domain}</span>
                                    </p>
                                </Col>
                            </Row>
                        </Form.Item>
                        <Form.Item
                            label="Admin Password"
                            name="password"
                            tooltip={<div>
                                <b>Password policy criteria</b>
                                <p>*Password must include uppercase, lowercase value.</p>
                                <p>*Must include at least one number.</p>
                                <p>*Must include at least one Symbol.</p>
                                <p>*The minimum length is 8</p>
                                <p>*The maximum length is 30</p>
                            </div>}
                            rules={[
                                {
                                    required: true,
                                    message: 'You can\'t keep this as empty.',
                                },
                                {
                                    pattern: new RegExp(/^(?=.*[a-z])/),
                                    message: 'Password must include at least one lowercase value.',
                                },
                                {
                                    pattern: new RegExp(/^(?=.*[A-Z])/),
                                    message: 'Password must include at least one uppercase value.',
                                },
                                {
                                    pattern: new RegExp(/^(?=.*[!@#$%^&*._-])/),
                                    message: 'Password must include at least one special character.',
                                },
                                {
                                    pattern: new RegExp(/^(?=.*[0-9])/),
                                    message: 'Password must include at least one number.',
                                },
                                {
                                    pattern: new RegExp(/^[^\s]{1,}$/),
                                    message: 'Spaces are not allowed.',
                                },
                                {
                                    min: 8,
                                    message: 'Password is too short.',
                                },
                                {
                                    max: 100,
                                    message: 'Password is too long.',
                                }
                            ]}
                            validateTrigger='onSubmit'
                        >
                            <Input.Password placeholder="Type the password" />
                        </Form.Item>
                        <Form.Item
                            name="confirm"
                            label="Confirm Password"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'You can\'t keep this as empty.',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The entered confirm password do not match.'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item {...tailLayout} className={"wizard-action"}>
                            <Button type="primary" htmlType="submit" className={"btn btn-primary btn-sm"}>
                                Next
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    );
}

export default CreateUser;