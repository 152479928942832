/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { Button, Input, Tooltip, message } from 'antd';
import * as Constants from '../../../../constants';
import { CopyOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import VerificationInfoModal from "./VerificationInfoModal";

const { TextArea } = Input;

type Props = {
    verifyGWSDomain: (values: any) => any
}

const VerifyDomain = ({ verifyGWSDomain }: Props) => {

    const { success, loading, error, configData } = useSelector(({ gws }: any) => gws);

    const [showTxtInstructions, setShowTxtInstructions] = useState(false);
    const [showCnameInstructions, setShowCnameInstructions] = useState(false);

    const onCancelInstructions = () => {
        setShowTxtInstructions(false);
        setShowCnameInstructions(false);
    }

    const showVerificationInstructions = (type: string) => {
        if(type === Constants.GWS_METHOD_CNAME) {
            setShowCnameInstructions(true);
        } else if(type === Constants.GWS_METHOD_TXT) {
            setShowTxtInstructions(true);
        }
    }

    return (
        <React.Fragment>
           <div className="card-body gws__body">
                <h4 className="gws-title">Verify Domain Ownership</h4>
                <div>
                    <div style={{ marginTop: 20, marginBottom: 10 }}>
                        <p>Add this {configData.verificationMethod === Constants.GWS_METHOD_CNAME ? "CNAME" : "TXT record" } to your domain</p>
                    </div>
                    {configData.verificationMethod === Constants.GWS_METHOD_TXT &&
                    <div style={{  marginTop: 20, marginBottom: 10 }}>
                        <Input.Group compact>
                            <TextArea autoSize={true} style={{maxWidth: 600, marginBottom: 10}} value={configData.verificationData} readOnly />
                            <Tooltip title="copy key">
                                <Button icon={<CopyOutlined />} onClick={() => {
                                    navigator.clipboard.writeText(configData.verificationData);
                                    message.success({ content: 'Copied to clipboard' });
                                }} />
                            </Tooltip>
                        </Input.Group>
                    </div>}
                    {configData.verificationMethod === Constants.GWS_METHOD_CNAME && configData.verificationData.split(' ').length > 1 &&
                        <div style={{  marginTop: 20, marginBottom: 10 }}>
                            <Input.Group compact>
                                <label>CNAME Lable / Host</label>
                                <TextArea autoSize={true} style={{maxWidth: 600, marginBottom: 10}} value={configData.verificationData.split(' ')[0]} readOnly />
                                <Tooltip title="copy key">
                                    <Button icon={<CopyOutlined />} onClick={() => {
                                        navigator.clipboard.writeText(configData.verificationData.split(' ')[0]);
                                        message.success({ content: 'Copied to clipboard' });
                                    }} />
                                </Tooltip>
                            </Input.Group>
                            <Input.Group compact>
                                <label>CNAME Destination / target</label>
                                <TextArea autoSize={true} style={{maxWidth: 600, marginBottom: 10}} value={configData.verificationData.split(' ')[1]} readOnly />
                                <Tooltip title="copy key">
                                    <Button icon={<CopyOutlined />} onClick={() => {
                                        navigator.clipboard.writeText(configData.verificationData.split(' ')[1]);
                                        message.success({ content: 'Copied to clipboard' });
                                    }} />
                                </Tooltip>
                            </Input.Group>
                        </div>
                    }
                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
                        <Link to={''} style={{ fontSize: 12 }} onClick={() => showVerificationInstructions(configData.verificationMethod)}>
                            <u>How to verify domain using {configData.verificationMethod === Constants.GWS_METHOD_CNAME ? "CNAME" : "TXT record" }</u>
                        </Link>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button
                        className="btn btn-primary btn-sm"
                        onClick={verifyGWSDomain}
                    >
                        Next
                    </button>
                </div>
                <VerificationInfoModal type={Constants.GWS_METHOD_TXT} show={showTxtInstructions} close={onCancelInstructions} />
                <VerificationInfoModal type={Constants.GWS_METHOD_CNAME} show={showCnameInstructions} close={onCancelInstructions} />
            </div>
        </React.Fragment>
    );
}

export default VerifyDomain;