import axiosInstance from "../axios-instance";
import * as CONSTANTS from "../constants";
import { WidgetSubscriptionModel } from "../pages/single-app/models/request/widget-subscription.model";
import {KycWidgetSubscriptionModel} from "../pages/single-app/models/request/kyc-widget-subscription.model";

const getAllApps = () => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/all-apps`
    });
};

const getCategories = () => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/widgets`
    });
};

const getFeatureAppDetailsForAppWizard = (limit: number, offset: number) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/widgets/featured/${limit}/${offset}`
    });
};

const getSliderItems = () => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.TEST_BACKEND_URL}/slider-items`
    });
};

const getTopApps = (limit: number, offset: number) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/widgets/top/${limit}/${offset}`
    });
};

const getNewlyArrivedApps = (limit: number, offset: number) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/widgets/newly-arrived/${limit}/${offset}`
    });
};

const getTrendingApps = () => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/widgets/trending`
    });
};

const getPaymentMsisdnList = (tenantId: string, widgetId: string) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/ent/msisdn/${tenantId}/${widgetId}`
    });
};

const getGenieCardList = (tenantId: string) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/revamp/genieCards/${tenantId}`
    });
};

const postWidgetSubscription = (tenantId: string, widgetId: string, req: WidgetSubscriptionModel) => {
    console.log("requestttttt", req)
    return axiosInstance({
        method: "post",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/widgets/sub-revamp/${tenantId}/${widgetId}`,
        data: req
    });
};

const getBusinessTypes = ()=> {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/revamp/landing-page/all-business-types`
    });
};

const getBusinessTypeDetails = (businessTypeId: string, searchTerm: string | null, limit: number, offset: number)=> {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/revamp/all-widgets/${businessTypeId}/${searchTerm}/${limit}/${offset}`
    });
};

const getBusinessTypeTopApps = (businessTypeId: string, limit: number, offset: number)=> {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/revamp/top-widgets/${businessTypeId}/${limit}/${offset}`
    });
};

const postKycWidgetSubscription = (tenantId: string, widgetId: string, req: KycWidgetSubscriptionModel) => {
    return axiosInstance({
        method: "post",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widget/kyc/kycConfig/${tenantId}/${widgetId}`,
        data: req
    });
};

const getAllWidgets = (limit: number, offset: number) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widgets/all/${limit}/${offset}`
    });
};

const getSearchResults = ( key: string, limit: number, offset: number) => {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_NODE_BACKEND_URL}/widgets/search-bar/${key}/${limit}/${offset}`,
    })
}

export {
    getAllApps,
    getCategories,
    getFeatureAppDetailsForAppWizard,
    getSliderItems,
    getTopApps,
    getNewlyArrivedApps,
    getTrendingApps,
    getPaymentMsisdnList,
    getGenieCardList,
    postWidgetSubscription,
    getBusinessTypes,
    getBusinessTypeDetails,
    getBusinessTypeTopApps,
    postKycWidgetSubscription,
    getAllWidgets,
    getSearchResults
};