import React, {useEffect, useState} from 'react'
import {MultiRateCardSelect} from "../../pages/single-app/components";
import SimpleReactValidator from "simple-react-validator";
import {WidgetRate} from "../../pages/single-app/models/response/single-app.model";

type Props = {
    appDetails: any
}

const ChargeableTypeSixComponent = ({appDetails}: Props) => {

    const [selectedRate, setSelectedRate] = React.useState('');
    const [validator] = useState(new SimpleReactValidator())

    useEffect(() => {
        if(appDetails && appDetails.widgetDetails && appDetails.widgetDetails.WidgetRate && Array.isArray(appDetails.widgetDetails.WidgetRate)) {
            appDetails.widgetDetails.WidgetRate.map((widgetRate: WidgetRate) => {
                if(Array.isArray(widgetRate.RatePlaneDetails) && widgetRate.RatePlaneDetails.length > 0) {
                    setSelectedRate(widgetRate.uuid ? widgetRate.uuid : '')
                }
            })
        }
    }, [])

    const onChangePackage = (e: any) => {
        if (appDetails && appDetails.widgetDetails && appDetails.widgetDetails.isPaymentMethodEditable) {
            setSelectedRate(e.target.value);
        }
    }

    return (
        <div className="panel-table-content-list">
            <MultiRateCardSelect
                appDetails={appDetails}
                onChangeRate={onChangePackage}
                selectedRate={selectedRate}
                validator={validator}
                disabled={!(appDetails && appDetails.widgetDetails && appDetails.widgetDetails.isPaymentMethodEditable)}
            />
        </div>
    )
}

export {ChargeableTypeSixComponent}