import React from 'react';
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { OfferModel } from '../models/offer.model';
import { Link } from 'react-router-dom';

type Props = {
    singleWidgetDetails: OfferModel,
    onRedirect: (uuid: string | null) => void,
    redirectToApp: (uuid: string | null) => string,
    redirectToCategories: (uuid: string | null) => string,
}

const SingleWidgetCard = ({ singleWidgetDetails, onRedirect, redirectToApp, redirectToCategories }: Props) => {
    console.log("singleWidgetDetails", singleWidgetDetails)
    return (
        <>
            <div className="app-listing-item__wrapper" onClick={() => onRedirect(singleWidgetDetails.uuid)}>
                <a>
                    <div className="app-listinng-item">
                        <div className="app-item-content__wrapper">
                            <div className="card app-thumbnail-holder" style={{ backgroundImage: `url(${singleWidgetDetails.thumbnail})` }}>
                            </div>
                            <div className="discount-label">Offer</div>
                        </div>
                        <div className="app-item-content__wrapper app-details">
                            <div className="card app-media-holder" style={{ backgroundImage: `url(${singleWidgetDetails.icon})` }}>
                            </div>
                            <div className="app-media-info-holder">
                                <Link to={redirectToApp(singleWidgetDetails.uuid)} className="app-media-title">{singleWidgetDetails.title}</Link>
                                {/* <Link to={redirectToCategories(singleWidgetDetails?.OFFER.SERVICE_ID)} className="app-media-category">{singleWidgetDetails?.OFFER.SERVICE_ID} </Link> */}
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </>
    )
}

export { SingleWidgetCard }